import React, {FC, useContext, useState} from "react";
import "./ResultBlock.scss";
import { AppContext } from "../../../context/AppContext";
import File from "../../../images/file.png"
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import ResultModalBlock from "./ResultModalBlock";

import cloneDeep from 'lodash/cloneDeep'

interface Props { 
    calcResult: any;
    areasCount: any;
    areas1: any;
    areas2: any;
    areas3: any;
    areas4: any;
    areas5: any;
    areas6: any;
    areas7: any;
    areas8: any;
    areas9: any;
    areas10: any;
    areas11: any;
    areas12: any;
    areas13: any;
    areas14: any;
    areas15: any;
    areas16: any;
    areas17: any;
    areas18: any;
    areas19: any;
    areas20: any;

    results1: any;
    setResults1: any;
    results2: any;
    setResults2: any;
    results3: any;
    setResults3: any;
    results4: any;
    setResults4: any;
    results5: any;
    setResults5: any;
    results6: any;
    setResults6: any;
    results7: any;
    setResults7: any;
    results8: any;
    setResults8: any;
    results9: any;
    setResults9: any;
    results10: any;
    setResults10: any;
    results11: any;
    setResults11: any;
    results12: any;
    setResults12: any;
    results13: any;
    setResults13: any;
    results14: any;
    setResults14: any;
    results15: any;
    setResults15: any;
    results16: any;
    setResults16: any;
    results17: any;
    setResults17: any;
    results18: any;
    setResults18: any;
    results19: any;
    setResults19: any;
    results20: any;
    setResults20: any;
    cellRows1: any;
    cellRows2: any;
    cellRows3: any;
    cellRows4: any;
    cellRows5: any;
    cellRows6: any;
    cellRows7: any;
    cellRows8: any;
    cellRows9: any;
    cellRows10: any;
    cellRows11: any;
    cellRows12: any;
    cellRows13: any;
    cellRows14: any;
    cellRows15: any;
    cellRows16: any;
    cellRows17: any;
    cellRows18: any;
    cellRows19: any;
    cellRows20: any;
    
    setIsPDFGenerating: any;
    isPDFGenerating: any;
    isGridsReady: any;
    setIsGridsReady: any;
}

const ResultBlock: FC<Props> = ({ calcResult, setIsPDFGenerating, isPDFGenerating, isGridsReady, 
    areas1, areas2, areas3, areas4, areas5, areas6, areas7, areas8, areas9, areas10, areas11, areas12, areas13, areas14, areas15, areas16, areas17, areas18, 
    areas19, areas20, setIsGridsReady, areasCount,
    results1, 
    setResults1, 
    results2, 
    setResults2, 
    results3,  
    setResults3,
    results4,  
    setResults4,
    results5,  
    setResults5,
    results6,  
    setResults6,
    results7,  
    setResults7,
    results8,  
    setResults8,
    results9,  
    setResults9,
    results10,  
    setResults10,
    results11,  
    setResults11,
    results12,  
    setResults12,
    results13,  
    setResults13,
    results14,  
    setResults14,
    results15,  
    setResults15,
    results16,  
    setResults16,
    results17,  
    setResults17,
    results18,  
    setResults18,
    results19,  
    setResults19,
    results20,
    setResults20,
    cellRows1,
    cellRows2,
    cellRows3,
    cellRows4,
    cellRows5,
    cellRows6,
    cellRows7,
    cellRows8,
    cellRows9,
    cellRows10,
    cellRows11,
    cellRows12,
    cellRows13,
    cellRows14,
    cellRows15,
    cellRows16,
    cellRows17,
    cellRows18,
    cellRows19,
    cellRows20,
}) => {
    const [leaves, setLeaves] = useState<any[]>([])
    const [leaves1, setLeaves1] = useState<any[]>([])
    const [leaves2, setLeaves2] = useState<any[]>([])
    const [leaves3, setLeaves3] = useState<any[]>([])
    const [leaves4, setLeaves4] = useState<any[]>([])
    const [leaves5, setLeaves5] = useState<any[]>([])
    const [leaves6, setLeaves6] = useState<any[]>([])
    const [leaves7, setLeaves7] = useState<any[]>([])
    const [leaves8, setLeaves8] = useState<any[]>([])
    const [leaves9, setLeaves9] = useState<any[]>([])
    const [leaves10, setLeaves10] = useState<any[]>([])
    const [leaves11, setLeaves11] = useState<any[]>([])
    const [leaves12, setLeaves12] = useState<any[]>([])
    const [leaves13, setLeaves13] = useState<any[]>([])
    const [leaves14, setLeaves14] = useState<any[]>([])
    const [leaves15, setLeaves15] = useState<any[]>([])
    const [leaves16, setLeaves16] = useState<any[]>([])
    const [leaves17, setLeaves17] = useState<any[]>([])
    const [leaves18, setLeaves18] = useState<any[]>([])
    const [leaves19, setLeaves19] = useState<any[]>([])
    const [leaves20, setLeaves20] = useState<any[]>([])
    const [totalLeaves, setTotalLeaves] = useState<any[]>([])
    const [totalLeavesSizes, setTotalLeavesSizes] = useState<any[]>([])
    const [totalLeavesNumber, setTotalLeavesNumber] = useState<any[]>([])
    const [totalLeavesValue, setTotalLeavesValue] = useState<any[]>([])
    const [totalSquare, setTotalSquare] = useState(0)
    const [totalLeavesSquare, setTotalLeavesSquare] = useState(0)
    const [totalLeavesUsfSquare, setTotalLeavesUsfSquare] = useState(0)
    const [totalLeavesWaste, setTotalLeavesWaste] = useState(0)
    const [totalLinearMeters, setTotalLinearMeters] = useState(0)
    const [resultModal, setResultModal] = useState(false)
    const appContext = useContext(AppContext);

    const { dictionary } = appContext.state;

    React.useEffect(() => {
        if(calcResult !== null){
            let leavesCopy: any = []
            for(let i = 0; i < calcResult[5].length; i++){
                leavesCopy.push(<p>Листи {calcResult[3] * 1000} мм x {Math.round(calcResult[5][i] * 1000)} мм: {calcResult[4][calcResult[5][i]]} шт;</p>)
            }
            setLeaves(leavesCopy)
        }
    }, [calcResult]);
    React.useEffect(() => {
        if(totalLeavesValue.length > 0){
            let totalLinearMeterscopy = 0
            let leavesCopy: any = []
            for(let i = 0; i < totalLeavesValue.length; i++){
                leavesCopy.push(<p>Листи {calcResult[3] * 1000} мм x {Math.round(totalLeavesValue[i] * 1000)} мм: {totalLeavesSizes[totalLeavesValue[i]]} шт;</p>)
                totalLinearMeterscopy = totalLinearMeterscopy + (totalLeavesValue[i] *  totalLeavesSizes[totalLeavesValue[i]])
            }
            setTotalLeaves(leavesCopy)
            setTotalLinearMeters(totalLinearMeterscopy)
        }
    }, [totalLeavesSizes]);

    React.useEffect(() => {
        if(results1.length > 0){
            let leavesCopy: any = []
            for(let i = 0; i < results1[5].length; i++){
                leavesCopy.push(<p>Листи {results1[3] * 1000} мм x {Math.round(results1[5][i] * 1000)} мм: {results1[4][results1[5][i]]} шт;</p>)
            }
            setLeaves1(leavesCopy)
        }
    }, [results1]);
    React.useEffect(() => {
        if(results2.length > 0){
            let leavesCopy: any = []
            for(let i = 0; i < results2[5].length; i++){
                leavesCopy.push(<p>Листи {results2[3] * 1000} мм x {Math.round(results2[5][i] * 1000)} мм: {results2[4][results2[5][i]]} шт;</p>)
            }
            setLeaves2(leavesCopy)
        }
    }, [results2]);
    React.useEffect(() => {
        if(results3.length > 0){
            let leavesCopy: any = []
            for(let i = 0; i < results3[5].length; i++){
                leavesCopy.push(<p>Листи {results3[3] * 1000} мм x {Math.round(results3[5][i] * 1000)} мм: {results3[4][results3[5][i]]} шт;</p>)
            }
            setLeaves3(leavesCopy)
        }
    }, [results3]);
    React.useEffect(() => {
        if(results4.length > 0){
            let leavesCopy: any = []
            for(let i = 0; i < results4[5].length; i++){
                leavesCopy.push(<p>Листи {results4[3] * 1000} мм x {Math.round(results4[5][i] * 1000)} мм: {results4[4][results4[5][i]]} шт;</p>)
            }
            setLeaves4(leavesCopy)
        }
    }, [results4]);
    React.useEffect(() => {
        if(results5.length > 0){
            let leavesCopy: any = []
            for(let i = 0; i < results5[5].length; i++){
                leavesCopy.push(<p>Листи {results5[3] * 1000} мм x {Math.round(results5[5][i] * 1000)} мм: {results5[4][results5[5][i]]} шт;</p>)
            }
            setLeaves5(leavesCopy)
        }
    }, [results5]);
    React.useEffect(() => {
        if(results6.length > 0){
            let leavesCopy: any = []
            for(let i = 0; i < results6[5].length; i++){
                leavesCopy.push(<p>Листи {results6[3] * 1000} мм x {Math.round(results6[5][i] * 1000)} мм: {results6[4][results6[5][i]]} шт;</p>)
            }
            setLeaves6(leavesCopy)
        }
    }, [results6]);
    React.useEffect(() => {
        if(results7.length > 0){
            let leavesCopy: any = []
            for(let i = 0; i < results7[5].length; i++){
                leavesCopy.push(<p>Листи {results7[3] * 1000} мм x {Math.round(results7[5][i] * 1000)} мм: {results7[4][results7[5][i]]} шт;</p>)
            }
            setLeaves7(leavesCopy)
        }
    }, [results7]);
    React.useEffect(() => {
        if(results8.length > 0){
            let leavesCopy: any = []
            for(let i = 0; i < results8[5].length; i++){
                leavesCopy.push(<p>Листи {results8[3] * 1000} мм x {Math.round(results8[5][i] * 1000)} мм: {results8[4][results8[5][i]]} шт;</p>)
            }
            setLeaves8(leavesCopy)
        }
    }, [results8]);
    React.useEffect(() => {
        if(results9.length > 0){
            let leavesCopy: any = []
            for(let i = 0; i < results9[5].length; i++){
                leavesCopy.push(<p>Листи {results9[3] * 1000} мм x {Math.round(results9[5][i] * 1000)} мм: {results9[4][results9[5][i]]} шт;</p>)
            }
            setLeaves9(leavesCopy)
        }
    }, [results9]);
    React.useEffect(() => {
        if(results10.length > 0){
            let leavesCopy: any = []
            for(let i = 0; i < results10[5].length; i++){
                leavesCopy.push(<p>Листи {results10[3] * 1000} мм x {Math.round(results10[5][i] * 1000)} мм: {results10[4][results10[5][i]]} шт;</p>)
            }
            setLeaves10(leavesCopy)
        }
    }, [results10]);
    React.useEffect(() => {
        if(results11.length > 0){
            let leavesCopy: any = []
            for(let i = 0; i < results11[5].length; i++){
                leavesCopy.push(<p>Листи {results11[3] * 1000} мм x {Math.round(results11[5][i] * 1000)} мм: {results11[4][results11[5][i]]} шт;</p>)
            }
            setLeaves11(leavesCopy)
        }
    }, [results11]);
    React.useEffect(() => {
        if(results12.length > 0){
            let leavesCopy: any = []
            for(let i = 0; i < results12[5].length; i++){
                leavesCopy.push(<p>Листи {results12[3] * 1000} мм x {Math.round(results12[5][i] * 1000)} мм: {results12[4][results12[5][i]]} шт;</p>)
            }
            setLeaves12(leavesCopy)
        }
    }, [results12]);
    React.useEffect(() => {
        if(results13.length > 0){
            let leavesCopy: any = []
            for(let i = 0; i < results13[5].length; i++){
                leavesCopy.push(<p>Листи {results13[3] * 1000} мм x {Math.round(results13[5][i] * 1000)} мм: {results13[4][results13[5][i]]} шт;</p>)
            }
            setLeaves13(leavesCopy)
        }
    }, [results13]);
    React.useEffect(() => {
        if(results14.length > 0){
            let leavesCopy: any = []
            for(let i = 0; i < results14[5].length; i++){
                leavesCopy.push(<p>Листи {results14[3] * 1000} мм x {Math.round(results14[5][i] * 1000)} мм: {results14[4][results14[5][i]]} шт;</p>)
            }
            setLeaves14(leavesCopy)
        }
    }, [results14]);
    React.useEffect(() => {
        if(results15.length > 0){
            let leavesCopy: any = []
            for(let i = 0; i < results15[5].length; i++){
                leavesCopy.push(<p>Листи {results15[3] * 1000} мм x {Math.round(results15[5][i] * 1000)} мм: {results15[4][results15[5][i]]} шт;</p>)
            }
            setLeaves15(leavesCopy)
        }
    }, [results15]);
    React.useEffect(() => {
        if(results16.length > 0){
            let leavesCopy: any = []
            for(let i = 0; i < results16[5].length; i++){
                leavesCopy.push(<p>Листи {results16[3] * 1000} мм x {Math.round(results16[5][i] * 1000)} мм: {results16[4][results16[5][i]]} шт;</p>)
            }
            setLeaves16(leavesCopy)
        }
    }, [results16]);
    React.useEffect(() => {
        if(results17.length > 0){
            let leavesCopy: any = []
            for(let i = 0; i < results17[5].length; i++){
                leavesCopy.push(<p>Листи {results17[3] * 1000} мм x {Math.round(results17[5][i] * 1000)} мм: {results17[4][results17[5][i]]} шт;</p>)
            }
            setLeaves17(leavesCopy)
        }
    }, [results17]);
    React.useEffect(() => {
        if(results18.length > 0){
            let leavesCopy: any = []
            for(let i = 0; i < results18[5].length; i++){
                leavesCopy.push(<p>Листи {results18[3] * 1000} мм x {Math.round(results18[5][i] * 1000)} мм: {results18[4][results18[5][i]]} шт;</p>)
            }
            setLeaves18(leavesCopy)
        }
    }, [results18]);
    React.useEffect(() => {
        if(results19.length > 0){
            let leavesCopy: any = []
            for(let i = 0; i < results19[5].length; i++){
                leavesCopy.push(<p>Листи {results19[3] * 1000} мм x {Math.round(results19[5][i] * 1000)} мм: {results19[4][results19[5][i]]} шт;</p>)
            }
            setLeaves19(leavesCopy)
        }
    }, [results19]);
    React.useEffect(() => {
        if(results20.length > 0){
            let leavesCopy: any = []
            for(let i = 0; i < results20[5].length; i++){
                leavesCopy.push(<p>Листи {results20[3] * 1000} мм x {Math.round(results20[5][i] * 1000)} мм: {results20[4][results20[5][i]]} шт;</p>)
            }
            setLeaves20(leavesCopy)
        }
    }, [results20]);

    React.useEffect(() => {
        setTotalLeavesNumber(results1[4])
        setTotalLeavesValue(results1[5])
        let totalLeavesObj = { ...results1[4] }
        let totalSquareCopy = 0
        let totalLeavesSquareCopy = 0
        let totalLeavesUsfSquareCopy = 0
        let totalLeavesWasteCopy = 0
        for(let i = 1; i < 21; i++){
            if(eval("results" + i).length > 0){
                totalSquareCopy = totalSquareCopy + Number((eval("results" + i + "[0]")).toFixed(2))
                totalLeavesSquareCopy = totalLeavesSquareCopy + Number((eval("results" + i + "[1]")).toFixed(2))
                totalLeavesUsfSquareCopy = totalLeavesUsfSquareCopy + Number((eval("results" + i + "[2]")).toFixed(2))
                totalLeavesWasteCopy = totalLeavesWasteCopy + Number((eval("results" + i + "[6]")).toFixed(2))

                let totalLeavesNumberCopy = cloneDeep(results1[4])
                let totalLeavesValueCopy = cloneDeep(results1[5])

                for(let n = 0; n < eval("results" + i + "[4]").lenght; n++){
                    if(totalLeavesValue.includes(eval("results" + i + "[5]")[n]) == false){

                        totalLeavesValueCopy.push(eval("results" + i + "[5]")[n])
                    }
                    
                }
                if(i > 1){
                    for (const key in eval("results" + i + "[4]")) {
                        if (totalLeavesObj[key]) {
                            totalLeavesObj[key] += eval("results" + i + "[4]")[key];
                        } else {
                            totalLeavesObj[key] = eval("results" + i + "[4]")[key];
                        }
                    }
                }
                let values = Object.keys(totalLeavesObj)
                setTotalLeavesValue(values)
                setTotalLeavesNumber(totalLeavesNumberCopy)
                setTotalLeavesSizes(totalLeavesObj)
            }

        }
        setTotalSquare(totalSquareCopy)
        setTotalLeavesSquare(totalLeavesSquareCopy)
        setTotalLeavesUsfSquare(totalLeavesUsfSquareCopy)
        setTotalLeavesWaste(totalLeavesWasteCopy)
    }, [results1, results2, results3, results4, results5, results6, results7, results8, results9, results10, 
        results11, results12, results13, results14, results15, results16, results17, results18, results19, results20]);

    const pdfGenerate = () =>{
        if(appContext.state.isEditedMode && areasCount == 0){
            let n: any;
            if(areas1.length > 0 && areas2.length == 0){
                n = 1
            }
            if(areas2.length > 0 && areas3.length == 0){
                n = 2
            }
            if(areas3.length > 0 && areas4.length == 0){
                n = 3
            }
            if(areas4.length > 0 && areas5.length == 0){
                n = 4
            }
            if(areas5.length > 0 && areas6.length == 0){
                n = 5
            }
            if(areas6.length > 0 && areas7.length == 0){
                n = 6
            }
            if(areas7.length > 0 && areas8.length == 0){
                n = 7
            }
            if(areas8.length > 0 && areas9.length == 0){
                n = 8
            }
            if(areas9.length > 0 && areas10.length == 0){
                n = 9
            }
            if(areas10.length > 0 && areas11.length == 0){
                n = 10
            }
            if(areas11.length > 0 && areas12.length == 0){
                n = 11
            }
            if(areas12.length > 0 && areas13.length == 0){
                n = 12
            }
            if(areas13.length > 0 && areas14.length == 0){
                n = 13
            }
            if(areas14.length > 0 && areas15.length == 0){
                n = 14
            }
            if(areas15.length > 0 && areas16.length == 0){
                n = 15
            }
            if(areas16.length > 0 && areas17.length == 0){
                n = 16
            }
            if(areas17.length > 0 && areas18.length == 0){
                n = 17
            }
            if(areas18.length > 0 && areas19.length == 0){
                n = 18
            }
            if(areas19.length > 0 && areas20.length == 0){
                n = 19
            }
            if(areas20.length > 0){
                n = 20
            }
            let isAllAreasCovered = true
            for(let i = 1; i < n + 1; i++){
                if(eval("cellRows" + i).length == 0){
                    isAllAreasCovered = false
                }
            }
            if(isAllAreasCovered){
                setIsPDFGenerating(true)
            }
            else{alert("виконайте накладання для всіх площин")}
        }
    }
    React.useEffect(() => {
        if(isGridsReady == true){
            let n: any;
            if(areas1.length > 0 && areas2.length == 0){
                n = 1
            }
            if(areas2.length > 0 && areas3.length == 0){
                n = 2
            }
            if(areas3.length > 0 && areas4.length == 0){
                n = 3
            }
            if(areas4.length > 0 && areas5.length == 0){
                n = 4
            }
            if(areas5.length > 0 && areas6.length == 0){
                n = 5
            }
            if(areas6.length > 0 && areas7.length == 0){
                n = 6
            }
            if(areas7.length > 0 && areas8.length == 0){
                n = 7
            }
            if(areas8.length > 0 && areas9.length == 0){
                n = 8
            }
            if(areas9.length > 0 && areas10.length == 0){
                n = 9
            }
            if(areas10.length > 0 && areas11.length == 0){
                n = 10
            }
            if(areas11.length > 0 && areas12.length == 0){
                n = 11
            }
            if(areas12.length > 0 && areas13.length == 0){
                n = 12
            }
            if(areas13.length > 0 && areas14.length == 0){
                n = 13
            }
            if(areas14.length > 0 && areas15.length == 0){
                n = 14
            }
            if(areas15.length > 0 && areas16.length == 0){
                n = 15
            }
            if(areas16.length > 0 && areas17.length == 0){
                n = 16
            }
            if(areas17.length > 0 && areas18.length == 0){
                n = 17
            }
            if(areas18.length > 0 && areas19.length == 0){
                n = 18
            }
            if(areas19.length > 0 && areas20.length == 0){
                n = 19
            }
            if(areas20.length > 0){
                n = 20
            }
            const pdf = new jsPDF();
            // @ts-ignore
            html2canvas(document.getElementById(`grid`))
            .then((canvas) => {
            pdf.text(`all areas`, 85, 10)
            const figureImg = canvas.toDataURL('image/png');
            pdf.addImage(figureImg, 'JPEG', 0, 30, 200, 100);
            pdf.addPage()
            });
            for(let i = 1; i < n + 1; i++){
                // @ts-ignore
                html2canvas(document.getElementById(`grid-${i}`))
                .then((canvas) => {
                pdf.text(`area ${i}`, 85, 10)
                const figureImg = canvas.toDataURL('image/png');
                pdf.addImage(figureImg, 'JPEG', 0, 30, 200, 100);
                });
                // @ts-ignore
                html2canvas(document.getElementById(`results-${i}`))
                .then((canvas) => {
                const resultsImg = canvas.toDataURL('image/png');
                pdf.addImage(resultsImg, 'JPEG', 0, 130, 60, 100);
                if(i === n){
                    pdf.save("Tile.pdf")
                    setIsPDFGenerating(false)
                }else{
                    pdf.addPage()
                }
                }); 
            }
        }
    }, [isGridsReady]);

    const openModal = () => {
        if(totalSquare > 0){
            setResultModal(true)
        }
        else{
            alert("жодна фігура не накладена")
        }
    }

    return(
        <>
            <div className="result-wrapper">
                <div className="result-header">
                    <span>Опис розмірів</span>
                    <button className="button" onClick={openModal} ><img src={File} alt="переглянути всі розміри" title="переглянути всі розміри" /></button>
                    <button className="button" onClick={pdfGenerate}><img src={File} alt="згенерувати pdf файл" title="згенерувати pdf файл" /></button>
                </div>
                {
                    calcResult !== null && appContext.state.editedMode === 4 ?
                    <div id="results">
                        <p className="active-text">Площа фігури {(calcResult[0]).toFixed(2)} м2.</p>
                        <p className="active-text">Висота фігури {(calcResult[7]).toFixed(2)} м.</p>
                        <p className="active-text">Загальна площа листів {(calcResult[1]).toFixed(2)} м2.</p>
                        <p className="active-text">Корисна площа листів {calcResult[2]} м2.</p>
                        <p className="active-text">Відходи {calcResult[6]} м2. - {(calcResult[6] / calcResult[1] * 100).toFixed(2)}%</p>
                        <div>
                            {leaves}
                        </div>
                    </div>
                : null
                }
                {
                    appContext.state.isEditedMode && areasCount == 0 ?
                    <div id="results-0">
                        <p>Площа фігур {totalSquare} м2.</p>
                        <p>Загальна площа листів {totalLeavesSquare} м2.</p>
                        <p>Корисна площа листів {totalLeavesUsfSquare} м2.</p>
                        <p>Відходи {totalLeavesWaste} м2.</p>
                        <div>
                            {totalLeaves}
                        </div>
                    </div>
                : null
                }
                {
                    (results1.length > 0 && isPDFGenerating) || (areasCount == 1 && appContext.state.editedMode === 4 && results1.length > 0) ?
                    <div id="results-1">
                        <p>Площа фігури {(results1[0]).toFixed(2)} м2.</p>
                        <p>Висота фігури {(results1[7]).toFixed(2)} м.</p>
                        <p>Загальна площа листів {(results1[1]).toFixed(2)} м2.</p>
                        <p>Корисна площа листів {results1[2]} м2.</p>
                        <p>Відходи {results1[6]} м2. - {(results1[6] / results1[1] * 100).toFixed(2)}%</p>
                        <div>
                            {leaves1}
                        </div>
                    </div>
                : null
                }
                {
                    (results2.length > 0 && isPDFGenerating) || (areasCount == 2 && appContext.state.editedMode === 4 && results2.length > 0) ?
                    <div id="results-2">
                        <p>Площа фігури {(results2[0]).toFixed(2)} м2.</p>
                        <p>Висота фігури {(results2[7]).toFixed(2)} м.</p>
                        <p>Загальна площа листів {(results2[1]).toFixed(2)} м2.</p>
                        <p>Корисна площа листів {results2[2]} м2.</p>
                        <p>Відходи {results2[6]} м2. - {(results2[6] / results2[1] * 100).toFixed(2)}%</p>
                        <div>
                            {leaves2}
                        </div>
                    </div>
                : null
                }
                {
                    results3.length > 0 && isPDFGenerating || (areasCount == 3 && appContext.state.editedMode === 4) && results3.length > 0?
                    <div id="results-3">
                        <p>Площа фігури {(results3[0]).toFixed(2)} м2.</p>
                        <p>Висота фігури {(results3[7]).toFixed(2)} м.</p>
                        <p>Загальна площа листів {(results3[1]).toFixed(2)} м2.</p>
                        <p>Корисна площа листів {results3[2]} м2.</p>
                        <p>Відходи {results3[6]} м2. - {(results3[6] / results3[1] * 100).toFixed(2)}%</p>
                        <div>
                            {leaves3}
                        </div>
                    </div>
                : null
                }
                {
                    results4.length > 0 && isPDFGenerating || (areasCount == 4 && appContext.state.editedMode === 4) && results4.length > 0?
                    <div id="results-4">
                        <p>Площа фігури {(results4[0]).toFixed(2)} м2.</p>
                        <p>Висота фігури {(results4[7]).toFixed(2)} м.</p>
                        <p>Загальна площа листів {(results4[1]).toFixed(2)} м2.</p>
                        <p>Корисна площа листів {results4[2]} м2.</p>
                        <p>Відходи {results4[6]} м2. - {(results4[6] / results4[1] * 100).toFixed(2)}%</p>
                        <div>
                            {leaves4}
                        </div>
                    </div>
                : null
                }
                {
                    results5.length > 0 && isPDFGenerating || (areasCount == 5 && appContext.state.editedMode === 4 && results5.length > 0)?
                    <div id="results-5">
                        <p>Площа фігури {(results5[0]).toFixed(2)} м2.</p>
                        <p>Висота фігури {(results5[7]).toFixed(2)} м.</p>
                        <p>Загальна площа листів {(results5[1]).toFixed(2)} м2.</p>
                        <p>Корисна площа листів {results5[2]} м2.</p>
                        <p>Відходи {results5[6]} м2. - {(results5[6] / results5[1] * 100).toFixed(2)}%</p>
                        <div>
                            {leaves5}
                        </div>
                    </div>
                : null
                }
                {
                    results6.length > 0 && isPDFGenerating || (areasCount == 6 && appContext.state.editedMode === 4 && results6.length > 0)?
                    <div id="results-6">
                        <p>Площа фігури {(results6[0]).toFixed(2)} м2.</p>
                        <p>Висота фігури {(results6[7]).toFixed(2)} м.</p>
                        <p>Загальна площа листів {(results6[1]).toFixed(2)} м2.</p>
                        <p>Корисна площа листів {results6[2]} м2.</p>
                        <p>Відходи {results6[6]} м2. - {(results6[6] / results6[1] * 100).toFixed(2)}%</p>
                        <div>
                            {leaves6}
                        </div>
                    </div>
                : null
                }
                {
                    results7.length > 0 && isPDFGenerating || (areasCount == 7 && appContext.state.editedMode === 4 && results7.length > 0)?
                    <div id="results-7">
                        <p>Площа фігури {(results7[0]).toFixed(2)} м2.</p>
                        <p>Висота фігури {(results7[7]).toFixed(2)} м.</p>
                        <p>Загальна площа листів {(results7[1]).toFixed(2)} м2.</p>
                        <p>Корисна площа листів {results7[2]} м2.</p>
                        <p>Відходи {results7[6]} м2. - {(results7[6] / results7[1] * 100).toFixed(2)}%</p>
                        <div>
                            {leaves7}
                        </div>
                    </div>
                : null
                }
                {
                    results8.length > 0 && isPDFGenerating || (areasCount == 8 && appContext.state.editedMode === 4 && results8.length > 0)?
                    <div id="results-8">
                        <p>Площа фігури {(results8[0]).toFixed(2)} м2.</p>
                        <p>Висота фігури {(results8[7]).toFixed(2)} м.</p>
                        <p>Загальна площа листів {(results8[1]).toFixed(2)} м2.</p>
                        <p>Корисна площа листів {results8[2]} м2.</p>
                        <p>Відходи {results8[6]} м2. - {(results8[6] / results8[1] * 100).toFixed(2)}%</p>
                        <div>
                            {leaves8}
                        </div>
                    </div>
                : null
                }
                {
                    results9.length > 0 && isPDFGenerating || (areasCount == 9 && appContext.state.editedMode === 4 && results9.length > 0)?
                    <div id="results-9">
                        <p>Площа фігури {(results9[0]).toFixed(2)} м2.</p>
                        <p>Висота фігури {(results9[7]).toFixed(2)} м.</p>
                        <p>Загальна площа листів {(results9[1]).toFixed(2)} м2.</p>
                        <p>Корисна площа листів {results9[2]} м2.</p>
                        <p>Відходи {results9[6]} м2. - {(results9[6] / results9[1] * 100).toFixed(2)}%</p>
                        <div>
                            {leaves9}
                        </div>
                    </div>
                : null
                }
                {
                    results10.length > 0 && isPDFGenerating || (areasCount == 10 && appContext.state.editedMode === 4 && results10.length > 0)?
                    <div id="results-10">
                        <p>Площа фігури {(results10[0]).toFixed(2)} м2.</p>
                        <p>Висота фігури {(results10[7]).toFixed(2)} м.</p>
                        <p>Загальна площа листів {(results10[1]).toFixed(2)} м2.</p>
                        <p>Корисна площа листів {results10[2]} м2.</p>
                        <p>Відходи {results10[6]} м2. - {(results10[6] / results10[1] * 100).toFixed(2)}%</p>
                        <div>
                            {leaves10}
                        </div>
                    </div>
                : null
                }
                {
                    results11.length > 0 && isPDFGenerating || (areasCount == 11 && appContext.state.editedMode === 4 && results11.length > 0)?
                    <div id="results-11">
                        <p>Площа фігури {(results11[0]).toFixed(2)} м2.</p>
                        <p>Висота фігури {(results11[7]).toFixed(2)} м.</p>
                        <p>Загальна площа листів {(results11[1]).toFixed(2)} м2.</p>
                        <p>Корисна площа листів {results11[2]} м2.</p>
                        <p>Відходи {results11[6]} м2. - {(results11[6] / results11[1] * 100).toFixed(2)}%</p>
                        <div>
                            {leaves11}
                        </div>
                    </div>
                : null
                }
                {
                    results12.length > 0 && isPDFGenerating || (areasCount == 12 && appContext.state.editedMode === 4 && results12.length > 0)?
                    <div id="results-12">
                        <p>Площа фігури {(results12[0]).toFixed(2)} м2.</p>
                        <p>Висота фігури {(results12[7]).toFixed(2)} м.</p>
                        <p>Загальна площа листів {(results12[1]).toFixed(2)} м2.</p>
                        <p>Корисна площа листів {results12[2]} м2.</p>
                        <p>Відходи {results12[6]} м2. - {(results12[6] / results12[1] * 100).toFixed(2)}%</p>
                        <div>
                            {leaves12}
                        </div>
                    </div>
                : null
                }
                {
                    results13.length > 0 && isPDFGenerating || (areasCount == 13 && appContext.state.editedMode === 4 && results13.length > 0)?
                    <div id="results-13">
                        <p>Площа фігури {(results13[0]).toFixed(2)} м2.</p>
                        <p>Висота фігури {(results13[7]).toFixed(2)} м.</p>
                        <p>Загальна площа листів {(results13[1]).toFixed(2)} м2.</p>
                        <p>Корисна площа листів {results13[2]} м2.</p>
                        <p>Відходи {results13[6]} м2. - {(results13[6] / results13[1] * 100).toFixed(2)}%</p>
                        <div>
                            {leaves13}
                        </div>
                    </div>
                : null
                }
                {
                    results14.length > 0 && isPDFGenerating || (areasCount == 14 && appContext.state.editedMode === 4 && results14.length > 0)?
                    <div id="results-14">
                        <p>Площа фігури {(results14[0]).toFixed(2)} м2.</p>
                        <p>Висота фігури {(results14[7]).toFixed(2)} м.</p>
                        <p>Загальна площа листів {(results14[1]).toFixed(2)} м2.</p>
                        <p>Корисна площа листів {results14[2]} м2.</p>
                        <p>Відходи {results14[6]} м2. - {(results14[6] / results14[1] * 100).toFixed(2)}%</p>
                        <div>
                            {leaves14}
                        </div>
                    </div>
                : null
                }
                {
                    results15.length > 0 && isPDFGenerating || (areasCount == 15 && appContext.state.editedMode === 4 && results15.length > 0)?
                    <div id="results-15">
                        <p>Площа фігури {(results15[0]).toFixed(2)} м2.</p>
                        <p>Висота фігури {(results15[7]).toFixed(2)} м.</p>
                        <p>Загальна площа листів {(results15[1]).toFixed(2)} м2.</p>
                        <p>Корисна площа листів {results15[2]} м2.</p>
                        <p>Відходи {results15[6]} м2. - {(results15[6] / results15[1] * 100).toFixed(2)}%</p>
                        <div>
                            {leaves15}
                        </div>
                    </div>
                : null
                }
                {
                    results16.length > 0 && isPDFGenerating || (areasCount == 16 && appContext.state.editedMode === 4 && results16.length > 0)?
                    <div id="results-16">
                        <p>Площа фігури {(results16[0]).toFixed(2)} м2.</p>
                        <p>Висота фігури {(results16[7]).toFixed(2)} м.</p>
                        <p>Загальна площа листів {(results16[1]).toFixed(2)} м2.</p>
                        <p>Корисна площа листів {results16[2]} м2.</p>
                        <p>Відходи {results16[6]} м2. - {(results16[6] / results16[1] * 100).toFixed(2)}%</p>
                        <div>
                            {leaves16}
                        </div>
                    </div>
                : null
                }
                {
                    results17.length > 0 && isPDFGenerating || (areasCount == 17 && appContext.state.editedMode === 4 && results17.length > 0)?
                    <div id="results-17">
                        <p>Площа фігури {(results17[0]).toFixed(2)} м2.</p>
                        <p>Висота фігури {(results17[7]).toFixed(2)} м.</p>
                        <p>Загальна площа листів {(results17[1]).toFixed(2)} м2.</p>
                        <p>Корисна площа листів {results17[2]} м2.</p>
                        <p>Відходи {results17[6]} м2. - {(results17[6] / results17[1] * 100).toFixed(2)}%</p>
                        <div>
                            {leaves17}
                        </div>
                    </div>
                : null
                }
                {
                    results18.length > 0 && isPDFGenerating || (areasCount == 18 && appContext.state.editedMode === 4 && results18.length > 0)?
                    <div id="results-18">
                        <p>Площа фігури {(results18[0]).toFixed(2)} м2.</p>
                        <p>Висота фігури {(results18[7]).toFixed(2)} м.</p>
                        <p>Загальна площа листів {(results18[1]).toFixed(2)} м2.</p>
                        <p>Корисна площа листів {results18[2]} м2.</p>
                        <p>Відходи {results18[6]} м2. - {(results18[6] / results18[1] * 100).toFixed(2)}%</p>
                        <div>
                            {leaves18}
                        </div>
                    </div>
                : null
                }
                {
                    results19.length > 0 && isPDFGenerating || (areasCount == 19 && appContext.state.editedMode === 4 && results19.length > 0)?
                    <div id="results-19">
                        <p>Площа фігури {(results19[0]).toFixed(2)} м2.</p>
                        <p>Висота фігури {(results19[7]).toFixed(2)} м.</p>
                        <p>Загальна площа листів {(results19[1]).toFixed(2)} м2.</p>
                        <p>Корисна площа листів {results19[2]} м2.</p>
                        <p>Відходи {results19[6]} м2. - {(results19[6] / results19[1] * 100).toFixed(2)}%</p>
                        <div>
                            {leaves19}
                        </div>
                    </div>
                : null
                }
                {
                    results20.length > 0 && isPDFGenerating || (areasCount == 20 && appContext.state.editedMode === 4 && results20.length > 0)?
                    <div id="results-20">
                        <p>Площа фігури {(results20[0]).toFixed(2)} м2.</p>
                        <p>Висота фігури {(results20[7]).toFixed(2)} м.</p>
                        <p>Загальна площа листів {(results20[1]).toFixed(2)} м2.</p>
                        <p>Корисна площа листів {results20[2]} м2.</p>
                        <p>Відходи {results20[6]} м2. - {(results20[6] / results20[1] * 100).toFixed(2)}%</p>
                        <div>
                            {leaves20}
                        </div>
                    </div>
                : null
                }
                {resultModal && <ResultModalBlock resultModal={resultModal} setResultModal={setResultModal} dictionaryItem={dictionary[0]} totalSquare={totalSquare} totalLeavesSquare={totalLeavesSquare} 
                totalLeavesUsfSquare={totalLeavesUsfSquare} totalLeavesWaste={totalLeavesWaste} totalLeaves={totalLeaves} totalLeavesSizes={totalLeavesSizes} totalLeavesValue={totalLeavesValue} totalLinearMeters={totalLinearMeters}
                results1={results1} results2={results2} results3={results3} results4={results4} results5={results5} results6={results6} results7={results7} results8={results8} results9={results9} results10={results10}
                results11={results11} results12={results12} results13={results13} results14={results14} results15={results15} results16={results16} results17={results17} results18={results18} results19={results19}
                results20={results20}/>}
            </div>
        </>
    )
}

export default ResultBlock;