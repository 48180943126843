import React, {FC, useState} from "react";
import "./LoaderForPdf.scss"
import { DotSpinner } from '@uiball/loaders'

interface IAddTableRowDialogProps {
    
}

const LoaderForPdf: FC<IAddTableRowDialogProps> = () => {

    return (
        <>
            {/* Модалка для додавання категорії */}

              <div className="loader-wrapper">
                    <p>Генерування pdf файлу</p>
                    <DotSpinner 
                    size={100}
                    speed={0.9} 
                    color="rgba(147, 214, 41, 0.5)"
                    />
              </div>
        </>
    )
}

export default LoaderForPdf;