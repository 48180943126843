import React, {FC} from "react";
import {
    Routes,
    Route,
    useRoutes
} from "react-router-dom";

import Calculator from "./Calculator/Calculator";
import Dictionary from "./Dictionary/Dictionary";
import Categories from "./Dictionary/Categories/Categories";
import Table from "./Dictionary/Table/Table";
import MainPage from "./MainPage/MainPage";

// import {
//     Routes,
//     Route
// } from "react-router-dom";

// import Calculator from "./Calculator/Calculator";
// import Dictionary from "./Dictionary/Dictionary";
// import Categories from "./Categories/Categories";
// import CategoryInfo from "./CategoryInfo/CategoryInfo";

// import Home from "./PageComponents/Home/Home";
// import Basket from "./PageComponents/Basket/Basket";
// import CatalogProducts from "./PageComponents/CatalogProducts/CatalogProducts";
// import Products from "./PageComponents/CatalogProducts/Categories/Products/Products";
// import Categories from "./PageComponents/CatalogProducts/Categories/Categories";
// import ProductInfo from "./PageComponents/CatalogProducts/ProductInfo/ProductInfo";
// import ProductList from "./PageComponents/CatalogProducts/Categories/Products/ProductList/ProductList";

interface IProduct {
    id: number,
    name: string,
    imageUrl: string,
    itemPrice: string;
    categoryId: number
}

interface IProducts {
    fruits: IProduct[];
    vegetables: IProduct[];
    meats: IProduct[];
    milk: IProduct[];
    spices: IProduct[];
    cereals: IProduct[];
    drinks: IProduct[];
}

interface Props {
    activeTab: any;
    setActiveTab: any;
}

// eslint-disable-next-line no-empty-pattern
const Routers: FC<Props> = ({activeTab, setActiveTab}) => {


    const [points, setPoints] = React.useState([]); 
    const [pointsForTestFigure, setPointsForTestFigure] = React.useState([]);
    const [lettersForTestFigure, setLettersForTestFigure] = React.useState([]);
    const [isReadyFigureBuild, setIsReadyFigureBuild] = React.useState(false); 
    const [lines, setLines] = React.useState([]);
    const [linesForTestFigure, setLinesForTestFigure] = React.useState([]);
    const [addLinesForTestFigure, setAddLinesForTestFigure] = React.useState([]);
    const [addLinesForTestFigure1, setAddLinesForTestFigure1] = React.useState([]);
    const [addLinesForTestFigure2, setAddLinesForTestFigure2] = React.useState([]);
    const [addLinesForTestFigure3, setAddLinesForTestFigure3] = React.useState([]);
    const [isLineClosed, setIsLineClosed] = React.useState([]);
    const [cutFigures, setCutFigures] = React.useState([]);
    const [cutFiguresLines, setCutFiguresLines] = React.useState([]);
    const [start, setStart] = React.useState(false);

    const [areas1, setAreas1] = React.useState([]);
    const [areas2, setAreas2] = React.useState([]);
    const [areas3, setAreas3] = React.useState([]);
    const [areas4, setAreas4] = React.useState([]);
    const [areas5, setAreas5] = React.useState([]);
    const [areas6, setAreas6] = React.useState([]);
    const [areas7, setAreas7] = React.useState([]);
    const [areas8, setAreas8] = React.useState([]);
    const [areas9, setAreas9] = React.useState([]);
    const [areas10, setAreas10] = React.useState([]);
    const [areas11, setAreas11] = React.useState([]);
    const [areas12, setAreas12] = React.useState([]);
    const [areas13, setAreas13] = React.useState([]);
    const [areas14, setAreas14] = React.useState([]);
    const [areas15, setAreas15] = React.useState([]);
    const [areas16, setAreas16] = React.useState([]);
    const [areas17, setAreas17] = React.useState([]);
    const [areas18, setAreas18] = React.useState([]);
    const [areas19, setAreas19] = React.useState([]);
    const [areas20, setAreas20] = React.useState([]);
    const [areas21, setAreas21] = React.useState([]);
    const [areas22, setAreas22] = React.useState([]);
    const [areas23, setAreas23] = React.useState([]);
    const [areas24, setAreas24] = React.useState([]);
    const [areas25, setAreas25] = React.useState([]);
    const [areas26, setAreas26] = React.useState([]);
    const [areas27, setAreas27] = React.useState([]);
    const [areas28, setAreas28] = React.useState([]);
    const [areas29, setAreas29] = React.useState([]);
    const [areas30, setAreas30] = React.useState([]);
    const [areas31, setAreas31] = React.useState([]);
    const [areas32, setAreas32] = React.useState([]);
    const [areas33, setAreas33] = React.useState([]);
    const [areas34, setAreas34] = React.useState([]);
    const [areas35, setAreas35] = React.useState([]);
    const [areas36, setAreas36] = React.useState([]);
    const [areas37, setAreas37] = React.useState([]);
    const [areas38, setAreas38] = React.useState([]);
    const [areas39, setAreas39] = React.useState([]);
    const [areas40, setAreas40] = React.useState([]);
    const [areas41, setAreas41] = React.useState([]);
    const [areas42, setAreas42] = React.useState([]);
    const [areas43, setAreas43] = React.useState([]);
    const [areas44, setAreas44] = React.useState([]);
    const [areas45, setAreas45] = React.useState([]);
    const [areas46, setAreas46] = React.useState([]);
    const [areas47, setAreas47] = React.useState([]);
    const [areas48, setAreas48] = React.useState([]);
    const [areas49, setAreas49] = React.useState([]);
    const [areas50, setAreas50] = React.useState([]);



    const [lines1, setLines1] = React.useState([]);
    const [lines2, setLines2] = React.useState([]);
    const [lines3, setLines3] = React.useState([]);
    const [lines4, setLines4] = React.useState([]);
    const [lines5, setLines5] = React.useState([]);
    const [lines6, setLines6] = React.useState([]);
    const [lines7, setLines7] = React.useState([]);
    const [lines8, setLines8] = React.useState([]);
    const [lines9, setLines9] = React.useState([]);
    const [lines10, setLines10] = React.useState([]);
    const [lines11, setLines11] = React.useState([]);
    const [lines12, setLines12] = React.useState([]);
    const [lines13, setLines13] = React.useState([]);
    const [lines14, setLines14] = React.useState([]);
    const [lines15, setLines15] = React.useState([]);
    const [lines16, setLines16] = React.useState([]);
    const [lines17, setLines17] = React.useState([]);
    const [lines18, setLines18] = React.useState([]);
    const [lines19, setLines19] = React.useState([]);
    const [lines20, setLines20] = React.useState([]);
    const [lines21, setLines21] = React.useState([]);
    const [lines22, setLines22] = React.useState([]);
    const [lines23, setLines23] = React.useState([]);
    const [lines24, setLines24] = React.useState([]);
    const [lines25, setLines25] = React.useState([]);
    const [lines26, setLines26] = React.useState([]);
    const [lines27, setLines27] = React.useState([]);
    const [lines28, setLines28] = React.useState([]);
    const [lines29, setLines29] = React.useState([]);
    const [lines30, setLines30] = React.useState([]);
    const [lines31, setLines31] = React.useState([]);
    const [lines32, setLines32] = React.useState([]);
    const [lines33, setLines33] = React.useState([]);
    const [lines34, setLines34] = React.useState([]);
    const [lines35, setLines35] = React.useState([]);
    const [lines36, setLines36] = React.useState([]);
    const [lines37, setLines37] = React.useState([]);
    const [lines38, setLines38] = React.useState([]);
    const [lines39, setLines39] = React.useState([]);
    const [lines40, setLines40] = React.useState([]);
    const [lines41, setLines41] = React.useState([]);
    const [lines42, setLines42] = React.useState([]);
    const [lines43, setLines43] = React.useState([]);
    const [lines44, setLines44] = React.useState([]);
    const [lines45, setLines45] = React.useState([]);
    const [lines46, setLines46] = React.useState([]);
    const [lines47, setLines47] = React.useState([]);
    const [lines48, setLines48] = React.useState([]);
    const [lines49, setLines49] = React.useState([]);
    const [lines50, setLines50] = React.useState([]);


    const [closedCuts1, setClosedCuts1] = React.useState([]);
    const [closedCuts2, setClosedCuts2] = React.useState([]);
    const [closedCuts3, setClosedCuts3] = React.useState([]);
    const [closedCuts4, setClosedCuts4] = React.useState([]);
    const [closedCuts5, setClosedCuts5] = React.useState([]);
    const [closedCuts6, setClosedCuts6] = React.useState([]);
    const [closedCuts7, setClosedCuts7] = React.useState([]);
    const [closedCuts8, setClosedCuts8] = React.useState([]);
    const [closedCuts9, setClosedCuts9] = React.useState([]);
    const [closedCuts10, setClosedCuts10] = React.useState([]);
    const [closedCuts11, setClosedCuts11] = React.useState([]);
    const [closedCuts12, setClosedCuts12] = React.useState([]);
    const [closedCuts13, setClosedCuts13] = React.useState([]);
    const [closedCuts14, setClosedCuts14] = React.useState([]);
    const [closedCuts15, setClosedCuts15] = React.useState([]);
    const [closedCuts16, setClosedCuts16] = React.useState([]);
    const [closedCuts17, setClosedCuts17] = React.useState([]);
    const [closedCuts18, setClosedCuts18] = React.useState([]);
    const [closedCuts19, setClosedCuts19] = React.useState([]);
    const [closedCuts20, setClosedCuts20] = React.useState([]);


    const [cuts1, setCuts1] = React.useState([]);
    const [cuts2, setCuts2] = React.useState([]);
    const [cuts3, setCuts3] = React.useState([]);
    const [cuts4, setCuts4] = React.useState([]);
    const [cuts5, setCuts5] = React.useState([]);
    const [cuts6, setCuts6] = React.useState([]);
    const [cuts7, setCuts7] = React.useState([]);
    const [cuts8, setCuts8] = React.useState([]);
    const [cuts9, setCuts9] = React.useState([]);
    const [cuts10, setCuts10] = React.useState([]);
    const [cuts11, setCuts11] = React.useState([]);
    const [cuts12, setCuts12] = React.useState([]);
    const [cuts13, setCuts13] = React.useState([]);
    const [cuts14, setCuts14] = React.useState([]);
    const [cuts15, setCuts15] = React.useState([]);
    const [cuts16, setCuts16] = React.useState([]);
    const [cuts17, setCuts17] = React.useState([]);
    const [cuts18, setCuts18] = React.useState([]);
    const [cuts19, setCuts19] = React.useState([]);
    const [cuts20, setCuts20] = React.useState([]);
    const [cuts21, setCuts21] = React.useState([]);
    const [cuts22, setCuts22] = React.useState([]);
    const [cuts23, setCuts23] = React.useState([]);
    const [cuts24, setCuts24] = React.useState([]);
    const [cuts25, setCuts25] = React.useState([]);
    const [cuts26, setCuts26] = React.useState([]);
    const [cuts27, setCuts27] = React.useState([]);
    const [cuts28, setCuts28] = React.useState([]);
    const [cuts29, setCuts29] = React.useState([]);
    const [cuts30, setCuts30] = React.useState([]);
    const [cuts31, setCuts31] = React.useState([]);
    const [cuts32, setCuts32] = React.useState([]);
    const [cuts33, setCuts33] = React.useState([]);
    const [cuts34, setCuts34] = React.useState([]);
    const [cuts35, setCuts35] = React.useState([]);
    const [cuts36, setCuts36] = React.useState([]);
    const [cuts37, setCuts37] = React.useState([]);
    const [cuts38, setCuts38] = React.useState([]);
    const [cuts39, setCuts39] = React.useState([]);
    const [cuts40, setCuts40] = React.useState([]);
    const [cuts41, setCuts41] = React.useState([]);
    const [cuts42, setCuts42] = React.useState([]);
    const [cuts43, setCuts43] = React.useState([]);
    const [cuts44, setCuts44] = React.useState([]);
    const [cuts45, setCuts45] = React.useState([]);
    const [cuts46, setCuts46] = React.useState([]);
    const [cuts47, setCuts47] = React.useState([]);
    const [cuts48, setCuts48] = React.useState([]);
    const [cuts49, setCuts49] = React.useState([]);
    const [cuts50, setCuts50] = React.useState([]);


    const [cutLines1, setCutLines1] = React.useState([]);
    const [cutLines2, setCutLines2] = React.useState([]);
    const [cutLines3, setCutLines3] = React.useState([]);
    const [cutLines4, setCutLines4] = React.useState([]);
    const [cutLines5, setCutLines5] = React.useState([]);
    const [cutLines6, setCutLines6] = React.useState([]);
    const [cutLines7, setCutLines7] = React.useState([]);
    const [cutLines8, setCutLines8] = React.useState([]);
    const [cutLines9, setCutLines9] = React.useState([]);
    const [cutLines10, setCutLines10] = React.useState([]);
    const [cutLines11, setCutLines11] = React.useState([]);
    const [cutLines12, setCutLines12] = React.useState([]);
    const [cutLines13, setCutLines13] = React.useState([]);
    const [cutLines14, setCutLines14] = React.useState([]);
    const [cutLines15, setCutLines15] = React.useState([]);
    const [cutLines16, setCutLines16] = React.useState([]);
    const [cutLines17, setCutLines17] = React.useState([]);
    const [cutLines18, setCutLines18] = React.useState([]);
    const [cutLines19, setCutLines19] = React.useState([]);
    const [cutLines20, setCutLines20] = React.useState([]);
    const [cutLines21, setCutLines21] = React.useState([]);
    const [cutLines22, setCutLines22] = React.useState([]);
    const [cutLines23, setCutLines23] = React.useState([]);
    const [cutLines24, setCutLines24] = React.useState([]);
    const [cutLines25, setCutLines25] = React.useState([]);
    const [cutLines26, setCutLines26] = React.useState([]);
    const [cutLines27, setCutLines27] = React.useState([]);
    const [cutLines28, setCutLines28] = React.useState([]);
    const [cutLines29, setCutLines29] = React.useState([]);
    const [cutLines30, setCutLines30] = React.useState([]);
    const [cutLines31, setCutLines31] = React.useState([]);
    const [cutLines32, setCutLines32] = React.useState([]);
    const [cutLines33, setCutLines33] = React.useState([]);
    const [cutLines34, setCutLines34] = React.useState([]);
    const [cutLines35, setCutLines35] = React.useState([]);
    const [cutLines36, setCutLines36] = React.useState([]);
    const [cutLines37, setCutLines37] = React.useState([]);
    const [cutLines38, setCutLines38] = React.useState([]);
    const [cutLines39, setCutLines39] = React.useState([]);
    const [cutLines40, setCutLines40] = React.useState([]);
    const [cutLines41, setCutLines41] = React.useState([]);
    const [cutLines42, setCutLines42] = React.useState([]);
    const [cutLines43, setCutLines43] = React.useState([]);
    const [cutLines44, setCutLines44] = React.useState([]);
    const [cutLines45, setCutLines45] = React.useState([]);
    const [cutLines46, setCutLines46] = React.useState([]);
    const [cutLines47, setCutLines47] = React.useState([]);
    const [cutLines48, setCutLines48] = React.useState([]);
    const [cutLines49, setCutLines49] = React.useState([]);
    const [cutLines50, setCutLines50] = React.useState([]);


    const [eaves1, setEaves1] = React.useState([]);
    const [eaves2, setEaves2] = React.useState([]);
    const [eaves3, setEaves3] = React.useState([]);
    const [eaves4, setEaves4] = React.useState([]);
    const [eaves5, setEaves5] = React.useState([]);
    const [eaves6, setEaves6] = React.useState([]);
    const [eaves7, setEaves7] = React.useState([]);
    const [eaves8, setEaves8] = React.useState([]);
    const [eaves9, setEaves9] = React.useState([]);
    const [eaves10, setEaves10] = React.useState([]);
    const [eaves11, setEaves11] = React.useState([]);
    const [eaves12, setEaves12] = React.useState([]);
    const [eaves13, setEaves13] = React.useState([]);
    const [eaves14, setEaves14] = React.useState([]);
    const [eaves15, setEaves15] = React.useState([]);
    const [eaves16, setEaves16] = React.useState([]);
    const [eaves17, setEaves17] = React.useState([]);
    const [eaves18, setEaves18] = React.useState([]);
    const [eaves19, setEaves19] = React.useState([]);
    const [eaves20, setEaves20] = React.useState([]);
    const [eaves21, setEaves21] = React.useState([]);
    const [eaves22, setEaves22] = React.useState([]);
    const [eaves23, setEaves23] = React.useState([]);
    const [eaves24, setEaves24] = React.useState([]);
    const [eaves25, setEaves25] = React.useState([]);
    const [eaves26, setEaves26] = React.useState([]);
    const [eaves27, setEaves27] = React.useState([]);
    const [eaves28, setEaves28] = React.useState([]);
    const [eaves29, setEaves29] = React.useState([]);
    const [eaves30, setEaves30] = React.useState([]);
    const [eaves31, setEaves31] = React.useState([]);
    const [eaves32, setEaves32] = React.useState([]);
    const [eaves33, setEaves33] = React.useState([]);
    const [eaves34, setEaves34] = React.useState([]);
    const [eaves35, setEaves35] = React.useState([]);
    const [eaves36, setEaves36] = React.useState([]);
    const [eaves37, setEaves37] = React.useState([]);
    const [eaves38, setEaves38] = React.useState([]);
    const [eaves39, setEaves39] = React.useState([]);
    const [eaves40, setEaves40] = React.useState([]);
    const [eaves41, setEaves41] = React.useState([]);
    const [eaves42, setEaves42] = React.useState([]);
    const [eaves43, setEaves43] = React.useState([]);
    const [eaves44, setEaves44] = React.useState([]);
    const [eaves45, setEaves45] = React.useState([]);
    const [eaves46, setEaves46] = React.useState([]);
    const [eaves47, setEaves47] = React.useState([]);
    const [eaves48, setEaves48] = React.useState([]);
    const [eaves49, setEaves49] = React.useState([]);
    const [eaves50, setEaves50] = React.useState([]);



    const [apex1, setApex1] = React.useState([]);
    const [apex2, setApex2] = React.useState([]);
    const [apex3, setApex3] = React.useState([]);
    const [apex4, setApex4] = React.useState([]);
    const [apex5, setApex5] = React.useState([]);
    const [apex6, setApex6] = React.useState([]);
    const [apex7, setApex7] = React.useState([]);
    const [apex8, setApex8] = React.useState([]);
    const [apex9, setApex9] = React.useState([]);
    const [apex10, setApex10] = React.useState([]);
    const [apex11, setApex11] = React.useState([]);
    const [apex12, setApex12] = React.useState([]);
    const [apex13, setApex13] = React.useState([]);
    const [apex14, setApex14] = React.useState([]);
    const [apex15, setApex15] = React.useState([]);
    const [apex16, setApex16] = React.useState([]);
    const [apex17, setApex17] = React.useState([]);
    const [apex18, setApex18] = React.useState([]);
    const [apex19, setApex19] = React.useState([]);
    const [apex20, setApex20] = React.useState([]);
    const [apex21, setApex21] = React.useState([]);
    const [apex22, setApex22] = React.useState([]);
    const [apex23, setApex23] = React.useState([]);
    const [apex24, setApex24] = React.useState([]);
    const [apex25, setApex25] = React.useState([]);
    const [apex26, setApex26] = React.useState([]);
    const [apex27, setApex27] = React.useState([]);
    const [apex28, setApex28] = React.useState([]);
    const [apex29, setApex29] = React.useState([]);
    const [apex30, setApex30] = React.useState([]);
    const [apex31, setApex31] = React.useState([]);
    const [apex32, setApex32] = React.useState([]);
    const [apex33, setApex33] = React.useState([]);
    const [apex34, setApex34] = React.useState([]);
    const [apex35, setApex35] = React.useState([]);
    const [apex36, setApex36] = React.useState([]);
    const [apex37, setApex37] = React.useState([]);
    const [apex38, setApex38] = React.useState([]);
    const [apex39, setApex39] = React.useState([]);
    const [apex40, setApex40] = React.useState([]);
    const [apex41, setApex41] = React.useState([]);
    const [apex42, setApex42] = React.useState([]);
    const [apex43, setApex43] = React.useState([]);
    const [apex44, setApex44] = React.useState([]);
    const [apex45, setApex45] = React.useState([]);
    const [apex46, setApex46] = React.useState([]);
    const [apex47, setApex47] = React.useState([]);
    const [apex48, setApex48] = React.useState([]);
    const [apex49, setApex49] = React.useState([]);
    const [apex50, setApex50] = React.useState([]);



    const [isLinesClosed1, setIsLinesClosed1] = React.useState(false);
    const [isLinesClosed2, setIsLinesClosed2] = React.useState(false);
    const [isLinesClosed3, setIsLinesClosed3] = React.useState(false);
    const [isLinesClosed4, setIsLinesClosed4] = React.useState(false);
    const [isLinesClosed5, setIsLinesClosed5] = React.useState(false);
    const [isLinesClosed6, setIsLinesClosed6] = React.useState(false);
    const [isLinesClosed7, setIsLinesClosed7] = React.useState(false);
    const [isLinesClosed8, setIsLinesClosed8] = React.useState(false);
    const [isLinesClosed9, setIsLinesClosed9] = React.useState(false);
    const [isLinesClosed10, setIsLinesClosed10] = React.useState(false);
    const [isLinesClosed11, setIsLinesClosed11] = React.useState(false);
    const [isLinesClosed12, setIsLinesClosed12] = React.useState(false);
    const [isLinesClosed13, setIsLinesClosed13] = React.useState(false);
    const [isLinesClosed14, setIsLinesClosed14] = React.useState(false);
    const [isLinesClosed15, setIsLinesClosed15] = React.useState(false);
    const [isLinesClosed16, setIsLinesClosed16] = React.useState(false);
    const [isLinesClosed17, setIsLinesClosed17] = React.useState(false);
    const [isLinesClosed18, setIsLinesClosed18] = React.useState(false);
    const [isLinesClosed19, setIsLinesClosed19] = React.useState(false);
    const [isLinesClosed20, setIsLinesClosed20] = React.useState(false);
    const [isLinesClosed21, setIsLinesClosed21] = React.useState(false);
    const [isLinesClosed22, setIsLinesClosed22] = React.useState(false);
    const [isLinesClosed23, setIsLinesClosed23] = React.useState(false);
    const [isLinesClosed24, setIsLinesClosed24] = React.useState(false);
    const [isLinesClosed25, setIsLinesClosed25] = React.useState(false);
    const [isLinesClosed26, setIsLinesClosed26] = React.useState(false);
    const [isLinesClosed27, setIsLinesClosed27] = React.useState(false);
    const [isLinesClosed28, setIsLinesClosed28] = React.useState(false);
    const [isLinesClosed29, setIsLinesClosed29] = React.useState(false);
    const [isLinesClosed30, setIsLinesClosed30] = React.useState(false);
    const [isLinesClosed31, setIsLinesClosed31] = React.useState(false);
    const [isLinesClosed32, setIsLinesClosed32] = React.useState(false);
    const [isLinesClosed33, setIsLinesClosed33] = React.useState(false);
    const [isLinesClosed34, setIsLinesClosed34] = React.useState(false);
    const [isLinesClosed35, setIsLinesClosed35] = React.useState(false);
    const [isLinesClosed36, setIsLinesClosed36] = React.useState(false);
    const [isLinesClosed37, setIsLinesClosed37] = React.useState(false);
    const [isLinesClosed38, setIsLinesClosed38] = React.useState(false);
    const [isLinesClosed39, setIsLinesClosed39] = React.useState(false);
    const [isLinesClosed40, setIsLinesClosed40] = React.useState(false);
    const [isLinesClosed41, setIsLinesClosed41] = React.useState(false);
    const [isLinesClosed42, setIsLinesClosed42] = React.useState(false);
    const [isLinesClosed43, setIsLinesClosed43] = React.useState(false);
    const [isLinesClosed44, setIsLinesClosed44] = React.useState(false);
    const [isLinesClosed45, setIsLinesClosed45] = React.useState(false);
    const [isLinesClosed46, setIsLinesClosed46] = React.useState(false);
    const [isLinesClosed47, setIsLinesClosed47] = React.useState(false);
    const [isLinesClosed48, setIsLinesClosed48] = React.useState(false);
    const [isLinesClosed49, setIsLinesClosed49] = React.useState(false);
    const [isLinesClosed50, setIsLinesClosed50] = React.useState(false);


    const [results1, setResults1] = React.useState([]);
    const [results2, setResults2] = React.useState([]);
    const [results3, setResults3] = React.useState([]);
    const [results4, setResults4] = React.useState([]);
    const [results5, setResults5] = React.useState([]);
    const [results6, setResults6] = React.useState([]);
    const [results7, setResults7] = React.useState([]);
    const [results8, setResults8] = React.useState([]);
    const [results9, setResults9] = React.useState([]);
    const [results10, setResults10] = React.useState([]);
    const [results11, setResults11] = React.useState([]);
    const [results12, setResults12] = React.useState([]);
    const [results13, setResults13] = React.useState([]);
    const [results14, setResults14] = React.useState([]);
    const [results15, setResults15] = React.useState([]);
    const [results16, setResults16] = React.useState([]);
    const [results17, setResults17] = React.useState([]);
    const [results18, setResults18] = React.useState([]);
    const [results19, setResults19] = React.useState([]);
    const [results20, setResults20] = React.useState([]);
    const [results21, setResults21] = React.useState([]);
    const [results22, setResults22] = React.useState([]);
    const [results23, setResults23] = React.useState([]);
    const [results24, setResults24] = React.useState([]);
    const [results25, setResults25] = React.useState([]);
    const [results26, setResults26] = React.useState([]);
    const [results27, setResults27] = React.useState([]);
    const [results28, setResults28] = React.useState([]);
    const [results29, setResults29] = React.useState([]);
    const [results30, setResults30] = React.useState([]);
    const [results31, setResults31] = React.useState([]);
    const [results32, setResults32] = React.useState([]);
    const [results33, setResults33] = React.useState([]);
    const [results34, setResults34] = React.useState([]);
    const [results35, setResults35] = React.useState([]);
    const [results36, setResults36] = React.useState([]);
    const [results37, setResults37] = React.useState([]);
    const [results38, setResults38] = React.useState([]);
    const [results39, setResults39] = React.useState([]);
    const [results40, setResults40] = React.useState([]);
    const [results41, setResults41] = React.useState([]);
    const [results42, setResults42] = React.useState([]);
    const [results43, setResults43] = React.useState([]);
    const [results44, setResults44] = React.useState([]);
    const [results45, setResults45] = React.useState([]);
    const [results46, setResults46] = React.useState([]);
    const [results47, setResults47] = React.useState([]);
    const [results48, setResults48] = React.useState([]);
    const [results49, setResults49] = React.useState([]);
    const [results50, setResults50] = React.useState([]);

    const [cellRows1, setCellRows1] = React.useState([]);
    const [cellRows2, setCellRows2] = React.useState([]);
    const [cellRows3, setCellRows3] = React.useState([]);
    const [cellRows4, setCellRows4] = React.useState([]);
    const [cellRows5, setCellRows5] = React.useState([]);
    const [cellRows6, setCellRows6] = React.useState([]);
    const [cellRows7, setCellRows7] = React.useState([]);
    const [cellRows8, setCellRows8] = React.useState([]);
    const [cellRows9, setCellRows9] = React.useState([]);
    const [cellRows10, setCellRows10] = React.useState([]);
    const [cellRows11, setCellRows11] = React.useState([]);
    const [cellRows12, setCellRows12] = React.useState([]);
    const [cellRows13, setCellRows13] = React.useState([]);
    const [cellRows14, setCellRows14] = React.useState([]);
    const [cellRows15, setCellRows15] = React.useState([]);
    const [cellRows16, setCellRows16] = React.useState([]);
    const [cellRows17, setCellRows17] = React.useState([]);
    const [cellRows18, setCellRows18] = React.useState([]);
    const [cellRows19, setCellRows19] = React.useState([]);
    const [cellRows20, setCellRows20] = React.useState([]);


    const [areas1Copy, setAreas1Copy] = React.useState([]);
    const [areas2Copy, setAreas2Copy] = React.useState([]);
    const [areas3Copy, setAreas3Copy] = React.useState([]);
    const [areas4Copy, setAreas4Copy] = React.useState([]);
    const [areas5Copy, setAreas5Copy] = React.useState([]);
    const [areas6Copy, setAreas6Copy] = React.useState([]);
    const [areas7Copy, setAreas7Copy] = React.useState([]);
    const [areas8Copy, setAreas8Copy] = React.useState([]);
    const [areas9Copy, setAreas9Copy] = React.useState([]);
    const [areas10Copy, setAreas10Copy] = React.useState([]);
    const [areas11Copy, setAreas11Copy] = React.useState([]);
    const [areas12Copy, setAreas12Copy] = React.useState([]);
    const [areas13Copy, setAreas13Copy] = React.useState([]);
    const [areas14Copy, setAreas14Copy] = React.useState([]);
    const [areas15Copy, setAreas15Copy] = React.useState([]);
    const [areas16Copy, setAreas16Copy] = React.useState([]);
    const [areas17Copy, setAreas17Copy] = React.useState([]);
    const [areas18Copy, setAreas18Copy] = React.useState([]);
    const [areas19Copy, setAreas19Copy] = React.useState([]);
    const [areas20Copy, setAreas20Copy] = React.useState([]);


    const [eaves1Copy, setEaves1Copy] = React.useState([]);
    const [eaves2Copy, setEaves2Copy] = React.useState([]);
    const [eaves3Copy, setEaves3Copy] = React.useState([]);
    const [eaves4Copy, setEaves4Copy] = React.useState([]);
    const [eaves5Copy, setEaves5Copy] = React.useState([]);
    const [eaves6Copy, setEaves6Copy] = React.useState([]);
    const [eaves7Copy, setEaves7Copy] = React.useState([]);
    const [eaves8Copy, setEaves8Copy] = React.useState([]);
    const [eaves9Copy, setEaves9Copy] = React.useState([]);
    const [eaves10Copy, setEaves10Copy] = React.useState([]);
    const [eaves11Copy, setEaves11Copy] = React.useState([]);
    const [eaves12Copy, setEaves12Copy] = React.useState([]);
    const [eaves13Copy, setEaves13Copy] = React.useState([]);
    const [eaves14Copy, setEaves14Copy] = React.useState([]);
    const [eaves15Copy, setEaves15Copy] = React.useState([]);
    const [eaves16Copy, setEaves16Copy] = React.useState([]);
    const [eaves17Copy, setEaves17Copy] = React.useState([]);
    const [eaves18Copy, setEaves18Copy] = React.useState([]);
    const [eaves19Copy, setEaves19Copy] = React.useState([]);
    const [eaves20Copy, setEaves20Copy] = React.useState([]);



    const [apex1Copy, setApex1Copy] = React.useState([]);
    const [apex2Copy, setApex2Copy] = React.useState([]);
    const [apex3Copy, setApex3Copy] = React.useState([]);
    const [apex4Copy, setApex4Copy] = React.useState([]);
    const [apex5Copy, setApex5Copy] = React.useState([]);
    const [apex6Copy, setApex6Copy] = React.useState([]);
    const [apex7Copy, setApex7Copy] = React.useState([]);
    const [apex8Copy, setApex8Copy] = React.useState([]);
    const [apex9Copy, setApex9Copy] = React.useState([]);
    const [apex10Copy, setApex10Copy] = React.useState([]);
    const [apex11Copy, setApex11Copy] = React.useState([]);
    const [apex12Copy, setApex12Copy] = React.useState([]);
    const [apex13Copy, setApex13Copy] = React.useState([]);
    const [apex14Copy, setApex14Copy] = React.useState([]);
    const [apex15Copy, setApex15Copy] = React.useState([]);
    const [apex16Copy, setApex16Copy] = React.useState([]);
    const [apex17Copy, setApex17Copy] = React.useState([]);
    const [apex18Copy, setApex18Copy] = React.useState([]);
    const [apex19Copy, setApex19Copy] = React.useState([]);
    const [apex20Copy, setApex20Copy] = React.useState([]);

    const [polygonPoints, setPolygonPoints] = React.useState<any[]>([])
    const [builtCustomPoints, setBuiltCustomPoints] = React.useState([]);
    const [builtCutCustomPoints, setBuiltCutCustomPoints] = React.useState([]);
    const [cutsForCover, setCutsForCover] = React.useState([]);

    const [builtCustomPoints1, setBuiltCustomPoints1] = React.useState([]);
    const [builtCustomPoints2, setBuiltCustomPoints2] = React.useState([]);
    const [builtCustomPoints3, setBuiltCustomPoints3] = React.useState([]);
    const [builtCustomPoints4, setBuiltCustomPoints4] = React.useState([]);
    const [builtCustomPoints5, setBuiltCustomPoints5] = React.useState([]);
    const [builtCustomPoints6, setBuiltCustomPoints6] = React.useState([]);
    const [builtCustomPoints7, setBuiltCustomPoints7] = React.useState([]);
    const [builtCustomPoints8, setBuiltCustomPoints8] = React.useState([]);
    const [builtCustomPoints9, setBuiltCustomPoints9] = React.useState([]);
    const [builtCustomPoints10, setBuiltCustomPoints10] = React.useState([]);
    const [builtCustomPoints11, setBuiltCustomPoints11] = React.useState([]);
    const [builtCustomPoints12, setBuiltCustomPoints12] = React.useState([]);
    const [builtCustomPoints13, setBuiltCustomPoints13] = React.useState([]);
    const [builtCustomPoints14, setBuiltCustomPoints14] = React.useState([]);
    const [builtCustomPoints15, setBuiltCustomPoints15] = React.useState([]);
    const [builtCustomPoints16, setBuiltCustomPoints16] = React.useState([]);
    const [builtCustomPoints17, setBuiltCustomPoints17] = React.useState([]);
    const [builtCustomPoints18, setBuiltCustomPoints18] = React.useState([]);
    const [builtCustomPoints19, setBuiltCustomPoints19] = React.useState([]);
    const [builtCustomPoints20, setBuiltCustomPoints20] = React.useState([]);
    const [builtSideSizes1, setBuiltSideSizes1] = React.useState([]);
    const [builtSideSizes2, setBuiltSideSizes2] = React.useState([]);
    const [builtSideSizes3, setBuiltSideSizes3] = React.useState([]);
    const [builtSideSizes4, setBuiltSideSizes4] = React.useState([]);
    const [builtSideSizes5, setBuiltSideSizes5] = React.useState([]);
    const [builtSideSizes6, setBuiltSideSizes6] = React.useState([]);
    const [builtSideSizes7, setBuiltSideSizes7] = React.useState([]);
    const [builtSideSizes8, setBuiltSideSizes8] = React.useState([]);
    const [builtSideSizes9, setBuiltSideSizes9] = React.useState([]);
    const [builtSideSizes10, setBuiltSideSizes10] = React.useState([]);
    const [builtSideSizes11, setBuiltSideSizes11] = React.useState([]);
    const [builtSideSizes12, setBuiltSideSizes12] = React.useState([]);
    const [builtSideSizes13, setBuiltSideSizes13] = React.useState([]);
    const [builtSideSizes14, setBuiltSideSizes14] = React.useState([]);
    const [builtSideSizes15, setBuiltSideSizes15] = React.useState([]);
    const [builtSideSizes16, setBuiltSideSizes16] = React.useState([]);
    const [builtSideSizes17, setBuiltSideSizes17] = React.useState([]);
    const [builtSideSizes18, setBuiltSideSizes18] = React.useState([]);
    const [builtSideSizes19, setBuiltSideSizes19] = React.useState([]);
    const [builtSideSizes20, setBuiltSideSizes20] = React.useState([]);

    const [builtCustomPoints1Copy, setBuiltCustomPoints1Copy] = React.useState([]);
    const [builtCustomPoints2Copy, setBuiltCustomPoints2Copy] = React.useState([]);
    const [builtCustomPoints3Copy, setBuiltCustomPoints3Copy] = React.useState([]);
    const [builtCustomPoints4Copy, setBuiltCustomPoints4Copy] = React.useState([]);
    const [builtCustomPoints5Copy, setBuiltCustomPoints5Copy] = React.useState([]);
    const [builtCustomPoints6Copy, setBuiltCustomPoints6Copy] = React.useState([]);
    const [builtCustomPoints7Copy, setBuiltCustomPoints7Copy] = React.useState([]);
    const [builtCustomPoints8Copy, setBuiltCustomPoints8Copy] = React.useState([]);
    const [builtCustomPoints9Copy, setBuiltCustomPoints9Copy] = React.useState([]);
    const [builtCustomPoints10Copy, setBuiltCustomPoints10Copy] = React.useState([]);
    const [builtCustomPoints11Copy, setBuiltCustomPoints11Copy] = React.useState([]);
    const [builtCustomPoints12Copy, setBuiltCustomPoints12Copy] = React.useState([]);
    const [builtCustomPoints13Copy, setBuiltCustomPoints13Copy] = React.useState([]);
    const [builtCustomPoints14Copy, setBuiltCustomPoints14Copy] = React.useState([]);
    const [builtCustomPoints15Copy, setBuiltCustomPoints15Copy] = React.useState([]);
    const [builtCustomPoints16Copy, setBuiltCustomPoints16Copy] = React.useState([]);
    const [builtCustomPoints17Copy, setBuiltCustomPoints17Copy] = React.useState([]);
    const [builtCustomPoints18Copy, setBuiltCustomPoints18Copy] = React.useState([]);
    const [builtCustomPoints19Copy, setBuiltCustomPoints19Copy] = React.useState([]);
    const [builtCustomPoints20Copy, setBuiltCustomPoints20Copy] = React.useState([]);

    const [Apex1Id, setApex1Id] = React.useState([]);
    const [Apex2Id, setApex2Id] = React.useState([]);
    const [Apex3Id, setApex3Id] = React.useState([]);
    const [Apex4Id, setApex4Id] = React.useState([]);
    const [Apex5Id, setApex5Id] = React.useState([]);
    const [Apex6Id, setApex6Id] = React.useState([]);
    const [Apex7Id, setApex7Id] = React.useState([]);
    const [Apex8Id, setApex8Id] = React.useState([]);
    const [Apex9Id, setApex9Id] = React.useState([]);
    const [Apex10Id, setApex10Id] = React.useState([]);
    const [Apex11Id, setApex11Id] = React.useState([]);
    const [Apex12Id, setApex12Id] = React.useState([]);
    const [Apex13Id, setApex13Id] = React.useState([]);
    const [Apex14Id, setApex14Id] = React.useState([]);
    const [Apex15Id, setApex15Id] = React.useState([]);
    const [Apex16Id, setApex16Id] = React.useState([]);
    const [Apex17Id, setApex17Id] = React.useState([]);
    const [Apex18Id, setApex18Id] = React.useState([]);
    const [Apex19Id, setApex19Id] = React.useState([]);
    const [Apex20Id, setApex20Id] = React.useState([]);

    const [Eaves1Id, setEaves1Id] = React.useState([]);
    const [Eaves2Id, setEaves2Id] = React.useState([]);
    const [Eaves3Id, setEaves3Id] = React.useState([]);
    const [Eaves4Id, setEaves4Id] = React.useState([]);
    const [Eaves5Id, setEaves5Id] = React.useState([]);
    const [Eaves6Id, setEaves6Id] = React.useState([]);
    const [Eaves7Id, setEaves7Id] = React.useState([]);
    const [Eaves8Id, setEaves8Id] = React.useState([]);
    const [Eaves9Id, setEaves9Id] = React.useState([]);
    const [Eaves10Id, setEaves10Id] = React.useState([]);
    const [Eaves11Id, setEaves11Id] = React.useState([]);
    const [Eaves12Id, setEaves12Id] = React.useState([]);
    const [Eaves13Id, setEaves13Id] = React.useState([]);
    const [Eaves14Id, setEaves14Id] = React.useState([]);
    const [Eaves15Id, setEaves15Id] = React.useState([]);
    const [Eaves16Id, setEaves16Id] = React.useState([]);
    const [Eaves17Id, setEaves17Id] = React.useState([]);
    const [Eaves18Id, setEaves18Id] = React.useState([]);
    const [Eaves19Id, setEaves19Id] = React.useState([]);
    const [Eaves20Id, setEaves20Id] = React.useState([]);

    const [lines1Copy, setLines1Copy]  = React.useState<number[]>([]);
    const [lines2Copy, setLines2Copy]  = React.useState<number[]>([]);
    const [lines3Copy, setLines3Copy]  = React.useState<number[]>([]);
    const [lines4Copy, setLines4Copy]  = React.useState<number[]>([]);
    const [lines5Copy, setLines5Copy]  = React.useState<number[]>([]);
    const [lines6Copy, setLines6Copy]  = React.useState<number[]>([]);
    const [lines7Copy, setLines7Copy]  = React.useState<number[]>([]);
    const [lines8Copy, setLines8Copy]  = React.useState<number[]>([]);
    const [lines9Copy, setLines9Copy]  = React.useState<number[]>([]);
    const [lines10Copy, setLines10Copy]  = React.useState<number[]>([]);
    const [lines11Copy, setLines11Copy]  = React.useState<number[]>([]);
    const [lines12Copy, setLines12Copy]  = React.useState<number[]>([]);
    const [lines13Copy, setLines13Copy]  = React.useState<number[]>([]);
    const [lines14Copy, setLines14Copy]  = React.useState<number[]>([]);
    const [lines15Copy, setLines15Copy]  = React.useState<number[]>([]);
    const [lines16Copy, setLines16Copy]  = React.useState<number[]>([]);
    const [lines17Copy, setLines17Copy]  = React.useState<number[]>([]);
    const [lines18Copy, setLines18Copy]  = React.useState<number[]>([]);
    const [lines19Copy, setLines19Copy]  = React.useState<number[]>([]);
    const [lines20Copy, setLines20Copy]  = React.useState<number[]>([]);
    return(
        <>
            {
                activeTab == "main" ?
                    <MainPage/>
                : null
            }
            {
                activeTab == "calculator" ?
                <Calculator 
                isReadyFigureBuild={isReadyFigureBuild} setIsReadyFigureBuild={setIsReadyFigureBuild}
                points={points} setPoints={setPoints}
                pointsForTestFigure={pointsForTestFigure} setPointsForTestFigure={setPointsForTestFigure}
                lettersForTestFigure={lettersForTestFigure} setLettersForTestFigure={setLettersForTestFigure}
                isLineClosed={isLineClosed} setIsLineClosed={setIsLineClosed}
                lines={lines} setLines={setLines}
                linesForTestFigure={linesForTestFigure} setLinesForTestFigure={setLinesForTestFigure} 
                addLinesForTestFigure={addLinesForTestFigure} setAddLinesForTestFigure={setAddLinesForTestFigure}
                addLinesForTestFigure1={addLinesForTestFigure1} setAddLinesForTestFigure1={setAddLinesForTestFigure1}
                addLinesForTestFigure2={addLinesForTestFigure2} setAddLinesForTestFigure2={setAddLinesForTestFigure2}
                addLinesForTestFigure3={addLinesForTestFigure3} setAddLinesForTestFigure3={setAddLinesForTestFigure3}
                cutFigures={cutFigures} setCutFigures={setCutFigures}
                cutFiguresLines={cutFiguresLines} setCutFiguresLines={setCutFiguresLines}
                start={start} setStart={setStart}
                
                areas1={areas1} setAreas1={setAreas1}
                areas2={areas2} setAreas2={setAreas2}
                areas3={areas3} setAreas3={setAreas3}
                areas4={areas4} setAreas4={setAreas4}
                areas5={areas5} setAreas5={setAreas5}
                areas6={areas6} setAreas6={setAreas6}
                areas7={areas7} setAreas7={setAreas7}
                areas8={areas8} setAreas8={setAreas8}
                areas9={areas9} setAreas9={setAreas9}
                areas10={areas10} setAreas10={setAreas10}
                areas11={areas11} setAreas11={setAreas11}
                areas12={areas12} setAreas12={setAreas12}
                areas13={areas13} setAreas13={setAreas13}
                areas14={areas14} setAreas14={setAreas14}
                areas15={areas15} setAreas15={setAreas15}
                areas16={areas16} setAreas16={setAreas16}
                areas17={areas17} setAreas17={setAreas17}
                areas18={areas18} setAreas18={setAreas18}
                areas19={areas19} setAreas19={setAreas19}
                areas20={areas20} setAreas20={setAreas20}


                cuts1={cuts1} setCuts1={setCuts1}
                cuts2={cuts2} setCuts2={setCuts2}
                cuts3={cuts3} setCuts3={setCuts3} 
                cuts4={cuts4} setCuts4={setCuts4}
                cuts5={cuts5} setCuts5={setCuts5}
                cuts6={cuts6} setCuts6={setCuts6}
                cuts7={cuts7} setCuts7={setCuts7}
                cuts8={cuts8} setCuts8={setCuts8}
                cuts9={cuts9} setCuts9={setCuts9}
                cuts10={cuts10} setCuts10={setCuts10}
                cuts11={cuts11} setCuts11={setCuts11}
                cuts12={cuts12} setCuts12={setCuts12} 
                cuts13={cuts13} setCuts13={setCuts13} 
                cuts14={cuts14} setCuts14={setCuts14} 
                cuts15={cuts15} setCuts15={setCuts15} 
                cuts16={cuts16} setCuts16={setCuts16} 
                cuts17={cuts17} setCuts17={setCuts17} 
                cuts18={cuts18} setCuts18={setCuts18} 
                cuts19={cuts19} setCuts19={setCuts19} 
                cuts20={cuts20} setCuts20={setCuts20} 

                closedCuts1={closedCuts1} setClosedCuts1={setClosedCuts1}
                closedCuts2={closedCuts2} setClosedCuts2={setClosedCuts2}
                closedCuts3={closedCuts3} setClosedCuts3={setClosedCuts3}
                closedCuts4={closedCuts4} setClosedCuts4={setClosedCuts4}
                closedCuts5={closedCuts5} setClosedCuts5={setClosedCuts5}
                closedCuts6={closedCuts6} setClosedCuts6={setClosedCuts6}
                closedCuts7={closedCuts7} setClosedCuts7={setClosedCuts7}
                closedCuts8={closedCuts8} setClosedCuts8={setClosedCuts8}
                closedCuts9={closedCuts9} setClosedCuts9={setClosedCuts9}
                closedCuts10={closedCuts10} setClosedCuts10={setClosedCuts10}
                closedCuts11={closedCuts11} setClosedCuts11={setClosedCuts11}
                closedCuts12={closedCuts12} setClosedCuts12={setClosedCuts12}
                closedCuts13={closedCuts13} setClosedCuts13={setClosedCuts13}
                closedCuts14={closedCuts14} setClosedCuts14={setClosedCuts14}
                closedCuts15={closedCuts15} setClosedCuts15={setClosedCuts15}
                closedCuts16={closedCuts16} setClosedCuts16={setClosedCuts16}
                closedCuts17={closedCuts17} setClosedCuts17={setClosedCuts17}
                closedCuts18={closedCuts18} setClosedCuts18={setClosedCuts18}
                closedCuts19={closedCuts19} setClosedCuts19={setClosedCuts19}
                closedCuts20={closedCuts20} setClosedCuts20={setClosedCuts20}


                lines1={lines1} setLines1={setLines1}
                lines2={lines2} setLines2={setLines2}
                lines3={lines3} setLines3={setLines3}
                lines4={lines4} setLines4={setLines4}
                lines5={lines5} setLines5={setLines5}
                lines6={lines6} setLines6={setLines6}
                lines7={lines7} setLines7={setLines7}
                lines8={lines8} setLines8={setLines8}
                lines9={lines9} setLines9={setLines9}
                lines10={lines10} setLines10={setLines10}
                lines11={lines11} setLines11={setLines11}
                lines12={lines12} setLines12={setLines12}
                lines13={lines13} setLines13={setLines13}
                lines14={lines14} setLines14={setLines14}
                lines15={lines15} setLines15={setLines15}
                lines16={lines16} setLines16={setLines16}
                lines17={lines17} setLines17={setLines17}
                lines18={lines18} setLines18={setLines18}
                lines19={lines19} setLines19={setLines19}
                lines20={lines20} setLines20={setLines20}

                cutLines1={cutLines1} setCutLines1={setCutLines1}
                cutLines2={cutLines2} setCutLines2={setCutLines2}
                cutLines3={cutLines3} setCutLines3={setCutLines3}
                cutLines4={cutLines4} setCutLines4={setCutLines4}
                cutLines5={cutLines5} setCutLines5={setCutLines5}
                cutLines6={cutLines6} setCutLines6={setCutLines6}
                cutLines7={cutLines7} setCutLines7={setCutLines7}
                cutLines8={cutLines8} setCutLines8={setCutLines8}
                cutLines9={cutLines9} setCutLines9={setCutLines9}
                cutLines10={cutLines10} setCutLines10={setCutLines10}
                cutLines11={cutLines11} setCutLines11={setCutLines11}
                cutLines12={cutLines12} setCutLines12={setCutLines12}
                cutLines13={cutLines13} setCutLines13={setCutLines13}
                cutLines14={cutLines14} setCutLines14={setCutLines14}
                cutLines15={cutLines15} setCutLines15={setCutLines15}
                cutLines16={cutLines16} setCutLines16={setCutLines16}
                cutLines17={cutLines17} setCutLines17={setCutLines17}
                cutLines18={cutLines18} setCutLines18={setCutLines18}
                cutLines19={cutLines19} setCutLines19={setCutLines19}
                cutLines20={cutLines20} setCutLines20={setCutLines20}



                eaves1={eaves1} setEaves1={setEaves1} 
                eaves2={eaves2} setEaves2={setEaves2} 
                eaves3={eaves3} setEaves3={setEaves3}
                eaves4={eaves4} setEaves4={setEaves4} 
                eaves5={eaves5} setEaves5={setEaves5} 
                eaves6={eaves6} setEaves6={setEaves6}
                eaves7={eaves7} setEaves7={setEaves7} 
                eaves8={eaves8} setEaves8={setEaves8} 
                eaves9={eaves9} setEaves9={setEaves9}
                eaves10={eaves10} setEaves10={setEaves10} 
                eaves11={eaves11} setEaves11={setEaves11} 
                eaves12={eaves12} setEaves12={setEaves12}
                eaves13={eaves13} setEaves13={setEaves13} 
                eaves14={eaves14} setEaves14={setEaves14} 
                eaves15={eaves15} setEaves15={setEaves15}
                eaves16={eaves16} setEaves16={setEaves16} 
                eaves17={eaves17} setEaves17={setEaves17} 
                eaves18={eaves18} setEaves18={setEaves18}
                eaves19={eaves19} setEaves19={setEaves19} 
                eaves20={eaves20} setEaves20={setEaves20}


                apex1={apex1} setApex1={setApex1}
                apex2={apex2} setApex2={setApex2}
                apex3={apex3} setApex3={setApex3}
                apex4={apex4} setApex4={setApex4}
                apex5={apex5} setApex5={setApex5}
                apex6={apex6} setApex6={setApex6}
                apex7={apex7} setApex7={setApex7}
                apex8={apex8} setApex8={setApex8}
                apex9={apex9} setApex9={setApex9}
                apex10={apex10} setApex10={setApex10}
                apex11={apex11} setApex11={setApex11}
                apex12={apex12} setApex12={setApex12}
                apex13={apex13} setApex13={setApex13}
                apex14={apex14} setApex14={setApex14}
                apex15={apex15} setApex15={setApex15}
                apex16={apex16} setApex16={setApex16}
                apex17={apex17} setApex17={setApex17}
                apex18={apex18} setApex18={setApex18}
                apex19={apex19} setApex19={setApex19}
                apex20={apex20} setApex20={setApex20}


                isLinesClosed1={isLinesClosed1} setIsLinesClosed1={setIsLinesClosed1} 
                isLinesClosed2={isLinesClosed2} setIsLinesClosed2={setIsLinesClosed2} 
                isLinesClosed3={isLinesClosed3} setIsLinesClosed3={setIsLinesClosed3} 
                isLinesClosed4={isLinesClosed4} setIsLinesClosed4={setIsLinesClosed4} 
                isLinesClosed5={isLinesClosed5} setIsLinesClosed5={setIsLinesClosed5} 
                isLinesClosed6={isLinesClosed6} setIsLinesClosed6={setIsLinesClosed6} 
                isLinesClosed7={isLinesClosed7} setIsLinesClosed7={setIsLinesClosed7} 
                isLinesClosed8={isLinesClosed8} setIsLinesClosed8={setIsLinesClosed8} 
                isLinesClosed9={isLinesClosed9} setIsLinesClosed9={setIsLinesClosed9} 
                isLinesClosed10={isLinesClosed10} setIsLinesClosed10={setIsLinesClosed10} 
                isLinesClosed11={isLinesClosed11} setIsLinesClosed11={setIsLinesClosed11} 
                isLinesClosed12={isLinesClosed12} setIsLinesClosed12={setIsLinesClosed12} 
                isLinesClosed13={isLinesClosed13} setIsLinesClosed13={setIsLinesClosed13} 
                isLinesClosed14={isLinesClosed14} setIsLinesClosed14={setIsLinesClosed14} 
                isLinesClosed15={isLinesClosed15} setIsLinesClosed15={setIsLinesClosed15} 
                isLinesClosed16={isLinesClosed16} setIsLinesClosed16={setIsLinesClosed16} 
                isLinesClosed17={isLinesClosed17} setIsLinesClosed17={setIsLinesClosed17} 
                isLinesClosed18={isLinesClosed18} setIsLinesClosed18={setIsLinesClosed18} 
                isLinesClosed19={isLinesClosed19} setIsLinesClosed19={setIsLinesClosed19} 
                isLinesClosed20={isLinesClosed20} setIsLinesClosed20={setIsLinesClosed20} 


                results1={results1} setResults1={setResults1} 
                results2={results2} setResults2={setResults2} 
                results3={results3} setResults3={setResults3} 
                results4={results4} setResults4={setResults4} 
                results5={results5} setResults5={setResults5} 
                results6={results6} setResults6={setResults6} 
                results7={results7} setResults7={setResults7} 
                results8={results8} setResults8={setResults8} 
                results9={results9} setResults9={setResults9} 
                results10={results10} setResults10={setResults10} 
                results11={results11} setResults11={setResults11} 
                results12={results12} setResults12={setResults12} 
                results13={results13} setResults13={setResults13} 
                results14={results14} setResults14={setResults14} 
                results15={results15} setResults15={setResults15} 
                results16={results16} setResults16={setResults16} 
                results17={results17} setResults17={setResults17} 
                results18={results18} setResults18={setResults18} 
                results19={results19} setResults19={setResults19} 
                results20={results20} setResults20={setResults20} 

                cellRows1={cellRows1} setCellRows1={setCellRows1}
                cellRows2={cellRows2} setCellRows2={setCellRows2}
                cellRows3={cellRows3} setCellRows3={setCellRows3}
                cellRows4={cellRows4} setCellRows4={setCellRows4}
                cellRows5={cellRows5} setCellRows5={setCellRows5}
                cellRows6={cellRows6} setCellRows6={setCellRows6}
                cellRows7={cellRows7} setCellRows7={setCellRows7}
                cellRows8={cellRows8} setCellRows8={setCellRows8}
                cellRows9={cellRows9} setCellRows9={setCellRows9}
                cellRows10={cellRows10} setCellRows10={setCellRows10}
                cellRows11={cellRows11} setCellRows11={setCellRows11}
                cellRows12={cellRows12} setCellRows12={setCellRows12}
                cellRows13={cellRows13} setCellRows13={setCellRows13}
                cellRows14={cellRows14} setCellRows14={setCellRows14}
                cellRows15={cellRows15} setCellRows15={setCellRows15}
                cellRows16={cellRows16} setCellRows16={setCellRows16}
                cellRows17={cellRows17} setCellRows17={setCellRows17}
                cellRows18={cellRows18} setCellRows18={setCellRows18}
                cellRows19={cellRows19} setCellRows19={setCellRows19}
                cellRows20={cellRows20} setCellRows20={setCellRows20}



                areas1Copy={areas1Copy} setAreas1Copy={setAreas1Copy}
                areas2Copy={areas2Copy} setAreas2Copy={setAreas2Copy}
                areas3Copy={areas3Copy} setAreas3Copy={setAreas3Copy}
                areas4Copy={areas4Copy} setAreas4Copy={setAreas4Copy}
                areas5Copy={areas5Copy} setAreas5Copy={setAreas5Copy}
                areas6Copy={areas6Copy} setAreas6Copy={setAreas6Copy}
                areas7Copy={areas7Copy} setAreas7Copy={setAreas7Copy}
                areas8Copy={areas8Copy} setAreas8Copy={setAreas8Copy}
                areas9Copy={areas9Copy} setAreas9Copy={setAreas9Copy}
                areas10Copy={areas10Copy} setAreas10Copy={setAreas10Copy}
                areas11Copy={areas11Copy} setAreas11Copy={setAreas11Copy}
                areas12Copy={areas12Copy} setAreas12Copy={setAreas12Copy}
                areas13Copy={areas13Copy} setAreas13Copy={setAreas13Copy}
                areas14Copy={areas14Copy} setAreas14Copy={setAreas14Copy}
                areas15Copy={areas15Copy} setAreas15Copy={setAreas15Copy}
                areas16Copy={areas16Copy} setAreas16Copy={setAreas16Copy}
                areas17Copy={areas17Copy} setAreas17Copy={setAreas17Copy}
                areas18Copy={areas18Copy} setAreas18Copy={setAreas18Copy}
                areas19Copy={areas19Copy} setAreas19Copy={setAreas19Copy}
                areas20Copy={areas20Copy} setAreas20Copy={setAreas20Copy}


                eaves1Copy={eaves1Copy} setEaves1Copy={setEaves1Copy}
                eaves2Copy={eaves2Copy} setEaves2Copy={setEaves2Copy}
                eaves3Copy={eaves3Copy} setEaves3Copy={setEaves3Copy}
                eaves4Copy={eaves4Copy} setEaves4Copy={setEaves4Copy}
                eaves5Copy={eaves5Copy} setEaves5Copy={setEaves5Copy}
                eaves6Copy={eaves6Copy} setEaves6Copy={setEaves6Copy}
                eaves7Copy={eaves7Copy} setEaves7Copy={setEaves7Copy}
                eaves8Copy={eaves8Copy} setEaves8Copy={setEaves8Copy}
                eaves9Copy={eaves9Copy} setEaves9Copy={setEaves9Copy}
                eaves10Copy={eaves10Copy} setEaves10Copy={setEaves10Copy}
                eaves11Copy={eaves11Copy} setEaves11Copy={setEaves11Copy}
                eaves12Copy={eaves12Copy} setEaves12Copy={setEaves12Copy}
                eaves13Copy={eaves13Copy} setEaves13Copy={setEaves13Copy}
                eaves14Copy={eaves14Copy} setEaves14Copy={setEaves14Copy}
                eaves15Copy={eaves15Copy} setEaves15Copy={setEaves15Copy}
                eaves16Copy={eaves16Copy} setEaves16Copy={setEaves16Copy}
                eaves17Copy={eaves17Copy} setEaves17Copy={setEaves17Copy}
                eaves18Copy={eaves18Copy} setEaves18Copy={setEaves18Copy}
                eaves19Copy={eaves19Copy} setEaves19Copy={setEaves19Copy}
                eaves20Copy={eaves20Copy} setEaves20Copy={setEaves20Copy}

                apex1Copy={apex1Copy} setApex1Copy={setApex1Copy}
                apex2Copy={apex1Copy} setApex2Copy={setApex2Copy}
                apex3Copy={apex1Copy} setApex3Copy={setApex3Copy}
                apex4Copy={apex1Copy} setApex4Copy={setApex4Copy}
                apex5Copy={apex1Copy} setApex5Copy={setApex5Copy}
                apex6Copy={apex1Copy} setApex6Copy={setApex6Copy}
                apex7Copy={apex1Copy} setApex7Copy={setApex7Copy}
                apex8Copy={apex1Copy} setApex8Copy={setApex8Copy}
                apex9Copy={apex1Copy} setApex9Copy={setApex9Copy}
                apex10Copy={apex1Copy} setApex10Copy={setApex10Copy}
                apex11Copy={apex1Copy} setApex11Copy={setApex11Copy}
                apex12Copy={apex1Copy} setApex12Copy={setApex12Copy}
                apex13Copy={apex1Copy} setApex13Copy={setApex13Copy}
                apex14Copy={apex1Copy} setApex14Copy={setApex14Copy}
                apex15Copy={apex1Copy} setApex15Copy={setApex15Copy}
                apex16Copy={apex1Copy} setApex16Copy={setApex16Copy}
                apex17Copy={apex1Copy} setApex17Copy={setApex17Copy}
                apex18Copy={apex1Copy} setApex18Copy={setApex18Copy}
                apex19Copy={apex1Copy} setApex19Copy={setApex19Copy}
                apex20Copy={apex1Copy} setApex20Copy={setApex20Copy}


                builtCustomPoints1={builtCustomPoints1} setBuiltCustomPoints1={setBuiltCustomPoints1}
                builtCustomPoints2={builtCustomPoints2} setBuiltCustomPoints2={setBuiltCustomPoints2}
                builtCustomPoints3={builtCustomPoints3} setBuiltCustomPoints3={setBuiltCustomPoints3}
                builtCustomPoints4={builtCustomPoints4} setBuiltCustomPoints4={setBuiltCustomPoints4}
                builtCustomPoints5={builtCustomPoints5} setBuiltCustomPoints5={setBuiltCustomPoints5}
                builtCustomPoints6={builtCustomPoints6} setBuiltCustomPoints6={setBuiltCustomPoints6}
                builtCustomPoints7={builtCustomPoints7} setBuiltCustomPoints7={setBuiltCustomPoints7}
                builtCustomPoints8={builtCustomPoints8} setBuiltCustomPoints8={setBuiltCustomPoints8}
                builtCustomPoints9={builtCustomPoints9} setBuiltCustomPoints9={setBuiltCustomPoints9}
                builtCustomPoints10={builtCustomPoints10} setBuiltCustomPoints10={setBuiltCustomPoints10}
                builtCustomPoints11={builtCustomPoints11} setBuiltCustomPoints11={setBuiltCustomPoints11}
                builtCustomPoints12={builtCustomPoints12} setBuiltCustomPoints12={setBuiltCustomPoints12}
                builtCustomPoints13={builtCustomPoints13} setBuiltCustomPoints13={setBuiltCustomPoints13}
                builtCustomPoints14={builtCustomPoints14} setBuiltCustomPoints14={setBuiltCustomPoints14}
                builtCustomPoints15={builtCustomPoints15} setBuiltCustomPoints15={setBuiltCustomPoints15}
                builtCustomPoints16={builtCustomPoints16} setBuiltCustomPoints16={setBuiltCustomPoints16}
                builtCustomPoints17={builtCustomPoints17} setBuiltCustomPoints17={setBuiltCustomPoints17}
                builtCustomPoints18={builtCustomPoints18} setBuiltCustomPoints18={setBuiltCustomPoints18}
                builtCustomPoints19={builtCustomPoints19} setBuiltCustomPoints19={setBuiltCustomPoints19}
                builtCustomPoints20={builtCustomPoints20} setBuiltCustomPoints20={setBuiltCustomPoints20}

                builtSideSizes1={builtSideSizes1} setBuiltSideSizes1={setBuiltSideSizes1}
                builtSideSizes2={builtSideSizes2} setBuiltSideSizes2={setBuiltSideSizes2}
                builtSideSizes3={builtSideSizes3} setBuiltSideSizes3={setBuiltSideSizes3}
                builtSideSizes4={builtSideSizes4} setBuiltSideSizes4={setBuiltSideSizes4}
                builtSideSizes5={builtSideSizes5} setBuiltSideSizes5={setBuiltSideSizes5}
                builtSideSizes6={builtSideSizes6} setBuiltSideSizes6={setBuiltSideSizes6}
                builtSideSizes7={builtSideSizes7} setBuiltSideSizes7={setBuiltSideSizes7}
                builtSideSizes8={builtSideSizes8} setBuiltSideSizes8={setBuiltSideSizes8}
                builtSideSizes9={builtSideSizes9} setBuiltSideSizes9={setBuiltSideSizes9}
                builtSideSizes10={builtSideSizes10} setBuiltSideSizes10={setBuiltSideSizes10}
                builtSideSizes11={builtSideSizes11} setBuiltSideSizes11={setBuiltSideSizes11}
                builtSideSizes12={builtSideSizes12} setBuiltSideSizes12={setBuiltSideSizes12}
                builtSideSizes13={builtSideSizes13} setBuiltSideSizes13={setBuiltSideSizes13}
                builtSideSizes14={builtSideSizes14} setBuiltSideSizes14={setBuiltSideSizes14}
                builtSideSizes15={builtSideSizes15} setBuiltSideSizes15={setBuiltSideSizes15}
                builtSideSizes16={builtSideSizes16} setBuiltSideSizes16={setBuiltSideSizes16}
                builtSideSizes17={builtSideSizes17} setBuiltSideSizes17={setBuiltSideSizes17}
                builtSideSizes18={builtSideSizes18} setBuiltSideSizes18={setBuiltSideSizes18}
                builtSideSizes19={builtSideSizes19} setBuiltSideSizes19={setBuiltSideSizes19}
                builtSideSizes20={builtSideSizes20} setBuiltSideSizes20={setBuiltSideSizes20}

                builtCustomPoints1Copy={builtCustomPoints1Copy} setBuiltCustomPoints1Copy={setBuiltCustomPoints1Copy}
                builtCustomPoints2Copy={builtCustomPoints2Copy} setBuiltCustomPoints2Copy={setBuiltCustomPoints2Copy}
                builtCustomPoints3Copy={builtCustomPoints3Copy} setBuiltCustomPoints3Copy={setBuiltCustomPoints3Copy}
                builtCustomPoints4Copy={builtCustomPoints4Copy} setBuiltCustomPoints4Copy={setBuiltCustomPoints4Copy}
                builtCustomPoints5Copy={builtCustomPoints5Copy} setBuiltCustomPoints5Copy={setBuiltCustomPoints5Copy}
                builtCustomPoints6Copy={builtCustomPoints6Copy} setBuiltCustomPoints6Copy={setBuiltCustomPoints6Copy}
                builtCustomPoints7Copy={builtCustomPoints7Copy} setBuiltCustomPoints7Copy={setBuiltCustomPoints7Copy}
                builtCustomPoints8Copy={builtCustomPoints8Copy} setBuiltCustomPoints8Copy={setBuiltCustomPoints8Copy}
                builtCustomPoints9Copy={builtCustomPoints9Copy} setBuiltCustomPoints9Copy={setBuiltCustomPoints9Copy}
                builtCustomPoints10Copy={builtCustomPoints10Copy} setBuiltCustomPoints10Copy={setBuiltCustomPoints10Copy}
                builtCustomPoints11Copy={builtCustomPoints11Copy} setBuiltCustomPoints11Copy={setBuiltCustomPoints11Copy}
                builtCustomPoints12Copy={builtCustomPoints12Copy} setBuiltCustomPoints12Copy={setBuiltCustomPoints12Copy}
                builtCustomPoints13Copy={builtCustomPoints13Copy} setBuiltCustomPoints13Copy={setBuiltCustomPoints13Copy}
                builtCustomPoints14Copy={builtCustomPoints14Copy} setBuiltCustomPoints14Copy={setBuiltCustomPoints14Copy}
                builtCustomPoints15Copy={builtCustomPoints15Copy} setBuiltCustomPoints15Copy={setBuiltCustomPoints15Copy}
                builtCustomPoints16Copy={builtCustomPoints16Copy} setBuiltCustomPoints16Copy={setBuiltCustomPoints16Copy}
                builtCustomPoints17Copy={builtCustomPoints17Copy} setBuiltCustomPoints17Copy={setBuiltCustomPoints17Copy}
                builtCustomPoints18Copy={builtCustomPoints18Copy} setBuiltCustomPoints18Copy={setBuiltCustomPoints18Copy}
                builtCustomPoints19Copy={builtCustomPoints19Copy} setBuiltCustomPoints19Copy={setBuiltCustomPoints19Copy}
                builtCustomPoints20Copy={builtCustomPoints20Copy} setBuiltCustomPoints20Copy={setBuiltCustomPoints20Copy}

                Apex1Id={Apex1Id} setApex1Id={setApex1Id}
                Apex2Id={Apex2Id} setApex2Id={setApex2Id}
                Apex3Id={Apex3Id} setApex3Id={setApex3Id}
                Apex4Id={Apex4Id} setApex4Id={setApex4Id}
                Apex5Id={Apex5Id} setApex5Id={setApex5Id}
                Apex6Id={Apex6Id} setApex6Id={setApex6Id}
                Apex7Id={Apex7Id} setApex7Id={setApex7Id}
                Apex8Id={Apex8Id} setApex8Id={setApex8Id}
                Apex9Id={Apex9Id} setApex9Id={setApex9Id}
                Apex10Id={Apex10Id} setApex10Id={setApex10Id}
                Apex11Id={Apex11Id} setApex11Id={setApex11Id}
                Apex12Id={Apex12Id} setApex12Id={setApex12Id}
                Apex13Id={Apex13Id} setApex13Id={setApex13Id}
                Apex14Id={Apex14Id} setApex14Id={setApex14Id}
                Apex15Id={Apex15Id} setApex15Id={setApex15Id}
                Apex16Id={Apex16Id} setApex16Id={setApex16Id}
                Apex17Id={Apex17Id} setApex17Id={setApex17Id}
                Apex18Id={Apex18Id} setApex18Id={setApex18Id}
                Apex19Id={Apex19Id} setApex19Id={setApex19Id}
                Apex20Id={Apex20Id} setApex20Id={setApex20Id}


                Eaves1Id={Eaves1Id} setEaves1Id={setEaves1Id}
                Eaves2Id={Eaves2Id} setEaves2Id={setEaves2Id}
                Eaves3Id={Eaves3Id} setEaves3Id={setEaves3Id}
                Eaves4Id={Eaves4Id} setEaves4Id={setEaves4Id}
                Eaves5Id={Eaves5Id} setEaves5Id={setEaves5Id}
                Eaves6Id={Eaves6Id} setEaves6Id={setEaves6Id}
                Eaves7Id={Eaves7Id} setEaves7Id={setEaves7Id}
                Eaves8Id={Eaves8Id} setEaves8Id={setEaves8Id}
                Eaves9Id={Eaves9Id} setEaves9Id={setEaves9Id}
                Eaves10Id={Eaves10Id} setEaves10Id={setEaves10Id}
                Eaves11Id={Eaves11Id} setEaves11Id={setEaves11Id}
                Eaves12Id={Eaves12Id} setEaves12Id={setEaves12Id}
                Eaves13Id={Eaves13Id} setEaves13Id={setEaves13Id}
                Eaves14Id={Eaves14Id} setEaves14Id={setEaves14Id}
                Eaves15Id={Eaves15Id} setEaves15Id={setEaves15Id}
                Eaves16Id={Eaves16Id} setEaves16Id={setEaves16Id}
                Eaves17Id={Eaves17Id} setEaves17Id={setEaves17Id}
                Eaves18Id={Eaves18Id} setEaves18Id={setEaves18Id}
                Eaves19Id={Eaves19Id} setEaves19Id={setEaves19Id}
                Eaves20Id={Eaves20Id} setEaves20Id={setEaves20Id}

                lines1Copy={lines1Copy} setLines1Copy={setLines1Copy}
                lines2Copy={lines2Copy} setLines2Copy={setLines2Copy}
                lines3Copy={lines3Copy} setLines3Copy={setLines3Copy}
                lines4Copy={lines4Copy} setLines4Copy={setLines4Copy}
                lines5Copy={lines5Copy} setLines5Copy={setLines5Copy}
                lines6Copy={lines6Copy} setLines6Copy={setLines6Copy}
                lines7Copy={lines7Copy} setLines7Copy={setLines7Copy}
                lines8Copy={lines8Copy} setLines8Copy={setLines8Copy}
                lines9Copy={lines9Copy} setLines9Copy={setLines9Copy}
                lines10Copy={lines10Copy} setLines10Copy={setLines10Copy}
                lines11Copy={lines11Copy} setLines11Copy={setLines11Copy}
                lines12Copy={lines12Copy} setLines12Copy={setLines12Copy}
                lines13Copy={lines13Copy} setLines13Copy={setLines13Copy}
                lines14Copy={lines14Copy} setLines14Copy={setLines14Copy}
                lines15Copy={lines15Copy} setLines15Copy={setLines15Copy}
                lines16Copy={lines16Copy} setLines16Copy={setLines16Copy}
                lines17Copy={lines17Copy} setLines17Copy={setLines17Copy}
                lines18Copy={lines18Copy} setLines18Copy={setLines18Copy}
                lines19Copy={lines19Copy} setLines19Copy={setLines19Copy}
                lines20Copy={lines20Copy} setLines20Copy={setLines20Copy}

                polygonPoints={polygonPoints} setPolygonPoints={setPolygonPoints}
                builtCustomPoints={builtCustomPoints} setBuiltCustomPoints={setBuiltCustomPoints}
                builtCutCustomPoints={builtCutCustomPoints} setBuiltCutCustomPoints={setBuiltCutCustomPoints}
                cutsForCover={cutsForCover} setCutsForCover={setCutsForCover}

                />
                : null
            }
            {
                activeTab == "dictionary" ?
                    <>
                        <Dictionary activeTab={activeTab} setActiveTab={setActiveTab}/>
                        <Categories activeTab={activeTab} setActiveTab={setActiveTab}/>
                    </>
                : null
            }
            {
                activeTab.includes('dictionary/table/category=') == true ?
                    <>
                        <Dictionary activeTab={activeTab} setActiveTab={setActiveTab}/>
                        <Table activeTab={activeTab} setActiveTab={setActiveTab}/>
                    </>
                : null
            }
        </>
    )
}

export default Routers;