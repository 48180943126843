// @ts-nocheck

import React, {FC, useContext, useEffect, useState, useRef} from 'react';

import {Circle, Layer, Line, Rect, Shape, Text} from "react-konva";
import {scalesConfig} from "../../data";
import {AppContext} from "../../context/AppContext";
import { useGridConfig } from "../../hooks/useGridConfig";
import cloneDeep from "lodash/cloneDeep";
import {gridParams} from "../../data"
import { checkIntersection } from "line-intersect";
interface Props {
    coords: any;
    dictionaryItem: any;
    setCalcResult: any;
    figureBottomLine1: any;
    leavePlusTop: any;
    setLeavePlusTop: any;
    leaveMinusTop: any;
    setLeaveMinusTop: any;
    leavePlusBot: any;
    setLeavePlusBot: any;
    leaveMinusBot: any;
    setLeaveMinusBot: any;
    leaveDelete: any;
    setLeaveDelete: any;
    leaveConnect: any;
    setLeaveConnect: any;
    leaveDivide: any;
    setLeaveDivide: any;
    leaveAdd: any;
    setLeaveAdd: any;
    plusMaxHeight: any;
    setPlusMaxHeight: any;
    minusMaxHeight: any;
    setMinusMaxHeight: any;
    changeCoverSide: any;
    setChangeCoverSide: any;
    leaveChangeValue: any;
    leaveArrowValue: any;
    setLeaveArrowValue: any;
    flipFigureX: any;
    setFlipFigureX: any;
    flipFigureY: any;
    setFlipFigureY: any;

    leavesLeft: any;
    setLeavesLeft: any;
    leavesTop: any;
    setLeavesTop: any;
    leavesRight: any;
    setLeavesRight: any;
    leavesBottom: any;
    setLeavesBottom: any;

    cutsForCover: any;
    isGridActive: any;

    areasCount: any;

    areas1: any;
    setAreas1: any;
    areas2: any; 
    setAreas2: any;
    areas3: any;
    setAreas3: any;
    areas4: any;
    setAreas4: any;
    areas5: any; 
    setAreas5: any;
    areas6: any;
    setAreas6: any;
    areas7: any;
    setAreas7: any;
    areas8: any; 
    setAreas8: any;
    areas9: any;
    setAreas9: any;
    areas10: any;
    setAreas10: any;
    areas11: any;
    setAreas11: any;
    areas12: any;
    setAreas12: any;
    areas13: any;
    setAreas13: any;
    areas14: any;
    setAreas14: any;
    areas15: any;
    setAreas15: any;
    areas16: any;
    setAreas16: any;
    areas17: any;
    setAreas17: any;
    areas18: any;
    setAreas18: any;
    areas19: any;
    setAreas19: any;
    areas20: any;
    setAreas20: any;

    lines1: any;
    setLines1: any;
    lines2: any; 
    setLines2: any;
    lines3: any;
    setLines3: any;
    lines4: any;
    setLines4: any;
    lines5: any; 
    setLines5: any;
    lines6: any;
    setLines6: any;
    lines7: any;
    setLines7: any;
    lines8: any; 
    setLines8: any;
    lines9: any;
    setLines9: any;
    lines10: any;
    setLines10: any;
    lines11: any;
    setLines11: any;
    lines12: any;
    setLines12: any;
    lines13: any;
    setLines13: any;
    lines14: any;
    setLines14: any;
    lines15: any;
    setLines15: any;
    lines16: any;
    setLines16: any;
    lines17: any;
    setLines17: any;
    lines18: any;
    setLines18: any;
    lines19: any;
    setLines19: any;
    lines20: any;
    setLines20: any;

    cuts1: any;
    setCuts1: any;
    cuts2: any; 
    setCuts2: any;
    cuts3: any;
    setCuts3: any;
    cuts4: any;
    setCuts4: any;
    cuts5: any; 
    setCuts5: any;
    cuts6: any;
    setCuts6: any;
    cuts7: any;
    setCuts7: any;
    cuts8: any; 
    setCuts8: any;
    cuts9: any;
    setCuts9: any;
    cuts10: any;
    setCuts10: any;
    cuts11: any;
    setCuts11: any;
    cuts12: any;
    setCuts12: any;
    cuts13: any;
    setCuts13: any;
    cuts14: any;
    setCuts14: any;
    cuts15: any;
    setCuts15: any;
    cuts16: any;
    setCuts16: any;
    cuts17: any;
    setCuts17: any;
    cuts18: any;
    setCuts18: any;
    cuts19: any;
    setCuts19: any;
    cuts20: any;
    setCuts20: any;

    results1: any;
    setResults1: any;
    results2: any;
    setResults2: any;
    results3: any;
    setResults3: any;
    results4: any;
    setResults4: any;
    results5: any;
    setResults5: any;
    results6: any;
    setResults6: any;
    results7: any;
    setResults7: any;
    results8: any;
    setResults8: any;
    results9: any;
    setResults9: any;
    results10: any;
    setResults10: any;
    results11: any;
    setResults11: any;
    results12: any;
    setResults12: any;
    results13: any;
    setResults13: any;
    results14: any;
    setResults14: any;
    results15: any;
    setResults15: any;
    results16: any;
    setResults16: any;
    results17: any;
    setResults17: any;
    results18: any;
    setResults18: any;
    results19: any;
    setResults19: any;
    results20: any;
    setResults20: any;

    cellRows1: any;
    setCellRows1: any;
    cellRows2: any;
    setCellRows2: any;
    cellRows3: any;
    setCellRows3: any;
    cellRows4: any;
    setCellRows4: any;
    cellRows5: any;
    setCellRows5: any;
    cellRows6: any;
    setCellRows6: any;
    cellRows7: any;
    setCellRows7: any;
    cellRows8: any;
    setCellRows8: any;
    cellRows9: any;
    setCellRows9: any;
    cellRows10: any;
    setCellRows10: any;
    cellRows11: any;
    setCellRows11: any;
    cellRows12: any;
    setCellRows12: any;
    cellRows13: any;
    setCellRows13: any;
    cellRows14: any;
    setCellRows14: any;
    cellRows15: any;
    setCellRows15: any;
    cellRows16: any;
    setCellRows16: any;
    cellRows17: any;
    setCellRows17: any;
    cellRows18: any;
    setCellRows18: any;
    cellRows19: any;
    setCellRows19: any;
    cellRows20: any;
    setCellRows20: any;
}
const CoveredPolygon: FC<Props> = ({coords, dictionaryItem,setCalcResult, figureBottomLine1, 
    leaveChange, leavePlusTop, setLeavePlusTop, leaveMinusTop, setLeaveMinusTop, leavePlusBot, setLeavePlusBot, leaveMinusBot, setLeaveMinusBot, changeCoverSide, setChangeCoverSide,
    leaveDelete, setLeaveDelete, leaveConnect, setLeaveConnect, leaveDivide, setLeaveDivide, leaveAdd, setLeaveAdd, plusMaxHeight, setPlusMaxHeight, minusMaxHeight, setMinusMaxHeight, leaveChangeValue, leaveArrowValue, setLeaveArrowValue, cutsForCover, isGridActive, areasCount,

    leavesLeft,
    setLeavesLeft,
    leavesTop,
    setLeavesTop,
    leavesRight,
    setLeavesRight,
    leavesBottom,
    setLeavesBottom,
    flipFigureX,
    setFlipFigureX,
    flipFigureY,
    setFlipFigureY,
    
    results1, 
    setResults1, 
    results2, 
    setResults2, 
    results3,  
    setResults3,
    results4,  
    setResults4,
    results5,  
    setResults5,
    results6,  
    setResults6,
    results7,  
    setResults7,
    results8,  
    setResults8,
    results9,  
    setResults9,
    results10,  
    setResults10,
    results11,  
    setResults11,
    results12,  
    setResults12,
    results13,  
    setResults13,
    results14,  
    setResults14,
    results15,  
    setResults15,
    results16,  
    setResults16,
    results17,  
    setResults17,
    results18,  
    setResults18,
    results19,  
    setResults19,
    results20,  
    setResults20,

    areas1,
    setAreas1,
    areas2, 
    setAreas2,
    areas3,
    setAreas3,
    areas4,
    setAreas4,
    areas5, 
    setAreas5,
    areas6,
    setAreas6,
    areas7,
    setAreas7,
    areas8, 
    setAreas8,
    areas9,
    setAreas9,
    areas10,
    setAreas10,
    areas11,
    setAreas11,
    areas12,
    setAreas12,
    areas13,
    setAreas13,
    areas14,
    setAreas14,
    areas15,
    setAreas15,
    areas16,
    setAreas16,
    areas17,
    setAreas17,
    areas18,
    setAreas18,
    areas19,
    setAreas19,
    areas20,
    setAreas20,



    cuts1,
    setCuts1,
    cuts2, 
    setCuts2,
    cuts3,
    setCuts3,
    cuts4,
    setCuts4,
    cuts5, 
    setCuts5,
    cuts6,
    setCuts6,
    cuts7,
    setCuts7,
    cuts8, 
    setCuts8,
    cuts9,
    setCuts9,
    cuts10,
    setCuts10,
    cuts11,
    setCuts11,
    cuts12,
    setCuts12,
    cuts13,
    setCuts13,
    cuts14,
    setCuts14,
    cuts15,
    setCuts15,
    cuts16,
    setCuts16,
    cuts17,
    setCuts17,
    cuts18,
    setCuts18,
    cuts19,
    setCuts19,
    cuts20,
    setCuts20,



    lines1,
    setLines1,
    lines2, 
    setLines2,
    lines3,
    setLines3,
    lines4,
    setLines4,
    lines5, 
    setLines5,
    lines6,
    setLines6,
    lines7,
    setLines7,
    lines8, 
    setLines8,
    lines9,
    setLines9,
    lines10,
    setLines10,
    lines11,
    setLines11,
    lines12,
    setLines12,
    lines13,
    setLines13,
    lines14,
    setLines14,
    lines15,
    setLines15,
    lines16,
    setLines16,
    lines17,
    setLines17,
    lines18,
    setLines18,
    lines19,
    setLines19,
    lines20,
    setLines20, 

    cellRows1,
    setCellRows1,
    cellRows2,
    setCellRows2,
    cellRows3,
    setCellRows3,
    cellRows4,
    setCellRows4,
    cellRows5,
    setCellRows5,
    cellRows6,
    setCellRows6,
    cellRows7,
    setCellRows7,
    cellRows8,
    setCellRows8,
    cellRows9,
    setCellRows9,
    cellRows10,
    setCellRows10,
    cellRows11,
    setCellRows11,
    cellRows12,
    setCellRows12,
    cellRows13,
    setCellRows13,
    cellRows14,
    setCellRows14,
    cellRows15,
    setCellRows15,
    cellRows16,
    setCellRows16,
    cellRows17,
    setCellRows17,
    cellRows18,
    setCellRows18,
    cellRows19,
    setCellRows19,
    cellRows20,
    setCellRows20

}) => {
    const appContext = useContext(AppContext);
    const gridConfig = useGridConfig(window, 1);

    const [scaledCoords, setScaledCoords] = React.useState([]);
    const [figureCoords, setFigureCoords] = React.useState([]);

    const [lineForLeavesMoving, setLineForLeavesMoving] = React.useState([{x: -100, y: 0}, {x: 100, y: 0}]);

    const [coveredCuts, setCoveredCuts] = React.useState([]);

    const [tiles, setTiles] = React.useState([]);

    const [id, setId] = useState([]);
    const [maxHeight, setMaxHeight] = useState(null);
    const [onMaxHeightChange, setOnMaxHeightChange] = useState(null);
    const [onChangeCoverSide, setOnChangeCoverSide] = useState(false);

    const [coveredLeaves, setCoveredLeaves] = useState([]);

    const [idChanged, setIdChanged] = useState(false);

    const [startEdit, setStartEdit] = useState(false);

    const [startCover, setStartCover] = useState(false);

    const getYCoord = (y: any) => {
        // @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 1){
            // @ts-ignore
            return -700 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 2){
            // @ts-ignore
            return -712 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 3){
            // @ts-ignore
            return -722 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 4){
            // @ts-ignore
            return -730 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 5){
            // @ts-ignore
            return -735 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 6){
            // @ts-ignore
            return -740 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 7){
            // @ts-ignore
            return -742 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 8){
            // @ts-ignore
            return -747 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 9){
            // @ts-ignore
            return -751 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 10){
            // @ts-ignore
            return -754 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 11){
            // @ts-ignore
            return -757 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 12){
            // @ts-ignore
            return -759 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 13){
            // @ts-ignore
            return -762 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 14){
            // @ts-ignore
            return -763 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 15){
            // @ts-ignore
            return -764 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 16){
            // @ts-ignore
            return -767 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 17){
            // @ts-ignore
            return -766 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 18){
            // @ts-ignore
            return -768 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 19){
            // @ts-ignore
            return -770 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 20){
            // @ts-ignore
            return -772 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 21){
            // @ts-ignore
            return -773 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 22){
            // @ts-ignore
            return -774 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 23){
            // @ts-ignore
            return -774.5 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 24){
            // @ts-ignore
            return -775 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 25){
            // @ts-ignore
            return -776 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 26){
            // @ts-ignore
            return -777 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 27){
            // @ts-ignore
            return -778 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 28){
            // @ts-ignore
            return -778.5 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 29){
            // @ts-ignore
            return -779 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 30){
            // @ts-ignore
            return -779.5 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 31){
            // @ts-ignore
            return -780 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 32){
            // @ts-ignore
            return -780.5 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
         if(scalesConfig[`${appContext.state.selectedScale}`] == 33){
            // @ts-ignore
            return -781 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 34){
            // @ts-ignore
            return -781.5 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }
    }
    const getXCoord = (x: any) => {
        return gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize + (x * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
    }
    const getHeight = (x: any) => {
        return x * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
    }
    useEffect(() => {
        if(scaledCoords.length == 0 & figureCoords.length == 0){
            setScaledCoords(coords);
            setFigureCoords(coords);
        }
    }, [coords])

    React.useEffect(() => {
        let leavesCopy: any = [];   
        if(flipFigureY == true){
            leavesCopy = cloneDeep(eval("cellRows" + areasCount));
                // @ts-ignore
                let topDott: any = eval("areas" + areasCount)[0].y;
                for (let i = 0; i < eval("areas" + areasCount).length; i++) {
                    if(eval("areas" + areasCount)[i].y > topDott){
                        topDott = eval("areas" + areasCount)[i].y
                    }
                }
                for (let i = 0; i < leavesCopy.length; i++) {
                    leavesCopy[i][1] = topDott - leavesCopy[i][3] - leavesCopy[i][1]
                }
        }
        if(flipFigureY == true && cellRows2.length == 0 && cellRows1.length !== 0 && areasCount > 0){
            setCellRows2(leavesCopy)
        }
        if(flipFigureY == true && cellRows3.length == 0 && cellRows2.length !== 0 && areasCount > 0){
            setCellRows3(leavesCopy)
        }
        if(flipFigureY == true && cellRows4.length == 0 && cellRows3.length !== 0 && areasCount > 0){
            setCellRows4(leavesCopy)
        }
        if(flipFigureY == true && cellRows5.length == 0 && cellRows4.length !== 0 && areasCount > 0){
            setCellRows5(leavesCopy)
        }
        if(flipFigureY == true && cellRows6.length == 0 && cellRows5.length !== 0 && areasCount > 0){
            setCellRows6(leavesCopy)
        }
        if(flipFigureY == true && cellRows7.length == 0 && cellRows6.length !== 0 && areasCount > 0){
            setCellRows7(leavesCopy)
        }
        if(flipFigureY == true && cellRows8.length == 0 && cellRows7.length !== 0 && areasCount > 0){
            setCellRows8(leavesCopy)
        }
        if(flipFigureY == true && cellRows9.length == 0 && cellRows8.length !== 0 && areasCount > 0){
            setCellRows9(leavesCopy)
        }
        if(flipFigureY == true && cellRows10.length == 0 && cellRows9.length !== 0 && areasCount > 0){
            setCellRows10(leavesCopy)
        }
        if(flipFigureY == true && cellRows11.length == 0 && cellRows10.length !== 0 && areasCount > 0){
            setCellRows11(leavesCopy)
        }
        if(flipFigureY == true && cellRows12.length == 0 && cellRows11.length !== 0 && areasCount > 0){
            setCellRows12(leavesCopy)
        }
        if(flipFigureY == true && cellRows13.length == 0 && cellRows12.length !== 0 && areasCount > 0){
            setCellRows13(leavesCopy)
        }
        if(flipFigureY == true && cellRows14.length == 0 && cellRows13.length !== 0 && areasCount > 0){
            setCellRows14(leavesCopy)
        }
        if(flipFigureY == true && cellRows15.length == 0 && cellRows14.length !== 0 && areasCount > 0){
            setCellRows15(leavesCopy)
        }
        if(flipFigureY == true && cellRows16.length == 0 && cellRows15.length !== 0 && areasCount > 0){
            setCellRows16(leavesCopy)
        }
        if(flipFigureY == true && cellRows17.length == 0 && cellRows16.length !== 0 && areasCount > 0){
            setCellRows17(leavesCopy)
        }
        if(flipFigureY == true && cellRows18.length == 0 && cellRows17.length !== 0 && areasCount > 0){
            setCellRows18(leavesCopy)
        }
        if(flipFigureY == true && cellRows19.length == 0 && cellRows18.length !== 0 && areasCount > 0){
            setCellRows19(leavesCopy)
        }
        if(flipFigureY == true && cellRows20.length == 0 && cellRows19.length !== 0 && areasCount > 0){
            setCellRows20(leavesCopy)
        }
        if(flipFigureY){
            appContext.dispatch({type: 'set-edited-mode',payload: {editedMode: 1, isEditedMode: true}});
            appContext.dispatch({type: 'set-build-mode',payload: {isBuildMode: false}});
            appContext.dispatch({type: 'set-cut-figure-mode',payload: {isCutFigureMode: false}});
            appContext.dispatch({type: 'set-clicked-coords', payload: {clickedCoords: null}});
        }
        setFlipFigureY(false)
        
    }, [flipFigureY]);

    React.useEffect(() => {
        let leavesCopy: any = [];   
        if(flipFigureX == true){
            leavesCopy = cloneDeep(eval("cellRows" + areasCount));
                // @ts-ignore
                let rightDott: any = eval("areas" + areasCount)[0].x;
                for (let i = 0; i < eval("areas" + areasCount).length; i++) {
                    if(eval("areas" + areasCount)[i].x > rightDott){
                        rightDott = eval("areas" + areasCount)[i].x
                    }
                }
                for (let i = 0; i < leavesCopy.length; i++) {
                    leavesCopy[i][0] = rightDott - leavesCopy[i][2] - leavesCopy[i][0]
                }
        }
        if(flipFigureX == true && cellRows2.length == 0 && cellRows1.length !== 0 && areasCount > 0){
            setCellRows2(leavesCopy)
        }
        if(flipFigureX == true && cellRows3.length == 0 && cellRows2.length !== 0 && areasCount > 0){
            setCellRows3(leavesCopy)
        }
        if(flipFigureX == true && cellRows4.length == 0 && cellRows3.length !== 0 && areasCount > 0){
            setCellRows4(leavesCopy)
        }
        if(flipFigureX == true && cellRows5.length == 0 && cellRows4.length !== 0 && areasCount > 0){
            setCellRows5(leavesCopy)
        }
        if(flipFigureX == true && cellRows6.length == 0 && cellRows5.length !== 0 && areasCount > 0){
            setCellRows6(leavesCopy)
        }
        if(flipFigureX == true && cellRows7.length == 0 && cellRows6.length !== 0 && areasCount > 0){
            setCellRows7(leavesCopy)
        }
        if(flipFigureX == true && cellRows8.length == 0 && cellRows7.length !== 0 && areasCount > 0){
            setCellRows8(leavesCopy)
        }
        if(flipFigureX == true && cellRows9.length == 0 && cellRows8.length !== 0 && areasCount > 0){
            setCellRows9(leavesCopy)
        }
        if(flipFigureX == true && cellRows10.length == 0 && cellRows9.length !== 0 && areasCount > 0){
            setCellRows10(leavesCopy)
        }
        if(flipFigureX == true && cellRows11.length == 0 && cellRows10.length !== 0 && areasCount > 0){
            setCellRows11(leavesCopy)
        }
        if(flipFigureX == true && cellRows12.length == 0 && cellRows11.length !== 0 && areasCount > 0){
            setCellRows12(leavesCopy)
        }
        if(flipFigureX == true && cellRows13.length == 0 && cellRows12.length !== 0 && areasCount > 0){
            setCellRows13(leavesCopy)
        }
        if(flipFigureX == true && cellRows14.length == 0 && cellRows13.length !== 0 && areasCount > 0){
            setCellRows14(leavesCopy)
        }
        if(flipFigureX == true && cellRows15.length == 0 && cellRows14.length !== 0 && areasCount > 0){
            setCellRows15(leavesCopy)
        }
        if(flipFigureX == true && cellRows16.length == 0 && cellRows15.length !== 0 && areasCount > 0){
            setCellRows16(leavesCopy)
        }
        if(flipFigureX == true && cellRows17.length == 0 && cellRows16.length !== 0 && areasCount > 0){
            setCellRows17(leavesCopy)
        }
        if(flipFigureX == true && cellRows18.length == 0 && cellRows17.length !== 0 && areasCount > 0){
            setCellRows18(leavesCopy)
        }
        if(flipFigureX == true && cellRows19.length == 0 && cellRows18.length !== 0 && areasCount > 0){
            setCellRows19(leavesCopy)
        }
        if(flipFigureX == true && cellRows20.length == 0 && cellRows19.length !== 0 && areasCount > 0){
            setCellRows20(leavesCopy)
        }
        if(flipFigureX){
            appContext.dispatch({type: 'set-edited-mode',payload: {editedMode: 1, isEditedMode: true}});
            appContext.dispatch({type: 'set-build-mode',payload: {isBuildMode: false}});
            appContext.dispatch({type: 'set-cut-figure-mode',payload: {isCutFigureMode: false}});
            appContext.dispatch({type: 'set-clicked-coords', payload: {clickedCoords: null}});
        }
        setFlipFigureX(false)
        
    }, [flipFigureX]);

    let buildLeave = (x: number, y: number, width: number, height: number, id: number, color: any) => {
        let fontSize = 15
        let xForText = x + width / 3
        let yForText = y + 0.25
        if(appContext.state.selectedScale >= 5){
            fontSize = 13
            yForText = y + 0.3
        }
        if(appContext.state.selectedScale >= 10){
            yForText = y + 0.35
            xForText = x + width / 4
        }
        if(appContext.state.selectedScale >= 13){
            yForText = y + 0.4
            xForText = x + width / 4.5
            fontSize = 12
        }
        return(
            <>
                {
                    height !== 0 ?
                        <>
                            <Rect
                                key={`polygon-${Math.random()}`}
                                id={id}
                                x={getXCoord(x)}
                                y={getYCoord(y)}
                                width={getHeight(width)}
                                height={getHeight(height)}
                                fill="rgba(147, 214, 41, 0.1)"
                                opacity={1}
                                strokeWidth={2}
                                stroke={color}
                                radius={20}
                                zIndex={7}
                                onClick = {onclickHandler.bind(this)}
                            />
                            <Text
                                text={(height * 1000).toFixed()}
                                fontSize={fontSize}
                                key={`rectangle-${Math.random()}`}
                                x={getXCoord(xForText)}
                                y={getYCoord(yForText)}
                                opacity={1}
                                scaleY={-1}
                                zIndex={7}
                                fill={'black'}
                                zIndex={8}
                            />
                        </>
                    : null
                }
            </>
        )
    }
    let buildTile = (x: number, y: number, width: number) => {
        return(
            <>
                <Rect
                    key={`polygon-${Math.random()}`}
                    x={getXCoord(x)}
                    y={getYCoord(y)}
                    width={getHeight(width)}
                    height={getHeight(0.0001)}
                    opacity={1}
                    strokeWidth={2}
                    stroke={"#708090"}
                />
            </>
        )
    }
    function onclickHandler(e: any) {
        let idCopy = id
        if(id.includes(e.currentTarget.id()) == false){
            idCopy.push(e.currentTarget.id())
        }
        else{
            for(let i = 0; i < idCopy.length; i++){
                if(e.currentTarget.id() == idCopy[i]){
                    idCopy.splice(i, 1)
                }
            }
        }
        setId(idCopy)
        setStartEdit(true)
        setIdChanged(true)
    }
    React.useEffect(() => {
        let buildCustomPointsCopy: any = [];
        for (let i = 0; i < eval("cellRows" + areasCount).length; i++) {
            // @ts-ignore
            buildCustomPointsCopy.push(buildLeave(eval("cellRows" + areasCount)[i][0], eval("cellRows" + areasCount)[i][1], eval("cellRows" + areasCount)[i][2], eval("cellRows" + areasCount)[i][3], eval("cellRows" + areasCount)[i][4], eval("cellRows" + areasCount)[i][5]));
        }
        setCoveredLeaves(buildCustomPointsCopy);
    }, [eval("cellRows" + areasCount), appContext.state.selectedScale])
    React.useEffect(() => {
        let buildTilesCopy: any = [];
        for (let i = 0; i < eval("cellRows" + areasCount).length; i++) {
            let n = Math.floor(eval("cellRows" + areasCount)[i][3] / 0.35)
            for(let k = 1; k < n + 1; k++){
                buildTilesCopy.push(buildTile(eval("cellRows" + areasCount)[i][0], eval("cellRows" + areasCount)[i][1] + (0.35 * k), eval("cellRows" + areasCount)[i][2]));
            }
        }
        setTiles(buildTilesCopy);
    }, [eval("cellRows" + areasCount), appContext.state.selectedScale])
    
    React.useEffect(() => {
        if(eval("cellRows" + areasCount).length > 0 && startEdit == false){
            setStartEdit(true)
        }
     }, [appContext.state.selectedScale])

    React.useEffect(() => {
        if(idChanged){ 
            if(id.length > 0){
                let cellRowsCopy = cloneDeep(eval("cellRows" + areasCount))
                for(let i = 0; i < eval("cellRows" + areasCount).length; i++){
                    if(id.includes(i) == false){
                        cellRowsCopy.splice(i, 1, [cellRowsCopy[i][0], cellRowsCopy[i][1], cellRowsCopy[i][2], 
                        cellRowsCopy[i][3], cellRowsCopy[i][4], "#93D629"])
                    }
                    if(id.includes(i)){
                        cellRowsCopy.splice(i, 1, [cellRowsCopy[i][0], cellRowsCopy[i][1], cellRowsCopy[i][2], 
                        cellRowsCopy[i][3], cellRowsCopy[i][4], "000"])
                    }
                }
                eval("setCellRows" + areasCount)(cellRowsCopy)
            }
            else{
                let cellRowsCopy = cloneDeep(eval("cellRows" + areasCount))
                for(let n = 0; n < cellRowsCopy.length; n++){
                    cellRowsCopy.splice(n, 1, [cellRowsCopy[n][0], cellRowsCopy[n][1], cellRowsCopy[n][2], 
                    cellRowsCopy[n][3], cellRowsCopy[n][4], "#93D629"])
                }
                eval("setCellRows" + areasCount)(cellRowsCopy)
            }
            setIdChanged(false)
        }
    }, [idChanged])
    useEffect(() => {
        if(id.length > 0){
            let cellRowsCopy = cloneDeep(eval("cellRows" + areasCount))
            for(let i = 0; i < id.length; i++){
                if(leavePlusTop == true && eval("cellRows" + areasCount)[id[i]][3] !== 0){
                    cellRowsCopy.splice(id[i], 1, [cellRowsCopy[id[i]][0], cellRowsCopy[id[i]][1], cellRowsCopy[id[i]][2], 
                    Number(((cellRowsCopy[id[i]][3]) + (leaveChangeValue / 1000)).toFixed(2)), cellRowsCopy[id[i]][4], "000"])
                    setLeavePlusTop(false)
                }
                if(leaveMinusTop == true && eval("cellRows" + areasCount)[id[i]][3] !== 0 && cellRowsCopy[id[i]][3] > (leaveChangeValue / 1000)){
                    cellRowsCopy.splice(id[i], 1, [cellRowsCopy[id[i]][0], cellRowsCopy[id[i]][1], cellRowsCopy[id[i]][2], 
                    Number(((cellRowsCopy[id[i]][3]) - (leaveChangeValue / 1000)).toFixed(2)), cellRowsCopy[id[i]][4], "000"])
                    setLeaveMinusTop(false) 
                }
            }
            eval("setCellRows" + areasCount)(cellRowsCopy)
        }
    }, [leavePlusTop, leaveMinusTop]);
    useEffect(() => {
        if(id.length > 0){
            let cellRowsCopy = cloneDeep(eval("cellRows" + areasCount))
            for(let i = 0; i < id.length; i++){
                if(leavePlusBot == true && eval("cellRows" + areasCount)[id[i]][3] !== 0){
                    cellRowsCopy.splice(id[i], 1, [cellRowsCopy[id[i]][0], cellRowsCopy[id[i]][1] - (leaveChangeValue / 1000), cellRowsCopy[id[i]][2], 
                    Number(((cellRowsCopy[id[i]][3]) + (leaveChangeValue / 1000)).toFixed(2)), cellRowsCopy[id[i]][4], "000"])
                    setLeavePlusBot(false)
                }
                if(leaveMinusBot == true && eval("cellRows" + areasCount)[id[i]][3] !== 0 && cellRowsCopy[id[i]][3] > (leaveChangeValue / 1000)){
                    cellRowsCopy.splice(id[i], 1, [cellRowsCopy[id[i]][0], cellRowsCopy[id[i]][1] + (leaveChangeValue / 1000), cellRowsCopy[id[i]][2], 
                    Number(((cellRowsCopy[id[i]][3]) - (leaveChangeValue / 1000)).toFixed(2)), cellRowsCopy[id[i]][4], "000"])
                    setLeaveMinusBot(false) 
                }
            }
            eval("setCellRows" + areasCount)(cellRowsCopy)
        }
    }, [leavePlusBot, leaveMinusBot]);

    useEffect(() => {
        let maxHeightCopy = cloneDeep(maxHeight)
        if(maxHeight == null){
            setMaxHeight(dictionaryItem.maxHeight)
        }
        if(plusMaxHeight && maxHeight < dictionaryItem.maxHeight){
            setMaxHeight(maxHeightCopy + 1)
            setOnMaxHeightChange(true)
        }
        if(minusMaxHeight && maxHeight > 0){
            setMaxHeight(maxHeightCopy - 1)
            setOnMaxHeightChange(true)
        }
        setPlusMaxHeight(false)
        setMinusMaxHeight(false)
    }, [dictionaryItem.maxHeight, plusMaxHeight, minusMaxHeight]); 
/*
    useEffect(() => {
        let cellRowsCopy = cloneDeep(eval("cellRows" + areasCount))
        if(changeCoverSide){
            let rightDott: any = eval("areas" + areasCount)[0].x;
            for (let i = 0; i < eval("areas" + areasCount).length; i++) {
                if(eval("areas" + areasCount)[i].x > rightDott){
                    rightDott = eval("areas" + areasCount)[i].x
                }
            }
            let rightLeaveSide: any = cellRowsCopy[0][0] + cellRowsCopy[0][2]
            for (let i = 0; i < cellRowsCopy.length; i++) {
                if(cellRowsCopy[i][0] + cellRowsCopy[i][2] > rightLeaveSide){
                    rightLeaveSide = cellRowsCopy[i][0] + cellRowsCopy[i][2]
                }
            }
            let leftLeaveSide: any = cellRowsCopy[0][0]
            for (let i = 0; i < cellRowsCopy.length; i++) {
                if(cellRowsCopy[i][0] < leftLeaveSide){
                    leftLeaveSide = cellRowsCopy[i][0]
                }
            }
            if(rightLeaveSide !== rightDott){
                for(let i = 0; i < cellRowsCopy.length; i++){
                    cellRowsCopy[i][0] -= (rightLeaveSide - rightDott)
                }
                eval("setCellRows" + areasCount + "(cellRowsCopy)")
            }
            else{
                for(let i = 0; i < cellRowsCopy.length; i++){
                    cellRowsCopy[i][0] += -leftLeaveSide
                }
                eval("setCellRows" + areasCount + "(cellRowsCopy)")
            }
        }
        setChangeCoverSide(false)
    }, [changeCoverSide]);*/
    useEffect(() => {
        let cellRowsCopy = cloneDeep(eval("cellRows" + areasCount))/*
        if(changeCoverSide){
            let rightDott: any = eval("areas" + areasCount)[0].x;
            for (let i = 0; i < eval("areas" + areasCount).length; i++) {
                if(eval("areas" + areasCount)[i].x > rightDott){
                    rightDott = eval("areas" + areasCount)[i].x
                }
            }
            let rightLeaveSide: any = cellRowsCopy[0][0] + cellRowsCopy[0][2]
            for (let i = 0; i < cellRowsCopy.length; i++) {
                if(cellRowsCopy[i][0] + cellRowsCopy[i][2] > rightLeaveSide){
                    rightLeaveSide = cellRowsCopy[i][0] + cellRowsCopy[i][2]
                }
            }
            if(rightLeaveSide !== rightDott){
                setLeaveArrowValue((rightLeaveSide - rightDott) * 1000)
                setLeavesLeft(true)
                setOnChangeCoverSide(true)
            }
            else{
                alert("листи вже накладено з іншої сторони")
            }
        }*/
        if(changeCoverSide){
            let rightDott: any = eval("areas" + areasCount)[0].x;
            for (let i = 0; i < eval("areas" + areasCount).length; i++) {
                if(eval("areas" + areasCount)[i].x > rightDott){
                    rightDott = eval("areas" + areasCount)[i].x
                }
            }
            let rightLeaveSide: any = cellRowsCopy[0][0] + cellRowsCopy[0][2]
            for (let i = 0; i < cellRowsCopy.length; i++) {
                if(cellRowsCopy[i][0] + cellRowsCopy[i][2] > rightLeaveSide){
                    rightLeaveSide = cellRowsCopy[i][0] + cellRowsCopy[i][2]
                }
            }
            let leftLeaveSide: any = cellRowsCopy[0][0]
            for (let i = 0; i < cellRowsCopy.length; i++) {
                if(cellRowsCopy[i][0] < leftLeaveSide){
                    leftLeaveSide = cellRowsCopy[i][0]
                }
            }
            if(rightLeaveSide !== rightDott){
                setLeaveArrowValue((rightLeaveSide - rightDott) * 1000)
                setLeavesLeft(true)
                setOnChangeCoverSide(true)
            }
            else{
                setLeaveArrowValue((-leftLeaveSide) * 1000)
                setLeavesRight(true)
                setOnChangeCoverSide(true)
            }
        }
        setChangeCoverSide(false)
    }, [changeCoverSide]);
    useEffect(() => {
        if(id.length > 0 && leaveDelete){
            let cellRowsCopy = cloneDeep(eval("cellRows" + areasCount))
            for(let i = 0; i < id.length; i++){
                cellRowsCopy.splice(id[i], 1, [cellRowsCopy[id[i]][0], cellRowsCopy[id[i]][1], 0, 
                0, cellRowsCopy[id[i]][4], "000"])       
            }
            eval("setCellRows" + areasCount)(cellRowsCopy)
            let idCopy = id
            idCopy.splice(0, id.length)
            setId(idCopy)
            setLeaveDelete(false)
        }
    }, [leaveDelete]); 
    useEffect(() => {
        if(leaveConnect == true){
            if(id.length == 2){
                if(eval("cellRows" + areasCount)[id[0]][0] == eval("cellRows" + areasCount)[id[1]][0]){
                    if(eval("cellRows" + areasCount)[id[1]][3] + eval("cellRows" + areasCount)[id[0]][3] - dictionaryItem.horizontalOverlap / 1000 - dictionaryItem.doubleOverlap / 1000 <= dictionaryItem.recommended[maxHeight] / 1000){
                    
                        if(id[0] < id[1]){
                            let cellRowsCopy = cloneDeep(eval("cellRows" + areasCount))
                            cellRowsCopy.splice(id[0], 1, [cellRowsCopy[id[0]][0], cellRowsCopy[id[0]][1], cellRowsCopy[id[0]][2], 
                            cellRowsCopy[id[0]][3] + cellRowsCopy[id[1]][3] - dictionaryItem.horizontalOverlap / 1000 - dictionaryItem.doubleOverlap / 1000, cellRowsCopy[id[0]][4], "#93D629"])
                            cellRowsCopy.splice(id[1], 1, [cellRowsCopy[id[1]][0], cellRowsCopy[id[1]][1], 0, 
                            0, cellRowsCopy[id[1]][4], "000"])
                            eval("setCellRows" + areasCount)(cellRowsCopy)
                            let idCopy = id
                            idCopy.splice(0, 2)
                            setId(idCopy)
                        }
                        if(id[1] < id[0]){
                            let cellRowsCopy = cloneDeep(eval("cellRows" + areasCount))
                            cellRowsCopy.splice(id[1], 1, [cellRowsCopy[id[1]][0], cellRowsCopy[id[1]][1], cellRowsCopy[id[1]][2], 
                            cellRowsCopy[id[1]][3] + cellRowsCopy[id[0]][3] - dictionaryItem.horizontalOverlap / 1000 - dictionaryItem.doubleOverlap / 1000, cellRowsCopy[id[1]][4], "#93D629"])
                            cellRowsCopy.splice(id[0], 1, [cellRowsCopy[id[0]][0], cellRowsCopy[id[0]][1], 0, 
                            0, cellRowsCopy[id[0]][4], "000"])
                            eval("setCellRows" + areasCount)(cellRowsCopy)
                            let idCopy = id
                            idCopy.splice(0, 2)
                            setId(idCopy)
                        }
                    }
                    else{alert("об'єднаний лист не можить бути більший за максимальний")}
                }
                else{alert("листи мають бути один над одним")}
            }
            else{alert("Для об'єднання треба вибрати 2 листа")}
            setLeaveConnect(false)
        }
    }, [leaveConnect]);
    useEffect(() => {
        if(leaveDivide == true){
            if(id.length == 1){
                let cellRowsCopy = cloneDeep(eval("cellRows" + areasCount))
                if(cellRowsCopy[id[0]][3] >= 0.7){

                    let height = cellRowsCopy[id[0]][3] / 2
                    let recomendedHeight
                    for(let q = 0; q < dictionaryItem.recommended.length; q++){
                        if(height < dictionaryItem.recommended[q] / 1000){
                            recomendedHeight = dictionaryItem.recommended[q] / 1000
                            break;
                        }
                    }
                    cellRowsCopy.splice(id[0], 1, [cellRowsCopy[id[0]][0], cellRowsCopy[id[0]][1], cellRowsCopy[id[0]][2], 
                    recomendedHeight, cellRowsCopy[id[0]][4], "#93D629"])


                    cellRowsCopy.splice(cellRowsCopy.length, 0, [cellRowsCopy[id[0]][0], 

                    cellRowsCopy[id[0]][1] + cellRowsCopy[id[0]][3] - dictionaryItem.horizontalOverlap / 1000 - dictionaryItem.doubleOverlap / 1000, 

                    cellRowsCopy[id[0]][2], 

                    height * 2 - recomendedHeight + dictionaryItem.horizontalOverlap / 1000 + dictionaryItem.doubleOverlap / 1000, 
                    cellRowsCopy.length, "#93D629"])
                    eval("setCellRows" + areasCount)(cellRowsCopy)
                    let idCopy = id
                    idCopy.splice(0, 1)
                    setId(idCopy)

                }
                else{alert("Для розділення лист має бути більший, ніж 700мм")}
            }
            else{alert("Для розділення має бути вибраний 1 лист")}
            setLeaveDivide(false)
        }
    }, [leaveDivide]);
    useEffect(() => {
        if(leaveAdd == true){
            if(id.length == 1){
                let cellRowsCopy = cloneDeep(eval("cellRows" + areasCount))

                let newHeight
                for(let q = 0; q < dictionaryItem.recommended.length; q++){
                    if(cellRowsCopy[id[0]][3] <= dictionaryItem.recommended[q] / 1000){
                        newHeight = dictionaryItem.recommended[q] / 1000                        
                        break;
                    }
                }

                cellRowsCopy.splice(id[0], 1, [cellRowsCopy[id[0]][0], cellRowsCopy[id[0]][1], cellRowsCopy[id[0]][2], 
                newHeight, cellRowsCopy[id[0]][4], "#93D629"])

                cellRowsCopy.splice(cellRowsCopy.length, 0, [cellRowsCopy[id[0]][0], cellRowsCopy[id[0]][1] + newHeight - dictionaryItem.horizontalOverlap / 1000 - dictionaryItem.doubleOverlap / 1000, cellRowsCopy[id[0]][2], 
                dictionaryItem.recommended[0] / 1000, cellRowsCopy.length, "#93D629"])
                
                eval("setCellRows" + areasCount)(cellRowsCopy)
                let idCopy = id
                idCopy.splice(0, 1)
                setId(idCopy)
            }
            else{alert("Для додавання має бути вибраний 1 лист")}
            setLeaveAdd(false)
        }
    }, [leaveAdd]);

    useEffect(() => {
        if(leavesLeft && id.length == 0){
            let coordsCopy = cloneDeep(figureCoords);
            let leftDottF: any = figureCoords[0].x
            let leftDottIdF: any = 0
            for(let i = 0; i < figureCoords.length; i++){
                if(figureCoords[i].x < leftDottF){
                    leftDottF = figureCoords[i].x
                    leftDottIdF = i
                }
            }
            let leftDottC: any = coords[0].x
            let leftDottIdC: any = 0
            for(let i = 0; i < coords.length; i++){
                if(coords[i].x < leftDottC){
                    leftDottC = coords[i].x
                    leftDottIdC = i
                }
            }
            if(leftDottF == leftDottC){
                coordsCopy.splice(leftDottIdF + 1, 0, {x: (figureCoords[leftDottIdF].x - (leaveArrowValue / 1000)), y: figureCoords[leftDottIdF].y})
                coordsCopy.splice(leftDottIdF + 2, 0, figureCoords[leftDottIdF])
            }
            else{
                coordsCopy.splice(leftDottIdF, 1, {x: (figureCoords[leftDottIdF].x - (leaveArrowValue / 1000)), y: figureCoords[leftDottIdF].y})
            }
            
            setFigureCoords(coordsCopy)
            setScaledCoords(coordsCopy)
            setStartCover(true)
        }
        if(onChangeCoverSide){
            setLeaveArrowValue(10)
        }
        setOnChangeCoverSide(false)
        setLeavesLeft(false)
    }, [leavesLeft]);
    useEffect(() => {
        if(leavesTop && id.length == 0){

            let lineForLeavesMovingCopy = cloneDeep(lineForLeavesMoving);
            for(let i = 0; i < 2; i++){
                lineForLeavesMovingCopy[i].y += (leaveArrowValue / 1000)
            }
            
            setLineForLeavesMoving(lineForLeavesMovingCopy)
            setStartCover(true)

        }
        setLeavesTop(false)
    }, [leavesTop]);
    useEffect(() => {
        if(leavesRight && id.length == 0){
            let coordsCopy = cloneDeep(figureCoords);
            let leftDottF: any = figureCoords[0].x
            let leftDottIdF: any = 0
            for(let i = 0; i < figureCoords.length; i++){
                if(figureCoords[i].x < leftDottF){
                    leftDottF = figureCoords[i].x
                    leftDottIdF = i
                }
            }
            let leftDottC: any = coords[0].x
            let leftDottIdC: any = 0
            for(let i = 0; i < coords.length; i++){
                if(coords[i].x < leftDottC){
                    leftDottC = coords[i].x
                    leftDottIdC = i
                }
            }
            if(leftDottF == leftDottC){
                coordsCopy.splice(leftDottIdF + 1, 0, {x: (figureCoords[leftDottIdF].x - dictionaryItem.usefulWidth / 1000 + (leaveArrowValue / 1000)), y: figureCoords[leftDottIdF].y})
                coordsCopy.splice(leftDottIdF + 2, 0, figureCoords[leftDottIdF])
            }
            else{
                coordsCopy.splice(leftDottIdF, 1, {x: (figureCoords[leftDottIdF].x - dictionaryItem.usefulWidth / 1000 + (leaveArrowValue / 1000)), y: figureCoords[leftDottIdF].y})
            }
            
            setFigureCoords(coordsCopy)
            setScaledCoords(coordsCopy)
            setStartCover(true)
        }
        if(onChangeCoverSide){
            setLeaveArrowValue(10)
        }
        setOnChangeCoverSide(false)
        setLeavesRight(false)
    }, [leavesRight]);
    useEffect(() => {
        if(leavesBottom && id.length == 0){
            let lineForLeavesMovingCopy = cloneDeep(lineForLeavesMoving);
            for(let i = 0; i < 2; i++){
                lineForLeavesMovingCopy[i].y -= (leaveArrowValue / 1000)
            }
            
            setLineForLeavesMoving(lineForLeavesMovingCopy)
            setStartCover(true)
        }
        setLeavesBottom(false)
    }, [leavesBottom]);

    useEffect(() => {
        if(-lineForLeavesMoving[0].y + (dictionaryItem.horizontalOverlap / 1000) >= Number(dictionaryItem.recommended[maxHeight]) / 1000){
            let lineForLeavesMovingCopy = cloneDeep(lineForLeavesMoving);
            for(let i = 0; i < 2; i++){
                lineForLeavesMovingCopy[i].y += Number(dictionaryItem.recommended[maxHeight]) / 1000
            }
            
            setLineForLeavesMoving(lineForLeavesMovingCopy)
        }
    }, [lineForLeavesMoving]);

    let getTrapezoidHeight = () => {
        const result_bottom = scaledCoords.reduce((a,b)=>{ return (a.y < b.y) ? a : b });
        const result_top = scaledCoords.reduce((a,b)=>{ return (a.y > b.y) ? a : b });

        let height = result_top.y - result_bottom.y;

        height = +(height.toFixed(2));
        height = height;
        height = +(height.toFixed(2));

        return height;
    }
    let checkIfLeavesSizeIsMade = (number, ranges) => {
        const num = parseInt(number, 10);

        for (const range of ranges) {
            const [start, end] = range.split('-').map(value => parseInt(value, 10));
            if (num >= start && num <= end) {
                return num / 1000;
            }
        }
        for (const range of ranges) {
            const [start] = range.split('-').map(value => parseInt(value, 10));
            if (num < start) {
                return start / 1000;
            }
        }
        return null;
    }

    let step1 = dictionaryItem.generalWidth / 1000;

    useEffect(() => {
        if((figureCoords.length > 0 && eval("cellRows" + areasCount).length == 0)){
            coverFunc()
        }
    }, [figureCoords]);
    useEffect(() => {
        if(startCover){
            coverFunc()
            setStartCover(false)
        }
    }, [startCover]);
    useEffect(() => {
        if(onMaxHeightChange){
            coverFunc()
        }
        setOnMaxHeightChange(false)
    }, [onMaxHeightChange]);

    
    let coverFunc = () => {
        let arr = [];

            let leavesCounter = 0

            // ----
           
            let lessX: any = figureCoords[0].x
            for(let i = 0; i < figureCoords.length; i++){
                if(lessX > figureCoords[i].x){
                    lessX = figureCoords[i].x
                }
            }
            let maxX: any = figureCoords[0].x
            for(let i = 0; i < figureCoords.length; i++){
                if(maxX < figureCoords[i].x){
                    maxX = figureCoords[i].x
                }
            }
            let maxY: any = figureCoords[0].y
            for(let i = 0; i < figureCoords.length; i++){
                if(maxY < figureCoords[i].y){
                    maxY = figureCoords[i].y
                }
            }
            let maxQ = 0
            for (let i = lessX; i < maxX; i += step1 - dictionaryItem.verticalOverlap / 1000) {
                let c2 = 0
                if(lineForLeavesMoving[0].y > 0){
                    for(let y = lineForLeavesMoving[0].y + (dictionaryItem.horizontalOverlap / 1000); y >= 0 && c2 == 0; y -= 0.01){
                        for(let x = 0; x <= step1; x += 0.01){
                            if(isPointInsidePolygon({x: i + x, y: y}, figureCoords , 0)){
                                if(y< dictionaryItem.recommended[maxHeight] / 1000){
                                    for(let q = 0; q < dictionaryItem.recommended.length; q++){
                                        if(y < dictionaryItem.recommended[q] / 1000 && y > 0){
                                            if(dictionaryItem.recommended[maxQ] / 1000 < dictionaryItem.recommended[q] / 1000 ){
                                                maxQ = q
                                            }
                                            
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
                for (let i = lessX; i < maxX; i += step1 - dictionaryItem.verticalOverlap / 1000) {
                    let c2 = 0

                    if(lineForLeavesMoving[0].y > 0){
                        for(let y = lineForLeavesMoving[0].y + (dictionaryItem.horizontalOverlap / 1000); y >= 0 && c2 == 0; y -= 0.01){
                            for(let x = dictionaryItem.verticalOverlap / 1000; x <= step1 - dictionaryItem.verticalOverlap / 1000; x += 0.01){
                                if(isPointInsidePolygon({x: i + x, y: y}, figureCoords , 0)){
                                    c2 = y + 0.01
                                    if(y < dictionaryItem.recommended[maxHeight] / 1000){
                                        for(let q = 0; q < dictionaryItem.recommended.length; q++){
                                            if(y < dictionaryItem.recommended[q] / 1000){
                                                if(y < lineForLeavesMoving[0].y){
                                                    arr.push([i, 0 - dictionaryItem.tail / 1000 - (dictionaryItem.recommended[maxQ] / 1000 - lineForLeavesMoving[0].y - dictionaryItem.horizontalOverlap / 1000), 
                                                    step1, checkIfLeavesSizeIsMade((y + (dictionaryItem.recommended[maxQ] / 1000 - lineForLeavesMoving[0].y - dictionaryItem.horizontalOverlap / 1000)) * 1000, dictionaryItem.made), leavesCounter, "#93D629"])
                                                    leavesCounter++ 
                                                    console.log((y + (dictionaryItem.recommended[maxQ] / 1000 - lineForLeavesMoving[0].y - dictionaryItem.horizontalOverlap / 1000)) * 1000)
                                                    console.log(checkIfLeavesSizeIsMade((y + (dictionaryItem.recommended[maxQ] / 1000 - lineForLeavesMoving[0].y - dictionaryItem.horizontalOverlap / 1000)) * 1000, dictionaryItem.made))
                                                    break;
                                                    
                                                }
                                                else{
                                                    arr.push([i, 0 - dictionaryItem.tail / 1000 - (dictionaryItem.recommended[maxQ] / 1000 - lineForLeavesMoving[0].y - dictionaryItem.horizontalOverlap / 1000), step1, dictionaryItem.recommended[q] / 1000, leavesCounter, "#93D629"])
                                                    leavesCounter++ 
                                                    break;
                                                }
                                            }
                                        }
                                    }
                                    else{
                                        let n = 0
                                        for(let l = y + 0.01; l > dictionaryItem.recommended[maxHeight] / 1000; l -= (dictionaryItem.recommended[maxHeight] - dictionaryItem.horizontalOverlap )/ 1000){
                                            arr.push([i, ((dictionaryItem.recommended[maxHeight] - dictionaryItem.horizontalOverlap - dictionaryItem.doubleOverlap) / 1000) * n , step1, 
                                            (Math.round(dictionaryItem.recommended[maxHeight] / 10)) / 100, 
                                            leavesCounter, "#93D629"])
                                            n++
                                            leavesCounter++
                                        }
                                        if(y - lineForLeavesMoving[0].y + 0.01 - ((dictionaryItem.recommended[maxHeight] - dictionaryItem.horizontalOverlap - dictionaryItem.doubleOverlap) / 1000) * n > dictionaryItem.recommended[0] / 1000){
                                            arr.push([i, ((dictionaryItem.recommended[maxHeight] - dictionaryItem.horizontalOverlap - dictionaryItem.doubleOverlap) / 1000) * n , step1, 
                                            Number((y - lineForLeavesMoving[0].y + 0.01 - ((dictionaryItem.recommended[maxHeight] - dictionaryItem.horizontalOverlap - dictionaryItem.doubleOverlap) / 1000) * n).toFixed(2)), 
                                            leavesCounter, "#93D629"])
                                        }
                                        if(y - lineForLeavesMoving[0].y + 0.01 - ((dictionaryItem.recommended[maxHeight] - dictionaryItem.horizontalOverlap - dictionaryItem.doubleOverlap) / 1000) * n < dictionaryItem.recommended[0] / 1000){
                                            arr.push([i, ((dictionaryItem.recommended[maxHeight] - dictionaryItem.horizontalOverlap - dictionaryItem.doubleOverlap) / 1000) * n , step1, 
                                            dictionaryItem.recommended[0] / 1000, 
                                            leavesCounter, "#93D629"])
                                        }
                                        leavesCounter++
                                    }
                                    
                                    break
                                }
                            }
                        }
                    }

                    let c1 = 0
                    for(let y = maxY; y >= 0 && c1 == 0; y -= 0.01){
                        for(let x = dictionaryItem.verticalOverlap / 1000; x <= step1 - dictionaryItem.verticalOverlap / 1000; x += 0.01){
                            if(isPointInsidePolygon({x: i + x, y: y}, figureCoords , 0)){
                                c1 = y + 0.01
                                if(y - lineForLeavesMoving[0].y< dictionaryItem.recommended[maxHeight] / 1000){
                                    for(let q = 0; q < dictionaryItem.recommended.length; q++){
                                        if(y - lineForLeavesMoving[0].y < dictionaryItem.recommended[q] / 1000 && y - lineForLeavesMoving[0].y > 0){

                                            let newY = 0 - dictionaryItem.tail / 1000 + lineForLeavesMoving[0].y
                                            let newHeight = (y - lineForLeavesMoving[0].y) * 1000
                                            while (newY < -0.35 && newHeight >= 500) {
                                                newY += 0.35;
                                                newHeight -= 350;
                                            }
                                            arr.push([i, newY, step1, checkIfLeavesSizeIsMade(newHeight, dictionaryItem.made), leavesCounter, "#93D629"])
                                            leavesCounter++ 
                                            break;
                                        }
                                    }
                                }
                                else{
                                    let n = 0
                                    for(let l = y - lineForLeavesMoving[0].y + 0.01; l > dictionaryItem.recommended[maxHeight] / 1000; l -= (dictionaryItem.recommended[maxHeight] - dictionaryItem.horizontalOverlap )/ 1000){
                                        let newY = ((dictionaryItem.recommended[maxHeight] - dictionaryItem.horizontalOverlap - dictionaryItem.doubleOverlap) / 1000) * n + lineForLeavesMoving[0].y
                                        let newHeight = (Math.round(dictionaryItem.recommended[maxHeight] / 10)) / 100
                                       
                                        while (newY < -0.35 && newHeight >= 0.5) {
                                            newY += 0.35;
                                            newHeight -= 0.35;
                                        }
                                        if(newHeight >= 0.5){
                                            arr.push([i, newY, step1, newHeight, leavesCounter, "#93D629"])
                                        }
                                        n++
                                        leavesCounter++
                                    }
                                    if(y - lineForLeavesMoving[0].y + 0.01 - ((dictionaryItem.recommended[maxHeight] - dictionaryItem.horizontalOverlap - dictionaryItem.doubleOverlap) / 1000) * n > dictionaryItem.recommended[0] / 1000){
                                        arr.push([i, ((dictionaryItem.recommended[maxHeight] - dictionaryItem.horizontalOverlap - dictionaryItem.doubleOverlap) / 1000) * n + lineForLeavesMoving[0].y , step1, 
                                        Number((y - lineForLeavesMoving[0].y + 0.01 - ((dictionaryItem.recommended[maxHeight] - dictionaryItem.horizontalOverlap - dictionaryItem.doubleOverlap) / 1000) * n).toFixed(2)), 
                                        leavesCounter, "#93D629"])
                                    }
                                    if(y - lineForLeavesMoving[0].y + 0.01 - ((dictionaryItem.recommended[maxHeight] - dictionaryItem.horizontalOverlap - dictionaryItem.doubleOverlap) / 1000) * n < dictionaryItem.recommended[0] / 1000){
                                        arr.push([i, ((dictionaryItem.recommended[maxHeight] - dictionaryItem.horizontalOverlap - dictionaryItem.doubleOverlap) / 1000) * n + lineForLeavesMoving[0].y , step1, 
                                        dictionaryItem.recommended[0] / 1000, 
                                        leavesCounter, "#93D629"])
                                    }
                                    leavesCounter++
                                }
                                
                                break
                            }
                        }
                    }
                }




            
                if(cutsForCover.length > 0){
                    for(let i = 0; i < cutsForCover.length; i++){
                        let minCutX = cutsForCover[i][0].x
                        let maxCutX = cutsForCover[i][0].x
                        let maxCutY = cutsForCover[i][0].y
                        for(let j = 0; j < cutsForCover[i].length; j++){
                            if(minCutX > cutsForCover[i][j].x){
                                minCutX = cutsForCover[i][j].x
                            }
                            if(maxCutX < cutsForCover[i][j].x){
                                maxCutX = cutsForCover[i][j].x
                            }
                            if(maxCutY < cutsForCover[i][j].y){
                                maxCutY = cutsForCover[i][j].y
                            }
                        }
                        for(let k = 0; k < arr.length; k++){
                            
                            let arr1 = [
                                {x: arr[k][0], y: arr[k][1]}, 
                                {x: arr[k][0], y: arr[k][1] + arr[k][3]},
                                {x: arr[k][0] + arr[k][2], y: arr[k][1] + arr[k][3]},
                                {x: arr[k][0] + arr[k][2], y: arr[k][1]}]
                                let result = intersection(cutsForCover[i], arr1)
                            if(result.every(e => e)){
                                arr.splice(k, 1, [arr[k][0], arr[k][1], 0, 
                                0, arr[k][4], "000"])
                            }
                            if(result[0] == false && result[1] == true && result[2] == true && result[3] == false){
                                let leaveShift = checkLeaveShift(cutsForCover[i], arr1, "top")
                                arr.splice(k, 1, [arr[k][0], arr[k][1], arr[k][2], 
                                arr[k][3] - leaveShift, arr[k][4], "#93D629"])
                            }
                            if(result[0] == true && result[1] == false && result[2] == false && result[3] == true){
                                let leaveShift = Number((checkLeaveShift(cutsForCover[i], arr1, "bottom")).toFixed(2))

                                let nearestTile

                                if (leaveShift > 0.35) {
                                    nearestTile = Math.floor(leaveShift / 0.35) * 0.35;
                                }
                                arr.splice(k, 1, [arr[k][0], arr[k][1] + nearestTile , arr[k][2], arr[k][3] - nearestTile, arr[k][4], "#93D629"])
                            }
                            if(arr[k][0] > minCutX && arr[k][0] + arr[k][2] < maxCutX && arr[k][1] < maxCutY){
                                let crossedPointsArr = []
                                for(let p = 0; p < cutsForCover[i].length - 1; p++){
                                    let checkLines = checkIntersection(cutsForCover[i][p].x, cutsForCover[i][p].y, cutsForCover[i][p + 1].x, cutsForCover[i][p + 1].y, arr[k][0], arr[k][1] , arr[k][0], arr[k][1] + arr[k][3])
                                    if(checkLines.type == 'intersecting'){
                                        crossedPointsArr.push(checkLines.point)
                                    }
                                }
                                for(let p = 0; p < cutsForCover[i].length - 1; p++){
                                    let checkLines = checkIntersection(cutsForCover[i][p].x, cutsForCover[i][p].y, cutsForCover[i][p + 1].x, cutsForCover[i][p + 1].y, arr[k][0] + arr[k][2], arr[k][1] , arr[k][0] + arr[k][2], arr[k][1] + arr[k][3])
                                    if(checkLines.type == 'intersecting'){
                                        crossedPointsArr.push(checkLines.point)
                                    }
                                }
                                let checkLines1 = checkIntersection(cutsForCover[i][0].x, cutsForCover[i][0].y, cutsForCover[i][cutsForCover[i].length - 1].x, cutsForCover[i][cutsForCover[i].length - 1].y, arr[k][0], arr[k][1] , arr[k][0], arr[k][1] + arr[k][3])
                                if(checkLines1.type == 'intersecting'){
                                    crossedPointsArr.push(checkLines1.point)
                                }
                                let checkLines2 = checkIntersection(cutsForCover[i][0].x, cutsForCover[i][0].y, cutsForCover[i][cutsForCover[i].length - 1].x, cutsForCover[i][cutsForCover[i].length - 1].y, arr[k][0] + arr[k][2], arr[k][1] , arr[k][0] + arr[k][2], arr[k][1] + arr[k][3])
                                if(checkLines2.type == 'intersecting'){
                                    crossedPointsArr.push(checkLines2.point)
                                }
                                
                                crossedPointsArr.sort(function (a, b) {
                                    if (a.y > b.y) {
                                      return 1;
                                    }
                                    if (a.y < b.y) {
                                      return -1;
                                    }
                                    // a должно быть равным b
                                    return 0;
                                  });
                                if(crossedPointsArr.length >= 4){
                                    let activeLeaveCopy = cloneDeep(arr[k])
                                    if(crossedPointsArr[1].y < dictionaryItem.recommended[0] / 1000){
                                        arr.splice(k, 1, [arr[k][0], arr[k][1], arr[k][2], dictionaryItem.recommended[0] / 1000, arr[k][4], "#93D629"])
                                    }
                                    else{
                                        arr.splice(k, 1, [arr[k][0], arr[k][1], arr[k][2], crossedPointsArr[1].y - arr[k][1], arr[k][4], "#93D629"])
                                    }

                                    let nearestTile: number = 0
                                    if (crossedPointsArr[2].y > 0.35) {
                                        nearestTile = Number(Math.floor(crossedPointsArr[2].y / 0.35) * 0.35)
                                    }
                                    arr.push([activeLeaveCopy[0], nearestTile, activeLeaveCopy[2], activeLeaveCopy[3] + activeLeaveCopy[1] - nearestTile, arr.length, "#93D629"])
                                    break
                                }   
                            }
                        }

                    }
               }


            function Gamut_Area(a,b) {
                a[a.length] = a[0];
                b[b.length] = b[0];
                var A = new Array();
                var B = new Array();
                var C = new Array();
                var t = new Array();
                var S = new Array();
                var Stotal = 0.0;
                for (var i=0; i < a.length-1; i++){
                    A[i] = Math.sqrt(a[i]*a[i]+b[i]*b[i]);
                    B[i] = Math.sqrt(a[i+1]*a[i+1]+b[i+1]*b[i+1]);
                    C[i] = Math.sqrt(Math.pow(a[i+1]-a[i],2) + Math.pow(b[i+1]-b[i],2));
                    t[i] = (A[i] + B[i] + C[i]) / 2;
                    S[i] = Math.sqrt(t[i] * (t[i] - A[i]) * (t[i] - B[i]) * (t[i] - C[i]));
                    Stotal += S[i];
                }
                return Stotal; //площадь фигуры
            }
            let xArr: any = []
            let yArr: any = []
            for(let i = 0; i < figureCoords.length; i++){
                xArr.push(figureCoords[i].x)
                yArr.push(figureCoords[i].y)
            }
            let square = Gamut_Area(xArr, yArr)
            let figure_height = getTrapezoidHeight()

            var leaves = {};
            for (var i = 0; i < eval("cellRows" + areasCount).length; ++i)
            {   
                if(eval("cellRows" + areasCount)[i][3] > 0){
                    var a = eval("cellRows" + areasCount)[i][3];
                }
                if (leaves[a] != undefined && leaves[a] != 0)
                    ++leaves[a];
                else
                    leaves[a] = 1;
            }
            let leavesValues: any = []
            for (const property in leaves) {
                leavesValues.push(property)
            }
            let blocks_general: number = 0
            for(let i = 0; i < eval("cellRows" + areasCount).length; i++){
                blocks_general = blocks_general + (step1 * eval("cellRows" + areasCount)[i][3])  
            }
            
            let leave_waste = Number((blocks_general - square).toFixed(2));
            let useful_square = Number((blocks_general - leave_waste).toFixed(2));

            if(isGridActive == true){
                setCalcResult([square, blocks_general, useful_square, step1, leaves, leavesValues, leave_waste, figure_height])
                eval("setResults" + areasCount)([square, blocks_general, useful_square, step1, leaves, leavesValues, leave_waste, figure_height])
            }
            if(eval("cellRows" + areasCount) !== arr){
                eval("setCellRows" + areasCount)(arr)
            }
    }
    
    React.useEffect(() => {
        if(figureCoords.length > 0){
            function Gamut_Area(a,b) {
                a[a.length] = a[0];
                b[b.length] = b[0];
                var A = new Array();
                var B = new Array();
                var C = new Array();
                var t = new Array();
                var S = new Array();
                var Stotal = 0.0;
                for (var i=0; i < a.length-1; i++){
                    A[i] = Math.sqrt(a[i]*a[i]+b[i]*b[i]);
                    B[i] = Math.sqrt(a[i+1]*a[i+1]+b[i+1]*b[i+1]);
                    C[i] = Math.sqrt(Math.pow(a[i+1]-a[i],2) + Math.pow(b[i+1]-b[i],2));
                    t[i] = (A[i] + B[i] + C[i]) / 2;
                    S[i] = Math.sqrt(t[i] * (t[i] - A[i]) * (t[i] - B[i]) * (t[i] - C[i]));
                    Stotal += S[i];
                }
                return Stotal; //площадь фигуры
            }
            let xArr: any = []
            let yArr: any = []
            for(let i = 0; i < figureCoords.length; i++){
                xArr.push(figureCoords[i].x)
                yArr.push(figureCoords[i].y)
            }
            let square = Gamut_Area(xArr, yArr)
            let figure_height = getTrapezoidHeight()

            var leaves = {};
            for (var i = 0; i < eval("cellRows" + areasCount).length; ++i)
            {
                if(eval("cellRows" + areasCount)[i][3] > 0){
                    
                    var a = eval("cellRows" + areasCount)[i][3]
                }
                if (leaves[a] != undefined)
                    ++leaves[a];
                else
                    leaves[a] = 1;
            }
            let leavesValues: any = []
            for (const property in leaves) {
                leavesValues.push(property)
            }
            let blocks_general: number = 0
            for(let i = 0; i < eval("cellRows" + areasCount).length; i++){
                blocks_general = blocks_general + (step1 * eval("cellRows" + areasCount)[i][3])  
            }

            let leave_waste = Number((blocks_general - square).toFixed(2));
            let useful_square = Number((blocks_general - leave_waste).toFixed(2));

            if(isGridActive == true){
                setCalcResult([square, blocks_general, useful_square, step1, leaves, leavesValues, leave_waste, figure_height])
                eval("setResults" + areasCount)([square, blocks_general, useful_square, step1, leaves, leavesValues, leave_waste, figure_height])
            }
        }
    }, [eval("cellRows" + areasCount)]);

    React.useEffect(() => {
        if(cutsForCover.length > 0){
            let cutFiguresLinesCopy: any = []
            for(let i = 0; i < cutsForCover.length; i++){
                let l: any = [];
                for (let k = 0; k < cutsForCover[i].length; k++) {
                    // @ts-ignore
                    l.push(getXCoord(cutsForCover[i][k].x));
                    // @ts-ignore
                    l.push(getYCoord(cutsForCover[i][k].y));
                }
                cutFiguresLinesCopy.push(
                    <Line 
                    // @ts-ignore
                    points={l}
                    stroke={'black'}
                    strokeWidth={2}
                    closed={true}
                    opacity={1}
                    />
                )
            }
            setCoveredCuts(cutFiguresLinesCopy)            
        }
      }, []);



    
    function intersection(cutFigureCoords, leaveCoords) {
        let arr = []
        for(let i = 0; i < leaveCoords.length; i++) {
            arr.push(isPointInsidePolygon(leaveCoords[i], cutFigureCoords, 0))
        }
        return arr
    }
      function checkLeaveShift(cutFigureCoords, leaveCoords, direction){
        let shift = 0.1
        if(direction == "top"){
            for(let n = 0; n < 1000; n++){
                for (let i = 1; i < 3; i++) {
                    if (!isPointInsidePolygon(leaveCoords[i], cutFigureCoords, shift)) {
                        return shift - 0.1;
                    }
                }
                shift += 0.1
            }
        }
        if(direction == "bottom"){
            for(let n = 0; n < 1000; n++){
                for (let i = 0; i < 5; i += 3) {
                    if (!isPointInsidePolygon(leaveCoords[i], cutFigureCoords, -shift)) {
                        return shift - 0.01;
                    }
                }
                shift += 0.01
            }
        }
        return shift.toFixed(2) 
        
      }
      // Функція для перевірки, чи точка знаходиться в межах багатокутника
      function isPointInsidePolygon(point, cutFigureCoords, shift) {

        const x = point.x;
        const y = point.y - shift;

        let isInside = false;
      
        for (let i = 0, j = cutFigureCoords.length - 1; i < cutFigureCoords.length; j = i++) {
          const xi = cutFigureCoords[i].x;
          const yi = cutFigureCoords[i].y;
          const xj = cutFigureCoords[j].x;
          const yj = cutFigureCoords[j].y;
      
          if (yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi) {
            isInside = !isInside;
          }
        }
      
        return isInside;
      }
    
      React.useEffect(() => {
        UpdateLeavesForCuts()
      }, []);

    const UpdateLeavesForCuts = () => {

        if(cutsForCover.length > 0){
            let cellRowsCopy = cloneDeep(eval("cellRows" + areasCount))
            for(let i = 0; i < cutsForCover.length; i++){
                let minCutX = cutsForCover[i][0].x
                let maxCutX = cutsForCover[i][0].x
                let maxCutY = cutsForCover[i][0].y
                for(let j = 0; j < cutsForCover[i].length; j++){
                    if(minCutX > cutsForCover[i][j].x){
                        minCutX = cutsForCover[i][j].x
                    }
                    if(maxCutX < cutsForCover[i][j].x){
                        maxCutX = cutsForCover[i][j].x
                    }
                    if(maxCutY < cutsForCover[i][j].y){
                        maxCutY = cutsForCover[i][j].y
                    }
                }
                for(let k = 0; k < eval("cellRows" + areasCount).length; k++){
                    
                    let arr = [
                        {x: eval("cellRows" + areasCount)[k][0], y: eval("cellRows" + areasCount)[k][1]}, 
                        {x: eval("cellRows" + areasCount)[k][0], y: eval("cellRows" + areasCount)[k][1] + eval("cellRows" + areasCount)[k][3]},
                        {x: eval("cellRows" + areasCount)[k][0] + eval("cellRows" + areasCount)[k][2], y: eval("cellRows" + areasCount)[k][1] + eval("cellRows" + areasCount)[k][3]},
                        {x: eval("cellRows" + areasCount)[k][0] + eval("cellRows" + areasCount)[k][2], y: eval("cellRows" + areasCount)[k][1]}]
                        let result = intersection(cutsForCover[i], arr)
                    if(result.every(e => e)){
                        cellRowsCopy.splice(k, 1, [cellRowsCopy[k][0], cellRowsCopy[k][1], 0, 
                        0, cellRowsCopy[k][4], "000"])
                    }
                    if(result[0] == false && result[1] == true && result[2] == true && result[3] == false){
                        let leaveShift = checkLeaveShift(cutsForCover[i], arr, "top")
                        cellRowsCopy.splice(k, 1, [cellRowsCopy[k][0], cellRowsCopy[k][1], cellRowsCopy[k][2], 
                        cellRowsCopy[k][3] - leaveShift, cellRowsCopy[k][4], "#93D629"])
                    }
                    if(result[0] == true && result[1] == false && result[2] == false && result[3] == true){
                        let leaveShift = Number((checkLeaveShift(cutsForCover[i], arr, "bottom")).toFixed(2))

                         let nearestTile

                        if (leaveShift > 0.35) {
                            nearestTile = Math.floor(leaveShift / 0.35) * 0.35;
                        }

                        cellRowsCopy.splice(k, 1, [cellRowsCopy[k][0], cellRowsCopy[k][1] + nearestTile, cellRowsCopy[k][2], 
                        cellRowsCopy[k][3] - nearestTile, cellRowsCopy[k][4], "#93D629"])
                    }

                    if(cellRowsCopy[k][0] > minCutX && cellRowsCopy[k][0] + cellRowsCopy[k][2] < maxCutX && eval("cellRows" + areasCount)[k][1] < maxCutY){
                        let crossedPointsArr = []
                        for(let p = 0; p < cutsForCover[i].length - 1; p++){
                            let checkLines = checkIntersection(cutsForCover[i][p].x, cutsForCover[i][p].y, cutsForCover[i][p + 1].x, cutsForCover[i][p + 1].y, cellRowsCopy[k][0], cellRowsCopy[k][1] , cellRowsCopy[k][0], cellRowsCopy[k][1] + cellRowsCopy[k][3])
                            if(checkLines.type == 'intersecting'){
                                crossedPointsArr.push(checkLines.point)
                            }
                        }
                        for(let p = 0; p < cutsForCover[i].length - 1; p++){
                            let checkLines = checkIntersection(cutsForCover[i][p].x, cutsForCover[i][p].y, cutsForCover[i][p + 1].x, cutsForCover[i][p + 1].y, cellRowsCopy[k][0] + cellRowsCopy[k][2], cellRowsCopy[k][1] , cellRowsCopy[k][0] + cellRowsCopy[k][2], cellRowsCopy[k][1] + cellRowsCopy[k][3])
                            if(checkLines.type == 'intersecting'){
                                crossedPointsArr.push(checkLines.point)
                            }
                        }
                        let checkLines1 = checkIntersection(cutsForCover[i][0].x, cutsForCover[i][0].y, cutsForCover[i][cutsForCover[i].length - 1].x, cutsForCover[i][cutsForCover[i].length - 1].y, cellRowsCopy[k][0], cellRowsCopy[k][1] , cellRowsCopy[k][0], cellRowsCopy[k][1] + cellRowsCopy[k][3])
                        if(checkLines1.type == 'intersecting'){
                            crossedPointsArr.push(checkLines1.point)
                        }
                        let checkLines2 = checkIntersection(cutsForCover[i][0].x, cutsForCover[i][0].y, cutsForCover[i][cutsForCover[i].length - 1].x, cutsForCover[i][cutsForCover[i].length - 1].y, cellRowsCopy[k][0] + cellRowsCopy[k][2], cellRowsCopy[k][1] , cellRowsCopy[k][0] + cellRowsCopy[k][2], cellRowsCopy[k][1] + cellRowsCopy[k][3])
                        if(checkLines2.type == 'intersecting'){
                            crossedPointsArr.push(checkLines2.point)
                        }
                        
                        crossedPointsArr.sort(function (a, b) {
                            if (a.y > b.y) {
                              return 1;
                            }
                            if (a.y < b.y) {
                              return -1;
                            }
                            // a должно быть равным b
                            return 0;
                          });
                       
                        if(crossedPointsArr.length >= 4){
                            let activeLeaveCopy = cloneDeep(cellRowsCopy[k])
                            if(crossedPointsArr[1].y < dictionaryItem.recommended[0] / 1000){
                                cellRowsCopy.splice(k, 1, [cellRowsCopy[k][0], cellRowsCopy[k][1], cellRowsCopy[k][2], dictionaryItem.recommended[0] / 1000, cellRowsCopy[k][4], "#93D629"])
                            }
                            else{
                                cellRowsCopy.splice(k, 1, [cellRowsCopy[k][0], cellRowsCopy[k][1], cellRowsCopy[k][2], crossedPointsArr[1].y - cellRowsCopy[k][1], cellRowsCopy[k][4], "#93D629"])
                            }    

                            let nearestTile: number = 0
                            if (crossedPointsArr[2].y > 0.35) {
                                nearestTile = Number(Math.floor(crossedPointsArr[2].y / 0.35) * 0.35)
                            }

                            arr.push([activeLeaveCopy[0], nearestTile, activeLeaveCopy[2], activeLeaveCopy[3] + activeLeaveCopy[1] - nearestTile, cellRowsCopy.length, "#93D629"])
                            break
                        }   
                    }
                }
            }
            eval("setCellRows" + areasCount)(cellRowsCopy)
        }
    }
    


    return (
        <>
            <Layer>
                {coveredCuts}
            </Layer>
            <Layer>
                {tiles}
            </Layer>
            <Layer>
                {coveredLeaves}
            </Layer>
        </>
    );
}

export default CoveredPolygon;