import React, {FC, useState, useContext} from "react";
import { Circle, Layer, Line, Text, Rect } from "react-konva";
import { useGridConfig } from "../../hooks/useGridConfig";
import {scalesConfig} from "../../data"
import { AppContext } from "../../context/AppContext"
import { gridParams } from "../../data";

interface Props {
    figurePoints: any;
    figureLeaves: any;
    figureCuts: any;
    figureCutsLines: any;
    updateScale: any;
}

const AreaForPDF: FC<Props> = ({
    figurePoints,
    figureLeaves,
    figureCuts,
    figureCutsLines,
    updateScale
}) => {
    const lineRef = React.useRef(null);
    const [builtCustomLines, setBuiltCustomLines] = useState([]);
    const [coveredLeaves, setCoveredLeaves] = useState([]);
    const [cutsForCover, setCutsForCover] = useState([]);
    const [tiles, setTiles] = React.useState([]);

    const gridConfig = useGridConfig(window, 1);
    const appContext = useContext(AppContext);


    const getYCoord = (y: any) => {
        // @ts-ignore
        if(updateScale == 1){
            // @ts-ignore
            return -700 + (y * gridParams.size1[`scale${updateScale}`].cellSize)
        }// @ts-ignore
        if(updateScale == 2){
            // @ts-ignore
            return -712 + (y * gridParams.size1[`scale${updateScale}`].cellSize)
        }// @ts-ignore
        if(updateScale == 3){
            // @ts-ignore
            return -722 + (y * gridParams.size1[`scale${updateScale}`].cellSize)
        }// @ts-ignore
        if(updateScale == 4){
            // @ts-ignore
            return -730 + (y * gridParams.size1[`scale${updateScale}`].cellSize)
        }// @ts-ignore
        if(updateScale == 5){
            // @ts-ignore
            return -735 + (y * gridParams.size1[`scale${updateScale}`].cellSize)
        }// @ts-ignore
        if(updateScale == 6){
            // @ts-ignore
            return -740 + (y * gridParams.size1[`scale${updateScale}`].cellSize)
        }// @ts-ignore
        if(updateScale == 7){
            // @ts-ignore
            return -742 + (y * gridParams.size1[`scale${updateScale}`].cellSize)
        }// @ts-ignore
        if(updateScale == 8){
            // @ts-ignore
            return -747 + (y * gridParams.size1[`scale${updateScale}`].cellSize)
        }// @ts-ignore
        if(updateScale == 9){
            // @ts-ignore
            return -751 + (y * gridParams.size1[`scale${updateScale}`].cellSize)
        }// @ts-ignore
        if(updateScale == 10){
            // @ts-ignore
            return -754 + (y * gridParams.size1[`scale${updateScale}`].cellSize)
        }// @ts-ignore
        if(updateScale == 11){
            // @ts-ignore
            return -757 + (y * gridParams.size1[`scale${updateScale}`].cellSize)
        }// @ts-ignore
        if(updateScale == 12){
            // @ts-ignore
            return -759 + (y * gridParams.size1[`scale${updateScale}`].cellSize)
        }// @ts-ignore
        if(updateScale == 13){
            // @ts-ignore
            return -762 + (y * gridParams.size1[`scale${updateScale}`].cellSize)
        }// @ts-ignore
        if(updateScale == 14){
            // @ts-ignore
            return -763 + (y * gridParams.size1[`scale${updateScale}`].cellSize)
        }// @ts-ignore
        if(updateScale == 15){
            // @ts-ignore
            return -764 + (y * gridParams.size1[`scale${updateScale}`].cellSize)
        }// @ts-ignore
        if(updateScale == 16){
            // @ts-ignore
            return -767 + (y * gridParams.size1[`scale${updateScale}`].cellSize)
        }// @ts-ignore
        if(updateScale == 17){
            // @ts-ignore
            return -766 + (y * gridParams.size1[`scale${updateScale}`].cellSize)
        }// @ts-ignore
        if(updateScale == 18){
            // @ts-ignore
            return -768 + (y * gridParams.size1[`scale${updateScale}`].cellSize)
        }// @ts-ignore
        if(updateScale == 19){
            // @ts-ignore
            return -770 + (y * gridParams.size1[`scale${updateScale}`].cellSize)
        }// @ts-ignore
        if(updateScale == 20){
            // @ts-ignore
            return -772 + (y * gridParams.size1[`scale${updateScale}`].cellSize)
        }// @ts-ignore
        if(updateScale == 21){
            // @ts-ignore
            return -773 + (y * gridParams.size1[`scale${updateScale}`].cellSize)
        }// @ts-ignore
        if(updateScale == 22){
            // @ts-ignore
            return -774 + (y * gridParams.size1[`scale${updateScale}`].cellSize)
        }// @ts-ignore
        if(updateScale == 23){
            // @ts-ignore
            return -774.5 + (y * gridParams.size1[`scale${updateScale}`].cellSize)
        }// @ts-ignore
        if(updateScale == 24){
            // @ts-ignore
            return -775 + (y * gridParams.size1[`scale${updateScale}`].cellSize)
        }// @ts-ignore
        if(updateScale == 25){
            // @ts-ignore
            return -776 + (y * gridParams.size1[`scale${updateScale}`].cellSize)
        }// @ts-ignore
        if(updateScale == 26){
            // @ts-ignore
            return -777 + (y * gridParams.size1[`scale${updateScale}`].cellSize)
        }// @ts-ignore
        if(updateScale == 27){
            // @ts-ignore
            return -778 + (y * gridParams.size1[`scale${updateScale}`].cellSize)
        }// @ts-ignore
        if(updateScale == 28){
            // @ts-ignore
            return -778.5 + (y * gridParams.size1[`scale${updateScale}`].cellSize)
        }// @ts-ignore
        if(updateScale == 29){
            // @ts-ignore
            return -779 + (y * gridParams.size1[`scale${updateScale}`].cellSize)
        }// @ts-ignore
        if(updateScale == 30){
            // @ts-ignore
            return -779.5 + (y * gridParams.size1[`scale${updateScale}`].cellSize)
        }// @ts-ignore
        if(updateScale == 31){
            // @ts-ignore
            return -780 + (y * gridParams.size1[`scale${updateScale}`].cellSize)
        }// @ts-ignore
        if(updateScale == 32){
            // @ts-ignore
            return -780.5 + (y * gridParams.size1[`scale${updateScale}`].cellSize)
        }// @ts-ignore
         if(updateScale == 33){
            // @ts-ignore
            return -781 + (y * gridParams.size1[`scale${updateScale}`].cellSize)
        }// @ts-ignore
        if(updateScale == 34){
            // @ts-ignore
            return -781.5 + (y * gridParams.size1[`scale${updateScale}`].cellSize)
        }
    }
    const getXCoord = (x: any) => {
        return gridParams.size1[`scale${updateScale}`].cellSize + (x * gridParams.size1[`scale${updateScale}`].cellSize)
    }
    const getHeight = (x: any) => {
        return x * gridParams.size1[`scale${updateScale}`].cellSize
    }

    React.useEffect(() => {
        // set array of points to lines
        let l: any = [];
        for (let i = 0; i < figurePoints.length; i++) {
            // @ts-ignore
            l.push(getXCoord(figurePoints[i].x));
            // @ts-ignore
            l.push(getYCoord(figurePoints[i].y));
        }
        // @ts-ignore
        setBuiltCustomLines(l);
    }, [figurePoints]);
    
    React.useEffect(() => {
        let buildCustomPointsCopy: any = [];
        for (let i = 0; i < figureLeaves.length; i++) {
            // @ts-ignore
            buildCustomPointsCopy.push(buildLeave(figureLeaves[i][0], figureLeaves[i][1], figureLeaves[i][2], figureLeaves[i][3], figureLeaves[i][4], figureLeaves[i][5]));
        }
        setCoveredLeaves(buildCustomPointsCopy);
    }, [figureLeaves])
    React.useEffect(() => {
        let buildTilesCopy: any = [];
        for (let i = 0; i < figureLeaves.length; i++) {
            let n = Math.floor(figureLeaves[i][3] / 0.35)
            for(let k = 1; k < n + 1; k++){
                buildTilesCopy.push(buildTile(figureLeaves[i][0], figureLeaves[i][1] + (0.35 * k), figureLeaves[i][2]));
            }
        }
        setTiles(buildTilesCopy);
    }, [figureLeaves])

    let buildTile = (x: number, y: number, width: number) => {
        return(
            <>
                <Rect
                    key={`polygon-${Math.random()}`}
                    x={getXCoord(x)}
                    y={getYCoord(y)}
                    width={getHeight(width)}
                    height={getHeight(0.0001)}
                    opacity={1}
                    strokeWidth={2}
                    stroke={"#708090"}
                />
            </>
        )
    }

    let buildLeave = (x: number, y: number, width: number, height: number, id: number, color: any) => {
        return(
            <>
                {
                    height !== 0 ?
                        <>
                            <Rect
                                key={`polygon-${Math.random()}`}
                                x={getXCoord(x)}
                                y={getYCoord(y)}
                                width={getHeight(width)}
                                height={getHeight(height)}
                                fill="rgba(147, 214, 41, 0.1)"
                                opacity={1}
                                strokeWidth={2}
                                stroke={color}
                                radius={20}
                            />
                            <Text
                                text={(height * 1000).toFixed()}
                                fontSize={15}
                                key={`rectangle-${Math.random()}`}
                                x={getXCoord(x)}
                                y={getYCoord(y)}
                                opacity={1}
                                scaleY={-1}
                                offsetX={-40}
                                offsetY={20}
                                zIndex={7}
                                fill={'black'}
                            />
                        </>
                    : null
                }
            </>
        )
    }
    React.useEffect(() => {
          let cutFiguresLinesCopy: any = []
          for(let i = 0; i < figureCuts.length; i++){

            let lItem: any = [];
                for(let n = 0; n < figureCuts[i].length; n++){
                    // @ts-ignore
                    lItem.push(getXCoord(figureCuts[i][n].x));
                    // @ts-ignore
                    lItem.push(getYCoord(figureCuts[i][n].y));
                }

            cutFiguresLinesCopy.push(
                <Line ref={lineRef}
                // @ts-ignore
                points={lItem}
                stroke={'black'}
                strokeWidth={2}
                closed={true}
                opacity={1}
                />
              )
          }
          setCutsForCover(cutFiguresLinesCopy)
      }, [figureCuts]);
    return (
        <>  
            <Layer>
                <Line ref={lineRef}
                    points={builtCustomLines}
                    stroke={'blue'}
                    strokeWidth={4}
                    closed={true} 
                    opacity={1}
                />
            </Layer>
            <Layer>
                {coveredLeaves}
            </Layer>
            <Layer>
                {tiles}
            </Layer>
            <Layer>
                {cutsForCover}
            </Layer>
        </>
    );
}

export default AreaForPDF;
