import React, {FC, useState} from "react";

interface IResultModalBlock {
 resultModal: any;
 setResultModal: any;
 dictionaryItem: any;
 totalSquare: any;
 totalLeavesSquare: any;
 totalLeavesUsfSquare: any;
 totalLeavesWaste: any;
 totalLeaves: any;
 totalLeavesSizes: any;
 totalLeavesValue: any;
 totalLinearMeters: any;
 results1: any;
  results2: any;
  results3: any;
  results4: any;
  results5: any;
  results6: any;
  results7: any;
  results8: any;
  results9: any;
  results10: any;
  results11: any;
  results12: any;
  results13: any;
  results14: any;
  results15: any;
  results16: any;
  results17: any;
  results18: any;
  results19: any;
  results20: any;
}

const ResultModalBlock: FC<IResultModalBlock> = ({resultModal, setResultModal, dictionaryItem, totalSquare, totalLeavesSquare, totalLeavesUsfSquare, totalLeavesWaste,totalLeaves, totalLeavesSizes, totalLeavesValue,
  totalLinearMeters,
  results1,
  results2,
  results3,
  results4,
  results5,
  results6,
  results7,
  results8,
  results9,
  results10,
  results11,
  results12,
  results13,
  results14,
  results15,
  results16,
  results17,
  results18,
  results19,
  results20

}) => {

    {/* Модалка для додавання довжин блоків певної категорії */}

    function getBody() {
      let body = [];
      for (let i = 1; i < 21; i++) {
        if(eval("results" + i).length > 0){
          body.push(
            <tr key={'table_tr'+ i} className="">
                <td>{i}</td>
                <td>{(eval("results" + i)[7]).toFixed(2)}</td>
                <td>{(eval("results" + i)[0]).toFixed(2)}</td>
                <td>{(eval("results" + i)[1]).toFixed(2)}</td>
            </tr>
          )
        }
      }
      return body;
    }

    function getBody1() {
      let body = [];
      body.push(<td>м.</td>)
      for(let i = 0; i < totalLeavesValue.length; i++){
        body.push(
          <td>{Math.round(totalLeavesValue[i] * 1000)}</td>
        )
      }
      return body;
    }
    function getBody2() {
      let body = [];
      body.push(<td>шт.</td>)
      for(let i = 0; i < totalLeavesValue.length; i++){
        body.push(
          <td>{totalLeavesSizes[totalLeavesValue[i]]}</td>
        )
      }
      return body;
    }


    return (
        <>

          {resultModal &&
                <div className="result-modal-wrapper">
                <div className='result-modal'>
                    <button className="result-pop-up-close" onClick={(e) => { setResultModal(false) }}>X</button>
                      <div>Замовник ......</div>
                      <div className='result-header'>Специфікація</div>
                      <div>Матеріал ......</div>
                      <div className="result-width-wrapper">
                        <div className="result-widths-block">
                          <ul>
                            <li>Корисна ш. листа {dictionaryItem.usefulWidth} мм.</li>
                            <li>Загальна ш. листа {dictionaryItem.generalWidth} мм.</li>
                          </ul>
                        </div>
                        <div className="result-widths-block result-ul-2">
                          <ul>
                            <li>Вертикальний нахлест {dictionaryItem.verticalOverlap} мм.</li>
                            <li>Горизонтальний нахлест {dictionaryItem.horizontalOverlap} мм.</li>
                          </ul>
                          
                        </div>
                      </div>
                      <table className="result-table">
                        <thead>
                          <tr>
                            <th>№ скату</th>
                            <th>L скату м</th>
                            <th>S скату м2</th>
                            <th>S повна м2</th>
                          </tr>
                        </thead>
                        <tbody>
                          {getBody()}
                        </tbody>
                      </table>
                      <table className="result-table">
                        <tbody>
                          <tr> 
                            {getBody1()}
                          </tr>
                          <tr>
                            {getBody2()}
                          </tr>
                        </tbody>
                      </table>
                      <div>погонні метри {totalLinearMeters} м.</div>
                </div>
              </div>
            }
        </>
    )
}

export default ResultModalBlock;