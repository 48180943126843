// @ts-nocheck

import React, {ContextType, createContext, FC, useContext, useEffect, useReducer, useRef, useState} from "react";
import "./Dictionary.scss";

// import { useHistory } from 'react-router';

import plusImg from "../../images/plus.png";
import categoryImg from "../../images/nophoto.png";
import {AppContext} from "../../context/AppContext";
import Categories from "./Categories/Categories";

import {
    Outlet, useLocation, useOutletContext
} from "react-router-dom";

interface ICategoryParams {
    categoryExceptions: any[];
    categoryRecommended: any[];
    categoryMade: any[];
    generalWidth: any[];
    usefulWidth: any[];
    verticalOverlap: any[];
    horizontalOverlap: any[];
    doubleOverlap: any[];
    tail: any[];
    maxHeight: any[];
}
interface Props {
    activeTab: any;
    setActiveTab: any;
}

const Dictionary: FC<Props> = ({activeTab, setActiveTab}) => {

    const appContext = useContext(AppContext);
   // const history = useHistory()
    let location = useLocation();

    const [modalCategory, setModalCategory] = useState<Boolean>(false);
    const [modalLength, setModalLength] = useState<Boolean>(false);
    const [type, setType] = useState('calculator');
    const [categoryName, setCategoryName] = useState('');

    const [categories, setCategories] = useState<any[]>([]);
    const [categoryExceptions, setCategoryExceptions] = useState<any[]>([]);
    const [categoryRecommended, setCategoryRecommended] = useState<any[]>([]);
    const [categoryMade, setCategoryMade] = useState<any[]>([]);
    const [categoryGeneralWidth, setCategoryGeneralWidth] = useState<any[]>([]);
    const [categoryUsefulWidth, setCategoryUsefulWidth] = useState<any[]>([]);
    const [categoryVerticalOverlap, setCategoryVerticalOverlap] = useState<any[]>([]);
    const [categoryHorizontalOverlap, setCategoryHorizontalOverlap] = useState<any[]>([]);
    const [categoryDoubleOverlap, setCategoryDoubleOverlap] = useState<any[]>([]);
    const [categoryTail, setCategoryTail] = useState<any[]>([]);
    const [categoryMaxHeight, setCategoryMaxHeight] = useState<any[]>([]);

    const [categoryParams, setCategoryParams] = useState<ICategoryParams[]>([]);

    const [name, setName] = useState<string>('');

    const [exception, setException] = useState<string>([]);
    const [recommended, setRecommended] = useState<string>([]);
    const [made, setMade] = useState<string>([]);
    const [generalWidth, setGeneralWidth] = useState<any[]>([]);
    const [usefulWidth, setUsefulWidth] = useState<any[]>([]);
    const [verticalOverlap, setVerticalOverlap] = useState<string>('');
    const [horizontalOverlap, setHorizontalOverlap] = useState<string>('');
    const [tail, setTail] = useState<string>('');
    const [maxHeight, setMaxHeight] = useState<string>('');

    const node = useRef<HTMLDivElement>(null);

    const [selectedFile, setSelectedFile] = useState(undefined)
    const [preview, setPreview] = useState(undefined)

    useEffect(() => {
        appContext.dispatch({type: 'update-dictionary', payload: {dictionary: categories}});
    }, [categories])

    useEffect(() => {
        setCategoryParams([{categoryExceptions: categoryExceptions, categoryRecommended: categoryRecommended, categoryMade: categoryMade, categoryGeneralWidth: categoryGeneralWidth ,categoryUsefulWidth: categoryUsefulWidth, 
            categoryVerticalOverlap: categoryVerticalOverlap, categoryHorizontalOverlap: categoryHorizontalOverlap, categoryDoubleOverlap: categoryDoubleOverlap, categoryTail: categoryTail, categoryMaxHeight: categoryMaxHeight} ])
    }, [categoryExceptions,categoryRecommended,categoryMade,categoryGeneralWidth,categoryUsefulWidth,categoryVerticalOverlap,categoryHorizontalOverlap,categoryDoubleOverlap,categoryTail, categoryMaxHeight] )

    useEffect(() => {
        setType('categories');
        setCategories(appContext.state.dictionary);
    },[])

    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(selectedFile)
        // @ts-ignore
        setPreview(objectUrl)
    }, [selectedFile])

    const onSelectFile = (e: any) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined)
            return
        }

        var binaryData = [];
        binaryData.push(selectedFile);
        // @ts-ignore
        let objectUrl = window.URL.createObjectURL(new Blob(binaryData, {type: "image"}))

        setSelectedFile(e.target.files[0])
    }

    const [activeCategory, setActiveCategory] = useState(null);

    useEffect(() => {
        if (activeTab) {
            let urlCategoryId = activeTab.split('=')[1];
            let result = appContext.state.dictionary.find(item => item.id.toString() === urlCategoryId);
            setActiveCategory(result);
        }
    }, [activeTab])
    console.log(activeCategory)
    return (
        <div className="dictionary-container">
            <div className="top-container">
                <div className="blocktitle">
                    <span>Довідник </span> {'>'} <span href="" onClick={() => setActiveTab("dictionary")} style={{textDecoration: 'underline'}}> Категорії</span> <a> {activeCategory ? `> ${activeCategory.name}` : ''} </a>
                </div>
            </div>
            <div className="main-container">

                <Outlet context={{ activeCategory, setActiveCategory }} />
            </div>

        </div>
    );
}

export default Dictionary;

export function useActiveCategory() {
    return useOutletContext<ContextType>();
}