import "./TopPanel.scss";
import React, {FC, useContext, useEffect, useState} from "react";

//import "./FigureActions.scss";
import { AppContext } from "../../context/AppContext";
import {FIGURES} from "../../enums/figure.enum";
import {IGridConfig} from "../../interfaces/grid-config-interface";
import {gridParams} from "../../data";

import Prev from "../../images/prev.png"
import Next from "../../images/next.png"
import Delete from "../../images/delete.png"
import CoordsStart from "../../images/coords-start.png"
import CutDelete from "../../images/cut-delete.png"
import Check from "../../images/check.png"

import squareTopIcon from '../../images/square_top_icon.png';
import fig1 from '../../images/figure1.png';
import fig2 from '../../images/figure2.png';
import fig3 from '../../images/figure3.png';
import fig4 from '../../images/figure4.png';
import fig5 from '../../images/figure5.png';
import fig6 from '../../images/figure6.png';
import fig7 from '../../images/figure7.png';
import fig8 from '../../images/figure8.png';
import fig9 from '../../images/figure9.png';
import fig10 from '../../images/figure10.png';
import fig11 from '../../images/figure11.png';
import fig12 from '../../images/figure12.png';
import fig13 from '../../images/figure13.png';
import fig14 from '../../images/figure14.png';
import fig15 from '../../images/figure15.png';
import fig16 from '../../images/figure16.png';
import fig17 from '../../images/figure17.png';
import fig18 from '../../images/figure18.png';
import fig19 from '../../images/figure19.png';
import fig20 from '../../images/figure20.png';
import fig21 from '../../images/figure21.png';
import fig22 from '../../images/figure22.png';
import fig23 from '../../images/figure23.png';
import fig24 from '../../images/figure24.png';
import fig25 from '../../images/figure25.png';
import fig26 from '../../images/figure26.png';
import fig27 from '../../images/figure27.png';
import fig28 from '../../images/figure28.png';
import fig29 from '../../images/figure29.png';
import fig30 from '../../images/figure30.png';
import fig31 from '../../images/figure31.png';
import fig32 from '../../images/figure32.png';
import FlipX from '../../images/flipX.png';
import FlipY from '../../images/flipY.png';
import Apex from '../../images/apex.png';
import Eaves from '../../images/eaves.png';
import Arrows from '../../images/arrows.png';
import Cross from '../../images/cross.png';


interface Props {
    setIsEditedModeCallback: any;
    isEditedMode: boolean;
    setXCords1: any;
    setYCords1: any;
    setIsDottAdditional: any;
    setPrevStep: any;
    setNextStep: any;
    setDeleteDot: any;
    setDoubleCutFigure: any;
    setXCutShift: any;
    setYCutShift: any;
    doubleCutFigure: any;
    setDeleteCutFigure: any;
    setCutFigureCount: any;
    setDeleteFigure: any;
    areasCount: any;
    setAreasCount: any;
    setAddArea: any;
    setAddApex: any;
    setAddEaves: any;
    setFinishFigure: any;
    setCopyFigure: any;
    setFlipFigureX: any;
    setFlipFigureY: any;
    setChangeDott: any;
    setOnCutFigureStartClick: any;
    turnFigure: any; 
    setTurnFigure: any;
    degreesTurnFigure: any; 
    setDegreesTurnFigure: any;
    isReadyFigureBuild: any;
    setIsReadyFigureBuild: any;

    onAInputFocus: any; setOnAInputFocus: any;
    onA1InputFocus: any; setOnA1InputFocus: any;
    onBInputFocus: any; setOnBInputFocus: any;
    onCInputFocus: any; setOnCInputFocus: any;
    onDInputFocus: any; setOnDInputFocus: any;
    onEInputFocus: any; setOnEInputFocus: any;
    onHeightInputFocus: any; setOnHeightInputFocus: any;
    onLInputFocus: any; setOnLInputFocus: any;
}

const TopPanel: FC<Props> = ({setIsEditedModeCallback, isEditedMode, setXCords1, setYCords1, setIsDottAdditional, setPrevStep, setNextStep, 
    setDeleteDot, setDoubleCutFigure, setXCutShift, setYCutShift, doubleCutFigure, setDeleteCutFigure, setCutFigureCount, setAreasCount,
    setAddArea, setAddApex, areasCount, setDeleteFigure, setAddEaves, setFinishFigure, setCopyFigure,setFlipFigureX, setFlipFigureY, setChangeDott, setOnCutFigureStartClick,
    turnFigure, setTurnFigure, degreesTurnFigure, setDegreesTurnFigure,
    isReadyFigureBuild, setIsReadyFigureBuild,

    onAInputFocus, setOnAInputFocus,
    onA1InputFocus, setOnA1InputFocus,
    onBInputFocus, setOnBInputFocus,
    onCInputFocus, setOnCInputFocus,
    onDInputFocus, setOnDInputFocus,
    onEInputFocus, setOnEInputFocus,
    onHeightInputFocus, setOnHeightInputFocus,
    onLInputFocus, setOnLInputFocus
    }) => {
    const appContext = useContext(AppContext);


    const [figureASide, setFigureASide] = useState(0);
    const [figureA1Side, setFigureA1Side] = useState(0);
    const [figureBSide, setFigureBSide] = useState(0);
    const [figureCSide, setFigureCSide] = useState(0);
    const [figureDSide, setFigureDSide] = useState(0);
    const [figureESide, setFigureESide] = useState(0);

    const [figureLSide, setFigureLSide] = useState(0);

    const [figureHeight, setFigureHeight] = useState(0);


    const [copyCutCount, setCopyCutCount] = useState(0);
    const [copyCutValue, setCopyCutValue] = useState(0);
    const [degree, setDegree] = useState(1);

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const [qwer, setQwer] = useState(0);

    useEffect(() => {
        setFigureASide(appContext.state.figureSides.figureASide);
        setFigureA1Side(appContext.state.figureSides.figureA1Side);
        setFigureBSide(appContext.state.figureSides.figureBSide);
        setFigureCSide(appContext.state.figureSides.figureCSide);
        setFigureDSide(appContext.state.figureSides.figureDSide);
        setFigureESide(appContext.state.figureSides.figureESide);
        setFigureLSide(appContext.state.figureSides.figureLSide);
        setFigureHeight(appContext.state.figureSides.figureHeight);
    },[appContext])

    /// Change side size inputs

    function onChangeFigureASideHandler(e: any) {
        setFigureASide(e.target.value);
    }
    function onChangeFigureA1SideHandler(e: any) {
        setFigureA1Side(e.target.value);
    }

    function onChangeFigureBSideHandler(e: any) {
        setFigureBSide(e.target.value);
    }

    function onChangeFigureCSideHandler(e: any) {
        setFigureCSide(e.target.value);
    }

    function onChangeFigureDSideHandler(e: any) {
        setFigureDSide(e.target.value);
    }

    function onChangeFigureESideHandler(e: any) {
        setFigureESide(e.target.value);
    }
   
    function onChangeFigureLSideHandler(e: any) {
        setFigureLSide(e.target.value);
    }

    function onChangeFigureHeightHandler(e: any) {
        setFigureHeight(e.target.value);
    }
    
    function onChangeCopyCutCountHandler(e: any) {
        setCopyCutCount(e.target.value);
    }
    function onChangeCopyCutValueHandler(e: any) {
        setCopyCutValue(e.target.value);
    }
    function onChangeDegreeHandler(e: any) {
        setDegree(Number(e.target.value));
    }

    /// Functional buttons

    function calcFigureBtnHandler(e: any) {
        switch (appContext.state.selectedFigure) {
            case FIGURES.F2: {
                if(figureASide > 0 && figureBSide > 0 && figureCSide > 0){
                    appContext.dispatch({type: 'change-figure-sides', payload: {...appContext.state.figureSides, figureASide: figureASide, figureA1Side: 0, 
                        figureBSide: figureBSide, figureCSide: figureCSide, figureDSide: 0, figureESide: 0, figureHeight: 0, figureLSide: 0}});
                    alert("трикутник буде побудовано за 3ма сторонами")
                    break
                }
                if(figureASide > 0 && figureA1Side > 0 && figureLSide > 0){
                    appContext.dispatch({type: 'change-figure-sides', payload: {...appContext.state.figureSides, figureASide: figureASide, figureA1Side: figureA1Side, 
                        figureBSide: 0, figureCSide: 0, figureDSide: 0, figureESide: 0, figureHeight: 0, figureLSide: figureLSide}});
                    alert("трикутник буде побудовано за сторонами A, A1, L")
                }
                else{alert("введіть будь ласка всі данні")}
                break;
            }
            case FIGURES.F3: {
                if(figureASide > 0){
                    appContext.dispatch({type: 'change-figure-sides', payload: {...appContext.state.figureSides, figureASide: figureASide, figureA1Side: figureA1Side, 
                        figureBSide: figureBSide, figureCSide: figureCSide, figureDSide: figureDSide, figureESide: figureESide, figureHeight: figureHeight, figureLSide: figureLSide}});
                }
                else{alert("введіть будь ласка всі данні")}
                break;
            }
            case FIGURES.F4: {
                if(figureASide > 0 && figureBSide > 0){
                    appContext.dispatch({type: 'change-figure-sides', payload: {...appContext.state.figureSides, figureASide: figureASide, figureA1Side: figureA1Side, 
                        figureBSide: figureBSide, figureCSide: figureCSide, figureDSide: figureDSide, figureESide: figureESide, figureHeight: figureHeight, figureLSide: figureLSide}});
                }
                else{alert("введіть будь ласка всі данні")}
                break;
            }
            case FIGURES.F5: {
                if(figureASide > 0 && figureBSide > 0){
                    appContext.dispatch({type: 'change-figure-sides', payload: {...appContext.state.figureSides, figureASide: figureASide, figureA1Side: figureA1Side, 
                        figureBSide: figureBSide, figureCSide: figureCSide, figureDSide: figureDSide, figureESide: figureESide, figureHeight: figureHeight, figureLSide: figureLSide}});
                }
                else{alert("введіть будь ласка всі данні")}
                break;
            }
            case FIGURES.F8: {
                if(figureASide > 0 && figureBSide > 0){
                    appContext.dispatch({type: 'change-figure-sides', payload: {...appContext.state.figureSides, figureASide: figureASide, figureA1Side: figureA1Side, 
                        figureBSide: figureBSide, figureCSide: figureCSide, figureDSide: figureDSide, figureESide: figureESide, figureHeight: figureHeight, figureLSide: figureLSide}});
                }
                else{alert("введіть будь ласка всі данні")}
                break;
            }
            case FIGURES.F9: {
                if(figureASide > 0 && figureBSide > 0 && figureCSide > 0){
                    appContext.dispatch({type: 'change-figure-sides', payload: {...appContext.state.figureSides, figureASide: figureASide, figureA1Side: figureA1Side, 
                        figureBSide: figureBSide, figureCSide: figureCSide, figureDSide: figureDSide, figureESide: figureESide, figureHeight: figureHeight, figureLSide: figureLSide}});
                }
                else{alert("введіть будь ласка всі данні")}
                break;
            }
            case FIGURES.F10: {
                if(figureASide > 0 && figureBSide > 0 && figureCSide > 0){
                    appContext.dispatch({type: 'change-figure-sides', payload: {...appContext.state.figureSides, figureASide: figureASide, figureA1Side: figureA1Side, 
                        figureBSide: figureBSide, figureCSide: figureCSide, figureDSide: figureDSide, figureESide: figureESide, figureHeight: figureHeight, figureLSide: figureLSide}});
                }
                else{alert("введіть будь ласка всі данні")}
                break;
            }
            case FIGURES.F11: {
                if(figureASide > 0 && figureBSide > 0 && figureCSide > 0){
                    appContext.dispatch({type: 'change-figure-sides', payload: {...appContext.state.figureSides, figureASide: figureASide, figureA1Side: figureA1Side, 
                        figureBSide: figureBSide, figureCSide: figureCSide, figureDSide: figureDSide, figureESide: figureESide, figureHeight: figureHeight, figureLSide: figureLSide}});
                }
                else{alert("введіть будь ласка всі данні")}
                break;
            }
            case FIGURES.F12: {
                if(figureASide > 0 && figureBSide > 0 && figureCSide > 0){
                    appContext.dispatch({type: 'change-figure-sides', payload: {...appContext.state.figureSides, figureASide: figureASide, figureA1Side: figureA1Side, 
                        figureBSide: figureBSide, figureCSide: figureCSide, figureDSide: figureDSide, figureESide: figureESide, figureHeight: figureHeight, figureLSide: figureLSide}});
                }
                else{alert("введіть будь ласка всі данні")}
                break;
            }
            case FIGURES.F13: {
                if(figureASide > 0 && figureBSide > 0 && figureA1Side > 0 && figureHeight > 0){
                    appContext.dispatch({type: 'change-figure-sides', payload: {...appContext.state.figureSides, figureASide: figureASide, figureA1Side: figureA1Side, 
                        figureBSide: figureBSide, figureCSide: figureCSide, figureDSide: figureDSide, figureESide: figureESide, figureHeight: figureHeight, figureLSide: figureLSide}});
                }
                else{alert("введіть будь ласка всі данні")}
                break;
            }
            case FIGURES.F14: {
                if(figureASide > 0 && figureBSide > 0 && figureA1Side > 0 && figureHeight > 0){
                    appContext.dispatch({type: 'change-figure-sides', payload: {...appContext.state.figureSides, figureASide: figureASide, figureA1Side: figureA1Side, 
                        figureBSide: figureBSide, figureCSide: figureCSide, figureDSide: figureDSide, figureESide: figureESide, figureHeight: figureHeight, figureLSide: figureLSide}});
                }
                else{alert("введіть будь ласка всі данні")}
                break;
            }
            case FIGURES.F15: {
                if(figureASide > 0 && figureBSide > 0 && figureHeight > 0){
                    appContext.dispatch({type: 'change-figure-sides', payload: {...appContext.state.figureSides, figureASide: figureASide, figureA1Side: figureA1Side, 
                        figureBSide: figureBSide, figureCSide: figureCSide, figureDSide: figureDSide, figureESide: figureESide, figureHeight: figureHeight, figureLSide: figureLSide}});
                }
                else{alert("введіть будь ласка всі данні")}
                break;
            }
            case FIGURES.F16: {
                if(figureASide > 0 && figureBSide > 0 && figureHeight > 0){
                    appContext.dispatch({type: 'change-figure-sides', payload: {...appContext.state.figureSides, figureASide: figureASide, figureA1Side: figureA1Side, 
                        figureBSide: figureBSide, figureCSide: figureCSide, figureDSide: figureDSide, figureESide: figureESide, figureHeight: figureHeight, figureLSide: figureLSide}});
                }
                else{alert("введіть будь ласка всі данні")}
                break;
            }
            case FIGURES.F17: {
                if(figureASide > 0 && figureBSide > 0 && figureCSide > 0 && figureDSide > 0 && figureHeight > 0){
                    appContext.dispatch({type: 'change-figure-sides', payload: {...appContext.state.figureSides, figureASide: figureASide, figureA1Side: figureA1Side, 
                        figureBSide: figureBSide, figureCSide: figureCSide, figureDSide: figureDSide, figureESide: figureESide, figureHeight: figureHeight, figureLSide: figureLSide}});
                }
                else{alert("введіть будь ласка всі данні")}
                break;
            }
            case FIGURES.F18: {
                if(figureASide > 0 && figureA1Side > 0 && figureBSide > 0 && figureCSide > 0 && figureDSide > 0 && figureHeight > 0 && figureLSide > 0){
                    appContext.dispatch({type: 'change-figure-sides', payload: {...appContext.state.figureSides, figureASide: figureASide, figureA1Side: figureA1Side, 
                        figureBSide: figureBSide, figureCSide: figureCSide, figureDSide: figureDSide, figureESide: figureESide, figureHeight: figureHeight, figureLSide: figureLSide}});
                }
                else{alert("введіть будь ласка всі данні")}
                break;
            }
            case FIGURES.F19: {
                if(figureASide > 0 && figureBSide > 0 && figureCSide > 0 && figureDSide > 0){
                    appContext.dispatch({type: 'change-figure-sides', payload: {...appContext.state.figureSides, figureASide: figureASide, figureA1Side: figureA1Side, 
                        figureBSide: figureBSide, figureCSide: figureCSide, figureDSide: figureDSide, figureESide: figureESide, figureHeight: figureHeight, figureLSide: figureLSide}});
                }
                else{alert("введіть будь ласка всі данні")}
                break;
            }
            case FIGURES.F20: {
                if(figureASide > 0 && figureBSide > 0 && figureCSide > 0 && figureDSide > 0){
                    appContext.dispatch({type: 'change-figure-sides', payload: {...appContext.state.figureSides, figureASide: figureASide, figureA1Side: figureA1Side, 
                        figureBSide: figureBSide, figureCSide: figureCSide, figureDSide: figureDSide, figureESide: figureESide, figureHeight: figureHeight, figureLSide: figureLSide}});
                }
                else{alert("введіть будь ласка всі данні")}
                break;
            }
            case FIGURES.F21: {
                if(figureASide > 0 && figureA1Side > 0 && figureBSide > 0 && figureCSide > 0 && figureDSide > 0 && figureESide > 0 && figureHeight > 0 && figureLSide > 0){
                    appContext.dispatch({type: 'change-figure-sides', payload: {...appContext.state.figureSides, figureASide: figureASide, figureA1Side: figureA1Side, 
                        figureBSide: figureBSide, figureCSide: figureCSide, figureDSide: figureDSide, figureESide: figureESide, figureHeight: figureHeight, figureLSide: figureLSide}});
                }
                else{alert("введіть будь ласка всі данні")}
                break;
            }
            case FIGURES.F22: {
                if(figureASide > 0 && figureBSide > 0 && figureCSide > 0 && figureDSide > 0){
                    appContext.dispatch({type: 'change-figure-sides', payload: {...appContext.state.figureSides, figureASide: figureASide, figureA1Side: figureA1Side, 
                        figureBSide: figureBSide, figureCSide: figureCSide, figureDSide: figureDSide, figureESide: figureESide, figureHeight: figureHeight, figureLSide: figureLSide}});
                }
                else{alert("введіть будь ласка всі данні")}
                break;
            }
            case FIGURES.F23: {
                if(figureASide > 0 && figureBSide > 0 && figureCSide > 0){
                    appContext.dispatch({type: 'change-figure-sides', payload: {...appContext.state.figureSides, figureASide: figureASide, figureA1Side: figureA1Side, 
                        figureBSide: figureBSide, figureCSide: figureCSide, figureDSide: figureDSide, figureESide: figureESide, figureHeight: figureHeight, figureLSide: figureLSide}});
                }
                else{alert("введіть будь ласка всі данні")}
                break;
            }
            case FIGURES.F24: {
                if(figureASide > 0 && figureBSide > 0 && figureCSide > 0){
                    appContext.dispatch({type: 'change-figure-sides', payload: {...appContext.state.figureSides, figureASide: figureASide, figureA1Side: figureA1Side, 
                        figureBSide: figureBSide, figureCSide: figureCSide, figureDSide: figureDSide, figureESide: figureESide, figureHeight: figureHeight, figureLSide: figureLSide}});
                }
                else{alert("введіть будь ласка всі данні")}
                break;
            }
            case FIGURES.F25: {
                if(figureASide > 0){
                    appContext.dispatch({type: 'change-figure-sides', payload: {...appContext.state.figureSides, figureASide: figureASide, figureA1Side: figureA1Side, 
                        figureBSide: figureBSide, figureCSide: figureCSide, figureDSide: figureDSide, figureESide: figureESide, figureHeight: figureHeight, figureLSide: figureLSide}});
                }
                else{alert("введіть будь ласка всі данні")}
                break;
            }
            case FIGURES.F26: {
                if(figureASide > 0 && figureBSide > 0 && figureCSide > 0 && figureHeight > 0){
                    appContext.dispatch({type: 'change-figure-sides', payload: {...appContext.state.figureSides, figureASide: figureASide, figureA1Side: figureA1Side, 
                        figureBSide: figureBSide, figureCSide: figureCSide, figureDSide: figureDSide, figureESide: figureESide, figureHeight: figureHeight, figureLSide: figureLSide}});
                }
                else{alert("введіть будь ласка всі данні")}
                break;
            }
            case FIGURES.F27: {
                if(figureASide > 0 && figureBSide > 0 && figureCSide > 0 && figureDSide > 0 && figureESide > 0 && figureHeight > 0){
                    appContext.dispatch({type: 'change-figure-sides', payload: {...appContext.state.figureSides, figureASide: figureASide, figureA1Side: figureA1Side, 
                        figureBSide: figureBSide, figureCSide: figureCSide, figureDSide: figureDSide, figureESide: figureESide, figureHeight: figureHeight, figureLSide: figureLSide}});
                }
                else{alert("введіть будь ласка всі данні")}
                break;
            }
            case FIGURES.F28: {
                if(figureASide > 0 && figureBSide > 0 && figureCSide > 0 && figureDSide > 0){
                    appContext.dispatch({type: 'change-figure-sides', payload: {...appContext.state.figureSides, figureASide: figureASide, figureA1Side: figureA1Side, 
                        figureBSide: figureBSide, figureCSide: figureCSide, figureDSide: figureDSide, figureESide: figureESide, figureHeight: figureHeight, figureLSide: figureLSide}});
                }
                else{alert("введіть будь ласка всі данні")}
                break;
            }
            case FIGURES.F29: {
                if(figureASide > 0 && figureBSide > 0 && figureCSide > 0 && figureDSide > 0){
                    appContext.dispatch({type: 'change-figure-sides', payload: {...appContext.state.figureSides, figureASide: figureASide, figureA1Side: figureA1Side, 
                        figureBSide: figureBSide, figureCSide: figureCSide, figureDSide: figureDSide, figureESide: figureESide, figureHeight: figureHeight, figureLSide: figureLSide}});
                }
                else{alert("введіть будь ласка всі данні")}
                break;
            }
            case FIGURES.F30: {
                if(figureASide > 0 && figureBSide > 0 && figureCSide > 0 && figureDSide > 0){
                    appContext.dispatch({type: 'change-figure-sides', payload: {...appContext.state.figureSides, figureASide: figureASide, figureA1Side: figureA1Side, 
                        figureBSide: figureBSide, figureCSide: figureCSide, figureDSide: figureDSide, figureESide: figureESide, figureHeight: figureHeight, figureLSide: figureLSide}});
                }
                else{alert("введіть будь ласка всі данні")}
                break;
            }
            case FIGURES.F31: {
                if(figureASide > 0 && figureBSide > 0 && figureHeight > 0){
                    appContext.dispatch({type: 'change-figure-sides', payload: {...appContext.state.figureSides, figureASide: figureASide, figureA1Side: figureA1Side, 
                        figureBSide: figureBSide, figureCSide: figureCSide, figureDSide: figureDSide, figureESide: figureESide, figureHeight: figureHeight, figureLSide: figureLSide}});
                }
                else{alert("введіть будь ласка всі данні")}
                break;
            }
            case FIGURES.F32: {
                if(figureASide > 0 && figureBSide > 0 && figureCSide > 0 && figureDSide > 0 && figureHeight > 0){
                    appContext.dispatch({type: 'change-figure-sides', payload: {...appContext.state.figureSides, figureASide: figureASide, figureA1Side: figureA1Side, 
                        figureBSide: figureBSide, figureCSide: figureCSide, figureDSide: figureDSide, figureESide: figureESide, figureHeight: figureHeight, figureLSide: figureLSide}});
                }
                else{alert("введіть будь ласка всі данні")}
                break;
            }
            default: {
                break;
            }
        }
    }

    
    function prevStepBtnHandler(e: any) {
        setPrevStep(true)
    }
    function nextStepBtnHandler(e: any) {
        setNextStep(true)
    }
    function deleteBtnHandler(e: any) {
        setDeleteDot(true)
    }
    
    function DoubleCutStartBtnHandler(e: any) {
        setOnCutFigureStartClick(true)
    }
    function CopyCutBtnHandler(e: any) {
        setXCutShift(copyCutValue / 100000)
        setQwer(copyCutCount - 1)
        setDoubleCutFigure(true)
        setCopyCutCount(0);
        setCopyCutValue(0);
    }
    function turnFigureBtnHandler(e: any) {
        setDegreesTurnFigure(degree)
        setTurnFigure(true)
    }
    function DeleteCutFigureBtnHandler(e: any) {
        setDeleteCutFigure(true)
    }
    React.useEffect(() => {
        if(doubleCutFigure == false && qwer > 0){
            setDoubleCutFigure(true)
            setQwer(qwer - 1)
        }
    }, [doubleCutFigure]);

    function onChooseFigure(id: any) {
        let figureId = id;

        // To switch case
        appContext.dispatch({type: 'set-selected-figure', payload: {selectedFigure: figureId}});
        appContext.dispatch({type: 'set-edited-mode', payload: {editedMode: 1, isEditedMode: true}});
        appContext.dispatch({type: 'set-build-mode', payload: {isBuildMode: false}});
        appContext.dispatch({type: 'set-clicked-coords', payload: {clickedCoords: []}});
        appContext.dispatch({type: 'set-cut-figure-mode',payload: {isCutFigureMode: false}});

        // To switch case
        appContext.dispatch({
            type: 'change-figure-sides',
            payload: {
                figureASide: Number(0),
                figureA1Side: Number(0),
                figureBSide: Number(0),
                figureCSide: Number(0),
                figureDSide: Number(0),
                figureESide: Number(0),
                figureHeight: Number(0),
                figureLSide: Number(0),
            }
        });
    }
    function onOpenMenuClickHandler(e: any) {
        if(isMenuOpen == false){
            setIsMenuOpen(true)
        }
    }
    function onCloseMenuClickHandler(e: any) {
        if(isMenuOpen == true){
            setIsMenuOpen(false)
        }
    }
    function onFlipXButtonClickHandler(e: any) {
        setFlipFigureX(true)
    }
    function onFlipYButtonClickHandler(e: any) {
        setFlipFigureY(true)
    }

    function onAddEavesButtonClickHandler(e: any) {
        setAddApex(false);
        setAddEaves(true);
    }
    function onAddApexButtonClickHandler(e: any) {
        setAddEaves(false);
        setAddApex(true);
    }
    return(
        <>
            <div className="wrapper">
                <div className="dropdown">
                    <button
                        className={"top_btn figure__button"}
                        onClick={() => onChooseFigure(3)}
                    >
                        <img  src={squareTopIcon}/>
                    </button>
                    <div className="dropdown-content">
                        <button
                            className={`figure__button button ${appContext.state.selectedFigure === FIGURES.F1 ? 'active-figure1' : ''}`}
                            onClick={() => onChooseFigure(1)}
                        >
                            <img  src={fig1}/>
                        </button>
                        <button
                            className={`figure__button button ${appContext.state.selectedFigure === FIGURES.F2 ? 'active-figure1' : ''}`}
                            onClick={() => onChooseFigure(2)}
                        >
                            <img src={fig2}/>
                        </button>
                        <button
                            className={`figure__button button ${appContext.state.selectedFigure === FIGURES.F3 ? 'active-figure1' : ''}`}
                            onClick={() => onChooseFigure(3)}
                        >
                            <img  src={fig3} alt="Квадрат"/>
                        </button>
                        <button
                            className={`figure__button button ${appContext.state.selectedFigure === FIGURES.F4 ? 'active-figure1' : ''}`}
                            onClick={() => onChooseFigure(4)}
                        >
                            <img  src={fig4}/>
                        </button>
                        <button
                            className={`figure__button button ${appContext.state.selectedFigure === FIGURES.F5 ? 'active-figure1' : ''}`}
                            onClick={() => onChooseFigure(5)}
                        >
                            <img  src={fig5}/>
                        </button>
                        <button
                            className={`figure__button button ${appContext.state.selectedFigure === FIGURES.F6 ? 'active-figure1' : ''}`}
                            onClick={() => onChooseFigure(6)}
                        >
                            <img  src={fig6}/>
                        </button>
                        <button
                            className={`figure__button button ${appContext.state.selectedFigure === FIGURES.F7 ? 'active-figure1' : ''}`}
                            onClick={() => onChooseFigure(7)}
                        >
                            <img  src={fig7}/>
                        </button>
                        <button
                            className={`figure__button button ${appContext.state.selectedFigure === FIGURES.F8 ? 'active-figure1' : ''}`}
                            onClick={() => onChooseFigure(8)}
                        >
                            <img  src={fig8}/>
                        </button>
                        <button
                            className={`figure__button button ${appContext.state.selectedFigure === FIGURES.F9 ? 'active-figure1' : ''}`}
                            onClick={() => onChooseFigure(9)}
                        >
                            <img  src={fig9}/>
                        </button>
                        <button
                            className={`figure__button button ${appContext.state.selectedFigure === FIGURES.F10 ? 'active-figure1' : ''}`}
                            onClick={() => onChooseFigure(10)}
                        >
                            <img  src={fig10}/>
                        </button>
                        <button
                            className={`figure__button button ${appContext.state.selectedFigure === FIGURES.F11 ? 'active-figure1' : ''}`}
                            onClick={() => onChooseFigure(11)}
                        >
                            <img  src={fig11}/>
                        </button>
                        <button
                            className={`figure__button button ${appContext.state.selectedFigure === FIGURES.F12 ? 'active-figure1' : ''}`}
                            onClick={() => onChooseFigure(12)}
                        >
                            <img  src={fig12}/>
                        </button>
                        <button
                            className={`figure__button button ${appContext.state.selectedFigure === FIGURES.F13 ? 'active-figure1' : ''}`}
                            onClick={() => onChooseFigure(13)}
                        >
                            <img  src={fig13}/>
                        </button>
                        <button
                            className={`figure__button button ${appContext.state.selectedFigure === FIGURES.F14 ? 'active-figure1' : ''}`}
                            onClick={() => onChooseFigure(14)}
                        >
                            <img  src={fig14}/>
                        </button>
                        <button
                            className={`figure__button button ${appContext.state.selectedFigure === FIGURES.F15 ? 'active-figure1' : ''}`}
                            onClick={() => onChooseFigure(15)}
                        >
                            <img  src={fig15}/>
                        </button>
                        <button
                            className={`figure__button button ${appContext.state.selectedFigure === FIGURES.F16 ? 'active-figure1' : ''}`}
                            onClick={() => onChooseFigure(16)}
                        >
                            <img  src={fig16}/>
                        </button>
                        <button
                            className={`figure__button button ${appContext.state.selectedFigure === FIGURES.F17 ? 'active-figure1' : ''}`}
                            onClick={() => onChooseFigure(17)}
                        >
                            <img  src={fig17}/>
                        </button>
                        <button
                            className={`figure__button button ${appContext.state.selectedFigure === FIGURES.F18 ? 'active-figure1' : ''}`}
                            onClick={() => onChooseFigure(18)}
                        >
                            <img  src={fig18}/>
                        </button>
                        <button
                            className={`figure__button button ${appContext.state.selectedFigure === FIGURES.F19 ? 'active-figure1' : ''}`}
                            onClick={() => onChooseFigure(19)}
                        >
                            <img  src={fig19}/>
                        </button>
                        <button
                            className={`figure__button button ${appContext.state.selectedFigure === FIGURES.F20 ? 'active-figure1' : ''}`}
                            onClick={() => onChooseFigure(20)}
                        >
                            <img  src={fig20}/>
                        </button>
                        <button
                            className={`figure__button button ${appContext.state.selectedFigure === FIGURES.F21 ? 'active-figure1' : ''}`}
                            onClick={() => onChooseFigure(21)}
                        >
                            <img  src={fig21}/>
                        </button>
                        <button
                            className={`figure__button button ${appContext.state.selectedFigure === FIGURES.F22 ? 'active-figure1' : ''}`}
                            onClick={() => onChooseFigure(22)}
                        >
                            <img  src={fig22}/>
                        </button>
                        <button
                            className={`figure__button button ${appContext.state.selectedFigure === FIGURES.F23 ? 'active-figure1' : ''}`}
                            onClick={() => onChooseFigure(23)}
                        >
                            <img  src={fig23}/>
                        </button>
                        <button
                            className={`figure__button button ${appContext.state.selectedFigure === FIGURES.F24 ? 'active-figure1' : ''}`}
                            onClick={() => onChooseFigure(24)}
                        >
                            <img  src={fig24}/>
                        </button>
                        <button
                            className={`figure__button button ${appContext.state.selectedFigure === FIGURES.F25 ? 'active-figure1' : ''}`}
                            onClick={() => onChooseFigure(25)}
                        >
                            <img  src={fig25}/>
                        </button>
                        <button
                            className={`figure__button button ${appContext.state.selectedFigure === FIGURES.F26 ? 'active-figure1' : ''}`}
                            onClick={() => onChooseFigure(26)}
                        >
                            <img  src={fig26}/>
                        </button>
                        <button
                            className={`figure__button button ${appContext.state.selectedFigure === FIGURES.F27 ? 'active-figure1' : ''}`}
                            onClick={() => onChooseFigure(27)}
                        >
                            <img  src={fig27}/>
                        </button>
                        <button
                            className={`figure__button button ${appContext.state.selectedFigure === FIGURES.F28 ? 'active-figure1' : ''}`}
                            onClick={() => onChooseFigure(28)}
                        >
                            <img  src={fig28}/>
                        </button>
                        <button
                            className={`figure__button button ${appContext.state.selectedFigure === FIGURES.F29 ? 'active-figure1' : ''}`}
                            onClick={() => onChooseFigure(29)}
                        >
                            <img  src={fig29}/>
                        </button>
                        <button
                            className={`figure__button button ${appContext.state.selectedFigure === FIGURES.F30 ? 'active-figure1' : ''}`}
                            onClick={() => onChooseFigure(30)}
                        >
                            <img  src={fig30}/>
                        </button>
                        <button
                            className={`figure__button button ${appContext.state.selectedFigure === FIGURES.F31 ? 'active-figure1' : ''}`}
                            onClick={() => onChooseFigure(31)}
                        >
                            <img  src={fig31}/>
                        </button>
                        <button
                            className={`figure__button button ${appContext.state.selectedFigure === FIGURES.F32 ? 'active-figure1' : ''}`}
                            onClick={() => onChooseFigure(32)}
                        >
                            <img  src={fig32}/>
                        </button>
                        
                        
                    </div>
                </div> 
                <div className="dott_button_block">
                    <button onClick={prevStepBtnHandler} className="button"><img src={Prev} alt="назад" title="назад"/></button>
                    <button onClick={nextStepBtnHandler} className="button"><img src={Next} alt="вперед" title="вперед"/></button>
                    <button onClick={deleteBtnHandler} className="button"><img src={Delete} alt="видалити точку" title="видалити точку"/></button>
                    <button onClick={DoubleCutStartBtnHandler} className="button"><img src={CoordsStart} alt="задати точку відліку" title="задати точку відліку"/></button>
                    <button onClick={DeleteCutFigureBtnHandler} className="button"><img src={CutDelete} alt="видалити вирізану фігуру" title="видалити вирізану фігуру"/></button>
                </div>
                { areasCount > 0 ?
                    <div className="cut_button_block">
                        <button className="button" onClick={onFlipXButtonClickHandler}><img src={FlipX} alt="відзеркалити по горизонталі" title="відзеркалити по горизонталі"/></button>
                        <button className="button" onClick={onFlipYButtonClickHandler}><img src={FlipY} alt="відзеркалити по вертикалі" title="відзеркалити по вертикалі"/></button>
                        <button className="button" onClick={onAddEavesButtonClickHandler}><img src={Eaves} alt="додати карниз" title="додати карниз"/></button>
                        <button className="button" onClick={onAddApexButtonClickHandler}><img src={Apex} alt="додати коньок" title="додати коньок"/></button>
                    </div>
                : null
                }
                <div className="double_button_block">
                    <span></span>
                    <input className="figures-input"
                            value={degree || ""}
                            onChange={onChangeDegreeHandler.bind(this)}
                            disabled={appContext.state.editedMode > 1/*
                                || appContext.state.selectedFigure === FIGURES.None
                                || appContext.state.selectedFigure === FIGURES.Square
                                || appContext.state.selectedFigure === FIGURES.Rectangle
                                || appContext.state.selectedFigure === FIGURES.Triangular
                                || appContext.state.selectedFigure === FIGURES.Trapezoid*/
                            }
                    />
                    <span>°</span>
                    <button  className="button" onClick={turnFigureBtnHandler}><img src={Check}/></button>
                                
                </div>
                <div className="double_button_block">
                    <button className="button" onClick={onOpenMenuClickHandler.bind(this)}><img src={Arrows} title="відкрити"/></button>
                    {
                        isMenuOpen ? 
                            <>
                                <input className="figures-input"
                                value={copyCutCount || ""}
                                onChange={onChangeCopyCutCountHandler.bind(this)}
                                disabled={appContext.state.editedMode > 1/*
                                    || appContext.state.selectedFigure === FIGURES.None
                                    || appContext.state.selectedFigure === FIGURES.Square
                                    || appContext.state.selectedFigure === FIGURES.Rectangle
                                    || appContext.state.selectedFigure === FIGURES.Triangular
                                    || appContext.state.selectedFigure === FIGURES.Trapezoid*/
                                }
                                />
                                <span>разів, через</span>
                                <input className="figures-input"
                                        value={copyCutValue || ""}
                                        onChange={onChangeCopyCutValueHandler.bind(this)}
                                        disabled={appContext.state.editedMode > 1/*
                                            || appContext.state.selectedFigure === FIGURES.None
                                            || appContext.state.selectedFigure === FIGURES.Square
                                            || appContext.state.selectedFigure === FIGURES.Rectangle
                                            || appContext.state.selectedFigure === FIGURES.Triangular
                                            || appContext.state.selectedFigure === FIGURES.Trapezoid*/
                                        }
                                />
                                <span>мм.</span>
                                <button  className="button" onClick={CopyCutBtnHandler}><img src={Check}/></button>
                                <button  className="button"  onClick={onCloseMenuClickHandler}><img src={Cross}/></button>
                            </>
                        :null
                    }
                </div>
                
                <div className="sides_blocks">

                    { appContext.state.selectedFigure > 1 && isReadyFigureBuild == false ?
                            <div className="field-size-wrapper">
                                <span className="darkgray-text bold-text">A:</span>
                                <input className="figures-input"
                                        id="figure-a-side-input"
                                        value={figureASide || ""}
                                        onChange={onChangeFigureASideHandler.bind(this)}
                                        onFocus={() => setOnAInputFocus(true)}
                                        onBlur={() => setOnAInputFocus(false)}
                                />
                                <span>м</span>
                            </div>
                        : null
                    }
                    {
                        (appContext.state.selectedFigure == 2 || appContext.state.selectedFigure == 13 || appContext.state.selectedFigure == 14 || appContext.state.selectedFigure == 18 || appContext.state.selectedFigure == 21) && isReadyFigureBuild == false? 
                        <div className="field-size-wrapper">
                            <span className="darkgray-text bold-text">A1:</span>
                            <input className="figures-input"
                                    id="figure-a1-side-input"
                                    value={figureA1Side || ""}
                                    onChange={onChangeFigureA1SideHandler.bind(this)}
                                    onFocus={() => {setOnA1InputFocus(true)}}
                                    onBlur={() => setOnA1InputFocus(false)}
                            />
                            <span>м</span>
                        </div>
                        : null
                    }
                    {
                        (appContext.state.selectedFigure == 2 || appContext.state.selectedFigure == 4 || appContext.state.selectedFigure == 5 || appContext.state.selectedFigure == 6 || appContext.state.selectedFigure == 7 || appContext.state.selectedFigure == 8
                        || appContext.state.selectedFigure == 9 || appContext.state.selectedFigure == 10 || appContext.state.selectedFigure == 11 || appContext.state.selectedFigure == 12 || appContext.state.selectedFigure == 13 || appContext.state.selectedFigure == 14 ||
                        appContext.state.selectedFigure == 15 || appContext.state.selectedFigure == 16 || appContext.state.selectedFigure == 17 || appContext.state.selectedFigure == 18 || appContext.state.selectedFigure == 19 || appContext.state.selectedFigure == 20
                        || appContext.state.selectedFigure == 21 || appContext.state.selectedFigure == 22 || appContext.state.selectedFigure == 23 || appContext.state.selectedFigure == 24 || appContext.state.selectedFigure == 26 || appContext.state.selectedFigure == 27
                        || appContext.state.selectedFigure == 28 || appContext.state.selectedFigure == 29 || appContext.state.selectedFigure == 30 || appContext.state.selectedFigure == 31 || appContext.state.selectedFigure == 32) && isReadyFigureBuild == false?
                        <div className="field-size-wrapper">
                            <span className="darkgray-text bold-text">B:</span>
                            <input className="figures-input"
                                    id="figure-b-side-input"
                                    value={figureBSide || ""}
                                    onChange={onChangeFigureBSideHandler.bind(this)}
                                    onFocus={() => setOnBInputFocus(true)}
                                    onBlur={() => setOnBInputFocus(false)}
                            />
                            <span>м</span>
                        </div>
                        : null
                    }
                    {
                        (appContext.state.selectedFigure == 2 || appContext.state.selectedFigure == 9 || appContext.state.selectedFigure == 10 || appContext.state.selectedFigure == 11 || appContext.state.selectedFigure == 12
                        || appContext.state.selectedFigure == 17 || appContext.state.selectedFigure == 18 || appContext.state.selectedFigure == 19 || appContext.state.selectedFigure == 20 || appContext.state.selectedFigure == 22
                        || appContext.state.selectedFigure == 21 || appContext.state.selectedFigure == 23 || appContext.state.selectedFigure == 24 || appContext.state.selectedFigure == 26 || appContext.state.selectedFigure == 27 || appContext.state.selectedFigure == 28
                        || appContext.state.selectedFigure == 29 || appContext.state.selectedFigure == 30 || appContext.state.selectedFigure == 32) && isReadyFigureBuild == false?
                        <div className="field-size-wrapper">
                            <span className="darkgray-text bold-text">C:</span>
                            <input className="figures-input"
                                    id="figure-c-side-input"
                                    value={figureCSide || ""}
                                    onChange={onChangeFigureCSideHandler.bind(this)}
                                    onFocus={() => setOnCInputFocus(true)}
                                    onBlur={() => setOnCInputFocus(false)}
                            />
                            <span>м</span>
                        </div>
                        : null
                    }
                    {
                        (appContext.state.selectedFigure == 17 || appContext.state.selectedFigure == 18 || appContext.state.selectedFigure == 19 || appContext.state.selectedFigure == 20 || appContext.state.selectedFigure == 21 || appContext.state.selectedFigure == 22
                        || appContext.state.selectedFigure == 21 || appContext.state.selectedFigure == 27 || appContext.state.selectedFigure == 28 || appContext.state.selectedFigure == 29 || appContext.state.selectedFigure == 30 || appContext.state.selectedFigure == 32)
                        && isReadyFigureBuild == false?
                        <div className="field-size-wrapper">
                            <span className="darkgray-text bold-text">D:</span>
                            <input className="figures-input"
                                    id="figure-d-side-input"
                                    value={figureDSide || ""}
                                    onChange={onChangeFigureDSideHandler.bind(this)}
                                    onFocus={() => setOnDInputFocus(true)}
                                    onBlur={() => setOnDInputFocus(false)}
                            />
                            <span>м</span>
                        </div>
                        : null
                    }
                    
                    {
                        (appContext.state.selectedFigure == 27 || appContext.state.selectedFigure == 21) && isReadyFigureBuild == false?
                        <div className="field-size-wrapper">
                            <span className="darkgray-text bold-text">E:</span>
                            <input className="figures-input"
                                    id="figure-e-side-input"
                                    value={figureESide || ""}
                                    onChange={onChangeFigureESideHandler.bind(this)}
                                    onFocus={() => setOnEInputFocus(true)}
                                    onBlur={() => setOnEInputFocus(false)}
                            />
                            <span>м</span>
                        </div>
                        : null
                    }
                    
                    {
                        (appContext.state.selectedFigure == 13 || appContext.state.selectedFigure == 14 || appContext.state.selectedFigure == 15 || appContext.state.selectedFigure == 16 || appContext.state.selectedFigure == 17
                        || appContext.state.selectedFigure == 18 || appContext.state.selectedFigure == 26 || appContext.state.selectedFigure == 27 || appContext.state.selectedFigure == 31 || appContext.state.selectedFigure == 32
                        || appContext.state.selectedFigure == 21) && isReadyFigureBuild == false?
                        <div className="field-size-wrapper">
                            <span className="darkgray-text bold-text">H:</span>
                            <input className="figures-input"
                                    id="figure-h-side-input"
                                    value={figureHeight|| ""}
                                    onChange={onChangeFigureHeightHandler.bind(this)}
                                    onFocus={() => setOnHeightInputFocus(true)}
                                    onBlur={() => setOnHeightInputFocus(false)}
                                    disabled={appContext.state.editedMode > 2/*
                                        || appContext.state.selectedFigure === FIGURES.None
                                        || appContext.state.selectedFigure === FIGURES.Square
                                        || appContext.state.selectedFigure === FIGURES.Rectangle
                                        || appContext.state.selectedFigure === FIGURES.Triangular
                                        || appContext.state.selectedFigure === FIGURES.Trapezoid
                                        || appContext.state.selectedFigure === FIGURES.Polygon*/
                                    }
                            />
                            <span>м</span>
                        </div>
                        : null
                    }
                    {
                        (appContext.state.selectedFigure == 2 || appContext.state.selectedFigure == 18|| appContext.state.selectedFigure == 21) && isReadyFigureBuild == false?
                        <div className="field-size-wrapper">
                            <span className="darkgray-text bold-text">L:</span>
                            <input className="figures-input"
                                    id="figure-l-side-input"
                                    value={figureLSide || ""}
                                    onChange={onChangeFigureLSideHandler.bind(this)}
                                    onFocus={() => setOnLInputFocus(true)}
                                    onBlur={() => setOnLInputFocus(false)}
                                    disabled={appContext.state.editedMode > 1/*
                                        || appContext.state.selectedFigure === FIGURES.None
                                        || appContext.state.selectedFigure === FIGURES.Square
                                        || appContext.state.selectedFigure === FIGURES.Rectangle
                                        || appContext.state.selectedFigure === FIGURES.Triangular
                                        || appContext.state.selectedFigure === FIGURES.Trapezoid
                                        || appContext.state.selectedFigure === FIGURES.Polygon*/
                                    }
                            />
                            <span>м</span>
                        </div>
                        : null  
                    }
                    {
                        isReadyFigureBuild == false ?
                        <button onClick={calcFigureBtnHandler} /*disabled={appContext.state.selectedFigure === FIGURES.None || appContext.state.selectedFigure === FIGURES.Polygon}*/ className="button">
                            <img src={Check} title="підтвердити"/>
                        </button>
                        : null
                    }
                </div>
            </div>
        </>
    )
}

export default TopPanel;
