import { FIGURES} from "./enums/figure.enum";

export const scalesConfig = {
    '1': 1,
    '2': 2,
    '3': 3,
    '4': 4,
    '5': 5,
    '6': 6,
    '7': 7,
    '8': 8,
    '9': 9,
    '10': 10,
    '11': 11,
    '12': 12,
    '13': 13,
    '14': 14,
    '15': 15,
    '16': 16,
    '17': 17,
    '18': 18,
    '19': 19,
    '20': 20,
    '21': 21,
    '22': 22,
    '23': 23,
    '24': 24,
    '25': 25,
    '26': 26,
    '27': 27,
    '28': 28,
    '29': 29,
    '30': 30,
    '31': 31,
    '32': 32,
    '33': 33,
    '34': 34,

}

type gridParamsOptions = {
    [key: string]: any
}

interface IGridParams {
    size1: gridParamsOptions;
}

export const gridParams: IGridParams = {
    size1: { // 1280 x 720
        scale1: {
            width: 1200, height: 800, startCoords: {x: 100, y: 100}, cellSize: 100, gridHorizontalNumbers: 50, gridVerticalNumbers: 50,
        },
        scale2: {
            width: 1200, height: 800, startCoords: {x: 75, y: 75}, cellSize: 88.7, gridHorizontalNumbers: 14, gridVerticalNumbers: 8,
        },
        scale3: {
            width: 1200, height: 800, startCoords: {x: 50, y: 50}, cellSize: 80, gridHorizontalNumbers: 22, gridVerticalNumbers: 12,
        },
        scale4: {
            width: 1200, height: 800, startCoords: {x: 25, y: 25}, cellSize: 72.5, gridHorizontalNumbers: 34, gridVerticalNumbers: 19,
        },
        scale5: {
            width: 1200, height: 800, startCoords: {x: 92, y: 99}, cellSize: 66.5, gridHorizontalNumbers: 34, gridVerticalNumbers: 19,
        },
        scale6: {
            width: 1200, height: 800, startCoords: {x: 84, y: 84}, cellSize: 61.5, gridHorizontalNumbers: 34, gridVerticalNumbers: 19,
        },
        scale7: {
            width: 1200, height: 800, startCoords: {x: 84, y: 84}, cellSize: 57, gridHorizontalNumbers: 34, gridVerticalNumbers: 19,
        },
        scale8: {
            width: 1200, height: 800, startCoords: {x: 84, y: 84}, cellSize: 53.3, gridHorizontalNumbers: 34, gridVerticalNumbers: 19,
        },
        scale9: {
            width: 1200, height: 800, startCoords: {x: 84, y: 84}, cellSize: 50, gridHorizontalNumbers: 34, gridVerticalNumbers: 19,
        },
        scale10: {
            width: 1200, height: 800, startCoords: {x: 84, y: 84}, cellSize: 47, gridHorizontalNumbers: 34, gridVerticalNumbers: 19,
        },
        scale11: {
            width: 1200, height: 800, startCoords: {x: 84, y: 84}, cellSize: 44.3, gridHorizontalNumbers: 34, gridVerticalNumbers: 19,
        },
        scale12: {
            width: 1200, height: 800, startCoords: {x: 84, y: 84}, cellSize: 42, gridHorizontalNumbers: 34, gridVerticalNumbers: 19,
        },
        scale13: {
            width: 1200, height: 800, startCoords: {x: 84, y: 84}, cellSize: 40, gridHorizontalNumbers: 34, gridVerticalNumbers: 19,
        },
        scale14: {
            width: 1200, height: 800, startCoords: {x: 84, y: 84}, cellSize: 38.1, gridHorizontalNumbers: 34, gridVerticalNumbers: 19,
        },
        scale15: {
            width: 1200, height: 800, startCoords: {x: 84, y: 84}, cellSize: 36.3, gridHorizontalNumbers: 34, gridVerticalNumbers: 19,
        },
        scale16: {
            width: 1200, height: 800, startCoords: {x: 84, y: 84}, cellSize: 34.7, gridHorizontalNumbers: 34, gridVerticalNumbers: 19,
        },
        scale17: {
            width: 1200, height: 800, startCoords: {x: 84, y: 84}, cellSize: 33.3, gridHorizontalNumbers: 34, gridVerticalNumbers: 19,
        },
        scale18: {
            width: 1200, height: 800, startCoords: {x: 84, y: 84}, cellSize: 32, gridHorizontalNumbers: 34, gridVerticalNumbers: 19,
        },
        scale19: {
            width: 1200, height: 800, startCoords: {x: 84, y: 84}, cellSize: 30.7, gridHorizontalNumbers: 34, gridVerticalNumbers: 19,
        },
        scale20: {
            width: 1200, height: 800, startCoords: {x: 84, y: 84}, cellSize: 29.6, gridHorizontalNumbers: 34, gridVerticalNumbers: 19,
        },
        scale21: {
            width: 1200, height: 800, startCoords: {x: 84, y: 84}, cellSize: 28.5, gridHorizontalNumbers: 34, gridVerticalNumbers: 19,
        },
        scale22: {
            width: 1200, height: 800, startCoords: {x: 84, y: 84}, cellSize: 27.5, gridHorizontalNumbers: 34, gridVerticalNumbers: 19,
        },
        scale23: {
            width: 1200, height: 800, startCoords: {x: 84, y: 84}, cellSize: 26.6, gridHorizontalNumbers: 34, gridVerticalNumbers: 19,
        },
        scale24: {
            width: 1200, height: 800, startCoords: {x: 84, y: 84}, cellSize: 25.8, gridHorizontalNumbers: 34, gridVerticalNumbers: 19,
        },
        scale25: {
            width: 1200, height: 800, startCoords: {x: 84, y: 84}, cellSize: 25, gridHorizontalNumbers: 34, gridVerticalNumbers: 19,
        },
        scale26: {
            width: 1200, height: 800, startCoords: {x: 84, y: 84}, cellSize: 24.2, gridHorizontalNumbers: 34, gridVerticalNumbers: 19,
        },
        scale27: {
            width: 1200, height: 800, startCoords: {x: 84, y: 84}, cellSize: 23.5, gridHorizontalNumbers: 34, gridVerticalNumbers: 19,
        },
        scale28: {
            width: 1200, height: 800, startCoords: {x: 84, y: 84}, cellSize: 22.8, gridHorizontalNumbers: 34, gridVerticalNumbers: 19,
        },
        scale29: {
            width: 1200, height: 800, startCoords: {x: 84, y: 84}, cellSize: 22.2, gridHorizontalNumbers: 34, gridVerticalNumbers: 19,
        },
        scale30: {
            width: 1200, height: 800, startCoords: {x: 84, y: 84}, cellSize: 21.6, gridHorizontalNumbers: 34, gridVerticalNumbers: 19,
        },
        scale31: {
            width: 1200, height: 800, startCoords: {x: 84, y: 84}, cellSize: 21, gridHorizontalNumbers: 34, gridVerticalNumbers: 19,
        },
        scale32: {
            width: 1200, height: 800, startCoords: {x: 84, y: 84}, cellSize: 20.5, gridHorizontalNumbers: 34, gridVerticalNumbers: 19,
        },
        scale33: {
            width: 1200, height: 800, startCoords: {x: 84, y: 84}, cellSize: 20, gridHorizontalNumbers: 34, gridVerticalNumbers: 19,
        },
        scale34: {
            width: 1200, height: 800, startCoords: {x: 84, y: 84}, cellSize: 19.5, gridHorizontalNumbers: 34, gridVerticalNumbers: 19,
        },
    }
};

export const initialState: any = {
    rectangleStartPoint: {x: 0, y: 0},
    gridConfig: {
        width: 0, height: 0, startCoords: {x: 0, y: 0}, cellSize: 0, gridHorizontalNumbers: 0, gridVerticalNumbers: 0,
    },
    selectedScale: 1,
    gridSizes: {
        width: 1200,
        height: 800,
    },
    cellSize: 100,
    gridHorizontalNumbers: 10,
    gridVerticalNumbers: 5,
    startCoords: {
        x: 100,
        y: 100,
    },
    selectedFigure: FIGURES.F1,
    figureSides: {
        figureASide: 0,
        figureA1Side: 0,
        figureBSide: 0,
        figureCSide: 0,
        figureDSide: 0,
        figureESide: 0,
        figureHeight: 0,
        figureLSide: 0
    },
    editedMode: 1,
    isEditedMode: true,
    isAddApexFigureMode: false,
    isBuildMode: false,
    clickedCoords: {
        x: null,
        y: null,
    },
    dictionary: []
}

export const defaultPointedFigures = {
    isSquire: false,
    isRectangle: false,
    isTrapeze: false,
    isTriangular: false,
    isCurrentFigure: false,
};

export const defaultFullFigures = {
    isFullSquire: false,
    isFullRectangle: false,
    isFullTrapezoid: false,
    isFullTriangle: false,
    isFullFigure: false,
};

export const defaultFigureSides = {
    figureASide: 0,
    figureA1Side: 0,
    figureBSide: 0,
    figureCSide: 0,
    figureDSide: 0,
    figureESide: 0,
    figureHeight: 0,
    figureLSide: 0
};
