import React, {FC, useContext, useState} from "react";

import "./TheoremBlock.scss";

import { AppContext } from "../../../context/AppContext"

import Delete from "../../../images/delete.png"
import Check from "../../../images/check.png"


interface Props {

}

const TheoremBlock: FC<Props> = ({}) => {

    const [figureACat, setFigureACat] = useState(0);
    const [figureBCat, setFigureBCat] = useState(0);
    const [hypotenuse, setHypotenuse] = useState(0);
    const [figureAAngle, setFigureAAngle] = useState(0);
    const [figureBAngle, setFigureBAngle] = useState(0);
    const [figureCAngle, setFigureCAngle] = useState(90);
    
    function onChangeFigureASideHandler(e: any) {
        setFigureACat(e.target.value);
    }
    function onChangeFigureBSideHandler(e: any) {
        setFigureBCat(e.target.value);
    }
    function onChangeFigureCSideHandler(e: any) {
        setHypotenuse(e.target.value);
    }
    function onChangeFigureAAngleHandler(e: any) {
        setFigureAAngle(e.target.value);
    }
    function onChangeFigureBAngleHandler(e: any) {
        setFigureBAngle(e.target.value);
    }

    function CalcSidesBtnHandler(e: any) {
        if(figureACat !== 0 && figureBCat !== 0 && hypotenuse == 0 && figureAAngle == 0 && figureBAngle == 0){
            setHypotenuse(Math.sqrt(Math.pow(figureACat, 2) + Math.pow(figureBCat, 2)))
            setFigureAAngle(180 / Math.PI * Math.atan2(figureACat, figureBCat))
            setFigureBAngle(figureCAngle - (180 / Math.PI * Math.atan2(figureACat, figureBCat)))
        }
        if(figureACat !== 0 && figureBCat == 0 && hypotenuse !== 0 && figureAAngle == 0 && figureBAngle == 0){
            setFigureBCat(Math.sqrt(Math.pow(hypotenuse, 2) - Math.pow(figureACat, 2)))
            setFigureAAngle(180 / Math.PI * Math.atan2(figureACat, (Math.sqrt(Math.pow(hypotenuse, 2) - Math.pow(figureACat, 2)))))
            setFigureBAngle(figureCAngle - (180 / Math.PI * Math.atan2(figureACat, (Math.sqrt(Math.pow(hypotenuse, 2) - Math.pow(figureACat, 2))))))
        }
        if(figureACat == 0 && figureBCat !== 0 && hypotenuse !== 0 && figureAAngle == 0 && figureBAngle == 0){
            setFigureACat(Math.sqrt(Math.pow(hypotenuse, 2) - Math.pow(figureBCat, 2)))
            setFigureAAngle(180 / Math.PI * Math.atan2((Math.sqrt(Math.pow(hypotenuse, 2) - Math.pow(figureBCat, 2))), figureBCat))
            setFigureBAngle(figureCAngle - (180 / Math.PI * Math.atan2((Math.sqrt(Math.pow(hypotenuse, 2) - Math.pow(figureBCat, 2))), figureBCat)))
        }
        if(figureACat == 0 && figureBCat == 0 && hypotenuse !== 0 && figureAAngle !== 0 && figureBAngle == 0){
            setFigureACat(hypotenuse * Math.sin(figureAAngle * Math.PI / 180))
            setFigureBCat(Math.sqrt(Math.pow(hypotenuse, 2) - Math.pow(hypotenuse * Math.sin(figureAAngle * Math.PI / 180), 2)))
            setFigureBAngle(figureCAngle - figureAAngle)
        }
        if(figureACat == 0 && figureBCat == 0 && hypotenuse !== 0 && figureAAngle == 0 && figureBAngle !== 0){
            setFigureACat(hypotenuse * Math.sin((figureCAngle - figureBAngle) * Math.PI / 180))
            setFigureBCat(Math.sqrt(Math.pow(hypotenuse, 2) - Math.pow(hypotenuse * Math.sin((figureCAngle - figureBAngle) * Math.PI / 180), 2)))
            setFigureAAngle(figureCAngle - figureBAngle)
        }
        if(figureACat !== 0 && figureBCat == 0 && hypotenuse == 0 && figureAAngle !== 0 && figureBAngle == 0){
            setFigureBCat(figureACat / Math.tan(figureAAngle * Math.PI / 180))
            setHypotenuse(Math.sqrt(Math.pow(figureACat, 2) + Math.pow(figureACat / Math.tan(figureAAngle * Math.PI / 180), 2)))
            setFigureBAngle(figureCAngle - figureAAngle)
        }
        if(figureACat == 0 && figureBCat !== 0 && hypotenuse == 0 && figureAAngle !== 0 && figureBAngle == 0){
            setFigureACat(figureBCat * Math.tan(figureAAngle * Math.PI / 180))
            setHypotenuse(Math.sqrt(Math.pow(figureBCat, 2) + Math.pow(figureBCat * Math.tan(figureAAngle * Math.PI / 180), 2)))
            setFigureBAngle(figureCAngle - figureAAngle)
        }
        if(figureACat !== 0 && figureBCat == 0 && hypotenuse == 0 && figureAAngle == 0 && figureBAngle !== 0){
            setFigureBCat(figureACat / Math.tan((figureCAngle - figureBAngle) * Math.PI / 180))
            setHypotenuse(Math.sqrt(Math.pow(figureACat, 2) + Math.pow(figureACat / Math.tan((figureCAngle - figureBAngle) * Math.PI / 180), 2)))
            setFigureAAngle(figureCAngle - figureBAngle)

        }
        if(figureACat == 0 && figureBCat !== 0 && hypotenuse == 0 && figureAAngle == 0 && figureBAngle !== 0){
            setFigureACat(figureBCat * Math.tan((figureCAngle - figureBAngle) * Math.PI / 180))
            setHypotenuse(Math.sqrt(Math.pow(figureBCat, 2) + Math.pow(figureBCat * Math.tan((figureCAngle - figureBAngle) * Math.PI / 180), 2)))
            setFigureAAngle(figureCAngle - figureBAngle)
        }
        if(figureACat == 0 && figureBCat == 0 && hypotenuse == 0 && figureAAngle !== 0 && figureBAngle !== 0){
            alert("неможливо обрахувати")
            setFigureAAngle(0)
            setFigureBAngle(0)
        }
    }
    function CleanInputsBtnHandler(e: any) {
        setFigureACat(0)
        setFigureBCat(0)
        setHypotenuse(0)
        setFigureAAngle(0)
        setFigureBAngle(0)
    }

    return (
        <>  
            <div className="block-wrapper">
                <div className="wrapper1">
                    <div className="field-size-wrapper1">
                        <input className="figures-input1"
                                value={ figureAAngle || ""}
                                onChange={onChangeFigureAAngleHandler.bind(this)}
                        />
                        <button onClick={CleanInputsBtnHandler} className="button theorem-button"><img src={Delete} alt="видалити" /></button>
                        <button onClick={CalcSidesBtnHandler} className="button theorem-button"><img src={Check} alt="обрахувати" /></button>                       
                    </div>
                    <div className="field-size-wrapper1">
                        <input className="figures-input1"
                                value={ figureBCat || ""}
                                onChange={onChangeFigureBSideHandler.bind(this)}
                        />
                        <input className="figures-input1"
                                value={ hypotenuse || ""}
                                onChange={onChangeFigureCSideHandler.bind(this)}
                        />
                    </div>
                    <div className="field-size-wrapper1">
                        <input className="figures-input1 figures-input2" 
                                value={ figureCAngle + "°"}
                        />
                        <input className="figures-input1 figures-input2"
                                value={ figureACat || ""}
                                onChange={onChangeFigureASideHandler.bind(this)}
                        />
                        <input className="figures-input1 figures-input2"
                                value={ figureBAngle || ""}
                                onChange={onChangeFigureBAngleHandler.bind(this)}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default TheoremBlock;
