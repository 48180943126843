import React, {FC, useState, useContext} from "react";
import { Circle, Layer, Line, Text } from "react-konva";
//import { AnyFunction } from "sequelize/types/utils";
import { idText } from "typescript";
import { AppContext } from "../../context/AppContext"
import {scalesConfig} from "../../data"
import { useGridConfig } from "../../hooks/useGridConfig";
import {gridParams} from "../../data"

import cloneDeep from 'lodash/cloneDeep'

interface Props {
    width: number;
    clickedCoords: any;
    activeMode: any;
    calcPolygonPointsCallback: (points: any[]) => void;
    activeDottCoordsX: any;
    setActiveDottCoordsX: any;
    activeDottCoordsY: any;
    setActiveDottCoordsY: any;
    isDottAdditional: any;
    setIsDottAdditional: any;
    prevStep: any;
    setPrevStep: any;
    nextStep: any;
    setNextStep: any;
    deleteDot: any;
    setDeleteDot: any;
    areasCount: any;
    setAreasCount: any;
    addArea: any;
    setAddArea: any;
    addEaves: any;
    setAddEaves: any;
    addApex: any;
    setAddApex: any;
    deleteFigure: any;
    setDeleteFigure: any;
    finishFigure: any;
    setFinishFigure: any;
    changeDott: any;
    setChangeDott: any;
    copyFigure: any;
    setCopyFigure: any;
    flipFigureX: any;
    setFlipFigureX: any;
    flipFigureY: any;
    setFlipFigureY: any;

    onAInputFocus: any; setOnAInputFocus: any;
    onA1InputFocus: any; setOnA1InputFocus: any;
    onBInputFocus: any; setOnBInputFocus: any;
    onCInputFocus: any; setOnCInputFocus: any;
    onDInputFocus: any; setOnDInputFocus: any;
    onEInputFocus: any; setOnEInputFocus: any;
    onHeightInputFocus: any; setOnHeightInputFocus: any;
    onLInputFocus: any; setOnLInputFocus: any;

    turnFigure: any; 
    setTurnFigure: any;
    degreesTurnFigure: any; 
    setDegreesTurnFigure: any;

    areas1: any;
    setAreas1: any;
    areas2: any; 
    setAreas2: any;
    areas3: any;
    setAreas3: any;
    areas4: any;
    setAreas4: any;
    areas5: any; 
    setAreas5: any;
    areas6: any;
    setAreas6: any;
    areas7: any;
    setAreas7: any;
    areas8: any; 
    setAreas8: any;
    areas9: any;
    setAreas9: any;
    areas10: any;
    setAreas10: any;
    areas11: any;
    setAreas11: any;
    areas12: any;
    setAreas12: any;
    areas13: any;
    setAreas13: any;
    areas14: any;
    setAreas14: any;
    areas15: any;
    setAreas15: any;
    areas16: any;
    setAreas16: any;
    areas17: any;
    setAreas17: any;
    areas18: any;
    setAreas18: any;
    areas19: any;
    setAreas19: any;
    areas20: any;
    setAreas20: any;



    cuts1: any;
    setCuts1: any;
    cuts2: any; 
    setCuts2: any;
    cuts3: any;
    setCuts3: any;
    cuts4: any;
    setCuts4: any;
    cuts5: any; 
    setCuts5: any;
    cuts6: any;
    setCuts6: any;
    cuts7: any;
    setCuts7: any;
    cuts8: any; 
    setCuts8: any;
    cuts9: any;
    setCuts9: any;
    cuts10: any;
    setCuts10: any;
    cuts11: any;
    setCuts11: any;
    cuts12: any;
    setCuts12: any;
    cuts13: any;
    setCuts13: any;
    cuts14: any;
    setCuts14: any;
    cuts15: any;
    setCuts15: any;
    cuts16: any;
    setCuts16: any;
    cuts17: any;
    setCuts17: any;
    cuts18: any;
    setCuts18: any;
    cuts19: any;
    setCuts19: any;
    cuts20: any;
    setCuts20: any;



    lines1: any;
    setLines1: any;
    lines2: any; 
    setLines2: any;
    lines3: any;
    setLines3: any;
    lines4: any;
    setLines4: any;
    lines5: any; 
    setLines5: any;
    lines6: any;
    setLines6: any;
    lines7: any;
    setLines7: any;
    lines8: any; 
    setLines8: any;
    lines9: any;
    setLines9: any;
    lines10: any;
    setLines10: any;
    lines11: any;
    setLines11: any;
    lines12: any;
    setLines12: any;
    lines13: any;
    setLines13: any;
    lines14: any;
    setLines14: any;
    lines15: any;
    setLines15: any;
    lines16: any;
    setLines16: any;
    lines17: any;
    setLines17: any;
    lines18: any;
    setLines18: any;
    lines19: any;
    setLines19: any;
    lines20: any;
    setLines20: any;

    cutLines1: any;
    setCutLines1: any;
    cutLines2: any; 
    setCutLines2: any;
    cutLines3: any;
    setCutLines3: any;
    cutLines4: any;
    setCutLines4: any;
    cutLines5: any; 
    setCutLines5: any;
    cutLines6: any;
    setCutLines6: any;
    cutLines7: any;
    setCutLines7: any;
    cutLines8: any; 
    setCutLines8: any;
    cutLines9: any;
    setCutLines9: any;
    cutLines10: any;
    setCutLines10: any;
    cutLines11: any;
    setCutLines11: any;
    cutLines12: any;
    setCutLines12: any;
    cutLines13: any;
    setCutLines13: any;
    cutLines14: any;
    setCutLines14: any;
    cutLines15: any;
    setCutLines15: any;
    cutLines16: any;
    setCutLines16: any;
    cutLines17: any;
    setCutLines17: any;
    cutLines18: any;
    setCutLines18: any;
    cutLines19: any;
    setCutLines19: any;
    cutLines20: any;
    setCutLines20: any;

    eaves1: any;
    setEaves1: any;
    eaves2: any;
    setEaves2: any;
    eaves3: any;
    setEaves3: any;
    eaves4: any;
    setEaves4: any;
    eaves5: any;
    setEaves5: any;
    eaves6: any;
    setEaves6: any;
    eaves7: any;
    setEaves7: any;
    eaves8: any;
    setEaves8: any;
    eaves9: any;
    setEaves9: any;
    eaves10: any;
    setEaves10: any;
    eaves11: any;
    setEaves11: any;
    eaves12: any;
    setEaves12: any;
    eaves13: any;
    setEaves13: any;
    eaves14: any;
    setEaves14: any;
    eaves15: any;
    setEaves15: any;
    eaves16: any;
    setEaves16: any;
    eaves17: any;
    setEaves17: any;
    eaves18: any;
    setEaves18: any;
    eaves19: any;
    setEaves19: any;
    eaves20: any;
    setEaves20: any;



    apex1: any;
    setApex1: any;
    apex2: any;
    setApex2: any;
    apex3: any;
    setApex3: any;
    apex4: any;
    setApex4: any;
    apex5: any;
    setApex5: any;
    apex6: any;
    setApex6: any;
    apex7: any;
    setApex7: any;
    apex8: any;
    setApex8: any;
    apex9: any;
    setApex9: any;
    apex10: any;
    setApex10: any;
    apex11: any;
    setApex11: any;
    apex12: any;
    setApex12: any;
    apex13: any;
    setApex13: any;
    apex14: any;
    setApex14: any;
    apex15: any;
    setApex15: any;
    apex16: any;
    setApex16: any;
    apex17: any;
    setApex17: any;
    apex18: any;
    setApex18: any;
    apex19: any;
    setApex19: any;
    apex20: any;
    setApex20: any;



    isLinesClosed1: any;
    setIsLinesClosed1: any;
    isLinesClosed2: any;
    setIsLinesClosed2: any;
    isLinesClosed3: any;
    setIsLinesClosed3: any;
    isLinesClosed4: any;
    setIsLinesClosed4: any;
    isLinesClosed5: any;
    setIsLinesClosed5: any;
    isLinesClosed6: any;
    setIsLinesClosed6: any;
    isLinesClosed7: any;
    setIsLinesClosed7: any;
    isLinesClosed8: any;
    setIsLinesClosed8: any;
    isLinesClosed9: any;
    setIsLinesClosed9: any;
    isLinesClosed10: any;
    setIsLinesClosed10: any;
    isLinesClosed11: any;
    setIsLinesClosed11: any;
    isLinesClosed12: any;
    setIsLinesClosed12: any;
    isLinesClosed13: any;
    setIsLinesClosed13: any;
    isLinesClosed14: any;
    setIsLinesClosed14: any;
    isLinesClosed15: any;
    setIsLinesClosed15: any;
    isLinesClosed16: any;
    setIsLinesClosed16: any;
    isLinesClosed17: any;
    setIsLinesClosed17: any;
    isLinesClosed18: any;
    setIsLinesClosed18: any;
    isLinesClosed19: any;
    setIsLinesClosed19: any;
    isLinesClosed20: any;
    setIsLinesClosed20: any;


    areas1Copy: any;
    setAreas1Copy: any;
    areas2Copy: any;
    setAreas2Copy: any;
    areas3Copy: any;
    setAreas3Copy: any;
    areas4Copy: any;
    setAreas4Copy: any;
    areas5Copy: any;
    setAreas5Copy: any;
    areas6Copy: any;
    setAreas6Copy: any;
    areas7Copy: any;
    setAreas7Copy: any;
    areas8Copy: any;
    setAreas8Copy: any;
    areas9Copy: any;
    setAreas9Copy: any;
    areas10Copy: any;
    setAreas10Copy: any;
    areas11Copy: any;
    setAreas11Copy: any;
    areas12Copy: any;
    setAreas12Copy: any;
    areas13Copy: any;
    setAreas13Copy: any;
    areas14Copy: any;
    setAreas14Copy: any;
    areas15Copy: any;
    setAreas15Copy: any;
    areas16Copy: any;
    setAreas16Copy: any;
    areas17Copy: any;
    setAreas17Copy: any;
    areas18Copy: any;
    setAreas18Copy: any;
    areas19Copy: any;
    setAreas19Copy: any;
    areas20Copy: any;
    setAreas20Copy: any;


    eaves1Copy: any;
    setEaves1Copy: any;
    eaves2Copy: any;
    setEaves2Copy: any;
    eaves3Copy: any;
    setEaves3Copy: any;
    eaves4Copy: any;
    setEaves4Copy: any;
    eaves5Copy: any;
    setEaves5Copy: any;
    eaves6Copy: any;
    setEaves6Copy: any;
    eaves7Copy: any;
    setEaves7Copy: any;
    eaves8Copy: any;
    setEaves8Copy: any;
    eaves9Copy: any;
    setEaves9Copy: any;
    eaves10Copy: any;
    setEaves10Copy: any;
    eaves11Copy: any;
    setEaves11Copy: any;
    eaves12Copy: any;
    setEaves12Copy: any;
    eaves13Copy: any;
    setEaves13Copy: any;
    eaves14Copy: any;
    setEaves14Copy: any;
    eaves15Copy: any;
    setEaves15Copy: any;
    eaves16Copy: any;
    setEaves16Copy: any;
    eaves17Copy: any;
    setEaves17Copy: any;
    eaves18Copy: any;
    setEaves18Copy: any;
    eaves19Copy: any;
    setEaves19Copy: any;
    eaves20Copy: any;
    setEaves20Copy: any;


    apex1Copy: any;
    setApex1Copy: any;
    apex2Copy: any;
    setApex2Copy: any;
    apex3Copy: any;
    setApex3Copy: any;
    apex4Copy: any;
    setApex4Copy: any;
    apex5Copy: any;
    setApex5Copy: any;
    apex6Copy: any;
    setApex6Copy: any;
    apex7Copy: any;
    setApex7Copy: any;
    apex8Copy: any;
    setApex8Copy: any;
    apex9Copy: any;
    setApex9Copy: any;
    apex10Copy: any;
    setApex10Copy: any;
    apex11Copy: any;
    setApex11Copy: any;
    apex12Copy: any;
    setApex12Copy: any;
    apex13Copy: any;
    setApex13Copy: any;
    apex14Copy: any;
    setApex14Copy: any;
    apex15Copy: any;
    setApex15Copy: any;
    apex16Copy: any;
    setApex16Copy: any;
    apex17Copy: any;
    setApex17Copy: any;
    apex18Copy: any;
    setApex18Copy: any;
    apex19Copy: any;
    setApex19Copy: any;
    apex20Copy: any;
    setApex20Copy: any;


    results1: any;
    setResults1: any;
    results2: any;
    setResults2: any;
    results3: any;
    setResults3: any;
    results4: any;
    setResults4: any;
    results5: any;
    setResults5: any;
    results6: any;
    setResults6: any;
    results7: any;
    setResults7: any;
    results8: any;
    setResults8: any;
    results9: any;
    setResults9: any;
    results10: any;
    setResults10: any;
    results11: any;
    setResults11: any;
    results12: any;
    setResults12: any;
    results13: any;
    setResults13: any;
    results14: any;
    setResults14: any;
    results15: any;
    setResults15: any;
    results16: any;
    setResults16: any;
    results17: any;
    setResults17: any;
    results18: any;
    setResults18: any;
    results19: any;
    setResults19: any;
    results20: any;
    setResults20: any;


    isReadyFigureBuild: any; setIsReadyFigureBuild: any;
    points: any; setPoints: any;
    pointsForTestFigure: any; setPointsForTestFigure: any;
    lettersForTestFigure: any; setLettersForTestFigure: any;
    builtCustomPoints: any; setBuiltCustomPoints: any;
    isLineClosed: any; setIsLineClosed: any;
    lines: any; setLines: any;
    linesForTestFigure: any; setLinesForTestFigure: any;
    addLinesForTestFigure: any; setAddLinesForTestFigure: any;
    addLinesForTestFigure1: any; setAddLinesForTestFigure1: any;
    addLinesForTestFigure2: any; setAddLinesForTestFigure2: any;
    addLinesForTestFigure3: any; setAddLinesForTestFigure3: any;

    builtCustomPoints1: any; setBuiltCustomPoints1: any;
    builtCustomPoints2: any; setBuiltCustomPoints2: any;
    builtCustomPoints3: any; setBuiltCustomPoints3: any;
    builtCustomPoints4: any; setBuiltCustomPoints4: any;
    builtCustomPoints5: any; setBuiltCustomPoints5: any;
    builtCustomPoints6: any; setBuiltCustomPoints6: any;
    builtCustomPoints7: any; setBuiltCustomPoints7: any;
    builtCustomPoints8: any; setBuiltCustomPoints8: any;
    builtCustomPoints9: any; setBuiltCustomPoints9: any;
    builtCustomPoints10: any; setBuiltCustomPoints10: any;
    builtCustomPoints11: any; setBuiltCustomPoints11: any;
    builtCustomPoints12: any; setBuiltCustomPoints12: any;
    builtCustomPoints13: any; setBuiltCustomPoints13: any;
    builtCustomPoints14: any; setBuiltCustomPoints14: any;
    builtCustomPoints15: any; setBuiltCustomPoints15: any;
    builtCustomPoints16: any; setBuiltCustomPoints16: any;
    builtCustomPoints17: any; setBuiltCustomPoints17: any;
    builtCustomPoints18: any; setBuiltCustomPoints18: any;
    builtCustomPoints19: any; setBuiltCustomPoints19: any;
    builtCustomPoints20: any; setBuiltCustomPoints20: any;

    builtSideSizes1: any; setBuiltSideSizes1: any;
    builtSideSizes2: any; setBuiltSideSizes2: any;
    builtSideSizes3: any; setBuiltSideSizes3: any;
    builtSideSizes4: any; setBuiltSideSizes4: any;
    builtSideSizes5: any; setBuiltSideSizes5: any;
    builtSideSizes6: any; setBuiltSideSizes6: any;
    builtSideSizes7: any; setBuiltSideSizes7: any;
    builtSideSizes8: any; setBuiltSideSizes8: any;
    builtSideSizes9: any; setBuiltSideSizes9: any;
    builtSideSizes10: any; setBuiltSideSizes10: any;
    builtSideSizes11: any; setBuiltSideSizes11: any;
    builtSideSizes12: any; setBuiltSideSizes12: any;
    builtSideSizes13: any; setBuiltSideSizes13: any;
    builtSideSizes14: any; setBuiltSideSizes14: any;
    builtSideSizes15: any; setBuiltSideSizes15: any;
    builtSideSizes16: any; setBuiltSideSizes16: any;
    builtSideSizes17: any; setBuiltSideSizes17: any;
    builtSideSizes18: any; setBuiltSideSizes18: any;
    builtSideSizes19: any; setBuiltSideSizes19: any;
    builtSideSizes20: any; setBuiltSideSizes20: any;

    builtCustomPoints1Copy: any; setBuiltCustomPoints1Copy: any;
    builtCustomPoints2Copy: any; setBuiltCustomPoints2Copy: any;
    builtCustomPoints3Copy: any; setBuiltCustomPoints3Copy: any;
    builtCustomPoints4Copy: any; setBuiltCustomPoints4Copy: any;
    builtCustomPoints5Copy: any; setBuiltCustomPoints5Copy: any;
    builtCustomPoints6Copy: any; setBuiltCustomPoints6Copy: any;
    builtCustomPoints7Copy: any; setBuiltCustomPoints7Copy: any;
    builtCustomPoints8Copy: any; setBuiltCustomPoints8Copy: any;
    builtCustomPoints9Copy: any; setBuiltCustomPoints9Copy: any;
    builtCustomPoints10Copy: any; setBuiltCustomPoints10Copy: any;
    builtCustomPoints11Copy: any; setBuiltCustomPoints11Copy: any;
    builtCustomPoints12Copy: any; setBuiltCustomPoints12Copy: any;
    builtCustomPoints13Copy: any; setBuiltCustomPoints13Copy: any;
    builtCustomPoints14Copy: any; setBuiltCustomPoints14Copy: any;
    builtCustomPoints15Copy: any; setBuiltCustomPoints15Copy: any;
    builtCustomPoints16Copy: any; setBuiltCustomPoints16Copy: any;
    builtCustomPoints17Copy: any; setBuiltCustomPoints17Copy: any;
    builtCustomPoints18Copy: any; setBuiltCustomPoints18Copy: any;
    builtCustomPoints19Copy: any; setBuiltCustomPoints19Copy: any;
    builtCustomPoints20Copy: any; setBuiltCustomPoints20Copy: any;

    Apex1Id: any; setApex1Id: any;
    Apex2Id: any; setApex2Id: any;
    Apex3Id: any; setApex3Id: any;
    Apex4Id: any; setApex4Id: any;
    Apex5Id: any; setApex5Id: any;
    Apex6Id: any; setApex6Id: any;
    Apex7Id: any; setApex7Id: any;
    Apex8Id: any; setApex8Id: any;
    Apex9Id: any; setApex9Id: any;
    Apex10Id: any; setApex10Id: any;
    Apex11Id: any; setApex11Id: any;
    Apex12Id: any; setApex12Id: any;
    Apex13Id: any; setApex13Id: any;
    Apex14Id: any; setApex14Id: any;
    Apex15Id: any; setApex15Id: any;
    Apex16Id: any; setApex16Id: any;
    Apex17Id: any; setApex17Id: any;
    Apex18Id: any; setApex18Id: any;
    Apex19Id: any; setApex19Id: any;
    Apex20Id: any; setApex20Id: any;


    Eaves1Id: any; setEaves1Id: any;
    Eaves2Id: any; setEaves2Id: any;
    Eaves3Id: any; setEaves3Id: any;
    Eaves4Id: any; setEaves4Id: any;
    Eaves5Id: any; setEaves5Id: any;
    Eaves6Id: any; setEaves6Id: any;
    Eaves7Id: any; setEaves7Id: any;
    Eaves8Id: any; setEaves8Id: any;
    Eaves9Id: any; setEaves9Id: any;
    Eaves10Id: any; setEaves10Id: any;
    Eaves11Id: any; setEaves11Id: any;
    Eaves12Id: any; setEaves12Id: any;
    Eaves13Id: any; setEaves13Id: any;
    Eaves14Id: any; setEaves14Id: any;
    Eaves15Id: any; setEaves15Id: any;
    Eaves16Id: any; setEaves16Id: any;
    Eaves17Id: any; setEaves17Id: any;
    Eaves18Id: any; setEaves18Id: any;
    Eaves19Id: any; setEaves19Id: any;
    Eaves20Id: any; setEaves20Id: any;

    lines1Copy: any; setLines1Copy: any;
    lines2Copy: any; setLines2Copy: any;
    lines3Copy: any; setLines3Copy: any;
    lines4Copy: any; setLines4Copy: any;
    lines5Copy: any; setLines5Copy: any;
    lines6Copy: any; setLines6Copy: any;
    lines7Copy: any; setLines7Copy: any;
    lines8Copy: any; setLines8Copy: any;
    lines9Copy: any; setLines9Copy: any;
    lines10Copy:any; setLines10Copy:any;
    lines11Copy:any; setLines11Copy:any;
    lines12Copy:any; setLines12Copy:any;
    lines13Copy:any; setLines13Copy:any;
    lines14Copy:any; setLines14Copy:any;
    lines15Copy:any; setLines15Copy:any;
    lines16Copy:any; setLines16Copy:any;
    lines17Copy:any; setLines17Copy:any;
    lines18Copy:any; setLines18Copy:any;
    lines19Copy:any; setLines19Copy:any;
    lines20Copy:any; setLines20Copy:any;
}

const CreativePointedPolygon: FC<Props> = ({width, clickedCoords,calcPolygonPointsCallback, isDottAdditional, setIsDottAdditional, prevStep, 
    setPrevStep, nextStep, setNextStep, deleteDot, setDeleteDot, addArea, setAddArea, areasCount, setAreasCount, 
    deleteFigure, setDeleteFigure, addEaves, addApex, setAddApex, setAddEaves, finishFigure, setFinishFigure, changeDott, setChangeDott,
    copyFigure, setCopyFigure, flipFigureX, setFlipFigureX, flipFigureY, setFlipFigureY,
    turnFigure, setTurnFigure, degreesTurnFigure, setDegreesTurnFigure, activeMode, activeDottCoordsX, setActiveDottCoordsX, activeDottCoordsY, setActiveDottCoordsY,
    
    onAInputFocus, setOnAInputFocus,
    onA1InputFocus, setOnA1InputFocus,
    onBInputFocus, setOnBInputFocus,
    onCInputFocus, setOnCInputFocus,
    onDInputFocus, setOnDInputFocus,
    onEInputFocus, setOnEInputFocus,
    onHeightInputFocus, setOnHeightInputFocus,
    onLInputFocus, setOnLInputFocus,

    areas1,
    setAreas1,
    areas2, 
    setAreas2,
    areas3,
    setAreas3,
    areas4,
    setAreas4,
    areas5, 
    setAreas5,
    areas6,
    setAreas6,
    areas7,
    setAreas7,
    areas8, 
    setAreas8,
    areas9,
    setAreas9,
    areas10,
    setAreas10,
    areas11,
    setAreas11,
    areas12,
    setAreas12,
    areas13,
    setAreas13,
    areas14,
    setAreas14,
    areas15,
    setAreas15,
    areas16,
    setAreas16,
    areas17,
    setAreas17,
    areas18,
    setAreas18,
    areas19,
    setAreas19,
    areas20,
    setAreas20,



    cuts1,
    setCuts1,
    cuts2, 
    setCuts2,
    cuts3,
    setCuts3,
    cuts4,
    setCuts4,
    cuts5, 
    setCuts5,
    cuts6,
    setCuts6,
    cuts7,
    setCuts7,
    cuts8, 
    setCuts8,
    cuts9,
    setCuts9,
    cuts10,
    setCuts10,
    cuts11,
    setCuts11,
    cuts12,
    setCuts12,
    cuts13,
    setCuts13,
    cuts14,
    setCuts14,
    cuts15,
    setCuts15,
    cuts16,
    setCuts16,
    cuts17,
    setCuts17,
    cuts18,
    setCuts18,
    cuts19,
    setCuts19,
    cuts20,
    setCuts20,



    lines1,
    setLines1,
    lines2, 
    setLines2,
    lines3,
    setLines3,
    lines4,
    setLines4,
    lines5, 
    setLines5,
    lines6,
    setLines6,
    lines7,
    setLines7,
    lines8, 
    setLines8,
    lines9,
    setLines9,
    lines10,
    setLines10,
    lines11,
    setLines11,
    lines12,
    setLines12,
    lines13,
    setLines13,
    lines14,
    setLines14,
    lines15,
    setLines15,
    lines16,
    setLines16,
    lines17,
    setLines17,
    lines18,
    setLines18,
    lines19,
    setLines19,
    lines20,
    setLines20,

    cutLines1,
    setCutLines1,
    cutLines2, 
    setCutLines2,
    cutLines3,
    setCutLines3,
    cutLines4,
    setCutLines4,
    cutLines5, 
    setCutLines5,
    cutLines6,
    setCutLines6,
    cutLines7,
    setCutLines7,
    cutLines8, 
    setCutLines8,
    cutLines9,
    setCutLines9,
    cutLines10,
    setCutLines10,
    cutLines11,
    setCutLines11,
    cutLines12,
    setCutLines12,
    cutLines13,
    setCutLines13,
    cutLines14,
    setCutLines14,
    cutLines15,
    setCutLines15,
    cutLines16,
    setCutLines16,
    cutLines17,
    setCutLines17,
    cutLines18,
    setCutLines18,
    cutLines19,
    setCutLines19,
    cutLines20,
    setCutLines20,

    eaves1,
    setEaves1,
    eaves2,
    setEaves2,
    eaves3,
    setEaves3,
    eaves4,
    setEaves4,
    eaves5,
    setEaves5,
    eaves6,
    setEaves6,
    eaves7,
    setEaves7,
    eaves8,
    setEaves8,
    eaves9,
    setEaves9,
    eaves10,
    setEaves10,
    eaves11,
    setEaves11,
    eaves12,
    setEaves12,
    eaves13,
    setEaves13,
    eaves14,
    setEaves14,
    eaves15,
    setEaves15,
    eaves16,
    setEaves16,
    eaves17,
    setEaves17,
    eaves18,
    setEaves18,
    eaves19,
    setEaves19,
    eaves20,
    setEaves20,



    apex1,
    setApex1,
    apex2,
    setApex2,
    apex3,
    setApex3,
    apex4,
    setApex4,
    apex5,
    setApex5,
    apex6,
    setApex6,
    apex7,
    setApex7,
    apex8,
    setApex8,
    apex9,
    setApex9,
    apex10,
    setApex10,
    apex11,
    setApex11,
    apex12,
    setApex12,
    apex13,
    setApex13,
    apex14,
    setApex14,
    apex15,
    setApex15,
    apex16,
    setApex16,
    apex17,
    setApex17,
    apex18,
    setApex18,
    apex19,
    setApex19,
    apex20,
    setApex20,



    isLinesClosed1,
    setIsLinesClosed1,
    isLinesClosed2,
    setIsLinesClosed2,
    isLinesClosed3,
    setIsLinesClosed3,
    isLinesClosed4,
    setIsLinesClosed4,
    isLinesClosed5,
    setIsLinesClosed5,
    isLinesClosed6,
    setIsLinesClosed6,
    isLinesClosed7,
    setIsLinesClosed7,
    isLinesClosed8,
    setIsLinesClosed8,
    isLinesClosed9,
    setIsLinesClosed9,
    isLinesClosed10,
    setIsLinesClosed10,
    isLinesClosed11,
    setIsLinesClosed11,
    isLinesClosed12,
    setIsLinesClosed12,
    isLinesClosed13,
    setIsLinesClosed13,
    isLinesClosed14,
    setIsLinesClosed14,
    isLinesClosed15,
    setIsLinesClosed15,
    isLinesClosed16,
    setIsLinesClosed16,
    isLinesClosed17,
    setIsLinesClosed17,
    isLinesClosed18,
    setIsLinesClosed18,
    isLinesClosed19,
    setIsLinesClosed19,
    isLinesClosed20,
    setIsLinesClosed20,



    areas1Copy,
    setAreas1Copy,
    areas2Copy,
    setAreas2Copy,
    areas3Copy,
    setAreas3Copy,
    areas4Copy,
    setAreas4Copy,
    areas5Copy,
    setAreas5Copy,
    areas6Copy,
    setAreas6Copy,
    areas7Copy,
    setAreas7Copy,
    areas8Copy,
    setAreas8Copy,
    areas9Copy,
    setAreas9Copy,
    areas10Copy,
    setAreas10Copy,
    areas11Copy,
    setAreas11Copy,
    areas12Copy,
    setAreas12Copy,
    areas13Copy,
    setAreas13Copy,
    areas14Copy,
    setAreas14Copy,
    areas15Copy,
    setAreas15Copy,
    areas16Copy,
    setAreas16Copy,
    areas17Copy,
    setAreas17Copy,
    areas18Copy,
    setAreas18Copy,
    areas19Copy,
    setAreas19Copy,
    areas20Copy,
    setAreas20Copy,


    eaves1Copy,
    setEaves1Copy,
    eaves2Copy,
    setEaves2Copy,
    eaves3Copy,
    setEaves3Copy,
    eaves4Copy,
    setEaves4Copy,
    eaves5Copy,
    setEaves5Copy,
    eaves6Copy,
    setEaves6Copy,
    eaves7Copy,
    setEaves7Copy,
    eaves8Copy,
    setEaves8Copy,
    eaves9Copy,
    setEaves9Copy,
    eaves10Copy,
    setEaves10Copy,
    eaves11Copy,
    setEaves11Copy,
    eaves12Copy,
    setEaves12Copy,
    eaves13Copy,
    setEaves13Copy,
    eaves14Copy,
    setEaves14Copy,
    eaves15Copy,
    setEaves15Copy,
    eaves16Copy,
    setEaves16Copy,
    eaves17Copy,
    setEaves17Copy,
    eaves18Copy,
    setEaves18Copy,
    eaves19Copy,
    setEaves19Copy,
    eaves20Copy,
    setEaves20Copy,


    apex1Copy,
    setApex1Copy,
    apex2Copy,
    setApex2Copy,
    apex3Copy,
    setApex3Copy,
    apex4Copy,
    setApex4Copy,
    apex5Copy,
    setApex5Copy,
    apex6Copy,
    setApex6Copy,
    apex7Copy,
    setApex7Copy,
    apex8Copy,
    setApex8Copy,
    apex9Copy,
    setApex9Copy,
    apex10Copy,
    setApex10Copy,
    apex11Copy,
    setApex11Copy,
    apex12Copy,
    setApex12Copy,
    apex13Copy,
    setApex13Copy,
    apex14Copy,
    setApex14Copy,
    apex15Copy,
    setApex15Copy,
    apex16Copy,
    setApex16Copy,
    apex17Copy,
    setApex17Copy,
    apex18Copy,
    setApex18Copy,
    apex19Copy,
    setApex19Copy,
    apex20Copy,
    setApex20Copy,

    results1, 
    setResults1, 
    results2, 
    setResults2, 
    results3,  
    setResults3,
    results4,  
    setResults4,
    results5,  
    setResults5,
    results6,  
    setResults6,
    results7,  
    setResults7,
    results8,  
    setResults8,
    results9,  
    setResults9,
    results10,  
    setResults10,
    results11,  
    setResults11,
    results12,  
    setResults12,
    results13,  
    setResults13,
    results14,  
    setResults14,
    results15,  
    setResults15,
    results16,  
    setResults16,
    results17,  
    setResults17,
    results18,  
    setResults18,
    results19,  
    setResults19,
    results20,  
    setResults20,


    isReadyFigureBuild, setIsReadyFigureBuild,
    points, setPoints,
    pointsForTestFigure, setPointsForTestFigure,
    lettersForTestFigure, setLettersForTestFigure,
    builtCustomPoints, setBuiltCustomPoints,
    isLineClosed, setIsLineClosed,
    lines, setLines,
    linesForTestFigure, setLinesForTestFigure,
    addLinesForTestFigure, setAddLinesForTestFigure,
    addLinesForTestFigure1, setAddLinesForTestFigure1,
    addLinesForTestFigure2, setAddLinesForTestFigure2,
    addLinesForTestFigure3, setAddLinesForTestFigure3,

    builtCustomPoints1, setBuiltCustomPoints1,
    builtCustomPoints2, setBuiltCustomPoints2,
    builtCustomPoints3, setBuiltCustomPoints3,
    builtCustomPoints4, setBuiltCustomPoints4,
    builtCustomPoints5, setBuiltCustomPoints5,
    builtCustomPoints6, setBuiltCustomPoints6,
    builtCustomPoints7, setBuiltCustomPoints7,
    builtCustomPoints8, setBuiltCustomPoints8,
    builtCustomPoints9, setBuiltCustomPoints9,
    builtCustomPoints10, setBuiltCustomPoints10,
    builtCustomPoints11, setBuiltCustomPoints11,
    builtCustomPoints12, setBuiltCustomPoints12,
    builtCustomPoints13, setBuiltCustomPoints13,
    builtCustomPoints14, setBuiltCustomPoints14,
    builtCustomPoints15, setBuiltCustomPoints15,
    builtCustomPoints16, setBuiltCustomPoints16,
    builtCustomPoints17, setBuiltCustomPoints17,
    builtCustomPoints18, setBuiltCustomPoints18,
    builtCustomPoints19, setBuiltCustomPoints19,
    builtCustomPoints20, setBuiltCustomPoints20,

    builtSideSizes1, setBuiltSideSizes1,
    builtSideSizes2, setBuiltSideSizes2,
    builtSideSizes3, setBuiltSideSizes3,
    builtSideSizes4, setBuiltSideSizes4,
    builtSideSizes5, setBuiltSideSizes5,
    builtSideSizes6, setBuiltSideSizes6,
    builtSideSizes7, setBuiltSideSizes7,
    builtSideSizes8, setBuiltSideSizes8,
    builtSideSizes9, setBuiltSideSizes9,
    builtSideSizes10, setBuiltSideSizes10,
    builtSideSizes11, setBuiltSideSizes11,
    builtSideSizes12, setBuiltSideSizes12,
    builtSideSizes13, setBuiltSideSizes13,
    builtSideSizes14, setBuiltSideSizes14,
    builtSideSizes15, setBuiltSideSizes15,
    builtSideSizes16, setBuiltSideSizes16,
    builtSideSizes17, setBuiltSideSizes17,
    builtSideSizes18, setBuiltSideSizes18,
    builtSideSizes19, setBuiltSideSizes19,
    builtSideSizes20, setBuiltSideSizes20,

    builtCustomPoints1Copy, setBuiltCustomPoints1Copy,
    builtCustomPoints2Copy, setBuiltCustomPoints2Copy,
    builtCustomPoints3Copy, setBuiltCustomPoints3Copy,
    builtCustomPoints4Copy, setBuiltCustomPoints4Copy,
    builtCustomPoints5Copy, setBuiltCustomPoints5Copy,
    builtCustomPoints6Copy, setBuiltCustomPoints6Copy,
    builtCustomPoints7Copy, setBuiltCustomPoints7Copy,
    builtCustomPoints8Copy, setBuiltCustomPoints8Copy,
    builtCustomPoints9Copy, setBuiltCustomPoints9Copy,
    builtCustomPoints10Copy, setBuiltCustomPoints10Copy,
    builtCustomPoints11Copy, setBuiltCustomPoints11Copy,
    builtCustomPoints12Copy, setBuiltCustomPoints12Copy,
    builtCustomPoints13Copy, setBuiltCustomPoints13Copy,
    builtCustomPoints14Copy, setBuiltCustomPoints14Copy,
    builtCustomPoints15Copy, setBuiltCustomPoints15Copy,
    builtCustomPoints16Copy, setBuiltCustomPoints16Copy,
    builtCustomPoints17Copy, setBuiltCustomPoints17Copy,
    builtCustomPoints18Copy, setBuiltCustomPoints18Copy,
    builtCustomPoints19Copy, setBuiltCustomPoints19Copy,
    builtCustomPoints20Copy, setBuiltCustomPoints20Copy,

    Apex1Id, setApex1Id,
    Apex2Id, setApex2Id,
    Apex3Id, setApex3Id,
    Apex4Id, setApex4Id,
    Apex5Id, setApex5Id,
    Apex6Id, setApex6Id,
    Apex7Id, setApex7Id,
    Apex8Id, setApex8Id,
    Apex9Id, setApex9Id,
    Apex10Id, setApex10Id,
    Apex11Id, setApex11Id,
    Apex12Id, setApex12Id,
    Apex13Id, setApex13Id,
    Apex14Id, setApex14Id,
    Apex15Id, setApex15Id,
    Apex16Id, setApex16Id,
    Apex17Id, setApex17Id,
    Apex18Id, setApex18Id,
    Apex19Id, setApex19Id,
    Apex20Id, setApex20Id,


    Eaves1Id, setEaves1Id,
    Eaves2Id, setEaves2Id,
    Eaves3Id, setEaves3Id,
    Eaves4Id, setEaves4Id,
    Eaves5Id, setEaves5Id,
    Eaves6Id, setEaves6Id,
    Eaves7Id, setEaves7Id,
    Eaves8Id, setEaves8Id,
    Eaves9Id, setEaves9Id,
    Eaves10Id, setEaves10Id,
    Eaves11Id, setEaves11Id,
    Eaves12Id, setEaves12Id,
    Eaves13Id, setEaves13Id,
    Eaves14Id, setEaves14Id,
    Eaves15Id, setEaves15Id,
    Eaves16Id, setEaves16Id,
    Eaves17Id, setEaves17Id,
    Eaves18Id, setEaves18Id,
    Eaves19Id, setEaves19Id,
    Eaves20Id, setEaves20Id,

    lines1Copy, setLines1Copy,
    lines2Copy, setLines2Copy,
    lines3Copy, setLines3Copy,
    lines4Copy, setLines4Copy,
    lines5Copy, setLines5Copy,
    lines6Copy, setLines6Copy,
    lines7Copy, setLines7Copy,
    lines8Copy, setLines8Copy,
    lines9Copy, setLines9Copy,
    lines10Copy, setLines10Copy,
    lines11Copy, setLines11Copy,
    lines12Copy, setLines12Copy,
    lines13Copy, setLines13Copy,
    lines14Copy, setLines14Copy,
    lines15Copy, setLines15Copy,
    lines16Copy, setLines16Copy,
    lines17Copy, setLines17Copy,
    lines18Copy, setLines18Copy,
    lines19Copy, setLines19Copy,
    lines20Copy, setLines20Copy
}) => {

    const appContext = useContext(AppContext);
    const gridConfig = useGridConfig(window, 1);

    const [turnDottCoordsX, setTurnDottCoordsX] = useState(null);
    const [turnDottCoordsY, setTurnDottCoordsY] = useState(null);
    const [moveDottCoordsX, setMoveDottCoordsX] = useState(null);
    const [moveDottCoordsY, setMoveDottCoordsY] = useState(null);
    const [turnLastDottArea, setTurnLastDottArea] = useState(0);
    const [turnLastDottId, setTurnLastDottId] = useState(null);
    const [turnDottArea, setTurnDottArea] = useState(0);
    const [turnDottId, setTurnDottId] = useState(null);
    const [activeDottArea, setActiveDottArea] = useState(0);
    const [activeDottId, setActiveDottId] = useState(null);
    const [moveDottId, setMoveDottId] = useState(null);
    const [turnDottAreaText, setTurnDottAreaText] = useState(0);
    const [activeDottChanged, setActiveDottChanged] = useState(false);    

    const [addpoint, setAddPoint] = useState([]);
    const [lastCanceledPoint, setLastCanceledPoint] = useState([]);
    const [qwe, setQwe] = useState(null);

    const [onDottClick, setOnDottClick] = useState(true);
    const [lastClickedDott, setlastClickedDott] = useState(null);
    const [lastClickedDottLine, setLastClickedDottLine] = useState([]);
    const [start, setStart] = useState(false);

    const [cutsForBuildMode, setCutsForBuildMode] = useState([]);

    const [activeChangingLine, setActiveChangingLine] = useState([]);

    const [iou, setiou] = useState(true);
    const [qx, setQx] = useState(null);

    const [figureASide, setFigureASide] = useState(0);
    const [figureA1Side, setFigureA1Side] = useState(0);
    const [figureBSide, setFigureBSide] = useState(0);
    const [figureCSide, setFigureCSide] = useState(0);
    const [figureDSide, setFigureDSide] = useState(0);
    const [figureESide, setFigureESide] = useState(0);
    const [figureHeight, setFigureHeight] = useState(0);
    const [figureLSide, setFigureLSide] = useState(0);

    const lineRef = React.useRef(null);

    const getYCoord = (y: any) => {
        // @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 1){
            // @ts-ignore
            return -700 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 2){
            // @ts-ignore
            return -712 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 3){
            // @ts-ignore
            return -722 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 4){
            // @ts-ignore
            return -730 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 5){
            // @ts-ignore
            return -735 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 6){
            // @ts-ignore
            return -740 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 7){
            // @ts-ignore
            return -742 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 8){
            // @ts-ignore
            return -747 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 9){
            // @ts-ignore
            return -751 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 10){
            // @ts-ignore
            return -754 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 11){
            // @ts-ignore
            return -757 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 12){
            // @ts-ignore
            return -759 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 13){
            // @ts-ignore
            return -762 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 14){
            // @ts-ignore
            return -763 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 15){
            // @ts-ignore
            return -764 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 16){
            // @ts-ignore
            return -767 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 17){
            // @ts-ignore
            return -766 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 18){
            // @ts-ignore
            return -768 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 19){
            // @ts-ignore
            return -770 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 20){
            // @ts-ignore
            return -772 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 21){
            // @ts-ignore
            return -773 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 22){
            // @ts-ignore
            return -774 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 23){
            // @ts-ignore
            return -774.5 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 24){
            // @ts-ignore
            return -775 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 25){
            // @ts-ignore
            return -776 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 26){
            // @ts-ignore
            return -777 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 27){
            // @ts-ignore
            return -778 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 28){
            // @ts-ignore
            return -778.5 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 29){
            // @ts-ignore
            return -779 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 30){
            // @ts-ignore
            return -779.5 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 31){
            // @ts-ignore
            return -780 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 32){
            // @ts-ignore
            return -780.5 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
         if(scalesConfig[`${appContext.state.selectedScale}`] == 33){
            // @ts-ignore
            return -781 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 34){
            // @ts-ignore
            return -781.5 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }
    }
    const getXCoord = (x: any) => {
        return gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize + (x * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
    }
    const getYByCoord = (y: any) => {
        // @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 1){
            return (y + 700) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 2){
            return (y + 712) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 3){
            return (y + 722) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 4){
            return (y + 730) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 5){
            return (y + 735) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 6){
            return (y + 740) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 7){
            return (y + 742) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 8){
            return (y + 747) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 9){
            return (y + 751) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 10){
            return (y + 754) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 11){
            return (y + 757) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 12){
            return (y + 759) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 13){
            return (y + 762) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 14){
            return (y + 763) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 15){
            return (y + 764) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 16){
            return (y + 767) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 17){
            return (y + 766) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 18){
            return (y + 768) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 19){
            return (y + 770) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 20){
            return (y + 772) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 21){
            return (y + 773) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 22){
            return (y + 774) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 23){
            return (y + 774.5) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 24){
            return (y + 775) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 25){
            return (y + 776) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 26){
            return (y + 777) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 27){
            //return (y + 775) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
            return (y + 778) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 28){
            //return (y + 920) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
            return (y + 778.5) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 29){
            return (y + 799) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 30){
            return (y + 779.5) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 31){
            //return (y + 775) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
            return (y + 780) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 32){
            //return (y + 920) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
            return (y + 780.5) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 33){
            return (y + 781) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 34){
            return (y + 781.5) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }
    }
    const getXByCoord = (x: any) => {
        return (x - gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
    }
    React.useEffect(() => {
        setFigureASide(Number(appContext.state.figureSides.figureASide));
        setFigureA1Side(Number(appContext.state.figureSides.figureA1Side));
        setFigureBSide(Number(appContext.state.figureSides.figureBSide));
        setFigureCSide(Number(appContext.state.figureSides.figureCSide));
        setFigureDSide(Number(appContext.state.figureSides.figureDSide));
        setFigureESide(Number(appContext.state.figureSides.figureESide));
        setFigureHeight(Number(appContext.state.figureSides.figureHeight));
        setFigureLSide(Number(appContext.state.figureSides.figureLSide));
    },[appContext])
    
    function isClockwise(points: any) {
        let sum = 0;
    
        for (let i = 0; i < points.length; i++) {
            const current = points[i];
            const next = points[(i + 1) % points.length];
    
            sum += (next.x - current.x) * (next.y + current.y);
        }
    
        return sum > 0;
    }
    
    React.useEffect(() => {
        if(appContext.state.selectedFigure === 2){
            alert("щоб побудувати трикутник введіть 3 сторони, або сторони A, A1 та L")
        }
    }, [appContext.state.selectedFigure])
    React.useEffect(() => {
        setPoints([])
        setPointsForTestFigure([])
        setLettersForTestFigure([])
        setAddLinesForTestFigure([])
        setAddLinesForTestFigure1([])
        setAddLinesForTestFigure2([])
        setAddLinesForTestFigure3([])
        setIsLineClosed(false)
        setIsReadyFigureBuild(false)
        if(appContext.state.selectedFigure === 2){
            if(figureASide == 0 && figureA1Side == 0 && figureBSide == 0 && figureCSide == 0 && figureLSide == 0){
                let pointsCopy: any = [];
                let lettersForTestFigureCopy: any = [];
                let hA = Math.sqrt(6 *6 - ((10 * 10 +6 *6 - 6 * 6) / (2 * 10)) ** 2);

                let xB = 10;
                let yB = 0;
                
                let xC = ((6 *6 + 10 * 10 - 6 * 6) / (2 * 10));
                let yC = hA;

                pointsCopy.push({x: 0, y: 0}); 
                pointsCopy.push({x: xC, y: yC});
                pointsCopy.push({x: xB, y: yB});

                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(6) || 0}
                    y = {getYCoord(-0.1) || 0}
                    text={"A" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={30}
                    offsetY={0}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(2.5) || 0}
                    y = {getYCoord(-0.3) || 0}
                    text={"A1" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={0}
                    fill={'black'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(7.5) || 0}
                    y = {getYCoord(2) || 0}
                    text={"B" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={15}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(2.5) || 0}
                    y = {getYCoord(2) || 0}
                    text={"C" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={15}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(4.7) || 0}
                    y = {getYCoord(1.5) || 0}
                    text={"L" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={0}
                    offsetY={15}
                    fill={'black'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )

                setLettersForTestFigure(lettersForTestFigureCopy)
                setPointsForTestFigure(pointsCopy)
                setActiveChangingLine([])
                setAddLinesForTestFigure([getXCoord(5), getYCoord(0), getXCoord(5), getYCoord(yC)])
                setAddLinesForTestFigure1([getXCoord(0), getYCoord(0), getXCoord(0), getYCoord(-0.2), getXCoord(5), getYCoord(-0.2), getXCoord(5), getYCoord(0)])
                if(onAInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(0), getXCoord(10), getYCoord(0)])
                }
                if(onA1InputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(-0.2), getXCoord(5), getYCoord(-0.2)])
                }
                if(onBInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(xC), getYCoord(yC), getXCoord(xB), getYCoord(yB)])
                }
                if(onCInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(0), getXCoord(xC), getYCoord(yC)])
                }
                if(onLInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(5), getYCoord(0), getXCoord(xC), getYCoord(yC)])
                }
            }
            if(figureASide > 0 && figureBSide > 0 && figureCSide > 0){
                let pointsCopy: any = [];
                let hA = Math.sqrt(figureCSide *figureCSide - ((figureASide * figureASide +figureCSide *figureCSide - figureBSide * figureBSide) / (2 * figureASide)) ** 2);

                let xB = figureASide;
                let yB = 0;
                
                let xC = ((figureCSide *figureCSide + figureASide * figureASide - figureBSide * figureBSide) / (2 * figureASide));
                let yC = hA;

                pointsCopy.push({x: 0, y: 0}); 
                pointsCopy.push({x: xC, y: yC});
                pointsCopy.push({x: xB, y: yB});

                setPoints(pointsCopy)
                setIsLineClosed(true)
                setActiveChangingLine([])
                setIsReadyFigureBuild(true)
            }
            if(figureASide > 0 && figureA1Side > 0 && figureLSide > 0){
                let pointsCopy: any = [];
               
                pointsCopy.push({x: 0, y: 0}); 
                pointsCopy.push({x: figureA1Side, y: figureLSide});
                pointsCopy.push({x: figureASide, y: 0});

                setPoints(pointsCopy)
                setIsLineClosed(true)
                setActiveChangingLine([]) 
                setIsReadyFigureBuild(true)
            }
        }
        if(appContext.state.selectedFigure === 3){
            let pointsCopy: any = [];
            let lettersForTestFigureCopy: any = [];
            if(figureASide == 0){
                pointsCopy.push({x: 0, y: 0});
                pointsCopy.push({x: 0, y: 4});
                pointsCopy.push({x: 4, y: 4});
                pointsCopy.push({x: 4, y: 0});

                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(0) || 0}
                    y = {getYCoord(2) || 0}
                    text={"A" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={30}
                    offsetY={15}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                setLettersForTestFigure(lettersForTestFigureCopy)
                setPointsForTestFigure(pointsCopy)
                setActiveChangingLine([])
                if(onAInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(0), getXCoord(0), getYCoord(4)])
                }
            }
            if(figureASide > 0){
                pointsCopy.push({x: 0, y: 0});
                pointsCopy.push({x: 0, y: figureASide});
                pointsCopy.push({x: figureASide, y: figureASide});
                pointsCopy.push({x: figureASide, y: 0});
                setPoints(pointsCopy)
                setIsLineClosed(true)
                setIsReadyFigureBuild(true)
            }
        }
        if(appContext.state.selectedFigure === 4){
            let pointsCopy: any = [];
            let lettersForTestFigureCopy: any = [];
            if(figureASide == 0 && figureBSide == 0){
                pointsCopy.push({x: 0, y: 0});
                pointsCopy.push({x: 0, y: 5});
                pointsCopy.push({x: 9, y: 5});
                pointsCopy.push({x: 9, y: 0});

                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(4.5) || 0}
                    y = {getYCoord(-0.1) || 0}
                    text={"A" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={30}
                    offsetY={0}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(0) || 0}
                    y = {getYCoord(2.5) || 0}
                    text={"B" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={30}
                    offsetY={15}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                setLettersForTestFigure(lettersForTestFigureCopy)
                setPointsForTestFigure(pointsCopy)
                setActiveChangingLine([])
                if(onAInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(0), getXCoord(9), getYCoord(0)])
                }
                if(onBInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(0), getXCoord(0), getYCoord(5)])
                }
            }
            if(figureASide > 0 && figureBSide > 0){
                pointsCopy.push({x: 0, y: 0});
                pointsCopy.push({x: 0, y: figureBSide});
                pointsCopy.push({x: figureASide, y: figureBSide});
                pointsCopy.push({x: figureASide, y: 0});
                setPoints(pointsCopy)
                setIsLineClosed(true)
                setIsReadyFigureBuild(true)
            }
        }
        if(appContext.state.selectedFigure === 5){
            let pointsCopy: any = [];
            let lettersForTestFigureCopy: any = [];
            if(figureASide == 0 && figureBSide == 0){
                pointsCopy.push({x: 0, y: 3});
                pointsCopy.push({x: 3, y: 3});
                pointsCopy.push({x: 3, y: 0});

                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(1.5) || 0}
                    y = {getYCoord(3.3) || 0}
                    text={"A" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={30}
                    offsetY={15}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(3.5) || 0}
                    y = {getYCoord(1.5) || 0}
                    text={"B" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={30}
                    offsetY={15}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                setLettersForTestFigure(lettersForTestFigureCopy)
                setPointsForTestFigure(pointsCopy)
                setActiveChangingLine([])
                if(onAInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(3), getXCoord(3), getYCoord(3)])
                }
                if(onBInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(3), getYCoord(0), getXCoord(3), getYCoord(3)])
                }
            }
            if(figureASide > 0 && figureBSide > 0){
                pointsCopy.push({x: 0, y: figureBSide});
                pointsCopy.push({x: figureASide, y: figureBSide});
                pointsCopy.push({x: figureASide, y: 0});
                setPoints(pointsCopy)
                setIsLineClosed(true)
                setIsReadyFigureBuild(true)
            }
        }
        if(appContext.state.selectedFigure === 6){
            let pointsCopy: any = [];
            let lettersForTestFigureCopy: any = [];
            if(figureASide == 0 && figureBSide == 0){
                pointsCopy.push({x: 0, y: 0});
                pointsCopy.push({x: 0, y: 3});
                pointsCopy.push({x: 3, y: 3});

                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(0) || 0}
                    y = {getYCoord(1.5) || 0}
                    text={"A" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={30}
                    offsetY={15}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(1.5) || 0}
                    y = {getYCoord(3.3) || 0}
                    text={"B" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={30}
                    offsetY={15}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                setLettersForTestFigure(lettersForTestFigureCopy)
                setPointsForTestFigure(pointsCopy)
                setActiveChangingLine([])
                if(onAInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(0), getXCoord(0), getYCoord(3)])
                }
                if(onBInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(3), getXCoord(3), getYCoord(3)])
                }
            }
            if(figureASide > 0 && figureBSide > 0){
                pointsCopy.push({x: 0, y: 0});
                pointsCopy.push({x: 0, y: figureASide});
                pointsCopy.push({x: figureBSide, y: figureASide});
                setPoints(pointsCopy)
                setIsLineClosed(true)
                setIsReadyFigureBuild(true)
            }
        }
        if(appContext.state.selectedFigure === 7){
            let pointsCopy: any = [];
            let lettersForTestFigureCopy: any = [];
            if(figureASide == 0 && figureBSide == 0){
                pointsCopy.push({x: 0, y: 0});
                pointsCopy.push({x: 3, y: 3});
                pointsCopy.push({x: 3, y: 0});

                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(3.5) || 0}
                    y = {getYCoord(1.5) || 0}
                    text={"A" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={30}
                    offsetY={15}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(1.5) || 0}
                    y = {getYCoord(-0.1) || 0}
                    text={"B" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={30}
                    offsetY={0}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                setLettersForTestFigure(lettersForTestFigureCopy)
                setPointsForTestFigure(pointsCopy)
                setActiveChangingLine([])
                if(onAInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(3), getYCoord(0), getXCoord(3), getYCoord(3)])
                }
                if(onBInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(0), getXCoord(3), getYCoord(0)])
                }
            }
            if(figureASide > 0 && figureBSide > 0){
                pointsCopy.push({x: 0, y: 0});
                pointsCopy.push({x: figureBSide, y: figureASide});
                pointsCopy.push({x: figureBSide, y: 0});
                setPoints(pointsCopy)
                setIsLineClosed(true)
                setIsReadyFigureBuild(true)
            }
        }
        if(appContext.state.selectedFigure === 8){
            let pointsCopy: any = [];
            let lettersForTestFigureCopy: any = [];
            if(figureASide == 0 && figureBSide == 0){
                pointsCopy.push({x: 0, y: 0});
                pointsCopy.push({x: 0, y: 3});
                pointsCopy.push({x: 3, y: 0});

                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(1.5) || 0}
                    y = {getYCoord(-0.1) || 0}
                    text={"A" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={30}
                    offsetY={0}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(0) || 0}
                    y = {getYCoord(1.5) || 0}
                    text={"B" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={30}
                    offsetY={15}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                setLettersForTestFigure(lettersForTestFigureCopy)
                setPointsForTestFigure(pointsCopy)
                setActiveChangingLine([])
                if(onAInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(0), getXCoord(3), getYCoord(0)])
                }
                if(onBInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(0), getXCoord(0), getYCoord(3)])
                }
            }
            if(figureASide > 0 && figureBSide > 0){
                pointsCopy.push({x: 0, y: 0});
                pointsCopy.push({x: 0, y: figureBSide});
                pointsCopy.push({x: figureASide, y: 0});
                setPoints(pointsCopy)
                setIsLineClosed(true)
                setIsReadyFigureBuild(true)
            }
        }
        if(appContext.state.selectedFigure === 9){
            let pointsCopy: any = [];
            let lettersForTestFigureCopy: any = [];
            if(figureASide == 0 && figureBSide == 0 && figureCSide == 0){
                pointsCopy.push({x: 0, y: 4});
                pointsCopy.push({x: 5, y: 4});
                pointsCopy.push({x: 5, y: 0});
                pointsCopy.push({x: 5 - 3, y: 0});

                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(2.5) || 0}
                    y = {getYCoord(4.3) || 0}
                    text={"A" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={30}
                    offsetY={15}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(5.5) || 0}
                    y = {getYCoord(2) || 0}
                    text={"B" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={30}
                    offsetY={15}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(3.5) || 0}
                    y = {getYCoord(-0.1) || 0}
                    text={"C" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={0}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                setLettersForTestFigure(lettersForTestFigureCopy)
                setPointsForTestFigure(pointsCopy)
                setActiveChangingLine([])
                if(onAInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(4), getXCoord(5), getYCoord(4)])
                }
                if(onBInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(5), getYCoord(0), getXCoord(5), getYCoord(4)])
                }
                if(onCInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(5 - 3), getYCoord(0), getXCoord(5), getYCoord(0)])
                }
            }
            if(figureASide > 0 && figureBSide > 0 && figureCSide > 0){
                pointsCopy.push({x: 0, y: figureBSide});
                pointsCopy.push({x: figureASide, y: figureBSide});
                pointsCopy.push({x: figureASide, y: 0});
                pointsCopy.push({x: figureASide - figureCSide, y: 0});
                setPoints(pointsCopy)
                setIsLineClosed(true)
                setIsReadyFigureBuild(true)
            }
        }
        if(appContext.state.selectedFigure === 10){
            let pointsCopy: any = [];
            let lettersForTestFigureCopy: any = [];
            if(figureASide == 0 && figureBSide == 0 && figureCSide == 0){
                pointsCopy.push({x: 0, y: 0});
                pointsCopy.push({x: 0, y: 4});
                pointsCopy.push({x: 5, y: 4});
                pointsCopy.push({x: 3, y: 0});

                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(2.5) || 0}
                    y = {getYCoord(4.3) || 0}
                    text={"A" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={30}
                    offsetY={15}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(0) || 0}
                    y = {getYCoord(2) || 0}
                    text={"B" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={30}
                    offsetY={15}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(1.5) || 0}
                    y = {getYCoord(-0.1) || 0}
                    text={"C" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={0}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                setLettersForTestFigure(lettersForTestFigureCopy)
                setPointsForTestFigure(pointsCopy)
                setActiveChangingLine([])
                if(onAInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(4), getXCoord(5), getYCoord(4)])
                }
                if(onBInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(0), getXCoord(0), getYCoord(4)])
                }
                if(onCInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(0), getXCoord(3), getYCoord(0)])
                }
            }
            if(figureASide > 0 && figureBSide > 0 && figureCSide > 0){
                pointsCopy.push({x: 0, y: 0});
                pointsCopy.push({x: 0, y: figureBSide});
                pointsCopy.push({x: figureASide, y: figureBSide});
                pointsCopy.push({x: figureCSide, y: 0});
                setPoints(pointsCopy)
                setIsLineClosed(true)
                setIsReadyFigureBuild(true)
            }
        }
        if(appContext.state.selectedFigure === 11){
            let pointsCopy: any = [];
            let lettersForTestFigureCopy: any = [];
            if(figureASide == 0 && figureBSide == 0 && figureCSide == 0){
                pointsCopy.push({x: 0, y: 0});
                pointsCopy.push({x: 5 - 3, y: 4});
                pointsCopy.push({x: 5, y: 4});
                pointsCopy.push({x: 5, y: 0});

                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(2.5) || 0}
                    y = {getYCoord(-0.1) || 0}
                    text={"A" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={0}
                    offsetY={0}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(5.5) || 0}
                    y = {getYCoord(2) || 0}
                    text={"B" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={30}
                    offsetY={15}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(3.5) || 0}
                    y = {getYCoord(4.3) || 0}
                    text={"C" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={15}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                setLettersForTestFigure(lettersForTestFigureCopy)
                setPointsForTestFigure(pointsCopy)
                setActiveChangingLine([])
                if(onAInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(0), getXCoord(5), getYCoord(0)])
                }
                if(onBInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(5), getYCoord(0), getXCoord(5), getYCoord(4)])
                }
                if(onCInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(5 - 3), getYCoord(4), getXCoord(5), getYCoord(4)])
                }
            }
            if(figureASide > 0 && figureBSide > 0 && figureCSide > 0){
                pointsCopy.push({x: 0, y: 0});
                pointsCopy.push({x: figureASide - figureCSide, y: figureBSide});
                pointsCopy.push({x: figureASide, y: figureBSide});
                pointsCopy.push({x: figureASide, y: 0});
                setPoints(pointsCopy)
                setIsLineClosed(true)
                setIsReadyFigureBuild(true)
            }
        }
        if(appContext.state.selectedFigure === 12){
            let pointsCopy: any = [];
            let lettersForTestFigureCopy: any = [];
            if(figureASide == 0 && figureBSide == 0 && figureCSide == 0){
                pointsCopy.push({x: 5, y: 0});
                pointsCopy.push({x: 0, y: 0});
                pointsCopy.push({x: 0, y: 4});
                pointsCopy.push({x: 3, y: 4});

                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(2.5) || 0}
                    y = {getYCoord(-0.1) || 0}
                    text={"A" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={0}
                    offsetY={0}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(0) || 0}
                    y = {getYCoord(2) || 0}
                    text={"B" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={30}
                    offsetY={15}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(1.5) || 0}
                    y = {getYCoord(4.3) || 0}
                    text={"C" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={0}
                    offsetY={15}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                setLettersForTestFigure(lettersForTestFigureCopy)
                setPointsForTestFigure(pointsCopy)
                setActiveChangingLine([])
                if(onAInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(0), getXCoord(5), getYCoord(0)])
                }
                if(onBInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(0), getXCoord(0), getYCoord(4)])
                }
                if(onCInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(4), getXCoord(3), getYCoord(4)])
                }
            }
            if(figureASide > 0 && figureBSide > 0 && figureCSide > 0){
                pointsCopy.push({x: figureASide, y: 0});
                pointsCopy.push({x: 0, y: 0});
                pointsCopy.push({x: 0, y: figureBSide});
                pointsCopy.push({x: figureCSide, y: figureBSide});
                setPoints(pointsCopy)
                setIsLineClosed(true)
                setIsReadyFigureBuild(true)
            }
        }
        if(appContext.state.selectedFigure === 13){
            let pointsCopy: any = [];
            let lettersForTestFigureCopy: any = [];
            if(figureASide == 0 && figureBSide == 0 && figureA1Side == 0 && figureHeight == 0){
                pointsCopy.push({x: 0, y: 0});
                pointsCopy.push({x: 5, y: 3});
                pointsCopy.push({x: 5 + 3, y: 3});
                pointsCopy.push({x: 10, y: 0});

                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(5.5) || 0}
                    y = {getYCoord(-0.1) || 0}
                    text={"A" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={0}
                    offsetY={0}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(2.5) || 0}
                    y = {getYCoord(-0.3) || 0}
                    text={"A1" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={0}
                    offsetY={0}
                    fill={'black'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(6.5) || 0}
                    y = {getYCoord(3.3) || 0}
                    text={"B" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={0}
                    offsetY={15}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(7.7) || 0}
                    y = {getYCoord(1.5) || 0}
                    text={"H" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={15}
                    fill={'black'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                setLettersForTestFigure(lettersForTestFigureCopy)
                setPointsForTestFigure(pointsCopy)
                setActiveChangingLine([])
                setAddLinesForTestFigure([getXCoord(8), getYCoord(3), getXCoord(8), getYCoord(0)])
                setAddLinesForTestFigure1([getXCoord(0), getYCoord(0), getXCoord(0), getYCoord(-0.2), getXCoord(5), getYCoord(-0.2), getXCoord(5), getYCoord(0)])
                if(onAInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(0), getXCoord(10), getYCoord(0)])
                } 
                if(onA1InputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(-0.2), getXCoord(5), getYCoord(-0.2)])
                }
                if(onBInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(5), getYCoord(3), getXCoord(5 + 3), getYCoord(3)])
                }
                if(onHeightInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(5 + 3), getYCoord(0), getXCoord(5 + 3), getYCoord(3)])
                }
            }
            if(figureASide > 0 && figureBSide > 0 && figureA1Side > 0 && figureHeight > 0){
                pointsCopy.push({x: 0, y: 0});
                pointsCopy.push({x: figureA1Side, y: figureHeight});
                pointsCopy.push({x: figureA1Side + figureBSide, y: figureHeight});
                pointsCopy.push({x: figureASide, y: 0});
                setPoints(pointsCopy)
                setIsLineClosed(true)
                setIsReadyFigureBuild(true)
            }
        }
        if(appContext.state.selectedFigure === 14){
            let pointsCopy: any = [];
            let lettersForTestFigureCopy: any = [];
            if(figureASide == 0 && figureBSide == 0 && figureA1Side == 0 && figureHeight == 0){
                pointsCopy.push({x: 0, y: 3});
                pointsCopy.push({x: 5, y: 0});
                pointsCopy.push({x: 5 + 3, y: 0});
                pointsCopy.push({x: 10, y: 3});

                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(5.5) || 0}
                    y = {getYCoord(3.3) || 0}
                    text={"A" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={0}
                    offsetY={0}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(2.5) || 0}
                    y = {getYCoord(3.6) || 0}
                    text={"A1" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={0}
                    offsetY={0}
                    fill={'black'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(6.5) || 0}
                    y = {getYCoord(-0.1) || 0}
                    text={"B" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={0}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(7.7) || 0}
                    y = {getYCoord(1.5) || 0}
                    text={"H" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={15}
                    fill={'black'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                setLettersForTestFigure(lettersForTestFigureCopy)
                setPointsForTestFigure(pointsCopy)
                setActiveChangingLine([])
                setAddLinesForTestFigure([getXCoord(8), getYCoord(3), getXCoord(8), getYCoord(0)])
                setAddLinesForTestFigure1([getXCoord(0), getYCoord(3), getXCoord(0), getYCoord(3.2), getXCoord(5), getYCoord(3.2), getXCoord(5), getYCoord(3)])
                setPointsForTestFigure(pointsCopy)
                setActiveChangingLine([])
                if(onAInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(3), getXCoord(10), getYCoord(3)])
                }
                if(onA1InputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(3.2), getXCoord(5), getYCoord(3.2)])
                }
                if(onBInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(5), getYCoord(0), getXCoord(5 + 3), getYCoord(0)])
                }
                if(onHeightInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(5 + 3), getYCoord(0), getXCoord(5 + 3), getYCoord(3)])
                }
            }
            if(figureASide > 0 && figureBSide > 0 && figureA1Side > 0 && figureHeight > 0){
                pointsCopy.push({x: 0, y: figureHeight});
                pointsCopy.push({x: figureA1Side, y: 0});
                pointsCopy.push({x: figureA1Side + figureBSide, y: 0});
                pointsCopy.push({x: figureASide, y: figureHeight});
                setPoints(pointsCopy)
                setIsLineClosed(true)
                setIsReadyFigureBuild(true)
            }
        }
        if(appContext.state.selectedFigure === 15){
            let pointsCopy: any = [];
            let lettersForTestFigureCopy: any = [];
            if(figureASide == 0 && figureBSide == 0 && figureHeight == 0){
                let line = [{x: 0, y: 0}, {x: 4, y: 0}]
                let y = 0
                let x = 0
                let rad = (Math.PI / 180)
                do{
                    for (let i = 0; i < line.length; i++) {
                        // @ts-ignore
                        line[i] = {x: line[0].x + (line[i].x - line[0].x) * Math.cos(rad) - (line[i].y - line[0].y) * Math.sin(rad), y: line[0].y + (line[i].x - line[0].x) * Math.sin(rad) + (line[i].y - line[0].y) * Math.cos(rad)};
                    }
                    y = line[1].y
                    x = line[1].x
                }while( y < 3 || x == 0)

                pointsCopy.push({x: 0, y: 0});
                pointsCopy.push({x: line[1].x, y: line[1].y});
                pointsCopy.push({x: line[1].x + 3, y: line[1].y});
                pointsCopy.push({x: 3, y: 0});
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(1.5) || 0}
                    y = {getYCoord(-0.1) || 0}
                    text={"A" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={0}
                    offsetY={0}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(1.3) || 0}
                    y = {getYCoord(2) || 0}
                    text={"B" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={0}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(3) || 0}
                    y = {getYCoord(1.5) || 0}
                    text={"H" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={15}
                    fill={'black'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                setLettersForTestFigure(lettersForTestFigureCopy)
                setPointsForTestFigure(pointsCopy)
                setAddLinesForTestFigure([getXCoord(line[1].x), getYCoord(line[1].y), getXCoord(line[1].x), getYCoord(0)])
                setActiveChangingLine([])
                if(onAInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(0), getXCoord(3), getYCoord(0)])
                }
                if(onBInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(0), getXCoord(line[1].x), getYCoord(line[1].y)])
                }
                if(onHeightInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(line[1].x), getYCoord(line[1].y), getXCoord(line[1].x), getYCoord(0)])
                }
            }
            if(figureASide > 0 && figureBSide > 0 && figureHeight > 0){
                let line = [{x: 0, y: 0}, {x: figureBSide, y: 0}]
                let y = 0
                let x = 0
                let rad = (Math.PI / 180)
                do{
                    for (let i = 0; i < line.length; i++) {
                        // @ts-ignore
                        line[i] = {x: line[0].x + (line[i].x - line[0].x) * Math.cos(rad) - (line[i].y - line[0].y) * Math.sin(rad), y: line[0].y + (line[i].x - line[0].x) * Math.sin(rad) + (line[i].y - line[0].y) * Math.cos(rad)};
                    }
                    y = line[1].y
                    x = line[1].x
                }while( y < figureHeight || x == 0)

                pointsCopy.push({x: 0, y: 0});
                pointsCopy.push({x: line[1].x, y: line[1].y});
                pointsCopy.push({x: line[1].x + figureASide, y: line[1].y});
                pointsCopy.push({x: figureASide, y: 0});
                setPoints(pointsCopy)
                setIsLineClosed(true)
                setIsReadyFigureBuild(true)
            }
        }
        if(appContext.state.selectedFigure === 16){
            let pointsCopy: any = [];
            let lettersForTestFigureCopy: any = [];
            if(figureASide == 0 && figureBSide == 0 && figureHeight == 0){
                let line = [{x: 0, y: 0}, {x: 4, y: 0}]
                let y = 0
                let x = 0
                let rad = (Math.PI / 180)
                do{
                    for (let i = 0; i < line.length; i++) {
                        // @ts-ignore
                        line[i] = {x: line[0].x + (line[i].x - line[0].x) * Math.cos(rad) - (line[i].y - line[0].y) * Math.sin(rad), y: line[0].y + (line[i].x - line[0].x) * Math.sin(rad) + (line[i].y - line[0].y) * Math.cos(rad)};
                    }
                    y = line[1].y
                    x = line[1].x
                }while( y < 3 || x == 0)

                pointsCopy.push({x: line[1].x + 3, y: 0});
                pointsCopy.push({x: 3, y: line[1].y});
                pointsCopy.push({x: 0, y: line[1].y});
                pointsCopy.push({x: line[1].x, y: 0});

                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(4) || 0}
                    y = {getYCoord(-0.1) || 0}
                    text={"A" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={0}
                    offsetY={0}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(1.3) || 0}
                    y = {getYCoord(1.3) || 0}
                    text={"B" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={0}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(3) || 0}
                    y = {getYCoord(1.5) || 0}
                    text={"H" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={15}
                    fill={'black'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                setLettersForTestFigure(lettersForTestFigureCopy)
                setPointsForTestFigure(pointsCopy)
                setAddLinesForTestFigure([getXCoord(line[1].x), getYCoord(line[1].y), getXCoord(line[1].x), getYCoord(0)])
                setActiveChangingLine([])
                if(onAInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(line[1].x), getYCoord(0), getXCoord(line[1].x + 3), getYCoord(0)])
                }
                if(onBInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(line[1].x), getYCoord(0), getXCoord(0), getYCoord(line[1].y)])
                }
                if(onHeightInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(line[1].x), getYCoord(line[1].y), getXCoord(line[1].x), getYCoord(0)])
                }
            }
            if(figureASide > 0 && figureBSide > 0 && figureHeight > 0){
                let line = [{x: 0, y: 0}, {x: figureBSide, y: 0}]
            let y = 0
            let x = 0
            let rad = (Math.PI / 180)
            do{
                for (let i = 0; i < line.length; i++) {
                    // @ts-ignore
                    line[i] = {x: line[0].x + (line[i].x - line[0].x) * Math.cos(rad) - (line[i].y - line[0].y) * Math.sin(rad), y: line[0].y + (line[i].x - line[0].x) * Math.sin(rad) + (line[i].y - line[0].y) * Math.cos(rad)};
                }
                y = line[1].y
                x = line[1].x
            }while( y < figureHeight || x == 0)

                pointsCopy.push({x: line[1].x + figureASide, y: 0});
                pointsCopy.push({x: figureASide, y: line[1].y});
                pointsCopy.push({x: 0, y: line[1].y});
                pointsCopy.push({x: line[1].x, y: 0});
                setPoints(pointsCopy)
                setIsLineClosed(true)
                setIsReadyFigureBuild(true)
            }
        }
        if(appContext.state.selectedFigure === 17){
            let pointsCopy: any = [];
            let lettersForTestFigureCopy: any = [];
            if(figureASide == 0 && figureBSide == 0 && figureCSide == 0 && figureDSide == 0 && figureHeight == 0){
                pointsCopy.push({x: 1, y: 0});
                pointsCopy.push({x: 0, y: 0});
                pointsCopy.push({x: 0, y: 3});
                pointsCopy.push({x: 5, y: 3});
                pointsCopy.push({x: 5, y: 0});
                pointsCopy.push({x: 5 - 1, y: 0});
                pointsCopy.push({x: 5 / 2, y: 3 - 2});

                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(0.5) || 0}
                    y = {getYCoord(-0.1) || 0}
                    text={"A" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={0}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(0) || 0}
                    y = {getYCoord(1.5) || 0}
                    text={"B" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={30}
                    offsetY={15}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(2.5) || 0}
                    y = {getYCoord(3.4) || 0}
                    text={"C" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={10}
                    offsetY={0}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(4.5) || 0}
                    y = {getYCoord(-0.1) || 0}
                    text={"D" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={0}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(2.3) || 0}
                    y = {getYCoord(2.1) || 0}
                    text={"H" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={10}
                    offsetY={0}
                    fill={'black'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                setLettersForTestFigure(lettersForTestFigureCopy)
                setAddLinesForTestFigure([getXCoord(5 / 2), getYCoord(3 - 2), getXCoord(5 / 2), getYCoord(3)])
                setPointsForTestFigure(pointsCopy)
                setActiveChangingLine([])
                if(onAInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(0), getXCoord(1), getYCoord(0)])
                }
                if(onBInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(0), getXCoord(0), getYCoord(3)])
                }
                if(onCInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(3), getXCoord(5), getYCoord(3)])
                }
                if(onDInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(5 - 1), getYCoord(0), getXCoord(5), getYCoord(0)])
                }
                if(onHeightInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(5 / 2), getYCoord(3), getXCoord(5 / 2), getYCoord(3 - 2)])
                }
            }
            if(figureASide > 0 && figureBSide > 0 && figureCSide > 0 && figureDSide > 0 && figureHeight > 0){
                pointsCopy.push({x: figureASide, y: 0});
                pointsCopy.push({x: 0, y: 0});
                pointsCopy.push({x: 0, y: figureBSide});
                pointsCopy.push({x: figureCSide, y: figureBSide});
                pointsCopy.push({x: figureCSide, y: 0});
                pointsCopy.push({x: figureCSide - figureDSide, y: 0});
                pointsCopy.push({x: figureCSide / 2, y: figureBSide - figureHeight});
                setPoints(pointsCopy)
                setIsLineClosed(true)
                setIsReadyFigureBuild(true)
            }
        }
        if(appContext.state.selectedFigure === 18){
            let pointsCopy: any = [];
            let lettersForTestFigureCopy: any = [];
            if(figureASide == 0 && figureBSide == 0 && figureA1Side == 0 && figureCSide == 0 && figureDSide == 0 && figureLSide == 0 && figureHeight == 0){
                pointsCopy.push({x: 2, y: 0});
                pointsCopy.push({x: 0, y: 0});
                pointsCopy.push({x: 1, y: 3});
                pointsCopy.push({x: 1 + 5, y: 3});
                pointsCopy.push({x: 2 + 3 + 2, y: 0});
                pointsCopy.push({x: 2 + 3, y: 0});
                pointsCopy.push({x: 2 + 3 / 2, y: 3 - 1});

                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(1.5) || 0}
                    y = {getYCoord(-0.1) || 0}
                    text={"A" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={0}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(0.5) || 0}
                    y = {getYCoord(-0.3) || 0}
                    text={"A1" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={0}
                    fill={'black'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(3.5) || 0}
                    y = {getYCoord(3.3) || 0}
                    text={"B" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={15}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(3.5) || 0}
                    y = {getYCoord(-0.3) || 0}
                    text={"C" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={0}
                    fill={'black'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(6) || 0}
                    y = {getYCoord(-0.1) || 0}
                    text={"D" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={0}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(3.3) || 0}
                    y = {getYCoord(2.5) || 0}
                    text={"H" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={15}
                    fill={'black'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(5.7) || 0}
                    y = {getYCoord(1.5) || 0}
                    text={"L" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={0}
                    offsetY={15}
                    fill={'black'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                setLettersForTestFigure(lettersForTestFigureCopy)
                setPointsForTestFigure(pointsCopy)
                setActiveChangingLine([])
                setAddLinesForTestFigure([getXCoord(2 + 3 / 2), getYCoord(3 - 1), getXCoord(2 + 3 / 2), getYCoord(3)])
                setAddLinesForTestFigure1([getXCoord(0), getYCoord(0), getXCoord(0), getYCoord(-0.2), getXCoord(1), getYCoord(-0.2), getXCoord(1), getYCoord(0)])
                setAddLinesForTestFigure2([getXCoord(2), getYCoord(0), getXCoord(2), getYCoord(-0.2), getXCoord(5), getYCoord(-0.2), getXCoord(5), getYCoord(0)])
                setAddLinesForTestFigure3([getXCoord(6), getYCoord(3), getXCoord(6), getYCoord(0)])
                if(onAInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(0), getXCoord(2), getYCoord(0)])
                }
                if(onA1InputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(-0.2), getXCoord(1), getYCoord(-0.2)])
                }
                if(onBInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(1), getYCoord(3), getXCoord(1 + 5), getYCoord(3)])
                }
                if(onCInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(2), getYCoord(-0.2), getXCoord(2 + 3), getYCoord(-0.2)])
                }
                if(onDInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(2 + 3), getYCoord(0), getXCoord(2 + 3 + 2), getYCoord(0)])
                }
                if(onHeightInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(2 + 3 / 2), getYCoord(3), getXCoord(2 + 3 / 2), getYCoord(3 - 1)])
                }
                if(onLInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(2 / 2 + 5), getYCoord(0), getXCoord(2 / 2 + 5), getYCoord(3)])
                }
            }
            if(figureASide > 0 && figureBSide > 0 && figureA1Side > 0 && figureCSide > 0 && figureDSide > 0 && figureLSide > 0 && figureHeight > 0){
                pointsCopy.push({x: figureASide, y: 0});
                pointsCopy.push({x: 0, y: 0});
                pointsCopy.push({x: figureA1Side, y: figureLSide});
                pointsCopy.push({x: figureA1Side + figureBSide, y: figureLSide});
                pointsCopy.push({x: figureASide + figureCSide + figureDSide, y: 0});
                pointsCopy.push({x: figureASide + figureCSide, y: 0});
                pointsCopy.push({x: figureASide + figureCSide / 2, y: figureLSide - figureHeight});
                setPoints(pointsCopy)
                setIsLineClosed(true)
                setIsReadyFigureBuild(true)
            }
        }
        if(appContext.state.selectedFigure === 19){
            let pointsCopy: any = [];
            let lettersForTestFigureCopy: any = [];
            if(figureASide == 0 && figureBSide == 0 && figureCSide == 0 && figureDSide == 0){
                pointsCopy.push({x: 0, y: 0});
                pointsCopy.push({x: 0, y: 3});
                pointsCopy.push({x: 5, y: 3});
                pointsCopy.push({x: 1, y: 1});
                pointsCopy.push({x: 1, y: 0});

                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(2.5) || 0}
                    y = {getYCoord(3.3) || 0}
                    text={"A" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={15}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(0) || 0}
                    y = {getYCoord(1.5) || 0}
                    text={"B" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={30}
                    offsetY={15}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(1.5) || 0}
                    y = {getYCoord(0.5) || 0}
                    text={"C" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={30}
                    offsetY={15}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(0.5) || 0}
                    y = {getYCoord(-0.1) || 0}
                    text={"D" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={0}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                setLettersForTestFigure(lettersForTestFigureCopy)
                setPointsForTestFigure(pointsCopy)
                setActiveChangingLine([])
                if(onAInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(3), getXCoord(5), getYCoord(3)])
                }
                if(onBInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(0), getXCoord(0), getYCoord(3)])
                }
                if(onCInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(1), getYCoord(0), getXCoord(1), getYCoord(1)])
                }
                if(onDInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(0), getXCoord(1), getYCoord(0)])
                }
            }
            if(figureASide > 0 && figureBSide > 0 && figureCSide > 0 && figureDSide > 0){
                pointsCopy.push({x: 0, y: 0});
                pointsCopy.push({x: 0, y: figureBSide});
                pointsCopy.push({x: figureASide, y: figureBSide});
                pointsCopy.push({x: figureDSide, y: figureCSide});
                pointsCopy.push({x: figureDSide, y: 0});
                setPoints(pointsCopy)
                setIsLineClosed(true)
                setIsReadyFigureBuild(true)
            }
        }
        if(appContext.state.selectedFigure === 20){
            let pointsCopy: any = [];
            let lettersForTestFigureCopy: any = [];
            if(figureASide == 0 && figureBSide == 0 && figureCSide == 0 && figureDSide == 0){
                pointsCopy.push({x: 0, y: 3});
                pointsCopy.push({x: 5, y: 3});
                pointsCopy.push({x: 5, y: 0});
                pointsCopy.push({x: 5 - 1, y: 0});
                pointsCopy.push({x: 5 - 1, y: 1});

                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(2.5) || 0}
                    y = {getYCoord(3.3) || 0}
                    text={"A" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={15}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(5.5) || 0}
                    y = {getYCoord(1.5) || 0}
                    text={"B" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={30}
                    offsetY={15}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(4.5) || 0}
                    y = {getYCoord(-0.1) || 0}
                    text={"C" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={0}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(3.6) || 0}
                    y = {getYCoord(0.5) || 0}
                    text={"D" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={0}
                    offsetY={15}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                setLettersForTestFigure(lettersForTestFigureCopy)
                setPointsForTestFigure(pointsCopy)
                setActiveChangingLine([])
                if(onAInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(3), getXCoord(5), getYCoord(3)])
                }
                if(onBInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(5), getYCoord(0), getXCoord(5), getYCoord(3)])
                }
                if(onCInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(5), getYCoord(0), getXCoord(5 - 1), getYCoord(0)])
                }
                if(onDInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(5 - 1), getYCoord(0), getXCoord(5 - 1), getYCoord(1)])
                }
            }
            if(figureASide > 0 && figureBSide > 0 && figureCSide > 0 && figureDSide > 0){
                pointsCopy.push({x: 0, y: figureBSide});
                pointsCopy.push({x: figureASide, y: figureBSide});
                pointsCopy.push({x: figureASide, y: 0});
                pointsCopy.push({x: figureASide - figureCSide, y: 0});
                pointsCopy.push({x: figureASide - figureCSide, y: figureDSide});
                setPoints(pointsCopy)
                setIsLineClosed(true)
                setIsReadyFigureBuild(true)
            }
        }
        if(appContext.state.selectedFigure === 21){
            let pointsCopy: any = [];
            let lettersForTestFigureCopy: any = [];
            if(figureASide == 0 && figureA1Side == 0 && figureBSide == 0 && figureCSide == 0 && figureDSide == 0 && figureESide == 0 && figureHeight == 0 && figureLSide == 0){
                pointsCopy.push({x: 2, y: 1});
                pointsCopy.push({x: 2, y: 0});
                pointsCopy.push({x: 0, y: 0});
                pointsCopy.push({x: 1, y: 3});
                pointsCopy.push({x: 1 + 5, y: 3});
                pointsCopy.push({x: 2 + 3 + 2, y: 0});
                pointsCopy.push({x: 2 + 3, y: 0});
                pointsCopy.push({x: 2 + 3, y: 1});
                pointsCopy.push({x: 2 + 3 / 2, y: 3 - 1});
                
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(1.5) || 0}
                    y = {getYCoord(-0.1) || 0}
                    text={"A" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={0}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(0.5) || 0}
                    y = {getYCoord(-0.3) || 0}
                    text={"A1" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={0}
                    fill={'black'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(3.5) || 0}
                    y = {getYCoord(3.3) || 0}
                    text={"B" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={15}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(3.5) || 0}
                    y = {getYCoord(-0.3) || 0}
                    text={"C" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={0}
                    fill={'black'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(6) || 0}
                    y = {getYCoord(-0.1) || 0}
                    text={"D" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={0}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(2.3) || 0}
                    y = {getYCoord(0.6) || 0}
                    text={"E" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={0}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(3.3) || 0}
                    y = {getYCoord(2.5) || 0}
                    text={"H" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={15}
                    fill={'black'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(5.7) || 0}
                    y = {getYCoord(1.5) || 0}
                    text={"L" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={0}
                    offsetY={15}
                    fill={'black'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                setLettersForTestFigure(lettersForTestFigureCopy)
                setPointsForTestFigure(pointsCopy)
                setActiveChangingLine([])
                setAddLinesForTestFigure([getXCoord(2 + 3 / 2), getYCoord(3 - 1), getXCoord(2 + 3 / 2), getYCoord(3)])
                setAddLinesForTestFigure1([getXCoord(0), getYCoord(0), getXCoord(0), getYCoord(-0.2), getXCoord(1), getYCoord(-0.2), getXCoord(1), getYCoord(0)])
                setAddLinesForTestFigure2([getXCoord(2), getYCoord(0), getXCoord(2), getYCoord(-0.2), getXCoord(5), getYCoord(-0.2), getXCoord(5), getYCoord(0)])
                setAddLinesForTestFigure3([getXCoord(6), getYCoord(3), getXCoord(6), getYCoord(0)])
                if(onAInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(0), getXCoord(2), getYCoord(0)])
                }
                if(onA1InputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(-0.2), getXCoord(1), getYCoord(-0.2)])
                }
                if(onBInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(1), getYCoord(3), getXCoord(1 + 5), getYCoord(3)])
                }
                if(onCInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(2), getYCoord(-0.2), getXCoord(2 + 3), getYCoord(-0.2)])
                }
                if(onDInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(2 + 3), getYCoord(0), getXCoord(2 + 3 + 2), getYCoord(0)])
                }
                if(onEInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(2), getYCoord(0), getXCoord(2), getYCoord(1)])
                }
                if(onHeightInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(2 + 3 / 2), getYCoord(3), getXCoord(2 + 3 / 2), getYCoord(3 - 1)])
                }
                if(onLInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(2 / 2 + 5), getYCoord(0), getXCoord(2 / 2 + 5), getYCoord(3)])
                }
            }
            if(figureASide == 0 && figureA1Side > 0 && figureBSide > 0 && figureCSide > 0 && figureDSide > 0 && figureESide > 0 && figureHeight > 0 && figureLSide > 0){
                pointsCopy.push({x: figureASide, y: figureESide});
                pointsCopy.push({x: figureASide, y: 0});
                pointsCopy.push({x: 0, y: 0});
                pointsCopy.push({x: figureA1Side, y: figureLSide});
                pointsCopy.push({x: figureA1Side + figureBSide, y: figureLSide});
                pointsCopy.push({x: figureASide + figureCSide + figureDSide, y: 0});
                pointsCopy.push({x: figureASide + figureCSide, y: 0});
                pointsCopy.push({x: figureASide + figureCSide, y: figureESide});
                pointsCopy.push({x: figureASide + figureCSide / 2, y: figureLSide - figureHeight});
                setPoints(pointsCopy)
                setIsLineClosed(true)
                setIsReadyFigureBuild(true)
            }
        }
        if(appContext.state.selectedFigure === 22){
            let pointsCopy: any = [];
            let lettersForTestFigureCopy: any = [];
            if(figureASide == 0 && figureBSide == 0 && figureCSide == 0 && figureDSide == 0){
                pointsCopy.push({x: 1, y: 0});
                pointsCopy.push({x: 1, y: 1});
                pointsCopy.push({x: 0, y: 1});
                pointsCopy.push({x: 0, y: 1 + 2});
                pointsCopy.push({x: 5, y: 1 + 2});
                pointsCopy.push({x: 5, y: 1});
                pointsCopy.push({x: 5 - 1, y: 1});
                pointsCopy.push({x: 5 - 1, y: 0});

                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(2.5) || 0}
                    y = {getYCoord(3.3) || 0}
                    text={"A" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={15}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(0) || 0}
                    y = {getYCoord(2) || 0}
                    text={"B" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={30}
                    offsetY={15}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(0.5) || 0}
                    y = {getYCoord(0.9) || 0}
                    text={"C" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={0}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(4.5) || 0}
                    y = {getYCoord(0.6) || 0}
                    text={"D" || null}
                    scaleY={-1}
                    fontSize={30}
                    offsetX={30}
                    offsetY={0}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                setLettersForTestFigure(lettersForTestFigureCopy)
                setPointsForTestFigure(pointsCopy)
                setActiveChangingLine([])
                if(onAInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(1 + 2), getXCoord(5), getYCoord(1 + 2)])
                }
                if(onBInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(1), getXCoord(0), getYCoord(1 + 2)])
                }
                if(onCInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(1), getXCoord(1), getYCoord(1)])
                }
                if(onDInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(4), getYCoord(0), getXCoord(4), getYCoord(1)])
                }
            }
            if(figureASide > 0 && figureBSide > 0 && figureCSide > 0 && figureDSide > 0){
                pointsCopy.push({x: figureCSide, y: 0});
                pointsCopy.push({x: figureCSide, y: figureDSide});
                pointsCopy.push({x: 0, y: figureDSide});
                pointsCopy.push({x: 0, y: figureDSide + figureBSide});
                pointsCopy.push({x: figureASide, y: figureDSide + figureBSide});
                pointsCopy.push({x: figureASide, y: figureDSide});
                pointsCopy.push({x: figureASide - figureCSide, y: figureDSide});
                pointsCopy.push({x: figureASide - figureCSide, y: 0});
                setPoints(pointsCopy)
                setIsLineClosed(true)
                setIsReadyFigureBuild(true)
            }
        }

        if(appContext.state.selectedFigure === 23){
            let pointsCopy: any = [];
            let lettersForTestFigureCopy: any = [];
            if(figureASide == 0 && figureBSide == 0 && figureCSide == 0){
                pointsCopy.push({x: 0, y: 4 - 3});
                pointsCopy.push({x: 0, y: 4});
                pointsCopy.push({x: 5, y: 4});
                pointsCopy.push({x: 5, y: 0});

                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(2.5) || 0}
                    y = {getYCoord(4.3) || 0}
                    text={"A" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={15}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(0) || 0}
                    y = {getYCoord(2.5) || 0}
                    text={"B" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={30}
                    offsetY={15}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(5.5) || 0}
                    y = {getYCoord(2) || 0}
                    text={"C" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={30}
                    offsetY={0}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                setLettersForTestFigure(lettersForTestFigureCopy)
                setPointsForTestFigure(pointsCopy)
                setActiveChangingLine([])
                if(onAInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(4), getXCoord(5), getYCoord(4)])
                }
                if(onBInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(4 - 3), getXCoord(0), getYCoord(4)])
                }
                if(onCInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(5), getYCoord(0), getXCoord(5), getYCoord(4)])
                }
            }
            if(figureASide > 0 && figureBSide > 0 && figureCSide > 0){
                pointsCopy.push({x: 0, y: figureCSide - figureBSide});
                pointsCopy.push({x: 0, y: figureCSide});
                pointsCopy.push({x: figureASide, y: figureCSide});
                pointsCopy.push({x: figureASide, y: 0});
                setPoints(pointsCopy)
                setIsLineClosed(true)
                setIsReadyFigureBuild(true)
            } 
        }

        if(appContext.state.selectedFigure === 24){
            let pointsCopy: any = [];
            let lettersForTestFigureCopy: any = [];
            if(figureASide == 0 && figureBSide == 0 && figureCSide == 0){
                pointsCopy.push({x: 0, y: 0});
                pointsCopy.push({x: 0, y: 4});
                pointsCopy.push({x: 5, y: 4});
                pointsCopy.push({x: 5, y: 4 - 3});

                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(2.5) || 0}
                    y = {getYCoord(4.3) || 0}
                    text={"A" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={15}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(0) || 0}
                    y = {getYCoord(2) || 0}
                    text={"B" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={30}
                    offsetY={15}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(5.5) || 0}
                    y = {getYCoord(2.5) || 0}
                    text={"C" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={30}
                    offsetY={0}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                setLettersForTestFigure(lettersForTestFigureCopy)
                setPointsForTestFigure(pointsCopy)
                setActiveChangingLine([])
                if(onAInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(4), getXCoord(5), getYCoord(4)])
                }
                if(onBInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(5), getYCoord(4 - 3), getXCoord(5), getYCoord(4)])
                }
                if(onCInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(0), getXCoord(0), getYCoord(4)])
                }
            }
            if(figureASide > 0 && figureBSide > 0 && figureCSide > 0){
                pointsCopy.push({x: 0, y: 0});
                pointsCopy.push({x: 0, y: figureCSide});
                pointsCopy.push({x: figureASide, y: figureCSide});
                pointsCopy.push({x: figureASide, y: figureCSide - figureBSide});
                setPoints(pointsCopy)
                setIsLineClosed(true)
                setIsReadyFigureBuild(true)
            }
        }
        if(appContext.state.selectedFigure === 25){
            let pointsCopy: any = [];
            let lettersForTestFigureCopy: any = [];
            let rad1 = - (Math.PI / 180) * -75
            let rad2 = - (Math.PI / 180) * -60
            let rad3 = - (Math.PI / 180) * -45
            let rad4 = - (Math.PI / 180) * -30
            let rad5 = - (Math.PI / 180) * -15
            let rad6 = - (Math.PI / 180) * 15
            let rad7 = - (Math.PI / 180) * 30
            let rad8 = - (Math.PI / 180) * 45
            let rad9 = - (Math.PI / 180) * 60
            let rad10 = - (Math.PI / 180) * 75

            if(figureASide == 0){
                pointsCopy.push({x: 5, y: 0});
                pointsCopy.push({x: 0, y: 0});
                pointsCopy.push({x: 5 / 2 + (5 / 2 - 5 / 2) * Math.cos(rad1) - (5 / 2) * Math.sin(rad1), y: figureBSide + (5 / 2 - 5 / 2) * Math.sin(rad1) + (5 / 2) * Math.cos(rad1)});
                pointsCopy.push({x: 5 / 2 + (5 / 2 - 5 / 2) * Math.cos(rad2) - (5 / 2) * Math.sin(rad2), y: figureBSide + (5 / 2 - 5 / 2) * Math.sin(rad2) + (5 / 2) * Math.cos(rad2)});
                pointsCopy.push({x: 5 / 2 + (5 / 2 - 5 / 2) * Math.cos(rad3) - (5 / 2) * Math.sin(rad3), y: figureBSide + (5 / 2 - 5 / 2) * Math.sin(rad3) + (5 / 2) * Math.cos(rad3)});
                pointsCopy.push({x: 5 / 2 + (5 / 2 - 5 / 2) * Math.cos(rad4) - (5 / 2) * Math.sin(rad4), y: figureBSide + (5 / 2 - 5 / 2) * Math.sin(rad4) + (5 / 2) * Math.cos(rad4)});
                pointsCopy.push({x: 5 / 2 + (5 / 2 - 5 / 2) * Math.cos(rad5) - (5 / 2) * Math.sin(rad5), y: figureBSide + (5 / 2 - 5 / 2) * Math.sin(rad5) + (5 / 2) * Math.cos(rad5)});
                pointsCopy.push({x: 5 / 2, y: 5 / 2});
                pointsCopy.push({x: 5 / 2 + (5 / 2 - 5 / 2) * Math.cos(rad6) - (5 / 2) * Math.sin(rad6), y: figureBSide + (5 / 2 - 5 / 2) * Math.sin(rad6) + (5 / 2) * Math.cos(rad6)});
                pointsCopy.push({x: 5 / 2 + (5 / 2 - 5 / 2) * Math.cos(rad7) - (5 / 2) * Math.sin(rad7), y: figureBSide + (5 / 2 - 5 / 2) * Math.sin(rad7) + (5 / 2) * Math.cos(rad7)});
                pointsCopy.push({x: 5 / 2 + (5 / 2 - 5 / 2) * Math.cos(rad8) - (5 / 2) * Math.sin(rad8), y: figureBSide + (5 / 2 - 5 / 2) * Math.sin(rad8) + (5 / 2) * Math.cos(rad8)});
                pointsCopy.push({x: 5 / 2 + (5 / 2 - 5 / 2) * Math.cos(rad9) - (5 / 2) * Math.sin(rad9), y: figureBSide + (5 / 2 - 5 / 2) * Math.sin(rad9) + (5 / 2) * Math.cos(rad9)});
                pointsCopy.push({x: 5 / 2 + (5 / 2 - 5 / 2) * Math.cos(rad10) - (5 / 2) * Math.sin(rad10), y: figureBSide + (5 / 2 - 5 / 2) * Math.sin(rad10) + (5 / 2) * Math.cos(rad10)});
                
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(2.5) || 0}
                    y = {getYCoord(-0.1) || 0}
                    text={"A" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={0}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                setLettersForTestFigure(lettersForTestFigureCopy)
                setPointsForTestFigure(pointsCopy)
                setActiveChangingLine([])
                if(onAInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(0), getXCoord(5), getYCoord(0)])
                }
            }
            else{
                pointsCopy.push({x: figureASide, y: 0});
                pointsCopy.push({x: 0, y: 0});
                pointsCopy.push({x: figureASide / 2 + (figureASide / 2 - figureASide / 2) * Math.cos(rad1) - (figureASide / 2) * Math.sin(rad1), y: figureBSide + (figureASide / 2 - figureASide / 2) * Math.sin(rad1) + (figureASide / 2) * Math.cos(rad1)});
                pointsCopy.push({x: figureASide / 2 + (figureASide / 2 - figureASide / 2) * Math.cos(rad2) - (figureASide / 2) * Math.sin(rad2), y: figureBSide + (figureASide / 2 - figureASide / 2) * Math.sin(rad2) + (figureASide / 2) * Math.cos(rad2)});
                pointsCopy.push({x: figureASide / 2 + (figureASide / 2 - figureASide / 2) * Math.cos(rad3) - (figureASide / 2) * Math.sin(rad3), y: figureBSide + (figureASide / 2 - figureASide / 2) * Math.sin(rad3) + (figureASide / 2) * Math.cos(rad3)});
                pointsCopy.push({x: figureASide / 2 + (figureASide / 2 - figureASide / 2) * Math.cos(rad4) - (figureASide / 2) * Math.sin(rad4), y: figureBSide + (figureASide / 2 - figureASide / 2) * Math.sin(rad4) + (figureASide / 2) * Math.cos(rad4)});
                pointsCopy.push({x: figureASide / 2 + (figureASide / 2 - figureASide / 2) * Math.cos(rad5) - (figureASide / 2) * Math.sin(rad5), y: figureBSide + (figureASide / 2 - figureASide / 2) * Math.sin(rad5) + (figureASide / 2) * Math.cos(rad5)});
                pointsCopy.push({x: figureASide / 2, y: figureASide / 2});
                pointsCopy.push({x: figureASide / 2 + (figureASide / 2 - figureASide / 2) * Math.cos(rad6) - (figureASide / 2) * Math.sin(rad6), y: figureBSide + (figureASide / 2 - figureASide / 2) * Math.sin(rad6) + (figureASide / 2) * Math.cos(rad6)});
                pointsCopy.push({x: figureASide / 2 + (figureASide / 2 - figureASide / 2) * Math.cos(rad7) - (figureASide / 2) * Math.sin(rad7), y: figureBSide + (figureASide / 2 - figureASide / 2) * Math.sin(rad7) + (figureASide / 2) * Math.cos(rad7)});
                pointsCopy.push({x: figureASide / 2 + (figureASide / 2 - figureASide / 2) * Math.cos(rad8) - (figureASide / 2) * Math.sin(rad8), y: figureBSide + (figureASide / 2 - figureASide / 2) * Math.sin(rad8) + (figureASide / 2) * Math.cos(rad8)});
                pointsCopy.push({x: figureASide / 2 + (figureASide / 2 - figureASide / 2) * Math.cos(rad9) - (figureASide / 2) * Math.sin(rad9), y: figureBSide + (figureASide / 2 - figureASide / 2) * Math.sin(rad9) + (figureASide / 2) * Math.cos(rad9)});
                pointsCopy.push({x: figureASide / 2 + (figureASide / 2 - figureASide / 2) * Math.cos(rad10) - (figureASide / 2) * Math.sin(rad10), y: figureBSide + (figureASide / 2 - figureASide / 2) * Math.sin(rad10) + (figureASide / 2) * Math.cos(rad10)});
                setPoints(pointsCopy)
                setIsLineClosed(true)
                setIsReadyFigureBuild(true)
            }  
        }
        if(appContext.state.selectedFigure === 26){
            let pointsCopy: any = [];
            let lettersForTestFigureCopy: any = [];
            if(figureASide == 0 && figureBSide == 0 && figureCSide == 0 && figureHeight == 0){
                pointsCopy.push({x: 0, y: 0});
                pointsCopy.push({x: 0, y:  2});
                pointsCopy.push({x: (5 - 4) / 2, y: 3});
                pointsCopy.push({x: (5 - 4) / 2 + 4, y: 3});
                pointsCopy.push({x: 5, y: 2});
                pointsCopy.push({x: 5, y: 0});
                
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(2.5) || 0}
                    y = {getYCoord(-0.1) || 0}
                    text={"A" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={0}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(0) || 0}
                    y = {getYCoord(1) || 0}
                    text={"B" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={30}
                    offsetY={15}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(2.5) || 0}
                    y = {getYCoord(3) || 0}
                    text={"C" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={0}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(1) || 0}
                    y = {getYCoord(1.5) || 0}
                    text={"H" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={30}
                    offsetY={15}
                    fill={'black'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                setLettersForTestFigure(lettersForTestFigureCopy)
                setPointsForTestFigure(pointsCopy)
                setActiveChangingLine([])
                setAddLinesForTestFigure([getXCoord(0.5), getYCoord(0), getXCoord(0.5), getYCoord(3)])
                if(onAInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(0), getXCoord(5), getYCoord(0)])
                }
                if(onBInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(0), getXCoord(0), getYCoord(2)])
                }
                if(onCInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord((5 - 4) / 2), getYCoord(3), getXCoord((5 - 4) / 2 + 4), getYCoord(3)])
                }
                if(onHeightInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord((5 - 4) / 2), getYCoord(3), getXCoord((5 - 4) / 2), getYCoord(0)])
                }
            }
            if(figureASide > 0 && figureBSide > 0 && figureCSide > 0 && figureHeight > 0){
                pointsCopy.push({x: 0, y: 0});
                pointsCopy.push({x: 0, y: figureBSide});
                pointsCopy.push({x: (figureASide - figureCSide) / 2, y: figureHeight});
                pointsCopy.push({x: (figureASide - figureCSide) / 2 + figureCSide, y: figureHeight});
                pointsCopy.push({x: figureASide, y: figureBSide});
                pointsCopy.push({x: figureASide, y: 0});
                setPoints(pointsCopy)
                setIsLineClosed(true)
                setIsReadyFigureBuild(true)
            }
        }
        if(appContext.state.selectedFigure === 27){
            let pointsCopy: any = [];
            let lettersForTestFigureCopy: any = [];
            if(figureASide == 0 && figureBSide == 0 && figureCSide == 0 && figureDSide == 0 && figureESide == 0 && figureHeight == 0){
                pointsCopy.push({x: 5, y: 0});
                pointsCopy.push({x: 0, y: 0});
                pointsCopy.push({x: (5 - 3) / 2, y: 5 - 1});
                pointsCopy.push({x: (5 - 5) / 2, y: 5 - 1});
                pointsCopy.push({x: (5 - 3) / 2, y: 5});
                pointsCopy.push({x: 5 - ((5 - 3) / 2), y: 5});
                pointsCopy.push({x: 5 - (5 - 5) / 2, y: 5 - 1});
                pointsCopy.push({x: 5 - ((5 - 3) / 2), y: 5 - 1});
                
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(2.5) || 0}
                    y = {getYCoord(-0.1) || 0}
                    text={"A" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={0}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(2.5) || 0}
                    y = {getYCoord(5.3) || 0}
                    text={"B" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={15}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(2.2) || 0}
                    y = {getYCoord(4.5) || 0}
                    text={"C" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={0}
                    fill={'black'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(2.2) || 0}
                    y = {getYCoord(3.7) || 0}
                    text={"E" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={0}
                    fill={'black'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(2.3) || 0}
                    y = {getYCoord(2.5) || 0}
                    text={"H" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={15}
                    fill={'black'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(0.8) || 0}
                    y = {getYCoord(4.5) || 0}
                    text={"D" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={30}
                    offsetY={15}
                    fill={'black'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                setLettersForTestFigure(lettersForTestFigureCopy)
                setPointsForTestFigure(pointsCopy)
                setActiveChangingLine([])
                setAddLinesForTestFigure([getXCoord(2.5), getYCoord(5), getXCoord(2.5), getYCoord(0)])
                setAddLinesForTestFigure1([getXCoord(0), getYCoord(4), getXCoord(0), getYCoord(3.8), getXCoord(5), getYCoord(3.8), getXCoord(5), getYCoord(4)])
                setAddLinesForTestFigure2([getXCoord(1), getYCoord(4), getXCoord(1), getYCoord(4.2), getXCoord(4), getYCoord(4.2), getXCoord(4), getYCoord(4)])
                setAddLinesForTestFigure3([getXCoord(1), getYCoord(4), getXCoord(0.8), getYCoord(4), getXCoord(0.8), getYCoord(5), getXCoord(1), getYCoord(5)])
                if(onAInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(0), getXCoord(5), getYCoord(0)])
                }
                if(onBInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(1), getYCoord(5), getXCoord(4), getYCoord(5)])
                }
                if(onCInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(1), getYCoord(4.2), getXCoord(4), getYCoord(4.2)])
                }
                if(onDInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0.8), getYCoord(4), getXCoord(0.8), getYCoord(5)])
                }
                if(onEInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(3.8), getXCoord(5), getYCoord(3.8)])
                }
                if(onHeightInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(2.5), getYCoord(0), getXCoord(2.5), getYCoord(5)])
                }
            }
            if(figureASide > 0 && figureBSide > 0 && figureCSide > 0 && figureDSide > 0 && figureESide > 0 && figureHeight > 0){
                pointsCopy.push({x: figureASide, y: 0});
                pointsCopy.push({x: 0, y: 0});
                pointsCopy.push({x: (figureESide - figureCSide) / 2, y: figureHeight - figureDSide});
                pointsCopy.push({x: (figureASide - figureESide) / 2, y: figureHeight - figureDSide});
                pointsCopy.push({x: (figureASide - figureBSide) / 2, y: figureHeight});
                pointsCopy.push({x: figureASide - ((figureASide - figureBSide) / 2), y: figureHeight});
                pointsCopy.push({x: figureASide - (figureASide - figureESide) / 2, y: figureHeight - figureDSide});
                pointsCopy.push({x: figureESide - ((figureASide - figureCSide) / 2), y: figureHeight - figureDSide});
                setPoints(pointsCopy)
                setIsLineClosed(true)
                setIsReadyFigureBuild(true)
            }
        }
        if(appContext.state.selectedFigure === 28){
            let pointsCopy: any = [];
            let lettersForTestFigureCopy: any = [];
            if(figureASide == 0 && figureBSide == 0 && figureCSide == 0 && figureDSide == 0){
                pointsCopy.push({x: 4, y: 0});
                pointsCopy.push({x: 0, y: 0});
                pointsCopy.push({x: 4 / 2 - 2 / 2, y: 3});
                pointsCopy.push({x: 4 / 2 - 2 / 2, y: 1});
                pointsCopy.push({x: 4 / 2 + 2 / 2, y: 1});
                pointsCopy.push({x: 4 / 2 + 2 / 2, y: 3});
                
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(2) || 0}
                    y = {getYCoord(-0.1) || 0}
                    text={"A" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={0}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(0) || 0}
                    y = {getYCoord(1) || 0}
                    text={"B" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={30}
                    offsetY={15}
                    fill={'black'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(2) || 0}
                    y = {getYCoord(1.2) || 0}
                    text={"C" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={15}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(2) || 0}
                    y = {getYCoord(0.5) || 0}
                    text={"D" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={30}
                    offsetY={15}
                    fill={'black'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                setLettersForTestFigure(lettersForTestFigureCopy)
                setPointsForTestFigure(pointsCopy)
                setActiveChangingLine([])
                setAddLinesForTestFigure([getXCoord(2), getYCoord(0), getXCoord(2), getYCoord(1)])
                setAddLinesForTestFigure1([getXCoord(0), getYCoord(0), getXCoord(0), getYCoord(3), getXCoord(1), getYCoord(3)])
                if(onAInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(0), getXCoord(4), getYCoord(0)])
                }
                if(onBInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(0), getXCoord(0), getYCoord(3)])
                }
                if(onCInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(4 / 2 - 2 / 2), getYCoord(1), getXCoord(4 / 2 + 2 / 2), getYCoord(1)])
                }
                if(onDInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(4 / 2), getYCoord(0), getXCoord(4 / 2), getYCoord(1)])
                }
            }
            if(figureASide > 0 && figureBSide > 0 && figureCSide > 0 && figureDSide > 0){
                pointsCopy.push({x: figureASide, y: 0});
                pointsCopy.push({x: 0, y: 0});
                pointsCopy.push({x: figureASide / 2 - figureCSide / 2, y: figureBSide});
                pointsCopy.push({x: figureASide / 2 - figureCSide / 2, y: figureDSide});
                pointsCopy.push({x: figureASide / 2 + figureCSide / 2, y: figureDSide});
                pointsCopy.push({x: figureASide / 2 + figureCSide / 2, y: figureBSide});
                setPoints(pointsCopy)
                setIsLineClosed(true)
                setIsReadyFigureBuild(true)
            }
        }
        if(appContext.state.selectedFigure === 29){
            let pointsCopy: any = [];
            let lettersForTestFigureCopy: any = [];
            if(figureASide == 0 && figureBSide == 0 && figureCSide == 0 && figureDSide == 0){
                pointsCopy.push({x: 0, y: 0});
                pointsCopy.push({x: 0, y: 3});
                pointsCopy.push({x: 4, y: 3});
                pointsCopy.push({x: 5, y: 2});
                pointsCopy.push({x: 5, y: 0});

                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(2.5) || 0}
                    y = {getYCoord(-0.1) || 0}
                    text={"A" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={0}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(0) || 0}
                    y = {getYCoord(1.5) || 0}
                    text={"B" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={30}
                    offsetY={15}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(2.5) || 0}
                    y = {getYCoord(3.3) || 0}
                    text={"C" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={10}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(5.5) || 0}
                    y = {getYCoord(1.1) || 0}
                    text={"D" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={30}
                    offsetY={0}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                setLettersForTestFigure(lettersForTestFigureCopy)
                setPointsForTestFigure(pointsCopy)
                setActiveChangingLine([])
                if(onAInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(0), getXCoord(5), getYCoord(0)])
                }
                if(onBInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(0), getXCoord(0), getYCoord(3)])
                }
                if(onCInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(3), getXCoord(4), getYCoord(3)])
                }
                if(onDInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(5), getYCoord(0), getXCoord(5), getYCoord(2)])
                }
            }
            if(figureASide > 0 && figureBSide > 0 && figureCSide > 0 && figureDSide > 0){
                pointsCopy.push({x: 0, y: 0});
                pointsCopy.push({x: 0, y: figureBSide});
                pointsCopy.push({x: figureCSide, y: figureBSide});
                pointsCopy.push({x: figureASide, y: figureDSide});
                pointsCopy.push({x: figureASide, y: 0});
                setPoints(pointsCopy)
                setIsLineClosed(true)
                setIsReadyFigureBuild(true)
            }
        }
        if(appContext.state.selectedFigure === 30){
            let pointsCopy: any = [];
            let lettersForTestFigureCopy: any = [];
            if(figureASide == 0 && figureBSide == 0 && figureCSide == 0 && figureDSide == 0){
                pointsCopy.push({x: 0, y: 0});
                pointsCopy.push({x: 0, y: 2});
                pointsCopy.push({x: 5 - 4, y: 3});
                pointsCopy.push({x: 5, y: 3});
                pointsCopy.push({x: 5, y: 0});

                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(2.5) || 0}
                    y = {getYCoord(-0.1) || 0}
                    text={"A" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={0}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(0) || 0}
                    y = {getYCoord(1.1) || 0}
                    text={"B" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={30}
                    offsetY={0}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(2.5) || 0}
                    y = {getYCoord(3.3) || 0}
                    text={"C" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={10}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(5.5) || 0}
                    y = {getYCoord(1.5) || 0}
                    text={"D" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={30}
                    offsetY={15}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                setLettersForTestFigure(lettersForTestFigureCopy)
                setPointsForTestFigure(pointsCopy)
                setActiveChangingLine([])
                if(onAInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(0), getXCoord(5), getYCoord(0)])
                }
                if(onBInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(5), getYCoord(0), getXCoord(5), getYCoord(3)])
                }
                if(onCInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(5 - 4), getYCoord(3), getXCoord(5), getYCoord(3)])
                }
                if(onDInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(0), getXCoord(0), getYCoord(2)])
                }
            }
            if(figureASide > 0 && figureBSide > 0 && figureCSide > 0 && figureDSide > 0){
                pointsCopy.push({x: 0, y: 0});
                pointsCopy.push({x: 0, y: figureDSide});
                pointsCopy.push({x: figureASide - figureCSide, y: figureBSide});
                pointsCopy.push({x: figureASide, y: figureBSide});
                pointsCopy.push({x: figureASide, y: 0});
                setPoints(pointsCopy)
                setIsLineClosed(true)
                setIsReadyFigureBuild(true)
            }
        }
        if(appContext.state.selectedFigure === 31){
            let pointsCopy: any = [];
            let lettersForTestFigureCopy: any = [];
            if(figureASide == 0 && figureBSide == 0 && figureHeight == 0){
                pointsCopy.push({x: 0, y: 0});
                pointsCopy.push({x: 0, y: 2});
                pointsCopy.push({x: 6 / 2, y: 4});
                pointsCopy.push({x: 6, y: 2});
                pointsCopy.push({x: 6, y: 0});
                
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(3) || 0}
                    y = {getYCoord(-0.1) || 0}
                    text={"A" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={0}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(0) || 0}
                    y = {getYCoord(1) || 0}
                    text={"B" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={30}
                    offsetY={15}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(3) || 0}
                    y = {getYCoord(2) || 0}
                    text={"H" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={30}
                    offsetY={15}
                    fill={'black'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                setLettersForTestFigure(lettersForTestFigureCopy)
                setPointsForTestFigure(pointsCopy)
                setActiveChangingLine([])
                setAddLinesForTestFigure([getXCoord(3), getYCoord(0), getXCoord(3), getYCoord(4)])
                if(onAInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(0), getXCoord(6), getYCoord(0)])
                }
                if(onBInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(0), getXCoord(0), getYCoord(2)])
                }
                if(onHeightInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(6 / 2), getYCoord(0), getXCoord(6 / 2), getYCoord(4)])
                }
            }
            if(figureASide > 0 && figureBSide > 0 && figureHeight > 0){
                pointsCopy.push({x: 0, y: 0});
                pointsCopy.push({x: 0, y: figureBSide});
                pointsCopy.push({x: figureASide / 2, y: figureHeight});
                pointsCopy.push({x: figureASide, y: figureBSide});
                pointsCopy.push({x: figureASide, y: 0});
                setPoints(pointsCopy)
                setIsLineClosed(true)
                setIsReadyFigureBuild(true)
            }
        }
        if(appContext.state.selectedFigure === 32){
            if(figureASide == 0 && figureBSide == 0 && figureCSide == 0 && figureDSide == 0 && figureDSide == 0){

                let length: any = ( Math.sqrt(Math.pow((5 / 2  - 0), 2) + Math.pow((5 - 2), 2))).toFixed(2) 
                let trCoords: any = [];
                var hA = Math.sqrt(2 *2 - ((length * length +2 *2 - 2 * 2) / (2 * length)) ** 2);

                var xB = length;
                var yB = 0;
                
                var xC = ((2 *2 + length * length - 2 * 2) / (2 * length));
                var yC = hA;

                trCoords.push({x: 0, y: 0});
                trCoords.push({x: xC, y: yC});
                trCoords.push({x: xB, y: yB});

                let y = 0
                let x = 0
                let rad = (Math.PI / 180)
                do{
                    for (let i = 0; i < trCoords.length; i++) {
                        // @ts-ignore
                        trCoords[i] = {x: trCoords[0].x + (trCoords[i].x - trCoords[0].x) * Math.cos(rad) - (trCoords[i].y - trCoords[0].y) * Math.sin(rad), y: trCoords[0].y + (trCoords[i].x - trCoords[0].x) * Math.sin(rad) + (trCoords[i].y - trCoords[0].y) * Math.cos(rad)};
                    }
                    y = trCoords[2].y
                    x = trCoords[2].x
                }while( y <= 5 - 2 || x == 0)
                let pointsCopy: any = [];
            let lettersForTestFigureCopy: any = [];
                pointsCopy.push({x: 5, y: 0});
                pointsCopy.push({x: 0, y: 0});
                pointsCopy.push({x: 0, y: 2});
                pointsCopy.push({x: trCoords[1].x, y: trCoords[1].y + 2});
                pointsCopy.push({x: 5 / 2, y: 5});
                pointsCopy.push({x: 5 / 2 + (5 / 2 - trCoords[1].x), y: trCoords[1].y + 2});
                pointsCopy.push({x: 5, y: 2});
                
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(2.5) || 0}
                    y = {getYCoord(-0.1) || 0}
                    text={"A" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={0}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(0) || 0}
                    y = {getYCoord(1) || 0}
                    text={"B" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={30}
                    offsetY={15}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(0.5) || 0}
                    y = {getYCoord(3) || 0}
                    text={"C" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={30}
                    offsetY={30}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(1.5) || 0}
                    y = {getYCoord(4.5) || 0}
                    text={"D" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={15}
                    offsetY={15}
                    fill={'blue'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                lettersForTestFigureCopy.push(<Text
                    x = {getXCoord(2.5) || 0}
                    y = {getYCoord(2.5) || 0}
                    text={"H" || null}
                    scaleY={-1}  
                    fontSize={30}
                    offsetX={30}
                    offsetY={15}
                    fill={'black'} 
                    stroke={'black'} 
                    strokeWidth={0.8}
                    /> )
                setLettersForTestFigure(lettersForTestFigureCopy)
                setPointsForTestFigure(pointsCopy)
                setActiveChangingLine([])
                setAddLinesForTestFigure([getXCoord(2.5), getYCoord(0), getXCoord(2.5), getYCoord(5)])
                if(onAInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(0), getXCoord(5), getYCoord(0)])
                }
                if(onBInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(0), getXCoord(0), getYCoord(2)])
                }
                if(onCInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(0), getYCoord(2), getXCoord(trCoords[1].x), getYCoord(trCoords[1].y + 2)])
                }
                if(onDInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(trCoords[1].x), getYCoord(trCoords[1].y + 2), getXCoord(5 / 2), getYCoord(5)])
                }
                if(onHeightInputFocus){
                    //@ts-ignore
                    setActiveChangingLine([getXCoord(5 / 2), getYCoord(0), getXCoord(5 / 2), getYCoord(5)])
                }
            }
            if(figureASide > 0 && figureBSide > 0 && figureCSide > 0 && figureDSide > 0 && figureDSide > 0){
                let length: any = ( Math.sqrt(Math.pow((figureASide / 2  - 0), 2) + Math.pow((figureHeight - figureBSide), 2))).toFixed(2) 
                let trCoords: any = [];
                var hA = Math.sqrt(figureCSide *figureCSide - ((length * length +figureCSide *figureCSide - figureDSide * figureDSide) / (2 * length)) ** 2);

                var xB = length;
                var yB = 0;
                
                var xC = ((figureCSide *figureCSide + length * length - figureDSide * figureDSide) / (2 * length));
                var yC = hA;

                trCoords.push({x: 0, y: 0});
                trCoords.push({x: xC, y: yC});
                trCoords.push({x: xB, y: yB});



                let y = 0
                let x = 0
                let rad = (Math.PI / 180)
                do{
                    for (let i = 0; i < trCoords.length; i++) {
                        // @ts-ignore
                        trCoords[i] = {x: trCoords[0].x + (trCoords[i].x - trCoords[0].x) * Math.cos(rad) - (trCoords[i].y - trCoords[0].y) * Math.sin(rad), y: trCoords[0].y + (trCoords[i].x - trCoords[0].x) * Math.sin(rad) + (trCoords[i].y - trCoords[0].y) * Math.cos(rad)};
                    }
                    y = trCoords[2].y
                    x = trCoords[2].x
                }while( y <= figureHeight - figureBSide || x == 0)
                let pointsCopy: any = [];
                pointsCopy.push({x: figureASide, y: 0});
                pointsCopy.push({x: 0, y: 0});
                pointsCopy.push({x: 0, y: figureBSide});
                pointsCopy.push({x: trCoords[1].x, y: trCoords[1].y + figureBSide});
                pointsCopy.push({x: figureASide / 2, y: figureHeight});
                pointsCopy.push({x: figureASide / 2 + (figureASide / 2 - trCoords[1].x), y: trCoords[1].y + figureBSide});
                pointsCopy.push({x: figureASide, y: figureBSide});
                setPoints(pointsCopy)
                setIsLineClosed(true)
                setIsReadyFigureBuild(true)
            }
        }
        
    },[appContext.state.selectedFigure, figureASide, figureBSide, figureCSide, figureDSide, figureESide, figureA1Side, figureHeight, figureLSide, onAInputFocus, onA1InputFocus, onBInputFocus, onCInputFocus, onDInputFocus, onEInputFocus, onHeightInputFocus, onLInputFocus])
    React.useEffect(() => {
    }, [deleteDot]);



    React.useEffect(() => {
        if(appContext.state.isEditedMode == true){
            if(points.length == 0 && clickedCoords !== null && isDottAdditional == false && areasCount == -1 && changeDott == false){
                let pointsCopy: any = [];
                pointsCopy.push(clickedCoords);
                setPoints(pointsCopy)
                setStart(true)
            }
            if (clickedCoords !== null && onDottClick && start && isDottAdditional == false && addpoint.length == 0 && areasCount == -1 && changeDott == false) {
                let pointsCopy: any = [];
                for (let i = 0; i < points.length; i++) {
                    pointsCopy[i] = points[i];
                }
                pointsCopy.push(clickedCoords);
                setPoints(pointsCopy);
                setAddPoint([])
                setLastCanceledPoint([])
            }
            if(onDottClick == false && isDottAdditional == false && areasCount == -1 && changeDott == false){
                setOnDottClick(true)
            }
            if(lastClickedDott !== null && onDottClick && isDottAdditional == false && addpoint.length == 0 && clickedCoords !== null && areasCount == -1 && changeDott == false){
                let pointsCopy: any = [];
                for (let i = 0; i < points.length; i++) {
                    pointsCopy[i] = points[i];
                }
                pointsCopy.splice(lastClickedDott + 1, 0, clickedCoords);
                setPoints(pointsCopy);
                setlastClickedDott(null)
                setAddPoint([])
                setActiveDottCoordsX(null)
                setActiveDottCoordsY(null)
                setAddPoint([])
            }
            if(isDottAdditional && clickedCoords !== null && areasCount == -1 && changeDott == false){
                setAddPoint(clickedCoords)
                setIsDottAdditional(false)
            }
            if(isDottAdditional == false && addpoint.length !== 0 && lastClickedDott == null && clickedCoords !== null && onDottClick && areasCount == -1 && changeDott == false){
                let pointsCopy: any = [];
                for (let i = 0; i < points.length; i++) {
                    pointsCopy[i] = points[i];
                }
                // @ts-ignore
                pointsCopy.push({x: addpoint.x, y: addpoint.y + clickedCoords.y});
                setPoints(pointsCopy);
                setAddPoint([])
                setAddPoint([])
            }
            if(isDottAdditional == false && addpoint.length !== 0 && lastClickedDott !== null && clickedCoords !== null && onDottClick && areasCount == -1 && changeDott == false){
                let pointsCopy: any = [];
                for (let i = 0; i < points.length; i++) {
                    pointsCopy[i] = points[i];
                }// @ts-ignore
                pointsCopy.splice(lastClickedDott + 1, 0, {x: addpoint.x, y: addpoint.y + clickedCoords.y});
                setPoints(pointsCopy);
                setlastClickedDott(null)
                setAddPoint([])
                setActiveDottCoordsX(null)
                setActiveDottCoordsY(null)
                setAddPoint([])
            }
            if(clickedCoords !== null && changeDott && areasCount == -1){
                let pointsCopy: any = [];
                for (let i = 0; i < points.length; i++) {
                    pointsCopy[i] = points[i];
                }// @ts-ignore
                pointsCopy[lastClickedDott] = clickedCoords
                setPoints(pointsCopy);
                setlastClickedDott(null)
                setAddPoint([])
                setActiveDottCoordsX(null)
                setActiveDottCoordsY(null)
                setAddPoint([])
                setChangeDott(false)
            }



            if (clickedCoords !== null && onDottClick && start && isDottAdditional == false && addpoint.length == 0 && areasCount > 0 && changeDott == false) {
                let pointsCopy: any = [];
                for (let i = 0; i < eval("areas" + areasCount).length; i++) {
                    pointsCopy[i] = eval("areas" + areasCount)[i];
                }
                pointsCopy.push(clickedCoords);
                eval("setAreas" + areasCount)(pointsCopy);
                setAddPoint([])
                setLastCanceledPoint([])
            }
            if(onDottClick == false && isDottAdditional == false && areasCount > 0 && changeDott == false){
                setOnDottClick(true)
            }
            if(lastClickedDott !== null && onDottClick && isDottAdditional == false && addpoint.length == 0 && clickedCoords !== null && areasCount > 0 && changeDott == false){
                let pointsCopy: any = [];
                for (let i = 0; i < eval("areas" + areasCount).length; i++) {
                    pointsCopy[i] = eval("areas" + areasCount)[i];
                }
                pointsCopy.splice(lastClickedDott + 1, 0, clickedCoords);
                eval("setAreas" + areasCount)(pointsCopy);
                setlastClickedDott(null)
                setAddPoint([])
                setActiveDottCoordsX(null)
                setActiveDottCoordsY(null)
                setAddPoint([])
            }
            if(isDottAdditional && clickedCoords !== null && areasCount > 0 && changeDott == false){
                setAddPoint(clickedCoords)
                setIsDottAdditional(false)
            }
            if(isDottAdditional == false && addpoint.length !== 0 && lastClickedDott == null && clickedCoords !== null && onDottClick && areasCount > 0 && changeDott == false){
                let pointsCopy: any = [];
                for (let i = 0; i < eval("areas" + areasCount).length; i++) {
                    pointsCopy[i] = eval("areas" + areasCount)[i];
                }
                // @ts-ignore
                pointsCopy.push({x: addpoint.x, y: addpoint.y + clickedCoords.y});
                eval("setAreas" + areasCount)(pointsCopy);
                setAddPoint([])
                setAddPoint([])
            }
            if(isDottAdditional == false && addpoint.length !== 0 && lastClickedDott !== null && clickedCoords !== null && onDottClick && areasCount > 0 && changeDott == false){
                let pointsCopy: any = [];
                for (let i = 0; i < eval("areas" + areasCount).length; i++) {
                    pointsCopy[i] = eval("areas" + areasCount)[i];
                }// @ts-ignore
                pointsCopy.splice(lastClickedDott + 1, 0, {x: addpoint.x, y: addpoint.y + clickedCoords.y});
                eval("setAreas" + areasCount)(pointsCopy);
                setlastClickedDott(null)
                setAddPoint([])
                setActiveDottCoordsX(null)
                setActiveDottCoordsY(null)
                setAddPoint([])
            }
            if(clickedCoords !== null && changeDott && areasCount > 0){
                let pointsCopy: any = [];
                for (let i = 0; i < eval("areas" + areasCount).length; i++) {
                    pointsCopy[i] = eval("areas" + areasCount)[i];
                }// @ts-ignore
                pointsCopy[lastClickedDott] = clickedCoords
                eval("setAreas" + areasCount)(pointsCopy);
                setlastClickedDott(null)
                setAddPoint([])
                setActiveDottCoordsX(null)
                setActiveDottCoordsY(null)
                setAddPoint([])
                setChangeDott(false)
            }
        }
    }, [clickedCoords]);
    React.useEffect(() => {
        if(prevStep == true && appContext.state.isEditedMode){
            if(areasCount == -1){
                let pointsCopy: any = [];
                for (let i = 0; i < points.length; i++) {
                    pointsCopy[i] = points[i];
                }// @ts-ignore
                let canceledPointsCopy: any = [];
                for (let i = 0; i < lastCanceledPoint.length; i++) {
                    canceledPointsCopy[i] = lastCanceledPoint[i];
                }
                if(qwe == null){
                    canceledPointsCopy.push([-1, pointsCopy.pop()])
                }
                if(qwe !== null){
                    let zxc = pointsCopy.splice(qwe + 1, 1)
                    canceledPointsCopy.push([qwe, zxc[0]])
                }
                
                setPoints(pointsCopy);
                setLastCanceledPoint(canceledPointsCopy)
                setQwe(null)
            }
            if(areasCount > 0){
                let pointsCopy: any = [];
                for (let i = 0; i < eval("areas" + areasCount).length; i++) {
                    pointsCopy[i] = eval("areas" + areasCount)[i];
                }// @ts-ignore
                let canceledPointsCopy: any = [];
                for (let i = 0; i < lastCanceledPoint.length; i++) {
                    canceledPointsCopy[i] = lastCanceledPoint[i];
                }
                if(qwe == null){
                    canceledPointsCopy.push([-1, pointsCopy.pop()])
                }
                if(qwe !== null){
                    let zxc = pointsCopy.splice(qwe + 1, 1)
                    canceledPointsCopy.push([qwe, zxc[0]])
                }
                
                eval("setAreas" + areasCount)(pointsCopy);
                setLastCanceledPoint(canceledPointsCopy)
                setQwe(null)
            }
            setPrevStep(false)
        }
        
        if(nextStep == true && lastCanceledPoint.length !== 0 && appContext.state.isEditedMode){
            if(areasCount == -1){
                let pointsCopy: any = [];
                for (let i = 0; i < points.length; i++) {
                    pointsCopy[i] = points[i];
                }
                let canceledPointsCopy: any = [];
                for (let i = 0; i < lastCanceledPoint.length; i++) {
                    canceledPointsCopy[i] = lastCanceledPoint[i];
                }
                // @ts-ignore
                if(lastCanceledPoint[lastCanceledPoint.length - 1][0] == -1){
                    pointsCopy.push(lastCanceledPoint[lastCanceledPoint.length - 1][1])
                }
                // @ts-ignore
                if(lastCanceledPoint[lastCanceledPoint.length - 1][0] !== -1){
                    pointsCopy.splice(lastCanceledPoint[lastCanceledPoint.length - 1][0] + 1, 0, lastCanceledPoint[lastCanceledPoint.length - 1][1])
                }
                canceledPointsCopy.pop()
                setPoints(pointsCopy);
                setLastCanceledPoint(canceledPointsCopy)
            }
            if(areasCount > 0){
                let pointsCopy: any = [];
                for (let i = 0; i < eval("areas" + areasCount).length; i++) {
                    pointsCopy[i] = eval("areas" + areasCount)[i];
                }
                let canceledPointsCopy: any = [];
                for (let i = 0; i < lastCanceledPoint.length; i++) {
                    canceledPointsCopy[i] = lastCanceledPoint[i];
                }
                // @ts-ignore
                if(lastCanceledPoint[lastCanceledPoint.length - 1][0] == -1){
                    pointsCopy.push(lastCanceledPoint[lastCanceledPoint.length - 1][1])
                }
                // @ts-ignore
                if(lastCanceledPoint[lastCanceledPoint.length - 1][0] !== -1){
                    pointsCopy.splice(lastCanceledPoint[lastCanceledPoint.length - 1][0] + 1, 0, lastCanceledPoint[lastCanceledPoint.length - 1][1])
                }
                canceledPointsCopy.pop()
                eval("setAreas" + areasCount)(pointsCopy);
                setLastCanceledPoint(canceledPointsCopy)
            }
            
            setNextStep(false)
        }
    }, [prevStep, nextStep]);  
    React.useEffect(() => {
        if(deleteDot == true && lastClickedDott !== null && appContext.state.isEditedMode){
            if(areasCount == -1){
                let pointsCopy: any = [];
                for (let i = 0; i < points.length; i++) {
                    pointsCopy[i] = points[i];
                }
                pointsCopy.splice(lastClickedDott, 1);
                setPoints(pointsCopy);
            }
            if(areasCount > 0){
                let pointsCopy: any = [];
                for (let i = 0; i < eval("areas" + areasCount).length; i++) {
                    pointsCopy[i] = eval("areas" + areasCount)[i];
                }
                pointsCopy.splice(lastClickedDott, 1);
                eval("setAreas" + areasCount)(pointsCopy);
            }
            setlastClickedDott(null)
            setActiveDottCoordsX(null)
            setActiveDottCoordsY(null)
            setDeleteDot(null)
        }
    }, [deleteDot]);
    React.useEffect(() => {
        if(finishFigure == true){
            if(isLineClosed == false && areasCount == -1){
                setIsLineClosed(true)
                let pointsCopy: any = [];
                // @ts-ignore
                let bottomDott: any = points[0].y;
                // @ts-ignore
                let leftDott: any = points[0].x;
                for (let i = 0; i < points.length; i++) {
                    pointsCopy[i] = points[i];
                    if(pointsCopy[i].y < bottomDott){
                        bottomDott = pointsCopy[i].y
                    }
                    if(pointsCopy[i].x < leftDott){
                        leftDott = pointsCopy[i].x
                    }
                }
                for (let i = 0; i < points.length; i++) {
                    pointsCopy[i].x = Number((pointsCopy[i].x - leftDott).toFixed(2))
                    pointsCopy[i].y = Number((pointsCopy[i].y - bottomDott).toFixed(2))
                }
                setPoints(pointsCopy)
            }
            setFinishFigure(false)
        }
    }, [finishFigure]);
    React.useEffect(() => {
        // Координати точок будь-якої фігури (визначте ваші точки)
        const pointsForText = points

        // Функція для знаходження вектора нормалі до відрізка
        function findNormalVector(point1: any, point2: any) {
            const dx = point2.x - point1.x;
            const dy = point2.y - point1.y;
            const length = Math.sqrt(dx ** 2 + dy ** 2);
            return [-dy / length, dx / length];
        }

        // Знаходимо вектори нормалі для кожної сторони фігури
        const normalVectors = pointsForText.map((point: any, index: any) => {
            const nextPoint = pointsForText[(index + 1) % pointsForText.length];
            return findNormalVector(point, nextPoint);
        });

        // Зсув середин відрізків на 10 одиниць вздовж нормалі
        const midpoints = pointsForText.map((point: any, index: any) => {
            const nextPoint = pointsForText[(index + 1) % pointsForText.length];
            return [(point.x + nextPoint.x) / 2, (point.y + nextPoint.y) / 2];
        });

        const shiftedMidpoints = midpoints.map((midpoint: any, index: any) => {
            const normalVector = normalVectors[index];
            if(isClockwise(pointsForText)){
                return [
                    midpoint[0] + 0.5 * normalVector[0],
                    midpoint[1] + 0.3 * normalVector[1],
                ];
            }
            else{
                return [
                    midpoint[0] - 0.5 * normalVector[0],
                    midpoint[1] - 0.3 * normalVector[1],
                ];
            }
        });

            // build anchors
            let buildCustomPointsCopy: any = [];
            for (let i = 0; i < points.length; i++) {
                // @ts-ignore
                buildCustomPointsCopy.push(buildAnchor(getXCoord(points[i].x), getYCoord(points[i].y), i, i, getXCoord(shiftedMidpoints[i][0]), getYCoord(shiftedMidpoints[i][1])));
            }
            setBuiltCustomPoints(buildCustomPointsCopy);
            
            // set array of points to lines
            let l: any = [];
            for (let i = 0; i < points.length; i++) {
                // @ts-ignore
                l.push(getXCoord(points[i].x));
                // @ts-ignore
                l.push(getYCoord(points[i].y));
            }
            // @ts-ignore
            setLines(l);
        
        calcPolygonPointsCallback(points)
    }, [points, isLineClosed, appContext.state.selectedScale]);
    React.useEffect(() => {
        // set array of points to lines
        let l: any = [];
        for (let i = 0; i < pointsForTestFigure.length; i++) {
            // @ts-ignore
            l.push(getXCoord(pointsForTestFigure[i].x));
            // @ts-ignore
            l.push(getYCoord(pointsForTestFigure[i].y));
        }
        // @ts-ignore
        setLinesForTestFigure(l);
    }, [pointsForTestFigure])
    
    React.useEffect(() => {
        if(addArea == true && areas1.length == 0){
            setAreas1(points)
            setPoints([])
            setAddArea(false)
            if(isLineClosed == true && isLinesClosed1 == false){
                setIsLinesClosed1(true)
            }
        }
        if(addArea == true && areas2.length == 0 && areas1.length !== 0){
            setAreas2(points) 
            setPoints([])
            setAddArea(false)
            if(isLineClosed == true && isLinesClosed2 == false){
                setIsLinesClosed2(true)
            }
        }
        if(addArea == true && areas3.length == 0 && areas2.length !== 0){
            setAreas3(points)
            setPoints([])
            setAddArea(false)
            if(isLineClosed == true && isLinesClosed3 == false){
                setIsLinesClosed3(true)
            }
        }
        if(addArea == true && areas4.length == 0 && areas3.length !== 0){
            setAreas4(points)
            setPoints([])
            setAddArea(false)
            if(isLineClosed == true && isLinesClosed4 == false){
                setIsLinesClosed4(true)
            }
        }
        if(addArea == true && areas5.length == 0 && areas4.length !== 0){
            setAreas5(points)
            setPoints([])
            setAddArea(false)
            if(isLineClosed == true && isLinesClosed5 == false){
                setIsLinesClosed5(true)
            }
        }
        if(addArea == true && areas6.length == 0 && areas5.length !== 0){
            setAreas6(points)
            setPoints([])
            setAddArea(false)
            if(isLineClosed == true && isLinesClosed6 == false){
                setIsLinesClosed6(true)
            }
        }
        if(addArea == true && areas7.length == 0 && areas6.length !== 0){
            setAreas7(points)
            setPoints([])
            setAddArea(false)
            if(isLineClosed == true && isLinesClosed7 == false){
                setIsLinesClosed7(true)
            }
        }
        if(addArea == true && areas8.length == 0 && areas7.length !== 0){
            setAreas8(points)
            setPoints([])
            setAddArea(false)
            if(isLineClosed == true && isLinesClosed8 == false){
                setIsLinesClosed8(true)
            }
        }
        if(addArea == true && areas9.length == 0 && areas8.length !== 0){
            setAreas9(points)
            setPoints([])
            setAddArea(false)
            if(isLineClosed == true && isLinesClosed9 == false){
                setIsLinesClosed9(true)
            }
        }
        if(addArea == true && areas10.length == 0 && areas9.length !== 0){
            setAreas10(points)
            setPoints([])
            setAddArea(false)
            if(isLineClosed == true && isLinesClosed10 == false){
                setIsLinesClosed10(true)
            }
        }
        if(addArea == true && areas11.length == 0 && areas10.length !== 0){
            setAreas11(points)
            setPoints([])
            setAddArea(false)
            if(isLineClosed == true && isLinesClosed11 == false){
                setIsLinesClosed11(true)
            }
        }
        if(addArea == true && areas12.length == 0 && areas11.length !== 0){
            setAreas12(points)
            setPoints([])
            setAddArea(false)
            if(isLineClosed == true && isLinesClosed12 == false){
                setIsLinesClosed12(true)
            }
        }
        if(addArea == true && areas13.length == 0 && areas12.length !== 0){
            setAreas13(points)
            setPoints([])
            setAddArea(false)
            if(isLineClosed == true && isLinesClosed13 == false){
                setIsLinesClosed13(true)
            }
        }
        if(addArea == true && areas14.length == 0 && areas13.length !== 0){
            setAreas14(points)
            setPoints([])
            setAddArea(false)
            if(isLineClosed == true && isLinesClosed14 == false){
                setIsLinesClosed14(true)
            }
        }
        if(addArea == true && areas15.length == 0 && areas14.length !== 0){
            setAreas15(points)
            setPoints([])
            setAddArea(false)
            if(isLineClosed == true && isLinesClosed15 == false){
                setIsLinesClosed15(true)
            }
        }
        if(addArea == true && areas16.length == 0 && areas15.length !== 0){
            setAreas16(points)
            setPoints([])
            setAddArea(false)
            if(isLineClosed == true && isLinesClosed16 == false){
                setIsLinesClosed16(true)
            }
        }
        if(addArea == true && areas17.length == 0 && areas16.length !== 0){
            setAreas17(points)
            setPoints([])
            setAddArea(false)
            if(isLineClosed == true && isLinesClosed17 == false){
                setIsLinesClosed17(true)
            }
        }
        if(addArea == true && areas18.length == 0 && areas17.length !== 0){
            setAreas18(points)
            setPoints([])
            setAddArea(false)
            if(isLineClosed == true && isLinesClosed18 == false){
                setIsLinesClosed18(true)
            }
        }
        if(addArea == true && areas19.length == 0 && areas18.length !== 0){
            setAreas19(points)
            setPoints([])
            setAddArea(false)
            if(isLineClosed == true && isLinesClosed19 == false){
                setIsLinesClosed19(true)
            }
        }
        if(addArea == true && areas20.length == 0 && areas19.length !== 0){
            setAreas20(points)
            setPoints([])
            setAddArea(false)
            if(isLineClosed == true && isLinesClosed20 == false){
                setIsLinesClosed20(true)
            }
        }
        setIsLineClosed(false)
        setAreasCount(-1)
        setLastClickedDottLine([])
        setActiveChangingLine([])
        setlastClickedDott(null)
    }, [addArea]);
    React.useEffect(() => {
        if(copyFigure == true && areas2.length == 0 && areas1.length !== 0 && areasCount > 0){
            setAreas2(eval("areas" + areasCount))
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed2 == false){
                setIsLinesClosed2(true)
            }
        }
        if(copyFigure == true && areas3.length == 0 && areas2.length !== 0 && areasCount > 0){
            setAreas3(eval("areas" + areasCount))
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed3 == false){
                setIsLinesClosed3(true)
            }
        }
        if(copyFigure == true && areas4.length == 0 && areas3.length !== 0 && areasCount > 0){
            setAreas4(eval("areas" + areasCount))
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed4 == false){
                setIsLinesClosed4(true)
            }
        }
        if(copyFigure == true && areas5.length == 0 && areas4.length !== 0 && areasCount > 0){
            setAreas5(eval("areas" + areasCount))
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed5 == false){
                setIsLinesClosed5(true)
            }
        }
        if(copyFigure == true && areas6.length == 0 && areas5.length !== 0 && areasCount > 0){
            setAreas6(eval("areas" + areasCount))
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed6 == false){
                setIsLinesClosed6(true)
            }
        }
        if(copyFigure == true && areas7.length == 0 && areas6.length !== 0 && areasCount > 0){
            setAreas7(eval("areas" + areasCount))
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed7 == false){
                setIsLinesClosed7(true)
            }
        }
        if(copyFigure == true && areas8.length == 0 && areas7.length !== 0 && areasCount > 0){
            setAreas8(eval("areas" + areasCount))
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed8 == false){
                setIsLinesClosed8(true)
            }
        }
        if(copyFigure == true && areas9.length == 0 && areas8.length !== 0 && areasCount > 0){
            setAreas9(eval("areas" + areasCount))
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed9 == false){
                setIsLinesClosed9(true)
            }
        }
        if(copyFigure == true && areas10.length == 0 && areas9.length !== 0 && areasCount > 0){
            setAreas10(eval("areas" + areasCount))
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed10 == false){
                setIsLinesClosed10(true)
            }
        }
        if(copyFigure == true && areas11.length == 0 && areas10.length !== 0 && areasCount > 0){
            setAreas11(eval("areas" + areasCount))
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed11 == false){
                setIsLinesClosed11(true)
            }
        }
        if(copyFigure == true && areas12.length == 0 && areas11.length !== 0 && areasCount > 0){
            setAreas12(eval("areas" + areasCount))
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed12 == false){
                setIsLinesClosed12(true)
            }
        }
        if(copyFigure == true && areas13.length == 0 && areas12.length !== 0 && areasCount > 0){
            setAreas13(eval("areas" + areasCount))
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed13 == false){
                setIsLinesClosed13(true)
            }
        }
        if(copyFigure == true && areas14.length == 0 && areas13.length !== 0 && areasCount > 0){
            setAreas14(eval("areas" + areasCount))
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed14 == false){
                setIsLinesClosed14(true)
            }
        }
        if(copyFigure == true && areas15.length == 0 && areas14.length !== 0 && areasCount > 0){
            setAreas15(eval("areas" + areasCount))
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed15 == false){
                setIsLinesClosed15(true)
            }
        }
        if(copyFigure == true && areas16.length == 0 && areas15.length !== 0 && areasCount > 0){
            setAreas16(eval("areas" + areasCount))
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed16 == false){
                setIsLinesClosed16(true)
            }
        }
        if(copyFigure == true && areas17.length == 0 && areas16.length !== 0 && areasCount > 0){
            setAreas17(eval("areas" + areasCount))
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed17 == false){
                setIsLinesClosed17(true)
            }
        }
        if(copyFigure == true && areas18.length == 0 && areas17.length !== 0 && areasCount > 0){
            setAreas18(eval("areas" + areasCount))
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed18 == false){
                setIsLinesClosed18(true)
            }
        }
        if(copyFigure == true && areas19.length == 0 && areas18.length !== 0 && areasCount > 0){
            setAreas19(eval("areas" + areasCount))
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed19 == false){
                setIsLinesClosed19(true)
            }
        }
        if(copyFigure == true && areas20.length == 0 && areas19.length !== 0 && areasCount > 0){
            setAreas20(eval("areas" + areasCount))
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed20 == false){
                setIsLinesClosed20(true)
            }
        }
        setIsLineClosed(false)
        setAreasCount(-1)
        setCopyFigure(false)
    }, [copyFigure]);
    React.useEffect(() => {
        let pointsCopy: any = [];
        if(flipFigureX == true){
            pointsCopy = cloneDeep(eval("areas" + areasCount));
                // @ts-ignore
                let rightDott: any = eval("areas" + areasCount)[0].x;
                for (let i = 0; i < eval("areas" + areasCount).length; i++) {
                    if(pointsCopy[i].x > rightDott){
                        rightDott = pointsCopy[i].x
                    }
                }
                for (let i = 0; i < eval("areas" + areasCount).length; i++) {
                    pointsCopy[i].x = rightDott - pointsCopy[i].x
                }
        }
        if(flipFigureX == true && areas2.length == 0 && areas1.length !== 0 && areasCount > 0){
            setAreas2(pointsCopy)
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed2 == false){
                setIsLinesClosed2(true)
            }
        }
        if(flipFigureX == true && areas3.length == 0 && areas2.length !== 0 && areasCount > 0){
            setAreas3(pointsCopy)
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed3 == false){
                setIsLinesClosed3(true)
            }
        }
        if(flipFigureX == true && areas4.length == 0 && areas3.length !== 0 && areasCount > 0){
            setAreas4(pointsCopy)
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed4 == false){
                setIsLinesClosed4(true)
            }
        }
        if(flipFigureX == true && areas5.length == 0 && areas4.length !== 0 && areasCount > 0){
            setAreas5(pointsCopy)
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed5 == false){
                setIsLinesClosed5(true)
            }
        }
        if(flipFigureX == true && areas6.length == 0 && areas5.length !== 0 && areasCount > 0){
            setAreas6(pointsCopy)
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed6 == false){
                setIsLinesClosed6(true)
            }
        }
        if(flipFigureX == true && areas7.length == 0 && areas6.length !== 0 && areasCount > 0){
            setAreas7(pointsCopy)
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed7 == false){
                setIsLinesClosed7(true)
            }
        }
        if(flipFigureX == true && areas8.length == 0 && areas7.length !== 0 && areasCount > 0){
            setAreas8(pointsCopy)
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed8 == false){
                setIsLinesClosed8(true)
            }
        }
        if(flipFigureX == true && areas9.length == 0 && areas8.length !== 0 && areasCount > 0){
            setAreas9(pointsCopy)
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed9 == false){
                setIsLinesClosed9(true)
            }
        }
        if(flipFigureX == true && areas10.length == 0 && areas9.length !== 0 && areasCount > 0){
            setAreas10(pointsCopy)
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed10 == false){
                setIsLinesClosed10(true)
            }
        }
        if(flipFigureX == true && areas11.length == 0 && areas10.length !== 0 && areasCount > 0){
            setAreas11(pointsCopy)
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed11 == false){
                setIsLinesClosed11(true)
            }
        }
        if(flipFigureX == true && areas12.length == 0 && areas11.length !== 0 && areasCount > 0){
            setAreas12(pointsCopy)
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed12 == false){
                setIsLinesClosed12(true)
            }
        }
        if(flipFigureX == true && areas13.length == 0 && areas12.length !== 0 && areasCount > 0){
            setAreas13(pointsCopy)
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed13 == false){
                setIsLinesClosed13(true)
            }
        }
        if(flipFigureX == true && areas14.length == 0 && areas13.length !== 0 && areasCount > 0){
            setAreas14(pointsCopy)
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed14 == false){
                setIsLinesClosed14(true)
            }
        }
        if(flipFigureX == true && areas15.length == 0 && areas14.length !== 0 && areasCount > 0){
            setAreas15(pointsCopy)
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed15 == false){
                setIsLinesClosed15(true)
            }
        }
        if(flipFigureX == true && areas16.length == 0 && areas15.length !== 0 && areasCount > 0){
            setAreas16(pointsCopy)
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed16 == false){
                setIsLinesClosed16(true)
            }
        }
        if(flipFigureX == true && areas17.length == 0 && areas16.length !== 0 && areasCount > 0){
            setAreas17(pointsCopy)
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed17 == false){
                setIsLinesClosed17(true)
            }
        }
        if(flipFigureX == true && areas18.length == 0 && areas17.length !== 0 && areasCount > 0){
            setAreas18(pointsCopy)
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed18 == false){
                setIsLinesClosed18(true)
            }
        }
        if(flipFigureX == true && areas19.length == 0 && areas18.length !== 0 && areasCount > 0){
            setAreas19(pointsCopy)
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed19 == false){
                setIsLinesClosed19(true)
            }
        }
        if(flipFigureX == true && areas20.length == 0 && areas19.length !== 0 && areasCount > 0){
            setAreas20(pointsCopy)
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed20 == false){
                setIsLinesClosed20(true)
            }
        }
        setIsLineClosed(false)
        setAreasCount(-1)
        setFlipFigureX(false)
        pointsCopy = []
    }, [flipFigureX]);
    React.useEffect(() => {
        let pointsCopy: any = [];
        if(flipFigureY == true){
                pointsCopy = cloneDeep(eval("areas" + areasCount));
                // @ts-ignore
                let topDott: any = eval("areas" + areasCount)[0].x;
                for (let i = 0; i < eval("areas" + areasCount).length; i++) {
                    //pointsCopy[i] = eval("areas" + areasCount)[i];
                    if(pointsCopy[i].y > topDott){
                     topDott = pointsCopy[i].y
                    }
                }
                for (let i = 0; i < eval("areas" + areasCount).length; i++) {
                    pointsCopy[i].y = topDott - pointsCopy[i].y
                }
                // @ts-ignore
                let bottomDott: any = eval("areas" + areasCount)[0].y;
                // @ts-ignore
                let leftDott: any = eval("areas" + areasCount)[0].x;
                for (let i = 0; i < eval("areas" + areasCount).length; i++) {
                    if(pointsCopy[i].y < bottomDott){
                        bottomDott = pointsCopy[i].y
                    }
                    if(pointsCopy[i].x < leftDott){
                        leftDott = pointsCopy[i].x
                    }
                }
                for (let i = 0; i < eval("areas" + areasCount).length; i++) {
                    pointsCopy[i].x = pointsCopy[i].x - leftDott
                    pointsCopy[i].y = pointsCopy[i].y - bottomDott
                }
        }
        if(flipFigureY == true && areas2.length == 0 && areas1.length !== 0 && areasCount > 0){
            setAreas2(pointsCopy)
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed2 == false){
                setIsLinesClosed2(true)
            }
        }
        if(flipFigureY == true && areas3.length == 0 && areas2.length !== 0 && areasCount > 0){
            setAreas3(pointsCopy)
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed3 == false){
                setIsLinesClosed3(true)
            }
        }
        if(flipFigureY == true && areas4.length == 0 && areas3.length !== 0 && areasCount > 0){
            setAreas4(pointsCopy)
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed4 == false){
                setIsLinesClosed4(true)
            }
        }
        if(flipFigureY == true && areas5.length == 0 && areas4.length !== 0 && areasCount > 0){
            setAreas5(pointsCopy)
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed5 == false){
                setIsLinesClosed5(true)
            }
        }
        if(flipFigureY == true && areas6.length == 0 && areas5.length !== 0 && areasCount > 0){
            setAreas6(pointsCopy)
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed6 == false){
                setIsLinesClosed6(true)
            }
        }
        if(flipFigureY == true && areas7.length == 0 && areas6.length !== 0 && areasCount > 0){
            setAreas7(pointsCopy)
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed7 == false){
                setIsLinesClosed7(true)
            }
        }
        if(flipFigureY == true && areas8.length == 0 && areas7.length !== 0 && areasCount > 0){
            setAreas8(pointsCopy)
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed8 == false){
                setIsLinesClosed8(true)
            }
        }
        if(flipFigureY == true && areas9.length == 0 && areas8.length !== 0 && areasCount > 0){
            setAreas9(pointsCopy)
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed9 == false){
                setIsLinesClosed9(true)
            }
        }
        if(flipFigureY == true && areas10.length == 0 && areas9.length !== 0 && areasCount > 0){
            setAreas10(pointsCopy)
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed10 == false){
                setIsLinesClosed10(true)
            }
        }
        if(flipFigureY == true && areas11.length == 0 && areas10.length !== 0 && areasCount > 0){
            setAreas11(pointsCopy)
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed11 == false){
                setIsLinesClosed11(true)
            }
        }
        if(flipFigureY == true && areas12.length == 0 && areas11.length !== 0 && areasCount > 0){
            setAreas12(pointsCopy)
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed12 == false){
                setIsLinesClosed12(true)
            }
        }
        if(flipFigureY == true && areas13.length == 0 && areas12.length !== 0 && areasCount > 0){
            setAreas13(pointsCopy)
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed13 == false){
                setIsLinesClosed13(true)
            }
        }
        if(flipFigureY == true && areas14.length == 0 && areas13.length !== 0 && areasCount > 0){
            setAreas14(pointsCopy)
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed14 == false){
                setIsLinesClosed14(true)
            }
        }
        if(flipFigureY == true && areas15.length == 0 && areas14.length !== 0 && areasCount > 0){
            setAreas15(pointsCopy)
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed15 == false){
                setIsLinesClosed15(true)
            }
        }
        if(flipFigureY == true && areas16.length == 0 && areas15.length !== 0 && areasCount > 0){
            setAreas16(pointsCopy)
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed16 == false){
                setIsLinesClosed16(true)
            }
        }
        if(flipFigureY == true && areas17.length == 0 && areas16.length !== 0 && areasCount > 0){
            setAreas17(pointsCopy)
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed17 == false){
                setIsLinesClosed17(true)
            }
        }
        if(flipFigureY == true && areas18.length == 0 && areas17.length !== 0 && areasCount > 0){
            setAreas18(pointsCopy)
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed18 == false){
                setIsLinesClosed18(true)
            }
        }
        if(flipFigureY == true && areas19.length == 0 && areas18.length !== 0 && areasCount > 0){
            setAreas19(pointsCopy)
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed19 == false){
                setIsLinesClosed19(true)
            }
        }
        if(flipFigureY == true && areas20.length == 0 && areas19.length !== 0 && areasCount > 0){
            setAreas20(pointsCopy)
            setPoints([])
            setAddArea(false)
            if(eval("isLinesClosed" + areasCount) == true && isLinesClosed20 == false){
                setIsLinesClosed20(true)
            }
        }
        setIsLineClosed(false)
        setAreasCount(-1)
        setFlipFigureY(false)
        pointsCopy = []
    }, [flipFigureY]);

    React.useEffect(() => {
        if(areas1 != null){
            // Координати точок будь-якої фігури (визначте ваші точки)
            const pointsForText = areas1

            // Функція для знаходження вектора нормалі до відрізка
            //@ts-ignore
            function findNormalVector(point1: any, point2: any) {
                const dx = point2.x - point1.x;
                const dy = point2.y - point1.y;
                const length = Math.sqrt(dx ** 2 + dy ** 2);
                return [-dy / length, dx / length];
            }

            // Знаходимо вектори нормалі для кожної сторони фігури
            const normalVectors = pointsForText.map((point: any, index: any) => {
                const nextPoint = pointsForText[(index + 1) % pointsForText.length];
                return findNormalVector(point, nextPoint);
            });

            // Зсув середин відрізків на 10 одиниць вздовж нормалі
            const midpoints = pointsForText.map((point: any, index: any) => {
                const nextPoint = pointsForText[(index + 1) % pointsForText.length];
                return [(point.x + nextPoint.x) / 2, (point.y + nextPoint.y) / 2];
            });

            const shiftedMidpoints = midpoints.map((midpoint: any, index: any) => {
                const normalVector = normalVectors[index];
                if(isClockwise(pointsForText)){
                    return [
                        midpoint[0] + 0.5 * normalVector[0],
                        midpoint[1] + 0.3 * normalVector[1],
                    ];
                }
                else{
                    return [
                        midpoint[0] - 0.5 * normalVector[0],
                        midpoint[1] - 0.3 * normalVector[1],
                    ];
                }
            });

            

            let buildCustomPointsCopy: any = [];
            let buildCustomSizesCopy: any = [];
            for (let i = 0; i < areas1.length; i++) {
                // @ts-ignore
                buildCustomPointsCopy.push(buildAnchor1(getXCoord(areas1[i].x), getYCoord(areas1[i].y), i, i, 1));
                // @ts-ignore
                buildCustomSizesCopy.push(buildSizes1(getXCoord(areas1[i].x), getYCoord(areas1[i].y), i, i, getXCoord(shiftedMidpoints[i][0]), getYCoord(shiftedMidpoints[i][1])));
            }
            setBuiltCustomPoints1(buildCustomPointsCopy);
            setBuiltSideSizes1(buildCustomSizesCopy)
            // set array of points to lines
            let l: any = [];
            for (let i = 0; i < areas1.length; i++) {
                // @ts-ignore
                l.push(getXCoord(areas1[i].x));
                // @ts-ignore
                l.push(getYCoord(areas1[i].y));
            }
            // @ts-ignore
            setLines1(l);
        }
    }, [areas1, isLinesClosed1, appContext.state.selectedScale]);
    React.useEffect(() => {
        if(areas2 != null){
            // Координати точок будь-якої фігури (визначте ваші точки)
            const pointsForText = areas2

            // Функція для знаходження вектора нормалі до відрізка
            //@ts-ignore
            function findNormalVector(point1: any, point2: any) {
                const dx = point2.x - point1.x;
                const dy = point2.y - point1.y;
                const length = Math.sqrt(dx ** 2 + dy ** 2);
                return [-dy / length, dx / length];
            }

            // Знаходимо вектори нормалі для кожної сторони фігури
            const normalVectors = pointsForText.map((point: any, index: any) => {
                const nextPoint = pointsForText[(index + 1) % pointsForText.length];
                return findNormalVector(point, nextPoint);
            });

            // Зсув середин відрізків на 10 одиниць вздовж нормалі
            const midpoints = pointsForText.map((point: any, index: any) => {
                const nextPoint = pointsForText[(index + 1) % pointsForText.length];
                return [(point.x + nextPoint.x) / 2, (point.y + nextPoint.y) / 2];
            });

            const shiftedMidpoints = midpoints.map((midpoint: any, index: any) => {
            const normalVector = normalVectors[index];
            if(isClockwise(pointsForText)){
                return [
                    midpoint[0] + 0.5 * normalVector[0],
                    midpoint[1] + 0.3 * normalVector[1],
                ];
            }
            else{
                return [
                    midpoint[0] - 0.5 * normalVector[0],
                    midpoint[1] - 0.3 * normalVector[1],
                ];
            }
            });

            let buildCustomPointsCopy: any = [];
            let buildCustomSizesCopy: any = [];
            for (let i = 0; i < areas2.length; i++) {
                // @ts-ignore
                buildCustomPointsCopy.push(buildAnchor2(getXCoord(areas2[i].x), getYCoord(areas2[i].y), i, i, 2));
                // @ts-ignore
                buildCustomSizesCopy.push(buildSizes2(getXCoord(areas2[i].x), getYCoord(areas2[i].y), i, i, getXCoord(shiftedMidpoints[i][0]), getYCoord(shiftedMidpoints[i][1])));
            }
            setBuiltCustomPoints2(buildCustomPointsCopy);
            setBuiltSideSizes2(buildCustomSizesCopy)
            
            // set array of points to lines
            let l: any = [];
            for (let i = 0; i < areas2.length; i++) {
                // @ts-ignore
                l.push(getXCoord(areas2[i].x));
                // @ts-ignore
                l.push(getYCoord(areas2[i].y));
            }
            // @ts-ignore
            setLines2(l);
        }
    }, [areas2, isLinesClosed2, appContext.state.selectedScale]);
    React.useEffect(() => {
        if(areas3 != null){
            // Координати точок будь-якої фігури (визначте ваші точки)
            const pointsForText = areas3

            // Функція для знаходження вектора нормалі до відрізка
            //@ts-ignore
            function findNormalVector(point1: any, point2: any) {
                const dx = point2.x - point1.x;
                const dy = point2.y - point1.y;
                const length = Math.sqrt(dx ** 2 + dy ** 2);
                return [-dy / length, dx / length];
            }

            // Знаходимо вектори нормалі для кожної сторони фігури
            const normalVectors = pointsForText.map((point: any, index: any) => {
                const nextPoint = pointsForText[(index + 1) % pointsForText.length];
                return findNormalVector(point, nextPoint);
            });

            // Зсув середин відрізків на 10 одиниць вздовж нормалі
            const midpoints = pointsForText.map((point: any, index: any) => {
                const nextPoint = pointsForText[(index + 1) % pointsForText.length];
                return [(point.x + nextPoint.x) / 2, (point.y + nextPoint.y) / 2];
            });

            const shiftedMidpoints = midpoints.map((midpoint: any, index: any) => {
            const normalVector = normalVectors[index];
            if(isClockwise(pointsForText)){
                return [
                    midpoint[0] + 0.5 * normalVector[0],
                    midpoint[1] + 0.3 * normalVector[1],
                ];
            }
            else{
                return [
                    midpoint[0] - 0.5 * normalVector[0],
                    midpoint[1] - 0.3 * normalVector[1],
                ];
            }
            });

            let buildCustomPointsCopy: any = [];
            let buildCustomSizesCopy: any = [];
            for (let i = 0; i < areas3.length; i++) {
                // @ts-ignore
                buildCustomPointsCopy.push(buildAnchor3(getXCoord(areas3[i].x), getYCoord(areas3[i].y), i, i, 3));
                // @ts-ignore
                buildCustomSizesCopy.push(buildSizes3(getXCoord(areas3[i].x), getYCoord(areas3[i].y), i, i, getXCoord(shiftedMidpoints[i][0]), getYCoord(shiftedMidpoints[i][1])));
            }
            setBuiltCustomPoints3(buildCustomPointsCopy);
            setBuiltSideSizes3(buildCustomSizesCopy)
            
            // set array of points to lines
            let l: any = [];
            for (let i = 0; i < areas3.length; i++) {
                // @ts-ignore
                l.push(getXCoord(areas3[i].x));
                // @ts-ignore
                l.push(getYCoord(areas3[i].y));
            }
            // @ts-ignore
            setLines3(l);
        }
    }, [areas3, isLinesClosed3, appContext.state.selectedScale]);
    React.useEffect(() => {
        if(areas4 != null){
            // Координати точок будь-якої фігури (визначте ваші точки)
            const pointsForText = areas4

            // Функція для знаходження вектора нормалі до відрізка
            //@ts-ignore
            function findNormalVector(point1: any, point2: any) {
                const dx = point2.x - point1.x;
                const dy = point2.y - point1.y;
                const length = Math.sqrt(dx ** 2 + dy ** 2);
                return [-dy / length, dx / length];
            }

            // Знаходимо вектори нормалі для кожної сторони фігури
            const normalVectors = pointsForText.map((point: any, index: any) => {
                const nextPoint = pointsForText[(index + 1) % pointsForText.length];
                return findNormalVector(point, nextPoint);
            });

            // Зсув середин відрізків на 10 одиниць вздовж нормалі
            const midpoints = pointsForText.map((point: any, index: any) => {
                const nextPoint = pointsForText[(index + 1) % pointsForText.length];
                return [(point.x + nextPoint.x) / 2, (point.y + nextPoint.y) / 2];
            });

            const shiftedMidpoints = midpoints.map((midpoint: any, index: any) => {
            const normalVector = normalVectors[index];
            if(isClockwise(pointsForText)){
                return [
                    midpoint[0] + 0.5 * normalVector[0],
                    midpoint[1] + 0.3 * normalVector[1],
                ];
            }
            else{
                return [
                    midpoint[0] - 0.5 * normalVector[0],
                    midpoint[1] - 0.3 * normalVector[1],
                ];
            }
            });

            let buildCustomPointsCopy: any = [];
            let buildCustomSizesCopy: any = [];
            for (let i = 0; i < areas4.length; i++) {
                // @ts-ignore
                buildCustomPointsCopy.push(buildAnchor4(getXCoord(areas4[i].x), getYCoord(areas4[i].y), i, i, 4));
                // @ts-ignore
                buildCustomSizesCopy.push(buildSizes4(getXCoord(areas4[i].x), getYCoord(areas4[i].y), i, i, getXCoord(shiftedMidpoints[i][0]), getYCoord(shiftedMidpoints[i][1])));
            }
            setBuiltCustomPoints4(buildCustomPointsCopy);
            setBuiltSideSizes4(buildCustomSizesCopy)
            
            // set array of points to lines
            let l: any = [];
            for (let i = 0; i < areas4.length; i++) {
                // @ts-ignore
                l.push(getXCoord(areas4[i].x));
                // @ts-ignore
                l.push(getYCoord(areas4[i].y));
            }
            // @ts-ignore
            setLines4(l);
        }
    }, [areas4, isLinesClosed4, appContext.state.selectedScale]);
    React.useEffect(() => {
        if(areas5 != null){
            // Координати точок будь-якої фігури (визначте ваші точки)
            const pointsForText = areas5

            // Функція для знаходження вектора нормалі до відрізка
            //@ts-ignore
            function findNormalVector(point1: any, point2: any) {
                const dx = point2.x - point1.x;
                const dy = point2.y - point1.y;
                const length = Math.sqrt(dx ** 2 + dy ** 2);
                return [-dy / length, dx / length];
            }

            // Знаходимо вектори нормалі для кожної сторони фігури
            const normalVectors = pointsForText.map((point: any, index: any) => {
                const nextPoint = pointsForText[(index + 1) % pointsForText.length];
                return findNormalVector(point, nextPoint);
            });

            // Зсув середин відрізків на 10 одиниць вздовж нормалі
            const midpoints = pointsForText.map((point: any, index: any) => {
                const nextPoint = pointsForText[(index + 1) % pointsForText.length];
                return [(point.x + nextPoint.x) / 2, (point.y + nextPoint.y) / 2];
            });

            const shiftedMidpoints = midpoints.map((midpoint: any, index: any) => {
            const normalVector = normalVectors[index];
            if(isClockwise(pointsForText)){
                return [
                    midpoint[0] + 0.5 * normalVector[0],
                    midpoint[1] + 0.3 * normalVector[1],
                ];
            }
            else{
                return [
                    midpoint[0] - 0.5 * normalVector[0],
                    midpoint[1] - 0.3 * normalVector[1],
                ];
            }
            });

            let buildCustomPointsCopy: any = [];
            let buildCustomSizesCopy: any = [];
            for (let i = 0; i < areas5.length; i++) {
                // @ts-ignore
                buildCustomPointsCopy.push(buildAnchor5(getXCoord(areas5[i].x), getYCoord(areas5[i].y), i, i, 5));
                // @ts-ignore
                buildCustomSizesCopy.push(buildSizes5(getXCoord(areas5[i].x), getYCoord(areas5[i].y), i, i, getXCoord(shiftedMidpoints[i][0]), getYCoord(shiftedMidpoints[i][1])));
            }
            setBuiltCustomPoints5(buildCustomPointsCopy);
            setBuiltSideSizes5(buildCustomSizesCopy)
            
            // set array of points to lines
            let l: any = [];
            for (let i = 0; i < areas5.length; i++) {
                // @ts-ignore
                l.push(getXCoord(areas5[i].x));
                // @ts-ignore
                l.push(getYCoord(areas5[i].y));
            }
            // @ts-ignore
            setLines5(l);
        }
    }, [areas5, isLinesClosed5, appContext.state.selectedScale]);
    React.useEffect(() => {
        if(areas6 != null){
                        // Координати точок будь-якої фігури (визначте ваші точки)
            const pointsForText = areas6

            // Функція для знаходження вектора нормалі до відрізка
            //@ts-ignore
            function findNormalVector(point1: any, point2: any) {
                const dx = point2.x - point1.x;
                const dy = point2.y - point1.y;
                const length = Math.sqrt(dx ** 2 + dy ** 2);
                return [-dy / length, dx / length];
            }

            // Знаходимо вектори нормалі для кожної сторони фігури
            const normalVectors = pointsForText.map((point: any, index: any) => {
                const nextPoint = pointsForText[(index + 1) % pointsForText.length];
                return findNormalVector(point, nextPoint);
            });

            // Зсув середин відрізків на 10 одиниць вздовж нормалі
            const midpoints = pointsForText.map((point: any, index: any) => {
                const nextPoint = pointsForText[(index + 1) % pointsForText.length];
                return [(point.x + nextPoint.x) / 2, (point.y + nextPoint.y) / 2];
            });

            const shiftedMidpoints = midpoints.map((midpoint: any, index: any) => {
            const normalVector = normalVectors[index];
            if(isClockwise(pointsForText)){
                return [
                    midpoint[0] + 0.5 * normalVector[0],
                    midpoint[1] + 0.3 * normalVector[1],
                ];
            }
            else{
                return [
                    midpoint[0] - 0.5 * normalVector[0],
                    midpoint[1] - 0.3 * normalVector[1],
                ];
            }
            });

            let buildCustomPointsCopy: any = [];
            let buildCustomSizesCopy: any = [];
            for (let i = 0; i < areas6.length; i++) {
                // @ts-ignore
                buildCustomPointsCopy.push(buildAnchor6(getXCoord(areas6[i].x), getYCoord(areas6[i].y), i, i, 6));
                // @ts-ignore
                buildCustomSizesCopy.push(buildSizes6(getXCoord(areas6[i].x), getYCoord(areas6[i].y), i, i, getXCoord(shiftedMidpoints[i][0]), getYCoord(shiftedMidpoints[i][1])));
            }
            setBuiltCustomPoints6(buildCustomPointsCopy);
            setBuiltSideSizes6(buildCustomSizesCopy)
            
            // set array of points to lines
            let l: any = [];
            for (let i = 0; i < areas6.length; i++) {
                // @ts-ignore
                l.push(getXCoord(areas6[i].x));
                // @ts-ignore
                l.push(getYCoord(areas6[i].y));
            }
            // @ts-ignore
            setLines6(l);
        }
    }, [areas6, isLinesClosed6, appContext.state.selectedScale]);
    React.useEffect(() => {
        if(areas7 != null){
                        // Координати точок будь-якої фігури (визначте ваші точки)
            const pointsForText = areas7

            // Функція для знаходження вектора нормалі до відрізка
            //@ts-ignore
            function findNormalVector(point1: any, point2: any) {
                const dx = point2.x - point1.x;
                const dy = point2.y - point1.y;
                const length = Math.sqrt(dx ** 2 + dy ** 2);
                return [-dy / length, dx / length];
            }

            // Знаходимо вектори нормалі для кожної сторони фігури
            const normalVectors = pointsForText.map((point: any, index: any) => {
                const nextPoint = pointsForText[(index + 1) % pointsForText.length];
                return findNormalVector(point, nextPoint);
            });

            // Зсув середин відрізків на 10 одиниць вздовж нормалі
            const midpoints = pointsForText.map((point: any, index: any) => {
                const nextPoint = pointsForText[(index + 1) % pointsForText.length];
                return [(point.x + nextPoint.x) / 2, (point.y + nextPoint.y) / 2];
            });

            const shiftedMidpoints = midpoints.map((midpoint: any, index: any) => {
            const normalVector = normalVectors[index];
            if(isClockwise(pointsForText)){
                return [
                    midpoint[0] + 0.5 * normalVector[0],
                    midpoint[1] + 0.3 * normalVector[1],
                ];
            }
            else{
                return [
                    midpoint[0] - 0.5 * normalVector[0],
                    midpoint[1] - 0.3 * normalVector[1],
                ];
            }
            });

            let buildCustomPointsCopy: any = [];
            let buildCustomSizesCopy: any = [];
            for (let i = 0; i < areas7.length; i++) {
                // @ts-ignore
                buildCustomPointsCopy.push(buildAnchor7(getXCoord(areas7[i].x), getYCoord(areas7[i].y), i, i, 7));
                // @ts-ignore
                buildCustomSizesCopy.push(buildSizes7(getXCoord(areas7[i].x), getYCoord(areas7[i].y), i, i, getXCoord(shiftedMidpoints[i][0]), getYCoord(shiftedMidpoints[i][1])));
            }
            setBuiltCustomPoints7(buildCustomPointsCopy);
            setBuiltSideSizes7(buildCustomSizesCopy)
            
            // set array of points to lines
            let l: any = [];
            for (let i = 0; i < areas7.length; i++) {
                // @ts-ignore
                l.push(getXCoord(areas7[i].x));
                // @ts-ignore
                l.push(getYCoord(areas7[i].y));
            }
            // @ts-ignore
            setLines7(l);
        }
    }, [areas7, isLinesClosed7, appContext.state.selectedScale]);
    React.useEffect(() => {
        if(areas8 != null){
                        // Координати точок будь-якої фігури (визначте ваші точки)
            const pointsForText = areas8

            // Функція для знаходження вектора нормалі до відрізка
            //@ts-ignore
            function findNormalVector(point1: any, point2: any) {
                const dx = point2.x - point1.x;
                const dy = point2.y - point1.y;
                const length = Math.sqrt(dx ** 2 + dy ** 2);
                return [-dy / length, dx / length];
            }

            // Знаходимо вектори нормалі для кожної сторони фігури
            const normalVectors = pointsForText.map((point: any, index: any) => {
                const nextPoint = pointsForText[(index + 1) % pointsForText.length];
                return findNormalVector(point, nextPoint);
            });

            // Зсув середин відрізків на 10 одиниць вздовж нормалі
            const midpoints = pointsForText.map((point: any, index: any) => {
                const nextPoint = pointsForText[(index + 1) % pointsForText.length];
                return [(point.x + nextPoint.x) / 2, (point.y + nextPoint.y) / 2];
            });

            const shiftedMidpoints = midpoints.map((midpoint: any, index: any) => {
            const normalVector = normalVectors[index];
            if(isClockwise(pointsForText)){
                return [
                    midpoint[0] + 0.5 * normalVector[0],
                    midpoint[1] + 0.3 * normalVector[1],
                ];
            }
            else{
                return [
                    midpoint[0] - 0.5 * normalVector[0],
                    midpoint[1] - 0.3 * normalVector[1],
                ];
            }
            });

            let buildCustomPointsCopy: any = [];
            let buildCustomSizesCopy: any = [];
            for (let i = 0; i < areas8.length; i++) {
                // @ts-ignore
                buildCustomPointsCopy.push(buildAnchor8(getXCoord(areas8[i].x), getYCoord(areas8[i].y), i, i, 8));
                // @ts-ignore
                buildCustomSizesCopy.push(buildSizes8(getXCoord(areas8[i].x), getYCoord(areas8[i].y), i, i, getXCoord(shiftedMidpoints[i][0]), getYCoord(shiftedMidpoints[i][1])));
            }
            setBuiltCustomPoints8(buildCustomPointsCopy);
            setBuiltSideSizes8(buildCustomSizesCopy)
            
            // set array of points to lines
            let l: any = [];
            for (let i = 0; i < areas8.length; i++) {
                // @ts-ignore
                l.push(getXCoord(areas8[i].x));
                // @ts-ignore
                l.push(getYCoord(areas8[i].y));
            }
            // @ts-ignore
            setLines8(l);
        }
    }, [areas8, isLinesClosed8, appContext.state.selectedScale]);
    React.useEffect(() => {
        if(areas9 != null){
                        // Координати точок будь-якої фігури (визначте ваші точки)
            const pointsForText = areas9

            // Функція для знаходження вектора нормалі до відрізка
            //@ts-ignore
            function findNormalVector(point1: any, point2: any) {
                const dx = point2.x - point1.x;
                const dy = point2.y - point1.y;
                const length = Math.sqrt(dx ** 2 + dy ** 2);
                return [-dy / length, dx / length];
            }

            // Знаходимо вектори нормалі для кожної сторони фігури
            const normalVectors = pointsForText.map((point: any, index: any) => {
                const nextPoint = pointsForText[(index + 1) % pointsForText.length];
                return findNormalVector(point, nextPoint);
            });

            // Зсув середин відрізків на 10 одиниць вздовж нормалі
            const midpoints = pointsForText.map((point: any, index: any) => {
                const nextPoint = pointsForText[(index + 1) % pointsForText.length];
                return [(point.x + nextPoint.x) / 2, (point.y + nextPoint.y) / 2];
            });

            const shiftedMidpoints = midpoints.map((midpoint: any, index: any) => {
            const normalVector = normalVectors[index];
            if(isClockwise(pointsForText)){
                return [
                    midpoint[0] + 0.5 * normalVector[0],
                    midpoint[1] + 0.3 * normalVector[1],
                ];
            }
            else{
                return [
                    midpoint[0] - 0.5 * normalVector[0],
                    midpoint[1] - 0.3 * normalVector[1],
                ];
            }
            });

            let buildCustomPointsCopy: any = [];
            let buildCustomSizesCopy: any = [];
            for (let i = 0; i < areas9.length; i++) {
                // @ts-ignore
                buildCustomPointsCopy.push(buildAnchor9(getXCoord(areas9[i].x), getYCoord(areas9[i].y), i, i, 9));
                // @ts-ignore
                buildCustomSizesCopy.push(buildSizes9(getXCoord(areas9[i].x), getYCoord(areas9[i].y), i, i, getXCoord(shiftedMidpoints[i][0]), getYCoord(shiftedMidpoints[i][1])));
            }
            setBuiltCustomPoints9(buildCustomPointsCopy);
            setBuiltSideSizes9(buildCustomSizesCopy)
            
            // set array of points to lines
            let l: any = [];
            for (let i = 0; i < areas9.length; i++) {
                // @ts-ignore
                l.push(getXCoord(areas9[i].x));
                // @ts-ignore
                l.push(getYCoord(areas9[i].y));
            }
            // @ts-ignore
            setLines9(l);
        }
    }, [areas9, isLinesClosed9, appContext.state.selectedScale]);
    React.useEffect(() => {
        if(areas10 != null){
                        // Координати точок будь-якої фігури (визначте ваші точки)
            const pointsForText = areas10

            // Функція для знаходження вектора нормалі до відрізка
            //@ts-ignore
            function findNormalVector(point1: any, point2: any) {
                const dx = point2.x - point1.x;
                const dy = point2.y - point1.y;
                const length = Math.sqrt(dx ** 2 + dy ** 2);
                return [-dy / length, dx / length];
            }

            // Знаходимо вектори нормалі для кожної сторони фігури
            const normalVectors = pointsForText.map((point: any, index: any) => {
                const nextPoint = pointsForText[(index + 1) % pointsForText.length];
                return findNormalVector(point, nextPoint);
            });

            // Зсув середин відрізків на 10 одиниць вздовж нормалі
            const midpoints = pointsForText.map((point: any, index: any) => {
                const nextPoint = pointsForText[(index + 1) % pointsForText.length];
                return [(point.x + nextPoint.x) / 2, (point.y + nextPoint.y) / 2];
            });

            const shiftedMidpoints = midpoints.map((midpoint: any, index: any) => {
            const normalVector = normalVectors[index];
            if(isClockwise(pointsForText)){
                return [
                    midpoint[0] + 0.5 * normalVector[0],
                    midpoint[1] + 0.3 * normalVector[1],
                ];
            }
            else{
                return [
                    midpoint[0] - 0.5 * normalVector[0],
                    midpoint[1] - 0.3 * normalVector[1],
                ];
            }
            });

            let buildCustomPointsCopy: any = [];
            let buildCustomSizesCopy: any = [];
            for (let i = 0; i < areas10.length; i++) {
                // @ts-ignore
                buildCustomPointsCopy.push(buildAnchor10(getXCoord(areas10[i].x), getYCoord(areas10[i].y), i, i, 10));
                // @ts-ignore
                buildCustomSizesCopy.push(buildSizes10(getXCoord(areas10[i].x), getYCoord(areas10[i].y), i, i, getXCoord(shiftedMidpoints[i][0]), getYCoord(shiftedMidpoints[i][1])));
            }
            setBuiltCustomPoints10(buildCustomPointsCopy);
            setBuiltSideSizes10(buildCustomSizesCopy)
            
            // set array of points to lines
            let l: any = [];
            for (let i = 0; i < areas10.length; i++) {
                // @ts-ignore
                l.push(getXCoord(areas10[i].x));
                // @ts-ignore
                l.push(getYCoord(areas10[i].y));
            }
            // @ts-ignore
            setLines10(l);
        }
    }, [areas10, isLinesClosed10, appContext.state.selectedScale]);
    React.useEffect(() => {
        if(areas11 != null){
                        // Координати точок будь-якої фігури (визначте ваші точки)
            const pointsForText = areas11

            // Функція для знаходження вектора нормалі до відрізка
            //@ts-ignore
            function findNormalVector(point1: any, point2: any) {
                const dx = point2.x - point1.x;
                const dy = point2.y - point1.y;
                const length = Math.sqrt(dx ** 2 + dy ** 2);
                return [-dy / length, dx / length];
            }

            // Знаходимо вектори нормалі для кожної сторони фігури
            const normalVectors = pointsForText.map((point: any, index: any) => {
                const nextPoint = pointsForText[(index + 1) % pointsForText.length];
                return findNormalVector(point, nextPoint);
            });

            // Зсув середин відрізків на 10 одиниць вздовж нормалі
            const midpoints = pointsForText.map((point: any, index: any) => {
                const nextPoint = pointsForText[(index + 1) % pointsForText.length];
                return [(point.x + nextPoint.x) / 2, (point.y + nextPoint.y) / 2];
            });

            const shiftedMidpoints = midpoints.map((midpoint: any, index: any) => {
            const normalVector = normalVectors[index];
            if(isClockwise(pointsForText)){
                return [
                    midpoint[0] + 0.5 * normalVector[0],
                    midpoint[1] + 0.3 * normalVector[1],
                ];
            }
            else{
                return [
                    midpoint[0] - 0.5 * normalVector[0],
                    midpoint[1] - 0.3 * normalVector[1],
                ];
            }
            });

            let buildCustomPointsCopy: any = [];
            let buildCustomSizesCopy: any = [];
            for (let i = 0; i < areas11.length; i++) {
                // @ts-ignore
                buildCustomPointsCopy.push(buildAnchor11(getXCoord(areas11[i].x), getYCoord(areas11[i].y), i, i, 11));
                // @ts-ignore
                buildCustomSizesCopy.push(buildSizes11(getXCoord(areas11[i].x), getYCoord(areas11[i].y), i, i, getXCoord(shiftedMidpoints[i][0]), getYCoord(shiftedMidpoints[i][1])));
            }
            setBuiltCustomPoints11(buildCustomPointsCopy);
            setBuiltSideSizes11(buildCustomSizesCopy)
            
            // set array of points to lines
            let l: any = [];
            for (let i = 0; i < areas11.length; i++) {
                // @ts-ignore
                l.push(getXCoord(areas11[i].x));
                // @ts-ignore
                l.push(getYCoord(areas11[i].y));
            }
            // @ts-ignore
            setLines11(l);
        }
    }, [areas11, isLinesClosed11, appContext.state.selectedScale]);
    React.useEffect(() => {
        if(areas12 != null){
                        // Координати точок будь-якої фігури (визначте ваші точки)
            const pointsForText = areas12

            // Функція для знаходження вектора нормалі до відрізка
            //@ts-ignore
            function findNormalVector(point1: any, point2: any) {
                const dx = point2.x - point1.x;
                const dy = point2.y - point1.y;
                const length = Math.sqrt(dx ** 2 + dy ** 2);
                return [-dy / length, dx / length];
            }

            // Знаходимо вектори нормалі для кожної сторони фігури
            const normalVectors = pointsForText.map((point: any, index: any) => {
                const nextPoint = pointsForText[(index + 1) % pointsForText.length];
                return findNormalVector(point, nextPoint);
            });

            // Зсув середин відрізків на 10 одиниць вздовж нормалі
            const midpoints = pointsForText.map((point: any, index: any) => {
                const nextPoint = pointsForText[(index + 1) % pointsForText.length];
                return [(point.x + nextPoint.x) / 2, (point.y + nextPoint.y) / 2];
            });

            const shiftedMidpoints = midpoints.map((midpoint: any, index: any) => {
            const normalVector = normalVectors[index];
            if(isClockwise(pointsForText)){
                return [
                    midpoint[0] + 0.5 * normalVector[0],
                    midpoint[1] + 0.3 * normalVector[1],
                ];
            }
            else{
                return [
                    midpoint[0] - 0.5 * normalVector[0],
                    midpoint[1] - 0.3 * normalVector[1],
                ];
            }
            });

            let buildCustomPointsCopy: any = [];
            let buildCustomSizesCopy: any = [];
            for (let i = 0; i < areas12.length; i++) {
                // @ts-ignore
                buildCustomPointsCopy.push(buildAnchor12(getXCoord(areas12[i].x), getYCoord(areas12[i].y), i, i, 12));
                // @ts-ignore
                buildCustomSizesCopy.push(buildSizes12(getXCoord(areas12[i].x), getYCoord(areas12[i].y), i, i, getXCoord(shiftedMidpoints[i][0]), getYCoord(shiftedMidpoints[i][1])));
            }
            setBuiltCustomPoints12(buildCustomPointsCopy);
            setBuiltSideSizes12(buildCustomSizesCopy)
            
            // set array of points to lines
            let l: any = [];
            for (let i = 0; i < areas12.length; i++) {
                // @ts-ignore
                l.push(getXCoord(areas12[i].x));
                // @ts-ignore
                l.push(getYCoord(areas12[i].y));
            }
            // @ts-ignore
            setLines12(l);
        }
    }, [areas12, isLinesClosed12, appContext.state.selectedScale]);
    React.useEffect(() => {
        if(areas13 != null){
                        // Координати точок будь-якої фігури (визначте ваші точки)
            const pointsForText = areas13

            // Функція для знаходження вектора нормалі до відрізка
            //@ts-ignore
            function findNormalVector(point1: any, point2: any) {
                const dx = point2.x - point1.x;
                const dy = point2.y - point1.y;
                const length = Math.sqrt(dx ** 2 + dy ** 2);
                return [-dy / length, dx / length];
            }

            // Знаходимо вектори нормалі для кожної сторони фігури
            const normalVectors = pointsForText.map((point: any, index: any) => {
                const nextPoint = pointsForText[(index + 1) % pointsForText.length];
                return findNormalVector(point, nextPoint);
            });

            // Зсув середин відрізків на 10 одиниць вздовж нормалі
            const midpoints = pointsForText.map((point: any, index: any) => {
                const nextPoint = pointsForText[(index + 1) % pointsForText.length];
                return [(point.x + nextPoint.x) / 2, (point.y + nextPoint.y) / 2];
            });

            const shiftedMidpoints = midpoints.map((midpoint: any, index: any) => {
            const normalVector = normalVectors[index];
            if(isClockwise(pointsForText)){
                return [
                    midpoint[0] + 0.5 * normalVector[0],
                    midpoint[1] + 0.3 * normalVector[1],
                ];
            }
            else{
                return [
                    midpoint[0] - 0.5 * normalVector[0],
                    midpoint[1] - 0.3 * normalVector[1],
                ];
            }
            });

            let buildCustomPointsCopy: any = [];
            let buildCustomSizesCopy: any = [];
            for (let i = 0; i < areas13.length; i++) {
                // @ts-ignore
                buildCustomPointsCopy.push(buildAnchor13(getXCoord(areas13[i].x), getYCoord(areas13[i].y), i, i, 13));
                // @ts-ignore
                buildCustomSizesCopy.push(buildSizes13(getXCoord(areas13[i].x), getYCoord(areas13[i].y), i, i, getXCoord(shiftedMidpoints[i][0]), getYCoord(shiftedMidpoints[i][1])));
            }
            setBuiltCustomPoints13(buildCustomPointsCopy);
            setBuiltSideSizes13(buildCustomSizesCopy)
            
            // set array of points to lines
            let l: any = [];
            for (let i = 0; i < areas13.length; i++) {
                // @ts-ignore
                l.push(getXCoord(areas13[i].x));
                // @ts-ignore
                l.push(getYCoord(areas13[i].y));
            }
            // @ts-ignore
            setLines13(l);
        }
    }, [areas13, isLinesClosed13, appContext.state.selectedScale]);
    React.useEffect(() => {
        if(areas14 != null){
                        // Координати точок будь-якої фігури (визначте ваші точки)
            const pointsForText = areas14

            // Функція для знаходження вектора нормалі до відрізка
            //@ts-ignore
            function findNormalVector(point1: any, point2: any) {
                const dx = point2.x - point1.x;
                const dy = point2.y - point1.y;
                const length = Math.sqrt(dx ** 2 + dy ** 2);
                return [-dy / length, dx / length];
            }

            // Знаходимо вектори нормалі для кожної сторони фігури
            const normalVectors = pointsForText.map((point: any, index: any) => {
                const nextPoint = pointsForText[(index + 1) % pointsForText.length];
                return findNormalVector(point, nextPoint);
            });

            // Зсув середин відрізків на 10 одиниць вздовж нормалі
            const midpoints = pointsForText.map((point: any, index: any) => {
                const nextPoint = pointsForText[(index + 1) % pointsForText.length];
                return [(point.x + nextPoint.x) / 2, (point.y + nextPoint.y) / 2];
            });

            const shiftedMidpoints = midpoints.map((midpoint: any, index: any) => {
            const normalVector = normalVectors[index];
            if(isClockwise(pointsForText)){
                return [
                    midpoint[0] + 0.5 * normalVector[0],
                    midpoint[1] + 0.3 * normalVector[1],
                ];
            }
            else{
                return [
                    midpoint[0] - 0.5 * normalVector[0],
                    midpoint[1] - 0.3 * normalVector[1],
                ];
            }
            });

            let buildCustomPointsCopy: any = [];
            let buildCustomSizesCopy: any = [];
            for (let i = 0; i < areas14.length; i++) {
                // @ts-ignore
                buildCustomPointsCopy.push(buildAnchor14(getXCoord(areas14[i].x), getYCoord(areas14[i].y), i, i, 14));
                // @ts-ignore
                buildCustomSizesCopy.push(buildSizes14(getXCoord(areas14[i].x), getYCoord(areas14[i].y), i, i, getXCoord(shiftedMidpoints[i][0]), getYCoord(shiftedMidpoints[i][1])));
            }
            setBuiltCustomPoints14(buildCustomPointsCopy);
            setBuiltSideSizes14(buildCustomSizesCopy)
            
            // set array of points to lines
            let l: any = [];
            for (let i = 0; i < areas14.length; i++) {
                // @ts-ignore
                l.push(getXCoord(areas14[i].x));
                // @ts-ignore
                l.push(getYCoord(areas14[i].y));
            }
            // @ts-ignore
            setLines14(l);
        }
    }, [areas14, isLinesClosed14, appContext.state.selectedScale]);
    React.useEffect(() => {
        if(areas15 != null){
                        // Координати точок будь-якої фігури (визначте ваші точки)
            const pointsForText = areas15

            // Функція для знаходження вектора нормалі до відрізка
            //@ts-ignore
            function findNormalVector(point1: any, point2: any) {
                const dx = point2.x - point1.x;
                const dy = point2.y - point1.y;
                const length = Math.sqrt(dx ** 2 + dy ** 2);
                return [-dy / length, dx / length];
            }

            // Знаходимо вектори нормалі для кожної сторони фігури
            const normalVectors = pointsForText.map((point: any, index: any) => {
                const nextPoint = pointsForText[(index + 1) % pointsForText.length];
                return findNormalVector(point, nextPoint);
            });

            // Зсув середин відрізків на 10 одиниць вздовж нормалі
            const midpoints = pointsForText.map((point: any, index: any) => {
                const nextPoint = pointsForText[(index + 1) % pointsForText.length];
                return [(point.x + nextPoint.x) / 2, (point.y + nextPoint.y) / 2];
            });

            const shiftedMidpoints = midpoints.map((midpoint: any, index: any) => {
            const normalVector = normalVectors[index];
            if(isClockwise(pointsForText)){
                return [
                    midpoint[0] + 0.5 * normalVector[0],
                    midpoint[1] + 0.3 * normalVector[1],
                ];
            }
            else{
                return [
                    midpoint[0] - 0.5 * normalVector[0],
                    midpoint[1] - 0.3 * normalVector[1],
                ];
            }
            });

            let buildCustomPointsCopy: any = [];
            let buildCustomSizesCopy: any = [];
            for (let i = 0; i < areas15.length; i++) {
                // @ts-ignore
                buildCustomPointsCopy.push(buildAnchor15(getXCoord(areas15[i].x), getYCoord(areas15[i].y), i, i, 15));
                // @ts-ignore
                buildCustomSizesCopy.push(buildSizes15(getXCoord(areas15[i].x), getYCoord(areas15[i].y), i, i, getXCoord(shiftedMidpoints[i][0]), getYCoord(shiftedMidpoints[i][1])));
            }
            setBuiltCustomPoints15(buildCustomPointsCopy);
            setBuiltSideSizes15(buildCustomSizesCopy)
            
            // set array of points to lines
            let l: any = [];
            for (let i = 0; i < areas15.length; i++) {
                // @ts-ignore
                l.push(getXCoord(areas15[i].x));
                // @ts-ignore
                l.push(getYCoord(areas15[i].y));
            }
            // @ts-ignore
            setLines15(l);
        }
    }, [areas15, isLinesClosed15, appContext.state.selectedScale]);
    React.useEffect(() => {
        if(areas16 != null){
                        // Координати точок будь-якої фігури (визначте ваші точки)
            const pointsForText = areas16

            // Функція для знаходження вектора нормалі до відрізка
            //@ts-ignore
            function findNormalVector(point1: any, point2: any) {
                const dx = point2.x - point1.x;
                const dy = point2.y - point1.y;
                const length = Math.sqrt(dx ** 2 + dy ** 2);
                return [-dy / length, dx / length];
            }

            // Знаходимо вектори нормалі для кожної сторони фігури
            const normalVectors = pointsForText.map((point: any, index: any) => {
                const nextPoint = pointsForText[(index + 1) % pointsForText.length];
                return findNormalVector(point, nextPoint);
            });

            // Зсув середин відрізків на 10 одиниць вздовж нормалі
            const midpoints = pointsForText.map((point: any, index: any) => {
                const nextPoint = pointsForText[(index + 1) % pointsForText.length];
                return [(point.x + nextPoint.x) / 2, (point.y + nextPoint.y) / 2];
            });

            const shiftedMidpoints = midpoints.map((midpoint: any, index: any) => {
            const normalVector = normalVectors[index];
            if(isClockwise(pointsForText)){
                return [
                    midpoint[0] + 0.5 * normalVector[0],
                    midpoint[1] + 0.3 * normalVector[1],
                ];
            }
            else{
                return [
                    midpoint[0] - 0.5 * normalVector[0],
                    midpoint[1] - 0.3 * normalVector[1],
                ];
            }
            });

            let buildCustomPointsCopy: any = [];
            let buildCustomSizesCopy: any = [];
            for (let i = 0; i < areas16.length; i++) {
                // @ts-ignore
                buildCustomPointsCopy.push(buildAnchor16(getXCoord(areas16[i].x), getYCoord(areas16[i].y), i, i, 16));
                // @ts-ignore
                buildCustomSizesCopy.push(buildSizes16(getXCoord(areas16[i].x), getYCoord(areas16[i].y), i, i, getXCoord(shiftedMidpoints[i][0]), getYCoord(shiftedMidpoints[i][1])));
            }
            setBuiltCustomPoints16(buildCustomPointsCopy);
            setBuiltSideSizes16(buildCustomSizesCopy)
            
            // set array of points to lines
            let l: any = [];
            for (let i = 0; i < areas16.length; i++) {
                // @ts-ignore
                l.push(getXCoord(areas16[i].x));
                // @ts-ignore
                l.push(getYCoord(areas16[i].y));
            }
            // @ts-ignore
            setLines16(l);
        }
    }, [areas16, isLinesClosed16, appContext.state.selectedScale]);
    React.useEffect(() => {
        if(areas17 != null){
                        // Координати точок будь-якої фігури (визначте ваші точки)
            const pointsForText = areas17

            // Функція для знаходження вектора нормалі до відрізка
            //@ts-ignore
            function findNormalVector(point1: any, point2: any) {
                const dx = point2.x - point1.x;
                const dy = point2.y - point1.y;
                const length = Math.sqrt(dx ** 2 + dy ** 2);
                return [-dy / length, dx / length];
            }

            // Знаходимо вектори нормалі для кожної сторони фігури
            const normalVectors = pointsForText.map((point: any, index: any) => {
                const nextPoint = pointsForText[(index + 1) % pointsForText.length];
                return findNormalVector(point, nextPoint);
            });

            // Зсув середин відрізків на 10 одиниць вздовж нормалі
            const midpoints = pointsForText.map((point: any, index: any) => {
                const nextPoint = pointsForText[(index + 1) % pointsForText.length];
                return [(point.x + nextPoint.x) / 2, (point.y + nextPoint.y) / 2];
            });

            const shiftedMidpoints = midpoints.map((midpoint: any, index: any) => {
            const normalVector = normalVectors[index];
            if(isClockwise(pointsForText)){
                return [
                    midpoint[0] + 0.5 * normalVector[0],
                    midpoint[1] + 0.3 * normalVector[1],
                ];
            }
            else{
                return [
                    midpoint[0] - 0.5 * normalVector[0],
                    midpoint[1] - 0.3 * normalVector[1],
                ];
            }
            });

            let buildCustomPointsCopy: any = [];
            let buildCustomSizesCopy: any = [];
            for (let i = 0; i < areas17.length; i++) {
                // @ts-ignore
                buildCustomPointsCopy.push(buildAnchor17(getXCoord(areas17[i].x), getYCoord(areas17[i].y), i, i, 17));
                // @ts-ignore
                buildCustomSizesCopy.push(buildSizes17(getXCoord(areas17[i].x), getYCoord(areas17[i].y), i, i, getXCoord(shiftedMidpoints[i][0]), getYCoord(shiftedMidpoints[i][1])));
            }
            setBuiltCustomPoints17(buildCustomPointsCopy);
            setBuiltSideSizes17(buildCustomSizesCopy)
            
            // set array of points to lines
            let l: any = [];
            for (let i = 0; i < areas17.length; i++) {
                // @ts-ignore
                l.push(getXCoord(areas17[i].x));
                // @ts-ignore
                l.push(getYCoord(areas17[i].y));
            }
            // @ts-ignore
            setLines17(l);
        }
    }, [areas17, isLinesClosed17, appContext.state.selectedScale]);
    React.useEffect(() => {
        if(areas18 != null){
                        // Координати точок будь-якої фігури (визначте ваші точки)
            const pointsForText = areas18

            // Функція для знаходження вектора нормалі до відрізка
            //@ts-ignore
            function findNormalVector(point1: any, point2: any) {
                const dx = point2.x - point1.x;
                const dy = point2.y - point1.y;
                const length = Math.sqrt(dx ** 2 + dy ** 2);
                return [-dy / length, dx / length];
            }

            // Знаходимо вектори нормалі для кожної сторони фігури
            const normalVectors = pointsForText.map((point: any, index: any) => {
                const nextPoint = pointsForText[(index + 1) % pointsForText.length];
                return findNormalVector(point, nextPoint);
            });

            // Зсув середин відрізків на 10 одиниць вздовж нормалі
            const midpoints = pointsForText.map((point: any, index: any) => {
                const nextPoint = pointsForText[(index + 1) % pointsForText.length];
                return [(point.x + nextPoint.x) / 2, (point.y + nextPoint.y) / 2];
            });

            const shiftedMidpoints = midpoints.map((midpoint: any, index: any) => {
            const normalVector = normalVectors[index];
            if(isClockwise(pointsForText)){
                return [
                    midpoint[0] + 0.5 * normalVector[0],
                    midpoint[1] + 0.3 * normalVector[1],
                ];
            }
            else{
                return [
                    midpoint[0] - 0.5 * normalVector[0],
                    midpoint[1] - 0.3 * normalVector[1],
                ];
            }
            });

            let buildCustomPointsCopy: any = [];
            let buildCustomSizesCopy: any = [];
            for (let i = 0; i < areas18.length; i++) {
                // @ts-ignore
                buildCustomPointsCopy.push(buildAnchor18(getXCoord(areas18[i].x), getYCoord(areas18[i].y), i, i, 18));
                // @ts-ignore
                buildCustomSizesCopy.push(buildSizes18(getXCoord(areas18[i].x), getYCoord(areas18[i].y), i, i, getXCoord(shiftedMidpoints[i][0]), getYCoord(shiftedMidpoints[i][1])));
            }
            setBuiltCustomPoints18(buildCustomPointsCopy);
            setBuiltSideSizes18(buildCustomSizesCopy)
            
            // set array of points to lines
            let l: any = [];
            for (let i = 0; i < areas18.length; i++) {
                // @ts-ignore
                l.push(getXCoord(areas18[i].x));
                // @ts-ignore
                l.push(getYCoord(areas18[i].y));
            }
            // @ts-ignore
            setLines18(l);
        }
    }, [areas18, isLinesClosed18, appContext.state.selectedScale]);
    React.useEffect(() => {
        if(areas19 != null){
                        // Координати точок будь-якої фігури (визначте ваші точки)
            const pointsForText = areas19

            // Функція для знаходження вектора нормалі до відрізка
            //@ts-ignore
            function findNormalVector(point1: any, point2: any) {
                const dx = point2.x - point1.x;
                const dy = point2.y - point1.y;
                const length = Math.sqrt(dx ** 2 + dy ** 2);
                return [-dy / length, dx / length];
            }

            // Знаходимо вектори нормалі для кожної сторони фігури
            const normalVectors = pointsForText.map((point: any, index: any) => {
                const nextPoint = pointsForText[(index + 1) % pointsForText.length];
                return findNormalVector(point, nextPoint);
            });

            // Зсув середин відрізків на 10 одиниць вздовж нормалі
            const midpoints = pointsForText.map((point: any, index: any) => {
                const nextPoint = pointsForText[(index + 1) % pointsForText.length];
                return [(point.x + nextPoint.x) / 2, (point.y + nextPoint.y) / 2];
            });

            const shiftedMidpoints = midpoints.map((midpoint: any, index: any) => {
            const normalVector = normalVectors[index];
            if(isClockwise(pointsForText)){
                return [
                    midpoint[0] + 0.5 * normalVector[0],
                    midpoint[1] + 0.3 * normalVector[1],
                ];
            }
            else{
                return [
                    midpoint[0] - 0.5 * normalVector[0],
                    midpoint[1] - 0.3 * normalVector[1],
                ];
            }
            });

            let buildCustomPointsCopy: any = [];
            let buildCustomSizesCopy: any = [];
            for (let i = 0; i < areas19.length; i++) {
                // @ts-ignore
                buildCustomPointsCopy.push(buildAnchor19(getXCoord(areas19[i].x), getYCoord(areas19[i].y), i, i, 19));
                // @ts-ignore
                buildCustomSizesCopy.push(buildSizes19(getXCoord(areas19[i].x), getYCoord(areas19[i].y), i, i, getXCoord(shiftedMidpoints[i][0]), getYCoord(shiftedMidpoints[i][1])));
            }
            setBuiltCustomPoints19(buildCustomPointsCopy);
            setBuiltSideSizes19(buildCustomSizesCopy)
            
            // set array of points to lines
            let l: any = [];
            for (let i = 0; i < areas19.length; i++) {
                // @ts-ignore
                l.push(getXCoord(areas19[i].x));
                // @ts-ignore
                l.push(getYCoord(areas19[i].y));
            }
            // @ts-ignore
            setLines19(l);
        }
    }, [areas19, isLinesClosed19, appContext.state.selectedScale]);
    React.useEffect(() => {
        if(areas20 != null){
                        // Координати точок будь-якої фігури (визначте ваші точки)
            const pointsForText = areas20

            // Функція для знаходження вектора нормалі до відрізка
            //@ts-ignore
            function findNormalVector(point1: any, point2: any) {
                const dx = point2.x - point1.x;
                const dy = point2.y - point1.y;
                const length = Math.sqrt(dx ** 2 + dy ** 2);
                return [-dy / length, dx / length];
            }

            // Знаходимо вектори нормалі для кожної сторони фігури
            const normalVectors = pointsForText.map((point: any, index: any) => {
                const nextPoint = pointsForText[(index + 1) % pointsForText.length];
                return findNormalVector(point, nextPoint);
            });

            // Зсув середин відрізків на 10 одиниць вздовж нормалі
            const midpoints = pointsForText.map((point: any, index: any) => {
                const nextPoint = pointsForText[(index + 1) % pointsForText.length];
                return [(point.x + nextPoint.x) / 2, (point.y + nextPoint.y) / 2];
            });

            const shiftedMidpoints = midpoints.map((midpoint: any, index: any) => {
            const normalVector = normalVectors[index];
            if(isClockwise(pointsForText)){
                return [
                    midpoint[0] + 0.5 * normalVector[0],
                    midpoint[1] + 0.3 * normalVector[1],
                ];
            }
            else{
                return [
                    midpoint[0] - 0.5 * normalVector[0],
                    midpoint[1] - 0.3 * normalVector[1],
                ];
            }
            });

            let buildCustomPointsCopy: any = [];
            let buildCustomSizesCopy: any = [];
            for (let i = 0; i < areas20.length; i++) {
                // @ts-ignore
                buildCustomPointsCopy.push(buildAnchor20(getXCoord(areas20[i].x), getYCoord(areas20[i].y), i, i, 20));
                // @ts-ignore
                buildCustomSizesCopy.push(buildSizes20(getXCoord(areas20[i].x), getYCoord(areas20[i].y), i, i, getXCoord(shiftedMidpoints[i][0]), getYCoord(shiftedMidpoints[i][1])));
            }
            setBuiltCustomPoints20(buildCustomPointsCopy);
            setBuiltSideSizes20(buildCustomSizesCopy)
            
            // set array of points to lines
            let l: any = [];
            for (let i = 0; i < areas20.length; i++) {
                // @ts-ignore
                l.push(getXCoord(areas20[i].x));
                // @ts-ignore
                l.push(getYCoord(areas20[i].y));
            }
            // @ts-ignore
            setLines20(l);
        }
    }, [areas20, isLinesClosed20, appContext.state.selectedScale]);


    React.useEffect(() => {
        setAreas1Copy(areas1)
    }, [areas1]);
    React.useEffect(() => {
        let areas2Copy = cloneDeep(areas2)
        let maxX = 0
        for(let n = 0; n < areas1.length; n++){
            if(areas1[n].x > maxX){
                maxX = areas1[n].x
            }
        }
        for(let k = 0; k < areas2Copy.length; k++){
            areas2Copy[k] = {x: areas2Copy[k].x + maxX, y: areas2Copy[k].y};
        }
        setAreas2Copy(areas2Copy)
    }, [areas2]);
    React.useEffect(() => {
        let areas3Copy = cloneDeep(areas3)
        for(let i = 1; i < 3; i++){
            let maxX = 0
            for(let n = 0; n < eval("areas" + i).length; n++){
                if(eval("areas" + i)[n].x > maxX){
                    maxX = eval("areas" + i)[n].x
                }
            }
            for(let k = 0; k < areas3Copy.length; k++){
                areas3Copy[k] = {x: areas3Copy[k].x + maxX, y: areas3Copy[k].y};
            }
        }
        setAreas3Copy(areas3Copy)
    }, [areas3]);
    React.useEffect(() => {
        let areas4Copy = cloneDeep(areas4)
        for(let i = 1; i < 4; i++){
            let maxX = 0
            for(let n = 0; n < eval("areas" + i).length; n++){
                if(eval("areas" + i)[n].x > maxX){
                    maxX = eval("areas" + i)[n].x
                }
            }
            for(let k = 0; k < areas4Copy.length; k++){
                areas4Copy[k] = {x: areas4Copy[k].x + maxX, y: areas4Copy[k].y};
            }
        }
        setAreas4Copy(areas4Copy)
    }, [areas4]);
    React.useEffect(() => {
        let areas5Copy = cloneDeep(areas5)
        for(let i = 1; i < 5; i++){
            let maxX = 0
            for(let n = 0; n < eval("areas" + i).length; n++){
                if(eval("areas" + i)[n].x > maxX){
                    maxX = eval("areas" + i)[n].x
                }
            }
            for(let k = 0; k < areas5Copy.length; k++){
                areas5Copy[k] = {x: areas5Copy[k].x + maxX, y: areas5Copy[k].y};
            }
        }
        setAreas5Copy(areas5Copy)
    }, [areas5]);
    React.useEffect(() => {
        let areas6Copy = cloneDeep(areas6)
        for(let i = 1; i < 6; i++){
            let maxX = 0
            for(let n = 0; n < eval("areas" + i).length; n++){
                if(eval("areas" + i)[n].x > maxX){
                    maxX = eval("areas" + i)[n].x
                }
            }
            for(let k = 0; k < areas6Copy.length; k++){
                areas6Copy[k] = {x: areas6Copy[k].x + maxX, y: areas6Copy[k].y};
            }
        }
        setAreas6Copy(areas6Copy)
    }, [areas6]);
    React.useEffect(() => {
        let areas7Copy = cloneDeep(areas7)
        for(let i = 1; i < 7; i++){
            let maxX = 0
            for(let n = 0; n < eval("areas" + i).length; n++){
                if(eval("areas" + i)[n].x > maxX){
                    maxX = eval("areas" + i)[n].x
                }
            }
            for(let k = 0; k < areas7Copy.length; k++){
                areas7Copy[k] = {x: areas7Copy[k].x + maxX, y: areas7Copy[k].y};
            }
        }
        setAreas7Copy(areas7Copy)
    }, [areas7]);
    React.useEffect(() => {
        let areas8Copy = cloneDeep(areas8)
        for(let i = 1; i < 8; i++){
            let maxX = 0
            for(let n = 0; n < eval("areas" + i).length; n++){
                if(eval("areas" + i)[n].x > maxX){
                    maxX = eval("areas" + i)[n].x
                }
            }
            for(let k = 0; k < areas8Copy.length; k++){
                areas8Copy[k] = {x: areas8Copy[k].x + maxX, y: areas8Copy[k].y};
            }
        }
        setAreas8Copy(areas8Copy)
    }, [areas8]);
    React.useEffect(() => {
        let areas9Copy = cloneDeep(areas9)
        for(let i = 1; i < 9; i++){
            let maxX = 0
            for(let n = 0; n < eval("areas" + i).length; n++){
                if(eval("areas" + i)[n].x > maxX){
                    maxX = eval("areas" + i)[n].x
                }
            }
            for(let k = 0; k < areas9Copy.length; k++){
                areas9Copy[k] = {x: areas9Copy[k].x + maxX, y: areas9Copy[k].y};
            }
        }
        setAreas9Copy(areas9Copy)
    }, [areas9]);
    React.useEffect(() => {
        let areas10Copy = cloneDeep(areas10)
        for(let i = 1; i < 10; i++){
            let maxX = 0
            for(let n = 0; n < eval("areas" + i).length; n++){
                if(eval("areas" + i)[n].x > maxX){
                    maxX = eval("areas" + i)[n].x
                }
            }
            for(let k = 0; k < areas10Copy.length; k++){
                areas10Copy[k] = {x: areas10Copy[k].x + maxX, y: areas10Copy[k].y};
            }
        }
        setAreas10Copy(areas10Copy)
    }, [areas10]);
    React.useEffect(() => {
        let areas11Copy = cloneDeep(areas11)
        for(let i = 1; i < 11; i++){
            let maxX = 0
            for(let n = 0; n < eval("areas" + i).length; n++){
                if(eval("areas" + i)[n].x > maxX){
                    maxX = eval("areas" + i)[n].x
                }
            }
            for(let k = 0; k < areas11Copy.length; k++){
                areas11Copy[k] = {x: areas11Copy[k].x + maxX, y: areas11Copy[k].y};
            }
        }
        setAreas11Copy(areas11Copy)
    }, [areas11]);
    React.useEffect(() => {
        let areas12Copy = cloneDeep(areas12)
        for(let i = 1; i < 12; i++){
            let maxX = 0
            for(let n = 0; n < eval("areas" + i).length; n++){
                if(eval("areas" + i)[n].x > maxX){
                    maxX = eval("areas" + i)[n].x
                }
            }
            for(let k = 0; k < areas12Copy.length; k++){
                areas12Copy[k] = {x: areas12Copy[k].x + maxX, y: areas12Copy[k].y};
            }
        }
        setAreas12Copy(areas12Copy)
    }, [areas12]);
    React.useEffect(() => {
        let areas13Copy = cloneDeep(areas13)
        for(let i = 1; i < 13; i++){
            let maxX = 0
            for(let n = 0; n < eval("areas" + i).length; n++){
                if(eval("areas" + i)[n].x > maxX){
                    maxX = eval("areas" + i)[n].x
                }
            }
            for(let k = 0; k < areas13Copy.length; k++){
                areas13Copy[k] = {x: areas13Copy[k].x + maxX, y: areas13Copy[k].y};
            }
        }
        setAreas13Copy(areas13Copy)
    }, [areas13]);
    React.useEffect(() => {
        let areas14Copy = cloneDeep(areas14)
        for(let i = 1; i < 14; i++){
            let maxX = 0
            for(let n = 0; n < eval("areas" + i).length; n++){
                if(eval("areas" + i)[n].x > maxX){
                    maxX = eval("areas" + i)[n].x
                }
            }
            for(let k = 0; k < areas14Copy.length; k++){
                areas14Copy[k] = {x: areas14Copy[k].x + maxX, y: areas14Copy[k].y};
            }
        }
        setAreas14Copy(areas14Copy)
    }, [areas14]);
    React.useEffect(() => {
        let areas15Copy = cloneDeep(areas15)
        for(let i = 1; i < 15; i++){
            let maxX = 0
            for(let n = 0; n < eval("areas" + i).length; n++){
                if(eval("areas" + i)[n].x > maxX){
                    maxX = eval("areas" + i)[n].x
                }
            }
            for(let k = 0; k < areas15Copy.length; k++){
                areas15Copy[k] = {x: areas15Copy[k].x + maxX, y: areas15Copy[k].y};
            }
        }
        setAreas15Copy(areas15Copy)
    }, [areas15]);
    React.useEffect(() => {
        let areas16Copy = cloneDeep(areas16)
        for(let i = 1; i < 16; i++){
            let maxX = 0
            for(let n = 0; n < eval("areas" + i).length; n++){
                if(eval("areas" + i)[n].x > maxX){
                    maxX = eval("areas" + i)[n].x
                }
            }
            for(let k = 0; k < areas16Copy.length; k++){
                areas16Copy[k] = {x: areas16Copy[k].x + maxX, y: areas16Copy[k].y};
            }
        }
        setAreas16Copy(areas16Copy)
    }, [areas16]);
    React.useEffect(() => {
        let areas17Copy = cloneDeep(areas17)
        for(let i = 1; i < 17; i++){
            let maxX = 0
            for(let n = 0; n < eval("areas" + i).length; n++){
                if(eval("areas" + i)[n].x > maxX){
                    maxX = eval("areas" + i)[n].x
                }
            }
            for(let k = 0; k < areas17Copy.length; k++){
                areas17Copy[k] = {x: areas17Copy[k].x + maxX, y: areas17Copy[k].y};
            }
        }
        setAreas17Copy(areas17Copy)
    }, [areas17]);
    React.useEffect(() => {
        let areas18Copy = cloneDeep(areas18)
        for(let i = 1; i < 18; i++){
            let maxX = 0
            for(let n = 0; n < eval("areas" + i).length; n++){
                if(eval("areas" + i)[n].x > maxX){
                    maxX = eval("areas" + i)[n].x
                }
            }
            for(let k = 0; k < areas18Copy.length; k++){
                areas18Copy[k] = {x: areas18Copy[k].x + maxX, y: areas18Copy[k].y};
            }
        }
        setAreas18Copy(areas18Copy)
    }, [areas18]);
    React.useEffect(() => {
        let areas19Copy = cloneDeep(areas19)
        for(let i = 1; i < 19; i++){
            let maxX = 0
            for(let n = 0; n < eval("areas" + i).length; n++){
                if(eval("areas" + i)[n].x > maxX){
                    maxX = eval("areas" + i)[n].x
                }
            }
            for(let k = 0; k < areas19Copy.length; k++){
                areas19Copy[k] = {x: areas19Copy[k].x + maxX, y: areas19Copy[k].y};
            }
        }
        setAreas19Copy(areas19Copy)
    }, [areas19]);
    React.useEffect(() => {
        let areas20Copy = cloneDeep(areas20)
        for(let i = 1; i < 20; i++){
            let maxX = 0
            for(let n = 0; n < eval("areas" + i).length; n++){
                if(eval("areas" + i)[n].x > maxX){
                    maxX = eval("areas" + i)[n].x
                }
            }
            for(let k = 0; k < areas20Copy.length; k++){
                areas20Copy[k] = {x: areas20Copy[k].x + maxX, y: areas20Copy[k].y};
            }
        }
        setAreas20Copy(areas20Copy)
    }, [areas20]);

    React.useEffect(() => {
        if(areas1Copy != null && appContext.state.isEditedMode == true){
            let buildCustomPointsCopy: any = [];
            for (let i = 0; i < areas1Copy.length; i++) {
                // @ts-ignore
                buildCustomPointsCopy.push(buildAnchor1Copy(getXCoord(areas1Copy[i].x), getYCoord(areas1Copy[i].y), i, i, 1));
            }
            setBuiltCustomPoints1Copy(buildCustomPointsCopy);
            
            // set array of points to lines
            let l: any = [];
            for (let i = 0; i < areas1Copy.length; i++) {
                // @ts-ignore
                l.push(getXCoord(areas1Copy[i].x));
                // @ts-ignore
                l.push(getYCoord(areas1Copy[i].y));
            }
            // @ts-ignore
            setLines1Copy(l)
        }
    }, [areas1Copy, isLinesClosed1, appContext.state.selectedScale, turnDottAreaText]);
    React.useEffect(() => {
        if(areas2Copy != null && appContext.state.isEditedMode == true){
            let buildCustomPointsCopy: any = [];
            for (let i = 0; i < areas2Copy.length; i++) {
                // @ts-ignore
                buildCustomPointsCopy.push(buildAnchor2Copy(getXCoord(areas2Copy[i].x), getYCoord(areas2Copy[i].y), i, i, 2));
            }
            setBuiltCustomPoints2Copy(buildCustomPointsCopy);
            
            // set array of points to lines
            let l: any = [];
            for (let i = 0; i < areas2Copy.length; i++) {
                // @ts-ignore
                l.push(getXCoord(areas2Copy[i].x));
                // @ts-ignore
                l.push(getYCoord(areas2Copy[i].y));
            }
            // @ts-ignore
            setLines2Copy(l)
        }
    }, [areas2Copy, isLinesClosed2, appContext.state.selectedScale, turnDottAreaText]);
    React.useEffect(() => {
        if(areas3Copy != null && appContext.state.isEditedMode == true){
            let buildCustomPointsCopy: any = [];
            for (let i = 0; i < areas3Copy.length; i++) {
                // @ts-ignore
                buildCustomPointsCopy.push(buildAnchor3Copy(getXCoord(areas3Copy[i].x), getYCoord(areas3Copy[i].y), i, i, 3));
            }
            setBuiltCustomPoints3Copy(buildCustomPointsCopy);
            
            // set array of points to lines
            let l: any = [];
            for (let i = 0; i < areas3Copy.length; i++) {
                // @ts-ignore
                l.push(getXCoord(areas3Copy[i].x));
                // @ts-ignore
                l.push(getYCoord(areas3Copy[i].y));
            }
            // @ts-ignore
            setLines3Copy(l)
        }
    }, [areas3Copy, isLinesClosed3, appContext.state.selectedScale, turnDottAreaText]);
    React.useEffect(() => {
        if(areas4Copy != null && appContext.state.isEditedMode == true){
            let buildCustomPointsCopy: any = [];
            for (let i = 0; i < areas4Copy.length; i++) {
                // @ts-ignore
                buildCustomPointsCopy.push(buildAnchor4Copy(getXCoord(areas4Copy[i].x), getYCoord(areas4Copy[i].y), i, i, 4));
            }
            setBuiltCustomPoints4Copy(buildCustomPointsCopy);
            
            // set array of points to lines
            let l: any = [];
            for (let i = 0; i < areas4Copy.length; i++) {
                // @ts-ignore
                l.push(getXCoord(areas4Copy[i].x));
                // @ts-ignore
                l.push(getYCoord(areas4Copy[i].y));
            }
            // @ts-ignore
            setLines4Copy(l)
        }
    }, [areas4Copy, isLinesClosed4, appContext.state.selectedScale, turnDottAreaText]);
    React.useEffect(() => {
        if(areas5Copy != null && appContext.state.isEditedMode == true){
            let buildCustomPointsCopy: any = [];
            for (let i = 0; i < areas5Copy.length; i++) {
                // @ts-ignore
                buildCustomPointsCopy.push(buildAnchor5Copy(getXCoord(areas5Copy[i].x), getYCoord(areas5Copy[i].y), i, i, 5));
            }
            setBuiltCustomPoints5Copy(buildCustomPointsCopy);
            
            // set array of points to lines
            let l: any = [];
            for (let i = 0; i < areas5Copy.length; i++) {
                // @ts-ignore
                l.push(getXCoord(areas5Copy[i].x));
                // @ts-ignore
                l.push(getYCoord(areas5Copy[i].y));
            }
            // @ts-ignore
            setLines5Copy(l)
        }
    }, [areas5Copy, isLinesClosed5, appContext.state.selectedScale, turnDottAreaText]);
    React.useEffect(() => {
        if(areas6Copy != null && appContext.state.isEditedMode == true){
            let buildCustomPointsCopy: any = [];
            for (let i = 0; i < areas6Copy.length; i++) {
                // @ts-ignore
                buildCustomPointsCopy.push(buildAnchor6Copy(getXCoord(areas6Copy[i].x), getYCoord(areas6Copy[i].y), i, i, 6));
            }
            setBuiltCustomPoints6Copy(buildCustomPointsCopy);
            
            // set array of points to lines
            let l: any = [];
            for (let i = 0; i < areas6Copy.length; i++) {
                // @ts-ignore
                l.push(getXCoord(areas6Copy[i].x));
                // @ts-ignore
                l.push(getYCoord(areas6Copy[i].y));
            }
            // @ts-ignore
            setLines6Copy(l)
        }
    }, [areas6Copy, isLinesClosed6, appContext.state.selectedScale, turnDottAreaText]);
    React.useEffect(() => {
        if(areas7Copy != null && appContext.state.isEditedMode == true){
            let buildCustomPointsCopy: any = [];
            for (let i = 0; i < areas7Copy.length; i++) {
                // @ts-ignore
                buildCustomPointsCopy.push(buildAnchor7Copy(getXCoord(areas7Copy[i].x), getYCoord(areas7Copy[i].y), i, i, 7));
            }
            setBuiltCustomPoints7Copy(buildCustomPointsCopy);
            
            // set array of points to lines
            let l: any = [];
            for (let i = 0; i < areas7Copy.length; i++) {
                // @ts-ignore
                l.push(getXCoord(areas7Copy[i].x));
                // @ts-ignore
                l.push(getYCoord(areas7Copy[i].y));
            }
            // @ts-ignore
            setLines7Copy(l)
        }
    }, [areas7Copy, isLinesClosed7, appContext.state.selectedScale, turnDottAreaText]);
    React.useEffect(() => {
        if(areas8Copy != null && appContext.state.isEditedMode == true){
            let buildCustomPointsCopy: any = [];
            for (let i = 0; i < areas8Copy.length; i++) {
                // @ts-ignore
                buildCustomPointsCopy.push(buildAnchor8Copy(getXCoord(areas8Copy[i].x), getYCoord(areas8Copy[i].y), i, i, 8));
            }
            setBuiltCustomPoints8Copy(buildCustomPointsCopy);
            
            // set array of points to lines
            let l: any = [];
            for (let i = 0; i < areas8Copy.length; i++) {
                // @ts-ignore
                l.push(getXCoord(areas8Copy[i].x));
                // @ts-ignore
                l.push(getYCoord(areas8Copy[i].y));
            }
            // @ts-ignore
            setLines8Copy(l)
        }
    }, [areas8Copy, isLinesClosed8, appContext.state.selectedScale, turnDottAreaText]);
    React.useEffect(() => {
        if(areas9Copy != null && appContext.state.isEditedMode == true){
            let buildCustomPointsCopy: any = [];
            for (let i = 0; i < areas9Copy.length; i++) {
                // @ts-ignore
                buildCustomPointsCopy.push(buildAnchor9Copy(getXCoord(areas9Copy[i].x), getYCoord(areas9Copy[i].y), i, i, 9));
            }
            setBuiltCustomPoints9Copy(buildCustomPointsCopy);
            
            // set array of points to lines
            let l: any = [];
            for (let i = 0; i < areas9Copy.length; i++) {
                // @ts-ignore
                l.push(getXCoord(areas9Copy[i].x));
                // @ts-ignore
                l.push(getYCoord(areas9Copy[i].y));
            }
            // @ts-ignore
            setLines9Copy(l)
        }
    }, [areas9Copy, isLinesClosed9, appContext.state.selectedScale, turnDottAreaText]);
    React.useEffect(() => {
        if(areas10Copy != null && appContext.state.isEditedMode == true){
            let buildCustomPointsCopy: any = [];
            for (let i = 0; i < areas10Copy.length; i++) {
                // @ts-ignore
                buildCustomPointsCopy.push(buildAnchor10Copy(getXCoord(areas10Copy[i].x), getYCoord(areas10Copy[i].y), i, i, 10));
            }
            setBuiltCustomPoints10Copy(buildCustomPointsCopy);
            
            // set array of points to lines
            let l: any = [];
            for (let i = 0; i < areas10Copy.length; i++) {
                // @ts-ignore
                l.push(getXCoord(areas10Copy[i].x));
                // @ts-ignore
                l.push(getYCoord(areas10Copy[i].y));
            }
            // @ts-ignore
            setLines10Copy(l)
        }
    }, [areas10Copy, isLinesClosed10, appContext.state.selectedScale, turnDottAreaText]);
    React.useEffect(() => {
        if(areas11Copy != null && appContext.state.isEditedMode == true){
            let buildCustomPointsCopy: any = [];
            for (let i = 0; i < areas11Copy.length; i++) {
                // @ts-ignore
                buildCustomPointsCopy.push(buildAnchor11Copy(getXCoord(areas11Copy[i].x), getYCoord(areas11Copy[i].y), i, i, 11));
            }
            setBuiltCustomPoints11Copy(buildCustomPointsCopy);
            
            // set array of points to lines
            let l: any = [];
            for (let i = 0; i < areas11Copy.length; i++) {
                // @ts-ignore
                l.push(getXCoord(areas11Copy[i].x));
                // @ts-ignore
                l.push(getYCoord(areas11Copy[i].y));
            }
            // @ts-ignore
            setLines11Copy(l)
        }
    }, [areas11Copy, isLinesClosed11, appContext.state.selectedScale, turnDottAreaText]);
    React.useEffect(() => {
        if(areas12Copy != null && appContext.state.isEditedMode == true){
            let buildCustomPointsCopy: any = [];
            for (let i = 0; i < areas12Copy.length; i++) {
                // @ts-ignore
                buildCustomPointsCopy.push(buildAnchor12Copy(getXCoord(areas12Copy[i].x), getYCoord(areas12Copy[i].y), i, i, 12));
            }
            setBuiltCustomPoints12Copy(buildCustomPointsCopy);
            
            // set array of points to lines
            let l: any = [];
            for (let i = 0; i < areas12Copy.length; i++) {
                // @ts-ignore
                l.push(getXCoord(areas12Copy[i].x));
                // @ts-ignore
                l.push(getYCoord(areas12Copy[i].y));
            }
            // @ts-ignore
            setLines12Copy(l)
        }
    }, [areas12Copy, isLinesClosed12, appContext.state.selectedScale, turnDottAreaText]);
    React.useEffect(() => {
        if(areas13Copy != null && appContext.state.isEditedMode == true){
            let buildCustomPointsCopy: any = [];
            for (let i = 0; i < areas13Copy.length; i++) {
                // @ts-ignore
                buildCustomPointsCopy.push(buildAnchor13Copy(getXCoord(areas13Copy[i].x), getYCoord(areas13Copy[i].y), i, i, 13));
            }
            setBuiltCustomPoints13Copy(buildCustomPointsCopy);
            
            // set array of points to lines
            let l: any = [];
            for (let i = 0; i < areas13Copy.length; i++) {
                // @ts-ignore
                l.push(getXCoord(areas13Copy[i].x));
                // @ts-ignore
                l.push(getYCoord(areas13Copy[i].y));
            }
            // @ts-ignore
            setLines13Copy(l)
        }
    }, [areas13Copy, isLinesClosed13, appContext.state.selectedScale, turnDottAreaText]);
    React.useEffect(() => {
        if(areas14Copy != null && appContext.state.isEditedMode == true){
            let buildCustomPointsCopy: any = [];
            for (let i = 0; i < areas14Copy.length; i++) {
                // @ts-ignore
                buildCustomPointsCopy.push(buildAnchor14Copy(getXCoord(areas14Copy[i].x), getYCoord(areas14Copy[i].y), i, i, 14));
            }
            setBuiltCustomPoints14Copy(buildCustomPointsCopy);
            
            // set array of points to lines
            let l: any = [];
            for (let i = 0; i < areas14Copy.length; i++) {
                // @ts-ignore
                l.push(getXCoord(areas14Copy[i].x));
                // @ts-ignore
                l.push(getYCoord(areas14Copy[i].y));
            }
            // @ts-ignore
            setLines14Copy(l)
        }
    }, [areas14Copy, isLinesClosed14, appContext.state.selectedScale, turnDottAreaText]);
    React.useEffect(() => {
        if(areas15Copy != null && appContext.state.isEditedMode == true){
            let buildCustomPointsCopy: any = [];
            for (let i = 0; i < areas15Copy.length; i++) {
                // @ts-ignore
                buildCustomPointsCopy.push(buildAnchor15Copy(getXCoord(areas15Copy[i].x), getYCoord(areas15Copy[i].y), i, i, 15));
            }
            setBuiltCustomPoints15Copy(buildCustomPointsCopy);
            
            // set array of points to lines
            let l: any = [];
            for (let i = 0; i < areas15Copy.length; i++) {
                // @ts-ignore
                l.push(getXCoord(areas15Copy[i].x));
                // @ts-ignore
                l.push(getYCoord(areas15Copy[i].y));
            }
            // @ts-ignore
            setLines15Copy(l)
        }
    }, [areas15Copy, isLinesClosed15, appContext.state.selectedScale, turnDottAreaText]);
    React.useEffect(() => {
        if(areas16Copy != null && appContext.state.isEditedMode == true){
            let buildCustomPointsCopy: any = [];
            for (let i = 0; i < areas16Copy.length; i++) {
                // @ts-ignore
                buildCustomPointsCopy.push(buildAnchor16Copy(getXCoord(areas16Copy[i].x), getYCoord(areas16Copy[i].y), i, i, 16));
            }
            setBuiltCustomPoints16Copy(buildCustomPointsCopy);
            
            // set array of points to lines
            let l: any = [];
            for (let i = 0; i < areas16Copy.length; i++) {
                // @ts-ignore
                l.push(getXCoord(areas16Copy[i].x));
                // @ts-ignore
                l.push(getYCoord(areas16Copy[i].y));
            }
            // @ts-ignore
            setLines16Copy(l)
        }
    }, [areas16Copy, isLinesClosed16, appContext.state.selectedScale, turnDottAreaText]);
    React.useEffect(() => {
        if(areas17Copy != null && appContext.state.isEditedMode == true){
            let buildCustomPointsCopy: any = [];
            for (let i = 0; i < areas17Copy.length; i++) {
                // @ts-ignore
                buildCustomPointsCopy.push(buildAnchor17Copy(getXCoord(areas17Copy[i].x), getYCoord(areas17Copy[i].y), i, i, 17));
            }
            setBuiltCustomPoints17Copy(buildCustomPointsCopy);
            
            // set array of points to lines
            let l: any = [];
            for (let i = 0; i < areas17Copy.length; i++) {
                // @ts-ignore
                l.push(getXCoord(areas17Copy[i].x));
                // @ts-ignore
                l.push(getYCoord(areas17Copy[i].y));
            }
            // @ts-ignore
            setLines17Copy(l)
        }
    }, [areas17Copy, isLinesClosed17, appContext.state.selectedScale, turnDottAreaText]);
    React.useEffect(() => {
        if(areas18Copy != null && appContext.state.isEditedMode == true){
            let buildCustomPointsCopy: any = [];
            for (let i = 0; i < areas18Copy.length; i++) {
                // @ts-ignore
                buildCustomPointsCopy.push(buildAnchor18Copy(getXCoord(areas18Copy[i].x), getYCoord(areas18Copy[i].y), i, i, 18));
            }
            setBuiltCustomPoints18Copy(buildCustomPointsCopy);
            
            // set array of points to lines
            let l: any = [];
            for (let i = 0; i < areas18Copy.length; i++) {
                // @ts-ignore
                l.push(getXCoord(areas18Copy[i].x));
                // @ts-ignore
                l.push(getYCoord(areas18Copy[i].y));
            }
            // @ts-ignore
            setLines18Copy(l)
        }
    }, [areas18Copy, isLinesClosed18, appContext.state.selectedScale, turnDottAreaText]);
    React.useEffect(() => {
        if(areas19Copy != null && appContext.state.isEditedMode == true){
            let buildCustomPointsCopy: any = [];
            for (let i = 0; i < areas19Copy.length; i++) {
                // @ts-ignore
                buildCustomPointsCopy.push(buildAnchor19Copy(getXCoord(areas19Copy[i].x), getYCoord(areas19Copy[i].y), i, i, 19));
            }
            setBuiltCustomPoints19Copy(buildCustomPointsCopy);
            
            // set array of points to lines
            let l: any = [];
            for (let i = 0; i < areas19Copy.length; i++) {
                // @ts-ignore
                l.push(getXCoord(areas19Copy[i].x));
                // @ts-ignore
                l.push(getYCoord(areas19Copy[i].y));
            }
            // @ts-ignore
            setLines19Copy(l)
        }
    }, [areas19Copy, isLinesClosed19, appContext.state.selectedScale, turnDottAreaText]);
    React.useEffect(() => {
        if(areas20Copy != null && appContext.state.isEditedMode == true){
            let buildCustomPointsCopy: any = [];
            for (let i = 0; i < areas20Copy.length; i++) {
                // @ts-ignore
                buildCustomPointsCopy.push(buildAnchor20Copy(getXCoord(areas20Copy[i].x), getYCoord(areas20Copy[i].y), i, i, 20));
            }
            setBuiltCustomPoints20Copy(buildCustomPointsCopy);
            
            // set array of points to lines
            let l: any = [];
            for (let i = 0; i < areas20Copy.length; i++) {
                // @ts-ignore
                l.push(getXCoord(areas20Copy[i].x));
                // @ts-ignore
                l.push(getYCoord(areas20Copy[i].y));
            }
            // @ts-ignore
            setLines20Copy(l)
        }
    }, [areas20Copy, isLinesClosed20, appContext.state.selectedScale, turnDottAreaText]);

    React.useEffect(() => {
        setActiveDottCoordsX(null)
        setActiveDottCoordsY(null)
        setTurnDottCoordsX(null)
        setTurnDottCoordsY(null)
        setMoveDottCoordsX(null)
        setMoveDottCoordsY(null)
    }, [areasCount]);

    React.useEffect(() => {
        // @ts-ignore
        if(Apex1Id.length > 0){
            let apex1Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Apex1Id.length; i++){
                apex1Copy.push(getXCoord(areas1[Apex1Id[i]].x))
                apex1Copy.push(getYCoord(areas1[Apex1Id[i]].y))
            }
            setApex1(apex1Copy)
            setApex1Copy(apex1Copy)
        }
    }, [Apex1Id, areas1, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Apex2Id.length > 0){
            let apex2Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Apex2Id.length; i++){
                apex2Copy.push(getXCoord(areas2[Apex2Id[i]].x))
                apex2Copy.push(getYCoord(areas2[Apex2Id[i]].y))
            }
            setApex2(apex2Copy)
            setApex2Copy(apex2Copy)
        }
    }, [Apex2Id, areas2, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Apex3Id.length > 0){
            let apex3Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Apex3Id.length; i++){
                apex3Copy.push(getXCoord(areas3[Apex3Id[i]].x))
                apex3Copy.push(getYCoord(areas3[Apex3Id[i]].y))
            }
            setApex3(apex3Copy)
            setApex3Copy(apex3Copy)
        }
    }, [Apex3Id, areas3, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Apex4Id.length > 0){
            let apex4Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Apex4Id.length; i++){
                apex4Copy.push(getXCoord(areas4[Apex4Id[i]].x))
                apex4Copy.push(getYCoord(areas4[Apex4Id[i]].y))
            }
            setApex4(apex4Copy)
            setApex4Copy(apex4Copy)
        }
    }, [Apex4Id, areas4, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Apex5Id.length > 0){
            let apex5Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Apex5Id.length; i++){
                apex5Copy.push(getXCoord(areas5[Apex5Id[i]].x))
                apex5Copy.push(getYCoord(areas5[Apex5Id[i]].y))
            }
            setApex5(apex5Copy)
            setApex5Copy(apex5Copy)
        }
    }, [Apex5Id, areas5, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Apex6Id.length > 0){
            let apex6Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Apex6Id.length; i++){
                apex6Copy.push(getXCoord(areas6[Apex6Id[i]].x))
                apex6Copy.push(getYCoord(areas6[Apex6Id[i]].y))
            }
            setApex6(apex6Copy)
            setApex6Copy(apex6Copy)
        }
    }, [Apex6Id, areas6, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Apex7Id.length > 0){
            let apex7Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Apex7Id.length; i++){
                apex7Copy.push(getXCoord(areas7[Apex7Id[i]].x))
                apex7Copy.push(getYCoord(areas7[Apex7Id[i]].y))
            }
            setApex7(apex7Copy)
            setApex7Copy(apex7Copy)
        }
    }, [Apex7Id, areas7, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Apex8Id.length > 0){
            let apex8Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Apex8Id.length; i++){
                apex8Copy.push(getXCoord(areas8[Apex8Id[i]].x))
                apex8Copy.push(getYCoord(areas8[Apex8Id[i]].y))
            }
            setApex8(apex8Copy)
            setApex8Copy(apex8Copy)
        }
    }, [Apex8Id, areas8, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Apex9Id.length > 0){
            let apex9Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Apex9Id.length; i++){
                apex9Copy.push(getXCoord(areas9[Apex9Id[i]].x))
                apex9Copy.push(getYCoord(areas9[Apex9Id[i]].y))
            }
            setApex9(apex9Copy)
            setApex9Copy(apex9Copy)
        }
    }, [Apex9Id, areas9, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Apex10Id.length > 0){
            let apex10Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Apex10Id.length; i++){
                apex10Copy.push(getXCoord(areas10[Apex10Id[i]].x))
                apex10Copy.push(getYCoord(areas10[Apex10Id[i]].y))
            }
            setApex10(apex10Copy)
            setApex10Copy(apex10Copy)
        }
    }, [Apex10Id, areas10, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Apex11Id.length > 0){
            let apex11Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Apex11Id.length; i++){
                apex11Copy.push(getXCoord(areas11[Apex11Id[i]].x))
                apex11Copy.push(getYCoord(areas11[Apex11Id[i]].y))
            }
            setApex11(apex11Copy)
            setApex11Copy(apex11Copy)
        }
    }, [Apex11Id, areas11, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Apex12Id.length > 0){
            let apex12Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Apex12Id.length; i++){
                apex12Copy.push(getXCoord(areas12[Apex12Id[i]].x))
                apex12Copy.push(getYCoord(areas12[Apex12Id[i]].y))
            }
            setApex12(apex12Copy)
            setApex12Copy(apex12Copy)
        }
    }, [Apex12Id, areas12, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Apex13Id.length > 0){
            let apex13Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Apex13Id.length; i++){
                apex13Copy.push(getXCoord(areas13[Apex13Id[i]].x))
                apex13Copy.push(getYCoord(areas13[Apex13Id[i]].y))
            }
            setApex13(apex13Copy)
            setApex13Copy(apex13Copy)
        }
    }, [Apex13Id, areas13, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Apex14Id.length > 0){
            let apex14Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Apex14Id.length; i++){
                apex14Copy.push(getXCoord(areas14[Apex14Id[i]].x))
                apex14Copy.push(getYCoord(areas14[Apex14Id[i]].y))
            }
            setApex14(apex14Copy)
            setApex14Copy(apex14Copy)
        }
    }, [Apex14Id, areas14, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Apex15Id.length > 0){
            let apex15Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Apex15Id.length; i++){
                apex15Copy.push(getXCoord(areas15[Apex15Id[i]].x))
                apex15Copy.push(getYCoord(areas15[Apex15Id[i]].y))
            }
            setApex15(apex15Copy)
            setApex15Copy(apex15Copy)
        }
    }, [Apex15Id, areas15, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Apex16Id.length > 0){
            let apex16Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Apex16Id.length; i++){
                apex16Copy.push(getXCoord(areas16[Apex16Id[i]].x))
                apex16Copy.push(getYCoord(areas16[Apex16Id[i]].y))
            }
            setApex16(apex16Copy)
            setApex16Copy(apex16Copy)
        }
    }, [Apex16Id, areas16, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Apex17Id.length > 0){
            let apex17Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Apex17Id.length; i++){
                apex17Copy.push(getXCoord(areas17[Apex17Id[i]].x))
                apex17Copy.push(getYCoord(areas17[Apex17Id[i]].y))
            }
            setApex17(apex17Copy)
            setApex17Copy(apex17Copy)
        }
    }, [Apex17Id, areas17, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Apex18Id.length > 0){
            let apex18Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Apex18Id.length; i++){
                apex18Copy.push(getXCoord(areas18[Apex18Id[i]].x))
                apex18Copy.push(getYCoord(areas18[Apex18Id[i]].y))
            }
            setApex18(apex18Copy)
            setApex18Copy(apex18Copy)
        }
    }, [Apex18Id, areas18, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Apex19Id.length > 0){
            let apex19Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Apex19Id.length; i++){
                apex19Copy.push(getXCoord(areas19[Apex19Id[i]].x))
                apex19Copy.push(getYCoord(areas19[Apex19Id[i]].y))
            }
            setApex19(apex19Copy)
            setApex19Copy(apex19Copy)
        }
    }, [Apex19Id, areas19, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Apex20Id.length > 0){
            let apex20Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Apex20Id.length; i++){
                apex20Copy.push(getXCoord(areas20[Apex20Id[i]].x))
                apex20Copy.push(getYCoord(areas20[Apex20Id[i]].y))
            }
            setApex20(apex20Copy)
            setApex20Copy(apex20Copy)
        }
    }, [Apex20Id, areas20, areasCount, appContext.state.selectedScale]);

    React.useEffect(() => {
        // @ts-ignore
        if(Eaves1Id.length > 0){
            let eaves1Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Eaves1Id.length; i++){
                eaves1Copy.push(getXCoord(areas1[Eaves1Id[i]].x))
                eaves1Copy.push(getYCoord(areas1[Eaves1Id[i]].y))
            }
            setEaves1(eaves1Copy)
            setEaves1Copy(eaves1Copy)
        }
    }, [Eaves1Id, areas1, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Eaves2Id.length > 0){
            let eaves2Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Eaves2Id.length; i++){
                eaves2Copy.push(getXCoord(areas2[Eaves2Id[i]].x))
                eaves2Copy.push(getYCoord(areas2[Eaves2Id[i]].y))
            }
            setEaves2(eaves2Copy)
            setEaves2Copy(eaves2Copy)
        }
    }, [Eaves2Id, areas2, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Eaves3Id.length > 0){
            let eaves3Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Eaves3Id.length; i++){
                eaves3Copy.push(getXCoord(areas3[Eaves3Id[i]].x))
                eaves3Copy.push(getYCoord(areas3[Eaves3Id[i]].y))
            }
            setEaves3(eaves3Copy)
            setEaves3Copy(eaves3Copy)
        }
    }, [Eaves3Id, areas3, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Eaves4Id.length > 0){
            let eaves4Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Eaves4Id.length; i++){
                eaves4Copy.push(getXCoord(areas4[Eaves4Id[i]].x))
                eaves4Copy.push(getYCoord(areas4[Eaves4Id[i]].y))
            }
            setEaves4(eaves4Copy)
            setEaves4Copy(eaves4Copy)
        }
    }, [Eaves4Id, areas4, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Eaves5Id.length > 0){
            let eaves5Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Eaves5Id.length; i++){
                eaves5Copy.push(getXCoord(areas5[Eaves5Id[i]].x))
                eaves5Copy.push(getYCoord(areas5[Eaves5Id[i]].y))
            }
            setEaves5(eaves5Copy)
            setEaves5Copy(eaves5Copy)
        }
    }, [Eaves5Id, areas5, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Eaves6Id.length > 0){
            let eaves6Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Eaves6Id.length; i++){
                eaves6Copy.push(getXCoord(areas6[Eaves6Id[i]].x))
                eaves6Copy.push(getYCoord(areas6[Eaves6Id[i]].y))
            }
            setEaves6(eaves6Copy)
            setEaves6Copy(eaves6Copy)
        }
    }, [Eaves6Id, areas6, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Eaves7Id.length > 0){
            let eaves7Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Eaves7Id.length; i++){
                eaves7Copy.push(getXCoord(areas7[Eaves7Id[i]].x))
                eaves7Copy.push(getYCoord(areas7[Eaves7Id[i]].y))
            }
            setEaves7(eaves7Copy)
            setEaves7Copy(eaves7Copy)
        }
    }, [Eaves7Id, areas7, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Eaves8Id.length > 0){
            let eaves8Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Eaves8Id.length; i++){
                eaves8Copy.push(getXCoord(areas8[Eaves8Id[i]].x))
                eaves8Copy.push(getYCoord(areas8[Eaves8Id[i]].y))
            }
            setEaves8(eaves8Copy)
            setEaves8Copy(eaves8Copy)
        }
    }, [Eaves8Id, areas8, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Eaves9Id.length > 0){
            let eaves9Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Eaves9Id.length; i++){
                eaves9Copy.push(getXCoord(areas9[Eaves9Id[i]].x))
                eaves9Copy.push(getYCoord(areas9[Eaves9Id[i]].y))
            }
            setEaves9(eaves9Copy)
            setEaves9Copy(eaves9Copy)
        }
    }, [Eaves9Id, areas9, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Eaves10Id.length > 0){
            let eaves10Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Eaves10Id.length; i++){
                eaves10Copy.push(getXCoord(areas10[Eaves10Id[i]].x))
                eaves10Copy.push(getYCoord(areas10[Eaves10Id[i]].y))
            }
            setEaves10(eaves10Copy)
            setEaves10Copy(eaves10Copy)
        }
    }, [Eaves10Id, areas10, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Eaves11Id.length > 0){
            let eaves11Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Eaves11Id.length; i++){
                eaves11Copy.push(getXCoord(areas11[Eaves11Id[i]].x))
                eaves11Copy.push(getYCoord(areas11[Eaves11Id[i]].y))
            }
            setEaves11(eaves11Copy)
            setEaves11Copy(eaves11Copy)
        }
    }, [Eaves11Id, areas11, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Eaves12Id.length > 0){
            let eaves12Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Eaves12Id.length; i++){
                eaves12Copy.push(getXCoord(areas12[Eaves12Id[i]].x))
                eaves12Copy.push(getYCoord(areas12[Eaves12Id[i]].y))
            }
            setEaves12(eaves12Copy)
            setEaves12Copy(eaves12Copy)
        }
    }, [Eaves12Id, areas12, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Eaves13Id.length > 0){
            let eaves13Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Eaves13Id.length; i++){
                eaves13Copy.push(getXCoord(areas13[Eaves13Id[i]].x))
                eaves13Copy.push(getYCoord(areas13[Eaves13Id[i]].y))
            }
            setEaves13(eaves13Copy)
            setEaves13Copy(eaves13Copy)
        }
    }, [Eaves13Id, areas13, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Eaves14Id.length > 0){
            let eaves14Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Eaves14Id.length; i++){
                eaves14Copy.push(getXCoord(areas14[Eaves14Id[i]].x))
                eaves14Copy.push(getYCoord(areas14[Eaves14Id[i]].y))
            }
            setEaves14(eaves14Copy)
            setEaves14Copy(eaves14Copy)
        }
    }, [Eaves14Id, areas14, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Eaves15Id.length > 0){
            let eaves15Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Eaves15Id.length; i++){
                eaves15Copy.push(getXCoord(areas15[Eaves15Id[i]].x))
                eaves15Copy.push(getYCoord(areas15[Eaves15Id[i]].y))
            }
            setEaves15(eaves15Copy)
            setEaves15Copy(eaves15Copy)
        }
    }, [Eaves15Id, areas15, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Eaves16Id.length > 0){
            let eaves16Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Eaves16Id.length; i++){
                eaves16Copy.push(getXCoord(areas16[Eaves16Id[i]].x))
                eaves16Copy.push(getYCoord(areas16[Eaves16Id[i]].y))
            }
            setEaves16(eaves16Copy)
            setEaves16Copy(eaves16Copy)
        }
    }, [Eaves16Id, areas16, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Eaves17Id.length > 0){
            let eaves17Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Eaves17Id.length; i++){
                eaves17Copy.push(getXCoord(areas17[Eaves17Id[i]].x))
                eaves17Copy.push(getYCoord(areas17[Eaves17Id[i]].y))
            }
            setEaves17(eaves17Copy)
            setEaves17Copy(eaves17Copy)
        }
    }, [Eaves17Id, areas17, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Eaves18Id.length > 0){
            let eaves18Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Eaves18Id.length; i++){
                eaves18Copy.push(getXCoord(areas18[Eaves18Id[i]].x))
                eaves18Copy.push(getYCoord(areas18[Eaves18Id[i]].y))
            }
            setEaves18(eaves18Copy)
            setEaves18Copy(eaves18Copy)
        }
    }, [Eaves18Id, areas18, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Eaves19Id.length > 0){
            let eaves19Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Eaves19Id.length; i++){
                eaves19Copy.push(getXCoord(areas19[Eaves19Id[i]].x))
                eaves19Copy.push(getYCoord(areas19[Eaves19Id[i]].y))
            }
            setEaves19(eaves19Copy)
            setEaves19Copy(eaves19Copy)
        }
    }, [Eaves19Id, areas19, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Eaves20Id.length > 0){
            let eaves20Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Eaves20Id.length; i++){
                eaves20Copy.push(getXCoord(areas20[Eaves20Id[i]].x))
                eaves20Copy.push(getYCoord(areas20[Eaves20Id[i]].y))
            }
            setEaves20(eaves20Copy)
            setEaves20Copy(eaves20Copy)
        }
    }, [Eaves20Id, areas20, areasCount, appContext.state.selectedScale]);

    React.useEffect(() => {
        // @ts-ignore
        if(Apex1Id.length > 0){
            let apex1Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Apex1Id.length; i++){
                apex1Copy.push(getXCoord(areas1Copy[Apex1Id[i]].x))
                apex1Copy.push(getYCoord(areas1Copy[Apex1Id[i]].y))
            }
            setApex1Copy(apex1Copy)
        }
    }, [Apex1Id, areas1Copy, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Apex2Id.length > 0){
            let apex2Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Apex2Id.length; i++){
                apex2Copy.push(getXCoord(areas2Copy[Apex2Id[i]].x))
                apex2Copy.push(getYCoord(areas2Copy[Apex2Id[i]].y))
            }
            setApex2Copy(apex2Copy)
        }
    }, [Apex2Id, areas2Copy, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Apex3Id.length > 0){
            let apex3Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Apex3Id.length; i++){
                apex3Copy.push(getXCoord(areas3Copy[Apex3Id[i]].x))
                apex3Copy.push(getYCoord(areas3Copy[Apex3Id[i]].y))
            }
            setApex3Copy(apex3Copy)
        }
    }, [Apex3Id, areas3Copy, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Apex4Id.length > 0){
            let apex4Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Apex4Id.length; i++){
                apex4Copy.push(getXCoord(areas4Copy[Apex4Id[i]].x))
                apex4Copy.push(getYCoord(areas4Copy[Apex4Id[i]].y))
            }
            setApex4Copy(apex4Copy)
        }
    }, [Apex4Id, areas4Copy, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Apex5Id.length > 0){
            let apex5Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Apex5Id.length; i++){
                apex5Copy.push(getXCoord(areas5Copy[Apex5Id[i]].x))
                apex5Copy.push(getYCoord(areas5Copy[Apex5Id[i]].y))
            }
            setApex5Copy(apex5Copy)
        }
    }, [Apex5Id, areas5Copy, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Apex6Id.length > 0){
            let apex6Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Apex6Id.length; i++){
                apex6Copy.push(getXCoord(areas6Copy[Apex6Id[i]].x))
                apex6Copy.push(getYCoord(areas6Copy[Apex6Id[i]].y))
            }
            setApex6Copy(apex6Copy)
        }
    }, [Apex6Id, areas6Copy, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Apex7Id.length > 0){
            let apex7Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Apex7Id.length; i++){
                apex7Copy.push(getXCoord(areas7Copy[Apex7Id[i]].x))
                apex7Copy.push(getYCoord(areas7Copy[Apex7Id[i]].y))
            }
            setApex7Copy(apex7Copy)
        }
    }, [Apex7Id, areas7Copy, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Apex8Id.length > 0){
            let apex8Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Apex8Id.length; i++){
                apex8Copy.push(getXCoord(areas8Copy[Apex8Id[i]].x))
                apex8Copy.push(getYCoord(areas8Copy[Apex8Id[i]].y))
            }
            setApex8Copy(apex8Copy)
        }
    }, [Apex8Id, areas8Copy, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Apex9Id.length > 0){
            let apex9Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Apex9Id.length; i++){
                apex9Copy.push(getXCoord(areas9Copy[Apex9Id[i]].x))
                apex9Copy.push(getYCoord(areas9Copy[Apex9Id[i]].y))
            }
            setApex9Copy(apex9Copy)
        }
    }, [Apex9Id, areas9Copy, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Apex10Id.length > 0){
            let apex10Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Apex10Id.length; i++){
                apex10Copy.push(getXCoord(areas10Copy[Apex10Id[i]].x))
                apex10Copy.push(getYCoord(areas10Copy[Apex10Id[i]].y))
            }
            setApex10Copy(apex10Copy)
        }
    }, [Apex10Id, areas10Copy, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Apex11Id.length > 0){
            let apex11Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Apex11Id.length; i++){
                apex11Copy.push(getXCoord(areas11Copy[Apex11Id[i]].x))
                apex11Copy.push(getYCoord(areas11Copy[Apex11Id[i]].y))
            }
            setApex11Copy(apex11Copy)
        }
    }, [Apex11Id, areas11Copy, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Apex12Id.length > 0){
            let apex12Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Apex12Id.length; i++){
                apex12Copy.push(getXCoord(areas12Copy[Apex12Id[i]].x))
                apex12Copy.push(getYCoord(areas12Copy[Apex12Id[i]].y))
            }
            setApex12Copy(apex12Copy)
        }
    }, [Apex12Id, areas12Copy, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Apex13Id.length > 0){
            let apex13Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Apex13Id.length; i++){
                apex13Copy.push(getXCoord(areas13Copy[Apex13Id[i]].x))
                apex13Copy.push(getYCoord(areas13Copy[Apex13Id[i]].y))
            }
            setApex13Copy(apex13Copy)
        }
    }, [Apex13Id, areas13Copy, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Apex14Id.length > 0){
            let apex14Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Apex14Id.length; i++){
                apex14Copy.push(getXCoord(areas14Copy[Apex14Id[i]].x))
                apex14Copy.push(getYCoord(areas14Copy[Apex14Id[i]].y))
            }
            setApex14Copy(apex14Copy)
        }
    }, [Apex14Id, areas14Copy, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Apex15Id.length > 0){
            let apex15Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Apex15Id.length; i++){
                apex15Copy.push(getXCoord(areas15Copy[Apex15Id[i]].x))
                apex15Copy.push(getYCoord(areas15Copy[Apex15Id[i]].y))
            }
            setApex15Copy(apex15Copy)
        }
    }, [Apex15Id, areas15Copy, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Apex16Id.length > 0){
            let apex16Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Apex16Id.length; i++){
                apex16Copy.push(getXCoord(areas16Copy[Apex16Id[i]].x))
                apex16Copy.push(getYCoord(areas16Copy[Apex16Id[i]].y))
            }
            setApex16Copy(apex16Copy)
        }
    }, [Apex16Id, areas16Copy, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Apex17Id.length > 0){
            let apex17Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Apex17Id.length; i++){
                apex17Copy.push(getXCoord(areas17Copy[Apex17Id[i]].x))
                apex17Copy.push(getYCoord(areas17Copy[Apex17Id[i]].y))
            }
            setApex17Copy(apex17Copy)
        }
    }, [Apex17Id, areas17Copy, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Apex18Id.length > 0){
            let apex18Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Apex18Id.length; i++){
                apex18Copy.push(getXCoord(areas18Copy[Apex18Id[i]].x))
                apex18Copy.push(getYCoord(areas18Copy[Apex18Id[i]].y))
            }
            setApex18Copy(apex18Copy)
        }
    }, [Apex18Id, areas18Copy, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Apex19Id.length > 0){
            let apex19Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Apex19Id.length; i++){
                apex19Copy.push(getXCoord(areas19Copy[Apex19Id[i]].x))
                apex19Copy.push(getYCoord(areas19Copy[Apex19Id[i]].y))
            }
            setApex19Copy(apex19Copy)
        }
    }, [Apex19Id, areas19Copy, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Apex20Id.length > 0){
            let apex20Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Apex20Id.length; i++){
                apex20Copy.push(getXCoord(areas20Copy[Apex20Id[i]].x))
                apex20Copy.push(getYCoord(areas20Copy[Apex20Id[i]].y))
            }
            setApex20Copy(apex20Copy)
        }
    }, [Apex20Id, areas20Copy, areasCount, appContext.state.selectedScale]);


    React.useEffect(() => {
        // @ts-ignore
        if(Eaves1Id.length > 0){
            let eaves1Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Eaves1Id.length; i++){
                eaves1Copy.push(getXCoord(areas1Copy[Eaves1Id[i]].x))
                eaves1Copy.push(getYCoord(areas1Copy[Eaves1Id[i]].y))
            }
            setEaves1Copy(eaves1Copy)
        }
    }, [Eaves1Id, areas1Copy, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Eaves2Id.length > 0){
            let eaves2Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Eaves2Id.length; i++){
                eaves2Copy.push(getXCoord(areas2Copy[Eaves2Id[i]].x))
                eaves2Copy.push(getYCoord(areas2Copy[Eaves2Id[i]].y))
            }
            setEaves2Copy(eaves2Copy)
        }
    }, [Eaves2Id, areas2Copy, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Eaves3Id.length > 0){
            let eaves3Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Eaves3Id.length; i++){
                eaves3Copy.push(getXCoord(areas3Copy[Eaves3Id[i]].x))
                eaves3Copy.push(getYCoord(areas3Copy[Eaves3Id[i]].y))
            }
            setEaves3Copy(eaves3Copy)
        }
    }, [Eaves3Id, areas3Copy, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Eaves4Id.length > 0){
            let eaves4Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Eaves4Id.length; i++){
                eaves4Copy.push(getXCoord(areas4Copy[Eaves4Id[i]].x))
                eaves4Copy.push(getYCoord(areas4Copy[Eaves4Id[i]].y))
            }
            setEaves4Copy(eaves4Copy)
        }
    }, [Eaves4Id, areas4Copy, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Eaves5Id.length > 0){
            let eaves5Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Eaves5Id.length; i++){
                eaves5Copy.push(getXCoord(areas5Copy[Eaves5Id[i]].x))
                eaves5Copy.push(getYCoord(areas5Copy[Eaves5Id[i]].y))
            }
            setEaves5Copy(eaves5Copy)
        }
    }, [Eaves5Id, areas5Copy, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Eaves6Id.length > 0){
            let eaves6Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Eaves6Id.length; i++){
                eaves6Copy.push(getXCoord(areas6Copy[Eaves6Id[i]].x))
                eaves6Copy.push(getYCoord(areas6Copy[Eaves6Id[i]].y))
            }
            setEaves6Copy(eaves6Copy)
        }
    }, [Eaves6Id, areas6Copy, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Eaves7Id.length > 0){
            let eaves7Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Eaves7Id.length; i++){
                eaves7Copy.push(getXCoord(areas7Copy[Eaves7Id[i]].x))
                eaves7Copy.push(getYCoord(areas7Copy[Eaves7Id[i]].y))
            }
            setEaves7Copy(eaves7Copy)
        }
    }, [Eaves7Id, areas7Copy, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Eaves8Id.length > 0){
            let eaves8Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Eaves8Id.length; i++){
                eaves8Copy.push(getXCoord(areas8Copy[Eaves8Id[i]].x))
                eaves8Copy.push(getYCoord(areas8Copy[Eaves8Id[i]].y))
            }
            setEaves8Copy(eaves8Copy)
        }
    }, [Eaves8Id, areas8Copy, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Eaves9Id.length > 0){
            let eaves9Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Eaves9Id.length; i++){
                eaves9Copy.push(getXCoord(areas9Copy[Eaves9Id[i]].x))
                eaves9Copy.push(getYCoord(areas9Copy[Eaves9Id[i]].y))
            }
            setEaves9Copy(eaves9Copy)
        }
    }, [Eaves9Id, areas9Copy, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Eaves10Id.length > 0){
            let eaves10Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Eaves10Id.length; i++){
                eaves10Copy.push(getXCoord(areas10Copy[Eaves10Id[i]].x))
                eaves10Copy.push(getYCoord(areas10Copy[Eaves10Id[i]].y))
            }
            setEaves10Copy(eaves10Copy)
        }
    }, [Eaves10Id, areas10Copy, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Eaves11Id.length > 0){
            let eaves11Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Eaves11Id.length; i++){
                eaves11Copy.push(getXCoord(areas11Copy[Eaves11Id[i]].x))
                eaves11Copy.push(getYCoord(areas11Copy[Eaves11Id[i]].y))
            }
            setEaves11Copy(eaves11Copy)
        }
    }, [Eaves11Id, areas11Copy, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Eaves12Id.length > 0){
            let eaves12Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Eaves12Id.length; i++){
                eaves12Copy.push(getXCoord(areas12Copy[Eaves12Id[i]].x))
                eaves12Copy.push(getYCoord(areas12Copy[Eaves12Id[i]].y))
            }
            setEaves12Copy(eaves12Copy)
        }
    }, [Eaves12Id, areas12Copy, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Eaves13Id.length > 0){
            let eaves13Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Eaves13Id.length; i++){
                eaves13Copy.push(getXCoord(areas13Copy[Eaves13Id[i]].x))
                eaves13Copy.push(getYCoord(areas13Copy[Eaves13Id[i]].y))
            }
            setEaves13Copy(eaves13Copy)
        }
    }, [Eaves13Id, areas13Copy, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Eaves14Id.length > 0){
            let eaves14Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Eaves14Id.length; i++){
                eaves14Copy.push(getXCoord(areas14Copy[Eaves14Id[i]].x))
                eaves14Copy.push(getYCoord(areas14Copy[Eaves14Id[i]].y))
            }
            setEaves14Copy(eaves14Copy)
        }
    }, [Eaves14Id, areas14Copy, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Eaves15Id.length > 0){
            let eaves15Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Eaves15Id.length; i++){
                eaves15Copy.push(getXCoord(areas15Copy[Eaves15Id[i]].x))
                eaves15Copy.push(getYCoord(areas15Copy[Eaves15Id[i]].y))
            }
            setEaves15Copy(eaves15Copy)
        }
    }, [Eaves15Id, areas15Copy, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Eaves16Id.length > 0){
            let eaves16Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Eaves16Id.length; i++){
                eaves16Copy.push(getXCoord(areas16Copy[Eaves16Id[i]].x))
                eaves16Copy.push(getYCoord(areas16Copy[Eaves16Id[i]].y))
            }
            setEaves16Copy(eaves16Copy)
        }
    }, [Eaves16Id, areas16Copy, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Eaves17Id.length > 0){
            let eaves17Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Eaves17Id.length; i++){
                eaves17Copy.push(getXCoord(areas17Copy[Eaves17Id[i]].x))
                eaves17Copy.push(getYCoord(areas17Copy[Eaves17Id[i]].y))
            }
            setEaves17Copy(eaves17Copy)
        }
    }, [Eaves17Id, areas17Copy, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Eaves18Id.length > 0){
            let eaves18Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Eaves18Id.length; i++){
                eaves18Copy.push(getXCoord(areas18Copy[Eaves18Id[i]].x))
                eaves18Copy.push(getYCoord(areas18Copy[Eaves18Id[i]].y))
            }
            setEaves18Copy(eaves18Copy)
        }
    }, [Eaves18Id, areas18Copy, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Eaves19Id.length > 0){
            let eaves19Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Eaves19Id.length; i++){
                eaves19Copy.push(getXCoord(areas19Copy[Eaves19Id[i]].x))
                eaves19Copy.push(getYCoord(areas19Copy[Eaves19Id[i]].y))
            }
            setEaves19Copy(eaves19Copy)
        }
    }, [Eaves19Id, areas19Copy, areasCount, appContext.state.selectedScale]);
    React.useEffect(() => {
        // @ts-ignore
        if(Eaves20Id.length > 0){
            let eaves20Copy: any = []
            // @ts-ignore
            for(let i = 0; i < Eaves20Id.length; i++){
                eaves20Copy.push(getXCoord(areas20Copy[Eaves20Id[i]].x))
                eaves20Copy.push(getYCoord(areas20Copy[Eaves20Id[i]].y))
            }
            setEaves20Copy(eaves20Copy)
        }
    }, [Eaves20Id, areas20Copy, areasCount, appContext.state.selectedScale]);

    
    // Побудова точки
    
    let buildAnchor = (x: number, y: number, name: string, key: number, textX: number, textY: number) => {
        let length1: any;
        if(points.length > key + 1){
            length1 = ( Math.sqrt(Math.pow((points[key + 1].x - points[key].x), 2) + Math.pow((points[key + 1].y- points[key].y), 2))).toFixed(2)
        }
        if(points.length > 1 && points.length == key + 1){
            length1 = ( Math.sqrt(Math.pow((points[key].x - points[0].x), 2) + Math.pow((points[key].y- points[0].y), 2))).toFixed(2)
        }
        return(
            <>
                <Circle key={key}
                    // @ts-ignore
                    x = {x || 0}
                    // @ts-ignore
                    y ={y || 0}
                    radius = {8}
                    stroke = {'#666'}
                    fill = {'#ddd'}
                    strokeWidth = {2}
                    id = {name}
                    draggable = {true}
                    onClick = {() => {activeDott(name, x, y)}}
                    onMouseOver = {onMouseOverHandler.bind(this)}
                    onMouseOut = {onMouseOutHandler.bind(this)}
                    onDragMove = {onDragMoveHandler.bind(this)}
                    onDragEnd = {onDragEndHandler.bind(this)}
                />
                {
                 points.length > key + 1 ? 
                 // @ts-ignore
                 <Text
                        x = {textX || 0}
                        y = {textY || 0}
                        text={length1 || null}
                        scaleY={-1}  
                        fontSize={20}
                        offsetX={20}
                        offsetY={10}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
                {
                 points.length > 1 && points.length == key + 1 && isLineClosed ? 
                 // @ts-ignore
                 <Text
                        x = {textX || 0}
                        y = {textY || 0}
                        text={length1 || null}
                        scaleY={-1}  
                        fontSize={20}
                        offsetX={20}
                        offsetY={10}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
            </>
        )
    }
    
    let buildSizes1 = (x: number, y: number, name: string, key: number, textX: any, textY: any) =>{
        let length1: any;
        if(areas1.length > key + 1){
            length1 = ( Math.sqrt(Math.pow((areas1[key + 1].x - areas1[key].x), 2) + Math.pow((areas1[key + 1].y- areas1[key].y), 2))).toFixed(2)
        }
        if(areas1.length > 1 && areas1.length == key + 1){
            length1 = ( Math.sqrt(Math.pow((areas1[key].x - areas1[0].x), 2) + Math.pow((areas1[key].y- areas1[0].y), 2))).toFixed(2)
        }
        return( 
            <>
                {
                 areas1.length > key + 1 ? 
                 // @ts-ignore
                 <Text
                        x = {textX || 0}
                        y = {textY || 0}
                        text={length1 || null}
                        scaleY={-1}  
                        fontSize={20}
                        offsetX={20}
                        offsetY={10}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
                {
                 areas1.length > 1 && areas1.length == key + 1 && isLinesClosed1 ? 
                 // @ts-ignore
                 <Text
                        x = {textX || 0}
                        y = {textY || 0}
                        text={length1 || null}
                        scaleY={-1}  
                        fontSize={20}
                        offsetX={20}
                        offsetY={10}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
            </>
        )
    }
    let buildAnchor1 = (x: number, y: number, name: string, key: number) => {
        return(
            <>
                <Circle key={key}
                        x = {x || 0}
                        y ={ y || 0}
                        radius = {8}
                        stroke = {'#666'}
                        fill = {'#ddd'}
                        strokeWidth = {2}
                        id = {name}
                        draggable = {true}
                        onClick = {() => {activeDott1(name, x, y)}}
                        onMouseOver = {onMouseOverHandler.bind(this)}
                        onMouseOut = {onMouseOutHandler.bind(this)}
                        onDragMove = {onDragMoveHandler1.bind(this)}
                        onDragEnd = {onDragEndHandler.bind(this)}
                />
            </>
        )
    }
    let buildSizes2 = (x: number, y: number, name: string, key: number, textX: any, textY: any) => {
        let length1: any;
        if(areas2.length > key + 1){
            length1 = ( Math.sqrt(Math.pow((areas2[key + 1].x - areas2[key].x), 2) + Math.pow((areas2[key + 1].y- areas2[key].y), 2))).toFixed(2)
        }
        if(areas2.length > 1 && areas2.length == key + 1){
            length1 = ( Math.sqrt(Math.pow((areas2[key].x - areas2[0].x), 2) + Math.pow((areas2[key].y- areas2[0].y), 2))).toFixed(2)
        }
        return( 
            <>
                {
                 areas2.length > key + 1 ? 
                 // @ts-ignore
                 <Text
                        x = {textX || 0}
                        y = {textY || 0}
                        text={length1 || null}
                        scaleY={-1}  
                        fontSize={20}
                        offsetX={20}
                        offsetY={10}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
                {
                 areas2.length > 1 && areas2.length == key + 1 && isLinesClosed1 ? 
                 // @ts-ignore
                 <Text
                        x = {textX || 0}
                        y = {textY || 0}
                        text={length1 || null}
                        scaleY={-1}  
                        fontSize={20}
                        offsetX={20}
                        offsetY={10}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
            </>
        )
    }
    let buildAnchor2 = (x: number, y: number, name: string, key: number) => {
        return(
            <>
                <Circle key={key}
                        x = {x || 0}
                        y ={ y || 0}
                        radius = {8}
                        stroke = {'#666'}
                        fill = {'#ddd'}
                        strokeWidth = {2}
                        id = {name}
                        draggable = {true}
                        onClick = {() => {activeDott1(name, x, y)}}
                        onMouseOver = {onMouseOverHandler.bind(this)}
                        onMouseOut = {onMouseOutHandler.bind(this)}
                        onDragMove = {onDragMoveHandler2.bind(this)}
                        onDragEnd = {onDragEndHandler.bind(this)}
                />
            </>
        )
    }
    let buildSizes3 = (x: number, y: number, name: string, key: number, textX: any, textY: any) => {
        let length1: any;
        if(areas3.length > key + 1){
            length1 = ( Math.sqrt(Math.pow((areas3[key + 1].x - areas3[key].x), 2) + Math.pow((areas3[key + 1].y- areas3[key].y), 2))).toFixed(2)
        }
        if(areas3.length > 1 && areas3.length == key + 1){
            length1 = ( Math.sqrt(Math.pow((areas3[key].x - areas3[0].x), 2) + Math.pow((areas3[key].y- areas3[0].y), 2))).toFixed(2)
        }
        return( 
            <>
                {
                 areas3.length > key + 1 ? 
                 // @ts-ignore
                 <Text
                        x = {textX || 0}
                        y = {textY || 0}
                        text={length1 || null}
                        scaleY={-1}  
                        fontSize={20}
                        offsetX={20}
                        offsetY={10}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
                {
                 areas3.length > 1 && areas3.length == key + 1 && isLinesClosed1 ? 
                 // @ts-ignore
                 <Text
                        x = {textX || 0}
                        y = {textY || 0}
                        text={length1 || null}
                        scaleY={-1}  
                        fontSize={20}
                        offsetX={20}
                        offsetY={10}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
            </>
        )
    }
    let buildAnchor3 = (x: number, y: number, name: string, key: number) => {
        return(
            <>
                <Circle key={key}
                        x = {x || 0}
                        y ={ y || 0}
                        radius = {8}
                        stroke = {'#666'}
                        fill = {'#ddd'}
                        strokeWidth = {2}
                        id = {name}
                        draggable = {true}
                        onClick = {() => {activeDott1(name, x, y)}}
                        onMouseOver = {onMouseOverHandler.bind(this)}
                        onMouseOut = {onMouseOutHandler.bind(this)}
                        onDragMove = {onDragMoveHandler3.bind(this)}
                        onDragEnd = {onDragEndHandler.bind(this)}
                />
            </>
        )
    }
    let buildSizes4 = (x: number, y: number, name: string, key: number, textX: any, textY: any) => {
        let length1: any;
        if(areas4.length > key + 1){
            length1 = ( Math.sqrt(Math.pow((areas4[key + 1].x - areas4[key].x), 2) + Math.pow((areas4[key + 1].y- areas4[key].y), 2))).toFixed(2)
        }
        if(areas4.length > 1 && areas4.length == key + 1){
            length1 = ( Math.sqrt(Math.pow((areas4[key].x - areas4[0].x), 2) + Math.pow((areas4[key].y- areas4[0].y), 2))).toFixed(2)
        }
        return( 
            <>
                {
                 areas4.length > key + 1 ? 
                 // @ts-ignore
                 <Text
                        x = {textX || 0}
                        y = {textY || 0}
                        text={length1 || null}
                        scaleY={-1}  
                        fontSize={20}
                        offsetX={20}
                        offsetY={10}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
                {
                 areas4.length > 1 && areas4.length == key + 1 && isLinesClosed1 ? 
                 // @ts-ignore
                 <Text
                        x = {textX || 0}
                        y = {textY || 0}
                        text={length1 || null}
                        scaleY={-1}  
                        fontSize={20}
                        offsetX={20}
                        offsetY={10}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
            </>
        )
    }
    let buildAnchor4 = (x: number, y: number, name: string, key: number) => {
        return(
            <>
                <Circle key={key}
                        x = {x || 0}
                        y ={ y || 0}
                        radius = {8}
                        stroke = {'#666'}
                        fill = {'#ddd'}
                        strokeWidth = {2}
                        id = {name}
                        draggable = {true}
                        onClick = {() => {activeDott1(name, x, y)}}
                        onMouseOver = {onMouseOverHandler.bind(this)}
                        onMouseOut = {onMouseOutHandler.bind(this)}
                        onDragMove = {onDragMoveHandler4.bind(this)}
                        onDragEnd = {onDragEndHandler.bind(this)}
                />
            </>
        )
    }
    let buildSizes5 = (x: number, y: number, name: string, key: number, textX: any, textY: any) => {
        let length1: any;
        if(areas5.length > key + 1){
            length1 = ( Math.sqrt(Math.pow((areas5[key + 1].x - areas5[key].x), 2) + Math.pow((areas5[key + 1].y- areas5[key].y), 2))).toFixed(2)
        }
        if(areas5.length > 1 && areas5.length == key + 1){
            length1 = ( Math.sqrt(Math.pow((areas5[key].x - areas5[0].x), 2) + Math.pow((areas5[key].y- areas5[0].y), 2))).toFixed(2)
        }
        return( 
            <>
                {
                 areas5.length > key + 1 ? 
                 // @ts-ignore
                 <Text
                        x = {textX || 0}
                        y = {textY || 0}
                        text={length1 || null}
                        scaleY={-1}  
                        fontSize={20}
                        offsetX={20}
                        offsetY={10}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
                {
                 areas5.length > 1 && areas5.length == key + 1 && isLinesClosed1 ? 
                 // @ts-ignore
                 <Text
                        x = {textX || 0}
                        y = {textY || 0}
                        text={length1 || null}
                        scaleY={-1}  
                        fontSize={20}
                        offsetX={20}
                        offsetY={10}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
            </>
        )
    }
    let buildAnchor5 = (x: number, y: number, name: string, key: number) => {
        return(
            <>
                <Circle key={key}
                        x = {x || 0}
                        y ={ y || 0}
                        radius = {8}
                        stroke = {'#666'}
                        fill = {'#ddd'}
                        strokeWidth = {2}
                        id = {name}
                        draggable = {true}
                        onClick = {() => {activeDott1(name, x, y)}}
                        onMouseOver = {onMouseOverHandler.bind(this)}
                        onMouseOut = {onMouseOutHandler.bind(this)}
                        onDragMove = {onDragMoveHandler5.bind(this)}
                        onDragEnd = {onDragEndHandler.bind(this)}
                />
            </>
        )
    }
    let buildSizes6 = (x: number, y: number, name: string, key: number, textX: any, textY: any) => {
        let length1: any;
        if(areas6.length > key + 1){
            length1 = ( Math.sqrt(Math.pow((areas6[key + 1].x - areas6[key].x), 2) + Math.pow((areas6[key + 1].y- areas6[key].y), 2))).toFixed(2)
        }
        if(areas6.length > 1 && areas6.length == key + 1){
            length1 = ( Math.sqrt(Math.pow((areas6[key].x - areas6[0].x), 2) + Math.pow((areas6[key].y- areas6[0].y), 2))).toFixed(2)
        }
        return( 
            <>
                {
                 areas6.length > key + 1 ? 
                 // @ts-ignore
                 <Text
                        x = {textX || 0}
                        y = {textY || 0}
                        text={length1 || null}
                        scaleY={-1}  
                        fontSize={20}
                        offsetX={20}
                        offsetY={10}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
                {
                 areas6.length > 1 && areas6.length == key + 1 && isLinesClosed1 ? 
                 // @ts-ignore
                 <Text
                        x = {textX || 0}
                        y = {textY || 0}
                        text={length1 || null}
                        scaleY={-1}  
                        fontSize={20}
                        offsetX={20}
                        offsetY={10}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
            </>
        )
    }
    let buildAnchor6 = (x: number, y: number, name: string, key: number) => {
       return(
            <>
                <Circle key={key}
                        x = {x || 0}
                        y ={ y || 0}
                        radius = {8}
                        stroke = {'#666'}
                        fill = {'#ddd'}
                        strokeWidth = {2}
                        id = {name}
                        draggable = {true}
                        onClick = {() => {activeDott1(name, x, y)}}
                        onMouseOver = {onMouseOverHandler.bind(this)}
                        onMouseOut = {onMouseOutHandler.bind(this)}
                        onDragMove = {onDragMoveHandler6.bind(this)}
                        onDragEnd = {onDragEndHandler.bind(this)}
                />
            </>
        )
    }
    let buildSizes7 = (x: number, y: number, name: string, key: number, textX: any, textY: any) => {
        let length1: any;
        if(areas7.length > key + 1){
            length1 = ( Math.sqrt(Math.pow((areas7[key + 1].x - areas7[key].x), 2) + Math.pow((areas7[key + 1].y- areas7[key].y), 2))).toFixed(2)
        }
        if(areas7.length > 1 && areas7.length == key + 1){
            length1 = ( Math.sqrt(Math.pow((areas7[key].x - areas7[0].x), 2) + Math.pow((areas7[key].y- areas7[0].y), 2))).toFixed(2)
        }
        return( 
            <>
                {
                 areas7.length > key + 1 ? 
                 // @ts-ignore
                 <Text
                        x = {textX || 0}
                        y = {textY || 0}
                        text={length1 || null}
                        scaleY={-1}  
                        fontSize={20}
                        offsetX={20}
                        offsetY={10}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
                {
                 areas7.length > 1 && areas7.length == key + 1 && isLinesClosed1 ? 
                 // @ts-ignore
                 <Text
                        x = {textX || 0}
                        y = {textY || 0}
                        text={length1 || null}
                        scaleY={-1}  
                        fontSize={20}
                        offsetX={20}
                        offsetY={10}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
            </>
        )
    }
    let buildAnchor7 = (x: number, y: number, name: string, key: number) => {
        return(
            <>
                <Circle key={key}
                        x = {x || 0}
                        y ={ y || 0}
                        radius = {8}
                        stroke = {'#666'}
                        fill = {'#ddd'}
                        strokeWidth = {2}
                        id = {name}
                        draggable = {true}
                        onClick = {() => {activeDott1(name, x, y)}}
                        onMouseOver = {onMouseOverHandler.bind(this)}
                        onMouseOut = {onMouseOutHandler.bind(this)}
                        onDragMove = {onDragMoveHandler7.bind(this)}
                        onDragEnd = {onDragEndHandler.bind(this)}
                />
            </>
        )
    }
    let buildSizes8 = (x: number, y: number, name: string, key: number, textX: any, textY: any) => {
        let length1: any;
        if(areas8.length > key + 1){
            length1 = ( Math.sqrt(Math.pow((areas8[key + 1].x - areas8[key].x), 2) + Math.pow((areas8[key + 1].y- areas8[key].y), 2))).toFixed(2)
        }
        if(areas8.length > 1 && areas8.length == key + 1){
            length1 = ( Math.sqrt(Math.pow((areas8[key].x - areas8[0].x), 2) + Math.pow((areas8[key].y- areas8[0].y), 2))).toFixed(2)
        }
        return( 
            <>
                {
                 areas8.length > key + 1 ? 
                 // @ts-ignore
                 <Text
                        x = {textX || 0}
                        y = {textY || 0}
                        text={length1 || null}
                        scaleY={-1}  
                        fontSize={20}
                        offsetX={20}
                        offsetY={10}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
                {
                 areas8.length > 1 && areas8.length == key + 1 && isLinesClosed1 ? 
                 // @ts-ignore
                 <Text
                        x = {textX || 0}
                        y = {textY || 0}
                        text={length1 || null}
                        scaleY={-1}  
                        fontSize={20}
                        offsetX={20}
                        offsetY={10}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
            </>
        )
    }
    let buildAnchor8 = (x: number, y: number, name: string, key: number) => {
        return(
            <>
                <Circle key={key}
                        x = {x || 0}
                        y ={ y || 0}
                        radius = {8}
                        stroke = {'#666'}
                        fill = {'#ddd'}
                        strokeWidth = {2}
                        id = {name}
                        draggable = {true}
                        onClick = {() => {activeDott1(name, x, y)}}
                        onMouseOver = {onMouseOverHandler.bind(this)}
                        onMouseOut = {onMouseOutHandler.bind(this)}
                        onDragMove = {onDragMoveHandler8.bind(this)}
                        onDragEnd = {onDragEndHandler.bind(this)}
                />
            </>
        )
    }
    let buildSizes9 = (x: number, y: number, name: string, key: number, textX: any, textY: any) => {
        let length1: any;
        if(areas9.length > key + 1){
            length1 = ( Math.sqrt(Math.pow((areas9[key + 1].x - areas9[key].x), 2) + Math.pow((areas9[key + 1].y- areas9[key].y), 2))).toFixed(2)
        }
        if(areas9.length > 1 && areas9.length == key + 1){
            length1 = ( Math.sqrt(Math.pow((areas9[key].x - areas9[0].x), 2) + Math.pow((areas9[key].y- areas9[0].y), 2))).toFixed(2)
        }
        return( 
            <>
                {
                 areas9.length > key + 1 ? 
                 // @ts-ignore
                 <Text
                        x = {textX || 0}
                        y = {textY || 0}
                        text={length1 || null}
                        scaleY={-1}  
                        fontSize={20}
                        offsetX={20}
                        offsetY={10}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
                {
                 areas9.length > 1 && areas9.length == key + 1 && isLinesClosed1 ? 
                 // @ts-ignore
                 <Text
                        x = {textX || 0}
                        y = {textY || 0}
                        text={length1 || null}
                        scaleY={-1}  
                        fontSize={20}
                        offsetX={20}
                        offsetY={10}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
            </>
        )
    }
    let buildAnchor9 = (x: number, y: number, name: string, key: number) => {
        return(
            <>
                <Circle key={key}
                        x = {x || 0}
                        y ={ y || 0}
                        radius = {8}
                        stroke = {'#666'}
                        fill = {'#ddd'}
                        strokeWidth = {2}
                        id = {name}
                        draggable = {true}
                        onClick = {() => {activeDott1(name, x, y)}}
                        onMouseOver = {onMouseOverHandler.bind(this)}
                        onMouseOut = {onMouseOutHandler.bind(this)}
                        onDragMove = {onDragMoveHandler9.bind(this)}
                        onDragEnd = {onDragEndHandler.bind(this)}
                />
            </>
        )
    }
    let buildSizes10 = (x: number, y: number, name: string, key: number, textX: any, textY: any) => {
        let length1: any;
        if(areas10.length > key + 1){
            length1 = ( Math.sqrt(Math.pow((areas10[key + 1].x - areas10[key].x), 2) + Math.pow((areas10[key + 1].y- areas10[key].y), 2))).toFixed(2)
        }
        if(areas10.length > 1 && areas10.length == key + 1){
            length1 = ( Math.sqrt(Math.pow((areas10[key].x - areas10[0].x), 2) + Math.pow((areas10[key].y- areas10[0].y), 2))).toFixed(2)
        }
        return( 
            <>
                {
                 areas10.length > key + 1 ? 
                 // @ts-ignore
                 <Text
                        x = {textX || 0}
                        y = {textY || 0}
                        text={length1 || null}
                        scaleY={-1}  
                        fontSize={20}
                        offsetX={20}
                        offsetY={10}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
                {
                 areas10.length > 1 && areas10.length == key + 1 && isLinesClosed1 ? 
                 // @ts-ignore
                 <Text
                        x = {textX || 0}
                        y = {textY || 0}
                        text={length1 || null}
                        scaleY={-1}  
                        fontSize={20}
                        offsetX={20}
                        offsetY={10}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
            </>
        )
    }
    let buildAnchor10 = (x: number, y: number, name: string, key: number) => {
        return(
            <>
                <Circle key={key}
                        x = {x || 0}
                        y ={ y || 0}
                        radius = {8}
                        stroke = {'#666'}
                        fill = {'#ddd'}
                        strokeWidth = {2}
                        id = {name}
                        draggable = {true}
                        onClick = {() => {activeDott1(name, x, y)}}
                        onMouseOver = {onMouseOverHandler.bind(this)}
                        onMouseOut = {onMouseOutHandler.bind(this)}
                        onDragMove = {onDragMoveHandler10.bind(this)}
                        onDragEnd = {onDragEndHandler.bind(this)}
                />
            </>
        )
    }
    let buildSizes11 = (x: number, y: number, name: string, key: number, textX: any, textY: any) => {
        let length1: any;
        if(areas11.length > key + 1){
            length1 = ( Math.sqrt(Math.pow((areas11[key + 1].x - areas11[key].x), 2) + Math.pow((areas11[key + 1].y- areas11[key].y), 2))).toFixed(2)
        }
        if(areas11.length > 1 && areas11.length == key + 1){
            length1 = ( Math.sqrt(Math.pow((areas11[key].x - areas11[0].x), 2) + Math.pow((areas11[key].y- areas11[0].y), 2))).toFixed(2)
        }
        return( 
            <>
                {
                 areas11.length > key + 1 ? 
                 // @ts-ignore
                 <Text
                        x = {textX || 0}
                        y = {textY || 0}
                        text={length1 || null}
                        scaleY={-1}  
                        fontSize={20}
                        offsetX={20}
                        offsetY={10}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
                {
                 areas11.length > 1 && areas11.length == key + 1 && isLinesClosed1 ? 
                 // @ts-ignore
                 <Text
                        x = {textX || 0}
                        y = {textY || 0}
                        text={length1 || null}
                        scaleY={-1}  
                        fontSize={20}
                        offsetX={20}
                        offsetY={10}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
            </>
        )
    }
    let buildAnchor11 = (x: number, y: number, name: string, key: number) => {
        return(
            <>
                <Circle key={key}
                        x = {x || 0}
                        y ={ y || 0}
                        radius = {8}
                        stroke = {'#666'}
                        fill = {'#ddd'}
                        strokeWidth = {2}
                        id = {name}
                        draggable = {true}
                        onClick = {() => {activeDott1(name, x, y)}}
                        onMouseOver = {onMouseOverHandler.bind(this)}
                        onMouseOut = {onMouseOutHandler.bind(this)}
                        onDragMove = {onDragMoveHandler11.bind(this)}
                        onDragEnd = {onDragEndHandler.bind(this)}
                />
            </>
        )
    }
    let buildSizes12 = (x: number, y: number, name: string, key: number, textX: any, textY: any) => {
        let length1: any;
        if(areas12.length > key + 1){
            length1 = ( Math.sqrt(Math.pow((areas12[key + 1].x - areas12[key].x), 2) + Math.pow((areas12[key + 1].y- areas12[key].y), 2))).toFixed(2)
        }
        if(areas12.length > 1 && areas12.length == key + 1){
            length1 = ( Math.sqrt(Math.pow((areas12[key].x - areas12[0].x), 2) + Math.pow((areas12[key].y- areas12[0].y), 2))).toFixed(2)
        }
        return( 
            <>
                {
                 areas12.length > key + 1 ? 
                 // @ts-ignore
                 <Text
                        x = {textX || 0}
                        y = {textY || 0}
                        text={length1 || null}
                        scaleY={-1}  
                        fontSize={20}
                        offsetX={20}
                        offsetY={10}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
                {
                 areas12.length > 1 && areas12.length == key + 1 && isLinesClosed1 ? 
                 // @ts-ignore
                 <Text
                        x = {textX || 0}
                        y = {textY || 0}
                        text={length1 || null}
                        scaleY={-1}  
                        fontSize={20}
                        offsetX={20}
                        offsetY={10}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
            </>
        )
    }
    let buildAnchor12 = (x: number, y: number, name: string, key: number) => { 
        return(
            <>
                <Circle key={key}
                        x = {x || 0}
                        y ={ y || 0}
                        radius = {8}
                        stroke = {'#666'}
                        fill = {'#ddd'}
                        strokeWidth = {2}
                        id = {name}
                        draggable = {true}
                        onClick = {() => {activeDott1(name, x, y)}}
                        onMouseOver = {onMouseOverHandler.bind(this)}
                        onMouseOut = {onMouseOutHandler.bind(this)}
                        onDragMove = {onDragMoveHandler12.bind(this)}
                        onDragEnd = {onDragEndHandler.bind(this)}
                />
            </>
        )
    }
    let buildSizes13 = (x: number, y: number, name: string, key: number, textX: any, textY: any) => {
        let length1: any;
        if(areas13.length > key + 1){
            length1 = ( Math.sqrt(Math.pow((areas13[key + 1].x - areas13[key].x), 2) + Math.pow((areas13[key + 1].y- areas13[key].y), 2))).toFixed(2)
        }
        if(areas13.length > 1 && areas13.length == key + 1){
            length1 = ( Math.sqrt(Math.pow((areas13[key].x - areas13[0].x), 2) + Math.pow((areas13[key].y- areas13[0].y), 2))).toFixed(2)
        }
        return( 
            <>
                {
                 areas13.length > key + 1 ? 
                 // @ts-ignore
                 <Text
                        x = {textX || 0}
                        y = {textY || 0}
                        text={length1 || null}
                        scaleY={-1}  
                        fontSize={20}
                        offsetX={20}
                        offsetY={10}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
                {
                 areas13.length > 1 && areas13.length == key + 1 && isLinesClosed1 ? 
                 // @ts-ignore
                 <Text
                        x = {textX || 0}
                        y = {textY || 0}
                        text={length1 || null}
                        scaleY={-1}  
                        fontSize={20}
                        offsetX={20}
                        offsetY={10}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
            </>
        )
    }
    let buildAnchor13 = (x: number, y: number, name: string, key: number) => {
        return(
            <>
                <Circle key={key}
                        x = {x || 0}
                        y ={ y || 0}
                        radius = {8}
                        stroke = {'#666'}
                        fill = {'#ddd'}
                        strokeWidth = {2}
                        id = {name}
                        draggable = {true}
                        onClick = {() => {activeDott1(name, x, y)}}
                        onMouseOver = {onMouseOverHandler.bind(this)}
                        onMouseOut = {onMouseOutHandler.bind(this)}
                        onDragMove = {onDragMoveHandler13.bind(this)}
                        onDragEnd = {onDragEndHandler.bind(this)}
                />
            </>
        )
    }
    let buildSizes14 = (x: number, y: number, name: string, key: number, textX: any, textY: any) => {
        let length1: any;
        if(areas14.length > key + 1){
            length1 = ( Math.sqrt(Math.pow((areas14[key + 1].x - areas14[key].x), 2) + Math.pow((areas14[key + 1].y- areas14[key].y), 2))).toFixed(2)
        }
        if(areas14.length > 1 && areas14.length == key + 1){
            length1 = ( Math.sqrt(Math.pow((areas14[key].x - areas14[0].x), 2) + Math.pow((areas14[key].y- areas14[0].y), 2))).toFixed(2)
        }
        return( 
            <>
                {
                 areas14.length > key + 1 ? 
                 // @ts-ignore
                 <Text
                        x = {textX || 0}
                        y = {textY || 0}
                        text={length1 || null}
                        scaleY={-1}  
                        fontSize={20}
                        offsetX={20}
                        offsetY={10}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
                {
                 areas14.length > 1 && areas14.length == key + 1 && isLinesClosed1 ? 
                 // @ts-ignore
                 <Text
                        x = {textX || 0}
                        y = {textY || 0}
                        text={length1 || null}
                        scaleY={-1}  
                        fontSize={20}
                        offsetX={20}
                        offsetY={10}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
            </>
        )
    }
    let buildAnchor14 = (x: number, y: number, name: string, key: number) => {
        return(
            <>
                <Circle key={key}
                        x = {x || 0}
                        y ={ y || 0}
                        radius = {8}
                        stroke = {'#666'}
                        fill = {'#ddd'}
                        strokeWidth = {2}
                        id = {name}
                        draggable = {true}
                        onClick = {() => {activeDott1(name, x, y)}}
                        onMouseOver = {onMouseOverHandler.bind(this)}
                        onMouseOut = {onMouseOutHandler.bind(this)}
                        onDragMove = {onDragMoveHandler14.bind(this)}
                        onDragEnd = {onDragEndHandler.bind(this)}
                />
            </>
        )
    }
    let buildSizes15 = (x: number, y: number, name: string, key: number, textX: any, textY: any) => {
        let length1: any;
        if(areas15.length > key + 1){
            length1 = ( Math.sqrt(Math.pow((areas15[key + 1].x - areas15[key].x), 2) + Math.pow((areas15[key + 1].y- areas15[key].y), 2))).toFixed(2)
        }
        if(areas15.length > 1 && areas15.length == key + 1){
            length1 = ( Math.sqrt(Math.pow((areas15[key].x - areas15[0].x), 2) + Math.pow((areas15[key].y- areas15[0].y), 2))).toFixed(2)
        }
        return( 
            <>
                {
                 areas15.length > key + 1 ? 
                 // @ts-ignore
                 <Text
                        x = {textX || 0}
                        y = {textY || 0}
                        text={length1 || null}
                        scaleY={-1}  
                        fontSize={20}
                        offsetX={20}
                        offsetY={10}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
                {
                 areas15.length > 1 && areas15.length == key + 1 && isLinesClosed1 ? 
                 // @ts-ignore
                 <Text
                        x = {textX || 0}
                        y = {textY || 0}
                        text={length1 || null}
                        scaleY={-1}  
                        fontSize={20}
                        offsetX={20}
                        offsetY={10}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
            </>
        )
    }
    let buildAnchor15 = (x: number, y: number, name: string, key: number) => {
        return(
            <>
                <Circle key={key}
                        x = {x || 0}
                        y ={ y || 0}
                        radius = {8}
                        stroke = {'#666'}
                        fill = {'#ddd'}
                        strokeWidth = {2}
                        id = {name}
                        draggable = {true}
                        onClick = {() => {activeDott1(name, x, y)}}
                        onMouseOver = {onMouseOverHandler.bind(this)}
                        onMouseOut = {onMouseOutHandler.bind(this)}
                        onDragMove = {onDragMoveHandler15.bind(this)}
                        onDragEnd = {onDragEndHandler.bind(this)}
                />
            </>
        )
    }
    let buildSizes16 = (x: number, y: number, name: string, key: number, textX: any, textY: any) => {
        let length1: any;
        if(areas16.length > key + 1){
            length1 = ( Math.sqrt(Math.pow((areas16[key + 1].x - areas16[key].x), 2) + Math.pow((areas16[key + 1].y- areas16[key].y), 2))).toFixed(2)
        }
        if(areas16.length > 1 && areas16.length == key + 1){
            length1 = ( Math.sqrt(Math.pow((areas16[key].x - areas16[0].x), 2) + Math.pow((areas16[key].y- areas16[0].y), 2))).toFixed(2)
        }
        return( 
            <>
                {
                 areas16.length > key + 1 ? 
                 // @ts-ignore
                 <Text
                        x = {textX || 0}
                        y = {textY || 0}
                        text={length1 || null}
                        scaleY={-1}  
                        fontSize={20}
                        offsetX={20}
                        offsetY={10}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
                {
                 areas16.length > 1 && areas16.length == key + 1 && isLinesClosed1 ? 
                 // @ts-ignore
                 <Text
                        x = {textX || 0}
                        y = {textY || 0}
                        text={length1 || null}
                        scaleY={-1}  
                        fontSize={20}
                        offsetX={20}
                        offsetY={10}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
            </>
        )
    }
    let buildAnchor16 = (x: number, y: number, name: string, key: number) => {
        return(
            <>
                <Circle key={key}
                        x = {x || 0}
                        y ={ y || 0}
                        radius = {8}
                        stroke = {'#666'}
                        fill = {'#ddd'}
                        strokeWidth = {2}
                        id = {name}
                        draggable = {true}
                        onClick = {() => {activeDott1(name, x, y)}}
                        onMouseOver = {onMouseOverHandler.bind(this)}
                        onMouseOut = {onMouseOutHandler.bind(this)}
                        onDragMove = {onDragMoveHandler16.bind(this)}
                        onDragEnd = {onDragEndHandler.bind(this)}
                />
            </>
        )
    }
    let buildSizes17 = (x: number, y: number, name: string, key: number, textX: any, textY: any) => {
        let length1: any;
        if(areas17.length > key + 1){
            length1 = ( Math.sqrt(Math.pow((areas17[key + 1].x - areas17[key].x), 2) + Math.pow((areas17[key + 1].y- areas17[key].y), 2))).toFixed(2)
        }
        if(areas17.length > 1 && areas17.length == key + 1){
            length1 = ( Math.sqrt(Math.pow((areas17[key].x - areas17[0].x), 2) + Math.pow((areas17[key].y- areas17[0].y), 2))).toFixed(2)
        }
        return( 
            <>
                {
                 areas17.length > key + 1 ? 
                 // @ts-ignore
                 <Text
                        x = {textX || 0}
                        y = {textY || 0}
                        text={length1 || null}
                        scaleY={-1}  
                        fontSize={20}
                        offsetX={20}
                        offsetY={10}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
                {
                 areas17.length > 1 && areas17.length == key + 1 && isLinesClosed1 ? 
                 // @ts-ignore
                 <Text
                        x = {textX || 0}
                        y = {textY || 0}
                        text={length1 || null}
                        scaleY={-1}  
                        fontSize={20}
                        offsetX={20}
                        offsetY={10}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
            </>
        )
    }
    let buildAnchor17 = (x: number, y: number, name: string, key: number) => {
        return(
            <>
                <Circle key={key}
                        x = {x || 0}
                        y ={ y || 0}
                        radius = {8}
                        stroke = {'#666'}
                        fill = {'#ddd'}
                        strokeWidth = {2}
                        id = {name}
                        draggable = {true}
                        onClick = {() => {activeDott1(name, x, y)}}
                        onMouseOver = {onMouseOverHandler.bind(this)}
                        onMouseOut = {onMouseOutHandler.bind(this)}
                        onDragMove = {onDragMoveHandler17.bind(this)}
                        onDragEnd = {onDragEndHandler.bind(this)}
                />
            </>
        )
    }
    let buildSizes18 = (x: number, y: number, name: string, key: number, textX: any, textY: any) => {
        let length1: any;
        if(areas18.length > key + 1){
            length1 = ( Math.sqrt(Math.pow((areas18[key + 1].x - areas18[key].x), 2) + Math.pow((areas18[key + 1].y- areas18[key].y), 2))).toFixed(2)
        }
        if(areas18.length > 1 && areas18.length == key + 1){
            length1 = ( Math.sqrt(Math.pow((areas18[key].x - areas18[0].x), 2) + Math.pow((areas18[key].y- areas18[0].y), 2))).toFixed(2)
        }
        return( 
            <>
                {
                 areas18.length > key + 1 ? 
                 // @ts-ignore
                 <Text
                        x = {textX || 0}
                        y = {textY || 0}
                        text={length1 || null}
                        scaleY={-1}  
                        fontSize={20}
                        offsetX={20}
                        offsetY={10}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
                {
                 areas18.length > 1 && areas18.length == key + 1 && isLinesClosed1 ? 
                 // @ts-ignore
                 <Text
                        x = {textX || 0}
                        y = {textY || 0}
                        text={length1 || null}
                        scaleY={-1}  
                        fontSize={20}
                        offsetX={20}
                        offsetY={10}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
            </>
        )
    }
    let buildAnchor18 = (x: number, y: number, name: string, key: number) => {
        return(
            <>
                <Circle key={key}
                        x = {x || 0}
                        y ={ y || 0}
                        radius = {8}
                        stroke = {'#666'}
                        fill = {'#ddd'}
                        strokeWidth = {2}
                        id = {name}
                        draggable = {true}
                        onClick = {() => {activeDott1(name, x, y)}}
                        onMouseOver = {onMouseOverHandler.bind(this)}
                        onMouseOut = {onMouseOutHandler.bind(this)}
                        onDragMove = {onDragMoveHandler18.bind(this)}
                        onDragEnd = {onDragEndHandler.bind(this)}
                />
            </>
        )
    }
    let buildSizes19 = (x: number, y: number, name: string, key: number, textX: any, textY: any) => {
        let length1: any;
        if(areas19.length > key + 1){
            length1 = ( Math.sqrt(Math.pow((areas19[key + 1].x - areas19[key].x), 2) + Math.pow((areas19[key + 1].y- areas19[key].y), 2))).toFixed(2)
        }
        if(areas19.length > 1 && areas19.length == key + 1){
            length1 = ( Math.sqrt(Math.pow((areas19[key].x - areas19[0].x), 2) + Math.pow((areas19[key].y- areas19[0].y), 2))).toFixed(2)
        }
        return( 
            <>
                {
                 areas19.length > key + 1 ? 
                 // @ts-ignore
                 <Text
                        x = {textX || 0}
                        y = {textY || 0}
                        text={length1 || null}
                        scaleY={-1}  
                        fontSize={20}
                        offsetX={20}
                        offsetY={10}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
                {
                 areas19.length > 1 && areas19.length == key + 1 && isLinesClosed1 ? 
                 // @ts-ignore
                 <Text
                        x = {textX || 0}
                        y = {textY || 0}
                        text={length1 || null}
                        scaleY={-1}  
                        fontSize={20}
                        offsetX={20}
                        offsetY={10}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
            </>
        )
    }
    let buildAnchor19 = (x: number, y: number, name: string, key: number) => {
        return(
            <>
                <Circle key={key}
                        x = {x || 0}
                        y ={ y || 0}
                        radius = {8}
                        stroke = {'#666'}
                        fill = {'#ddd'}
                        strokeWidth = {2}
                        id = {name}
                        draggable = {true}
                        onClick = {() => {activeDott1(name, x, y)}}
                        onMouseOver = {onMouseOverHandler.bind(this)}
                        onMouseOut = {onMouseOutHandler.bind(this)}
                        onDragMove = {onDragMoveHandler19.bind(this)}
                        onDragEnd = {onDragEndHandler.bind(this)}
                />
            </>
        )
    }
    let buildSizes20 = (x: number, y: number, name: string, key: number, textX: any, textY: any) => {
        let length1: any;
        if(areas20.length > key + 1){
            length1 = ( Math.sqrt(Math.pow((areas20[key + 1].x - areas20[key].x), 2) + Math.pow((areas20[key + 1].y- areas20[key].y), 2))).toFixed(2)
        }
        if(areas20.length > 1 && areas20.length == key + 1){
            length1 = ( Math.sqrt(Math.pow((areas20[key].x - areas20[0].x), 2) + Math.pow((areas20[key].y- areas20[0].y), 2))).toFixed(2)
        }
        return( 
            <>
                {
                 areas20.length > key + 1 ? 
                 // @ts-ignore
                 <Text
                        x = {textX || 0}
                        y = {textY || 0}
                        text={length1 || null}
                        scaleY={-1}  
                        fontSize={20}
                        offsetX={20}
                        offsetY={10}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
                {
                 areas20.length > 1 && areas20.length == key + 1 && isLinesClosed1 ? 
                 // @ts-ignore
                 <Text
                        x = {textX || 0}
                        y = {textY || 0}
                        text={length1 || null}
                        scaleY={-1}  
                        fontSize={20}
                        offsetX={20}
                        offsetY={10}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
            </>
        )
    }
    let buildAnchor20 = (x: number, y: number, name: string, key: number) => {
        return(
            <>
                <Circle key={key}
                        x = {x || 0}
                        y ={ y || 0}
                        radius = {8}
                        stroke = {'#666'}
                        fill = {'#ddd'}
                        strokeWidth = {2}
                        id = {name}
                        draggable = {true}
                        onClick = {() => {activeDott1(name, x, y)}}
                        onMouseOver = {onMouseOverHandler.bind(this)}
                        onMouseOut = {onMouseOutHandler.bind(this)}
                        onDragMove = {onDragMoveHandler20.bind(this)}
                        onDragEnd = {onDragEndHandler.bind(this)}
                />
            </>
        )
    }

    
    let buildAnchor1Copy = (x: number, y: number, name: string, key: number) => {
        let length1: any;
        let length2: any;
        // @ts-ignore
        let bottomDott: any = areas1Copy[0].y;
        // @ts-ignore
        let topDott: any = areas1Copy[0].y;
        // @ts-ignore
        let rightDott: any = areas1Copy[0].x;
        // @ts-ignore
        let leftDott: any = areas1Copy[0].x;
        for (let i = 0; i < areas1Copy.length; i++) {
            if(areas1Copy[i].y < bottomDott){
                bottomDott = areas1Copy[i].y
            }
            if(areas1Copy[i].y > topDott){
                topDott = areas1Copy[i].y
            }
            if(areas1Copy[i].x > rightDott){
                rightDott = areas1Copy[i].x
            }
            if(areas1Copy[i].x < leftDott){
                leftDott = areas1Copy[i].x
            }
        }
        if(areas1Copy.length > 1 && key > 0){
            // @ts-ignore
            length1 = ( Math.sqrt(Math.pow((areas1Copy[key].x - areas1Copy[key - 1].x), 2) + Math.pow((areas1Copy[key].y - areas1Copy[key - 1].y), 2)) ).toFixed(2)
        }
        if(areas1Copy.length > 2 && key == 0){
            // @ts-ignore
            length2 = ( Math.sqrt(Math.pow((areas1Copy[key].x) - (areas1Copy[areas1Copy.length - 1].x), 2) + Math.pow((areas1Copy[key].y - areas1Copy[areas1Copy.length - 1].y), 2)) ).toFixed(2)
        } 
        return(
            <>
                <Circle key={key}
                        x = {x || 0}
                        y ={ y || 0}
                        radius = {8}
                        stroke = {'#666'}
                        fill = {'#ddd'}
                        strokeWidth = {2}
                        id = {name}
                        // @ts-ignore 
                        onClick = {() => {activeDott3(x, y, name, 1, turnDottArea)}}
                        draggable={true}
                        onDragEnd = {onDragEndHandler.bind(this)}
                        onDragMove={onDragMoveHandler1Copy.bind(this)}
                        onMouseOver = {onMouseOverHandler.bind(this)}
                        onMouseOut = {onMouseOutHandler.bind(this)}
                        
                />
                 <Text  x = {getXCoord((leftDott + rightDott) / 2) || 0}
                        // @ts-ignore 
                        y ={getYCoord((topDott + bottomDott) / 2) || 0} 
                        text={"1"}
                        fill={turnDottAreaText == 1 ? "green" : "black" }
                        onClick = {() => {setTurnDottAreaText(turnDottAreaText == 1 ? 0 : 1)}}
                        fontSize={20}
                        scaleY={-1}
                /> 
                {
                    areas1Copy.length > 1 && key > 0 ? 
                    <Text  x = {((getXCoord(areas1Copy[key].x) + getXCoord(areas1Copy[key - 1].x)) / 2)|| 0}
                        // @ts-ignore 
                        y ={((getYCoord(areas1Copy[key].y) + getYCoord(areas1Copy[key - 1].y)) / 2)|| 0}
                        text={length1.toString()}
                        scaleY={-1}  
                        fontSize={20}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                    : null
                }
                {
                 areas1Copy.length > 2 && key == 0 && isLinesClosed1 ? 
                 // @ts-ignore
                 <Text  x = {((getXCoord(areas1Copy[key].x) + getXCoord(areas1Copy[areas1Copy.length - 1].x)) / 2) || 0}
                        // @ts-ignore 
                        y ={((getYCoord(areas1Copy[key].y) + getYCoord(areas1Copy[areas1Copy.length - 1].y)) / 2) || 0} 
                        text={length2.toString()}
                        scaleY={-1}  
                        fontSize={20}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
            </>
        )
    }
    let buildAnchor2Copy = (x: number, y: number, name: string, key: number) => {
        let length1: any;
        let length2: any;
        // @ts-ignore
        let bottomDott: any = areas2Copy[0].y;
        // @ts-ignore
        let topDott: any = areas2Copy[0].y;
        // @ts-ignore
        let rightDott: any = areas2Copy[0].x;
        // @ts-ignore
        let leftDott: any = areas2Copy[0].x;
        for (let i = 0; i < areas2Copy.length; i++) {
            if(areas2Copy[i].y < bottomDott){
                bottomDott = areas2Copy[i].y
            }
            if(areas2Copy[i].y > topDott){
                topDott = areas2Copy[i].y
            }
            if(areas2Copy[i].x > rightDott){
                rightDott = areas2Copy[i].x
            }
            if(areas2Copy[i].x < leftDott){
                leftDott = areas2Copy[i].x
            }
        }
        if(areas2Copy.length > 1 && key > 0){
            // @ts-ignore
            length1 = ( Math.sqrt(Math.pow((areas2Copy[key].x - areas2Copy[key - 1].x), 2) + Math.pow((areas2Copy[key].y - areas2Copy[key - 1].y), 2)) ).toFixed(2)
        }
        if(areas2Copy.length > 2 && key == 0){
            // @ts-ignore
            length2 = ( Math.sqrt(Math.pow((areas2Copy[key].x) - (areas2Copy[areas2Copy.length - 1].x), 2) + Math.pow((areas2Copy[key].y - areas2Copy[areas2Copy.length - 1].y), 2)) ).toFixed(2)
        } 
        return(
            <>
                <Circle key={key}
                        x = {x || 0}
                        y ={ y || 0}
                        radius = {8}
                        stroke = {'#666'}
                        fill = {'#ddd'}
                        strokeWidth = {2}
                        id = {name}
                        // @ts-ignore 
                        onClick = {() => {activeDott3(x, y, name, 2, turnDottArea)}}
                        draggable={true}
                        onDragEnd = {onDragEndHandler.bind(this)}
                        onDragMove={onDragMoveHandler2Copy.bind(this)}
                        onMouseOver = {onMouseOverHandler.bind(this)}
                        onMouseOut = {onMouseOutHandler.bind(this)}
                />
                <Text  x = {getXCoord((leftDott + rightDott) / 2) || 0}
                        // @ts-ignore 
                        y ={getYCoord((topDott + bottomDott) / 2) || 0} 
                        fill={turnDottAreaText == 2 ? "green" : "black" }
                        onClick = {() => {setTurnDottAreaText(turnDottAreaText == 2 ? 0 : 2)}}
                        text={"2"}
                        fontSize={20}
                        scaleY={-1}
                />
                {
                    areas2Copy.length > 1 && key > 0 ? 
                    <Text  x = {((getXCoord(areas2Copy[key].x) + getXCoord(areas2Copy[key - 1].x)) / 2)|| 0}
                        // @ts-ignore 
                        y ={((getYCoord(areas2Copy[key].y) + getYCoord(areas2Copy[key - 1].y)) / 2)|| 0}
                        text={length1.toString()}
                        scaleY={-1}  
                        fontSize={20}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                    : null
                }
                {
                 areas2Copy.length > 2 && key == 0 && isLinesClosed1 ? 
                 // @ts-ignore
                 <Text  x = {((getXCoord(areas2Copy[key].x) + getXCoord(areas2Copy[areas2Copy.length - 1].x)) / 2) || 0}
                        // @ts-ignore 
                        y ={((getYCoord(areas2Copy[key].y) + getYCoord(areas2Copy[areas2Copy.length - 1].y)) / 2) || 0} 
                        text={length2.toString()}
                        scaleY={-1}  
                        fontSize={20}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
            </>
        )
    }
    let buildAnchor3Copy = (x: number, y: number, name: string, key: number) => {
        let length1: any;
        let length2: any;
        // @ts-ignore
        let bottomDott: any = areas3Copy[0].y;
        // @ts-ignore
        let topDott: any = areas3Copy[0].y;
        // @ts-ignore
        let rightDott: any = areas3Copy[0].x;
        // @ts-ignore
        let leftDott: any = areas3Copy[0].x;
        for (let i = 0; i < areas3Copy.length; i++) {
            if(areas3Copy[i].y < bottomDott){
                bottomDott = areas3Copy[i].y
            }
            if(areas3Copy[i].y > topDott){
                topDott = areas3Copy[i].y
            }
            if(areas3Copy[i].x > rightDott){
                rightDott = areas3Copy[i].x
            }
            if(areas3Copy[i].x < leftDott){
                leftDott = areas3Copy[i].x
            }
        }
        if(areas3Copy.length > 1 && key > 0){
            // @ts-ignore
            length1 = ( Math.sqrt(Math.pow((areas3Copy[key].x - areas3Copy[key - 1].x), 2) + Math.pow((areas3Copy[key].y - areas3Copy[key - 1].y), 2)) ).toFixed(2)
        }
        if(areas3Copy.length > 2 && key == 0){
            // @ts-ignore
            length2 = ( Math.sqrt(Math.pow((areas3Copy[key].x) - (areas3Copy[areas3Copy.length - 1].x), 2) + Math.pow((areas3Copy[key].y - areas3Copy[areas3Copy.length - 1].y), 2)) ).toFixed(2)
        } 
        return(
            <>
                <Circle key={key}
                        x = {x || 0}
                        y ={ y || 0}
                        radius = {8}
                        stroke = {'#666'}
                        fill = {'#ddd'}
                        strokeWidth = {2}
                        id = {name}
                        
                        // @ts-ignore 
                        onClick = {() => {activeDott3(x, y, name, 3, turnDottArea)}}
                        onMouseOver = {onMouseOverHandler.bind(this)}
                        onMouseOut = {onMouseOutHandler.bind(this)}
                        draggable={true}
                        onDragEnd = {onDragEndHandler.bind(this)}
                        onDragMove={onDragMoveHandler3Copy.bind(this)}
                        
                />
                <Text  x = {getXCoord((leftDott + rightDott) / 2) || 0}
                        // @ts-ignore 
                        y ={getYCoord((topDott + bottomDott) / 2) || 0} 
                        fill={turnDottAreaText == 3 ? "green" : "black" }
                        onClick = {() => {setTurnDottAreaText(turnDottAreaText == 3 ? 0 : 3)}}
                        text={"3"}
                        fontSize={20}
                        scaleY={-1}
                />
                {
                    areas3Copy.length > 1 && key > 0 ? 
                    <Text  x = {((getXCoord(areas3Copy[key].x) + getXCoord(areas3Copy[key - 1].x)) / 2)|| 0}
                        // @ts-ignore 
                        y ={((getYCoord(areas3Copy[key].y) + getYCoord(areas3Copy[key - 1].y)) / 2)|| 0}
                        text={length1.toString()}
                        scaleY={-1}  
                        fontSize={20}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                    : null
                }
                {
                 areas3Copy.length > 2 && key == 0 && isLinesClosed1 ? 
                 // @ts-ignore
                 <Text  x = {((getXCoord(areas3Copy[key].x) + getXCoord(areas3Copy[areas3Copy.length - 1].x)) / 2) || 0}
                        // @ts-ignore 
                        y ={((getYCoord(areas3Copy[key].y) + getYCoord(areas3Copy[areas3Copy.length - 1].y)) / 2) || 0} 
                        text={length2.toString()}
                        scaleY={-1}  
                        fontSize={20}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
            </>
        )
    }
    let buildAnchor4Copy = (x: number, y: number, name: string, key: number) => {
        let length1: any;
        let length2: any;
        // @ts-ignore
        let bottomDott: any = areas4Copy[0].y;
        // @ts-ignore
        let topDott: any = areas4Copy[0].y;
        // @ts-ignore
        let rightDott: any = areas4Copy[0].x;
        // @ts-ignore
        let leftDott: any = areas4Copy[0].x;
        for (let i = 0; i < areas4Copy.length; i++) {
            if(areas4Copy[i].y < bottomDott){
                bottomDott = areas4Copy[i].y
            }
            if(areas4Copy[i].y > topDott){
                topDott = areas4Copy[i].y
            }
            if(areas4Copy[i].x > rightDott){
                rightDott = areas4Copy[i].x
            }
            if(areas4Copy[i].x < leftDott){
                leftDott = areas4Copy[i].x
            }
        }
        if(areas4Copy.length > 1 && key > 0){
            // @ts-ignore
            length1 = ( Math.sqrt(Math.pow((areas4Copy[key].x - areas4Copy[key - 1].x), 2) + Math.pow((areas4Copy[key].y - areas4Copy[key - 1].y), 2)) ).toFixed(2)
        }
        if(areas4Copy.length > 2 && key == 0){
            // @ts-ignore
            length2 = ( Math.sqrt(Math.pow((areas4Copy[key].x) - (areas4Copy[areas4Copy.length - 1].x), 2) + Math.pow((areas4Copy[key].y - areas4Copy[areas4Copy.length - 1].y), 2)) ).toFixed(2)
        } 
        return(
            <>
                <Circle key={key}
                        x = {x || 0}
                        y ={ y || 0}
                        radius = {8}
                        stroke = {'#666'}
                        fill = {'#ddd'}
                        strokeWidth = {2}
                        id = {name}
                        
                        // @ts-ignore 
                        onClick = {() => {activeDott3(x, y, name, 4)}}
                        onMouseOver = {onMouseOverHandler.bind(this)}
                        onMouseOut = {onMouseOutHandler.bind(this)}
                        draggable={true}
                        onDragEnd = {onDragEndHandler.bind(this)}
                        onDragMove={onDragMoveHandler4Copy.bind(this)}
                        
                />
                <Text  x = {getXCoord((leftDott + rightDott) / 2) || 0}
                        // @ts-ignore 
                        y ={getYCoord((topDott + bottomDott) / 2) || 0} 
                        fill={turnDottAreaText == 4 ? "green" : "black" }
                        onClick = {() => {setTurnDottAreaText(turnDottAreaText == 4 ? 0 : 4)}}
                        text={"4"}
                        fontSize={20}
                        scaleY={-1}
                />
                {
                    areas4Copy.length > 1 && key > 0 ? 
                    <Text  x = {((getXCoord(areas4Copy[key].x) + getXCoord(areas4Copy[key - 1].x)) / 2)|| 0}
                        // @ts-ignore 
                        y ={((getYCoord(areas4Copy[key].y) + getYCoord(areas4Copy[key - 1].y)) / 2)|| 0}
                        text={length1.toString()}
                        scaleY={-1}  
                        fontSize={20}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                    : null
                }
                {
                 areas4Copy.length > 2 && key == 0 && isLinesClosed1 ? 
                 // @ts-ignore
                 <Text  x = {((getXCoord(areas4Copy[key].x) + getXCoord(areas4Copy[areas4Copy.length - 1].x)) / 2) || 0}
                        // @ts-ignore 
                        y ={((getYCoord(areas4Copy[key].y) + getYCoord(areas4Copy[areas4Copy.length - 1].y)) / 2) || 0} 
                        text={length2.toString()}
                        scaleY={-1}  
                        fontSize={20}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
            </>
        )
    }
    let buildAnchor5Copy = (x: number, y: number, name: string, key: number) => {
        let length1: any;
        let length2: any;
        // @ts-ignore
        let bottomDott: any = areas5Copy[0].y;
        // @ts-ignore
        let topDott: any = areas5Copy[0].y;
        // @ts-ignore
        let rightDott: any = areas5Copy[0].x;
        // @ts-ignore
        let leftDott: any = areas5Copy[0].x;
        for (let i = 0; i < areas5Copy.length; i++) {
            if(areas5Copy[i].y < bottomDott){
                bottomDott = areas5Copy[i].y
            }
            if(areas5Copy[i].y > topDott){
                topDott = areas5Copy[i].y
            }
            if(areas5Copy[i].x > rightDott){
                rightDott = areas5Copy[i].x
            }
            if(areas5Copy[i].x < leftDott){
                leftDott = areas5Copy[i].x
            }
        }
        if(areas5Copy.length > 1 && key > 0){
            // @ts-ignore
            length1 = ( Math.sqrt(Math.pow((areas5Copy[key].x - areas5Copy[key - 1].x), 2) + Math.pow((areas5Copy[key].y - areas5Copy[key - 1].y), 2)) ).toFixed(2)
        }
        if(areas5Copy.length > 2 && key == 0){
            // @ts-ignore
            length2 = ( Math.sqrt(Math.pow((areas5Copy[key].x) - (areas5Copy[areas5Copy.length - 1].x), 2) + Math.pow((areas5Copy[key].y - areas5Copy[areas5Copy.length - 1].y), 2)) ).toFixed(2)
        } 
        return(
            <>
                <Circle key={key}
                        x = {x || 0}
                        y ={ y || 0}
                        radius = {8}
                        stroke = {'#666'}
                        fill = {'#ddd'}
                        strokeWidth = {2}
                        id = {name}
                        
                        // @ts-ignore 
                        onClick = {() => {activeDott3(x, y, name, 5)}}
                        onMouseOver = {onMouseOverHandler.bind(this)}
                        onMouseOut = {onMouseOutHandler.bind(this)}
                        draggable={true}
                        onDragEnd = {onDragEndHandler.bind(this)}
                        onDragMove={onDragMoveHandler5Copy.bind(this)}
                        
                />
                <Text  x = {getXCoord((leftDott + rightDott) / 2) || 0}
                        // @ts-ignore 
                        y ={getYCoord((topDott + bottomDott) / 2) || 0} 
                        fill={turnDottAreaText == 5 ? "green" : "black" }
                        onClick = {() => {setTurnDottAreaText(turnDottAreaText == 5 ? 0 : 5)}}
                        text={"5"}
                        fontSize={20}
                        scaleY={-1}
                />
                {
                    areas5Copy.length > 1 && key > 0 ? 
                    <Text  x = {((getXCoord(areas5Copy[key].x) + getXCoord(areas5Copy[key - 1].x)) / 2)|| 0}
                        // @ts-ignore 
                        y ={((getYCoord(areas5Copy[key].y) + getYCoord(areas5Copy[key - 1].y)) / 2)|| 0}
                        text={length1.toString()}
                        scaleY={-1}  
                        fontSize={20}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                    : null
                }
                {
                 areas5Copy.length > 2 && key == 0 && isLinesClosed1 ? 
                 // @ts-ignore
                 <Text  x = {((getXCoord(areas5Copy[key].x) + getXCoord(areas5Copy[areas5Copy.length - 1].x)) / 2) || 0}
                        // @ts-ignore 
                        y ={((getYCoord(areas5Copy[key].y) + getYCoord(areas5Copy[areas5Copy.length - 1].y)) / 2) || 0} 
                        text={length2.toString()}
                        scaleY={-1}  
                        fontSize={20}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
            </>
        )
    }
    let buildAnchor6Copy = (x: number, y: number, name: string, key: number) => {
        let length1: any;
        let length2: any;
        // @ts-ignore
        let bottomDott: any = areas6Copy[0].y;
        // @ts-ignore
        let topDott: any = areas6Copy[0].y;
        // @ts-ignore
        let rightDott: any = areas6Copy[0].x;
        // @ts-ignore
        let leftDott: any = areas6Copy[0].x;
        for (let i = 0; i < areas6Copy.length; i++) {
            if(areas6Copy[i].y < bottomDott){
                bottomDott = areas6Copy[i].y
            }
            if(areas6Copy[i].y > topDott){
                topDott = areas6Copy[i].y
            }
            if(areas6Copy[i].x > rightDott){
                rightDott = areas6Copy[i].x
            }
            if(areas6Copy[i].x < leftDott){
                leftDott = areas6Copy[i].x
            }
        }
        if(areas6Copy.length > 1 && key > 0){
            // @ts-ignore
            length1 = ( Math.sqrt(Math.pow((areas6Copy[key].x - areas6Copy[key - 1].x), 2) + Math.pow((areas6Copy[key].y - areas6Copy[key - 1].y), 2)) ).toFixed(2)
        }
        if(areas6Copy.length > 2 && key == 0){
            // @ts-ignore
            length2 = ( Math.sqrt(Math.pow((areas6Copy[key].x) - (areas6Copy[areas6Copy.length - 1].x), 2) + Math.pow((areas6Copy[key].y - areas6Copy[areas6Copy.length - 1].y), 2)) ).toFixed(2)
        } 
        return(
            <>
                <Circle key={key}
                        x = {x || 0}
                        y ={ y || 0}
                        radius = {8}
                        stroke = {'#666'}
                        fill = {'#ddd'}
                        strokeWidth = {2}
                        id = {name}
                        
                        // @ts-ignore 
                        onClick = {() => {activeDott3(x, y, name, 6)}}
                        onMouseOver = {onMouseOverHandler.bind(this)}
                        onMouseOut = {onMouseOutHandler.bind(this)}
                        draggable={true}
                        onDragEnd = {onDragEndHandler.bind(this)}
                        onDragMove={onDragMoveHandler6Copy.bind(this)}
                        
                />
                <Text  x = {getXCoord((leftDott + rightDott) / 2) || 0}
                        // @ts-ignore 
                        y ={getYCoord((topDott + bottomDott) / 2) || 0} 
                        fill={turnDottAreaText == 6 ? "green" : "black" }
                        onClick = {() => {setTurnDottAreaText(turnDottAreaText == 6 ? 0 : 6)}}
                        text={"6"}
                        fontSize={20}
                        scaleY={-1}
                />
                {
                    areas6Copy.length > 1 && key > 0 ? 
                    <Text  x = {((getXCoord(areas6Copy[key].x) + getXCoord(areas6Copy[key - 1].x)) / 2)|| 0}
                        // @ts-ignore 
                        y ={((getYCoord(areas6Copy[key].y) + getYCoord(areas6Copy[key - 1].y)) / 2)|| 0}
                        text={length1.toString()}
                        scaleY={-1}  
                        fontSize={20}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                    : null
                }
                {
                 areas6Copy.length > 2 && key == 0 && isLinesClosed1 ? 
                 // @ts-ignore
                 <Text  x = {((getXCoord(areas6Copy[key].x) + getXCoord(areas6Copy[areas6Copy.length - 1].x)) / 2) || 0}
                        // @ts-ignore 
                        y ={((getYCoord(areas6Copy[key].y) + getYCoord(areas6Copy[areas6Copy.length - 1].y)) / 2) || 0} 
                        text={length2.toString()}
                        scaleY={-1}  
                        fontSize={20}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
            </>
        )
    }
    let buildAnchor7Copy = (x: number, y: number, name: string, key: number) => {
        let length1: any;
        let length2: any;
        // @ts-ignore
        let bottomDott: any = areas7Copy[0].y;
        // @ts-ignore
        let topDott: any = areas7Copy[0].y;
        // @ts-ignore
        let rightDott: any = areas7Copy[0].x;
        // @ts-ignore
        let leftDott: any = areas7Copy[0].x;
        for (let i = 0; i < areas7Copy.length; i++) {
            if(areas7Copy[i].y < bottomDott){
                bottomDott = areas7Copy[i].y
            }
            if(areas7Copy[i].y > topDott){
                topDott = areas7Copy[i].y
            }
            if(areas7Copy[i].x > rightDott){
                rightDott = areas7Copy[i].x
            }
            if(areas7Copy[i].x < leftDott){
                leftDott = areas7Copy[i].x
            }
        }
        if(areas7Copy.length > 1 && key > 0){
            // @ts-ignore
            length1 = ( Math.sqrt(Math.pow((areas7Copy[key].x - areas7Copy[key - 1].x), 2) + Math.pow((areas7Copy[key].y - areas7Copy[key - 1].y), 2)) ).toFixed(2)
        }
        if(areas7Copy.length > 2 && key == 0){
            // @ts-ignore
            length2 = ( Math.sqrt(Math.pow((areas7Copy[key].x) - (areas7Copy[areas7Copy.length - 1].x), 2) + Math.pow((areas7Copy[key].y - areas7Copy[areas7Copy.length - 1].y), 2)) ).toFixed(2)
        } 
        return(
            <>
                <Circle key={key}
                        x = {x || 0}
                        y ={ y || 0}
                        radius = {8}
                        stroke = {'#666'}
                        fill = {'#ddd'}
                        strokeWidth = {2}
                        id = {name}
                        
                        // @ts-ignore 
                        onClick = {() => {activeDott3(x, y, name, 7)}}
                        onMouseOver = {onMouseOverHandler.bind(this)}
                        onMouseOut = {onMouseOutHandler.bind(this)}
                        draggable={true}
                        onDragEnd = {onDragEndHandler.bind(this)}
                        onDragMove={onDragMoveHandler7Copy.bind(this)}
                        
                />
                <Text  x = {getXCoord((leftDott + rightDott) / 2) || 0}
                        // @ts-ignore 
                        y ={getYCoord((topDott + bottomDott) / 2) || 0} 
                        fill={turnDottAreaText == 7 ? "green" : "black" }
                        onClick = {() => {setTurnDottAreaText(turnDottAreaText == 7 ? 0 : 7)}}
                        text={"7"}
                        fontSize={20}
                        scaleY={-1}
                />
                {
                    areas7Copy.length > 1 && key > 0 ? 
                    <Text  x = {((getXCoord(areas7Copy[key].x) + getXCoord(areas7Copy[key - 1].x)) / 2)|| 0}
                        // @ts-ignore 
                        y ={((getYCoord(areas7Copy[key].y) + getYCoord(areas7Copy[key - 1].y)) / 2)|| 0}
                        text={length1.toString()}
                        scaleY={-1}  
                        fontSize={20}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                    : null
                }
                {
                 areas7Copy.length > 2 && key == 0 && isLinesClosed1 ? 
                 // @ts-ignore
                 <Text  x = {((getXCoord(areas7Copy[key].x) + getXCoord(areas7Copy[areas7Copy.length - 1].x)) / 2) || 0}
                        // @ts-ignore 
                        y ={((getYCoord(areas7Copy[key].y) + getYCoord(areas7Copy[areas7Copy.length - 1].y)) / 2) || 0} 
                        text={length2.toString()}
                        scaleY={-1}  
                        fontSize={20}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
            </>
        )
    }
    let buildAnchor8Copy = (x: number, y: number, name: string, key: number) => {
        let length1: any;
        let length2: any;
        // @ts-ignore
        let bottomDott: any = areas8Copy[0].y;
        // @ts-ignore
        let topDott: any = areas8Copy[0].y;
        // @ts-ignore
        let rightDott: any = areas8Copy[0].x;
        // @ts-ignore
        let leftDott: any = areas8Copy[0].x;
        for (let i = 0; i < areas8Copy.length; i++) {
            if(areas8Copy[i].y < bottomDott){
                bottomDott = areas8Copy[i].y
            }
            if(areas8Copy[i].y > topDott){
                topDott = areas8Copy[i].y
            }
            if(areas8Copy[i].x > rightDott){
                rightDott = areas8Copy[i].x
            }
            if(areas8Copy[i].x < leftDott){
                leftDott = areas8Copy[i].x
            }
        }
        if(areas8Copy.length > 1 && key > 0){
            // @ts-ignore
            length1 = ( Math.sqrt(Math.pow((areas8Copy[key].x - areas8Copy[key - 1].x), 2) + Math.pow((areas8Copy[key].y - areas8Copy[key - 1].y), 2)) ).toFixed(2)
        }
        if(areas8Copy.length > 2 && key == 0){
            // @ts-ignore
            length2 = ( Math.sqrt(Math.pow((areas8Copy[key].x) - (areas8Copy[areas8Copy.length - 1].x), 2) + Math.pow((areas8Copy[key].y - areas8Copy[areas8Copy.length - 1].y), 2)) ).toFixed(2)
        } 
        return(
            <>
                <Circle key={key}
                        x = {x || 0}
                        y ={ y || 0}
                        radius = {8}
                        stroke = {'#666'}
                        fill = {'#ddd'}
                        strokeWidth = {2}
                        id = {name}
                        
                        // @ts-ignore 
                        onClick = {() => {activeDott3(x, y, name, 8)}}
                        onMouseOver = {onMouseOverHandler.bind(this)}
                        onMouseOut = {onMouseOutHandler.bind(this)}
                        draggable={true}
                        onDragEnd = {onDragEndHandler.bind(this)}
                        onDragMove={onDragMoveHandler8Copy.bind(this)}
                        
                />
                <Text  x = {getXCoord((leftDott + rightDott) / 2) || 0}
                        // @ts-ignore 
                        y ={getYCoord((topDott + bottomDott) / 2) || 0} 
                        fill={turnDottAreaText == 8 ? "green" : "black" }
                        onClick = {() => {setTurnDottAreaText(turnDottAreaText == 8 ? 0 : 8)}}
                        text={"8"}
                        fontSize={20}
                        scaleY={-1}
                />
                {
                    areas8Copy.length > 1 && key > 0 ? 
                    <Text  x = {((getXCoord(areas8Copy[key].x) + getXCoord(areas8Copy[key - 1].x)) / 2)|| 0}
                        // @ts-ignore 
                        y ={((getYCoord(areas8Copy[key].y) + getYCoord(areas8Copy[key - 1].y)) / 2)|| 0}
                        text={length1.toString()}
                        scaleY={-1}  
                        fontSize={20}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                    : null
                }
                {
                 areas8Copy.length > 2 && key == 0 && isLinesClosed1 ? 
                 // @ts-ignore
                 <Text  x = {((getXCoord(areas8Copy[key].x) + getXCoord(areas8Copy[areas8Copy.length - 1].x)) / 2) || 0}
                        // @ts-ignore 
                        y ={((getYCoord(areas8Copy[key].y) + getYCoord(areas8Copy[areas8Copy.length - 1].y)) / 2) || 0} 
                        text={length2.toString()}
                        scaleY={-1}  
                        fontSize={20}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
            </>
        )
    }
    let buildAnchor9Copy = (x: number, y: number, name: string, key: number) => {
        let length1: any;
        let length2: any;
        // @ts-ignore
        let bottomDott: any = areas9Copy[0].y;
        // @ts-ignore
        let topDott: any = areas9Copy[0].y;
        // @ts-ignore
        let rightDott: any = areas9Copy[0].x;
        // @ts-ignore
        let leftDott: any = areas9Copy[0].x;
        for (let i = 0; i < areas9Copy.length; i++) {
            if(areas9Copy[i].y < bottomDott){
                bottomDott = areas9Copy[i].y
            }
            if(areas9Copy[i].y > topDott){
                topDott = areas9Copy[i].y
            }
            if(areas9Copy[i].x > rightDott){
                rightDott = areas9Copy[i].x
            }
            if(areas9Copy[i].x < leftDott){
                leftDott = areas9Copy[i].x
            }
        }
        if(areas9Copy.length > 1 && key > 0){
            // @ts-ignore
            length1 = ( Math.sqrt(Math.pow((areas9Copy[key].x - areas9Copy[key - 1].x), 2) + Math.pow((areas9Copy[key].y - areas9Copy[key - 1].y), 2)) ).toFixed(2)
        }
        if(areas9Copy.length > 2 && key == 0){
            // @ts-ignore
            length2 = ( Math.sqrt(Math.pow((areas9Copy[key].x) - (areas9Copy[areas9Copy.length - 1].x), 2) + Math.pow((areas9Copy[key].y - areas9Copy[areas9Copy.length - 1].y), 2)) ).toFixed(2)
        } 
        return(
            <>
                <Circle key={key}
                        x = {x || 0}
                        y ={ y || 0}
                        radius = {8}
                        stroke = {'#666'}
                        fill = {'#ddd'}
                        strokeWidth = {2}
                        id = {name}
                        
                        // @ts-ignore 
                        onClick = {() => {activeDott3(x, y, name, 9)}}
                        onMouseOver = {onMouseOverHandler.bind(this)}
                        onMouseOut = {onMouseOutHandler.bind(this)}
                        draggable={true}
                        onDragEnd = {onDragEndHandler.bind(this)}
                        onDragMove={onDragMoveHandler9Copy.bind(this)}
                        
                />
                <Text  x = {getXCoord((leftDott + rightDott) / 2) || 0}
                        // @ts-ignore 
                        y ={getYCoord((topDott + bottomDott) / 2) || 0} 
                        fill={turnDottAreaText == 9 ? "green" : "black" }
                        onClick = {() => {setTurnDottAreaText(turnDottAreaText == 9 ? 0 : 9)}}
                        text={"9"}
                        fontSize={20}
                        scaleY={-1}
                />
                {
                    areas9Copy.length > 1 && key > 0 ? 
                    <Text  x = {((getXCoord(areas9Copy[key].x) + getXCoord(areas9Copy[key - 1].x)) / 2)|| 0}
                        // @ts-ignore 
                        y ={((getYCoord(areas9Copy[key].y) + getYCoord(areas9Copy[key - 1].y)) / 2)|| 0}
                        text={length1.toString()}
                        scaleY={-1}  
                        fontSize={20}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                    : null
                }
                {
                 areas9Copy.length > 2 && key == 0 && isLinesClosed1 ? 
                 // @ts-ignore
                 <Text  x = {((getXCoord(areas9Copy[key].x) + getXCoord(areas9Copy[areas9Copy.length - 1].x)) / 2) || 0}
                        // @ts-ignore 
                        y ={((getYCoord(areas9Copy[key].y) + getYCoord(areas9Copy[areas9Copy.length - 1].y)) / 2) || 0} 
                        text={length2.toString()}
                        scaleY={-1}  
                        fontSize={20}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
            </>
        )
    }
    let buildAnchor10Copy = (x: number, y: number, name: string, key: number) => {
        let length1: any;
        let length2: any;
        // @ts-ignore
        let bottomDott: any = areas10Copy[0].y;
        // @ts-ignore
        let topDott: any = areas10Copy[0].y;
        // @ts-ignore
        let rightDott: any = areas10Copy[0].x;
        // @ts-ignore
        let leftDott: any = areas10Copy[0].x;
        for (let i = 0; i < areas10Copy.length; i++) {
            if(areas10Copy[i].y < bottomDott){
                bottomDott = areas10Copy[i].y
            }
            if(areas10Copy[i].y > topDott){
                topDott = areas10Copy[i].y
            }
            if(areas10Copy[i].x > rightDott){
                rightDott = areas10Copy[i].x
            }
            if(areas10Copy[i].x < leftDott){
                leftDott = areas10Copy[i].x
            }
        }
        if(areas10Copy.length > 1 && key > 0){
            // @ts-ignore
            length1 = ( Math.sqrt(Math.pow((areas10Copy[key].x - areas10Copy[key - 1].x), 2) + Math.pow((areas10Copy[key].y - areas10Copy[key - 1].y), 2)) ).toFixed(2)
        }
        if(areas10Copy.length > 2 && key == 0){
            // @ts-ignore
            length2 = ( Math.sqrt(Math.pow((areas10Copy[key].x) - (areas10Copy[areas10Copy.length - 1].x), 2) + Math.pow((areas10Copy[key].y - areas10Copy[areas10Copy.length - 1].y), 2)) ).toFixed(2)
        } 
        return(
            <>
                <Circle key={key}
                        x = {x || 0}
                        y ={ y || 0}
                        radius = {8}
                        stroke = {'#666'}
                        fill = {'#ddd'}
                        strokeWidth = {2}
                        id = {name}
                        
                        // @ts-ignore 
                        onClick = {() => {activeDott3(x, y, name, 10)}}
                        onMouseOver = {onMouseOverHandler.bind(this)}
                        onMouseOut = {onMouseOutHandler.bind(this)}
                        draggable={true}
                        onDragEnd = {onDragEndHandler.bind(this)}
                        onDragMove={onDragMoveHandler10Copy.bind(this)}
                        
                />
                <Text  x = {getXCoord((leftDott + rightDott) / 2) || 0}
                        // @ts-ignore 
                        y ={getYCoord((topDott + bottomDott) / 2) || 0} 
                        fill={turnDottAreaText == 10 ? "green" : "black" }
                        onClick = {() => {setTurnDottAreaText(turnDottAreaText == 10 ? 0 : 10)}}
                        text={"10"}
                        fontSize={20}
                        scaleY={-1}
                />
                {
                    areas10Copy.length > 1 && key > 0 ? 
                    <Text  x = {((getXCoord(areas10Copy[key].x) + getXCoord(areas10Copy[key - 1].x)) / 2)|| 0}
                        // @ts-ignore 
                        y ={((getYCoord(areas10Copy[key].y) + getYCoord(areas10Copy[key - 1].y)) / 2)|| 0}
                        text={length1.toString()}
                        scaleY={-1}  
                        fontSize={20}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                    : null
                }
                {
                 areas10Copy.length > 2 && key == 0 && isLinesClosed1 ? 
                 // @ts-ignore
                 <Text  x = {((getXCoord(areas10Copy[key].x) + getXCoord(areas10Copy[areas10Copy.length - 1].x)) / 2) || 0}
                        // @ts-ignore 
                        y ={((getYCoord(areas10Copy[key].y) + getYCoord(areas10Copy[areas10Copy.length - 1].y)) / 2) || 0} 
                        text={length2.toString()}
                        scaleY={-1}  
                        fontSize={20}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
            </>
        )
    }
    let buildAnchor11Copy = (x: number, y: number, name: string, key: number) => {
        let length1: any;
        let length2: any;
        // @ts-ignore
        let bottomDott: any = areas11Copy[0].y;
        // @ts-ignore
        let topDott: any = areas11Copy[0].y;
        // @ts-ignore
        let rightDott: any = areas11Copy[0].x;
        // @ts-ignore
        let leftDott: any = areas11Copy[0].x;
        for (let i = 0; i < areas11Copy.length; i++) {
            if(areas11Copy[i].y < bottomDott){
                bottomDott = areas11Copy[i].y
            }
            if(areas11Copy[i].y > topDott){
                topDott = areas11Copy[i].y
            }
            if(areas11Copy[i].x > rightDott){
                rightDott = areas11Copy[i].x
            }
            if(areas11Copy[i].x < leftDott){
                leftDott = areas11Copy[i].x
            }
        }
        if(areas11Copy.length > 1 && key > 0){
            // @ts-ignore
            length1 = ( Math.sqrt(Math.pow((areas11Copy[key].x - areas11Copy[key - 1].x), 2) + Math.pow((areas11Copy[key].y - areas11Copy[key - 1].y), 2)) ).toFixed(2)
        }
        if(areas11Copy.length > 2 && key == 0){
            // @ts-ignore
            length2 = ( Math.sqrt(Math.pow((areas11Copy[key].x) - (areas11Copy[areas11Copy.length - 1].x), 2) + Math.pow((areas11Copy[key].y - areas11Copy[areas11Copy.length - 1].y), 2)) ).toFixed(2)
        } 
        return(
            <>
                <Circle key={key}
                        x = {x || 0}
                        y ={ y || 0}
                        radius = {8}
                        stroke = {'#666'}
                        fill = {'#ddd'}
                        strokeWidth = {2}
                        id = {name}
                        
                        // @ts-ignore 
                        onClick = {() => {activeDott3(x, y, name, 11)}}
                        onMouseOver = {onMouseOverHandler.bind(this)}
                        onMouseOut = {onMouseOutHandler.bind(this)}
                        draggable={true}
                        onDragEnd = {onDragEndHandler.bind(this)}
                        onDragMove={onDragMoveHandler11Copy.bind(this)}
                        
                />
                <Text  x = {getXCoord((leftDott + rightDott) / 2) || 0}
                        // @ts-ignore 
                        y ={getYCoord((topDott + bottomDott) / 2) || 0} 
                        fill={turnDottAreaText == 11 ? "green" : "black" }
                        onClick = {() => {setTurnDottAreaText(turnDottAreaText == 11 ? 0 : 11)}}
                        text={"11"}
                        fontSize={20}
                        scaleY={-1}
                />
                {
                    areas11Copy.length > 1 && key > 0 ? 
                    <Text  x = {((getXCoord(areas11Copy[key].x) + getXCoord(areas11Copy[key - 1].x)) / 2)|| 0}
                        // @ts-ignore 
                        y ={((getYCoord(areas11Copy[key].y) + getYCoord(areas11Copy[key - 1].y)) / 2)|| 0}
                        text={length1.toString()}
                        scaleY={-1}  
                        fontSize={20}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                    : null
                }
                {
                 areas11Copy.length > 2 && key == 0 && isLinesClosed1 ? 
                 // @ts-ignore
                 <Text  x = {((getXCoord(areas11Copy[key].x) + getXCoord(areas11Copy[areas11Copy.length - 1].x)) / 2) || 0}
                        // @ts-ignore 
                        y ={((getYCoord(areas11Copy[key].y) + getYCoord(areas11Copy[areas11Copy.length - 1].y)) / 2) || 0} 
                        text={length2.toString()}
                        scaleY={-1}  
                        fontSize={20}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
            </>
        )
    }
    let buildAnchor12Copy = (x: number, y: number, name: string, key: number) => {
        let length1: any;
        let length2: any;
        // @ts-ignore
        let bottomDott: any = areas12Copy[0].y;
        // @ts-ignore
        let topDott: any = areas12Copy[0].y;
        // @ts-ignore
        let rightDott: any = areas12Copy[0].x;
        // @ts-ignore
        let leftDott: any = areas12Copy[0].x;
        for (let i = 0; i < areas12Copy.length; i++) {
            if(areas12Copy[i].y < bottomDott){
                bottomDott = areas12Copy[i].y
            }
            if(areas12Copy[i].y > topDott){
                topDott = areas12Copy[i].y
            }
            if(areas12Copy[i].x > rightDott){
                rightDott = areas12Copy[i].x
            }
            if(areas12Copy[i].x < leftDott){
                leftDott = areas12Copy[i].x
            }
        }
        if(areas12Copy.length > 1 && key > 0){
            // @ts-ignore
            length1 = ( Math.sqrt(Math.pow((areas12Copy[key].x - areas12Copy[key - 1].x), 2) + Math.pow((areas12Copy[key].y - areas12Copy[key - 1].y), 2)) ).toFixed(2)
        }
        if(areas12Copy.length > 2 && key == 0){
            // @ts-ignore
            length2 = ( Math.sqrt(Math.pow((areas12Copy[key].x) - (areas12Copy[areas12Copy.length - 1].x), 2) + Math.pow((areas12Copy[key].y - areas12Copy[areas12Copy.length - 1].y), 2)) ).toFixed(2)
        } 
        return(
            <>
                <Circle key={key}
                        x = {x || 0}
                        y ={ y || 0}
                        radius = {8}
                        stroke = {'#666'}
                        fill = {'#ddd'}
                        strokeWidth = {2}
                        id = {name}
                        
                        // @ts-ignore 
                        onClick = {() => {activeDott3(x, y, name, 12)}}
                        onMouseOver = {onMouseOverHandler.bind(this)}
                        onMouseOut = {onMouseOutHandler.bind(this)}
                        draggable={true}
                        onDragEnd = {onDragEndHandler.bind(this)}
                        onDragMove={onDragMoveHandler12Copy.bind(this)}
                        
                />
                <Text  x = {getXCoord((leftDott + rightDott) / 2) || 0}
                        // @ts-ignore 
                        y ={getYCoord((topDott + bottomDott) / 2) || 0} 
                        fill={turnDottAreaText == 12 ? "green" : "black" }
                        onClick = {() => {setTurnDottAreaText(turnDottAreaText == 12 ? 0 : 12)}}
                        text={"12"}
                        fontSize={20}
                        scaleY={-1}
                />
                {
                    areas12Copy.length > 1 && key > 0 ? 
                    <Text  x = {((getXCoord(areas12Copy[key].x) + getXCoord(areas12Copy[key - 1].x)) / 2)|| 0}
                        // @ts-ignore 
                        y ={((getYCoord(areas12Copy[key].y) + getYCoord(areas12Copy[key - 1].y)) / 2)|| 0}
                        text={length1.toString()}
                        scaleY={-1}  
                        fontSize={20}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                    : null
                }
                {
                 areas12Copy.length > 2 && key == 0 && isLinesClosed1 ? 
                 // @ts-ignore
                 <Text  x = {((getXCoord(areas12Copy[key].x) + getXCoord(areas12Copy[areas12Copy.length - 1].x)) / 2) || 0}
                        // @ts-ignore 
                        y ={((getYCoord(areas12Copy[key].y) + getYCoord(areas12Copy[areas12Copy.length - 1].y)) / 2) || 0} 
                        text={length2.toString()}
                        scaleY={-1}  
                        fontSize={20}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
            </>
        )
    }
    let buildAnchor13Copy = (x: number, y: number, name: string, key: number) => {
        let length1: any;
        let length2: any;
        // @ts-ignore
        let bottomDott: any = areas13Copy[0].y;
        // @ts-ignore
        let topDott: any = areas13Copy[0].y;
        // @ts-ignore
        let rightDott: any = areas13Copy[0].x;
        // @ts-ignore
        let leftDott: any = areas13Copy[0].x;
        for (let i = 0; i < areas13Copy.length; i++) {
            if(areas13Copy[i].y < bottomDott){
                bottomDott = areas13Copy[i].y
            }
            if(areas13Copy[i].y > topDott){
                topDott = areas13Copy[i].y
            }
            if(areas13Copy[i].x > rightDott){
                rightDott = areas13Copy[i].x
            }
            if(areas13Copy[i].x < leftDott){
                leftDott = areas13Copy[i].x
            }
        }
        if(areas13Copy.length > 1 && key > 0){
            // @ts-ignore
            length1 = ( Math.sqrt(Math.pow((areas13Copy[key].x - areas13Copy[key - 1].x), 2) + Math.pow((areas13Copy[key].y - areas13Copy[key - 1].y), 2)) ).toFixed(2)
        }
        if(areas13Copy.length > 2 && key == 0){
            // @ts-ignore
            length2 = ( Math.sqrt(Math.pow((areas13Copy[key].x) - (areas13Copy[areas13Copy.length - 1].x), 2) + Math.pow((areas13Copy[key].y - areas13Copy[areas13Copy.length - 1].y), 2)) ).toFixed(2)
        } 
        return(
            <>
                <Circle key={key}
                        x = {x || 0}
                        y ={ y || 0}
                        radius = {8}
                        stroke = {'#666'}
                        fill = {'#ddd'}
                        strokeWidth = {2}
                        id = {name}
                        
                        // @ts-ignore 
                        onClick = {() => {activeDott3(x, y, name, 13)}}
                        onMouseOver = {onMouseOverHandler.bind(this)}
                        onMouseOut = {onMouseOutHandler.bind(this)}
                        draggable={true}
                        onDragEnd = {onDragEndHandler.bind(this)}
                        onDragMove={onDragMoveHandler13Copy.bind(this)}
                        
                />
                <Text  x = {getXCoord((leftDott + rightDott) / 2) || 0}
                        // @ts-ignore 
                        y ={getYCoord((topDott + bottomDott) / 2) || 0} 
                        fill={turnDottAreaText == 13 ? "green" : "black" }
                        onClick = {() => {setTurnDottAreaText(turnDottAreaText == 13 ? 0 : 13)}}
                        text={"13"}
                        fontSize={20}
                        scaleY={-1}
                />
                {
                    areas13Copy.length > 1 && key > 0 ? 
                    <Text  x = {((getXCoord(areas13Copy[key].x) + getXCoord(areas13Copy[key - 1].x)) / 2)|| 0}
                        // @ts-ignore 
                        y ={((getYCoord(areas13Copy[key].y) + getYCoord(areas13Copy[key - 1].y)) / 2)|| 0}
                        text={length1.toString()}
                        scaleY={-1}  
                        fontSize={20}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                    : null
                }
                {
                 areas13Copy.length > 2 && key == 0 && isLinesClosed1 ? 
                 // @ts-ignore
                 <Text  x = {((getXCoord(areas13Copy[key].x) + getXCoord(areas13Copy[areas13Copy.length - 1].x)) / 2) || 0}
                        // @ts-ignore 
                        y ={((getYCoord(areas13Copy[key].y) + getYCoord(areas13Copy[areas13Copy.length - 1].y)) / 2) || 0} 
                        text={length2.toString()}
                        scaleY={-1}  
                        fontSize={20}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
            </>
        )
    }
    let buildAnchor14Copy = (x: number, y: number, name: string, key: number) => {
        let length1: any;
        let length2: any;
        // @ts-ignore
        let bottomDott: any = areas14Copy[0].y;
        // @ts-ignore
        let topDott: any = areas14Copy[0].y;
        // @ts-ignore
        let rightDott: any = areas14Copy[0].x;
        // @ts-ignore
        let leftDott: any = areas14Copy[0].x;
        for (let i = 0; i < areas14Copy.length; i++) {
            if(areas14Copy[i].y < bottomDott){
                bottomDott = areas14Copy[i].y
            }
            if(areas14Copy[i].y > topDott){
                topDott = areas14Copy[i].y
            }
            if(areas14Copy[i].x > rightDott){
                rightDott = areas14Copy[i].x
            }
            if(areas14Copy[i].x < leftDott){
                leftDott = areas14Copy[i].x
            }
        }
        if(areas14Copy.length > 1 && key > 0){
            // @ts-ignore
            length1 = ( Math.sqrt(Math.pow((areas14Copy[key].x - areas14Copy[key - 1].x), 2) + Math.pow((areas14Copy[key].y - areas14Copy[key - 1].y), 2)) ).toFixed(2)
        }
        if(areas14Copy.length > 2 && key == 0){
            // @ts-ignore
            length2 = ( Math.sqrt(Math.pow((areas14Copy[key].x) - (areas14Copy[areas14Copy.length - 1].x), 2) + Math.pow((areas14Copy[key].y - areas14Copy[areas14Copy.length - 1].y), 2)) ).toFixed(2)
        } 
        return(
            <>
                <Circle key={key}
                        x = {x || 0}
                        y ={ y || 0}
                        radius = {8}
                        stroke = {'#666'}
                        fill = {'#ddd'}
                        strokeWidth = {2}
                        id = {name}
                        
                        // @ts-ignore 
                        onClick = {() => {activeDott3(x, y, name, 14)}}
                        onMouseOver = {onMouseOverHandler.bind(this)}
                        onMouseOut = {onMouseOutHandler.bind(this)}
                        draggable={true}
                        onDragEnd = {onDragEndHandler.bind(this)}
                        onDragMove={onDragMoveHandler14Copy.bind(this)}
                        
                />
                <Text  x = {getXCoord((leftDott + rightDott) / 2) || 0}
                        // @ts-ignore 
                        y ={getYCoord((topDott + bottomDott) / 2) || 0} 
                        fill={turnDottAreaText == 14 ? "green" : "black" }
                        onClick = {() => {setTurnDottAreaText(turnDottAreaText == 14 ? 0 : 14)}}
                        text={"14"}
                        fontSize={20}
                        scaleY={-1}
                />
                {
                    areas14Copy.length > 1 && key > 0 ? 
                    <Text  x = {((getXCoord(areas14Copy[key].x) + getXCoord(areas14Copy[key - 1].x)) / 2)|| 0}
                        // @ts-ignore 
                        y ={((getYCoord(areas14Copy[key].y) + getYCoord(areas14Copy[key - 1].y)) / 2)|| 0}
                        text={length1.toString()}
                        scaleY={-1}  
                        fontSize={20}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                    : null
                }
                {
                 areas14Copy.length > 2 && key == 0 && isLinesClosed1 ? 
                 // @ts-ignore
                 <Text  x = {((getXCoord(areas14Copy[key].x) + getXCoord(areas14Copy[areas14Copy.length - 1].x)) / 2) || 0}
                        // @ts-ignore 
                        y ={((getYCoord(areas14Copy[key].y) + getYCoord(areas14Copy[areas14Copy.length - 1].y)) / 2) || 0} 
                        text={length2.toString()}
                        scaleY={-1}  
                        fontSize={20}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
            </>
        )
    }
    let buildAnchor15Copy = (x: number, y: number, name: string, key: number) => {
        let length1: any;
        let length2: any;
        // @ts-ignore
        let bottomDott: any = areas15Copy[0].y;
        // @ts-ignore
        let topDott: any = areas15Copy[0].y;
        // @ts-ignore
        let rightDott: any = areas15Copy[0].x;
        // @ts-ignore
        let leftDott: any = areas15Copy[0].x;
        for (let i = 0; i < areas15Copy.length; i++) {
            if(areas15Copy[i].y < bottomDott){
                bottomDott = areas15Copy[i].y
            }
            if(areas15Copy[i].y > topDott){
                topDott = areas15Copy[i].y
            }
            if(areas15Copy[i].x > rightDott){
                rightDott = areas15Copy[i].x
            }
            if(areas15Copy[i].x < leftDott){
                leftDott = areas15Copy[i].x
            }
        }
        if(areas15Copy.length > 1 && key > 0){
            // @ts-ignore
            length1 = ( Math.sqrt(Math.pow((areas15Copy[key].x - areas15Copy[key - 1].x), 2) + Math.pow((areas15Copy[key].y - areas15Copy[key - 1].y), 2)) ).toFixed(2)
        }
        if(areas15Copy.length > 2 && key == 0){
            // @ts-ignore
            length2 = ( Math.sqrt(Math.pow((areas15Copy[key].x) - (areas15Copy[areas15Copy.length - 1].x), 2) + Math.pow((areas15Copy[key].y - areas15Copy[areas15Copy.length - 1].y), 2)) ).toFixed(2)
        } 
        return(
            <>
                <Circle key={key}
                        x = {x || 0}
                        y ={ y || 0}
                        radius = {8}
                        stroke = {'#666'}
                        fill = {'#ddd'}
                        strokeWidth = {2}
                        id = {name}
                        
                        // @ts-ignore 
                        onClick = {() => {activeDott3(x, y, name, 15)}}
                        onMouseOver = {onMouseOverHandler.bind(this)}
                        onMouseOut = {onMouseOutHandler.bind(this)}
                        draggable={true}
                        onDragEnd = {onDragEndHandler.bind(this)}
                        onDragMove={onDragMoveHandler15Copy.bind(this)}
                        
                />
                <Text  x = {getXCoord((leftDott + rightDott) / 2) || 0}
                        // @ts-ignore 
                        y ={getYCoord((topDott + bottomDott) / 2) || 0} 
                        fill={turnDottAreaText == 15 ? "green" : "black" }
                        onClick = {() => {setTurnDottAreaText(turnDottAreaText == 15 ? 0 : 15)}}
                        text={"15"}
                        fontSize={20}
                        scaleY={-1}
                />
                {
                    areas15Copy.length > 1 && key > 0 ? 
                    <Text  x = {((getXCoord(areas15Copy[key].x) + getXCoord(areas15Copy[key - 1].x)) / 2)|| 0}
                        // @ts-ignore 
                        y ={((getYCoord(areas15Copy[key].y) + getYCoord(areas15Copy[key - 1].y)) / 2)|| 0}
                        text={length1.toString()}
                        scaleY={-1}  
                        fontSize={20}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                    : null
                }
                {
                 areas15Copy.length > 2 && key == 0 && isLinesClosed1 ? 
                 // @ts-ignore
                 <Text  x = {((getXCoord(areas15Copy[key].x) + getXCoord(areas15Copy[areas15Copy.length - 1].x)) / 2) || 0}
                        // @ts-ignore 
                        y ={((getYCoord(areas15Copy[key].y) + getYCoord(areas15Copy[areas15Copy.length - 1].y)) / 2) || 0} 
                        text={length2.toString()}
                        scaleY={-1}  
                        fontSize={20}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
            </>
        )
    }
    let buildAnchor16Copy = (x: number, y: number, name: string, key: number) => {
        let length1: any;
        let length2: any;
        // @ts-ignore
        let bottomDott: any = areas16Copy[0].y;
        // @ts-ignore
        let topDott: any = areas16Copy[0].y;
        // @ts-ignore
        let rightDott: any = areas16Copy[0].x;
        // @ts-ignore
        let leftDott: any = areas16Copy[0].x;
        for (let i = 0; i < areas16Copy.length; i++) {
            if(areas16Copy[i].y < bottomDott){
                bottomDott = areas16Copy[i].y
            }
            if(areas16Copy[i].y > topDott){
                topDott = areas16Copy[i].y
            }
            if(areas16Copy[i].x > rightDott){
                rightDott = areas16Copy[i].x
            }
            if(areas16Copy[i].x < leftDott){
                leftDott = areas16Copy[i].x
            }
        }
        if(areas16Copy.length > 1 && key > 0){
            // @ts-ignore
            length1 = ( Math.sqrt(Math.pow((areas16Copy[key].x - areas16Copy[key - 1].x), 2) + Math.pow((areas16Copy[key].y - areas16Copy[key - 1].y), 2)) ).toFixed(2)
        }
        if(areas16Copy.length > 2 && key == 0){
            // @ts-ignore
            length2 = ( Math.sqrt(Math.pow((areas16Copy[key].x) - (areas16Copy[areas16Copy.length - 1].x), 2) + Math.pow((areas16Copy[key].y - areas16Copy[areas16Copy.length - 1].y), 2)) ).toFixed(2)
        } 
        return(
            <>
                <Circle key={key}
                        x = {x || 0}
                        y ={ y || 0}
                        radius = {8}
                        stroke = {'#666'}
                        fill = {'#ddd'}
                        strokeWidth = {2}
                        id = {name}
                        
                        // @ts-ignore 
                        onClick = {() => {activeDott3(x, y, name, 16)}}
                        onMouseOver = {onMouseOverHandler.bind(this)}
                        onMouseOut = {onMouseOutHandler.bind(this)}
                        draggable={true}
                        onDragEnd = {onDragEndHandler.bind(this)}
                        onDragMove={onDragMoveHandler16Copy.bind(this)}
                        
                />
                <Text  x = {getXCoord((leftDott + rightDott) / 2) || 0}
                        // @ts-ignore 
                        y ={getYCoord((topDott + bottomDott) / 2) || 0} 
                        fill={turnDottAreaText == 16 ? "green" : "black" }
                        onClick = {() => {setTurnDottAreaText(turnDottAreaText == 16 ? 0 : 16)}}
                        text={"16"}
                        fontSize={20}
                        scaleY={-1}
                />
                {
                    areas16Copy.length > 1 && key > 0 ? 
                    <Text  x = {((getXCoord(areas16Copy[key].x) + getXCoord(areas16Copy[key - 1].x)) / 2)|| 0}
                        // @ts-ignore 
                        y ={((getYCoord(areas16Copy[key].y) + getYCoord(areas16Copy[key - 1].y)) / 2)|| 0}
                        text={length1.toString()}
                        scaleY={-1}  
                        fontSize={20}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                    : null
                }
                {
                 areas16Copy.length > 2 && key == 0 && isLinesClosed1 ? 
                 // @ts-ignore
                 <Text  x = {((getXCoord(areas16Copy[key].x) + getXCoord(areas16Copy[areas16Copy.length - 1].x)) / 2) || 0}
                        // @ts-ignore 
                        y ={((getYCoord(areas16Copy[key].y) + getYCoord(areas16Copy[areas16Copy.length - 1].y)) / 2) || 0} 
                        text={length2.toString()}
                        scaleY={-1}  
                        fontSize={20}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
            </>
        )
    }
    let buildAnchor17Copy = (x: number, y: number, name: string, key: number) => {
        let length1: any;
        let length2: any;
        // @ts-ignore
        let bottomDott: any = areas17Copy[0].y;
        // @ts-ignore
        let topDott: any = areas17Copy[0].y;
        // @ts-ignore
        let rightDott: any = areas17Copy[0].x;
        // @ts-ignore
        let leftDott: any = areas17Copy[0].x;
        for (let i = 0; i < areas17Copy.length; i++) {
            if(areas17Copy[i].y < bottomDott){
                bottomDott = areas17Copy[i].y
            }
            if(areas17Copy[i].y > topDott){
                topDott = areas17Copy[i].y
            }
            if(areas17Copy[i].x > rightDott){
                rightDott = areas17Copy[i].x
            }
            if(areas17Copy[i].x < leftDott){
                leftDott = areas17Copy[i].x
            }
        }
        if(areas17Copy.length > 1 && key > 0){
            // @ts-ignore
            length1 = ( Math.sqrt(Math.pow((areas17Copy[key].x - areas17Copy[key - 1].x), 2) + Math.pow((areas17Copy[key].y - areas17Copy[key - 1].y), 2)) ).toFixed(2)
        }
        if(areas17Copy.length > 2 && key == 0){
            // @ts-ignore
            length2 = ( Math.sqrt(Math.pow((areas17Copy[key].x) - (areas17Copy[areas17Copy.length - 1].x), 2) + Math.pow((areas17Copy[key].y - areas17Copy[areas17Copy.length - 1].y), 2)) ).toFixed(2)
        } 
        return(
            <>
                <Circle key={key}
                        x = {x || 0}
                        y ={ y || 0}
                        radius = {8}
                        stroke = {'#666'}
                        fill = {'#ddd'}
                        strokeWidth = {2}
                        id = {name}
                        
                        // @ts-ignore 
                        onClick = {() => {activeDott3(x, y, name, 17)}}
                        onMouseOver = {onMouseOverHandler.bind(this)}
                        onMouseOut = {onMouseOutHandler.bind(this)}
                        draggable={true}
                        onDragEnd = {onDragEndHandler.bind(this)}
                        onDragMove={onDragMoveHandler17Copy.bind(this)}
                        
                />
                <Text  x = {getXCoord((leftDott + rightDott) / 2) || 0}
                        // @ts-ignore 
                        y ={getYCoord((topDott + bottomDott) / 2) || 0} 
                        fill={turnDottAreaText == 17 ? "green" : "black" }
                        onClick = {() => {setTurnDottAreaText(turnDottAreaText == 17 ? 0 : 17)}}
                        text={"17"}
                        fontSize={20}
                        scaleY={-1}
                />
                {
                    areas17Copy.length > 1 && key > 0 ? 
                    <Text  x = {((getXCoord(areas17Copy[key].x) + getXCoord(areas17Copy[key - 1].x)) / 2)|| 0}
                        // @ts-ignore 
                        y ={((getYCoord(areas17Copy[key].y) + getYCoord(areas17Copy[key - 1].y)) / 2)|| 0}
                        text={length1.toString()}
                        scaleY={-1}  
                        fontSize={20}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                    : null
                }
                {
                 areas17Copy.length > 2 && key == 0 && isLinesClosed1 ? 
                 // @ts-ignore
                 <Text  x = {((getXCoord(areas17Copy[key].x) + getXCoord(areas17Copy[areas17Copy.length - 1].x)) / 2) || 0}
                        // @ts-ignore 
                        y ={((getYCoord(areas17Copy[key].y) + getYCoord(areas17Copy[areas17Copy.length - 1].y)) / 2) || 0} 
                        text={length2.toString()}
                        scaleY={-1}  
                        fontSize={20}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
            </>
        )
    }
    let buildAnchor18Copy = (x: number, y: number, name: string, key: number) => {
        let length1: any;
        let length2: any;
        // @ts-ignore
        let bottomDott: any = areas18Copy[0].y;
        // @ts-ignore
        let topDott: any = areas18Copy[0].y;
        // @ts-ignore
        let rightDott: any = areas18Copy[0].x;
        // @ts-ignore
        let leftDott: any = areas18Copy[0].x;
        for (let i = 0; i < areas18Copy.length; i++) {
            if(areas18Copy[i].y < bottomDott){
                bottomDott = areas18Copy[i].y
            }
            if(areas18Copy[i].y > topDott){
                topDott = areas18Copy[i].y
            }
            if(areas18Copy[i].x > rightDott){
                rightDott = areas18Copy[i].x
            }
            if(areas18Copy[i].x < leftDott){
                leftDott = areas18Copy[i].x
            }
        }
        if(areas18Copy.length > 1 && key > 0){
            // @ts-ignore
            length1 = ( Math.sqrt(Math.pow((areas18Copy[key].x - areas18Copy[key - 1].x), 2) + Math.pow((areas18Copy[key].y - areas18Copy[key - 1].y), 2)) ).toFixed(2)
        }
        if(areas18Copy.length > 2 && key == 0){
            // @ts-ignore
            length2 = ( Math.sqrt(Math.pow((areas18Copy[key].x) - (areas18Copy[areas18Copy.length - 1].x), 2) + Math.pow((areas18Copy[key].y - areas18Copy[areas18Copy.length - 1].y), 2)) ).toFixed(2)
        } 
        return(
            <>
                <Circle key={key}
                        x = {x || 0}
                        y ={ y || 0}
                        radius = {8}
                        stroke = {'#666'}
                        fill = {'#ddd'}
                        strokeWidth = {2}
                        id = {name}
                        
                        // @ts-ignore 
                        onClick = {() => {activeDott3(x, y, name, 18)}}
                        onMouseOver = {onMouseOverHandler.bind(this)}
                        onMouseOut = {onMouseOutHandler.bind(this)}
                        draggable={true}
                        onDragEnd = {onDragEndHandler.bind(this)}
                        onDragMove={onDragMoveHandler18Copy.bind(this)}
                        
                />
                <Text  x = {getXCoord((leftDott + rightDott) / 2) || 0}
                        // @ts-ignore 
                        y ={getYCoord((topDott + bottomDott) / 2) || 0} 
                        fill={turnDottAreaText == 18 ? "green" : "black" }
                        onClick = {() => {setTurnDottAreaText(turnDottAreaText == 18 ? 0 : 18)}}
                        text={"18"}
                        fontSize={20}
                        scaleY={-1}
                />
                {
                    areas18Copy.length > 1 && key > 0 ? 
                    <Text  x = {((getXCoord(areas18Copy[key].x) + getXCoord(areas18Copy[key - 1].x)) / 2)|| 0}
                        // @ts-ignore 
                        y ={((getYCoord(areas18Copy[key].y) + getYCoord(areas18Copy[key - 1].y)) / 2)|| 0}
                        text={length1.toString()}
                        scaleY={-1}  
                        fontSize={20}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                    : null
                }
                {
                 areas18Copy.length > 2 && key == 0 && isLinesClosed1 ? 
                 // @ts-ignore
                 <Text  x = {((getXCoord(areas18Copy[key].x) + getXCoord(areas18Copy[areas18Copy.length - 1].x)) / 2) || 0}
                        // @ts-ignore 
                        y ={((getYCoord(areas18Copy[key].y) + getYCoord(areas18Copy[areas18Copy.length - 1].y)) / 2) || 0} 
                        text={length2.toString()}
                        scaleY={-1}  
                        fontSize={20}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
            </>
        )
    }
    let buildAnchor19Copy = (x: number, y: number, name: string, key: number) => {
        let length1: any;
        let length2: any;
        // @ts-ignore
        let bottomDott: any = areas19Copy[0].y;
        // @ts-ignore
        let topDott: any = areas19Copy[0].y;
        // @ts-ignore
        let rightDott: any = areas19Copy[0].x;
        // @ts-ignore
        let leftDott: any = areas19Copy[0].x;
        for (let i = 0; i < areas19Copy.length; i++) {
            if(areas19Copy[i].y < bottomDott){
                bottomDott = areas19Copy[i].y
            }
            if(areas19Copy[i].y > topDott){
                topDott = areas19Copy[i].y
            }
            if(areas19Copy[i].x > rightDott){
                rightDott = areas19Copy[i].x
            }
            if(areas19Copy[i].x < leftDott){
                leftDott = areas19Copy[i].x
            }
        }
        if(areas19Copy.length > 1 && key > 0){
            // @ts-ignore
            length1 = ( Math.sqrt(Math.pow((areas19Copy[key].x - areas19Copy[key - 1].x), 2) + Math.pow((areas19Copy[key].y - areas19Copy[key - 1].y), 2)) ).toFixed(2)
        }
        if(areas19Copy.length > 2 && key == 0){
            // @ts-ignore
            length2 = ( Math.sqrt(Math.pow((areas19Copy[key].x) - (areas19Copy[areas19Copy.length - 1].x), 2) + Math.pow((areas19Copy[key].y - areas19Copy[areas19Copy.length - 1].y), 2)) ).toFixed(2)
        } 
        return(
            <>
                <Circle key={key}
                        x = {x || 0}
                        y ={ y || 0}
                        radius = {8}
                        stroke = {'#666'}
                        fill = {'#ddd'}
                        strokeWidth = {2}
                        id = {name}
                        
                        // @ts-ignore 
                        onClick = {() => {activeDott3(x, y, name, 19)}}
                        onMouseOver = {onMouseOverHandler.bind(this)}
                        onMouseOut = {onMouseOutHandler.bind(this)}
                        draggable={true}
                        onDragEnd = {onDragEndHandler.bind(this)}
                        onDragMove={onDragMoveHandler19Copy.bind(this)}
                        
                />
                <Text  x = {getXCoord((leftDott + rightDott) / 2) || 0}
                        // @ts-ignore 
                        y ={getYCoord((topDott + bottomDott) / 2) || 0} 
                        fill={turnDottAreaText == 19 ? "green" : "black" }
                        onClick = {() => {setTurnDottAreaText(turnDottAreaText == 19 ? 0 : 19)}}
                        text={"19"}
                        fontSize={20}
                        scaleY={-1}
                />
                {
                    areas19Copy.length > 1 && key > 0 ? 
                    <Text  x = {((getXCoord(areas19Copy[key].x) + getXCoord(areas19Copy[key - 1].x)) / 2)|| 0}
                        // @ts-ignore 
                        y ={((getYCoord(areas19Copy[key].y) + getYCoord(areas19Copy[key - 1].y)) / 2)|| 0}
                        text={length1.toString()}
                        scaleY={-1}  
                        fontSize={20}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                    : null
                }
                {
                 areas19Copy.length > 2 && key == 0 && isLinesClosed1 ? 
                 // @ts-ignore
                 <Text  x = {((getXCoord(areas19Copy[key].x) + getXCoord(areas19Copy[areas19Copy.length - 1].x)) / 2) || 0}
                        // @ts-ignore 
                        y ={((getYCoord(areas19Copy[key].y) + getYCoord(areas19Copy[areas19Copy.length - 1].y)) / 2) || 0} 
                        text={length2.toString()}
                        scaleY={-1}  
                        fontSize={20}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
            </>
        )
    }
    let buildAnchor20Copy = (x: number, y: number, name: string, key: number) => {
        let length1: any;
        let length2: any;
        // @ts-ignore
        let bottomDott: any = areas20Copy[0].y;
        // @ts-ignore
        let topDott: any = areas20Copy[0].y;
        // @ts-ignore
        let rightDott: any = areas20Copy[0].x;
        // @ts-ignore
        let leftDott: any = areas20Copy[0].x;
        for (let i = 0; i < areas20Copy.length; i++) {
            if(areas20Copy[i].y < bottomDott){
                bottomDott = areas20Copy[i].y
            }
            if(areas20Copy[i].y > topDott){
                topDott = areas20Copy[i].y
            }
            if(areas20Copy[i].x > rightDott){
                rightDott = areas20Copy[i].x
            }
            if(areas20Copy[i].x < leftDott){
                leftDott = areas20Copy[i].x
            }
        }
        if(areas20Copy.length > 1 && key > 0){
            // @ts-ignore
            length1 = ( Math.sqrt(Math.pow((areas20Copy[key].x - areas20Copy[key - 1].x), 2) + Math.pow((areas20Copy[key].y - areas20Copy[key - 1].y), 2)) ).toFixed(2)
        }
        if(areas20Copy.length > 2 && key == 0){
            // @ts-ignore
            length2 = ( Math.sqrt(Math.pow((areas20Copy[key].x) - (areas20Copy[areas20Copy.length - 1].x), 2) + Math.pow((areas20Copy[key].y - areas20Copy[areas20Copy.length - 1].y), 2)) ).toFixed(2)
        } 
        return(
            <>
                <Circle key={key}
                        x = {x || 0}
                        y ={ y || 0}
                        radius = {8}
                        stroke = {'#666'}
                        fill = {'#ddd'}
                        strokeWidth = {2}
                        id = {name}
                        
                        // @ts-ignore 
                        onClick = {() => {activeDott3(x, y, name, 20)}}
                        onMouseOver = {onMouseOverHandler.bind(this)}
                        onMouseOut = {onMouseOutHandler.bind(this)}
                        draggable={true}
                        onDragEnd = {onDragEndHandler.bind(this)}
                        onDragMove={onDragMoveHandler20Copy.bind(this)}
                        
                />
                <Text  x = {getXCoord((leftDott + rightDott) / 2) || 0}
                        // @ts-ignore 
                        y ={getYCoord((topDott + bottomDott) / 2) || 0} 
                        fill={turnDottAreaText == 20 ? "green" : "black" }
                        onClick = {() => {setTurnDottAreaText(turnDottAreaText == 20 ? 0 : 20)}}
                        text={"20"}
                        fontSize={20}
                        scaleY={-1}
                />
                {
                    areas20Copy.length > 1 && key > 0 ? 
                    <Text  x = {((getXCoord(areas20Copy[key].x) + getXCoord(areas20Copy[key - 1].x)) / 2)|| 0}
                        // @ts-ignore 
                        y ={((getYCoord(areas20Copy[key].y) + getYCoord(areas20Copy[key - 1].y)) / 2)|| 0}
                        text={length1.toString()}
                        scaleY={-1}  
                        fontSize={20}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                    : null
                }
                {
                 areas20Copy.length > 2 && key == 0 && isLinesClosed1 ? 
                 // @ts-ignore
                 <Text  x = {((getXCoord(areas20Copy[key].x) + getXCoord(areas20Copy[areas20Copy.length - 1].x)) / 2) || 0}
                        // @ts-ignore 
                        y ={((getYCoord(areas20Copy[key].y) + getYCoord(areas20Copy[areas20Copy.length - 1].y)) / 2) || 0} 
                        text={length2.toString()}
                        scaleY={-1}  
                        fontSize={20}
                        fill={'blue'} 
                        stroke={'black'} 
                        strokeWidth={0.8}
                        /> 
                        
                : null}
            </>
        )
    }


    // @ts-ignore
    function onMouseOverHandler(e: any) {
        document.body.style.cursor = 'pointer';
        e.target.strokeWidth(4);
    }
    // @ts-ignore
    function onMouseOutHandler(e: any) {
        document.body.style.cursor = 'default';
        e.target.strokeWidth(2);
    }
    function onDragEndHandler(e: any) {
        e.target.draggable(true);
    }
    
    let onDragMoveHandler = (e: any) => {
        // @ts-ignore
        if(appContext.state.isEditedMode == true && getXByCoord(e.currentTarget.x()) >= 0 && getYByCoord(e.currentTarget.y()) >= 0){
            let id = Number(e.currentTarget.id());
            // build anchors
            let dragedPoints: any = [];

            for (let i = 0; i < points.length; i++) {
                dragedPoints[i] = points[i];
            }
            // @ts-ignore
            dragedPoints[id] = {x: getXByCoord(e.currentTarget.x()), y: getYByCoord(e.currentTarget.y())}
            
            let l: any = [];
            for (let i = 0; i < dragedPoints.length; i++) {
                // @ts-ignore
                l.push(dragedPoints[i].x);
                // @ts-ignore
                l.push(dragedPoints[i].y);
            }
            // @ts-ignore
            setLines(l);
            setPoints(dragedPoints);
            calcPolygonPointsCallback(dragedPoints)
        }
        else{e.target.draggable(false);}
    }
    let onDragMoveHandler1 = (e: any) => {
        // @ts-ignore
        if(appContext.state.isEditedMode == true && getXByCoord(e.currentTarget.x()) >= 0 && getYByCoord(e.currentTarget.y()) >= 0){
            let id = Number(e.currentTarget.id());
            // build anchors
            let dragedPoints: any = [];
            

            for (let i = 0; i < areas1.length; i++) {
                dragedPoints[i] = areas1[i];
            }
            // @ts-ignore
            dragedPoints[id] = {x:  getXByCoord(e.currentTarget.x()), y: getYByCoord(e.currentTarget.y())}
            setAreas1(dragedPoints);

            let l: any = [];
            for (let i = 0; i < areas1.length; i++) {
                // @ts-ignore
                l.push(dragedPoints[i].x);
                // @ts-ignore
                l.push(dragedPoints[i].y);
            }
            // @ts-ignore
            setLines1(l);

            calcPolygonPointsCallback(dragedPoints)
        }
        else{e.target.draggable(false);}
    }
    let onDragMoveHandler2 = (e: any) => {
        // @ts-ignore
        if(appContext.state.isEditedMode == true && getXByCoord(e.currentTarget.x()) >= 0 && getYByCoord(e.currentTarget.y()) >= 0){
            let id = Number(e.currentTarget.id());
            // build anchors
            let dragedPoints: any = [];
            

            for (let i = 0; i < areas2.length; i++) {
                dragedPoints[i] = areas2[i];
            }
            // @ts-ignore
            dragedPoints[id] = {x:  getXByCoord(e.currentTarget.x()), y: getYByCoord(e.currentTarget.y())}
            setAreas2(dragedPoints);

            let l: any = [];
            for (let i = 0; i < areas2.length; i++) {
                // @ts-ignore
                l.push(dragedPoints[i].x);
                // @ts-ignore
                l.push(dragedPoints[i].y);
            }
            // @ts-ignore
            setLines2(l);

            calcPolygonPointsCallback(dragedPoints)
        }
        else{e.target.draggable(false);}
    }
    let onDragMoveHandler3 = (e: any) => {
        // @ts-ignore
        if(appContext.state.isEditedMode == true && getXByCoord(e.currentTarget.x()) >= 0 && getYByCoord(e.currentTarget.y()) >= 0){
            let id = Number(e.currentTarget.id());
            // build anchors
            let dragedPoints: any = [];
            

            for (let i = 0; i < areas3.length; i++) {
                dragedPoints[i] = areas3[i];
            }
            // @ts-ignore
            dragedPoints[id] = {x:  getXByCoord(e.currentTarget.x()), y: getYByCoord(e.currentTarget.y())}
            setAreas3(dragedPoints);

            let l: any = [];
            for (let i = 0; i < areas3.length; i++) {
                // @ts-ignore
                l.push(dragedPoints[i].x);
                // @ts-ignore
                l.push(dragedPoints[i].y);
            }
            // @ts-ignore
            setLines3(l);

            calcPolygonPointsCallback(dragedPoints)
        }
        else{e.target.draggable(false);}
    }
    let onDragMoveHandler4 = (e: any) => {
        // @ts-ignore
        if(appContext.state.isEditedMode == true && getXByCoord(e.currentTarget.x()) >= 0 && getYByCoord(e.currentTarget.y()) >= 0){
            let id = Number(e.currentTarget.id());
            // build anchors
            let dragedPoints: any = [];
            

            for (let i = 0; i < areas4.length; i++) {
                dragedPoints[i] = areas4[i];
            }
            // @ts-ignore
            dragedPoints[id] = {x:  getXByCoord(e.currentTarget.x()), y: getYByCoord(e.currentTarget.y())}
            setAreas4(dragedPoints);

            let l: any = [];
            for (let i = 0; i < areas4.length; i++) {
                // @ts-ignore
                l.push(dragedPoints[i].x);
                // @ts-ignore
                l.push(dragedPoints[i].y);
            }
            // @ts-ignore
            setLines4(l);

            calcPolygonPointsCallback(dragedPoints)
        }
        else{e.target.draggable(false)};
    }
    let onDragMoveHandler5 = (e: any) => {
        // @ts-ignore
        if(appContext.state.isEditedMode == true && getXByCoord(e.currentTarget.x()) >= 0 && getYByCoord(e.currentTarget.y()) >= 0){
            let id = Number(e.currentTarget.id());
            // build anchors
            let dragedPoints: any = [];
            

            for (let i = 0; i < areas5.length; i++) {
                dragedPoints[i] = areas5[i];
            }
            // @ts-ignore
            dragedPoints[id] = {x:  getXByCoord(e.currentTarget.x()), y: getYByCoord(e.currentTarget.y())}
            setAreas5(dragedPoints);

            let l: any = [];
            for (let i = 0; i < areas5.length; i++) {
                // @ts-ignore
                l.push(dragedPoints[i].x);
                // @ts-ignore
                l.push(dragedPoints[i].y);
            }
            // @ts-ignore
            setLines5(l);

            calcPolygonPointsCallback(dragedPoints)
        }
        else{e.target.draggable(false)};
    }
    let onDragMoveHandler6 = (e: any) => {
        // @ts-ignore
        if(appContext.state.isEditedMode == true && getXByCoord(e.currentTarget.x()) >= 0 && getYByCoord(e.currentTarget.y()) >= 0){
            let id = Number(e.currentTarget.id());
            // build anchors
            let dragedPoints: any = [];
            

            for (let i = 0; i < areas6.length; i++) {
                dragedPoints[i] = areas6[i];
            }
            // @ts-ignore
            dragedPoints[id] = {x:  getXByCoord(e.currentTarget.x()), y: getYByCoord(e.currentTarget.y())}
            setAreas6(dragedPoints);

            let l: any = [];
            for (let i = 0; i < areas6.length; i++) {
                // @ts-ignore
                l.push(dragedPoints[i].x);
                // @ts-ignore
                l.push(dragedPoints[i].y);
            }
            // @ts-ignore
            setLines6(l);

            calcPolygonPointsCallback(dragedPoints)
        }
        else{e.target.draggable(false)};
    }
    let onDragMoveHandler7 = (e: any) => {
        // @ts-ignore
        if(appContext.state.isEditedMode == true && getXByCoord(e.currentTarget.x()) >= 0 && getYByCoord(e.currentTarget.y()) >= 0){
            let id = Number(e.currentTarget.id());
            // build anchors
            let dragedPoints: any = [];
            

            for (let i = 0; i < areas7.length; i++) {
                dragedPoints[i] = areas7[i];
            }
            // @ts-ignore
            dragedPoints[id] = {x:  getXByCoord(e.currentTarget.x()), y: getYByCoord(e.currentTarget.y())}
            setAreas7(dragedPoints);

            let l: any = [];
            for (let i = 0; i < areas7.length; i++) {
                // @ts-ignore
                l.push(dragedPoints[i].x);
                // @ts-ignore
                l.push(dragedPoints[i].y);
            }
            // @ts-ignore
            setLines7(l);

            calcPolygonPointsCallback(dragedPoints)
        }
        else{e.target.draggable(false)};
    }
    let onDragMoveHandler8 = (e: any) => {
        // @ts-ignore
        if(appContext.state.isEditedMode == true && getXByCoord(e.currentTarget.x()) >= 0 && getYByCoord(e.currentTarget.y()) >= 0){
            let id = Number(e.currentTarget.id());
            // build anchors
            let dragedPoints: any = [];
            

            for (let i = 0; i < areas8.length; i++) {
                dragedPoints[i] = areas8[i];
            }
            // @ts-ignore
            dragedPoints[id] = {x:  getXByCoord(e.currentTarget.x()), y: getYByCoord(e.currentTarget.y())}
            setAreas8(dragedPoints);

            let l: any = [];
            for (let i = 0; i < areas8.length; i++) {
                // @ts-ignore
                l.push(dragedPoints[i].x);
                // @ts-ignore
                l.push(dragedPoints[i].y);
            }
            // @ts-ignore
            setLines8(l);

            calcPolygonPointsCallback(dragedPoints)
        }
        else{e.target.draggable(false)};
    }
    let onDragMoveHandler9 = (e: any) => {
        // @ts-ignore
        if(appContext.state.isEditedMode == true && getXByCoord(e.currentTarget.x()) >= 0 && getYByCoord(e.currentTarget.y()) >= 0){
            let id = Number(e.currentTarget.id());
            // build anchors
            let dragedPoints: any = [];
            

            for (let i = 0; i < areas9.length; i++) {
                dragedPoints[i] = areas9[i];
            }
            // @ts-ignore
            dragedPoints[id] = {x:  getXByCoord(e.currentTarget.x()), y: getYByCoord(e.currentTarget.y())}
            setAreas9(dragedPoints);

            let l: any = [];
            for (let i = 0; i < areas9.length; i++) {
                // @ts-ignore
                l.push(dragedPoints[i].x);
                // @ts-ignore
                l.push(dragedPoints[i].y);
            }
            // @ts-ignore
            setLines9(l);

            calcPolygonPointsCallback(dragedPoints)
        }
        else{e.target.draggable(false)};
    }
    let onDragMoveHandler10 = (e: any) => {
        // @ts-ignore
        if(appContext.state.isEditedMode == true && getXByCoord(e.currentTarget.x()) >= 0 && getYByCoord(e.currentTarget.y()) >= 0){
            let id = Number(e.currentTarget.id());
            // build anchors
            let dragedPoints: any = [];
            

            for (let i = 0; i < areas10.length; i++) {
                dragedPoints[i] = areas10[i];
            }
            // @ts-ignore
            dragedPoints[id] = {x:  getXByCoord(e.currentTarget.x()), y: getYByCoord(e.currentTarget.y())}
            setAreas10(dragedPoints);

            let l: any = [];
            for (let i = 0; i < areas10.length; i++) {
                // @ts-ignore
                l.push(dragedPoints[i].x);
                // @ts-ignore
                l.push(dragedPoints[i].y);
            }
            // @ts-ignore
            setLines10(l);

            calcPolygonPointsCallback(dragedPoints)
        }
        else{e.target.draggable(false)};
    }
    let onDragMoveHandler11 = (e: any) => {
        // @ts-ignore
        if(appContext.state.isEditedMode == true && getXByCoord(e.currentTarget.x()) >= 0 && getYByCoord(e.currentTarget.y()) >= 0){
            let id = Number(e.currentTarget.id());
            // build anchors
            let dragedPoints: any = [];
            

            for (let i = 0; i < areas11.length; i++) {
                dragedPoints[i] = areas11[i];
            }
            // @ts-ignore
            dragedPoints[id] = {x:  getXByCoord(e.currentTarget.x()), y: getYByCoord(e.currentTarget.y())}
            setAreas11(dragedPoints);

            let l: any = [];
            for (let i = 0; i < areas11.length; i++) {
                // @ts-ignore
                l.push(dragedPoints[i].x);
                // @ts-ignore
                l.push(dragedPoints[i].y);
            }
            // @ts-ignore
            setLines11(l);

            calcPolygonPointsCallback(dragedPoints)
        }
        else{e.target.draggable(false)};
    }
    let onDragMoveHandler12 = (e: any) => {
        // @ts-ignore
        if(appContext.state.isEditedMode == true && getXByCoord(e.currentTarget.x()) >= 0 && getYByCoord(e.currentTarget.y()) >= 0){
            let id = Number(e.currentTarget.id());
            // build anchors
            let dragedPoints: any = [];
            

            for (let i = 0; i < areas12.length; i++) {
                dragedPoints[i] = areas12[i];
            }

            // @ts-ignore
            dragedPoints[id] = {x:  getXByCoord(e.currentTarget.x()), y: getYByCoord(e.currentTarget.y())}
            setAreas12(dragedPoints);

            let l: any = [];
            for (let i = 0; i < areas12.length; i++) {
                // @ts-ignore
                l.push(dragedPoints[i].x);
                // @ts-ignore
                l.push(dragedPoints[i].y);
            }
            // @ts-ignore
            setLines12(l);

            calcPolygonPointsCallback(dragedPoints)
        }
        else{e.target.draggable(false)};
    }
    let onDragMoveHandler13 = (e: any) => {
        // @ts-ignore
        if(appContext.state.isEditedMode == true && getXByCoord(e.currentTarget.x()) >= 0 && getYByCoord(e.currentTarget.y()) >= 0){
            let id = Number(e.currentTarget.id());
            // build anchors
            let dragedPoints: any = [];
            

            for (let i = 0; i < areas13.length; i++) {
                dragedPoints[i] = areas13[i];
            }

            // @ts-ignore
            dragedPoints[id] = {x:  getXByCoord(e.currentTarget.x()), y: getYByCoord(e.currentTarget.y())}
            setAreas13(dragedPoints);

            let l: any = [];
            for (let i = 0; i < areas13.length; i++) {
                // @ts-ignore
                l.push(dragedPoints[i].x);
                // @ts-ignore
                l.push(dragedPoints[i].y);
            }
            // @ts-ignore
            setLines13(l);

            calcPolygonPointsCallback(dragedPoints)
        }
        else{e.target.draggable(false)};
    }
    let onDragMoveHandler14 = (e: any) => {
        // @ts-ignore
        if(appContext.state.isEditedMode == true && getXByCoord(e.currentTarget.x()) >= 0 && getYByCoord(e.currentTarget.y()) >= 0){
            let id = Number(e.currentTarget.id());
            // build anchors
            let dragedPoints: any = [];
            

            for (let i = 0; i < areas14.length; i++) {
                dragedPoints[i] = areas14[i];
            }

            // @ts-ignore
            dragedPoints[id] = {x:  getXByCoord(e.currentTarget.x()), y: getYByCoord(e.currentTarget.y())}
            setAreas14(dragedPoints);

            let l: any = [];
            for (let i = 0; i < areas14.length; i++) {
                // @ts-ignore
                l.push(dragedPoints[i].x);
                // @ts-ignore
                l.push(dragedPoints[i].y);
            }
            // @ts-ignore
            setLines14(l);

            calcPolygonPointsCallback(dragedPoints)
        }
        else{e.target.draggable(false)};
    }
    let onDragMoveHandler15 = (e: any) => {
        // @ts-ignore
        if(appContext.state.isEditedMode == true && getXByCoord(e.currentTarget.x()) >= 0 && getYByCoord(e.currentTarget.y()) >= 0){
            let id = Number(e.currentTarget.id());
            // build anchors
            let dragedPoints: any = [];
            

            for (let i = 0; i < areas15.length; i++) {
                dragedPoints[i] = areas15[i];
            }

            // @ts-ignore
            dragedPoints[id] = {x:  getXByCoord(e.currentTarget.x()), y: getYByCoord(e.currentTarget.y())}
            setAreas15(dragedPoints);

            let l: any = [];
            for (let i = 0; i < areas15.length; i++) {
                // @ts-ignore
                l.push(dragedPoints[i].x);
                // @ts-ignore
                l.push(dragedPoints[i].y);
            }
            // @ts-ignore
            setLines15(l);

            calcPolygonPointsCallback(dragedPoints)
        }
        else{e.target.draggable(false)};
    }
    let onDragMoveHandler16 = (e: any) => {
        // @ts-ignore
        if(appContext.state.isEditedMode == true && getXByCoord(e.currentTarget.x()) >= 0 && getYByCoord(e.currentTarget.y()) >= 0){
            let id = Number(e.currentTarget.id());
            // build anchors
            let dragedPoints: any = [];
            

            for (let i = 0; i < areas16.length; i++) {
                dragedPoints[i] = areas16[i];
            }

            // @ts-ignore
            dragedPoints[id] = {x:  getXByCoord(e.currentTarget.x()), y: getYByCoord(e.currentTarget.y())}
            setAreas16(dragedPoints);

            let l: any = [];
            for (let i = 0; i < areas16.length; i++) {
                // @ts-ignore
                l.push(dragedPoints[i].x);
                // @ts-ignore
                l.push(dragedPoints[i].y);
            }
            // @ts-ignore
            setLines16(l);

            calcPolygonPointsCallback(dragedPoints)
        }
        else{e.target.draggable(false)};
    }
    let onDragMoveHandler17 = (e: any) => {
        // @ts-ignore
        if(appContext.state.isEditedMode == true && getXByCoord(e.currentTarget.x()) >= 0 && getYByCoord(e.currentTarget.y()) >= 0){
            let id = Number(e.currentTarget.id());
            // build anchors
            let dragedPoints: any = [];
            

            for (let i = 0; i < areas17.length; i++) {
                dragedPoints[i] = areas17[i];
            }
            
            // @ts-ignore
            dragedPoints[id] = {x:  getXByCoord(e.currentTarget.x()), y: getYByCoord(e.currentTarget.y())}
            setAreas17(dragedPoints);

            let l: any = [];
            for (let i = 0; i < areas17.length; i++) {
                // @ts-ignore
                l.push(dragedPoints[i].x);
                // @ts-ignore
                l.push(dragedPoints[i].y);
            }
            // @ts-ignore
            setLines17(l);

            calcPolygonPointsCallback(dragedPoints)
        }
        else{e.target.draggable(false)};
    }
    let onDragMoveHandler18 = (e: any) => {
        // @ts-ignore
        if(appContext.state.isEditedMode == true && getXByCoord(e.currentTarget.x()) >= 0 && getYByCoord(e.currentTarget.y()) >= 0){
            let id = Number(e.currentTarget.id());
            // build anchors
            let dragedPoints: any = [];
            

            for (let i = 0; i < areas18.length; i++) {
                dragedPoints[i] = areas18[i];
            }
            
            // @ts-ignore
            dragedPoints[id] = {x:  getXByCoord(e.currentTarget.x()), y: getYByCoord(e.currentTarget.y())}
            setAreas18(dragedPoints);

            let l: any = [];
            for (let i = 0; i < areas18.length; i++) {
                // @ts-ignore
                l.push(dragedPoints[i].x);
                // @ts-ignore
                l.push(dragedPoints[i].y);
            }
            // @ts-ignore
            setLines18(l);

            calcPolygonPointsCallback(dragedPoints)
        }
        else{e.target.draggable(false)};
    }
    let onDragMoveHandler19 = (e: any) => {
        // @ts-ignore
        if(appContext.state.isEditedMode == true && getXByCoord(e.currentTarget.x()) >= 0 && getYByCoord(e.currentTarget.y()) >= 0){
            let id = Number(e.currentTarget.id());
            // build anchors
            let dragedPoints: any = [];
            

            for (let i = 0; i < areas19.length; i++) {
                dragedPoints[i] = areas19[i];
            }
            
            // @ts-ignore
            dragedPoints[id] = {x:  getXByCoord(e.currentTarget.x()), y: getYByCoord(e.currentTarget.y())}
            setAreas19(dragedPoints);

            let l: any = [];
            for (let i = 0; i < areas19.length; i++) {
                // @ts-ignore
                l.push(dragedPoints[i].x);
                // @ts-ignore
                l.push(dragedPoints[i].y);
            }
            // @ts-ignore
            setLines19(l);

            calcPolygonPointsCallback(dragedPoints)
        }
        else{e.target.draggable(false)};
    }
    let onDragMoveHandler20 = (e: any) => {
        // @ts-ignore
        if(appContext.state.isEditedMode == true && getXByCoord(e.currentTarget.x()) >= 0 && getYByCoord(e.currentTarget.y()) >= 0){
            let id = Number(e.currentTarget.id());
            // build anchors
            let dragedPoints: any = [];
            

            for (let i = 0; i < areas20.length; i++) {
                dragedPoints[i] = areas20[i];
            }
            // @ts-ignore
            dragedPoints[id] = {x:  getXByCoord(e.currentTarget.x()), y: getYByCoord(e.currentTarget.y())}
            setAreas20(dragedPoints);

            let l: any = [];
            for (let i = 0; i < areas20.length; i++) {
                // @ts-ignore
                l.push(dragedPoints[i].x);
                // @ts-ignore
                l.push(dragedPoints[i].y);
            }
            // @ts-ignore
            setLines20(l);
            

            calcPolygonPointsCallback(dragedPoints)
        }
        else{e.target.draggable(false)};
    }
    







    let onDragMoveHandler1Copy = (e: any) => {
        // @ts-ignore
        if(appContext.state.isEditedMode == true && getXByCoord(e.currentTarget.x()) >= 0 && getYByCoord(e.currentTarget.y()) >= 0){
            let id = Number(e.currentTarget.id());
            // build anchors
            let dragedPoints: any = [];
            

            for (let i = 0; i < areas1.length; i++) {
                dragedPoints[i] = areas1[i];
            }
            if(areasCount == 0){
                // @ts-ignore
                let xShift = areas1[id].x - getXByCoord(e.currentTarget.x())
                // @ts-ignore
                let yShift = areas1[id].y - getYByCoord(e.currentTarget.y())
                for (let i = 0; i < areas1.length; i++) {
                    dragedPoints[i] = {x: areas1[i].x - xShift, y: areas1[i].y - yShift};
                }
                setAreas1Copy(dragedPoints);
            }
            if(areasCount !== 0){
                // @ts-ignore
                dragedPoints[id] = {x:  getXByCoord(e.currentTarget.x()), y: getYByCoord(e.currentTarget.y())}
                setAreas1(dragedPoints);

                let l: any = [];
                for (let i = 0; i < areas1.length; i++) {
                    // @ts-ignore
                    l.push(dragedPoints[i].x);
                    // @ts-ignore
                    l.push(dragedPoints[i].y);
                }
                // @ts-ignore
                setLines1(l);
            }

            calcPolygonPointsCallback(dragedPoints)
        }
        else{e.target.draggable(false);}
    }
    let onDragMoveHandler2Copy = (e: any) => {
        // @ts-ignore
        if(appContext.state.isEditedMode == true && getXByCoord(e.currentTarget.x()) >= 0 && getYByCoord(e.currentTarget.y()) >= 0){
            let id = Number(e.currentTarget.id());
            // build anchors
            let dragedPoints: any = [];
            for (let i = 0; i < areas2.length; i++) {
                dragedPoints[i] = areas2[i];
            }
            // @ts-ignore
            let xShift = areas2[id].x - getXByCoord(e.currentTarget.x())
            // @ts-ignore
            let yShift = areas2[id].y - getYByCoord(e.currentTarget.y())
            for (let i = 0; i < areas2.length; i++) {
                dragedPoints[i] = {x: areas2[i].x - xShift, y: areas2[i].y - yShift};
            }
            setAreas2Copy(dragedPoints);

            calcPolygonPointsCallback(dragedPoints)
        }
        else{e.target.draggable(false);}
    }
    let onDragMoveHandler3Copy = (e: any) => {
        // @ts-ignore
        if(appContext.state.isEditedMode == true && getXByCoord(e.currentTarget.x()) >= 0 && getYByCoord(e.currentTarget.y()) >= 0){
            let id = Number(e.currentTarget.id());
            // build anchors
            let dragedPoints: any = [];
            
            for (let i = 0; i < areas3.length; i++) {
                dragedPoints[i] = areas3[i];
            }
            // @ts-ignore
            let xShift = areas3[id].x - getXByCoord(e.currentTarget.x())
            // @ts-ignore
            let yShift = areas3[id].y - getYByCoord(e.currentTarget.y())
            for (let i = 0; i < areas3.length; i++) {
                dragedPoints[i] = {x: areas3[i].x - xShift, y: areas3[i].y - yShift};
            }
            setAreas3Copy(dragedPoints);
            
            calcPolygonPointsCallback(dragedPoints)
        }
        else{e.target.draggable(false);}
    }
    let onDragMoveHandler4Copy = (e: any) => {
        // @ts-ignore
        if(appContext.state.isEditedMode == true && getXByCoord(e.currentTarget.x()) >= 0 && getYByCoord(e.currentTarget.y()) >= 0){
            let id = Number(e.currentTarget.id());
            // build anchors
            let dragedPoints: any = [];
            

            for (let i = 0; i < areas4.length; i++) {
                dragedPoints[i] = areas4[i];
            }
            // @ts-ignore
            let xShift = areas4[id].x - getXByCoord(e.currentTarget.x())
            // @ts-ignore
            let yShift = areas4[id].y - getYByCoord(e.currentTarget.y())
            for (let i = 0; i < areas4.length; i++) {
                dragedPoints[i] = {x: areas4[i].x - xShift, y: areas4[i].y - yShift};
            }
            setAreas4Copy(dragedPoints);

            calcPolygonPointsCallback(dragedPoints)
        }
        else{e.target.draggable(false)};
    }
    let onDragMoveHandler5Copy = (e: any) => {
        // @ts-ignore
        if(appContext.state.isEditedMode == true && getXByCoord(e.currentTarget.x()) >= 0 && getYByCoord(e.currentTarget.y()) >= 0){
            let id = Number(e.currentTarget.id());
            // build anchors
            let dragedPoints: any = [];
            

            for (let i = 0; i < areas5.length; i++) {
                dragedPoints[i] = areas5[i];
            }
            // @ts-ignore
            let xShift = areas5[id].x - getXByCoord(e.currentTarget.x())
            // @ts-ignore
            let yShift = areas5[id].y - getYByCoord(e.currentTarget.y())
            for (let i = 0; i < areas5.length; i++) {
                dragedPoints[i] = {x: areas5[i].x - xShift, y: areas5[i].y - yShift};
            }
            setAreas5Copy(dragedPoints);

            calcPolygonPointsCallback(dragedPoints)
        }
        else{e.target.draggable(false)};
    }
    let onDragMoveHandler6Copy = (e: any) => {
        // @ts-ignore
        if(appContext.state.isEditedMode == true && getXByCoord(e.currentTarget.x()) >= 0 && getYByCoord(e.currentTarget.y()) >= 0){
            let id = Number(e.currentTarget.id());
            // build anchors
            let dragedPoints: any = [];
            

            for (let i = 0; i < areas6.length; i++) {
                dragedPoints[i] = areas6[i];
            }
            // @ts-ignore
            let xShift = areas6[id].x - getXByCoord(e.currentTarget.x())
            // @ts-ignore
            let yShift = areas6[id].y - getYByCoord(e.currentTarget.y())
            for (let i = 0; i < areas6.length; i++) {
                dragedPoints[i] = {x: areas6[i].x - xShift, y: areas6[i].y - yShift};
            }
            setAreas6Copy(dragedPoints);

            calcPolygonPointsCallback(dragedPoints)
        }
        else{e.target.draggable(false)};
    }
    let onDragMoveHandler7Copy = (e: any) => {
        // @ts-ignore
        if(appContext.state.isEditedMode == true && getXByCoord(e.currentTarget.x()) >= 0 && getYByCoord(e.currentTarget.y()) >= 0){
            let id = Number(e.currentTarget.id());
            // build anchors
            let dragedPoints: any = [];
            

            for (let i = 0; i < areas7.length; i++) {
                dragedPoints[i] = areas7[i];
            }
            // @ts-ignore
            let xShift = areas7[id].x - getXByCoord(e.currentTarget.x())
            // @ts-ignore
            let yShift = areas7[id].y - getYByCoord(e.currentTarget.y())
            for (let i = 0; i < areas7.length; i++) {
                dragedPoints[i] = {x: areas7[i].x - xShift, y: areas7[i].y - yShift};
            }
            setAreas7Copy(dragedPoints);
            
            calcPolygonPointsCallback(dragedPoints)
        }
        else{e.target.draggable(false)};
    }
    let onDragMoveHandler8Copy = (e: any) => {
        // @ts-ignore
        if(appContext.state.isEditedMode == true && getXByCoord(e.currentTarget.x()) >= 0 && getYByCoord(e.currentTarget.y()) >= 0){
            let id = Number(e.currentTarget.id());
            // build anchors
            let dragedPoints: any = [];
            

            for (let i = 0; i < areas8.length; i++) {
                dragedPoints[i] = areas8[i];
            }
            // @ts-ignore
            let xShift = areas8[id].x - getXByCoord(e.currentTarget.x())
            // @ts-ignore
            let yShift = areas8[id].y - getYByCoord(e.currentTarget.y())
            for (let i = 0; i < areas8.length; i++) {
                dragedPoints[i] = {x: areas8[i].x - xShift, y: areas8[i].y - yShift};
            }
            setAreas8Copy(dragedPoints);
           
            calcPolygonPointsCallback(dragedPoints)
        }
        else{e.target.draggable(false)};
    }
    let onDragMoveHandler9Copy = (e: any) => {
        // @ts-ignore
        if(appContext.state.isEditedMode == true && getXByCoord(e.currentTarget.x()) >= 0 && getYByCoord(e.currentTarget.y()) >= 0){
            let id = Number(e.currentTarget.id());
            // build anchors
            let dragedPoints: any = [];
            

            for (let i = 0; i < areas9.length; i++) {
                dragedPoints[i] = areas9[i];
            }
            // @ts-ignore
            let xShift = areas9[id].x - getXByCoord(e.currentTarget.x())
            // @ts-ignore
            let yShift = areas9[id].y - getYByCoord(e.currentTarget.y())
            for (let i = 0; i < areas9.length; i++) {
                dragedPoints[i] = {x: areas9[i].x - xShift, y: areas9[i].y - yShift};
            }
            setAreas9Copy(dragedPoints);
            
            calcPolygonPointsCallback(dragedPoints)
        }
        else{e.target.draggable(false)};
    }
    let onDragMoveHandler10Copy = (e: any) => {
        // @ts-ignore
        if(appContext.state.isEditedMode == true && getXByCoord(e.currentTarget.x()) >= 0 && getYByCoord(e.currentTarget.y()) >= 0){
            let id = Number(e.currentTarget.id());
            // build anchors
            let dragedPoints: any = [];
            

            for (let i = 0; i < areas10.length; i++) {
                dragedPoints[i] = areas10[i];
            }
            // @ts-ignore
            let xShift = areas10[id].x - getXByCoord(e.currentTarget.x())
            // @ts-ignore
            let yShift = areas10[id].y - getYByCoord(e.currentTarget.y())
            for (let i = 0; i < areas10.length; i++) {
                dragedPoints[i] = {x: areas10[i].x - xShift, y: areas10[i].y - yShift};
            }
            setAreas10Copy(dragedPoints);
            
            calcPolygonPointsCallback(dragedPoints)
        }
        else{e.target.draggable(false)};
    }
    let onDragMoveHandler11Copy = (e: any) => {
        // @ts-ignore
        if(appContext.state.isEditedMode == true && getXByCoord(e.currentTarget.x()) >= 0 && getYByCoord(e.currentTarget.y()) >= 0){
            let id = Number(e.currentTarget.id());
            // build anchors
            let dragedPoints: any = [];
            

            for (let i = 0; i < areas11.length; i++) {
                dragedPoints[i] = areas11[i];
            }
            // @ts-ignore
            let xShift = areas11[id].x - getXByCoord(e.currentTarget.x())
            // @ts-ignore
            let yShift = areas11[id].y - getYByCoord(e.currentTarget.y())
            for (let i = 0; i < areas11.length; i++) {
                dragedPoints[i] = {x: areas11[i].x - xShift, y: areas11[i].y - yShift};
            }
            setAreas11Copy(dragedPoints);
            
            calcPolygonPointsCallback(dragedPoints)
        }
        else{e.target.draggable(false)};
    }
    let onDragMoveHandler12Copy = (e: any) => {
        // @ts-ignore
        if(appContext.state.isEditedMode == true && getXByCoord(e.currentTarget.x()) >= 0 && getYByCoord(e.currentTarget.y()) >= 0){
            let id = Number(e.currentTarget.id());
            // build anchors
            let dragedPoints: any = [];
            

            for (let i = 0; i < areas12.length; i++) {
                dragedPoints[i] = areas12[i];
            }
            // @ts-ignore
            let xShift = areas12[id].x - getXByCoord(e.currentTarget.x())
            // @ts-ignore
            let yShift = areas12[id].y - getYByCoord(e.currentTarget.y())
            for (let i = 0; i < areas12.length; i++) {
                dragedPoints[i] = {x: areas12[i].x - xShift, y: areas12[i].y - yShift};
            }
            setAreas12Copy(dragedPoints);
           
            calcPolygonPointsCallback(dragedPoints)
        }
        else{e.target.draggable(false)};
    }
    let onDragMoveHandler13Copy = (e: any) => {
        // @ts-ignore
        if(appContext.state.isEditedMode == true && getXByCoord(e.currentTarget.x()) >= 0 && getYByCoord(e.currentTarget.y()) >= 0){
            let id = Number(e.currentTarget.id());
            // build anchors
            let dragedPoints: any = [];
            

            for (let i = 0; i < areas13.length; i++) {
                dragedPoints[i] = areas13[i];
            }
            // @ts-ignore
            let xShift = areas13[id].x - getXByCoord(e.currentTarget.x())
            // @ts-ignore
            let yShift = areas13[id].y - getYByCoord(e.currentTarget.y())
            for (let i = 0; i < areas13.length; i++) {
                dragedPoints[i] = {x: areas13[i].x - xShift, y: areas13[i].y - yShift};
            }
            setAreas13Copy(dragedPoints);

            calcPolygonPointsCallback(dragedPoints)
        }
        else{e.target.draggable(false)};
    }
    let onDragMoveHandler14Copy = (e: any) => {
        // @ts-ignore
        if(appContext.state.isEditedMode == true && getXByCoord(e.currentTarget.x()) >= 0 && getYByCoord(e.currentTarget.y()) >= 0){
            let id = Number(e.currentTarget.id());
            // build anchors
            let dragedPoints: any = [];
            

            for (let i = 0; i < areas14.length; i++) {
                dragedPoints[i] = areas14[i];
            }
            // @ts-ignore
            let xShift = areas14[id].x - getXByCoord(e.currentTarget.x())
            // @ts-ignore
            let yShift = areas14[id].y - getYByCoord(e.currentTarget.y())
            for (let i = 0; i < areas14.length; i++) {
                dragedPoints[i] = {x: areas14[i].x - xShift, y: areas14[i].y - yShift};
            }
            setAreas14Copy(dragedPoints);
           
            calcPolygonPointsCallback(dragedPoints)
        }
        else{e.target.draggable(false)};
    }
    let onDragMoveHandler15Copy = (e: any) => {
        // @ts-ignore
        if(appContext.state.isEditedMode == true && getXByCoord(e.currentTarget.x()) >= 0 && getYByCoord(e.currentTarget.y()) >= 0){
            let id = Number(e.currentTarget.id());
            // build anchors
            let dragedPoints: any = [];
            

            for (let i = 0; i < areas15.length; i++) {
                dragedPoints[i] = areas15[i];
            }
            // @ts-ignore
            let xShift = areas15[id].x - getXByCoord(e.currentTarget.x())
            // @ts-ignore
            let yShift = areas15[id].y - getYByCoord(e.currentTarget.y())
            for (let i = 0; i < areas15.length; i++) {
                dragedPoints[i] = {x: areas15[i].x - xShift, y: areas15[i].y - yShift};
            }
            setAreas15Copy(dragedPoints);
            
            calcPolygonPointsCallback(dragedPoints)
        }
        else{e.target.draggable(false)};
    }
    let onDragMoveHandler16Copy = (e: any) => {
        // @ts-ignore
        if(appContext.state.isEditedMode == true && getXByCoord(e.currentTarget.x()) >= 0 && getYByCoord(e.currentTarget.y()) >= 0){
            let id = Number(e.currentTarget.id());
            // build anchors
            let dragedPoints: any = [];
            

            for (let i = 0; i < areas16.length; i++) {
                dragedPoints[i] = areas16[i];
            }
            // @ts-ignore
            let xShift = areas16[id].x - getXByCoord(e.currentTarget.x())
            // @ts-ignore
            let yShift = areas16[id].y - getYByCoord(e.currentTarget.y())
            for (let i = 0; i < areas16.length; i++) {
                dragedPoints[i] = {x: areas16[i].x - xShift, y: areas16[i].y - yShift};
            }
            setAreas16Copy(dragedPoints);
            
            calcPolygonPointsCallback(dragedPoints)
        }
        else{e.target.draggable(false)};
    }
    let onDragMoveHandler17Copy = (e: any) => {
        // @ts-ignore
        if(appContext.state.isEditedMode == true && getXByCoord(e.currentTarget.x()) >= 0 && getYByCoord(e.currentTarget.y()) >= 0){
            let id = Number(e.currentTarget.id());
            // build anchors
            let dragedPoints: any = [];
            

            for (let i = 0; i < areas17.length; i++) {
                dragedPoints[i] = areas17[i];
            }
            // @ts-ignore
            let xShift = areas17[id].x - getXByCoord(e.currentTarget.x())
            // @ts-ignore
            let yShift = areas17[id].y - getYByCoord(e.currentTarget.y())
            for (let i = 0; i < areas17.length; i++) {
                dragedPoints[i] = {x: areas17[i].x - xShift, y: areas17[i].y - yShift};
            }
            setAreas17Copy(dragedPoints);
            
            calcPolygonPointsCallback(dragedPoints)
        }
        else{e.target.draggable(false)};
    }
    let onDragMoveHandler18Copy = (e: any) => {
        // @ts-ignore
        if(appContext.state.isEditedMode == true && getXByCoord(e.currentTarget.x()) >= 0 && getYByCoord(e.currentTarget.y()) >= 0){
            let id = Number(e.currentTarget.id());
            // build anchors
            let dragedPoints: any = [];
            

            for (let i = 0; i < areas18.length; i++) {
                dragedPoints[i] = areas18[i];
            }
            // @ts-ignore
            let xShift = areas18[id].x - getXByCoord(e.currentTarget.x())
            // @ts-ignore
            let yShift = areas18[id].y - getYByCoord(e.currentTarget.y())
            for (let i = 0; i < areas18.length; i++) {
                dragedPoints[i] = {x: areas18[i].x - xShift, y: areas18[i].y - yShift};
            }
            setAreas18Copy(dragedPoints);
           
            calcPolygonPointsCallback(dragedPoints)
        }
        else{e.target.draggable(false)};
    }
    let onDragMoveHandler19Copy = (e: any) => {
        // @ts-ignore
        if(appContext.state.isEditedMode == true && getXByCoord(e.currentTarget.x()) >= 0 && getYByCoord(e.currentTarget.y()) >= 0){
            let id = Number(e.currentTarget.id());
            // build anchors
            let dragedPoints: any = [];
            

            for (let i = 0; i < areas19.length; i++) {
                dragedPoints[i] = areas19[i];
            }
            // @ts-ignore
            let xShift = areas19[id].x - getXByCoord(e.currentTarget.x())
            // @ts-ignore
            let yShift = areas19[id].y - getYByCoord(e.currentTarget.y())
            for (let i = 0; i < areas19.length; i++) {
                dragedPoints[i] = {x: areas19[i].x - xShift, y: areas19[i].y - yShift};
            }
            setAreas19Copy(dragedPoints);
            
            calcPolygonPointsCallback(dragedPoints)
        }
        else{e.target.draggable(false)};
    }
    let onDragMoveHandler20Copy = (e: any) => {
        // @ts-ignore
        if(appContext.state.isEditedMode == true && getXByCoord(e.currentTarget.x()) >= 0 && getYByCoord(e.currentTarget.y()) >= 0){
            let id = Number(e.currentTarget.id());
            // build anchors
            let dragedPoints: any = [];
            

            for (let i = 0; i < areas20.length; i++) {
                dragedPoints[i] = areas20[i];
            }
            // @ts-ignore
            let xShift = areas20[id].x - getXByCoord(e.currentTarget.x())
            // @ts-ignore
            let yShift = areas20[id].y - getYByCoord(e.currentTarget.y())
            for (let i = 0; i < areas20.length; i++) {
                dragedPoints[i] = {x: areas20[i].x - xShift, y: areas20[i].y - yShift};
            }
            setAreas20Copy(dragedPoints);
            
            calcPolygonPointsCallback(dragedPoints)
        }
        else{e.target.draggable(false)};
    }

    

    const activeDott = (id: any, x: any, y: any) =>{
        if(appContext.state.isEditedMode == true){
            let dottId = id
            setlastClickedDott(dottId)
            setQwe(dottId)
            setOnDottClick(false)
            setActiveDottCoordsX(x)
            setActiveDottCoordsY(y)
        }
    }
    React.useEffect(() => {
        if(lastClickedDott !== null){
            if(areasCount == -1){
                if(lastClickedDott < points.length -1){
                    // @ts-ignore
                    setLastClickedDottLine([getXCoord(points[lastClickedDott].x), getYCoord(points[lastClickedDott].y), getXCoord(points[lastClickedDott + 1].x), getYCoord(points[lastClickedDott + 1].y)])
                }
                if(lastClickedDott == points.length - 1){
                    // @ts-ignore
                    setLastClickedDottLine([getXCoord(points[lastClickedDott].x), getYCoord(points[lastClickedDott].y), getXCoord(points[0].x), getYCoord(points[0].y)])
                }
            }
            if(areasCount !== -1){
                if(lastClickedDott < eval("areas" + areasCount).length -1){
                    // @ts-ignore
                    setLastClickedDottLine([getXCoord(eval("areas" + areasCount)[lastClickedDott].x), getYCoord(eval("areas" + areasCount)[lastClickedDott].y), getXCoord(eval("areas" + areasCount)[lastClickedDott + 1].x), getYCoord(eval("areas" + areasCount)[lastClickedDott + 1].y)])
                }
                if(lastClickedDott == eval("areas" + areasCount).length - 1){
                    // @ts-ignore
                    setLastClickedDottLine([getXCoord(eval("areas" + areasCount)[lastClickedDott].x), getYCoord(eval("areas" + areasCount)[lastClickedDott].y), getXCoord(eval("areas" + areasCount)[0].x), getYCoord(eval("areas" + areasCount)[0].y)])
                }
            }
        }
        else{setLastClickedDottLine([])}
    },[lastClickedDott])

    const activeDott1 = (id: any, x: any, y: any) =>{
        if(appContext.state.isEditedMode == true){
            let dottId = id
            setlastClickedDott(dottId)
            setActiveDottCoordsX(x)
            setActiveDottCoordsY(y)
            setOnDottClick(false)
            setQx(id)
            setiou(true)
        }
    }
    
    React.useEffect(() => {
        if(turnDottArea == 0 && turnDottId == null){
            setTurnDottArea(activeDottArea)
            setTurnDottId(activeDottId)
        }
        if(turnDottArea == activeDottArea){
            setTurnDottArea(activeDottArea)
            setTurnDottId(activeDottId)
        }

        else{
            setTurnLastDottArea(turnDottArea)
            setTurnLastDottId(turnDottId)
            setTurnDottArea(activeDottArea)
            setTurnDottId(activeDottId)
        }
        setActiveDottChanged(false)

    },[activeDottChanged])
    React.useEffect(() => {
        if(turnLastDottArea !== 0 && turnLastDottId !== null){

            let dragedPoints: any = [];
            // @ts-ignore
            let xShift = Number((eval("areas" + turnDottArea + "Copy")[turnDottId].x - eval("areas" + turnLastDottArea + "Copy")[turnLastDottId].x).toFixed(2))
            // @ts-ignore
            let yShift = Number((eval("areas" + turnDottArea + "Copy")[turnDottId].y - eval("areas" + turnLastDottArea + "Copy")[turnLastDottId].y).toFixed(2))

            for (let k = 0; k < eval("areas" + turnLastDottArea + "Copy").length; k++) {
                dragedPoints[k] = {x: Number((eval("areas" + turnLastDottArea + "Copy")[k].x + xShift).toFixed(2)), y: Number((eval("areas" + turnLastDottArea + "Copy")[k].y + yShift).toFixed(2))};
            }
    
            eval("setAreas" + turnLastDottArea + "Copy")(dragedPoints);
            setTurnLastDottArea(0)
            setTurnLastDottId(null)
            setTurnDottArea(0)
            setTurnDottCoordsX(null); 
            setTurnDottCoordsY(null);
            setTurnDottId(null)
        }
    },[turnLastDottArea, turnLastDottId])
    React.useEffect(() => {
        if(turnFigure == true && turnDottAreaText > 0){
            if(moveDottId !== null){
                let dragedPoints: any = []
                for (let i = 0; i < eval("areas" + turnDottAreaText + "Copy").length; i++) {
                    dragedPoints[i] = eval("areas" + turnDottAreaText + "Copy")[i];
                }
                let rad : any;
                rad = - (Math.PI / 180) * degreesTurnFigure
                for (let i = 0; i < eval("areas" + turnDottAreaText + "Copy").length; i++) {
                    // @ts-ignore
                    dragedPoints[i] = {x: eval("areas" + turnDottAreaText + "Copy")[moveDottId].x + (eval("areas" + turnDottAreaText + "Copy")[i].x - eval("areas" + turnDottAreaText + "Copy")[moveDottId].x) * Math.cos(rad) - (eval("areas" + turnDottAreaText + "Copy")[i].y - eval("areas" + turnDottAreaText + "Copy")[moveDottId].y) * Math.sin(rad), y: eval("areas" + turnDottAreaText + "Copy")[moveDottId].y + (eval("areas" + turnDottAreaText + "Copy")[i].x - eval("areas" + turnDottAreaText + "Copy")[moveDottId].x) * Math.sin(rad) + (eval("areas" + turnDottAreaText + "Copy")[i].y - eval("areas" + turnDottAreaText + "Copy")[moveDottId].y) * Math.cos(rad)};
                }
                eval("setAreas" + turnDottAreaText + "Copy")(dragedPoints)
            }
            else{alert("Виберіть будь ласка точку для обертання")}
        }
        setTurnFigure(false)
        setDegreesTurnFigure(null)
    },[turnFigure])
    React.useEffect(() => {
        if(turnDottAreaText == 0){
            setMoveDottId(null)
            setMoveDottCoordsX(null); 
            setMoveDottCoordsY(null);
        }
    },[turnDottAreaText])

    const activeDott3 = (x: any, y: any, id: any, area: any, turnArea: any) =>{
        if(turnDottAreaText == 0){
            setActiveDottArea(area)
            setActiveDottId(id)
            setTurnDottCoordsX(x); 
            setTurnDottCoordsY(y);
            setActiveDottChanged(true)
        }
        if(turnDottAreaText !== 0){
            for(let i = 0; i < eval("areas" + turnDottAreaText + "Copy").length; i++){
                if(eval("areas" + turnDottAreaText + "Copy")[i].x == getXByCoord(x) &&  eval("areas" + turnDottAreaText + "Copy")[i].y == getYByCoord(y)){
                    // @ts-ignore
                    setMoveDottId(i)
                }
            }
            setMoveDottCoordsX(x); 
            setMoveDottCoordsY(y);
        }
    }
    
    if(addApex == true && iou == true && appContext.state.isEditedMode == true && qx !== null){
        if(areasCount == 1){
            let l:any = Apex1Id
            l.push(qx)
            setApex1Id(l);
        }
        if(areasCount == 2){
            let l:any = Apex2Id
            l.push(qx)
            setApex2Id(l);
        }
        if(areasCount == 3){
            let l:any = Apex3Id
            l.push(qx)
            setApex3Id(l);
        }
        if(areasCount == 4){
            let l:any = Apex4Id
            l.push(qx)
            setApex4Id(l);
        }
        if(areasCount == 5){
            let l:any = Apex5Id
            l.push(qx)
            setApex5Id(l);
        }
        if(areasCount == 6){
            let l:any = Apex6Id
            l.push(qx)
            setApex6Id(l);
        }
        if(areasCount == 7){
            let l:any = Apex7Id
            l.push(qx)
            setApex7Id(l);
        }
        if(areasCount == 8){
            let l:any = Apex8Id
            l.push(qx)
            setApex8Id(l);
        }
        if(areasCount == 9){
            let l:any = Apex9Id
            l.push(qx)
            setApex9Id(l);
        }
        if(areasCount == 10){
            let l:any = Apex10Id
            l.push(qx)
            setApex10Id(l);
        }
        if(areasCount == 11){
            let l:any = Apex11Id
            l.push(qx)
            setApex11Id(l);
        }
        if(areasCount == 12){
            let l:any = Apex12Id
            l.push(qx)
            setApex12Id(l);
        }
        if(areasCount == 13){
            let l:any = Apex13Id
            l.push(qx)
            setApex13Id(l);
        }
        if(areasCount == 14){
            let l:any = Apex14Id
            l.push(qx)
            setApex14Id(l);
        }
        if(areasCount == 15){
            let l:any = Apex15Id
            l.push(qx)
            setApex15Id(l);
        }
        if(areasCount == 16){
            let l:any = Apex16Id
            l.push(qx)
            setApex16Id(l);
        }
        if(areasCount == 17){
            let l:any = Apex17Id
            l.push(qx)
            setApex17Id(l);
        }
        if(areasCount == 18){
            let l:any = Apex18Id
            l.push(qx)
            setApex18Id(l);
        }
        if(areasCount == 19){
            let l:any = Apex19Id
            l.push(qx)
            setApex19Id(l);
        }
        if(areasCount == 20){
            let l:any = Apex20Id
            l.push(qx)
            setApex20Id(l);
        }
        setiou(false)
        setActiveDottCoordsX(null)
        setActiveDottCoordsY(null)
    }
    if(addEaves == true && iou == true && appContext.state.isEditedMode == true && qx !== null){
        if(areasCount == 1){
            let l:any = Eaves1Id
            l.push(qx)
            setEaves1Id(l);
        }
        if(areasCount == 2){
            let l:any = Eaves2Id
            l.push(qx)
            setEaves2Id(l);
        }
        if(areasCount == 3){
            let l:any = Eaves3Id
            l.push(qx)
            setEaves3Id(l);
        }
        if(areasCount == 4){
            let l:any = Eaves4Id
            l.push(qx)
            setEaves4Id(l);
        }
        if(areasCount == 5){
            let l:any = Eaves5Id
            l.push(qx)
            setEaves5Id(l);
        }
        if(areasCount == 6){
            let l:any = Eaves6Id
            l.push(qx)
            setEaves6Id(l);
        }
        if(areasCount == 7){
            let l:any = Eaves7Id
            l.push(qx)
            setEaves7Id(l);
        }
        if(areasCount == 8){
            let l:any = Eaves8Id
            l.push(qx)
            setEaves8Id(l);
        }
        if(areasCount == 9){
            let l:any = Eaves9Id
            l.push(qx)
            setEaves9Id(l);
        }
        if(areasCount == 10){
            let l:any = Eaves10Id
            l.push(qx)
            setEaves10Id(l);
        }
        if(areasCount == 11){
            let l:any = Eaves11Id
            l.push(qx)
            setEaves11Id(l);
        }
        if(areasCount == 12){
            let l:any = Eaves12Id
            l.push(qx)
            setEaves12Id(l);
        }
        if(areasCount == 13){
            let l:any = Eaves13Id
            l.push(qx)
            setEaves13Id(l);
        }
        if(areasCount == 14){
            let l:any = Eaves14Id
            l.push(qx)
            setEaves14Id(l);
        }
        if(areasCount == 15){
            let l:any = Eaves15Id
            l.push(qx)
            setEaves15Id(l);
        }
        if(areasCount == 16){
            let l:any = Eaves16Id
            l.push(qx)
            setEaves16Id(l);
        }
        if(areasCount == 17){
            let l:any = Eaves17Id
            l.push(qx)
            setEaves17Id(l);
        }
        if(areasCount == 18){
            let l:any = Eaves18Id
            l.push(qx)
            setEaves18Id(l);
        }
        if(areasCount == 19){
            let l:any = Eaves19Id
            l.push(qx)
            setEaves19Id(l);
        }
        if(areasCount == 20){
            let l:any = Eaves20Id
            l.push(qx)
            setEaves20Id(l);
        }
        setiou(false)
        setActiveDottCoordsX(null)
        setActiveDottCoordsY(null)
    }

        return(
            <>
                {
                    areasCount == 0 ?
                            <>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={lines1Copy}
                                        stroke={'blue'}
                                        strokeWidth={4}
                                        closed={isLinesClosed1 ? true : false} 
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={lines2Copy}
                                        stroke={'blue'}
                                        strokeWidth={4}
                                        closed={isLinesClosed2 ? true : false} 
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={lines3Copy}
                                        stroke={'blue'}
                                        strokeWidth={4}
                                        closed={isLinesClosed3 ? true : false} 
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={lines4Copy}
                                        stroke={'blue'}
                                        strokeWidth={4}
                                        closed={isLinesClosed4 ? true : false} 
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={lines5Copy}
                                        stroke={'blue'}
                                        strokeWidth={4}
                                        closed={isLinesClosed5 ? true : false} 
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={lines6Copy}
                                        stroke={'blue'}
                                        strokeWidth={4}
                                        closed={isLinesClosed6 ? true : false} 
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={lines7Copy}
                                        stroke={'blue'}
                                        strokeWidth={4}
                                        closed={isLinesClosed7 ? true : false} 
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={lines8Copy}
                                        stroke={'blue'}
                                        strokeWidth={4}
                                        closed={isLinesClosed8 ? true : false} 
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={lines9Copy}
                                        stroke={'blue'}
                                        strokeWidth={4}
                                        closed={isLinesClosed9 ? true : false} 
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={lines10Copy}
                                        stroke={'blue'}
                                        strokeWidth={4}
                                        closed={isLinesClosed10 ? true : false} 
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={lines11Copy}
                                        stroke={'blue'}
                                        strokeWidth={4}
                                        closed={isLinesClosed11 ? true : false} 
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={lines12Copy}
                                        stroke={'blue'}
                                        strokeWidth={4}
                                        closed={isLinesClosed12 ? true : false} 
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={lines13Copy}
                                        stroke={'blue'}
                                        strokeWidth={4}
                                        closed={isLinesClosed13 ? true : false} 
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={lines14Copy}
                                        stroke={'blue'}
                                        strokeWidth={4}
                                        closed={isLinesClosed14 ? true : false} 
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={lines15Copy}
                                        stroke={'blue'}
                                        strokeWidth={4}
                                        closed={isLinesClosed15 ? true : false} 
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={lines16Copy}
                                        stroke={'blue'}
                                        strokeWidth={4}
                                        closed={isLinesClosed16 ? true : false} 
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={lines17Copy}
                                        stroke={'blue'}
                                        strokeWidth={4}
                                        closed={isLinesClosed17 ? true : false} 
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={lines18Copy}
                                        stroke={'blue'}
                                        strokeWidth={4}
                                        closed={isLinesClosed18 ? true : false} 
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={lines19Copy}
                                        stroke={'blue'}
                                        strokeWidth={4}
                                        closed={isLinesClosed19 ? true : false} 
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={lines20Copy}
                                        stroke={'blue'}
                                        strokeWidth={4}
                                        closed={isLinesClosed20 ? true : false} 
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer style={{zIndex: 10}}>
                                    <Line ref={lineRef}
                                        points={eaves1Copy}
                                        stroke={'green'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={eaves2Copy}
                                        stroke={'green'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={eaves3Copy}
                                        stroke={'green'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={eaves4Copy}
                                        stroke={'green'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={eaves5Copy}
                                        stroke={'green'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={eaves6Copy}
                                        stroke={'green'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={eaves7Copy}
                                        stroke={'green'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={eaves8Copy}
                                        stroke={'green'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={eaves9Copy}
                                        stroke={'green'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={eaves10Copy}
                                        stroke={'green'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={eaves11Copy}
                                        stroke={'green'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={eaves12Copy}
                                        stroke={'green'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={eaves13Copy}
                                        stroke={'green'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={eaves14Copy}
                                        stroke={'green'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={eaves15Copy}
                                        stroke={'green'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={eaves16Copy}
                                        stroke={'green'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={eaves17Copy}
                                        stroke={'green'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={eaves18Copy}
                                        stroke={'green'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={eaves19Copy}
                                        stroke={'green'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={eaves20Copy}
                                        stroke={'green'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer style={{zIndex: 10}}>
                                    <Line ref={lineRef}
                                        points={apex1Copy}
                                        stroke={'red'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={apex2Copy}
                                        stroke={'red'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={apex3Copy}
                                        stroke={'red'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={apex4Copy}
                                        stroke={'red'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={apex5Copy}
                                        stroke={'red'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={apex6Copy}
                                        stroke={'red'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={apex7Copy}
                                        stroke={'red'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={apex8Copy}
                                        stroke={'red'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={apex9Copy}
                                        stroke={'red'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={apex10Copy}
                                        stroke={'red'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={apex11Copy}
                                        stroke={'red'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={apex12Copy}
                                        stroke={'red'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={apex13Copy}
                                        stroke={'red'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={apex14Copy}
                                        stroke={'red'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={apex15Copy}
                                        stroke={'red'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={apex16Copy}
                                        stroke={'red'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={apex17Copy}
                                        stroke={'red'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={apex18Copy}
                                        stroke={'red'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={apex19Copy}
                                        stroke={'red'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={apex20Copy}
                                        stroke={'red'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer style={{zIndex: 2}}>
                                    {builtCustomPoints1Copy}
                                    {builtCustomPoints2Copy}
                                    {builtCustomPoints3Copy}
                                    {builtCustomPoints4Copy}
                                    {builtCustomPoints5Copy}
                                    {builtCustomPoints6Copy}
                                    {builtCustomPoints7Copy}
                                    {builtCustomPoints8Copy}
                                    {builtCustomPoints9Copy}
                                    {builtCustomPoints10Copy}
                                    {builtCustomPoints11Copy}
                                    {builtCustomPoints12Copy}
                                    {builtCustomPoints13Copy}
                                    {builtCustomPoints14Copy}
                                    {builtCustomPoints15Copy}
                                    {builtCustomPoints16Copy}
                                    {builtCustomPoints17Copy}
                                    {builtCustomPoints18Copy}
                                    {builtCustomPoints19Copy}
                                    {builtCustomPoints20Copy}
                                </Layer>
                                <Layer style={{zIndex: 1000000000}}>
                                    <Circle
                                    // @ts-ignore
                                    x={turnDottCoordsX || -5000}
                                    // @ts-ignore
                                    y={turnDottCoordsY}
                                    radius = {8}
                                    stroke = {'red'}
                                    fill = {'#ddd'}
                                    strokeWidth = {2}
                                    />
                                </Layer>
                                <Layer style={{zIndex: 1000000000}}>
                                    <Circle
                                    // @ts-ignore
                                    x={moveDottCoordsX || -5000}
                                    // @ts-ignore
                                    y={moveDottCoordsY}
                                    radius = {8}
                                    stroke = {'green'}
                                    fill = {'#ddd'}
                                    strokeWidth = {2}
                                    />
                                </Layer>
                            </>
                    : null   
                }
                {
                    areasCount == -1?
                            <>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={lines}
                                        stroke={'blue'}
                                        strokeWidth={4}
                                        closed={isLineClosed ? true : false} 
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={linesForTestFigure}
                                        stroke={'blue'}
                                        strokeWidth={4}
                                        closed={true} 
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={addLinesForTestFigure}
                                        stroke={'black'}
                                        dash = {[7, 7]}
                                        strokeWidth={4}
                                        closed={false} 
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={addLinesForTestFigure1}
                                        stroke={'black'}
                                        dash = {[7, 7]}
                                        strokeWidth={4}
                                        closed={false} 
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={addLinesForTestFigure2}
                                        stroke={'black'}
                                        dash = {[7, 7]}
                                        strokeWidth={4}
                                        closed={false} 
                                        opacity={1}
                                    />
                                    <Line ref={lineRef}
                                        points={addLinesForTestFigure3}
                                        stroke={'black'}
                                        dash = {[7, 7]}
                                        strokeWidth={4}
                                        closed={false} 
                                        opacity={1}
                                    />
                                    {lettersForTestFigure}
                                </Layer>
                                {
                                    appContext.state.isEditedMode ?
                                            <>  
                                            <Layer>
                                                <Line
                                                    points={lastClickedDottLine}
                                                    stroke = {'red'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer>
                                                <Line
                                                    points={activeChangingLine}
                                                    stroke = {'red'}
                                                    strokeWidth = {4}
                                                />
                                            </Layer>
                                            <Layer style={{zIndex: 2}}>
                                                {builtCustomPoints}
                                            </Layer>
                                            <Layer style={{zIndex: 10}}>
                                                <Line ref={lineRef}
                                                    // @ts-ignore
                                                    points={[getXCoord(addpoint.x), 0, getXCoord(addpoint.x), -1000]}
                                                    stroke={'black'}
                                                    strokeWidth={2}
                                                    //closed={true}
                                                    dash = {[20, 35]}
                                                    opacity={1}
                                                />
                                                    <Circle key={0}
                                                    // @ts-ignore
                                                    x = {getXCoord(addpoint.x) || -5000}
                                                    // @ts-ignore
                                                    y = {getYCoord(addpoint.y) || 100}
                                                    radius = {8}
                                                    stroke = {'#666'}
                                                    fill = {'#ddd'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer style={{zIndex: 1000000000}}>
                                                <Circle
                                                // @ts-ignore
                                                x={activeDottCoordsX || -5000}
                                                // @ts-ignore
                                                y={activeDottCoordsY }
                                                radius = {8}
                                                stroke = {'red'}
                                                fill = {'#ddd'}
                                                strokeWidth = {2}
                                                />
                                            </Layer>
                                            
                                         </>
                                    : null
                                }
                            </>
                    : null   
                }
                {
                    areasCount == 1?
                            <>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={lines1}
                                        stroke={'blue'}
                                        strokeWidth={4}
                                        closed={isLinesClosed1 ? true : false} 
                                        opacity={1}
                                    />
                                    {cutsForBuildMode}
                                </Layer>
                                <Layer style={{zIndex: 10}}>
                                    <Line ref={lineRef}
                                        points={eaves1}
                                        stroke={'green'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer style={{zIndex: 10}}>
                                    <Line ref={lineRef}
                                        points={apex1}
                                        stroke={'red'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer style={{zIndex: 2}}>
                                    {builtSideSizes1}
                                </Layer>
                                {
                                    appContext.state.isEditedMode ?
                                        <>
                                            <Layer>
                                                <Line
                                                    points={lastClickedDottLine}
                                                    stroke = {'red'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer>
                                                <Line
                                                    points={activeChangingLine}
                                                    stroke = {'red'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer style={{zIndex: 2}}>
                                                {builtCustomPoints1}
                                            </Layer>
                                            <Layer style={{zIndex: 10}}>
                                                <Line ref={lineRef}
                                                    // @ts-ignore
                                                    points={[getXCoord(addpoint.x), 0, getXCoord(addpoint.x), -1000]}
                                                    stroke={'black'}
                                                    strokeWidth={2}
                                                    //closed={true}
                                                    dash = {[20, 35]}
                                                    opacity={1}
                                                />
                                                    <Circle key={0}
                                                    // @ts-ignore
                                                    x = {getXCoord(addpoint.x) || -5000}
                                                    // @ts-ignore
                                                    y = {getYCoord(addpoint.y) || 100}
                                                    radius = {8}
                                                    stroke = {'#666'}
                                                    fill = {'#ddd'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer style={{zIndex: 1000000000}}>
                                                <Circle
                                                // @ts-ignore
                                                x={activeDottCoordsX || -5000}
                                                // @ts-ignore
                                                y={activeDottCoordsY }
                                                radius = {8}
                                                stroke = {'red'}
                                                fill = {'#ddd'}
                                                strokeWidth = {2}
                                                />
                                            </Layer>
                                        </>
                                    : null
                                }
                            </>
                    : null   
                }
                {
                    areasCount == 2 ?
                            <>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={lines2}
                                        stroke={'blue'}
                                        strokeWidth={4}
                                        closed={isLinesClosed2 ? true : false} 
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={eaves2}
                                        stroke={'green'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={apex2}
                                        stroke={'red'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer style={{zIndex: 2}}>
                                    {builtSideSizes2}
                                </Layer>
                                {
                                    appContext.state.isEditedMode ?
                                        <>
                                            <Layer>
                                                <Line
                                                    points={lastClickedDottLine}
                                                    stroke = {'red'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer>
                                                <Line
                                                    points={activeChangingLine}
                                                    stroke = {'red'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer style={{zIndex: 2}}>
                                            {builtCustomPoints2}
                                        </Layer>
                                        <Layer style={{zIndex: 10}}>
                                                <Line ref={lineRef}
                                                    // @ts-ignore
                                                    points={[getXCoord(addpoint.x), 0, getXCoord(addpoint.x), -1000]}
                                                    stroke={'black'}
                                                    strokeWidth={2}
                                                    //closed={true}
                                                    dash = {[20, 35]}
                                                    opacity={1}
                                                />
                                                    <Circle key={0}
                                                    // @ts-ignore
                                                    x = {getXCoord(addpoint.x) || -5000}
                                                    // @ts-ignore
                                                    y = {getYCoord(addpoint.y) || 100}
                                                    radius = {8}
                                                    stroke = {'#666'}
                                                    fill = {'#ddd'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer style={{zIndex: 1000000000}}>
                                                <Circle
                                                // @ts-ignore
                                                x={activeDottCoordsX || -5000}
                                                // @ts-ignore
                                                y={activeDottCoordsY }
                                                radius = {8}
                                                stroke = {'red'}
                                                fill = {'#ddd'}
                                                strokeWidth = {2}
                                                />
                                            </Layer>
                                    </>
                                    : null
                                }
                            </>
                    : null
                }
                {
                    areasCount == 3 ?
                            <>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={lines3}
                                        stroke={'blue'}
                                        strokeWidth={4}
                                        closed={isLinesClosed3 ? true : false} 
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={eaves3}
                                        stroke={'green'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={apex3}
                                        stroke={'red'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer style={{zIndex: 2}}>
                                    {builtSideSizes3}
                                </Layer>
                                {
                                    appContext.state.isEditedMode ?
                                        <>
                                            <Layer>
                                                <Line
                                                    points={lastClickedDottLine}
                                                    stroke = {'red'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer>
                                                <Line
                                                    points={activeChangingLine}
                                                    stroke = {'red'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer style={{zIndex: 2}}>
                                            {builtCustomPoints3}
                                        </Layer>
                                        <Layer style={{zIndex: 10}}>
                                                <Line ref={lineRef}
                                                    // @ts-ignore
                                                    points={[getXCoord(addpoint.x), 0, getXCoord(addpoint.x), -1000]}
                                                    stroke={'black'}
                                                    strokeWidth={2}
                                                    //closed={true}
                                                    dash = {[20, 35]}
                                                    opacity={1}
                                                />
                                                    <Circle key={0}
                                                    // @ts-ignore
                                                    x = {getXCoord(addpoint.x) || -5000}
                                                    // @ts-ignore
                                                    y = {getYCoord(addpoint.y) || 100}
                                                    radius = {8}
                                                    stroke = {'#666'}
                                                    fill = {'#ddd'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer style={{zIndex: 1000000000}}>
                                                <Circle
                                                // @ts-ignore
                                                x={activeDottCoordsX || -5000}
                                                // @ts-ignore
                                                y={activeDottCoordsY }
                                                radius = {8}
                                                stroke = {'red'}
                                                fill = {'#ddd'}
                                                strokeWidth = {2}
                                                />
                                            </Layer>
                                    </>
                                    : null
                                }
                            </>
                    : null   
                }
                {
                    areasCount == 4 ?
                            <>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={lines4}
                                        stroke={'blue'}
                                        strokeWidth={4}
                                        closed={isLinesClosed4 ? true : false} 
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={eaves4}
                                        stroke={'green'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={apex4}
                                        stroke={'red'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer style={{zIndex: 2}}>
                                    {builtSideSizes4}
                                </Layer>
                                {
                                    appContext.state.isEditedMode ?
                                        <>
                                            <Layer>
                                                <Line
                                                    points={lastClickedDottLine}
                                                    stroke = {'red'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer>
                                                <Line
                                                    points={activeChangingLine}
                                                    stroke = {'red'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer style={{zIndex: 2}}>
                                            {builtCustomPoints4}
                                        </Layer>
                                        <Layer style={{zIndex: 10}}>
                                                <Line ref={lineRef}
                                                    // @ts-ignore
                                                    points={[getXCoord(addpoint.x), 0, getXCoord(addpoint.x), -1000]}
                                                    stroke={'black'}
                                                    strokeWidth={2}
                                                    //closed={true}
                                                    dash = {[20, 35]}
                                                    opacity={1}
                                                />
                                                    <Circle key={0}
                                                    // @ts-ignore
                                                    x = {getXCoord(addpoint.x) || -5000}
                                                    // @ts-ignore
                                                    y = {getYCoord(addpoint.y) || 100}
                                                    radius = {8}
                                                    stroke = {'#666'}
                                                    fill = {'#ddd'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer style={{zIndex: 1000000000}}>
                                                <Circle
                                                // @ts-ignore
                                                x={activeDottCoordsX || -5000}
                                                // @ts-ignore
                                                y={activeDottCoordsY }
                                                radius = {8}
                                                stroke = {'red'}
                                                fill = {'#ddd'}
                                                strokeWidth = {2}
                                                />
                                            </Layer>
                                    </>
                                    : null
                                }
                            </>
                    : null   
                }
                {
                    areasCount == 5 ?
                            <>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={lines5}
                                        stroke={'blue'}
                                        strokeWidth={4}
                                        closed={isLinesClosed5 ? true : false} 
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={eaves5}
                                        stroke={'green'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={apex5}
                                        stroke={'red'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer style={{zIndex: 2}}>
                                    {builtSideSizes5}
                                </Layer>
                                {
                                    appContext.state.isEditedMode ?
                                        <>
                                            <Layer>
                                                <Line
                                                    points={lastClickedDottLine}
                                                    stroke = {'red'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer style={{zIndex: 2}}>
                                            {builtCustomPoints5}
                                        </Layer>
                                        <Layer style={{zIndex: 10}}>
                                                <Line ref={lineRef}
                                                    // @ts-ignore
                                                    points={[getXCoord(addpoint.x), 0, getXCoord(addpoint.x), -1000]}
                                                    stroke={'black'}
                                                    strokeWidth={2}
                                                    //closed={true}
                                                    dash = {[20, 35]}
                                                    opacity={1}
                                                />
                                                    <Circle key={0}
                                                    // @ts-ignore
                                                    x = {getXCoord(addpoint.x) || -5000}
                                                    // @ts-ignore
                                                    y = {getYCoord(addpoint.y) || 100}
                                                    radius = {8}
                                                    stroke = {'#666'}
                                                    fill = {'#ddd'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer style={{zIndex: 1000000000}}>
                                                <Circle
                                                // @ts-ignore
                                                x={activeDottCoordsX || -5000}
                                                // @ts-ignore
                                                y={activeDottCoordsY }
                                                radius = {8}
                                                stroke = {'red'}
                                                fill = {'#ddd'}
                                                strokeWidth = {2}
                                                />
                                            </Layer>
                                    </>
                                    : null
                                }
                            </>
                    : null   
                }
                {
                    areasCount == 6 ?
                            <>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={lines6}
                                        stroke={'blue'}
                                        strokeWidth={4}
                                        closed={isLinesClosed6 ? true : false} 
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={eaves6}
                                        stroke={'green'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={apex6}
                                        stroke={'red'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer style={{zIndex: 2}}>
                                    {builtSideSizes6}
                                </Layer>
                                {
                                    appContext.state.isEditedMode ?
                                        <>
                                            <Layer>
                                                <Line
                                                    points={lastClickedDottLine}
                                                    stroke = {'red'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer>
                                                <Line
                                                    points={activeChangingLine}
                                                    stroke = {'red'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer style={{zIndex: 2}}>
                                            {builtCustomPoints6}
                                        </Layer>
                                        <Layer style={{zIndex: 10}}>
                                                <Line ref={lineRef}
                                                    // @ts-ignore
                                                    points={[getXCoord(addpoint.x), 0, getXCoord(addpoint.x), -1000]}
                                                    stroke={'black'}
                                                    strokeWidth={2}
                                                    //closed={true}
                                                    dash = {[20, 35]}
                                                    opacity={1}
                                                />
                                                    <Circle key={0}
                                                    // @ts-ignore
                                                    x = {getXCoord(addpoint.x) || -5000}
                                                    // @ts-ignore
                                                    y = {getYCoord(addpoint.y) || 100}
                                                    radius = {8}
                                                    stroke = {'#666'}
                                                    fill = {'#ddd'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer style={{zIndex: 1000000000}}>
                                                <Circle
                                                // @ts-ignore
                                                x={activeDottCoordsX || -5000}
                                                // @ts-ignore
                                                y={activeDottCoordsY }
                                                radius = {8}
                                                stroke = {'red'}
                                                fill = {'#ddd'}
                                                strokeWidth = {2}
                                                />
                                            </Layer>
                                    </>
                                    : null
                                }
                            </>
                    : null   
                }
                {
                    areasCount == 7 ?
                            <>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={lines7}
                                        stroke={'blue'}
                                        strokeWidth={4}
                                        closed={isLinesClosed7 ? true : false} 
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={eaves7}
                                        stroke={'green'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={apex7}
                                        stroke={'red'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer style={{zIndex: 2}}>
                                    {builtSideSizes7}
                                </Layer>
                                {
                                    appContext.state.isEditedMode ?
                                        <>
                                            <Layer>
                                                <Line
                                                    points={lastClickedDottLine}
                                                    stroke = {'red'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer>
                                                <Line
                                                    points={activeChangingLine}
                                                    stroke = {'red'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer style={{zIndex: 2}}>
                                            {builtCustomPoints7}
                                        </Layer>
                                        <Layer style={{zIndex: 10}}>
                                                <Line ref={lineRef}
                                                    // @ts-ignore
                                                    points={[getXCoord(addpoint.x), 0, getXCoord(addpoint.x), -1000]}
                                                    stroke={'black'}
                                                    strokeWidth={2}
                                                    //closed={true}
                                                    dash = {[20, 35]}
                                                    opacity={1}
                                                />
                                                    <Circle key={0}
                                                    // @ts-ignore
                                                    x = {getXCoord(addpoint.x) || -5000}
                                                    // @ts-ignore
                                                    y = {getYCoord(addpoint.y) || 100}
                                                    radius = {8}
                                                    stroke = {'#666'}
                                                    fill = {'#ddd'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer style={{zIndex: 1000000000}}>
                                                <Circle
                                                // @ts-ignore
                                                x={activeDottCoordsX || -5000}
                                                // @ts-ignore
                                                y={activeDottCoordsY }
                                                radius = {8}
                                                stroke = {'red'}
                                                fill = {'#ddd'}
                                                strokeWidth = {2}
                                                />
                                            </Layer>
                                    </>
                                    : null
                                }
                            </>
                    : null   
                }
                {
                    areasCount == 8 ?
                            <>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={lines8}
                                        stroke={'blue'}
                                        strokeWidth={4}
                                        closed={isLinesClosed8 ? true : false} 
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={eaves8}
                                        stroke={'green'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={apex8}
                                        stroke={'red'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer style={{zIndex: 2}}>
                                    {builtSideSizes8}
                                </Layer>
                                {
                                    appContext.state.isEditedMode ?
                                        <>
                                            <Layer>
                                                <Line
                                                    points={lastClickedDottLine}
                                                    stroke = {'red'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer>
                                                <Line
                                                    points={activeChangingLine}
                                                    stroke = {'red'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer style={{zIndex: 2}}>
                                            {builtCustomPoints8}
                                        </Layer>
                                        <Layer style={{zIndex: 10}}>
                                                <Line ref={lineRef}
                                                    // @ts-ignore
                                                    points={[getXCoord(addpoint.x), 0, getXCoord(addpoint.x), -1000]}
                                                    stroke={'black'}
                                                    strokeWidth={2}
                                                    //closed={true}
                                                    dash = {[20, 35]}
                                                    opacity={1}
                                                />
                                                    <Circle key={0}
                                                    // @ts-ignore
                                                    x = {getXCoord(addpoint.x) || -5000}
                                                    // @ts-ignore
                                                    y = {getYCoord(addpoint.y) || 100}
                                                    radius = {8}
                                                    stroke = {'#666'}
                                                    fill = {'#ddd'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer style={{zIndex: 1000000000}}>
                                                <Circle
                                                // @ts-ignore
                                                x={activeDottCoordsX || -5000}
                                                // @ts-ignore
                                                y={activeDottCoordsY }
                                                radius = {8}
                                                stroke = {'red'}
                                                fill = {'#ddd'}
                                                strokeWidth = {2}
                                                />
                                            </Layer>
                                    </>
                                    : null
                                }
                            </>
                    : null   
                }
                {
                    areasCount == 9 ?
                            <>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={lines9}
                                        stroke={'blue'}
                                        strokeWidth={4}
                                        closed={isLinesClosed9 ? true : false} 
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={eaves9}
                                        stroke={'green'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={apex9}
                                        stroke={'red'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer style={{zIndex: 2}}>
                                    {builtSideSizes9}
                                </Layer>
                                {
                                    appContext.state.isEditedMode ?
                                        <>
                                            <Layer>
                                                <Line
                                                    points={lastClickedDottLine}
                                                    stroke = {'red'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer>
                                                <Line
                                                    points={activeChangingLine}
                                                    stroke = {'red'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer style={{zIndex: 2}}>
                                            {builtCustomPoints9}
                                        </Layer>
                                        <Layer style={{zIndex: 10}}>
                                                <Line ref={lineRef}
                                                    // @ts-ignore
                                                    points={[getXCoord(addpoint.x), 0, getXCoord(addpoint.x), -1000]}
                                                    stroke={'black'}
                                                    strokeWidth={2}
                                                    //closed={true}
                                                    dash = {[20, 35]}
                                                    opacity={1}
                                                />
                                                    <Circle key={0}
                                                    // @ts-ignore
                                                    x = {getXCoord(addpoint.x) || -5000}
                                                    // @ts-ignore
                                                    y = {getYCoord(addpoint.y) || 100}
                                                    radius = {8}
                                                    stroke = {'#666'}
                                                    fill = {'#ddd'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer style={{zIndex: 1000000000}}>
                                                <Circle
                                                // @ts-ignore
                                                x={activeDottCoordsX || -5000}
                                                // @ts-ignore
                                                y={activeDottCoordsY }
                                                radius = {8}
                                                stroke = {'red'}
                                                fill = {'#ddd'}
                                                strokeWidth = {2}
                                                />
                                            </Layer>
                                    </>
                                    : null
                                }
                            </>
                    : null   
                }
                {
                    areasCount == 10 ?
                            <>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={lines10}
                                        stroke={'blue'}
                                        strokeWidth={4}
                                        closed={isLinesClosed10 ? true : false} 
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={eaves10}
                                        stroke={'green'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={apex10}
                                        stroke={'red'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer style={{zIndex: 2}}>
                                    {builtSideSizes10}
                                </Layer>
                                {
                                    appContext.state.isEditedMode ?
                                        <>
                                            <Layer>
                                                <Line
                                                    points={lastClickedDottLine}
                                                    stroke = {'red'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer>
                                                <Line
                                                    points={activeChangingLine}
                                                    stroke = {'red'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer style={{zIndex: 2}}>
                                            {builtCustomPoints10}
                                        </Layer>
                                       
                                    </>
                                    : null
                                }
                            </>
                    : null   
                }
                {
                    areasCount == 11 ?
                            <>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={lines11}
                                        stroke={'blue'}
                                        strokeWidth={4}
                                        closed={isLinesClosed11 ? true : false} 
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={eaves11}
                                        stroke={'green'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={apex11}
                                        stroke={'red'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer style={{zIndex: 2}}>
                                    {builtSideSizes11}
                                </Layer>
                                {
                                    appContext.state.isEditedMode ?
                                        <>
                                            <Layer>
                                                <Line
                                                    points={lastClickedDottLine}
                                                    stroke = {'red'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer>
                                                <Line
                                                    points={activeChangingLine}
                                                    stroke = {'red'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer style={{zIndex: 2}}>
                                            {builtCustomPoints11}
                                        </Layer>
                                        <Layer style={{zIndex: 10}}>
                                                <Line ref={lineRef}
                                                    // @ts-ignore
                                                    points={[getXCoord(addpoint.x), 0, getXCoord(addpoint.x), -1000]}
                                                    stroke={'black'}
                                                    strokeWidth={2}
                                                    //closed={true}
                                                    dash = {[20, 35]}
                                                    opacity={1}
                                                />
                                                    <Circle key={0}
                                                    // @ts-ignore
                                                    x = {getXCoord(addpoint.x) || -5000}
                                                    // @ts-ignore
                                                    y = {getYCoord(addpoint.y) || 100}
                                                    radius = {8}
                                                    stroke = {'#666'}
                                                    fill = {'#ddd'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer style={{zIndex: 1000000000}}>
                                                <Circle
                                                // @ts-ignore
                                                x={activeDottCoordsX || -5000}
                                                // @ts-ignore
                                                y={activeDottCoordsY }
                                                radius = {8}
                                                stroke = {'red'}
                                                fill = {'#ddd'}
                                                strokeWidth = {2}
                                                />
                                            </Layer>
                                    </>
                                    : null
                                }
                            </>
                    : null   
                }
                {
                    areasCount == 12 ?
                            <>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={lines12}
                                        stroke={'blue'}
                                        strokeWidth={4}
                                        closed={isLinesClosed12 ? true : false} 
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={eaves12}
                                        stroke={'green'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={apex12}
                                        stroke={'red'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer style={{zIndex: 2}}>
                                    {builtSideSizes12}
                                </Layer>
                                {
                                    appContext.state.isEditedMode ?
                                        <>
                                            <Layer>
                                                <Line
                                                    points={lastClickedDottLine}
                                                    stroke = {'red'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer>
                                                <Line
                                                    points={activeChangingLine}
                                                    stroke = {'red'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer style={{zIndex: 2}}>
                                            {builtCustomPoints12}
                                        </Layer>
                                        <Layer style={{zIndex: 10}}>
                                                <Line ref={lineRef}
                                                    // @ts-ignore
                                                    points={[getXCoord(addpoint.x), 0, getXCoord(addpoint.x), -1000]}
                                                    stroke={'black'}
                                                    strokeWidth={2}
                                                    //closed={true}
                                                    dash = {[20, 35]}
                                                    opacity={1}
                                                />
                                                    <Circle key={0}
                                                    // @ts-ignore
                                                    x = {getXCoord(addpoint.x) || -5000}
                                                    // @ts-ignore
                                                    y = {getYCoord(addpoint.y) || 100}
                                                    radius = {8}
                                                    stroke = {'#666'}
                                                    fill = {'#ddd'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer style={{zIndex: 1000000000}}>
                                                <Circle
                                                // @ts-ignore
                                                x={activeDottCoordsX || -5000}
                                                // @ts-ignore
                                                y={activeDottCoordsY }
                                                radius = {8}
                                                stroke = {'red'}
                                                fill = {'#ddd'}
                                                strokeWidth = {2}
                                                />
                                            </Layer>
                                    </>
                                    : null
                                }
                            </>
                    : null   
                }
                {
                    areasCount == 13 ?
                            <>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={lines13}
                                        stroke={'blue'}
                                        strokeWidth={4}
                                        closed={isLinesClosed13 ? true : false} 
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={eaves13}
                                        stroke={'green'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={apex13}
                                        stroke={'red'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer style={{zIndex: 2}}>
                                    {builtSideSizes13}
                                </Layer>
                                {
                                    appContext.state.isEditedMode ?
                                        <>
                                            <Layer>
                                                <Line
                                                    points={lastClickedDottLine}
                                                    stroke = {'red'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer>
                                                <Line
                                                    points={activeChangingLine}
                                                    stroke = {'red'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer style={{zIndex: 2}}>
                                            {builtCustomPoints13}
                                        </Layer>
                                        <Layer style={{zIndex: 10}}>
                                                <Line ref={lineRef}
                                                    // @ts-ignore
                                                    points={[getXCoord(addpoint.x), 0, getXCoord(addpoint.x), -1000]}
                                                    stroke={'black'}
                                                    strokeWidth={2}
                                                    //closed={true}
                                                    dash = {[20, 35]}
                                                    opacity={1}
                                                />
                                                    <Circle key={0}
                                                    // @ts-ignore
                                                    x = {getXCoord(addpoint.x) || -5000}
                                                    // @ts-ignore
                                                    y = {getYCoord(addpoint.y) || 100}
                                                    radius = {8}
                                                    stroke = {'#666'}
                                                    fill = {'#ddd'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer style={{zIndex: 1000000000}}>
                                                <Circle
                                                // @ts-ignore
                                                x={activeDottCoordsX || -5000}
                                                // @ts-ignore
                                                y={activeDottCoordsY }
                                                radius = {8}
                                                stroke = {'red'}
                                                fill = {'#ddd'}
                                                strokeWidth = {2}
                                                />
                                            </Layer>
                                    </>
                                    : null
                                }
                            </>
                    : null   
                }
                {
                    areasCount == 14 ?
                            <>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={lines14}
                                        stroke={'blue'}
                                        strokeWidth={4}
                                        closed={isLinesClosed14 ? true : false} 
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={eaves14}
                                        stroke={'green'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={apex14}
                                        stroke={'red'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer style={{zIndex: 2}}>
                                    {builtSideSizes14}
                                </Layer>
                                {
                                    appContext.state.isEditedMode ?
                                        <>
                                            <Layer>
                                                <Line
                                                    points={lastClickedDottLine}
                                                    stroke = {'red'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer>
                                                <Line
                                                    points={activeChangingLine}
                                                    stroke = {'red'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer style={{zIndex: 2}}>
                                            {builtCustomPoints14}
                                        </Layer>
                                        <Layer style={{zIndex: 10}}>
                                                <Line ref={lineRef}
                                                    // @ts-ignore
                                                    points={[getXCoord(addpoint.x), 0, getXCoord(addpoint.x), -1000]}
                                                    stroke={'black'}
                                                    strokeWidth={2}
                                                    //closed={true}
                                                    dash = {[20, 35]}
                                                    opacity={1}
                                                />
                                                    <Circle key={0}
                                                    // @ts-ignore
                                                    x = {getXCoord(addpoint.x) || -5000}
                                                    // @ts-ignore
                                                    y = {getYCoord(addpoint.y) || 100}
                                                    radius = {8}
                                                    stroke = {'#666'}
                                                    fill = {'#ddd'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer style={{zIndex: 1000000000}}>
                                                <Circle
                                                // @ts-ignore
                                                x={activeDottCoordsX || -5000}
                                                // @ts-ignore
                                                y={activeDottCoordsY }
                                                radius = {8}
                                                stroke = {'red'}
                                                fill = {'#ddd'}
                                                strokeWidth = {2}
                                                />
                                            </Layer>
                                    </>
                                    : null
                                }
                            </>
                    : null   
                }
                {
                    areasCount == 15 ?
                            <>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={lines15}
                                        stroke={'blue'}
                                        strokeWidth={4}
                                        closed={isLinesClosed15 ? true : false} 
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={eaves15}
                                        stroke={'green'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={apex15}
                                        stroke={'red'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer style={{zIndex: 2}}>
                                    {builtSideSizes15}
                                </Layer>
                                {
                                    appContext.state.isEditedMode ?
                                        <>
                                            <Layer>
                                                <Line
                                                    points={lastClickedDottLine}
                                                    stroke = {'red'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer>
                                                <Line
                                                    points={activeChangingLine}
                                                    stroke = {'red'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer style={{zIndex: 2}}>
                                            {builtCustomPoints15}
                                        </Layer>
                                       
                                    </>
                                    : null
                                }
                            </>
                    : null   
                }
                {
                    areasCount == 16 ?
                            <>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={lines16}
                                        stroke={'blue'}
                                        strokeWidth={4}
                                        closed={isLinesClosed16 ? true : false} 
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={eaves16}
                                        stroke={'green'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={apex16}
                                        stroke={'red'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer style={{zIndex: 2}}>
                                    {builtSideSizes16}
                                </Layer>
                                {
                                    appContext.state.isEditedMode ?
                                        <>
                                            <Layer>
                                                <Line
                                                    points={lastClickedDottLine}
                                                    stroke = {'red'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer>
                                                <Line
                                                    points={activeChangingLine}
                                                    stroke = {'red'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer style={{zIndex: 2}}>
                                            {builtCustomPoints16}
                                        </Layer>
                                        <Layer style={{zIndex: 10}}>
                                                <Line ref={lineRef}
                                                    // @ts-ignore
                                                    points={[getXCoord(addpoint.x), 0, getXCoord(addpoint.x), -1000]}
                                                    stroke={'black'}
                                                    strokeWidth={2}
                                                    //closed={true}
                                                    dash = {[20, 35]}
                                                    opacity={1}
                                                />
                                                    <Circle key={0}
                                                    // @ts-ignore
                                                    x = {getXCoord(addpoint.x) || -5000}
                                                    // @ts-ignore
                                                    y = {getYCoord(addpoint.y) || 100}
                                                    radius = {8}
                                                    stroke = {'#666'}
                                                    fill = {'#ddd'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer style={{zIndex: 1000000000}}>
                                                <Circle
                                                // @ts-ignore
                                                x={activeDottCoordsX || -5000}
                                                // @ts-ignore
                                                y={activeDottCoordsY }
                                                radius = {8}
                                                stroke = {'red'}
                                                fill = {'#ddd'}
                                                strokeWidth = {2}
                                                />
                                            </Layer>
                                    </>
                                    : null
                                }
                            </>
                    : null   
                }
                {
                    areasCount == 17 ?
                            <>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={lines17}
                                        stroke={'blue'}
                                        strokeWidth={4}
                                        closed={isLinesClosed17 ? true : false} 
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={eaves17}
                                        stroke={'green'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={apex17}
                                        stroke={'red'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer style={{zIndex: 2}}>
                                    {builtSideSizes17}
                                </Layer>
                                {
                                    appContext.state.isEditedMode ?
                                        <>
                                            <Layer>
                                                <Line
                                                    points={lastClickedDottLine}
                                                    stroke = {'red'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer>
                                                <Line
                                                    points={activeChangingLine}
                                                    stroke = {'red'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer style={{zIndex: 2}}>
                                            {builtCustomPoints17}
                                        </Layer>
                                        <Layer style={{zIndex: 10}}>
                                                <Line ref={lineRef}
                                                    // @ts-ignore
                                                    points={[getXCoord(addpoint.x), 0, getXCoord(addpoint.x), -1000]}
                                                    stroke={'black'}
                                                    strokeWidth={2}
                                                    //closed={true}
                                                    dash = {[20, 35]}
                                                    opacity={1}
                                                />
                                                    <Circle key={0}
                                                    // @ts-ignore
                                                    x = {getXCoord(addpoint.x) || -5000}
                                                    // @ts-ignore
                                                    y = {getYCoord(addpoint.y) || 100}
                                                    radius = {8}
                                                    stroke = {'#666'}
                                                    fill = {'#ddd'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer style={{zIndex: 1000000000}}>
                                                <Circle
                                                // @ts-ignore
                                                x={activeDottCoordsX || -5000}
                                                // @ts-ignore
                                                y={activeDottCoordsY }
                                                radius = {8}
                                                stroke = {'red'}
                                                fill = {'#ddd'}
                                                strokeWidth = {2}
                                                />
                                            </Layer>
                                    </>
                                    : null
                                }
                            </>
                    : null   
                }
                {
                    areasCount == 18 ?
                            <>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={lines18}
                                        stroke={'blue'}
                                        strokeWidth={4}
                                        closed={isLinesClosed18 ? true : false} 
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={eaves18}
                                        stroke={'green'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={apex18}
                                        stroke={'red'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer style={{zIndex: 2}}>
                                    {builtSideSizes18}
                                </Layer>
                                {
                                    appContext.state.isEditedMode ?
                                        <>
                                            <Layer>
                                                <Line
                                                    points={lastClickedDottLine}
                                                    stroke = {'red'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer>
                                                <Line
                                                    points={activeChangingLine}
                                                    stroke = {'red'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer style={{zIndex: 2}}>
                                            {builtCustomPoints18}
                                        </Layer>
                                        <Layer style={{zIndex: 10}}>
                                                <Line ref={lineRef}
                                                    // @ts-ignore
                                                    points={[getXCoord(addpoint.x), 0, getXCoord(addpoint.x), -1000]}
                                                    stroke={'black'}
                                                    strokeWidth={2}
                                                    //closed={true}
                                                    dash = {[20, 35]}
                                                    opacity={1}
                                                />
                                                    <Circle key={0}
                                                    // @ts-ignore
                                                    x = {getXCoord(addpoint.x) || -5000}
                                                    // @ts-ignore
                                                    y = {getYCoord(addpoint.y) || 100}
                                                    radius = {8}
                                                    stroke = {'#666'}
                                                    fill = {'#ddd'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer style={{zIndex: 1000000000}}>
                                                <Circle
                                                // @ts-ignore
                                                x={activeDottCoordsX || -5000}
                                                // @ts-ignore
                                                y={activeDottCoordsY }
                                                radius = {8}
                                                stroke = {'red'}
                                                fill = {'#ddd'}
                                                strokeWidth = {2}
                                                />
                                            </Layer>
                                    </>
                                    : null
                                }
                            </>
                    : null   
                }
                {
                    areasCount == 19 ?
                            <>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={lines19}
                                        stroke={'blue'}
                                        strokeWidth={4}
                                        closed={isLinesClosed19 ? true : false} 
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={eaves19}
                                        stroke={'green'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={apex19}
                                        stroke={'red'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer style={{zIndex: 2}}>
                                    {builtSideSizes19}
                                </Layer>
                                {
                                    appContext.state.isEditedMode ?
                                        <>
                                            <Layer>
                                                <Line
                                                    points={lastClickedDottLine}
                                                    stroke = {'red'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer>
                                                <Line
                                                    points={activeChangingLine}
                                                    stroke = {'red'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer style={{zIndex: 2}}>
                                            {builtCustomPoints19}
                                        </Layer>
                                        <Layer style={{zIndex: 10}}>
                                                <Line ref={lineRef}
                                                    // @ts-ignore
                                                    points={[getXCoord(addpoint.x), 0, getXCoord(addpoint.x), -1000]}
                                                    stroke={'black'}
                                                    strokeWidth={2}
                                                    //closed={true}
                                                    dash = {[20, 35]}
                                                    opacity={1}
                                                />
                                                    <Circle key={0}
                                                    // @ts-ignore
                                                    x = {getXCoord(addpoint.x) || -5000}
                                                    // @ts-ignore
                                                    y = {getYCoord(addpoint.y) || 100}
                                                    radius = {8}
                                                    stroke = {'#666'}
                                                    fill = {'#ddd'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer style={{zIndex: 1000000000}}>
                                                <Circle
                                                // @ts-ignore
                                                x={activeDottCoordsX || -5000}
                                                // @ts-ignore
                                                y={activeDottCoordsY }
                                                radius = {8}
                                                stroke = {'red'}
                                                fill = {'#ddd'}
                                                strokeWidth = {2}
                                                />
                                            </Layer>
                                    </>
                                    : null
                                }
                            </>
                    : null   
                }
                {
                    areasCount == 20 ?
                            <>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={lines20}
                                        stroke={'blue'}
                                        strokeWidth={4}
                                        closed={isLinesClosed20 ? true : false} 
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={eaves20}
                                        stroke={'green'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer>
                                    <Line ref={lineRef}
                                        points={apex20}
                                        stroke={'red'}
                                        strokeWidth={2}
                                        opacity={1}
                                    />
                                </Layer>
                                <Layer style={{zIndex: 2}}>
                                    {builtSideSizes1}
                                </Layer>
                                {
                                    appContext.state.isEditedMode ?
                                        <>
                                            <Layer>
                                                <Line
                                                    points={lastClickedDottLine}
                                                    stroke = {'red'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer>
                                                <Line
                                                    points={activeChangingLine}
                                                    stroke = {'red'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer style={{zIndex: 2}}>
                                            {builtCustomPoints20}
                                        </Layer>
                                        <Layer style={{zIndex: 10}}>
                                                <Line ref={lineRef}
                                                    // @ts-ignore
                                                    points={[getXCoord(addpoint.x), 0, getXCoord(addpoint.x), -1000]}
                                                    stroke={'black'}
                                                    strokeWidth={2}
                                                    //closed={true}
                                                    dash = {[20, 35]}
                                                    opacity={1}
                                                />
                                                    <Circle key={0}
                                                    // @ts-ignore
                                                    x = {getXCoord(addpoint.x) || -5000}
                                                    // @ts-ignore
                                                    y = {getYCoord(addpoint.y) || 100}
                                                    radius = {8}
                                                    stroke = {'#666'}
                                                    fill = {'#ddd'}
                                                    strokeWidth = {2}
                                                />
                                            </Layer>
                                            <Layer style={{zIndex: 1000000000}}>
                                                <Circle
                                                // @ts-ignore
                                                x={activeDottCoordsX || -5000}
                                                // @ts-ignore
                                                y={activeDottCoordsY }
                                                radius = {8}
                                                stroke = {'red'}
                                                fill = {'#ddd'}
                                                strokeWidth = {2}
                                                />
                                            </Layer>
                                    </>
                                    : null
                                }
                            </>
                    : null   
                }
            </>
        )
}

export default CreativePointedPolygon;
