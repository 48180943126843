import React, {FC, useContext} from "react";

import "./SidePanel.scss";

import { AppContext } from "../../context/AppContext";
import TheoremBlock from "./TheoremBlock/TheoremBlock";
import AreasBlock from "./AreasBlock/AreasBlock"
import CoordsBlock from "./CoordsBlock/CoordsBlock";
import ResultBlock from "./ResultBlock/ResultBlock";

interface Props {
    areas1: any;
    areas2: any;
    areas3: any;
    areas4: any;
    areas5: any;
    areas6: any;
    areas7: any;
    areas8: any;
    areas9: any;
    areas10: any;
    areas11: any;
    areas12: any;
    areas13: any;
    areas14: any;
    areas15: any;
    areas16: any;
    areas17: any;
    areas18: any;
    areas19: any;
    areas20: any;
    areasCount: any;
    setAddArea: any;
    setAddEaves: any;
    setAddApex: any;
    setDeleteFigure: any;
    setAreasCount: any;
    setCopyFigure: any;
    setFinishFigure: any;
    setXCords1: any;
    setYCords1: any;
    setIsDottAdditional: any;
    setChangeDott: any;
    calcResult: any;
    setXCutShift: any;
    setYCutShift: any;
    isPDFGenerating: any;
    setIsPDFGenerating: any;
    isGridsReady: any;
    setIsGridsReady: any;
    setOnCutFigureStartClick: any;
    onCutFigureStartClick: any;
    setCutFigureStart: any;
    activeDottCoordsX: any;
    setActiveDottCoordsX: any;
    activeDottCoordsY: any;
    setActiveDottCoordsY: any;

    closedCuts1: any;setClosedCuts1: any
    closedCuts2: any;setClosedCuts2: any
    closedCuts3: any;setClosedCuts3: any
    closedCuts4: any;setClosedCuts4: any
    closedCuts5: any;setClosedCuts5: any
    closedCuts6: any;setClosedCuts6: any
    closedCuts7: any;setClosedCuts7: any
    closedCuts8: any;setClosedCuts8: any
    closedCuts9: any;setClosedCuts9: any
    closedCuts10: any;setClosedCuts10: any
    closedCuts11: any;setClosedCuts11: any
    closedCuts12: any;setClosedCuts12: any
    closedCuts13: any;setClosedCuts13: any
    closedCuts14: any;setClosedCuts14: any
    closedCuts15: any;setClosedCuts15: any
    closedCuts16: any;setClosedCuts16: any
    closedCuts17: any;setClosedCuts17: any
    closedCuts18: any;setClosedCuts18: any
    closedCuts19: any;setClosedCuts19: any
    closedCuts20: any;setClosedCuts20: any

    results1: any;
    setResults1: any;
    results2: any;
    setResults2: any;
    results3: any;
    setResults3: any;
    results4: any;
    setResults4: any;
    results5: any;
    setResults5: any;
    results6: any;
    setResults6: any;
    results7: any;
    setResults7: any;
    results8: any;
    setResults8: any;
    results9: any;
    setResults9: any;
    results10: any;
    setResults10: any;
    results11: any;
    setResults11: any;
    results12: any;
    setResults12: any;
    results13: any;
    setResults13: any;
    results14: any;
    setResults14: any;
    results15: any;
    setResults15: any;
    results16: any;
    setResults16: any;
    results17: any;
    setResults17: any;
    results18: any;
    setResults18: any;
    results19: any;
    setResults19: any;
    results20: any;
    setResults20: any;
    cellRows1: any;
    cellRows2: any;
    cellRows3: any;
    cellRows4: any;
    cellRows5: any;
    cellRows6: any;
    cellRows7: any;
    cellRows8: any;
    cellRows9: any;
    cellRows10: any;
    cellRows11: any;
    cellRows12: any;
    cellRows13: any;
    cellRows14: any;
    cellRows15: any;
    cellRows16: any;
    cellRows17: any;
    cellRows18: any;
    cellRows19: any;
    cellRows20: any;
 }

const SidePanel: FC<Props> = ({areas1, areas2, areas3, areas4, areas5, areas6, areas7, areas8, areas9, areas10, areas11, areas12, areas13, areas14, areas15, areas16, areas17, areas18, 
    areas19, areas20, 
    
    areasCount, setAddArea, setAddEaves, setAddApex, setDeleteFigure, setAreasCount,
    setCopyFigure, setFinishFigure, setXCords1, setYCords1, setIsDottAdditional, setChangeDott, calcResult, setXCutShift, setYCutShift, setOnCutFigureStartClick, onCutFigureStartClick,
    activeDottCoordsX, setActiveDottCoordsX, activeDottCoordsY, setActiveDottCoordsY,
    setCutFigureStart,
    results1, 
    setResults1, 
    results2, 
    setResults2, 
    results3,  
    setResults3,
    results4,  
    setResults4,
    results5,  
    setResults5,
    results6,  
    setResults6,
    results7,  
    setResults7,
    results8,  
    setResults8,
    results9,  
    setResults9,
    results10,  
    setResults10,
    results11,  
    setResults11,
    results12,  
    setResults12,
    results13,  
    setResults13,
    results14,  
    setResults14,
    results15,  
    setResults15,
    results16,  
    setResults16,
    results17,  
    setResults17,
    results18,  
    setResults18,
    results19,  
    setResults19,
    results20,  
    setResults20,

    closedCuts1,setClosedCuts1,
    closedCuts2,setClosedCuts2,
    closedCuts3,setClosedCuts3,
    closedCuts4,setClosedCuts4,
    closedCuts5,setClosedCuts5,
    closedCuts6,setClosedCuts6,
    closedCuts7,setClosedCuts7,
    closedCuts8,setClosedCuts8,
    closedCuts9,setClosedCuts9,
    closedCuts10,setClosedCuts10,
    closedCuts11,setClosedCuts11,
    closedCuts12,setClosedCuts12,
    closedCuts13,setClosedCuts13,
    closedCuts14,setClosedCuts14,
    closedCuts15,setClosedCuts15,
    closedCuts16,setClosedCuts16,
    closedCuts17,setClosedCuts17,
    closedCuts18,setClosedCuts18,
    closedCuts19,setClosedCuts19,
    closedCuts20,setClosedCuts20,

    cellRows1,
    cellRows2,
    cellRows3,
    cellRows4,
    cellRows5,
    cellRows6,
    cellRows7,
    cellRows8,
    cellRows9,
    cellRows10,
    cellRows11,
    cellRows12,
    cellRows13,
    cellRows14,
    cellRows15,
    cellRows16,
    cellRows17,
    cellRows18,
    cellRows19,
    cellRows20,
    isPDFGenerating, setIsPDFGenerating, isGridsReady, setIsGridsReady

}) => {
    const appContext = useContext(AppContext);

    return(
        <>
            <TheoremBlock/>
            <AreasBlock
            areas1={areas1} 
            areas2={areas2} 
            areas3={areas3} 
            areas4={areas4} 
            areas5={areas5}
            areas6={areas6} 
            areas7={areas7} 
            areas8={areas8} 
            areas9={areas9} 
            areas10={areas10} 
            areas11={areas11} 
            areas12={areas12} 
            areas13={areas13} 
            areas14={areas14} 
            areas15={areas15} 
            areas16={areas16} 
            areas17={areas17} 
            areas18={areas18} 
            areas19={areas19} 
            areas20={areas20} 

            cellRows1={cellRows1}
            cellRows2={cellRows2}
            cellRows3={cellRows3}
            cellRows4={cellRows4}
            cellRows5={cellRows5}
            cellRows6={cellRows6}
            cellRows7={cellRows7}
            cellRows8={cellRows8}
            cellRows9={cellRows9}
            cellRows10={cellRows10}
            cellRows11={cellRows11}
            cellRows12={cellRows12}
            cellRows13={cellRows13}
            cellRows14={cellRows14}
            cellRows15={cellRows15}
            cellRows16={cellRows16}
            cellRows17={cellRows17}
            cellRows18={cellRows18}
            cellRows19={cellRows19}
            cellRows20={cellRows20}
            
            
            areasCount={areasCount} setAddArea={setAddArea} setAddEaves={setAddEaves} setAddApex={setAddApex} setDeleteFigure={setDeleteFigure} 
            setAreasCount={setAreasCount} setCopyFigure={setCopyFigure}/>
            <CoordsBlock setXCords1={setXCords1} setYCords1={setYCords1} setIsDottAdditional={setIsDottAdditional} setChangeDott={setChangeDott} setFinishFigure={setFinishFigure}
            setXCutShift={setXCutShift} setYCutShift={setYCutShift} setOnCutFigureStartClick={setOnCutFigureStartClick} onCutFigureStartClick={onCutFigureStartClick} 
            setCutFigureStart={setCutFigureStart} areasCount={areasCount} 
            activeDottCoordsX={activeDottCoordsX} setActiveDottCoordsX = {setActiveDottCoordsX} activeDottCoordsY={activeDottCoordsY} setActiveDottCoordsY = {setActiveDottCoordsY}
            
            closedCuts1={closedCuts1} setClosedCuts1={setClosedCuts1}
                closedCuts2={closedCuts2} setClosedCuts2={setClosedCuts2}
                closedCuts3={closedCuts3} setClosedCuts3={setClosedCuts3}
                closedCuts4={closedCuts4} setClosedCuts4={setClosedCuts4}
                closedCuts5={closedCuts5} setClosedCuts5={setClosedCuts5}
                closedCuts6={closedCuts6} setClosedCuts6={setClosedCuts6}
                closedCuts7={closedCuts7} setClosedCuts7={setClosedCuts7}
                closedCuts8={closedCuts8} setClosedCuts8={setClosedCuts8}
                closedCuts9={closedCuts9} setClosedCuts9={setClosedCuts9}
                closedCuts10={closedCuts10} setClosedCuts10={setClosedCuts10}
                closedCuts11={closedCuts11} setClosedCuts11={setClosedCuts11}
                closedCuts12={closedCuts12} setClosedCuts12={setClosedCuts12}
                closedCuts13={closedCuts13} setClosedCuts13={setClosedCuts13}
                closedCuts14={closedCuts14} setClosedCuts14={setClosedCuts14}
                closedCuts15={closedCuts15} setClosedCuts15={setClosedCuts15}
                closedCuts16={closedCuts16} setClosedCuts16={setClosedCuts16}
                closedCuts17={closedCuts17} setClosedCuts17={setClosedCuts17}
                closedCuts18={closedCuts18} setClosedCuts18={setClosedCuts18}
                closedCuts19={closedCuts19} setClosedCuts19={setClosedCuts19}
                closedCuts20={closedCuts20} setClosedCuts20={setClosedCuts20}
            />
            <ResultBlock calcResult={calcResult}  
                setIsPDFGenerating={setIsPDFGenerating}
                isPDFGenerating={isPDFGenerating}
                isGridsReady={isGridsReady}
                setIsGridsReady={setIsGridsReady}
                areasCount={areasCount}
                areas1={areas1} 
                areas2={areas2} 
                areas3={areas3} 
                areas4={areas4} 
                areas5={areas5}
                areas6={areas6} 
                areas7={areas7} 
                areas8={areas8} 
                areas9={areas9} 
                areas10={areas10} 
                areas11={areas11} 
                areas12={areas12} 
                areas13={areas13} 
                areas14={areas14} 
                areas15={areas15} 
                areas16={areas16} 
                areas17={areas17} 
                areas18={areas18} 
                areas19={areas19} 
                areas20={areas20} 


                results1={results1} setResults1={setResults1}
                 results2={results2} setResults2={setResults2}
                 results3={results3} setResults3={setResults3}
                 results4={results4} setResults4={setResults4}
                 results5={results5} setResults5={setResults5}
                 results6={results6} setResults6={setResults6}
                 results7={results7} setResults7={setResults7}
                 results8={results8} setResults8={setResults8}
                 results9={results9} setResults9={setResults9}
                 results10={results10} setResults10={setResults10}
                 results11={results11} setResults11={setResults11}
                 results12={results12} setResults12={setResults12}
                 results13={results13} setResults13={setResults13}
                 results14={results14} setResults14={setResults14}
                 results15={results15} setResults15={setResults15}
                 results16={results16} setResults16={setResults16}
                 results17={results17} setResults17={setResults17}
                 results18={results18} setResults18={setResults18}
                 results19={results19} setResults19={setResults19}
                 results20={results20} setResults20={setResults20}
                 
                 cellRows1={cellRows1}
                cellRows2={cellRows2}
                cellRows3={cellRows3}
                cellRows4={cellRows4}
                cellRows5={cellRows5}
                cellRows6={cellRows6}
                cellRows7={cellRows7}
                cellRows8={cellRows8}
                cellRows9={cellRows9}
                cellRows10={cellRows10}
                cellRows11={cellRows11}
                cellRows12={cellRows12}
                cellRows13={cellRows13}
                cellRows14={cellRows14}
                cellRows15={cellRows15}
                cellRows16={cellRows16}
                cellRows17={cellRows17}
                cellRows18={cellRows18}
                cellRows19={cellRows19}
                cellRows20={cellRows20}
            />
        </>
    )
}

export default SidePanel;
