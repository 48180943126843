import React, { FC, useContext, useEffect } from "react";
import CanvasFullGrid from "./CanvasFullGrid";
import GridNumbers from "./GridNumbers";
interface Props { updateScale: number }
const CanvasGridContainer: FC<Props> = (updateScale) => {
  let cellSize: any
  let gridHeight: any = 800
  if(Number(updateScale.updateScale) == 1){
    cellSize = 100
  }
  if(Number(updateScale.updateScale) == 2){
      cellSize = 88.7
  }
  if(Number(updateScale.updateScale) == 3){
      cellSize = 80
  }
  if(Number(updateScale.updateScale) == 4){
      cellSize = 72.5
  }
  if(Number(updateScale.updateScale) == 5){
      cellSize = 66.5
  }
  if(Number(updateScale.updateScale) == 6){
      cellSize = 61.5
  }
  if(Number(updateScale.updateScale) == 7){
      cellSize = 57
  }
  if(Number(updateScale.updateScale) == 8){
      cellSize = 53.3
  }
  if(Number(updateScale.updateScale) == 9){
      cellSize = 50
  }
  if(Number(updateScale.updateScale) == 10){
    cellSize = 47
  }
if(Number(updateScale.updateScale) == 11){
  cellSize = 44.3
}
if(Number(updateScale.updateScale) == 12){
  cellSize = 42
}
if(Number(updateScale.updateScale) == 13){
  cellSize = 40
}
if(Number(updateScale.updateScale) == 14){
  cellSize = 38.1
}
if(Number(updateScale.updateScale) == 15){
  cellSize = 36.3
}
if(Number(updateScale.updateScale) == 16){
  cellSize = 34.7
}
if(Number(updateScale.updateScale) == 17){
  cellSize = 33.3
}
if(Number(updateScale.updateScale) == 18){
  cellSize = 32
}
if(Number(updateScale.updateScale) == 19){
  cellSize = 30.7
}
if(Number(updateScale.updateScale) == 20){
  cellSize = 29.6
}
if(Number(updateScale.updateScale) == 21){
  cellSize = 28.5
}
if(Number(updateScale.updateScale) == 22){
  cellSize = 27.5
}
if(Number(updateScale.updateScale) == 23){
  cellSize = 26.6
}
if(Number(updateScale.updateScale) == 24){
cellSize = 25.8
}
if(Number(updateScale.updateScale) == 25){
cellSize = 25
}
if(Number(updateScale.updateScale) == 26){
cellSize = 24.2
}
if(Number(updateScale.updateScale) == 27){
cellSize = 23.5
}
if(Number(updateScale.updateScale) == 28){
cellSize = 22.8
}
if(Number(updateScale.updateScale) == 29){
cellSize = 22.2
}
if(Number(updateScale.updateScale) == 30){
cellSize = 21.6
}
if(Number(updateScale.updateScale) == 31){
cellSize = 21
}
if(Number(updateScale.updateScale) == 32){
cellSize = 20.5
}
if(Number(updateScale.updateScale) == 33){
cellSize = 20
}
if(Number(updateScale.updateScale) == 34){
cellSize = 19.5
}

  return (
    <>
      <CanvasFullGrid
        cellSize={cellSize}
      />
      <GridNumbers
        cellSize={cellSize}
        gridHeight={gridHeight}
        updateScale={updateScale}
      />
    </>
  )
}

export default CanvasGridContainer;