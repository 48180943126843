

// @ts-nocheck

import React, { FC, useContext, useEffect, useReducer, useRef, useState} from "react";
import "./Table.scss";
import { useLocation } from "react-router-dom";

import plusImg from "../../../images/plus.png";
import {AppContext} from "../../../context/AppContext";
import AddTableRowDialog from "../Dialogs/AddTableRowDialog";
import ChangeTableRowDialog from "../Dialogs/ChangeTableRowDialog copy";
import ChangeOverlapsValues from "../Dialogs/ChangeOverlapsValues";
import cloneDeep from "lodash/cloneDeep";

interface ICategoryParams {
    categoryExceptions: any[];
    categoryRecommended: any[];
    categoryMade: any[];
    generalWidth: any[];
    usefulWidth: any[];
    verticalOverlap: any[];
    horizontalOverlap: any[];
    doubleOverlap: any[];
    tail: any[];
    maxHeight: any[];
}

interface Props {
    activeTab: any;
    setActiveTab: any;
}

const Table: FC<Props> = ({activeTab, setActiveTab}) => {
    const appContext = useContext(AppContext);
    let location = useLocation();
    //const activeCategoryOutletCtx = useActiveCategory();

    const [activeCategory, setActiveCategory] = useState(null);

    /*useEffect(() => {
        if (location) {
            let urlCategoryId = location.pathname.split('=')[1];
            let result = appContext.state.dictionary.find(item => item.id.toString() === urlCategoryId);
            setActiveCategory(result);
        }
    }, [location])*/
    useEffect(() => {
        if (activeTab) {
            let urlCategoryId = activeTab.split('=')[1];
            let result = appContext.state.dictionary.find(item => item.id.toString() === urlCategoryId);
            
            setActiveCategory(result);
        }
    }, [activeTab])

    const [modalLength, setModalLength] = useState<Boolean>(false);
    const [modalChange, setModalChange] = useState<Boolean>(false);
    const [rowId, setRowId] = useState<number>(null);
    const [modalOverlap, setModalOverlap] = useState<Boolean>(false);

    const [categoryExceptions, setCategoryExceptions] = useState<any[]>([]);
    const [categoryRecommended, setCategoryRecommended] = useState<any[]>([]);
    const [categoryMade, setCategoryMade] = useState<any[]>([]);
    const [categoryGeneralWidth, setCategoryGeneralWidth] = useState<any[]>([]);
    const [categoryUsefulWidth, setCategoryUsefulWidth] = useState<any[]>([]);
    const [categoryVerticalOverlap, setCategoryVerticalOverlap] = useState<any[]>([]);
    const [categoryHorizontalOverlap, setCategoryHorizontalOverlap] = useState<any[]>([]);
    const [categoryDoubleOverlap, setCategoryDoubleOverlap] = useState<any[]>([]);
    const [categoryTail, setCategoryTail] = useState<any[]>([]);
    const [categoryMaxHeight, setCategoryMaxHeight] = useState<any[]>([]);


    const [categoryParams, setCategoryParams] = useState<ICategoryParams[]>([]);

    const [exception, setException] = useState<string>([]);
    const [recommended, setRecommended] = useState<string>([]);
    const [made, setMade] = useState<string>([]);
    const [generalWidth, setGeneralWidth] = useState<number>(null);
    const [usefulWidth, setUsefulWidth] = useState<number>(null);
    const [verticalOverlap, setVerticalOverlap] = useState<number>(null);
    const [horizontalOverlap, setHorizontalOverlap] = useState<number>(null);
    const [doubleOverlap, setDoubleOverlap] = useState<number>(null);
    const [tail, setTail] = useState<number>(null);
    const [maxHeight, setMaxHeight] = useState<number>(null);

    const [inputGeneralWidth, setInputGeneralWidth] = useState<number>(0);
    const [inputUsefulWidth, setInputUsefulWidth] = useState<number>(0);
    const [inputVerticalOverlap, setInputVerticalOverlap] = useState<number>(0);
    const [inputHorizontalOverlap, setInputHorizontalOverlap] = useState<number>(0);
    const [inputDoubleOverlap, setInputDoubleOverlap] = useState<number>(0);
    const [inputTail, setInputTail] = useState<number>(0);

    function onChangeGeneralWidth(e: any) {
        setInputGeneralWidth(Number(e.target.value));
    }
    function onChangeUsefulWidth(e: any) {
        setInputUsefulWidth(Number(e.target.value));
    }
    function onChangeHorizontalOverlap(e: any) {
        setInputHorizontalOverlap(Number(e.target.value));
    }
    function onChangeDoubleOverlap(e: any) {
        setInputDoubleOverlap(Number(e.target.value));
    }
    function onChangeTail(e: any) {
        setInputTail(Number(e.target.value));
    }
    useEffect(() => {
        setInputVerticalOverlap(inputGeneralWidth - inputUsefulWidth)
    },[inputGeneralWidth, inputUsefulWidth])

    let onCreateLength = () => {
        setModalLength(false)
        //// API

        var requestOptions;

        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        if (categoryExceptions?.length) {
            setCategoryExceptions([...categoryExceptions, exception]);
            setCategoryRecommended([...categoryRecommended, recommended]);
            setCategoryMade([...categoryMade, made]);
            requestOptions = {
                // method: 'POST',
                method: 'PUT',
                headers: myHeaders,
                body: JSON.stringify({
                    id: activeCategory.id,
                    exceptions: [...categoryExceptions, exception],
                    recommended : [...categoryRecommended, recommended],
                    made : [...categoryMade, made],
                }),
                redirect: 'follow'
            };

        } else {
            setCategoryExceptions([exception]);
            setCategoryRecommended([recommended]);
            setCategoryMade([made]);

            requestOptions = {
                // method: 'POST',
                method: 'PUT',
                headers: myHeaders,
                body: JSON.stringify({
                    id: activeCategory.id,
                    exceptions: [exception],
                    recommended : [recommended],
                    made : [made],
                }),
                redirect: 'follow'
            };
        }

        // @ts-ignore
        fetch('https://tile.sofenty.com/api/update-category', requestOptions)
            .then(response => {
                return response.text()
            })
            .then(result => console.log("result: ", result), updateState())
            // .then(result => document.location.reload())
            .catch(error => console.log('error', error));
    }

    let onChangeLength = () => {
        setModalChange(false)
        //// API

        var requestOptions;

        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        let newCategoryExceptions = cloneDeep(categoryExceptions)
        newCategoryExceptions.splice(rowId, 1, exception)
        let newCategoryRecommended = cloneDeep(categoryRecommended)
        newCategoryRecommended.splice(rowId, 1, recommended)
        let newCategoryMade = cloneDeep(categoryMade)
        newCategoryMade.splice(rowId, 1, made)
        let newCategoryMaxHeight = cloneDeep(categoryMaxHeight)
        
        if(newCategoryMaxHeight >= newCategoryExceptions.length){
            newCategoryMaxHeight = newCategoryExceptions.length - 1
        }

        setCategoryExceptions(newCategoryExceptions);
        setCategoryRecommended(newCategoryRecommended);
        setCategoryMade(newCategoryMade);
        requestOptions = {
            // method: 'POST',
            method: 'PUT',
            headers: myHeaders,
            body: JSON.stringify({
                id: activeCategory.id,
                exceptions: newCategoryExceptions,
                recommended : newCategoryRecommended,
                made : newCategoryMade,
                maxHeight: newCategoryMaxHeight
            }),
            redirect: 'follow'
        };

        // @ts-ignore
        fetch('https://tile.sofenty.com/api/update-category', requestOptions)
            .then(response => {
                return response.text()
            })
            .then(result => console.log("result: ", result), updateState())
            // .then(result => document.location.reload())
            .catch(error => console.log('error', error));




















/*






        var requestOptions;

        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        if (categoryExceptions?.length) {
            setCategoryExceptions([...categoryExceptions, exception]);
            setCategoryRecommended([...categoryRecommended, recommended]);
            setCategoryMade([...categoryMade, made]);
            requestOptions = {
                // method: 'POST',
                method: 'PUT',
                headers: myHeaders,
                body: JSON.stringify({
                    id: activeCategory.id,
                    exceptions: [...categoryExceptions, exception],
                    recommended : [...categoryRecommended, recommended],
                    made : [...categoryMade, made],
                }),
                redirect: 'follow'
            };

        } else {
            setCategoryExceptions([exception]);
            setCategoryRecommended([recommended]);
            setCategoryMade([made]);

            requestOptions = {
                // method: 'POST',
                method: 'PUT',
                headers: myHeaders,
                body: JSON.stringify({
                    id: activeCategory.id,
                    exceptions: [exception],
                    recommended : [recommended],
                    made : [made],
                }),
                redirect: 'follow'
            };
        }

        // @ts-ignore
        fetch('https://tile.sofenty.com/api/update-category', requestOptions)
            .then(response => {
                return response.text()
            })
            .then(result => console.log("result: ", result), updateState())
            // .then(result => document.location.reload())
            .catch(error => console.log('error', error));*/
    }

    let onChangeOverlapValues = () => {
        setModalOverlap(false)
        //// API
        var requestOptions;
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

            setCategoryGeneralWidth(generalWidth);
            setCategoryUsefulWidth(usefulWidth);
            setCategoryVerticalOverlap(verticalOverlap);
            setCategoryHorizontalOverlap(horizontalOverlap);
            setCategoryDoubleOverlap(doubleOverlap);
            setCategoryTail(tail);

            requestOptions = {
                // method: 'POST',
                method: 'PUT',
                headers: myHeaders,
                body: JSON.stringify({
                    id: activeCategory.id,
                    generalWidth: generalWidth,
                    usefulWidth : usefulWidth,
                    verticalOverlap : generalWidth - usefulWidth,
                    horizontalOverlap: horizontalOverlap,
                    doubleOverlap : doubleOverlap,
                    tail : tail,
                }),
                redirect: 'follow'
            };

        // @ts-ignore
        fetch('https://tile.sofenty.com/api/update-category', requestOptions)
            .then(response => {
                return response.text()
            })
            .then(result => console.log("result: ", result), updateState())
            // .then(result => document.location.reload())
            .catch(error => console.log('error', error));
    }
    let onChangeMaxHeight = () => {
        setModalOverlap(false)
        //// API
        var requestOptions;
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

            setCategoryMaxHeight(maxHeight);

            requestOptions = {
                // method: 'POST',
                method: 'PUT',
                headers: myHeaders,
                body: JSON.stringify({
                    id: activeCategory.id,
                    maxHeight : maxHeight
                }),
                redirect: 'follow'
            };

        // @ts-ignore
        fetch('https://tile.sofenty.com/api/update-category', requestOptions)
            .then(response => {
                return response.text()
            })
            .then(result => console.log("result: ", result), updateState())
            // .then(result => document.location.reload())
            .catch(error => console.log('error', error));

    }

    useEffect(() => {
        if (activeCategory) {
            setCategoryExceptions(activeCategory.exceptions)
            setCategoryRecommended(activeCategory.recommended)
            setCategoryMade(activeCategory.made)
            setCategoryGeneralWidth(activeCategory.generalWidth);
            setCategoryUsefulWidth(activeCategory.usefulWidth);
            setCategoryVerticalOverlap(activeCategory.verticalOverlap);
            setCategoryHorizontalOverlap(activeCategory.horizontalOverlap);
            setCategoryDoubleOverlap(activeCategory.doubleOverlap);
            setCategoryTail(activeCategory.tail);
            setCategoryMaxHeight(activeCategory.maxHeight);
        }
    },[activeCategory])

    useEffect(() => {
            fetch('https://tile.sofenty.com/api/categories', ).then((data) => data.json())
            .then(((json) => {
                let urlCategoryId = activeTab.split('=')[1];
                let result = json.find(item => item.id.toString() === urlCategoryId);
                setActiveCategory(result)
                setCategoryParams(json)
            }))

    }, [categoryExceptions,categoryRecommended,categoryMade,categoryGeneralWidth,categoryUsefulWidth,categoryVerticalOverlap,categoryHorizontalOverlap,categoryDoubleOverlap,categoryTail, categoryMaxHeight] )

    function getTrs() {
        let trs = [];
        for (let i = 0; i < activeCategory?.exceptions?.length && i < activeCategory?.recommended?.length && i < activeCategory?.made?.length/* && i < activeCategory?.categoryFullOverlap?.length && i < activeCategory?.categoryUsfOverlap?.length && i < activeCategory?.categoryOverlap?.length*/; i++) {
            trs.push(
                <tr key={'table_tr'+ i} className="">
                    <td>{activeCategory.exceptions[i] || '-'}</td>
                    <td>{activeCategory.recommended[i] || '-'}</td>
                    <td>{activeCategory.made[i] || '-'}</td>
                    <td><input type="radio" name="maxHeight" checked={activeCategory.maxHeight == i ? true : false} onClick={() => {setMaxHeight(i)}}/></td>
                    <td><button onClick={() =>{deleteRow(i)}}>X</button> <button onClick={() =>{changeRow(i)}}>р</button></td>
                </tr>
            )
        }
        return trs;
    }
    useEffect(() => {
        if(maxHeight !== null){
            onChangeMaxHeight()
        }
    }, [maxHeight])

    async function getDictionaries() {
        return await fetch('https://tile.sofenty.com/api/categories', ).then((data) => data.json());
     }
 
    const updateState = () => {
         getDictionaries()
             .then(((json) => {
                 appContext.dispatch({type: 'update-dictionary', payload: {dictionary: json}})
             }))
             .catch(() => {
                 console.log(new Error('Data not loaded'));
             });
     }
     const deleteRow = (row: any) => {
        
        var requestOptions;

        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        let newCategoryExceptions = cloneDeep(categoryExceptions)
        newCategoryExceptions.splice(row, 1)
        let newCategoryRecommended = cloneDeep(categoryRecommended)
        newCategoryRecommended.splice(row, 1)
        let newCategoryMade = cloneDeep(categoryMade)
        newCategoryMade.splice(row, 1)
        let newCategoryMaxHeight = cloneDeep(categoryMaxHeight)
        
        if(newCategoryMaxHeight >= newCategoryExceptions.length){
            newCategoryMaxHeight = newCategoryExceptions.length - 1
        }

        setCategoryExceptions(newCategoryExceptions);
        setCategoryRecommended(newCategoryRecommended);
        setCategoryMade(newCategoryMade);
        requestOptions = {
            // method: 'POST',
            method: 'PUT',
            headers: myHeaders,
            body: JSON.stringify({
                id: activeCategory.id,
                exceptions: newCategoryExceptions,
                recommended : newCategoryRecommended,
                made : newCategoryMade,
                maxHeight: newCategoryMaxHeight
            }),
            redirect: 'follow'
        };

        // @ts-ignore
        fetch('https://tile.sofenty.com/api/update-category', requestOptions)
            .then(response => {
                return response.text()
            })
            .then(result => console.log("result: ", result), updateState())
            // .then(result => document.location.reload())
            .catch(error => console.log('error', error));

    }

    const changeRow = (row: any) => {
        setModalChange(true)
        setRowId(row)
        /*
        var requestOptions;

        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        let newCategoryExceptions = cloneDeep(categoryExceptions)
        newCategoryExceptions.splice(row, 1)
        let newCategoryRecommended = cloneDeep(categoryRecommended)
        newCategoryRecommended.splice(row, 1)
        let newCategoryMade = cloneDeep(categoryMade)
        newCategoryMade.splice(row, 1)
        let newCategoryMaxHeight = cloneDeep(categoryMaxHeight)
        
        if(newCategoryMaxHeight >= newCategoryExceptions.length){
            newCategoryMaxHeight = newCategoryExceptions.length - 1
        }

        setCategoryExceptions(newCategoryExceptions);
        setCategoryRecommended(newCategoryRecommended);
        setCategoryMade(newCategoryMade);
        requestOptions = {
            // method: 'POST',
            method: 'PUT',
            headers: myHeaders,
            body: JSON.stringify({
                id: activeCategory.id,
                exceptions: newCategoryExceptions,
                recommended : newCategoryRecommended,
                made : newCategoryMade,
                maxHeight: newCategoryMaxHeight
            }),
            redirect: 'follow'
        };

        // @ts-ignore
        fetch('https://tile.sofenty.com/api/update-category', requestOptions)
            .then(response => {
                return response.text()
            })
            .then(result => console.log("result: ", result), updateState())
            // .then(result => document.location.reload())
            .catch(error => console.log('error', error));
*/
    }


    function getTrs1() {
        console.log(activeCategory)
        if(typeof activeCategory !== "undefined" && activeCategory !== null){ 
            let trs1 = [];
                trs1.push(
                    <tr key={'table_tr'+ 1} className="">
                        <td>{activeCategory.generalWidth}</td>
                        <td>{activeCategory.usefulWidth}</td>
                        <td>{activeCategory.verticalOverlap}</td>
                    </tr>
                )
                if(generalWidth == null && usefulWidth == null && verticalOverlap == null){
                    setGeneralWidth(activeCategory.generalWidth)
                    setUsefulWidth(activeCategory.usefulWidth)
                    setVerticalOverlap(activeCategory.verticalOverlap)
                }
            return trs1;
        }
    }
    
    function getTrs2() {
        if(typeof activeCategory !== "undefined" && activeCategory !== null){ 
            let trs2 = [];
            trs2.push(
                <tr key={'table_tr'+ 1} className="">
                <td>{activeCategory.horizontalOverlap}</td>
                <td>{activeCategory.doubleOverlap}</td>
                <td>{activeCategory.tail}</td>
            </tr>
            )
            if(horizontalOverlap == null && doubleOverlap == null && tail == null){
                setHorizontalOverlap(activeCategory.horizontalOverlap)
                setDoubleOverlap(activeCategory.doubleOverlap)
                setTail(activeCategory.tail)
            }
        return trs2;
        }
    }
  
    return (
           <div className="table-wrapper">
                <div className="category-info">
                  <table className="table1">
                    <thead>
                    <tr>
                      <th>Не виготовляється</th>
                      <th>Рекомендовані довжини</th>
                      <th>Виготовляється</th>
                      <th>Макс. висота</th>
                      <th>Дії</th>
                      <th className="th-btn" className="small-plus-wrapper">
                        <img className="small-plus-img" onClick={() => {setModalLength(true)}} src={plusImg} alt="Plus" />
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    {getTrs()}
                    </tbody>
                  </table>
                  <div className="table2">
                    <table>
                        <thead>
                            <tr>   
                                <th>Загальна ширина</th>
                                <th>Кориснна ширина</th>
                                <th>Вертикальний нахлест</th>
                                <th className="th-btn ">
                                    <button onClick={e => setModalOverlap(true)}>Редагувати</button>
                                </th>
                            </tr>
                            {getTrs1()}
                        </thead>
                        <tbody>
                            <tr>   
                                <th>Горизонтальний нахлест</th>
                                <th>Подвійний нахлест</th>
                                <th>Хвостик</th>
                            </tr>
                            {getTrs2()}
                        </tbody>
                    </table>
                  </div>
                </div>

               {modalLength &&
                 <AddTableRowDialog

                   modalLength={modalLength}
                   setModalLength={setModalLength}

                   exception={exception}
                   setException={setException}

                   recommended={recommended}
                   setRecommended={setRecommended}

                   made={made}
                   setMade={setMade}
                
                   onCreateLengthCallback={onCreateLength}
               />}
               {modalChange &&
                 <ChangeTableRowDialog

                   modalChange={modalChange}
                   setModalChange={setModalChange}

                   exception={exception}
                   setException={setException}

                   recommended={recommended}
                   setRecommended={setRecommended}

                   made={made}
                   setMade={setMade}
                   rowId={rowId}
                
                   onChangeLengthCallback={onChangeLength}
               />}
               {modalOverlap &&
                 <ChangeOverlapsValues

                   modalOverlap={modalOverlap}
                   setModalOverlap={setModalOverlap}

                   generalWidth={generalWidth} 
                   setGeneralWidth={setGeneralWidth}

                   usefulWidth={usefulWidth} 
                   setUsefulWidth={setUsefulWidth}

                   verticalOverlap={verticalOverlap} 
                   setVerticalOverlap={setVerticalOverlap}

                   horizontalOverlap={horizontalOverlap} 
                   setHorizontalOverlap={setHorizontalOverlap}

                   doubleOverlap={doubleOverlap} 
                   setDoubleOverlap={setDoubleOverlap}

                   tail={tail} 
                   setTail={setTail}
                
                   onChangeOverlapValuesCallback={onChangeOverlapValues}
               />}
        </div>
    );
}

export default Table;