import React, {FC, useState} from "react";

interface ChangeOverlapsValuesProps {
    modalOverlap: any;
    setModalOverlap: any;

    generalWidth: any;
    setGeneralWidth: any;

    usefulWidth: any;
    setUsefulWidth: any;

    verticalOverlap: any; 
    setVerticalOverlap: any;

    horizontalOverlap: any;
    setHorizontalOverlap: any;

    doubleOverlap: any;
    setDoubleOverlap: any;
    
    tail: any;
    setTail: any;
    onChangeOverlapValuesCallback: any;
}

const ChangeOverlapsValues: FC<ChangeOverlapsValuesProps> = ({ modalOverlap, setModalOverlap, generalWidth, setGeneralWidth, usefulWidth, setUsefulWidth,
    verticalOverlap, setVerticalOverlap, horizontalOverlap, setHorizontalOverlap, doubleOverlap, setDoubleOverlap, tail, setTail, onChangeOverlapValuesCallback }) => {
    
    function onChangeOverlapValues() {
        onChangeOverlapValuesCallback();
    }
    function onChangeGeneralWidth(e: any) {
        setGeneralWidth(Number(e.target.value));
    }
    function onChangeUsefulWidth(e: any) {
        setUsefulWidth(Number(e.target.value));
    }
    function onChangeHorizontalOverlap(e: any) {
        setHorizontalOverlap(Number(e.target.value));
    }
    function onChangeDoubleOverlap(e: any) {
        setDoubleOverlap(Number(e.target.value));
    }
    function onChangeTail(e: any) {
        setTail(Number(e.target.value));
    }
    return (
        <>
            {modalOverlap && <div className="change-values-modal-wrapper">
                <div className='modal'>
                    <div className='form'>
                        <button className="pop-up-close" onClick={(e) => {
                            setModalOverlap(false)
                        }}>X
                        </button>
                        <div className='right'>
                            <div className='header'>
                                <div>Змінити значення</div>
                                <div style={{fontSize: '12px', color: 'darkgray', fontWeight: 400}}>Ви можете змінити одне чи більше значень 
                                *значення вертикального нахдесту зміниться автоматично*
                                </div>
                            </div>
                            <div>
                                <div className='label'>Загальна ширина</div>
                                <input type='text' value={generalWidth} onChange={onChangeGeneralWidth.bind(this)} />
                            </div>

                            <div>
                                <div className='label'>Кориснна ширина</div>
                                <input value={usefulWidth} type='text' onChange={onChangeUsefulWidth.bind(this)}/>
                            </div>

                            <div>
                                <div className='label'>Горизонтальний нахлест</div>
                                <input type='text' value={horizontalOverlap}onChange={onChangeHorizontalOverlap.bind(this)}/>
                            </div>

                            <div>
                                <div className='label'>Подвійний нахлест</div>
                                <input type='text' value={doubleOverlap} onChange={onChangeDoubleOverlap.bind(this)}/>
                            </div>

                            <div>
                                <div className='label'>Хвостик</div>
                                <input type='text' value={tail} onChange={onChangeTail.bind(this)}/>
                            </div>

                            <button className="save" onClick={e => {
                                onChangeOverlapValues()
                            }}>
                                Змінити
                            </button>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}

export default ChangeOverlapsValues;