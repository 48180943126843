import React, {createContext, FC, useEffect, useReducer, useState} from "react";

import './MainPage.scss';

const MainPage: FC = () => {

    return (
        <div className="main-page-container">
            Головна сторінка додатку
        </div>

    );
}

export default MainPage;
