import React, {FC, useContext, useEffect, useState} from "react";

import Calculator from "./Calculator/Calculator";
import Dictionary from "./Dictionary/Dictionary";
import { AppContext } from "../context/AppContext";
import Routers from "../components/Routers";
import { Link } from "react-router-dom";

/// App component

const SiteWrapper: FC = () => {

    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [activeTab, setActiveTab] = useState("main");
    const appContext = useContext(AppContext);

    async function getDictionaries() {
       return await fetch('https://tile.sofenty.com/api/categories', ).then((data) => data.json());
    }

    useEffect(() => {
        getDictionaries()
            .then(((json) => {
                appContext.dispatch({type: 'update-dictionary', payload: {dictionary: json}})
                setIsDataLoaded(true);
            }))
            .catch(() => {
                console.log(new Error('Data not loaded'));
            });
    }, []);

    return (
        <div className="general-container">
            <div className="top-row">
                <div className="content-container space-between">

                    <Link to="/" className="logo">
                        <img
                            src="https://tile.ua/wp-content/themes/twenty-twenty-one-child/images/svg/xlogo-ua.png.pagespeed.ic.olRBQyltns.png"
                            alt="Tile.ua" data-pagespeed-url-hash="2528759146" />
                        {' '}calculator
                    </Link>

                    <div className="page-types">

                        <button style={{marginRight: '15px'}}
                                onClick={ ()=> {
                                    //window.location.href = '/dictionary'
                                    if(appContext.state.isEditedMode){
                                    setActiveTab("dictionary")
                                    }
                                    else{alert("Перейдіть у режим побудови")}
                                }}>
                            Довідник
                        </button>

                        <button style={{marginRight: '15px'}}
                                onClick={ ()=> {
                                    //window.location.href = '/calculator'
                                    setActiveTab("calculator")
                                }}>
                            Калькулятор
                        </button>

                    </div>
                </div>
            </div>

            <div className="main-row">
                <div className="content-container">
                    {isDataLoaded ? <Routers activeTab={activeTab} setActiveTab={setActiveTab}/> : <div style={{marginTop: '30px'}}>Дані не завантажено, сталася помилка</div>}
                </div>
            </div>
        </div>
    );
}

export default SiteWrapper;
