import React, { FC } from "react";

import "./App.scss";
import { AppContextProvider} from "./context/AppContext";
import SiteWrapper from "./components/SiteWrapper";

/// App component

const App: FC = () => {
    return (
        <AppContextProvider>
            <SiteWrapper />
        </AppContextProvider>
    );
}

export default App;
