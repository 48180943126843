import React, {FC, useState, useContext, useRef} from "react";
import { Circle, Layer, Line, Text } from "react-konva";
import { idText } from "typescript";
import { AppContext } from "../../context/AppContext"
import {scalesConfig} from "../../data"
import { useGridConfig } from "../../hooks/useGridConfig";
import { gridParams } from "../../data";
import cloneDeep from "lodash/cloneDeep";

interface Props {
    width: number;
    clickedCoords: any;
    isDottAdditional: any;
    setIsDottAdditional: any;
    prevStep: any;
    setPrevStep: any;
    nextStep: any;
    setNextStep: any;
    deleteDot: any;
    setDeleteDot: any;
    coords: any;
    doubleCutFigure: any;
    setDoubleCutFigure: any;
    xCutShift: any;
    yCutShift: any;
    setXCutShift: any;
    setYCutShift: any;
    cutFigureCount: any;
    setCutFigureCount: any;
    deleteCutFigure: any;
    setDeleteCutFigure: any;
    deleteFigure: any;
    areasCount: any;

    builtCutCustomPoints: any;
    setBuiltCutCustomPoints: any;
    cutFigureStart: any;
    setCutFigureStart: any;

    cutFigures: any; setCutFigures: any;
    cutFiguresLines: any; setCutFiguresLines: any;
    start: any; setStart: any;

    areas1: any;
    setAreas1: any;
    areas2: any; 
    setAreas2: any;
    areas3: any;
    setAreas3: any;
    areas4: any;
    setAreas4: any;
    areas5: any; 
    setAreas5: any;
    areas6: any;
    setAreas6: any;
    areas7: any;
    setAreas7: any;
    areas8: any; 
    setAreas8: any;
    areas9: any;
    setAreas9: any;
    areas10: any;
    setAreas10: any;
    areas11: any;
    setAreas11: any;
    areas12: any;
    setAreas12: any;
    areas13: any;
    setAreas13: any;
    areas14: any;
    setAreas14: any;
    areas15: any;
    setAreas15: any;
    areas16: any;
    setAreas16: any;
    areas17: any;
    setAreas17: any;
    areas18: any;
    setAreas18: any;
    areas19: any;
    setAreas19: any;
    areas20: any;
    setAreas20: any;

    cuts1: any;
    setCuts1: any;
    cuts2: any; 
    setCuts2: any;
    cuts3: any;
    setCuts3: any;
    cuts4: any;
    setCuts4: any;
    cuts5: any; 
    setCuts5: any;
    cuts6: any;
    setCuts6: any;
    cuts7: any;
    setCuts7: any;
    cuts8: any; 
    setCuts8: any;
    cuts9: any;
    setCuts9: any;
    cuts10: any;
    setCuts10: any;
    cuts11: any;
    setCuts11: any;
    cuts12: any;
    setCuts12: any;
    cuts13: any;
    setCuts13: any;
    cuts14: any;
    setCuts14: any;
    cuts15: any;
    setCuts15: any;
    cuts16: any;
    setCuts16: any;
    cuts17: any;
    setCuts17: any;
    cuts18: any;
    setCuts18: any;
    cuts19: any;
    setCuts19: any;
    cuts20: any;
    setCuts20: any;
   
    closedCuts1: any; setClosedCuts1: any
    closedCuts2: any;setClosedCuts2: any
    closedCuts3: any;setClosedCuts3: any
    closedCuts4: any;setClosedCuts4: any
    closedCuts5: any;setClosedCuts5: any
    closedCuts6: any;setClosedCuts6: any
    closedCuts7: any;setClosedCuts7: any
    closedCuts8: any;setClosedCuts8: any
    closedCuts9: any;setClosedCuts9: any
    closedCuts10: any;setClosedCuts10: any
    closedCuts11: any;setClosedCuts11: any
    closedCuts12: any;setClosedCuts12: any
    closedCuts13: any;setClosedCuts13: any
    closedCuts14: any;setClosedCuts14: any
    closedCuts15: any;setClosedCuts15: any
    closedCuts16: any;setClosedCuts16: any
    closedCuts17: any;setClosedCuts17: any
    closedCuts18: any;setClosedCuts18: any
    closedCuts19: any;setClosedCuts19: any
    closedCuts20: any;setClosedCuts20: any
    
    lines1: any;
    setLines1: any;
    lines2: any; 
    setLines2: any;
    lines3: any;
    setLines3: any;
    lines4: any;
    setLines4: any;
    lines5: any; 
    setLines5: any;
    lines6: any;
    setLines6: any;
    lines7: any;
    setLines7: any;
    lines8: any; 
    setLines8: any;
    lines9: any;
    setLines9: any;
    lines10: any;
    setLines10: any;
    lines11: any;
    setLines11: any;
    lines12: any;
    setLines12: any;
    lines13: any;
    setLines13: any;
    lines14: any;
    setLines14: any;
    lines15: any;
    setLines15: any;
    lines16: any;
    setLines16: any;
    lines17: any;
    setLines17: any;
    lines18: any;
    setLines18: any;
    lines19: any;
    setLines19: any;
    lines20: any;
    setLines20: any;

    cutLines1: any;
    setCutLines1: any;
    cutLines2: any; 
    setCutLines2: any;
    cutLines3: any;
    setCutLines3: any;
    cutLines4: any;
    setCutLines4: any;
    cutLines5: any; 
    setCutLines5: any;
    cutLines6: any;
    setCutLines6: any;
    cutLines7: any;
    setCutLines7: any;
    cutLines8: any; 
    setCutLines8: any;
    cutLines9: any;
    setCutLines9: any;
    cutLines10: any;
    setCutLines10: any;
    cutLines11: any;
    setCutLines11: any;
    cutLines12: any;
    setCutLines12: any;
    cutLines13: any;
    setCutLines13: any;
    cutLines14: any;
    setCutLines14: any;
    cutLines15: any;
    setCutLines15: any;
    cutLines16: any;
    setCutLines16: any;
    cutLines17: any;
    setCutLines17: any;
    cutLines18: any;
    setCutLines18: any;
    cutLines19: any;
    setCutLines19: any;
    cutLines20: any;
    setCutLines20: any;

    /*eaves1: any;
    setEaves1: any;
    eaves2: any;
    setEaves2: any;
    eaves3: any;
    setEaves3: any;
    apex1: any;
    setApex1: any;
    apex2: any;
    setApex2: any;
    apex3: any;
    setApex3: any;
    isLinesClosed1: any;
    setIsLinesClosed1: any;
    isLinesClosed2: any;
    setIsLinesClosed2: any;
    isLinesClosed3: any;
    setIsLinesClosed3: any;*/
}

const CutFigure: FC<Props> = ({width, clickedCoords, isDottAdditional, setIsDottAdditional, prevStep, setPrevStep, nextStep, setNextStep, 
    deleteDot, setDeleteDot, coords, doubleCutFigure, setDoubleCutFigure, xCutShift, yCutShift, setXCutShift, 
    setYCutShift, cutFigureCount, setCutFigureCount, deleteCutFigure, setDeleteCutFigure, deleteFigure, areasCount,
    builtCutCustomPoints, setBuiltCutCustomPoints, cutFigureStart, setCutFigureStart, start, setStart,

    areas1,
    setAreas1,
    areas2, 
    setAreas2,
    areas3,
    setAreas3,
    areas4,
    setAreas4,
    areas5, 
    setAreas5,
    areas6,
    setAreas6,
    areas7,
    setAreas7,
    areas8, 
    setAreas8,
    areas9,
    setAreas9,
    areas10,
    setAreas10,
    areas11,
    setAreas11,
    areas12,
    setAreas12,
    areas13,
    setAreas13,
    areas14,
    setAreas14,
    areas15,
    setAreas15,
    areas16,
    setAreas16,
    areas17,
    setAreas17,
    areas18,
    setAreas18,
    areas19,
    setAreas19,
    areas20,
    setAreas20,



    cuts1,
    setCuts1,
    cuts2, 
    setCuts2,
    cuts3,
    setCuts3,
    cuts4,
    setCuts4,
    cuts5, 
    setCuts5,
    cuts6,
    setCuts6,
    cuts7,
    setCuts7,
    cuts8, 
    setCuts8,
    cuts9,
    setCuts9,
    cuts10,
    setCuts10,
    cuts11,
    setCuts11,
    cuts12,
    setCuts12,
    cuts13,
    setCuts13,
    cuts14,
    setCuts14,
    cuts15,
    setCuts15,
    cuts16,
    setCuts16,
    cuts17,
    setCuts17,
    cuts18,
    setCuts18,
    cuts19,
    setCuts19,
    cuts20,
    setCuts20,



    lines1,
    setLines1,
    lines2, 
    setLines2,
    lines3,
    setLines3,
    lines4,
    setLines4,
    lines5, 
    setLines5,
    lines6,
    setLines6,
    lines7,
    setLines7,
    lines8, 
    setLines8,
    lines9,
    setLines9,
    lines10,
    setLines10,
    lines11,
    setLines11,
    lines12,
    setLines12,
    lines13,
    setLines13,
    lines14,
    setLines14,
    lines15,
    setLines15,
    lines16,
    setLines16,
    lines17,
    setLines17,
    lines18,
    setLines18,
    lines19,
    setLines19,
    lines20,
    setLines20,

    cutLines1,
    setCutLines1,
    cutLines2, 
    setCutLines2,
    cutLines3,
    setCutLines3,
    cutLines4,
    setCutLines4,
    cutLines5, 
    setCutLines5,
    cutLines6,
    setCutLines6,
    cutLines7,
    setCutLines7,
    cutLines8, 
    setCutLines8,
    cutLines9,
    setCutLines9,
    cutLines10,
    setCutLines10,
    cutLines11,
    setCutLines11,
    cutLines12,
    setCutLines12,
    cutLines13,
    setCutLines13,
    cutLines14,
    setCutLines14,
    cutLines15,
    setCutLines15,
    cutLines16,
    setCutLines16,
    cutLines17,
    setCutLines17,
    cutLines18,
    setCutLines18,
    cutLines19,
    setCutLines19,
    cutLines20,
    setCutLines20,
    cutFigures, setCutFigures,
    cutFiguresLines, setCutFiguresLines,

    closedCuts1,setClosedCuts1,
    closedCuts2,setClosedCuts2,
    closedCuts3,setClosedCuts3,
    closedCuts4,setClosedCuts4,
    closedCuts5,setClosedCuts5,
    closedCuts6,setClosedCuts6,
    closedCuts7,setClosedCuts7,
    closedCuts8,setClosedCuts8,
    closedCuts9,setClosedCuts9,
    closedCuts10,setClosedCuts10,
    closedCuts11,setClosedCuts11,
    closedCuts12,setClosedCuts12,
    closedCuts13,setClosedCuts13,
    closedCuts14,setClosedCuts14,
    closedCuts15,setClosedCuts15,
    closedCuts16,setClosedCuts16,
    closedCuts17,setClosedCuts17,
    closedCuts18,setClosedCuts18,
    closedCuts19,setClosedCuts19,
    closedCuts20,setClosedCuts20,
    
    /*eaves1, setEaves1, eaves2, setEaves2, eaves3, setEaves3, 
    apex1, setApex1, apex2, setApex2, apex3, setApex3, isLinesClosed1, setIsLinesClosed1, isLinesClosed2, setIsLinesClosed2, isLinesClosed3,  setIsLinesClosed3*/

}) => {

    const gridConfig = useGridConfig(window, 1);

    const [activeDottCoordsX, setActiveDottCoordsX] = useState(null);
    const [activeDottCoordsY, setActiveDottCoordsY] = useState(null);
    const [addpoint, setAddPoint] = useState([]);
    const [lastCanceledPoint, setLastCanceledPoint] = useState([]);
    const [copyCutId, setCopyCutId] = useState(-1);
    const [qwe, setQwe] = useState(null);
    const [onDottClick, setOnDottClick] = useState(true);
    const [deleteLastDott, setDeleteLastDott] = useState(true);
    const [lastClickedDott, setlastClickedDott] = useState(null);
    

    

    const appContext = useContext(AppContext);
    const lineRef = React.useRef(null);

    const getYCoord = (y: any) => {
        // @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 1){
            // @ts-ignore
            return -700 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 2){
            // @ts-ignore
            return -712 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 3){
            // @ts-ignore
            return -722 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 4){
            // @ts-ignore
            return -730 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 5){
            // @ts-ignore
            return -735 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 6){
            // @ts-ignore
            return -740 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 7){
            // @ts-ignore
            return -742 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 8){
            // @ts-ignore
            return -747 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 9){
            // @ts-ignore
            return -751 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 10){
            // @ts-ignore
            return -754 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 11){
            // @ts-ignore
            return -757 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 12){
            // @ts-ignore
            return -759 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 13){
            // @ts-ignore
            return -762 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 14){
            // @ts-ignore
            return -763 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 15){
            // @ts-ignore
            return -764 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 16){
            // @ts-ignore
            return -767 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 17){
            // @ts-ignore
            return -766 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 18){
            // @ts-ignore
            return -768 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 19){
            // @ts-ignore
            return -770 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 20){
            // @ts-ignore
            return -772 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 21){
            // @ts-ignore
            return -773 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 22){
            // @ts-ignore
            return -774 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 23){
            // @ts-ignore
            return -774.5 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 24){
            // @ts-ignore
            return -775 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 25){
            // @ts-ignore
            return -776 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 26){
            // @ts-ignore
            return -777 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 27){
            // @ts-ignore
            return -778 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 28){
            // @ts-ignore
            return -778.5 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 29){
            // @ts-ignore
            return -779 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 30){
            // @ts-ignore
            return -779.5 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 31){
            // @ts-ignore
            return -780 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 32){
            // @ts-ignore
            return -780.5 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
         if(scalesConfig[`${appContext.state.selectedScale}`] == 33){
            // @ts-ignore
            return -781 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 34){
            // @ts-ignore
            return -781.5 + (y * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
        }
    }
    const getXCoord = (x: any) => {
        return gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize + (x * gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize)
    }
    const getYByCoord = (y: any) => {
        // @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 1){
            return (y + 700) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 2){
            return (y + 712) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 3){
            return (y + 722) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 4){
            return (y + 730) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 5){
            return (y + 735) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 6){
            return (y + 740) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 7){
            return (y + 742) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 8){
            return (y + 747) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 9){
            return (y + 751) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 10){
            return (y + 754) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 11){
            return (y + 757) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 12){
            return (y + 759) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 13){
            return (y + 762) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 14){
            return (y + 763) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 15){
            return (y + 764) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 16){
            return (y + 767) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 17){
            return (y + 766) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 18){
            return (y + 768) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 19){
            return (y + 770) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 20){
            return (y + 772) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 21){
            return (y + 773) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 22){
            return (y + 774) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 23){
            return (y + 774.5) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 24){
            return (y + 775) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 25){
            return (y + 776) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 26){
            return (y + 777) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 27){
            //return (y + 775) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
            return (y + 778) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 28){
            //return (y + 920) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
            return (y + 778.5) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 29){
            return (y + 799) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 30){
            return (y + 779.5) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 31){
            //return (y + 775) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
            return (y + 780) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 32){
            //return (y + 920) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
            return (y + 780.5) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 33){
            return (y + 781) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 34){
            return (y + 781.5) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }
    }
    const getXByCoord = (x: any) => {
        return (x - gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
    }
    React.useEffect(() => {
        if(appContext.state.isEditedMode == false && deleteLastDott == false){
            if (clickedCoords !== null && lastClickedDott == null && onDottClick &&  isDottAdditional == false && addpoint.length == 0) {
                if(eval("closedCuts" + areasCount).length == 0){
                    eval("setClosedCuts" + areasCount)([false])
                    let pointsCopy: any = [];
                    let cutAreasCopy: any = []
                    for (let i = 0; i < eval("cuts" + areasCount).length; i++) {
                        pointsCopy[i] = eval("cuts" + areasCount)[i];
                    }
                    if(pointsCopy.length == 0){
                        pointsCopy.push([])
                        cutAreasCopy.push(areasCount)
                    }
                    pointsCopy[0].push(clickedCoords);
                    eval("setCuts" + areasCount)(pointsCopy);
                    setAddPoint([])
                    setLastCanceledPoint([])
                }
                if(eval("closedCuts" + areasCount).length > 0){
                    let index = eval("closedCuts" + areasCount).indexOf(false)
                    let pointsCopy: any = [];
                    let cutAreasCopy: any = []
                    for (let i = 0; i < eval("cuts" + areasCount).length; i++) {
                        pointsCopy[i] = eval("cuts" + areasCount)[i];
                    }
                    if(pointsCopy.length < index + 1){
                        pointsCopy.push([])
                        cutAreasCopy.push(areasCount)
                    }
                    pointsCopy[index].push(clickedCoords);
                    eval("setCuts" + areasCount)(pointsCopy);
                    setAddPoint([])
                    setLastCanceledPoint([])
                }
            }
            if(onDottClick == false && isDottAdditional == false){
                setOnDottClick(true)
            }
            if(lastClickedDott !== null && onDottClick && isDottAdditional == false && addpoint.length == 0 && clickedCoords !== null){
                let pointsCopy: any = [];
                let index = eval("closedCuts" + areasCount).indexOf(false)
                for (let i = 0; i < eval("cuts" + areasCount).length; i++) {
                    pointsCopy[i] = eval("cuts" + areasCount)[i];
                }
                pointsCopy[index].splice(lastClickedDott + 1, 0, clickedCoords);
                eval("setCuts" + areasCount)(pointsCopy);

                setlastClickedDott(null)
                setAddPoint([])
                setActiveDottCoordsX(null)
                setActiveDottCoordsY(null)
                setAddPoint([])
            }
            if(isDottAdditional && clickedCoords !== null){
                setAddPoint(clickedCoords)
                setIsDottAdditional(false)
            }
            if(isDottAdditional == false && addpoint.length !== 0 && lastClickedDott == null && clickedCoords !== null && onDottClick){
                let pointsCopy: any = [];
                let index = eval("closedCuts" + areasCount).indexOf(false)
                for (let i = 0; i < eval("cuts" + areasCount).length; i++) {
                    pointsCopy[i] = eval("cuts" + areasCount)[i];
                }
                // @ts-ignore
                pointsCopy[index].push({x: addpoint.x, y: addpoint.y + (clickedCoords.y + 700)});
                eval("setCuts" + areasCount)(pointsCopy);
                setAddPoint([])
                setAddPoint([])
            }
            if(isDottAdditional == false && addpoint.length !== 0 && lastClickedDott !== null && clickedCoords !== null && onDottClick){
                let pointsCopy: any = [];
                let index = eval("closedCuts" + areasCount).indexOf(false)
                for (let i = 0; i < eval("cuts" + areasCount).length; i++) {
                    pointsCopy[i] = eval("cuts" + areasCount)[i];
                }// @ts-ignore
                pointsCopy[index].splice(lastClickedDott + 1, 0, {x: addpoint.x, y: addpoint.y + (clickedCoords.y + 700)});
                eval("setCuts" + areasCount)(pointsCopy);
                setlastClickedDott(null)
                setAddPoint([])
                setActiveDottCoordsX(null)
                setActiveDottCoordsY(null)
                setAddPoint([])
            }
        }
        else{
            setDeleteLastDott(false)
        }
    }, [clickedCoords]);
    React.useEffect(() => {
        if(prevStep == true && appContext.state.isEditedMode == false){
            let pointsCopy = cloneDeep(eval("cuts" + areasCount))
            let index = eval("closedCuts" + areasCount).indexOf(false)
            // @ts-ignore
            let canceledPointsCopy: any = [];
            for (let i = 0; i < lastCanceledPoint.length; i++) {
                canceledPointsCopy[i] = lastCanceledPoint[i];
            }
            if(qwe == null && pointsCopy[index].length > 0){
                canceledPointsCopy.push([-1, pointsCopy[index].pop()])
            }
            if(qwe !== null){
                let zxc = pointsCopy[index].splice(qwe + 1, 1)
                canceledPointsCopy.push([qwe, zxc[0]])
            }
            setCuts1(pointsCopy);
            setLastCanceledPoint(canceledPointsCopy)
            setPrevStep(false)
            setQwe(null)
        }
        if(nextStep == true && lastCanceledPoint.length !== 0 && appContext.state.isEditedMode == false){
            let pointsCopy = cloneDeep(eval("cuts" + areasCount))
            let index = eval("closedCuts" + areasCount).indexOf(false)
            let canceledPointsCopy: any = [];
            for (let i = 0; i < lastCanceledPoint.length; i++) {
                canceledPointsCopy[i] = lastCanceledPoint[i];
            }
            // @ts-ignore
            if(lastCanceledPoint[lastCanceledPoint.length - 1][0] == -1){
                pointsCopy[index].push(lastCanceledPoint[lastCanceledPoint.length - 1][1])
            }
            // @ts-ignore
            if(lastCanceledPoint[lastCanceledPoint.length - 1][0] !== -1){
                pointsCopy[index].splice(lastCanceledPoint[lastCanceledPoint.length - 1][0] + 1, 0, lastCanceledPoint[lastCanceledPoint.length - 1][1])
            }
            canceledPointsCopy.pop()
            setCuts1(pointsCopy);
            setLastCanceledPoint(canceledPointsCopy)
            setNextStep(false)
        }
    }, [prevStep, nextStep]);
    React.useEffect(() => {
        if(deleteDot == true && lastClickedDott !== null && appContext.state.isEditedMode == false){
            let pointsCopy = cloneDeep(eval("cuts" + areasCount))
            let index = eval("closedCuts" + areasCount).indexOf(false)
            pointsCopy[index].splice(lastClickedDott, 1);
            eval("setCuts" + areasCount)(pointsCopy);
            setlastClickedDott(null)
            setActiveDottCoordsX(null)
            setActiveDottCoordsY(null)
        }
        setDeleteDot(null)
    }, [deleteDot]);
    React.useEffect(() => {
        if(doubleCutFigure == true && appContext.state.isEditedMode == false){
            if(copyCutId !== -1){
                let pointsCopy: any = [];
                for(let i = 0; i < eval("cuts" + areasCount).length; i++){
                    pointsCopy[i] = eval("cuts" + areasCount)[i];
                }
                // @ts-ignore
                let leftDott: any =  pointsCopy[copyCutId][0].x
                // @ts-ignore
                let rightDott: any =  pointsCopy[copyCutId][0].x
                for (let i = 0; i < eval("cuts" + areasCount)[copyCutId].length; i++) {
                    if(pointsCopy[copyCutId][i].x < leftDott){
                        leftDott = pointsCopy[copyCutId][i].x
                    }
                    if(pointsCopy[copyCutId][i].x > rightDott){
                        rightDott = pointsCopy[copyCutId][i].x
                    }
                }
                let mnb = rightDott - leftDott
                let pointsCopyItem: any = []
                for (let i = 0; i < pointsCopy[copyCutId].length; i++) {
                    pointsCopyItem.push({x: pointsCopy[copyCutId][i].x + mnb + (xCutShift * 100), y: pointsCopy[copyCutId][i].y})
                }
                pointsCopy.push(pointsCopyItem)
                eval("setCuts" + areasCount)(pointsCopy)

                let linesCopy: any = [];
                // @ts-ignore
                for (let i = 0; i < eval("cuts" + areasCount).length; i++) {
                    linesCopy[i] = eval("cutLines" + areasCount)[i]
                }
                let l: any = [];
                for(let i = 0; i < pointsCopyItem.length; i++){
                    // @ts-ignore
                    l.push(pointsCopyItem[i].x);
                    // @ts-ignore
                    l.push(pointsCopyItem[i].y);
                }
                linesCopy.push(l)
                eval("setCutLines" + areasCount)(linesCopy);
                setCopyCutId(eval("cuts" + areasCount + ".length"))
                let closedCutsCopy = cloneDeep(eval("closedCuts" + areasCount))
                closedCutsCopy.splice(closedCutsCopy.length - 1, 0, true)
                eval("setClosedCuts" + areasCount)(closedCutsCopy)
            }
            else{alert("Виберіть фігуру для дублювання")}
            setDoubleCutFigure(false)
        }
    }, [doubleCutFigure]);
    React.useEffect(() => {
        if(cutFigureStart == true && appContext.state.isEditedMode == false){
            if(copyCutId !== -1){
                // @ts-ignore
                let xStartCoord = eval("cuts" + areasCount)[copyCutId][0].x - (xCutShift)
                // @ts-ignore
                let yStartCoord = eval("cuts" + areasCount)[copyCutId][0].y - (yCutShift)
                // @ts-ignore
                let pointsCopy = cloneDeep(eval("cuts" + areasCount))
                for (let i = 0; i < eval("cuts" + areasCount)[copyCutId].length; i++) {
                    // @ts-ignore
                    pointsCopy[copyCutId][i].x = pointsCopy[copyCutId][i].x - xStartCoord// + 100
                    pointsCopy[copyCutId][i].y = pointsCopy[copyCutId][i].y - yStartCoord// - 700
                }
                eval("setCuts" + areasCount)(pointsCopy)
                setCopyCutId(-1)
            }
            else{alert("Виберіть фігуру для задання точки відліку")}
            setCutFigureStart(false)
        }
    }, [cutFigureStart]);
    React.useEffect(() => {
            // build anchors
            let buildCustomPointsCopy: any = [];
            for (let a = 0; a < eval("cuts" + areasCount).length; a++) {
                let buildCustomPointsCopyItem: any = [];
                for(let i = 0; i < eval("cuts" + areasCount)[a].length; i++){
                    // @ts-ignore
                    buildCustomPointsCopyItem.push(buildCutAnchor(getXCoord(eval("cuts" + areasCount)[a][i].x), getYCoord(eval("cuts" + areasCount)[a][i].y), a, i))
                }
                buildCustomPointsCopy.push(buildCustomPointsCopyItem)
            }
            setBuiltCutCustomPoints(buildCustomPointsCopy);

            // set array of points to  
            
            let l: any = [];
            for (let a = 0; a < eval("cuts" + areasCount).length; a++) {
                let lItem: any = [];
                for(let n = 0; n < eval("cuts" + areasCount)[a].length; n++){
                    // @ts-ignore
                    lItem.push(getXCoord(eval("cuts" + areasCount)[a][n].x));
                    // @ts-ignore
                    lItem.push(getYCoord(eval("cuts" + areasCount)[a][n].y));
                }
                l.push(lItem)
            }
            eval("setCutLines" + areasCount)(l);
    }, [eval("cuts" + areasCount), areasCount, copyCutId, appContext.state.selectedScale, appContext.state.isCutFigureMode]);
    React.useEffect(() => {
        if(deleteFigure == true && appContext.state.isEditedMode == false ){
            eval("setCuts" + areasCount)([])
            setBuiltCutCustomPoints([])
            eval("setCutLines" + areasCount)([])
        }
    }, [deleteFigure]);
    React.useEffect(() => {
        if(deleteCutFigure == true && appContext.state.isEditedMode == false){
            if(copyCutId !== -1){
                let buildCustomPointsCopy: any = [];
                for (let a = 0; a < eval("cuts" + areasCount).length; a++) {
                    buildCustomPointsCopy[a] = eval("cuts" + areasCount)[a]
                }
                buildCustomPointsCopy.splice(copyCutId, 1)
                eval("setCuts" + areasCount)(buildCustomPointsCopy);

                // set array of points to 
                
                let l: any = [];
                for (let a = 0; a < eval("cutLines" + areasCount).length; a++) {
                    l[a] = eval("cutLines" + areasCount)[a]
                }
                l.splice(copyCutId, 1)
                eval("setCutLines" + areasCount)(l);
                setCopyCutId(-1)
                let closedCutsCopy: any = []
                for(let i = 0; i < eval("closedCuts" + areasCount).length; i++){
                    closedCutsCopy[i] = eval("closedCuts" + areasCount)[i]
                }
                closedCutsCopy.splice(copyCutId, 1)
                eval("setClosedCuts" + areasCount)(closedCutsCopy);
            }
            else{alert("Виберіть фігуру для видалення")}
            setDeleteCutFigure(false)
        }
    }, [deleteCutFigure]);

    // Побудова точки
    let buildCutAnchor = (x: number, y: number, name: string, key: number) => {
        // @ts-ignore
        let bottomDott: any = eval("cuts" + areasCount)[name][0].y;
        // @ts-ignore
        let topDott: any = eval("cuts" + areasCount)[name][0].y;
        // @ts-ignore
        let rightDott: any = eval("cuts" + areasCount)[name][0].x;
        // @ts-ignore
        let leftDott: any = eval("cuts" + areasCount)[name][0].x;
        for (let i = 0; i < eval("cuts" + areasCount)[name].length; i++) {
            if(eval("cuts" + areasCount)[name][i].y < bottomDott){
                bottomDott = eval("cuts" + areasCount)[name][i].y
            }
            if(eval("cuts" + areasCount)[name][i].y > topDott){
                topDott = eval("cuts" + areasCount)[name][i].y
            }
            if(eval("cuts" + areasCount)[name][i].x > rightDott){
                rightDott = eval("cuts" + areasCount)[name][i].x
            }
            if(eval("cuts" + areasCount)[name][i].x < leftDott){
                leftDott = eval("cuts" + areasCount)[name][i].x
            }
        }
        return(
            <>
                <Circle  key={key}
                            x = {x || 0}
                            y ={ y || 0}
                            radius = {8}
                            stroke = {'#666'}
                            fill = {'#ddd'}
                            strokeWidth = {2}
                            name = {name}
                            id = {key.toString()}
                            draggable = {true}
                            onClick = {activeDott.bind(this)}
                            onMouseOver = {onMouseOverHandler.bind(this)}
                            onMouseOut = {onMouseOutHandler.bind(this)}
                            onDragMove = {onDragMoveHandler.bind(this)}
                />
                <Text  x = {getXCoord((leftDott + rightDott) / 2) || 0}
                        // @ts-ignore 
                        y ={getYCoord((topDott + bottomDott) / 2) || 0} 
                        text={name + 1}
                        fill={copyCutId == Number(name) ? "blue" : "black" }
                        onClick = {() => {setCopyCutId(copyCutId == Number(name) ? -1 : Number(name)); setDeleteLastDott(true)}}
                        fontSize={20}
                        scaleY={-1}
                /> 
            </>
        )
    }
    function activeDott(e: any) {
        setlastClickedDott(e.currentTarget.id())
        setQwe(e.currentTarget.id())
        setOnDottClick(false)
        setActiveDottCoordsX(e.currentTarget.x())
        setActiveDottCoordsY(e.currentTarget.y())
    }
    function onMouseOverHandler(e: any) {
        document.body.style.cursor = 'pointer';
        e.target.strokeWidth(4);
    }
    function onMouseOutHandler(e: any) {
        document.body.style.cursor = 'default';
        e.target.strokeWidth(2);
    }
    function onDragMoveHandler(e: any) {
        let a = Number(e.currentTarget.name());
        let id = Number(e.currentTarget.id());
        let pointsCopy: any = [];
        for (let i = 0; i < eval("cuts" + areasCount).length; i++) {
            pointsCopy[i] = eval("cuts" + areasCount)[i]
        }
        let dragedPoints: any = [];
        // @ts-ignore
        let xShift = eval("cuts" + areasCount)[a][id].x - getXByCoord(e.currentTarget.x())
        // @ts-ignore
        let yShift = eval("cuts" + areasCount)[a][id].y - getYByCoord(e.currentTarget.y())
        for (let i = 0; i < eval("cuts" + areasCount)[a].length; i++) {
            dragedPoints[i] = {x: Number((eval("cuts" + areasCount)[a][i].x - xShift).toFixed(2)), y: Number((eval("cuts" + areasCount)[a][i].y - yShift).toFixed(2))};
        }
        pointsCopy.splice(a, 1, dragedPoints)
        eval("setCuts" + areasCount)(pointsCopy);
    }
    
    React.useEffect(() => {
            let cutFiguresLinesCopy: any = []
            let cutFiguresCopy: any = [];
            for(let i = 0; i < eval("cuts" + areasCount).length; i++){
                //if(cutAreas[i] == areasCount){
                    cutFiguresCopy.push(builtCutCustomPoints[i])
                    cutFiguresLinesCopy.push(
                        <Line ref={lineRef}
                        points={eval("cutLines" + areasCount)[i]}
                        stroke={'black'}
                        strokeWidth={2}
                        closed={true}
                        opacity={1}
                    />
                    )
                //}
            }
            setCutFiguresLines(cutFiguresLinesCopy) 
            setCutFigures(cutFiguresCopy);
    }, [eval("cutLines" + areasCount), eval("cuts" + areasCount), areasCount, builtCutCustomPoints, doubleCutFigure, appContext.state.isCutFigureMode]);
    return(
        <>
                    <Layer style={{zIndex: 1}}>
                        {cutFiguresLines} 
                    </Layer>
            {   
                appContext.state.isCutFigureMode == true ?
                    <Layer style={{zIndex: 1}}> 
                        {cutFigures}
                    </Layer>
                : null
            }
        </>
    )
}

export default CutFigure;