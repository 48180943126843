import React, {FC, useContext} from "react";

import "./AreasBlock.scss";

import { AppContext } from "../../../context/AppContext";

import Plus from "../../../images/leave-plus.png"
import CopyArea from "../../../images/doubleArea.png"
import Delete from "../../../images/delete.png"
import Check from "../../../images/check.png"
import {gridParams} from "../../../data"

interface Props { 
    areas1: any;
    areas2: any;
    areas3: any;
    areas4: any;
    areas5: any;
    areas6: any;
    areas7: any;
    areas8: any;
    areas9: any;
    areas10: any;
    areas11: any;
    areas12: any;
    areas13: any;
    areas14: any;
    areas15: any;
    areas16: any;
    areas17: any;
    areas18: any;
    areas19: any;
    areas20: any;
    cellRows1: any;
    cellRows2: any;
    cellRows3: any;
    cellRows4: any;
    cellRows5: any;
    cellRows6: any;
    cellRows7: any;
    cellRows8: any;
    cellRows9: any;
    cellRows10: any;
    cellRows11: any;
    cellRows12: any;
    cellRows13: any;
    cellRows14: any;
    cellRows15: any;
    cellRows16: any;
    cellRows17: any;
    cellRows18: any;
    cellRows19: any;
    cellRows20: any;
    areasCount: any;
    setAddArea: any;
    setAddEaves: any;
    setAddApex: any;
    setDeleteFigure: any;
    setAreasCount: any;
    setCopyFigure: any;
}

const AreasBlock: FC<Props> = ({areas1, areas2, areas3, areas4, areas5, areas6, areas7, areas8, areas9, areas10, areas11, areas12, areas13, areas14, areas15, areas16, areas17, areas18, 
    areas19, areas20, 
    cellRows1,
    cellRows2,
    cellRows3,
    cellRows4,
    cellRows5,
    cellRows6,
    cellRows7,
    cellRows8,
    cellRows9,
    cellRows10,
    cellRows11,
    cellRows12,
    cellRows13,
    cellRows14,
    cellRows15,
    cellRows16,
    cellRows17,
    cellRows18,
    cellRows19,
    cellRows20,
    
    
    areasCount, setAddArea, setAddEaves, setAddApex, setDeleteFigure, setAreasCount,
    setCopyFigure}) => {
    const appContext = useContext(AppContext);

    function onAddAreaButtonClickHandler(e: any) {
        if(appContext.state.isEditedMode){
            setAddArea(true);
        }
        else{
            alert("перейдіть у режим побудови")
        }
    }
    function onDeleteButtonClickHandler(e: any) {
        setDeleteFigure(true)
    }
    function onCopyButtonClickHandler(e: any) {
        setCopyFigure(true)
    }
    const areasCountChange = (id: any) => {
        if(appContext.state.isEditedMode){
            setAreasCount(id);
            setAddEaves(false);
            setAddApex(false);
            if(id == 0){
                let maxX = 0
                for(let i = 1; i < 21; i++){
                    if(eval("areas" + i).length > 0){
                        let maxAreaX = 0
                        for(let n = 0; n < eval("areas" + i).length; n++){
                            if(eval("areas" + i)[n].x > maxAreaX){
                                maxAreaX = eval("areas" + i)[n].x
                            }
                        }
                        maxX = Math.round(maxX + maxAreaX)
                    }
                }
                let scale = maxX - 9
                if(maxX - 9 <= 1){
                    scale = 1
                }
                if(maxX - 9 >= 34){
                    scale = 34
                }
                let defaultGridConfig: any = null;
                let createdScale = `scale${scale}`;
                appContext.dispatch({type: 'set-selected-scale', payload: {selectedScale: scale}});
                defaultGridConfig = gridParams.size1[createdScale];
                appContext.dispatch({type: 'set-default-grid-config', payload: {gridConfig: defaultGridConfig}});
            }
            if(id > 0){
                let maxX = 0
                for(let n = 0; n < eval("areas" + id).length; n++){
                    if(eval("areas" + id)[n].x > maxX){
                        maxX = Math.round(eval("areas" + id)[n].x)
                    }
                }
                let scale = maxX - 9
                if(maxX - 9 <=1 ){
                    scale = 1
                }
                if(maxX - 9 >= 34){
                    scale = 34
                }

                let defaultGridConfig: any = null;
                let createdScale = `scale${scale}`;
                appContext.dispatch({type: 'set-selected-scale', payload: {selectedScale: scale}});
                defaultGridConfig = gridParams.size1[createdScale];
                appContext.dispatch({type: 'set-default-grid-config', payload: {gridConfig: defaultGridConfig}});
            }
            
        }
        else{
            alert("перейдіть у режим побудови")
        }
    }
    return(
        <>
            <div className="areas-wrapper">
                <div className="areas-header">
                    <span>Площини </span>
                    <div className="areas-buttons">
                        <button onClick={onAddAreaButtonClickHandler} className="button"><img src={Plus} alt="додати площину" title="додати площину"/></button>
                        <button onClick={onCopyButtonClickHandler} className="button"><img src={CopyArea} alt="копіювати площину" title="копіювати площину"/></button>
                        <button onClick={onDeleteButtonClickHandler} className="button"><img src={Delete} alt="видалити фігуру" title="видалити фігуру"/></button>
                    </div>
                </div>
                <div className="areas-body">
                    {areas1.length > 0 ? <button onClick={() => {areasCountChange(1)}} className={areasCount == 1 ? "areas-active" : ''}>Площина 1 {cellRows1.length > 0 ?<img src={Check} className="button-checkbox"/> : ''}</button> : null}
                    {areas2.length > 0 ? <button onClick={() => {areasCountChange(2)}} className={areasCount == 2 ? "areas-active" : ''}>Площина 2 {cellRows2.length > 0 ?<img src={Check} className="button-checkbox"/> : ''}</button> : null}
                    {areas3.length > 0 ? <button onClick={() => {areasCountChange(3)}} className={areasCount == 3 ? "areas-active" : ''}>Площина 3 {cellRows3.length > 0 ?<img src={Check} className="button-checkbox"/> : ''}</button> : null}
                    {areas4.length > 0 ? <button onClick={() => {areasCountChange(4)}} className={areasCount == 4 ? "areas-active" : ''}>Площина 4 {cellRows4.length > 0 ?<img src={Check} className="button-checkbox"/> : ''}</button> : null}
                    {areas5.length > 0 ? <button onClick={() => {areasCountChange(5)}} className={areasCount == 5 ? "areas-active" : ''}>Площина 5 {cellRows5.length > 0 ?<img src={Check} className="button-checkbox"/> : ''}</button> : null}
                    {areas6.length > 0 ? <button onClick={() => {areasCountChange(6)}} className={areasCount == 6 ? "areas-active" : ''}>Площина 6 {cellRows6.length > 0 ?<img src={Check} className="button-checkbox"/> : ''}</button> : null}
                    {areas7.length > 0 ? <button onClick={() => {areasCountChange(7)}} className={areasCount == 7 ? "areas-active" : ''}>Площина 7 {cellRows7.length > 0 ?<img src={Check} className="button-checkbox"/> : ''}</button> : null}
                    {areas8.length > 0 ? <button onClick={() => {areasCountChange(8)}} className={areasCount == 8 ? "areas-active" : ''}>Площина 8 {cellRows8.length > 0 ?<img src={Check} className="button-checkbox"/> : ''}</button> : null}
                    {areas9.length > 0 ? <button onClick={() => {areasCountChange(9)}} className={areasCount == 9 ? "areas-active" : ''}>Площина 9 {cellRows9.length > 0 ?<img src={Check} className="button-checkbox"/> : ''}</button> : null}
                    {areas10.length > 0 ? <button onClick={() => {areasCountChange(10)}} className={areasCount == 10 ? "areas-active" : ''}>Площина 10 {cellRows10.length > 0 ?<img src={Check} className="button-checkbox"/> : ''}</button> : null}
                    {areas11.length > 0 ? <button onClick={() => {areasCountChange(11)}} className={areasCount == 11 ? "areas-active" : ''}>Площина 11 {cellRows11.length > 0 ?<img src={Check} className="button-checkbox"/> : ''}</button> : null}
                    {areas12.length > 0 ? <button onClick={() => {areasCountChange(12)}} className={areasCount == 12 ? "areas-active" : ''}>Площина 12 {cellRows12.length > 0 ?<img src={Check} className="button-checkbox"/> : ''}</button> : null}
                    {areas13.length > 0 ? <button onClick={() => {areasCountChange(13)}} className={areasCount == 13 ? "areas-active" : ''}>Площина 13 {cellRows13.length > 0 ?<img src={Check} className="button-checkbox"/> : ''}</button> : null}
                    {areas14.length > 0 ? <button onClick={() => {areasCountChange(14)}} className={areasCount == 14 ? "areas-active" : ''}>Площина 14 {cellRows14.length > 0 ?<img src={Check} className="button-checkbox"/> : ''}</button> : null}
                    {areas15.length > 0 ? <button onClick={() => {areasCountChange(15)}} className={areasCount == 15 ? "areas-active" : ''}>Площина 15 {cellRows15.length > 0 ?<img src={Check} className="button-checkbox"/> : ''}</button> : null}
                    {areas16.length > 0 ? <button onClick={() => {areasCountChange(16)}} className={areasCount == 16 ? "areas-active" : ''}>Площина 16 {cellRows16.length > 0 ?<img src={Check} className="button-checkbox"/> : ''}</button> : null}
                    {areas17.length > 0 ? <button onClick={() => {areasCountChange(17)}} className={areasCount == 17 ? "areas-active" : ''}>Площина 17 {cellRows17.length > 0 ?<img src={Check} className="button-checkbox"/> : ''}</button> : null}
                    {areas18.length > 0 ? <button onClick={() => {areasCountChange(18)}} className={areasCount == 18 ? "areas-active" : ''}>Площина 18 {cellRows18.length > 0 ?<img src={Check} className="button-checkbox"/> : ''}</button> : null}
                    {areas19.length > 0 ? <button onClick={() => {areasCountChange(19)}} className={areasCount == 19 ? "areas-active" : ''}>Площина 19 {cellRows19.length > 0 ?<img src={Check} className="button-checkbox"/> : ''}</button> : null}
                    {areas20.length > 0 ? <button onClick={() => {areasCountChange(20)}} className={areasCount == 20 ? "areas-active" : ''}>Площина 20 {cellRows20.length > 0 ?<img src={Check} className="button-checkbox"/> : ''}</button> : null}

                </div>
                <div className="areas-footer">
                    <button onClick={() => {areasCountChange(-1)}} className={areasCount == -1? "areas-active" : ''}>Нова площина</button>
                    <button onClick={() => {areasCountChange(0)}} className={areasCount == 0 ? "areas-active" : ''}>Всі площини</button>
                </div>
            </div>
        </>
    )
}

export default AreasBlock;
