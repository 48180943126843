import React, {FC, useState} from "react";

interface IAddTableRowDialogProps {
    modalCategory: any;
    setModalCategory: any;

    name: any;
    setName: any;

    selectedFile: any;
    setSelectedFile: any;

    onCreateCategoryCallback: any;
}

const AddCategoryDialog: FC<IAddTableRowDialogProps> = ({ modalCategory, setModalCategory, name, setName, selectedFile, setSelectedFile, onCreateCategoryCallback }) => {

    function onCreateCategory() {
        onCreateCategoryCallback();
    }

    {/* Модалка для додавання довжин блоків певної категорії */}

    const onSelectFile = (e: any) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined)
            return
        }

        var binaryData = [];
        binaryData.push(selectedFile);
        // @ts-ignore
        let objectUrl = window.URL.createObjectURL(new Blob(binaryData, {type: "image"}))

        setSelectedFile(e.target.files[0])
    }

    return (
        <>
            {/* Модалка для додавання категорії */}

            {modalCategory &&
              <div className="add-category-modal-wrapper">
                <div className='modal'>
                  <div className='form'>
                    <button className="pop-up-close" onClick={(e) => { setModalCategory(false) }}>X</button>

                    <div className='right'>
                      <div className='header'>Створити категорію</div>

                      <div>
                        <div className='label'>Назва</div>
                        <input type='text' onChange={e => setName(e.target.value)} value={name} />
                      </div>

                      <div>
                        <div className='label'>Фото</div>
                        <input type="file" accept="image/*" onChange={onSelectFile}/>
                      </div>

                      <button className="save" onClick={e => onCreateCategory()}>Створити</button>
                    </div>
                  </div>
                </div>
              </div>}
        </>
    )
}

export default AddCategoryDialog;