// @ts-nocheck

import React, {FC, useContext, useEffect} from 'react';

import { Layer, Line } from "react-konva";
import {scalesConfig} from "../../data";

interface Props {
    cellSize: number;
}
const CanvasFullGrid: FC<Props> = ({cellSize}) => {
    function createCellRows() {

        let step = cellSize;

        let arr = []
        let x = 0;
        let y = -800
        for (let i = 1; i < 100; i++) {
           arr.push(
               <Line key={i}
                  x={x += step}
                  y={y}
                  width={100}
                  points={[0, 0, 0, 5000, 0]}
                  stroke="rgba(204, 204, 204, 0.5)"
               />
           );
        }

        for (let i = 1; i < 100; i++) {
           arr.push(
               <Line key={100 + i}
                   x={0}
                   y={y += step}
                   width={100}
                   points={[0, 0, 50 * cellSize + cellSize * 200, 0, 0]}
                   stroke="rgba(204, 204, 204, 0.5)"
               />
           );
        }
       return arr;
    }

    return (
        <>
            <Layer>
                {createCellRows()}
            </Layer>
       </>
    );
}

export default CanvasFullGrid;