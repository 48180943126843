import React, {FC, useContext, useEffect, useState} from "react";
import "./CoordsBlock.scss";

import { AppContext } from "../../../context/AppContext";

import { useGridConfig } from "../../../hooks/useGridConfig";
import {scalesConfig} from "../../../data"
import { gridParams } from "../../../data";

import {FIGURES} from "../../../enums/figure.enum";
import Dott from "../../../images/dott.png"
import AddDott from "../../../images/addDott.png"
import FigureFinish from "../../../images/figureFinish.png"
import DottChange from "../../../images/dottChange.png"
import { cloneDeep } from "lodash";

interface Props { 
    setXCords1: any;
    setYCords1: any;
    setIsDottAdditional: any;
    areasCount: any;
    setChangeDott: any;
    setFinishFigure: any;
    setXCutShift: any; 
    setYCutShift: any;
    setOnCutFigureStartClick: any;
    onCutFigureStartClick: any;
    setCutFigureStart: any;
    activeDottCoordsX: any;
    setActiveDottCoordsX: any;
    activeDottCoordsY: any;
    setActiveDottCoordsY: any;
    closedCuts1: any;setClosedCuts1: any
    closedCuts2: any;setClosedCuts2: any
    closedCuts3: any;setClosedCuts3: any
    closedCuts4: any;setClosedCuts4: any
    closedCuts5: any;setClosedCuts5: any
    closedCuts6: any;setClosedCuts6: any
    closedCuts7: any;setClosedCuts7: any
    closedCuts8: any;setClosedCuts8: any
    closedCuts9: any;setClosedCuts9: any
    closedCuts10: any;setClosedCuts10: any
    closedCuts11: any;setClosedCuts11: any
    closedCuts12: any;setClosedCuts12: any
    closedCuts13: any;setClosedCuts13: any
    closedCuts14: any;setClosedCuts14: any
    closedCuts15: any;setClosedCuts15: any
    closedCuts16: any;setClosedCuts16: any
    closedCuts17: any;setClosedCuts17: any
    closedCuts18: any;setClosedCuts18: any
    closedCuts19: any;setClosedCuts19: any
    closedCuts20: any;setClosedCuts20: any
}

const CoordsBlock: FC<Props> = ({setXCords1, setYCords1, setIsDottAdditional, setChangeDott, setFinishFigure, setXCutShift, setYCutShift, setOnCutFigureStartClick, 
    onCutFigureStartClick, setCutFigureStart, areasCount,
    activeDottCoordsX, setActiveDottCoordsX, activeDottCoordsY, setActiveDottCoordsY,
    closedCuts1,setClosedCuts1,
    closedCuts2,setClosedCuts2,
    closedCuts3,setClosedCuts3,
    closedCuts4,setClosedCuts4,
    closedCuts5,setClosedCuts5,
    closedCuts6,setClosedCuts6,
    closedCuts7,setClosedCuts7,
    closedCuts8,setClosedCuts8,
    closedCuts9,setClosedCuts9,
    closedCuts10,setClosedCuts10,
    closedCuts11,setClosedCuts11,
    closedCuts12,setClosedCuts12,
    closedCuts13,setClosedCuts13,
    closedCuts14,setClosedCuts14,
    closedCuts15,setClosedCuts15,
    closedCuts16,setClosedCuts16,
    closedCuts17,setClosedCuts17,
    closedCuts18,setClosedCuts18,
    closedCuts19,setClosedCuts19,
    closedCuts20,setClosedCuts20,
}) => {
    const gridConfig = useGridConfig(window, 1);
    const getXByCoord = (x: any) => {
        return (x - gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
    }
    const getYByCoord = (y: any) => {
        // @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 1){
            return (y + 700) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 2){
            return (y + 712) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 3){
            return (y + 722) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 4){
            return (y + 730) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 5){
            return (y + 735) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 6){
            return (y + 740) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 7){
            return (y + 742) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 8){
            return (y + 747) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 9){
            return (y + 751) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 10){
            return (y + 754) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 11){
            return (y + 757) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 12){
            return (y + 759) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 13){
            return (y + 762) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 14){
            return (y + 763) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 15){
            return (y + 764) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 16){
            return (y + 767) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 17){
            return (y + 766) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 18){
            return (y + 768) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 19){
            return (y + 770) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 20){
            return (y + 772) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 21){
            return (y + 773) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 22){
            return (y + 774) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 23){
            return (y + 774.5) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 24){
            return (y + 775) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 25){
            return (y + 776) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 26){
            return (y + 777) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 27){
            //return (y + 775) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
            return (y + 778) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 28){
            //return (y + 920) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
            return (y + 778.5) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 29){
            return (y + 799) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 30){
            return (y + 779.5) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 31){
            //return (y + 775) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
            return (y + 780) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 32){
            //return (y + 920) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
            return (y + 780.5) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 33){
            return (y + 781) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }// @ts-ignore
        if(scalesConfig[`${appContext.state.selectedScale}`] == 34){
            return (y + 781.5) / gridParams.size1[`scale${appContext.state.selectedScale}`].cellSize
        }
    }

    const appContext = useContext(AppContext);

    const [figureXCord, setFigureXCord] = useState(0);
    const [figureYCord, setFigureYCord] = useState(0);

    function onChangeFigureXCordHandler(e: any) {
        setFigureXCord(e.target.value);
    }
    function onChangeFigureYCordHandler(e: any) {
        setFigureYCord(e.target.value);
    }
    React.useEffect(() => {
        if(activeDottCoordsX !== null && activeDottCoordsY !== null){
            setFigureXCord(getXByCoord(activeDottCoordsX));
            // @ts-ignore
            setFigureYCord(getYByCoord(activeDottCoordsY));
        }
        else{
            setFigureXCord(0);
            setFigureYCord(0);
        }
    }, [activeDottCoordsX, activeDottCoordsY]);

    function calcFigureCordsBtnHandler(e: any) {
        setXCords1(figureXCord);
        setYCords1(figureYCord);
        /*setFigureXCord(0);
        setFigureYCord(0);*/
    }
    function calcFigureCordsAddBtnHandler(e: any) {
        setXCords1(figureXCord);
        setYCords1(figureYCord);
        setFigureXCord(0);
        setFigureYCord(0);
        setIsDottAdditional(true)
    }
    function FinishFigureBtnHandler(e: any) {
        if(appContext.state.isEditedMode){
            setFinishFigure(true)
        }
        if(appContext.state.isCutFigureMode){
            let closedCutsCopy = cloneDeep(eval("closedCuts" + areasCount))
            closedCutsCopy.splice(closedCutsCopy.length - 1, 0, true)
            eval("setClosedCuts" + areasCount)(closedCutsCopy)
        }
    }
    function ChangeDottBtnHandler(e: any) {
        setChangeDott(true)
    }
    React.useEffect(() => {
        if(onCutFigureStartClick == true){
            setCutFigureStart(true)
            setXCutShift(figureXCord)
            setYCutShift(figureYCord)
            setFigureXCord(0)
            setFigureYCord(0)
        }
        setOnCutFigureStartClick(false)
    }, [onCutFigureStartClick]);

    function Handler1(e: any){
        const input2 = (document.getElementById("figure-y-cords-input") as HTMLInputElement )
        const btn1 = document.getElementById("figure-add-point-button")
         
        if(e.key == "ArrowRight"){
            /*input2.focus()
            input2.select()*/
            input2.click()
        }
        if(e.key == "ArrowLeft"){
            btn1?.focus()
        }
    }
    function Handler2(e: any){
     
        let input1 = document.getElementById("figure-x-cords-input")
        let btn1 = document.getElementById("figure-add-point-button")
         
        if(e.key == "ArrowRight"){
            btn1?.focus()
        }
        if(e.key == "ArrowLeft"){
            input1?.focus()
        }
    }
    function Handler3(e: any){
     
        let input1 = document.getElementById("figure-x-cords-input")
        let input2 = document.getElementById("figure-y-cords-input")
        
        if(e.key == "ArrowRight"){
            input1?.focus()
        }
        if(e.key == "ArrowLeft"){
            input2?.focus()
        }
    }

    
    const handleSelect = (e: any) => {
        e.target.select();
      };
    return(
        <>
            <div className="coords-wrapper">
                <div className="coord-block">
                    <div>
                        <span className="darkgray-text bold-text">X:</span>
                        <input className="figures-input"
                            id="figure-x-cords-input"
                            value={figureXCord || ""}
                            onChange={onChangeFigureXCordHandler.bind(this)}
                            onKeyUp={(e) => Handler1(e)}
                            onFocus={handleSelect.bind(this)}
                            onClick={handleSelect.bind(this)}
                            disabled={appContext.state.editedMode > 1/*
                            || appContext.state.selectedFigure === FIGURES.None
                            || appContext.state.selectedFigure === FIGURES.Square
                            || appContext.state.selectedFigure === FIGURES.Rectangle
                            || appContext.state.selectedFigure === FIGURES.Triangular
                            || appContext.state.selectedFigure === FIGURES.Trapezoid*/
                            }
                        />
                    </div>
                    <div>
                        <span className="darkgray-text bold-text">Y:</span>
                        <input className="figures-input"
                            id="figure-y-cords-input"
                            value={figureYCord || ""}
                            onChange={onChangeFigureYCordHandler.bind(this)}
                            onFocus={handleSelect.bind(this)}
                            onClick={handleSelect.bind(this)}
                            onKeyUp={(e) => Handler2(e)}
                            disabled={appContext.state.editedMode > 1/*
                                || appContext.state.selectedFigure === FIGURES.None
                                || appContext.state.selectedFigure === FIGURES.Square
                                || appContext.state.selectedFigure === FIGURES.Rectangle
                                || appContext.state.selectedFigure === FIGURES.Triangular
                                || appContext.state.selectedFigure === FIGURES.Trapezoid*/
                            }
                        />
                    </div>
                </div>
                <div className="buttons-block">
                    <div className="coords-buttons">
                        <button onClick={calcFigureCordsBtnHandler} onKeyUp={(e) => Handler3(e)} id="figure-add-point-button" className="button"><img src={Dott} alt= "додати точку" title="додати точку"/></button>
                        <button onClick={calcFigureCordsAddBtnHandler} className="button"><img src={AddDott} alt= "додати доп. точку" title="додати доп. точку"/></button>
                    </div>
                    <div className="coords-buttons">
                        <button onClick={ChangeDottBtnHandler} className="button"><img src={DottChange} alt= "змінити координати" title="змінити координати"/></button>
                        <button onClick={FinishFigureBtnHandler} className="button"><img src={FigureFinish} alt= "завершити фігуру" title="завершити фігуру"/></button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CoordsBlock;
