import React, { FC, useContext, useEffect, useState } from "react";

import { AppContext } from "../../context/AppContext";

import CreativePointedPolygon from "./CreativePointedPolygon";
import CoveredPolygon from "./CoveredPolygon";
import CutFigure from "./CutFigure";
import {Layer, Line, Rect, Shape, Text} from "react-konva";

import {scalesConfig} from "../../data"

interface Props {
    clickedCoords: any[] | null;
    setNewSidesCallback: (points: any) => void;
    calcPolygonPointsCallback: (points: any[]) => void;
    selectedCategory: any;
    activeDottCoordsX: any;
    setActiveDottCoordsX: any;
    activeDottCoordsY: any;
    setActiveDottCoordsY: any;
    selectedPolygonId: any;
    selectedPolygonShapeName: any;
    setCalcResult: any;
    start: any; setStart: any;
    figureBottomLine1: any;
    leaveChange: any;
    isDottAdditional: any;
    setIsDottAdditional: any;
    prevStep: any;
    setPrevStep: any;
    nextStep: any;
    setNextStep: any;
    deleteDot: any;
    setDeleteDot: any;
    doubleCutFigure: any;
    setDoubleCutFigure: any;
    xCutShift: any;
    yCutShift: any;
    setXCutShift: any;
    setYCutShift: any;
    cutFigureCount: any;
    setCutFigureCount: any;
    deleteCutFigure: any;
    setDeleteCutFigure: any;
    addArea: any;
    setAddArea: any;
    addEaves: any;
    setAddEaves: any;
    areasCount: any;
    setAreasCount: any;
    deleteFigure: any;
    setDeleteFigure: any;
    addApex: any;
    setAddApex: any;
    finishFigure: any;
    setFinishFigure: any;
    changeDott: any;
    setChangeDott: any;
    copyFigure: any;
    setCopyFigure: any;
    flipFigureX: any;
    setFlipFigureX: any;
    flipFigureY: any;
    setFlipFigureY: any;
    leavePlusTop: any;
    setLeavePlusTop: any;
    leaveMinusTop: any;
    setLeaveMinusTop: any;
    leavePlusBot: any;
    setLeavePlusBot: any;
    leaveMinusBot: any;
    setLeaveMinusBot: any;
    leaveDelete: any;
    setLeaveDelete: any;
    leaveConnect: any;
    setLeaveConnect: any;
    leaveDivide: any;
    setLeaveDivide: any;
    leaveAdd: any;
    setLeaveAdd: any;
    plusMaxHeight: any;
    setPlusMaxHeight: any;
    minusMaxHeight: any;
    setMinusMaxHeight: any;
    changeCoverSide: any;
    setChangeCoverSide: any;
    leaveChangeValue: any;
    leaveArrowValue: any;
    setLeaveArrowValue: any;

    leavesLeft: any;
    setLeavesLeft: any;
    leavesTop: any;
    setLeavesTop: any;
    leavesRight: any;
    setLeavesRight: any;
    leavesBottom: any;
    setLeavesBottom: any;

    onAInputFocus: any; setOnAInputFocus: any;
    onA1InputFocus: any; setOnA1InputFocus: any;
    onBInputFocus: any; setOnBInputFocus: any;
    onCInputFocus: any; setOnCInputFocus: any;
    onDInputFocus: any; setOnDInputFocus: any;
    onEInputFocus: any; setOnEInputFocus: any;
    onHeightInputFocus: any; setOnHeightInputFocus: any;
    onLInputFocus: any; setOnLInputFocus: any;


    isGridActive: any;
    cutFigureStart: any;
    setCutFigureStart: any;
    isAllGrids: any;

    turnFigure: any; 
    setTurnFigure: any;
    degreesTurnFigure: any; 
    setDegreesTurnFigure: any;


    areas1: any;
    setAreas1: any;
    areas2: any; 
    setAreas2: any;
    areas3: any;
    setAreas3: any;
    areas4: any;
    setAreas4: any;
    areas5: any; 
    setAreas5: any;
    areas6: any;
    setAreas6: any;
    areas7: any;
    setAreas7: any;
    areas8: any; 
    setAreas8: any;
    areas9: any;
    setAreas9: any;
    areas10: any;
    setAreas10: any;
    areas11: any;
    setAreas11: any;
    areas12: any;
    setAreas12: any;
    areas13: any;
    setAreas13: any;
    areas14: any;
    setAreas14: any;
    areas15: any;
    setAreas15: any;
    areas16: any;
    setAreas16: any;
    areas17: any;
    setAreas17: any;
    areas18: any;
    setAreas18: any;
    areas19: any;
    setAreas19: any;
    areas20: any;
    setAreas20: any;



    cuts1: any;
    setCuts1: any;
    cuts2: any; 
    setCuts2: any;
    cuts3: any;
    setCuts3: any;
    cuts4: any;
    setCuts4: any;
    cuts5: any; 
    setCuts5: any;
    cuts6: any;
    setCuts6: any;
    cuts7: any;
    setCuts7: any;
    cuts8: any; 
    setCuts8: any;
    cuts9: any;
    setCuts9: any;
    cuts10: any;
    setCuts10: any;
    cuts11: any;
    setCuts11: any;
    cuts12: any;
    setCuts12: any;
    cuts13: any;
    setCuts13: any;
    cuts14: any;
    setCuts14: any;
    cuts15: any;
    setCuts15: any;
    cuts16: any;
    setCuts16: any;
    cuts17: any;
    setCuts17: any;
    cuts18: any;
    setCuts18: any;
    cuts19: any;
    setCuts19: any;
    cuts20: any;
    setCuts20: any;

    closedCuts1: any;setClosedCuts1: any
    closedCuts2: any;setClosedCuts2: any
    closedCuts3: any;setClosedCuts3: any
    closedCuts4: any;setClosedCuts4: any
    closedCuts5: any;setClosedCuts5: any
    closedCuts6: any;setClosedCuts6: any
    closedCuts7: any;setClosedCuts7: any
    closedCuts8: any;setClosedCuts8: any
    closedCuts9: any;setClosedCuts9: any
    closedCuts10: any;setClosedCuts10: any
    closedCuts11: any;setClosedCuts11: any
    closedCuts12: any;setClosedCuts12: any
    closedCuts13: any;setClosedCuts13: any
    closedCuts14: any;setClosedCuts14: any
    closedCuts15: any;setClosedCuts15: any
    closedCuts16: any;setClosedCuts16: any
    closedCuts17: any;setClosedCuts17: any
    closedCuts18: any;setClosedCuts18: any
    closedCuts19: any;setClosedCuts19: any
    closedCuts20: any;setClosedCuts20: any

    lines1: any;
    setLines1: any;
    lines2: any; 
    setLines2: any;
    lines3: any;
    setLines3: any;
    lines4: any;
    setLines4: any;
    lines5: any; 
    setLines5: any;
    lines6: any;
    setLines6: any;
    lines7: any;
    setLines7: any;
    lines8: any; 
    setLines8: any;
    lines9: any;
    setLines9: any;
    lines10: any;
    setLines10: any;
    lines11: any;
    setLines11: any;
    lines12: any;
    setLines12: any;
    lines13: any;
    setLines13: any;
    lines14: any;
    setLines14: any;
    lines15: any;
    setLines15: any;
    lines16: any;
    setLines16: any;
    lines17: any;
    setLines17: any;
    lines18: any;
    setLines18: any;
    lines19: any;
    setLines19: any;
    lines20: any;
    setLines20: any;

    cutLines1: any;
    setCutLines1: any;
    cutLines2: any; 
    setCutLines2: any;
    cutLines3: any;
    setCutLines3: any;
    cutLines4: any;
    setCutLines4: any;
    cutLines5: any; 
    setCutLines5: any;
    cutLines6: any;
    setCutLines6: any;
    cutLines7: any;
    setCutLines7: any;
    cutLines8: any; 
    setCutLines8: any;
    cutLines9: any;
    setCutLines9: any;
    cutLines10: any;
    setCutLines10: any;
    cutLines11: any;
    setCutLines11: any;
    cutLines12: any;
    setCutLines12: any;
    cutLines13: any;
    setCutLines13: any;
    cutLines14: any;
    setCutLines14: any;
    cutLines15: any;
    setCutLines15: any;
    cutLines16: any;
    setCutLines16: any;
    cutLines17: any;
    setCutLines17: any;
    cutLines18: any;
    setCutLines18: any;
    cutLines19: any;
    setCutLines19: any;
    cutLines20: any;
    setCutLines20: any;

    eaves1: any;
    setEaves1: any;
    eaves2: any;
    setEaves2: any;
    eaves3: any;
    setEaves3: any;
    eaves4: any;
    setEaves4: any;
    eaves5: any;
    setEaves5: any;
    eaves6: any;
    setEaves6: any;
    eaves7: any;
    setEaves7: any;
    eaves8: any;
    setEaves8: any;
    eaves9: any;
    setEaves9: any;
    eaves10: any;
    setEaves10: any;
    eaves11: any;
    setEaves11: any;
    eaves12: any;
    setEaves12: any;
    eaves13: any;
    setEaves13: any;
    eaves14: any;
    setEaves14: any;
    eaves15: any;
    setEaves15: any;
    eaves16: any;
    setEaves16: any;
    eaves17: any;
    setEaves17: any;
    eaves18: any;
    setEaves18: any;
    eaves19: any;
    setEaves19: any;
    eaves20: any;
    setEaves20: any;



    apex1: any;
    setApex1: any;
    apex2: any;
    setApex2: any;
    apex3: any;
    setApex3: any;
    apex4: any;
    setApex4: any;
    apex5: any;
    setApex5: any;
    apex6: any;
    setApex6: any;
    apex7: any;
    setApex7: any;
    apex8: any;
    setApex8: any;
    apex9: any;
    setApex9: any;
    apex10: any;
    setApex10: any;
    apex11: any;
    setApex11: any;
    apex12: any;
    setApex12: any;
    apex13: any;
    setApex13: any;
    apex14: any;
    setApex14: any;
    apex15: any;
    setApex15: any;
    apex16: any;
    setApex16: any;
    apex17: any;
    setApex17: any;
    apex18: any;
    setApex18: any;
    apex19: any;
    setApex19: any;
    apex20: any;
    setApex20: any;



    isLinesClosed1: any;
    setIsLinesClosed1: any;
    isLinesClosed2: any;
    setIsLinesClosed2: any;
    isLinesClosed3: any;
    setIsLinesClosed3: any;
    isLinesClosed4: any;
    setIsLinesClosed4: any;
    isLinesClosed5: any;
    setIsLinesClosed5: any;
    isLinesClosed6: any;
    setIsLinesClosed6: any;
    isLinesClosed7: any;
    setIsLinesClosed7: any;
    isLinesClosed8: any;
    setIsLinesClosed8: any;
    isLinesClosed9: any;
    setIsLinesClosed9: any;
    isLinesClosed10: any;
    setIsLinesClosed10: any;
    isLinesClosed11: any;
    setIsLinesClosed11: any;
    isLinesClosed12: any;
    setIsLinesClosed12: any;
    isLinesClosed13: any;
    setIsLinesClosed13: any;
    isLinesClosed14: any;
    setIsLinesClosed14: any;
    isLinesClosed15: any;
    setIsLinesClosed15: any;
    isLinesClosed16: any;
    setIsLinesClosed16: any;
    isLinesClosed17: any;
    setIsLinesClosed17: any;
    isLinesClosed18: any;
    setIsLinesClosed18: any;
    isLinesClosed19: any;
    setIsLinesClosed19: any;
    isLinesClosed20: any;
    setIsLinesClosed20: any;

    results1: any;
    setResults1: any;
    results2: any;
    setResults2: any;
    results3: any;
    setResults3: any;
    results4: any;
    setResults4: any;
    results5: any;
    setResults5: any;
    results6: any;
    setResults6: any;
    results7: any;
    setResults7: any;
    results8: any;
    setResults8: any;
    results9: any;
    setResults9: any;
    results10: any;
    setResults10: any;
    results11: any;
    setResults11: any;
    results12: any;
    setResults12: any;
    results13: any;
    setResults13: any;
    results14: any;
    setResults14: any;
    results15: any;
    setResults15: any;
    results16: any;
    setResults16: any;
    results17: any;
    setResults17: any;
    results18: any;
    setResults18: any;
    results19: any;
    setResults19: any;
    results20: any;
    setResults20: any;

    areas1Copy: any   
    setAreas1Copy: any
    areas2Copy: any   
    setAreas2Copy: any
    areas3Copy: any   
    setAreas3Copy: any
    areas4Copy: any  
    setAreas4Copy: any
    areas5Copy: any   
    setAreas5Copy: any
    areas6Copy: any   
    setAreas6Copy: any
    areas7Copy: any   
    setAreas7Copy: any
    areas8Copy: any   
    setAreas8Copy: any
    areas9Copy: any   
    setAreas9Copy: any
    areas10Copy: any    
    setAreas10Copy: any
    areas11Copy: any    
    setAreas11Copy: any
    areas12Copy: any    
    setAreas12Copy: any
    areas13Copy: any    
    setAreas13Copy: any
    areas14Copy: any    
    setAreas14Copy: any
    areas15Copy: any    
    setAreas15Copy: any
    areas16Copy: any    
    setAreas16Copy: any
    areas17Copy: any    
    setAreas17Copy: any
    areas18Copy: any    
    setAreas18Copy: any
    areas19Copy: any    
    setAreas19Copy: any
    areas20Copy: any    
    setAreas20Copy: any


    eaves1Copy: any
    setEaves1Copy: any
    eaves2Copy: any
    setEaves2Copy: any
    eaves3Copy: any
    setEaves3Copy: any
    eaves4Copy: any
    setEaves4Copy: any
    eaves5Copy: any
    setEaves5Copy: any
    eaves6Copy: any
    setEaves6Copy: any
    eaves7Copy: any
    setEaves7Copy: any
    eaves8Copy: any
    setEaves8Copy: any
    eaves9Copy: any
    setEaves9Copy: any
    eaves10Copy: any
    setEaves10Copy: any
    eaves11Copy: any
    setEaves11Copy: any
    eaves12Copy: any
    setEaves12Copy: any
    eaves13Copy: any
    setEaves13Copy: any
    eaves14Copy: any
    setEaves14Copy: any
    eaves15Copy: any
    setEaves15Copy: any
    eaves16Copy: any
    setEaves16Copy: any
    eaves17Copy: any
    setEaves17Copy: any
    eaves18Copy: any
    setEaves18Copy: any
    eaves19Copy: any
    setEaves19Copy: any
    eaves20Copy: any
    setEaves20Copy: any



    apex1Copy: any
    setApex1Copy: any
    apex2Copy: any
    setApex2Copy: any
    apex3Copy: any
    setApex3Copy: any
    apex4Copy: any
    setApex4Copy: any
    apex5Copy: any
    setApex5Copy: any
    apex6Copy: any
    setApex6Copy: any
    apex7Copy: any
    setApex7Copy: any
    apex8Copy: any
    setApex8Copy: any
    apex9Copy: any
    setApex9Copy: any
    apex10Copy: any
    setApex10Copy: any
    apex11Copy: any
    setApex11Copy: any
    apex12Copy: any
    setApex12Copy: any
    apex13Copy: any
    setApex13Copy: any
    apex14Copy: any
    setApex14Copy: any
    apex15Copy: any
    setApex15Copy: any
    apex16Copy: any
    setApex16Copy: any
    apex17Copy: any
    setApex17Copy: any
    apex18Copy: any
    setApex18Copy: any
    apex19Copy: any
    setApex19Copy: any
    apex20Copy: any
    setApex20Copy: any

    cellRows1: any;
    setCellRows1: any;
    cellRows2: any;
    setCellRows2: any;
    cellRows3: any;
    setCellRows3: any;
    cellRows4: any;
    setCellRows4: any;
    cellRows5: any;
    setCellRows5: any;
    cellRows6: any;
    setCellRows6: any;
    cellRows7: any;
    setCellRows7: any;
    cellRows8: any;
    setCellRows8: any;
    cellRows9: any;
    setCellRows9: any;
    cellRows10: any;
    setCellRows10: any;
    cellRows11: any;
    setCellRows11: any;
    cellRows12: any;
    setCellRows12: any;
    cellRows13: any;
    setCellRows13: any;
    cellRows14: any;
    setCellRows14: any;
    cellRows15: any;
    setCellRows15: any;
    cellRows16: any;
    setCellRows16: any;
    cellRows17: any;
    setCellRows17: any;
    cellRows18: any;
    setCellRows18: any;
    cellRows19: any;
    setCellRows19: any;
    cellRows20: any;
    setCellRows20: any;



    isReadyFigureBuild: any; setIsReadyFigureBuild: any;
    points: any; setPoints: any;
    pointsForTestFigure: any; setPointsForTestFigure: any;
    lettersForTestFigure: any; setLettersForTestFigure: any;
    isLineClosed: any; setIsLineClosed: any;
    lines: any; setLines: any;
    linesForTestFigure: any; setLinesForTestFigure: any;
    addLinesForTestFigure: any; setAddLinesForTestFigure: any;
    addLinesForTestFigure1: any; setAddLinesForTestFigure1: any;
    addLinesForTestFigure2: any; setAddLinesForTestFigure2: any;
    addLinesForTestFigure3: any; setAddLinesForTestFigure3: any;
    cutFigures: any; setCutFigures: any;
    cutFiguresLines: any; setCutFiguresLines: any;

    builtCustomPoints1: any; setBuiltCustomPoints1: any;
    builtCustomPoints2: any; setBuiltCustomPoints2: any;
    builtCustomPoints3: any; setBuiltCustomPoints3: any;
    builtCustomPoints4: any; setBuiltCustomPoints4: any;
    builtCustomPoints5: any; setBuiltCustomPoints5: any;
    builtCustomPoints6: any; setBuiltCustomPoints6: any;
    builtCustomPoints7: any; setBuiltCustomPoints7: any;
    builtCustomPoints8: any; setBuiltCustomPoints8: any;
    builtCustomPoints9: any; setBuiltCustomPoints9: any;
    builtCustomPoints10: any; setBuiltCustomPoints10: any;
    builtCustomPoints11: any; setBuiltCustomPoints11: any;
    builtCustomPoints12: any; setBuiltCustomPoints12: any;
    builtCustomPoints13: any; setBuiltCustomPoints13: any;
    builtCustomPoints14: any; setBuiltCustomPoints14: any;
    builtCustomPoints15: any; setBuiltCustomPoints15: any;
    builtCustomPoints16: any; setBuiltCustomPoints16: any;
    builtCustomPoints17: any; setBuiltCustomPoints17: any;
    builtCustomPoints18: any; setBuiltCustomPoints18: any;
    builtCustomPoints19: any; setBuiltCustomPoints19: any;
    builtCustomPoints20: any; setBuiltCustomPoints20: any;

    builtSideSizes1: any; setBuiltSideSizes1: any;
    builtSideSizes2: any; setBuiltSideSizes2: any;
    builtSideSizes3: any; setBuiltSideSizes3: any;
    builtSideSizes4: any; setBuiltSideSizes4: any;
    builtSideSizes5: any; setBuiltSideSizes5: any;
    builtSideSizes6: any; setBuiltSideSizes6: any;
    builtSideSizes7: any; setBuiltSideSizes7: any;
    builtSideSizes8: any; setBuiltSideSizes8: any;
    builtSideSizes9: any; setBuiltSideSizes9: any;
    builtSideSizes10: any; setBuiltSideSizes10: any;
    builtSideSizes11: any; setBuiltSideSizes11: any;
    builtSideSizes12: any; setBuiltSideSizes12: any;
    builtSideSizes13: any; setBuiltSideSizes13: any;
    builtSideSizes14: any; setBuiltSideSizes14: any;
    builtSideSizes15: any; setBuiltSideSizes15: any;
    builtSideSizes16: any; setBuiltSideSizes16: any;
    builtSideSizes17: any; setBuiltSideSizes17: any;
    builtSideSizes18: any; setBuiltSideSizes18: any;
    builtSideSizes19: any; setBuiltSideSizes19: any;
    builtSideSizes20: any; setBuiltSideSizes20: any;

    builtCustomPoints1Copy: any; setBuiltCustomPoints1Copy: any;
    builtCustomPoints2Copy: any; setBuiltCustomPoints2Copy: any;
    builtCustomPoints3Copy: any; setBuiltCustomPoints3Copy: any;
    builtCustomPoints4Copy: any; setBuiltCustomPoints4Copy: any;
    builtCustomPoints5Copy: any; setBuiltCustomPoints5Copy: any;
    builtCustomPoints6Copy: any; setBuiltCustomPoints6Copy: any;
    builtCustomPoints7Copy: any; setBuiltCustomPoints7Copy: any;
    builtCustomPoints8Copy: any; setBuiltCustomPoints8Copy: any;
    builtCustomPoints9Copy: any; setBuiltCustomPoints9Copy: any;
    builtCustomPoints10Copy: any; setBuiltCustomPoints10Copy: any;
    builtCustomPoints11Copy: any; setBuiltCustomPoints11Copy: any;
    builtCustomPoints12Copy: any; setBuiltCustomPoints12Copy: any;
    builtCustomPoints13Copy: any; setBuiltCustomPoints13Copy: any;
    builtCustomPoints14Copy: any; setBuiltCustomPoints14Copy: any;
    builtCustomPoints15Copy: any; setBuiltCustomPoints15Copy: any;
    builtCustomPoints16Copy: any; setBuiltCustomPoints16Copy: any;
    builtCustomPoints17Copy: any; setBuiltCustomPoints17Copy: any;
    builtCustomPoints18Copy: any; setBuiltCustomPoints18Copy: any;
    builtCustomPoints19Copy: any; setBuiltCustomPoints19Copy: any;
    builtCustomPoints20Copy: any; setBuiltCustomPoints20Copy: any;

    Apex1Id: any; setApex1Id: any;
    Apex2Id: any; setApex2Id: any;
    Apex3Id: any; setApex3Id: any;
    Apex4Id: any; setApex4Id: any;
    Apex5Id: any; setApex5Id: any;
    Apex6Id: any; setApex6Id: any;
    Apex7Id: any; setApex7Id: any;
    Apex8Id: any; setApex8Id: any;
    Apex9Id: any; setApex9Id: any;
    Apex10Id: any; setApex10Id: any;
    Apex11Id: any; setApex11Id: any;
    Apex12Id: any; setApex12Id: any;
    Apex13Id: any; setApex13Id: any;
    Apex14Id: any; setApex14Id: any;
    Apex15Id: any; setApex15Id: any;
    Apex16Id: any; setApex16Id: any;
    Apex17Id: any; setApex17Id: any;
    Apex18Id: any; setApex18Id: any;
    Apex19Id: any; setApex19Id: any;
    Apex20Id: any; setApex20Id: any;


    Eaves1Id: any; setEaves1Id: any;
    Eaves2Id: any; setEaves2Id: any;
    Eaves3Id: any; setEaves3Id: any;
    Eaves4Id: any; setEaves4Id: any;
    Eaves5Id: any; setEaves5Id: any;
    Eaves6Id: any; setEaves6Id: any;
    Eaves7Id: any; setEaves7Id: any;
    Eaves8Id: any; setEaves8Id: any;
    Eaves9Id: any; setEaves9Id: any;
    Eaves10Id: any; setEaves10Id: any;
    Eaves11Id: any; setEaves11Id: any;
    Eaves12Id: any; setEaves12Id: any;
    Eaves13Id: any; setEaves13Id: any;
    Eaves14Id: any; setEaves14Id: any;
    Eaves15Id: any; setEaves15Id: any;
    Eaves16Id: any; setEaves16Id: any;
    Eaves17Id: any; setEaves17Id: any;
    Eaves18Id: any; setEaves18Id: any;
    Eaves19Id: any; setEaves19Id: any;
    Eaves20Id: any; setEaves20Id: any;

    lines1Copy: any; setLines1Copy: any;
    lines2Copy: any; setLines2Copy: any;
    lines3Copy: any; setLines3Copy: any;
    lines4Copy: any; setLines4Copy: any;
    lines5Copy: any; setLines5Copy: any;
    lines6Copy: any; setLines6Copy: any;
    lines7Copy: any; setLines7Copy: any;
    lines8Copy: any; setLines8Copy: any;
    lines9Copy: any; setLines9Copy: any;
    lines10Copy:any; setLines10Copy:any;
    lines11Copy:any; setLines11Copy:any;
    lines12Copy:any; setLines12Copy:any;
    lines13Copy:any; setLines13Copy:any;
    lines14Copy:any; setLines14Copy:any;
    lines15Copy:any; setLines15Copy:any;
    lines16Copy:any; setLines16Copy:any;
    lines17Copy:any; setLines17Copy:any;
    lines18Copy:any; setLines18Copy:any;
    lines19Copy:any; setLines19Copy:any;
    lines20Copy:any; setLines20Copy:any;

    polygonPoints: any; setPolygonPoints: any;
    builtCustomPoints: any; setBuiltCustomPoints: any;
    builtCutCustomPoints: any; setBuiltCutCustomPoints: any;
    cutsForCover: any; setCutsForCover: any;

}

const PolygonContainer: FC<Props> = ({clickedCoords, calcPolygonPointsCallback, setNewSidesCallback, selectedCategory,
  selectedPolygonShapeName, setCalcResult, figureBottomLine1, leaveChange, isDottAdditional,setIsDottAdditional, prevStep, 
  setPrevStep, nextStep, setNextStep, deleteDot, setDeleteDot, doubleCutFigure,setDoubleCutFigure, xCutShift, yCutShift, setXCutShift, setYCutShift, deleteCutFigure, setDeleteCutFigure,
  cutFigureCount, setCutFigureCount,addArea, setAddArea, areasCount, setAreasCount,  setDeleteFigure, deleteFigure, addEaves, setAddEaves,
  addApex, setAddApex, finishFigure, setFinishFigure, changeDott, setChangeDott,
  copyFigure, setCopyFigure, flipFigureX, cutFigureStart, setCutFigureStart,setFlipFigureX, flipFigureY, setFlipFigureY, 
  leavePlusTop, setLeavePlusTop, leaveMinusTop, setLeaveMinusTop, leavePlusBot, setLeavePlusBot, leaveMinusBot, setLeaveMinusBot,
  leaveDelete, setLeaveDelete, leaveConnect, setLeaveConnect, leaveDivide, setLeaveDivide, leaveAdd, setLeaveAdd, isGridActive, isAllGrids, leaveChangeValue, leaveArrowValue, setLeaveArrowValue,
  plusMaxHeight, setPlusMaxHeight,minusMaxHeight, setMinusMaxHeight, changeCoverSide, setChangeCoverSide,
  turnFigure, setTurnFigure, degreesTurnFigure, setDegreesTurnFigure, activeDottCoordsX, setActiveDottCoordsX, activeDottCoordsY, setActiveDottCoordsY,
  
    leavesLeft,
    setLeavesLeft,
    leavesTop,
    setLeavesTop,
    leavesRight,
    setLeavesRight,
    leavesBottom,
    setLeavesBottom,

    onAInputFocus, setOnAInputFocus,
    onA1InputFocus, setOnA1InputFocus,
    onBInputFocus, setOnBInputFocus,
    onCInputFocus, setOnCInputFocus,
    onDInputFocus, setOnDInputFocus,
    onEInputFocus, setOnEInputFocus,
    onHeightInputFocus, setOnHeightInputFocus,
    onLInputFocus, setOnLInputFocus,

    areas1,
    setAreas1,
    areas2, 
    setAreas2,
    areas3,
    setAreas3,
    areas4,
    setAreas4,
    areas5, 
    setAreas5,
    areas6,
    setAreas6,
    areas7,
    setAreas7,
    areas8, 
    setAreas8,
    areas9,
    setAreas9,
    areas10,
    setAreas10,
    areas11,
    setAreas11,
    areas12,
    setAreas12,
    areas13,
    setAreas13,
    areas14,
    setAreas14,
    areas15,
    setAreas15,
    areas16,
    setAreas16,
    areas17,
    setAreas17,
    areas18,
    setAreas18,
    areas19,
    setAreas19,
    areas20,
    setAreas20,



    cuts1,
    setCuts1,
    cuts2, 
    setCuts2,
    cuts3,
    setCuts3,
    cuts4,
    setCuts4,
    cuts5, 
    setCuts5,
    cuts6,
    setCuts6,
    cuts7,
    setCuts7,
    cuts8, 
    setCuts8,
    cuts9,
    setCuts9,
    cuts10,
    setCuts10,
    cuts11,
    setCuts11,
    cuts12,
    setCuts12,
    cuts13,
    setCuts13,
    cuts14,
    setCuts14,
    cuts15,
    setCuts15,
    cuts16,
    setCuts16,
    cuts17,
    setCuts17,
    cuts18,
    setCuts18,
    cuts19,
    setCuts19,
    cuts20,
    setCuts20,

    closedCuts1,setClosedCuts1,
    closedCuts2,setClosedCuts2,
    closedCuts3,setClosedCuts3,
    closedCuts4,setClosedCuts4,
    closedCuts5,setClosedCuts5,
    closedCuts6,setClosedCuts6,
    closedCuts7,setClosedCuts7,
    closedCuts8,setClosedCuts8,
    closedCuts9,setClosedCuts9,
    closedCuts10,setClosedCuts10,
    closedCuts11,setClosedCuts11,
    closedCuts12,setClosedCuts12,
    closedCuts13,setClosedCuts13,
    closedCuts14,setClosedCuts14,
    closedCuts15,setClosedCuts15,
    closedCuts16,setClosedCuts16,
    closedCuts17,setClosedCuts17,
    closedCuts18,setClosedCuts18,
    closedCuts19,setClosedCuts19,
    closedCuts20,setClosedCuts20,

    lines1,
    setLines1,
    lines2, 
    setLines2,
    lines3,
    setLines3,
    lines4,
    setLines4,
    lines5, 
    setLines5,
    lines6,
    setLines6,
    lines7,
    setLines7,
    lines8, 
    setLines8,
    lines9,
    setLines9,
    lines10,
    setLines10,
    lines11,
    setLines11,
    lines12,
    setLines12,
    lines13,
    setLines13,
    lines14,
    setLines14,
    lines15,
    setLines15,
    lines16,
    setLines16,
    lines17,
    setLines17,
    lines18,
    setLines18,
    lines19,
    setLines19,
    lines20,
    setLines20,

    cutLines1,
    setCutLines1,
    cutLines2, 
    setCutLines2,
    cutLines3,
    setCutLines3,
    cutLines4,
    setCutLines4,
    cutLines5, 
    setCutLines5,
    cutLines6,
    setCutLines6,
    cutLines7,
    setCutLines7,
    cutLines8, 
    setCutLines8,
    cutLines9,
    setCutLines9,
    cutLines10,
    setCutLines10,
    cutLines11,
    setCutLines11,
    cutLines12,
    setCutLines12,
    cutLines13,
    setCutLines13,
    cutLines14,
    setCutLines14,
    cutLines15,
    setCutLines15,
    cutLines16,
    setCutLines16,
    cutLines17,
    setCutLines17,
    cutLines18,
    setCutLines18,
    cutLines19,
    setCutLines19,
    cutLines20,
    setCutLines20,

    eaves1,
    setEaves1,
    eaves2,
    setEaves2,
    eaves3,
    setEaves3,
    eaves4,
    setEaves4,
    eaves5,
    setEaves5,
    eaves6,
    setEaves6,
    eaves7,
    setEaves7,
    eaves8,
    setEaves8,
    eaves9,
    setEaves9,
    eaves10,
    setEaves10,
    eaves11,
    setEaves11,
    eaves12,
    setEaves12,
    eaves13,
    setEaves13,
    eaves14,
    setEaves14,
    eaves15,
    setEaves15,
    eaves16,
    setEaves16,
    eaves17,
    setEaves17,
    eaves18,
    setEaves18,
    eaves19,
    setEaves19,
    eaves20,
    setEaves20,



    apex1,
    setApex1,
    apex2,
    setApex2,
    apex3,
    setApex3,
    apex4,
    setApex4,
    apex5,
    setApex5,
    apex6,
    setApex6,
    apex7,
    setApex7,
    apex8,
    setApex8,
    apex9,
    setApex9,
    apex10,
    setApex10,
    apex11,
    setApex11,
    apex12,
    setApex12,
    apex13,
    setApex13,
    apex14,
    setApex14,
    apex15,
    setApex15,
    apex16,
    setApex16,
    apex17,
    setApex17,
    apex18,
    setApex18,
    apex19,
    setApex19,
    apex20,
    setApex20,



    isLinesClosed1,
    setIsLinesClosed1,
    isLinesClosed2,
    setIsLinesClosed2,
    isLinesClosed3,
    setIsLinesClosed3,
    isLinesClosed4,
    setIsLinesClosed4,
    isLinesClosed5,
    setIsLinesClosed5,
    isLinesClosed6,
    setIsLinesClosed6,
    isLinesClosed7,
    setIsLinesClosed7,
    isLinesClosed8,
    setIsLinesClosed8,
    isLinesClosed9,
    setIsLinesClosed9,
    isLinesClosed10,
    setIsLinesClosed10,
    isLinesClosed11,
    setIsLinesClosed11,
    isLinesClosed12,
    setIsLinesClosed12,
    isLinesClosed13,
    setIsLinesClosed13,
    isLinesClosed14,
    setIsLinesClosed14,
    isLinesClosed15,
    setIsLinesClosed15,
    isLinesClosed16,
    setIsLinesClosed16,
    isLinesClosed17,
    setIsLinesClosed17,
    isLinesClosed18,
    setIsLinesClosed18,
    isLinesClosed19,
    setIsLinesClosed19,
    isLinesClosed20,
    setIsLinesClosed20,
                                      

    results1, 
    setResults1, 
    results2, 
    setResults2, 
    results3,  
    setResults3,
    results4,  
    setResults4,
    results5,  
    setResults5,
    results6,  
    setResults6,
    results7,  
    setResults7,
    results8,  
    setResults8,
    results9,  
    setResults9,
    results10,  
    setResults10,
    results11,  
    setResults11,
    results12,  
    setResults12,
    results13,  
    setResults13,
    results14,  
    setResults14,
    results15,  
    setResults15,
    results16,  
    setResults16,
    results17,  
    setResults17,
    results18,  
    setResults18,
    results19,  
    setResults19,
    results20,  
    setResults20,

    areas1Copy,   
    setAreas1Copy,
    areas2Copy,   
    setAreas2Copy,
    areas3Copy,   
    setAreas3Copy,
    areas4Copy,  
    setAreas4Copy,
    areas5Copy,   
    setAreas5Copy,
    areas6Copy,   
    setAreas6Copy,
    areas7Copy,   
    setAreas7Copy,
    areas8Copy,   
    setAreas8Copy,
    areas9Copy,   
    setAreas9Copy,
    areas10Copy,    
    setAreas10Copy,
    areas11Copy,    
    setAreas11Copy,
    areas12Copy,    
    setAreas12Copy,
    areas13Copy,    
    setAreas13Copy,
    areas14Copy,    
    setAreas14Copy,
    areas15Copy,    
    setAreas15Copy,
    areas16Copy,    
    setAreas16Copy,
    areas17Copy,    
    setAreas17Copy,
    areas18Copy,    
    setAreas18Copy,
    areas19Copy,    
    setAreas19Copy,
    areas20Copy,    
    setAreas20Copy,


    eaves1Copy,
    setEaves1Copy,
    eaves2Copy,
    setEaves2Copy,
    eaves3Copy,
    setEaves3Copy,
    eaves4Copy,
    setEaves4Copy,
    eaves5Copy,
    setEaves5Copy,
    eaves6Copy,
    setEaves6Copy,
    eaves7Copy,
    setEaves7Copy,
    eaves8Copy,
    setEaves8Copy,
    eaves9Copy,
    setEaves9Copy,
    eaves10Copy,
    setEaves10Copy,
    eaves11Copy,
    setEaves11Copy,
    eaves12Copy,
    setEaves12Copy,
    eaves13Copy,
    setEaves13Copy,
    eaves14Copy,
    setEaves14Copy,
    eaves15Copy,
    setEaves15Copy,
    eaves16Copy,
    setEaves16Copy,
    eaves17Copy,
    setEaves17Copy,
    eaves18Copy,
    setEaves18Copy,
    eaves19Copy,
    setEaves19Copy,
    eaves20Copy,
    setEaves20Copy,



    apex1Copy,
    setApex1Copy,
    apex2Copy,
    setApex2Copy,
    apex3Copy,
    setApex3Copy,
    apex4Copy,
    setApex4Copy,
    apex5Copy,
    setApex5Copy,
    apex6Copy,
    setApex6Copy,
    apex7Copy,
    setApex7Copy,
    apex8Copy,
    setApex8Copy,
    apex9Copy,
    setApex9Copy,
    apex10Copy,
    setApex10Copy,
    apex11Copy,
    setApex11Copy,
    apex12Copy,
    setApex12Copy,
    apex13Copy,
    setApex13Copy,
    apex14Copy,
    setApex14Copy,
    apex15Copy,
    setApex15Copy,
    apex16Copy,
    setApex16Copy,
    apex17Copy,
    setApex17Copy,
    apex18Copy,
    setApex18Copy,
    apex19Copy,
    setApex19Copy,
    apex20Copy,
    setApex20Copy,


    cellRows1,
    setCellRows1,
    cellRows2,
    setCellRows2,
    cellRows3,
    setCellRows3,
    cellRows4,
    setCellRows4,
    cellRows5,
    setCellRows5,
    cellRows6,
    setCellRows6,
    cellRows7,
    setCellRows7,
    cellRows8,
    setCellRows8,
    cellRows9,
    setCellRows9,
    cellRows10,
    setCellRows10,
    cellRows11,
    setCellRows11,
    cellRows12,
    setCellRows12,
    cellRows13,
    setCellRows13,
    cellRows14,
    setCellRows14,
    cellRows15,
    setCellRows15,
    cellRows16,
    setCellRows16,
    cellRows17,
    setCellRows17,
    cellRows18,
    setCellRows18,
    cellRows19,
    setCellRows19,
    cellRows20,
    setCellRows20, 

    polygonPoints, setPolygonPoints,
    builtCustomPoints, setBuiltCustomPoints,
    builtCutCustomPoints, setBuiltCutCustomPoints,
    cutsForCover, setCutsForCover,
    start, setStart,

    isReadyFigureBuild, setIsReadyFigureBuild,
    points, setPoints,
    pointsForTestFigure, setPointsForTestFigure,
    lettersForTestFigure, setLettersForTestFigure,
    isLineClosed, setIsLineClosed,
    lines, setLines,
    linesForTestFigure, setLinesForTestFigure,
    addLinesForTestFigure, setAddLinesForTestFigure,
    addLinesForTestFigure1, setAddLinesForTestFigure1,
    addLinesForTestFigure2, setAddLinesForTestFigure2,
    addLinesForTestFigure3, setAddLinesForTestFigure3,
    cutFigures, setCutFigures,
    cutFiguresLines, setCutFiguresLines,

    builtCustomPoints1, setBuiltCustomPoints1,
    builtCustomPoints2, setBuiltCustomPoints2,
    builtCustomPoints3, setBuiltCustomPoints3,
    builtCustomPoints4, setBuiltCustomPoints4,
    builtCustomPoints5, setBuiltCustomPoints5,
    builtCustomPoints6, setBuiltCustomPoints6,
    builtCustomPoints7, setBuiltCustomPoints7,
    builtCustomPoints8, setBuiltCustomPoints8,
    builtCustomPoints9, setBuiltCustomPoints9,
    builtCustomPoints10, setBuiltCustomPoints10,
    builtCustomPoints11, setBuiltCustomPoints11,
    builtCustomPoints12, setBuiltCustomPoints12,
    builtCustomPoints13, setBuiltCustomPoints13,
    builtCustomPoints14, setBuiltCustomPoints14,
    builtCustomPoints15, setBuiltCustomPoints15,
    builtCustomPoints16, setBuiltCustomPoints16,
    builtCustomPoints17, setBuiltCustomPoints17,
    builtCustomPoints18, setBuiltCustomPoints18,
    builtCustomPoints19, setBuiltCustomPoints19,
    builtCustomPoints20, setBuiltCustomPoints20,

    builtSideSizes1, setBuiltSideSizes1,
    builtSideSizes2, setBuiltSideSizes2,
    builtSideSizes3, setBuiltSideSizes3,
    builtSideSizes4, setBuiltSideSizes4,
    builtSideSizes5, setBuiltSideSizes5,
    builtSideSizes6, setBuiltSideSizes6,
    builtSideSizes7, setBuiltSideSizes7,
    builtSideSizes8, setBuiltSideSizes8,
    builtSideSizes9, setBuiltSideSizes9,
    builtSideSizes10, setBuiltSideSizes10,
    builtSideSizes11, setBuiltSideSizes11,
    builtSideSizes12, setBuiltSideSizes12,
    builtSideSizes13, setBuiltSideSizes13,
    builtSideSizes14, setBuiltSideSizes14,
    builtSideSizes15, setBuiltSideSizes15,
    builtSideSizes16, setBuiltSideSizes16,
    builtSideSizes17, setBuiltSideSizes17,
    builtSideSizes18, setBuiltSideSizes18,
    builtSideSizes19, setBuiltSideSizes19,
    builtSideSizes20, setBuiltSideSizes20,

    builtCustomPoints1Copy, setBuiltCustomPoints1Copy,
    builtCustomPoints2Copy, setBuiltCustomPoints2Copy,
    builtCustomPoints3Copy, setBuiltCustomPoints3Copy,
    builtCustomPoints4Copy, setBuiltCustomPoints4Copy,
    builtCustomPoints5Copy, setBuiltCustomPoints5Copy,
    builtCustomPoints6Copy, setBuiltCustomPoints6Copy,
    builtCustomPoints7Copy, setBuiltCustomPoints7Copy,
    builtCustomPoints8Copy, setBuiltCustomPoints8Copy,
    builtCustomPoints9Copy, setBuiltCustomPoints9Copy,
    builtCustomPoints10Copy, setBuiltCustomPoints10Copy,
    builtCustomPoints11Copy, setBuiltCustomPoints11Copy,
    builtCustomPoints12Copy, setBuiltCustomPoints12Copy,
    builtCustomPoints13Copy, setBuiltCustomPoints13Copy,
    builtCustomPoints14Copy, setBuiltCustomPoints14Copy,
    builtCustomPoints15Copy, setBuiltCustomPoints15Copy,
    builtCustomPoints16Copy, setBuiltCustomPoints16Copy,
    builtCustomPoints17Copy, setBuiltCustomPoints17Copy,
    builtCustomPoints18Copy, setBuiltCustomPoints18Copy,
    builtCustomPoints19Copy, setBuiltCustomPoints19Copy,
    builtCustomPoints20Copy, setBuiltCustomPoints20Copy,

    Apex1Id, setApex1Id,
    Apex2Id, setApex2Id,
    Apex3Id, setApex3Id,
    Apex4Id, setApex4Id,
    Apex5Id, setApex5Id,
    Apex6Id, setApex6Id,
    Apex7Id, setApex7Id,
    Apex8Id, setApex8Id,
    Apex9Id, setApex9Id,
    Apex10Id, setApex10Id,
    Apex11Id, setApex11Id,
    Apex12Id, setApex12Id,
    Apex13Id, setApex13Id,
    Apex14Id, setApex14Id,
    Apex15Id, setApex15Id,
    Apex16Id, setApex16Id,
    Apex17Id, setApex17Id,
    Apex18Id, setApex18Id,
    Apex19Id, setApex19Id,
    Apex20Id, setApex20Id,


    Eaves1Id, setEaves1Id,
    Eaves2Id, setEaves2Id,
    Eaves3Id, setEaves3Id,
    Eaves4Id, setEaves4Id,
    Eaves5Id, setEaves5Id,
    Eaves6Id, setEaves6Id,
    Eaves7Id, setEaves7Id,
    Eaves8Id, setEaves8Id,
    Eaves9Id, setEaves9Id,
    Eaves10Id, setEaves10Id,
    Eaves11Id, setEaves11Id,
    Eaves12Id, setEaves12Id,
    Eaves13Id, setEaves13Id,
    Eaves14Id, setEaves14Id,
    Eaves15Id, setEaves15Id,
    Eaves16Id, setEaves16Id,
    Eaves17Id, setEaves17Id,
    Eaves18Id, setEaves18Id,
    Eaves19Id, setEaves19Id,
    Eaves20Id, setEaves20Id,

    lines1Copy, setLines1Copy,
    lines2Copy, setLines2Copy,
    lines3Copy, setLines3Copy,
    lines4Copy, setLines4Copy,
    lines5Copy, setLines5Copy,
    lines6Copy, setLines6Copy,
    lines7Copy, setLines7Copy,
    lines8Copy, setLines8Copy,
    lines9Copy, setLines9Copy,
    lines10Copy, setLines10Copy,
    lines11Copy, setLines11Copy,
    lines12Copy, setLines12Copy,
    lines13Copy, setLines13Copy,
    lines14Copy, setLines14Copy,
    lines15Copy, setLines15Copy,
    lines16Copy, setLines16Copy,
    lines17Copy, setLines17Copy,
    lines18Copy, setLines18Copy,
    lines19Copy, setLines19Copy,
    lines20Copy, setLines20Copy

  }) => {

    const appContext = useContext(AppContext);
    const { selectedFigure, editedMode, isBuildMode, figureSides, dictionary } = appContext.state;

    function calcFigurePoints(points: any[]) {
        setPolygonPoints(points);
    }
    
    let coordsForCover: any = []
    if(areasCount > 0){
      for(let i = 0; i < eval("areas" + areasCount + ".length"); i++){
        coordsForCover.push({x: eval("areas" + areasCount + "[" + i + "].x"), y: eval("areas" + areasCount + "[" + i + "].y")})
      }
    }
    React.useEffect(() => {
        if(areasCount > 0){
          setCutsForCover(eval("cuts" + areasCount))
        }
    }, [areasCount, builtCustomPoints, doubleCutFigure, cuts1, cuts2, cuts3, cuts4, cuts5, cuts6, cuts7, cuts8, cuts9, cuts10, cuts11, cuts12, cuts13, cuts14, cuts15, cuts16, cuts17, cuts18,
      cuts19, cuts20]);

      React.useEffect(() => {
        if(areasCount > 0){
          eval("setCellRows" + areasCount)([])
        }
    }, [areas1, areas2, areas3, areas4, areas5, areas6, areas7, areas8, areas9, areas10, areas11, areas12, areas13, areas14, areas15, areas16, areas17, areas18,
      areas19, areas20])
    return (
        <>
            {isGridActive &&
              <CreativePointedPolygon
                clickedCoords={clickedCoords}
                activeDottCoordsX={activeDottCoordsX} setActiveDottCoordsX = {setActiveDottCoordsX}
                activeDottCoordsY={activeDottCoordsY} setActiveDottCoordsY = {setActiveDottCoordsY}
                activeMode={appContext.state}
                width={500}
                calcPolygonPointsCallback={calcFigurePoints}
                isDottAdditional={isDottAdditional}
                setIsDottAdditional={setIsDottAdditional}
                prevStep={prevStep}
                setPrevStep={setPrevStep}
                nextStep={nextStep}
                setNextStep={setNextStep}
                deleteDot={deleteDot}
                setDeleteDot={setDeleteDot}
                addArea={addArea}
                setAddArea={setAddArea}
                areasCount={areasCount}
                setAreasCount={setAreasCount}
                deleteFigure={deleteFigure}
                setDeleteFigure={setDeleteFigure}
                addEaves={addEaves}
                setAddEaves={setAddEaves}
                addApex={addApex} 
                setAddApex={setAddApex}
                finishFigure={finishFigure}
                setFinishFigure={setFinishFigure}
                changeDott={changeDott}
                setChangeDott={setChangeDott}
                copyFigure={copyFigure}
                setCopyFigure={setCopyFigure}
                flipFigureX={flipFigureX}
                setFlipFigureX={setFlipFigureX}
                flipFigureY={flipFigureY}
                setFlipFigureY={setFlipFigureY}


                onAInputFocus={onAInputFocus} setOnAInputFocus={setOnAInputFocus}
                onA1InputFocus={onA1InputFocus} setOnA1InputFocus={setOnA1InputFocus}
                onBInputFocus={onBInputFocus} setOnBInputFocus={setOnBInputFocus}
                onCInputFocus={onCInputFocus} setOnCInputFocus={setOnCInputFocus}
                onDInputFocus={onDInputFocus} setOnDInputFocus={setOnDInputFocus}
                onEInputFocus={onEInputFocus} setOnEInputFocus={setOnEInputFocus}
                onHeightInputFocus={onHeightInputFocus} setOnHeightInputFocus={setOnHeightInputFocus}
                onLInputFocus={onLInputFocus} setOnLInputFocus={setOnLInputFocus}

                turnFigure={turnFigure} 
                setTurnFigure={setTurnFigure} 
                degreesTurnFigure={degreesTurnFigure} 
                setDegreesTurnFigure={setDegreesTurnFigure}
                
                areas1Copy={areas1Copy}
                setAreas1Copy={setAreas1Copy}
                areas2Copy={areas2Copy}
                setAreas2Copy={setAreas2Copy}
                areas3Copy={areas3Copy}
                setAreas3Copy={setAreas3Copy}
                areas4Copy={areas4Copy}
                setAreas4Copy={setAreas4Copy}
                areas5Copy={areas5Copy}
                setAreas5Copy={setAreas5Copy}
                areas6Copy={areas6Copy}
                setAreas6Copy={setAreas6Copy}
                areas7Copy={areas7Copy}
                setAreas7Copy={setAreas7Copy}
                areas8Copy={areas8Copy}
                setAreas8Copy={setAreas8Copy}
                areas9Copy={areas9Copy}
                setAreas9Copy={setAreas9Copy}
                areas10Copy={areas10Copy}
                setAreas10Copy={setAreas10Copy}
                areas11Copy={areas11Copy}
                setAreas11Copy={setAreas11Copy}
                areas12Copy={areas12Copy}
                setAreas12Copy={setAreas12Copy}
                areas13Copy={areas13Copy}
                setAreas13Copy={setAreas13Copy}
                areas14Copy={areas14Copy}
                setAreas14Copy={setAreas14Copy}
                areas15Copy={areas15Copy}
                setAreas15Copy={setAreas15Copy}
                areas16Copy={areas16Copy}
                setAreas16Copy={setAreas16Copy}
                areas17Copy={areas17Copy}
                setAreas17Copy={setAreas17Copy}
                areas18Copy={areas18Copy}
                setAreas18Copy={setAreas18Copy}
                areas19Copy={areas19Copy}
                setAreas19Copy={setAreas19Copy}
                areas20Copy={areas20Copy}
                setAreas20Copy={setAreas20Copy}

                eaves1Copy={eaves1Copy}
                setEaves1Copy={setEaves1Copy}
                eaves2Copy={eaves2Copy}
                setEaves2Copy={setEaves2Copy}
                eaves3Copy={eaves3Copy}
                setEaves3Copy={setEaves3Copy}
                eaves4Copy={eaves4Copy}
                setEaves4Copy={setEaves4Copy}
                eaves5Copy={eaves5Copy}
                setEaves5Copy={setEaves5Copy}
                eaves6Copy={eaves6Copy}
                setEaves6Copy={setEaves6Copy}
                eaves7Copy={eaves7Copy}
                setEaves7Copy={setEaves7Copy}
                eaves8Copy={eaves8Copy}
                setEaves8Copy={setEaves8Copy}
                eaves9Copy={eaves9Copy}
                setEaves9Copy={setEaves9Copy}
                eaves10Copy={eaves10Copy}
                setEaves10Copy={setEaves10Copy}
                eaves11Copy={eaves11Copy}
                setEaves11Copy={setEaves11Copy}
                eaves12Copy={eaves12Copy}
                setEaves12Copy={setEaves12Copy}
                eaves13Copy={eaves13Copy}
                setEaves13Copy={setEaves13Copy}
                eaves14Copy={eaves14Copy}
                setEaves14Copy={setEaves14Copy}
                eaves15Copy={eaves15Copy}
                setEaves15Copy={setEaves15Copy}
                eaves16Copy={eaves16Copy}
                setEaves16Copy={setEaves16Copy}
                eaves17Copy={eaves17Copy}
                setEaves17Copy={setEaves17Copy}
                eaves18Copy={eaves18Copy}
                setEaves18Copy={setEaves18Copy}
                eaves19Copy={eaves19Copy}
                setEaves19Copy={setEaves19Copy}
                eaves20Copy={eaves20Copy}
                setEaves20Copy={setEaves20Copy}

                apex1Copy={apex1Copy}
                setApex1Copy={setApex1Copy}
                apex2Copy={apex2Copy}
                setApex2Copy={setApex2Copy}
                apex3Copy={apex3Copy}
                setApex3Copy={setApex3Copy}
                apex4Copy={apex4Copy}
                setApex4Copy={setApex4Copy}
                apex5Copy={apex5Copy}
                setApex5Copy={setApex5Copy}
                apex6Copy={apex6Copy}
                setApex6Copy={setApex6Copy}
                apex7Copy={apex7Copy}
                setApex7Copy={setApex7Copy}
                apex8Copy={apex8Copy}
                setApex8Copy={setApex8Copy}
                apex9Copy={apex9Copy}
                setApex9Copy={setApex9Copy}
                apex10Copy={apex10Copy}
                setApex10Copy={setApex10Copy}
                apex11Copy={apex11Copy}
                setApex11Copy={setApex11Copy}
                apex12Copy={apex12Copy}
                setApex12Copy={setApex12Copy}
                apex13Copy={apex13Copy}
                setApex13Copy={setApex13Copy}
                apex14Copy={apex14Copy}
                setApex14Copy={setApex14Copy}
                apex15Copy={apex15Copy}
                setApex15Copy={setApex15Copy}
                apex16Copy={apex16Copy}
                setApex16Copy={setApex16Copy}
                apex17Copy={apex17Copy}
                setApex17Copy={setApex17Copy}
                apex18Copy={apex18Copy}
                setApex18Copy={setApex18Copy}
                apex19Copy={apex19Copy}
                setApex19Copy={setApex19Copy}
                apex20Copy={apex20Copy}
                setApex20Copy={setApex20Copy}

                areas1={areas1} setAreas1={setAreas1}
                areas2={areas2} setAreas2={setAreas2}
                areas3={areas3} setAreas3={setAreas3}
                areas4={areas4} setAreas4={setAreas4}
                areas5={areas5} setAreas5={setAreas5}
                areas6={areas6} setAreas6={setAreas6}
                areas7={areas7} setAreas7={setAreas7}
                areas8={areas8} setAreas8={setAreas8}
                areas9={areas9} setAreas9={setAreas9}
                areas10={areas10} setAreas10={setAreas10}
                areas11={areas11} setAreas11={setAreas11}
                areas12={areas12} setAreas12={setAreas12}
                areas13={areas13} setAreas13={setAreas13}
                areas14={areas14} setAreas14={setAreas14}
                areas15={areas15} setAreas15={setAreas15}
                areas16={areas16} setAreas16={setAreas16}
                areas17={areas17} setAreas17={setAreas17}
                areas18={areas18} setAreas18={setAreas18}
                areas19={areas19} setAreas19={setAreas19}
                areas20={areas20} setAreas20={setAreas20}

                cuts1={cuts1} setCuts1={setCuts1}
                cuts2={cuts2} setCuts2={setCuts2}
                cuts3={cuts3} setCuts3={setCuts3} 
                cuts4={cuts4} setCuts4={setCuts4}
                cuts5={cuts5} setCuts5={setCuts5}
                cuts6={cuts6} setCuts6={setCuts6}
                cuts7={cuts7} setCuts7={setCuts7}
                cuts8={cuts8} setCuts8={setCuts8}
                cuts9={cuts9} setCuts9={setCuts9}
                cuts10={cuts10} setCuts10={setCuts10}
                cuts11={cuts11} setCuts11={setCuts11}
                cuts12={cuts12} setCuts12={setCuts12} 
                cuts13={cuts13} setCuts13={setCuts13} 
                cuts14={cuts14} setCuts14={setCuts14} 
                cuts15={cuts15} setCuts15={setCuts15} 
                cuts16={cuts16} setCuts16={setCuts16} 
                cuts17={cuts17} setCuts17={setCuts17} 
                cuts18={cuts18} setCuts18={setCuts18} 
                cuts19={cuts19} setCuts19={setCuts19} 
                cuts20={cuts20} setCuts20={setCuts20} 

                lines1={lines1} setLines1={setLines1}
                lines2={lines2} setLines2={setLines2}
                lines3={lines3} setLines3={setLines3}
                lines4={lines4} setLines4={setLines4}
                lines5={lines5} setLines5={setLines5}
                lines6={lines6} setLines6={setLines6}
                lines7={lines7} setLines7={setLines7}
                lines8={lines8} setLines8={setLines8}
                lines9={lines9} setLines9={setLines9}
                lines10={lines10} setLines10={setLines10}
                lines11={lines11} setLines11={setLines11}
                lines12={lines12} setLines12={setLines12}
                lines13={lines13} setLines13={setLines13}
                lines14={lines14} setLines14={setLines14}
                lines15={lines15} setLines15={setLines15}
                lines16={lines16} setLines16={setLines16}
                lines17={lines17} setLines17={setLines17}
                lines18={lines18} setLines18={setLines18}
                lines19={lines19} setLines19={setLines19}
                lines20={lines20} setLines20={setLines20}

                cutLines1={cutLines1} setCutLines1={setCutLines1}
                cutLines2={cutLines2} setCutLines2={setCutLines2}
                cutLines3={cutLines3} setCutLines3={setCutLines3}
                cutLines4={cutLines4} setCutLines4={setCutLines4}
                cutLines5={cutLines5} setCutLines5={setCutLines5}
                cutLines6={cutLines6} setCutLines6={setCutLines6}
                cutLines7={cutLines7} setCutLines7={setCutLines7}
                cutLines8={cutLines8} setCutLines8={setCutLines8}
                cutLines9={cutLines9} setCutLines9={setCutLines9}
                cutLines10={cutLines10} setCutLines10={setCutLines10}
                cutLines11={cutLines11} setCutLines11={setCutLines11}
                cutLines12={cutLines12} setCutLines12={setCutLines12}
                cutLines13={cutLines13} setCutLines13={setCutLines13}
                cutLines14={cutLines14} setCutLines14={setCutLines14}
                cutLines15={cutLines15} setCutLines15={setCutLines15}
                cutLines16={cutLines16} setCutLines16={setCutLines16}
                cutLines17={cutLines17} setCutLines17={setCutLines17}
                cutLines18={cutLines18} setCutLines18={setCutLines18}
                cutLines19={cutLines19} setCutLines19={setCutLines19}
                cutLines20={cutLines20} setCutLines20={setCutLines20}

                eaves1={eaves1} setEaves1={setEaves1} 
                eaves2={eaves2} setEaves2={setEaves2} 
                eaves3={eaves3} setEaves3={setEaves3}
                eaves4={eaves4} setEaves4={setEaves4} 
                eaves5={eaves5} setEaves5={setEaves5} 
                eaves6={eaves6} setEaves6={setEaves6}
                eaves7={eaves7} setEaves7={setEaves7} 
                eaves8={eaves8} setEaves8={setEaves8} 
                eaves9={eaves9} setEaves9={setEaves9}
                eaves10={eaves10} setEaves10={setEaves10} 
                eaves11={eaves11} setEaves11={setEaves11} 
                eaves12={eaves12} setEaves12={setEaves12}
                eaves13={eaves13} setEaves13={setEaves13} 
                eaves14={eaves14} setEaves14={setEaves14} 
                eaves15={eaves15} setEaves15={setEaves15}
                eaves16={eaves16} setEaves16={setEaves16} 
                eaves17={eaves17} setEaves17={setEaves17} 
                eaves18={eaves18} setEaves18={setEaves18}
                eaves19={eaves19} setEaves19={setEaves19} 
                eaves20={eaves20} setEaves20={setEaves20}

                apex1={apex1} setApex1={setApex1}
                apex2={apex2} setApex2={setApex2}
                apex3={apex3} setApex3={setApex3}
                apex4={apex4} setApex4={setApex4}
                apex5={apex5} setApex5={setApex5}
                apex6={apex6} setApex6={setApex6}
                apex7={apex7} setApex7={setApex7}
                apex8={apex8} setApex8={setApex8}
                apex9={apex9} setApex9={setApex9}
                apex10={apex10} setApex10={setApex10}
                apex11={apex11} setApex11={setApex11}
                apex12={apex12} setApex12={setApex12}
                apex13={apex13} setApex13={setApex13}
                apex14={apex14} setApex14={setApex14}
                apex15={apex15} setApex15={setApex15}
                apex16={apex16} setApex16={setApex16}
                apex17={apex17} setApex17={setApex17}
                apex18={apex18} setApex18={setApex18}
                apex19={apex19} setApex19={setApex19}
                apex20={apex20} setApex20={setApex20}

                isLinesClosed1={isLinesClosed1} setIsLinesClosed1={setIsLinesClosed1} 
                isLinesClosed2={isLinesClosed2} setIsLinesClosed2={setIsLinesClosed2} 
                isLinesClosed3={isLinesClosed3} setIsLinesClosed3={setIsLinesClosed3} 
                isLinesClosed4={isLinesClosed4} setIsLinesClosed4={setIsLinesClosed4} 
                isLinesClosed5={isLinesClosed5} setIsLinesClosed5={setIsLinesClosed5} 
                isLinesClosed6={isLinesClosed6} setIsLinesClosed6={setIsLinesClosed6} 
                isLinesClosed7={isLinesClosed7} setIsLinesClosed7={setIsLinesClosed7} 
                isLinesClosed8={isLinesClosed8} setIsLinesClosed8={setIsLinesClosed8} 
                isLinesClosed9={isLinesClosed9} setIsLinesClosed9={setIsLinesClosed9} 
                isLinesClosed10={isLinesClosed10} setIsLinesClosed10={setIsLinesClosed10} 
                isLinesClosed11={isLinesClosed11} setIsLinesClosed11={setIsLinesClosed11} 
                isLinesClosed12={isLinesClosed12} setIsLinesClosed12={setIsLinesClosed12} 
                isLinesClosed13={isLinesClosed13} setIsLinesClosed13={setIsLinesClosed13} 
                isLinesClosed14={isLinesClosed14} setIsLinesClosed14={setIsLinesClosed14} 
                isLinesClosed15={isLinesClosed15} setIsLinesClosed15={setIsLinesClosed15} 
                isLinesClosed16={isLinesClosed16} setIsLinesClosed16={setIsLinesClosed16} 
                isLinesClosed17={isLinesClosed17} setIsLinesClosed17={setIsLinesClosed17} 
                isLinesClosed18={isLinesClosed18} setIsLinesClosed18={setIsLinesClosed18} 
                isLinesClosed19={isLinesClosed19} setIsLinesClosed19={setIsLinesClosed19} 
                isLinesClosed20={isLinesClosed20} setIsLinesClosed20={setIsLinesClosed20} 


                results1={results1} setResults1={setResults1}
                 results2={results2} setResults2={setResults2}
                 results3={results3} setResults3={setResults3}
                 results4={results4} setResults4={setResults4}
                 results5={results5} setResults5={setResults5}
                 results6={results6} setResults6={setResults6}
                 results7={results7} setResults7={setResults7}
                 results8={results8} setResults8={setResults8}
                 results9={results9} setResults9={setResults9}
                 results10={results10} setResults10={setResults10}
                 results11={results11} setResults11={setResults11}
                 results12={results12} setResults12={setResults12}
                 results13={results13} setResults13={setResults13}
                 results14={results14} setResults14={setResults14}
                 results15={results15} setResults15={setResults15}
                 results16={results16} setResults16={setResults16}
                 results17={results17} setResults17={setResults17}
                 results18={results18} setResults18={setResults18}
                 results19={results19} setResults19={setResults19}
                 results20={results20} setResults20={setResults20}



                isReadyFigureBuild={isReadyFigureBuild} setIsReadyFigureBuild={setIsReadyFigureBuild}
                points={points} setPoints={setPoints}
                pointsForTestFigure={pointsForTestFigure} setPointsForTestFigure={setPointsForTestFigure}
                lettersForTestFigure={lettersForTestFigure} setLettersForTestFigure={setLettersForTestFigure}
                builtCustomPoints={builtCustomPoints} setBuiltCustomPoints={setBuiltCustomPoints}
                isLineClosed={isLineClosed} setIsLineClosed={setIsLineClosed}
                lines={lines} setLines={setLines}
                linesForTestFigure={linesForTestFigure} setLinesForTestFigure={setLinesForTestFigure}
                addLinesForTestFigure={addLinesForTestFigure} setAddLinesForTestFigure={setAddLinesForTestFigure}
                addLinesForTestFigure1={addLinesForTestFigure1} setAddLinesForTestFigure1={setAddLinesForTestFigure1}
                addLinesForTestFigure2={addLinesForTestFigure2} setAddLinesForTestFigure2={setAddLinesForTestFigure2}
                addLinesForTestFigure3={addLinesForTestFigure3} setAddLinesForTestFigure3={setAddLinesForTestFigure3}
                builtCustomPoints1={builtCustomPoints1} setBuiltCustomPoints1={setBuiltCustomPoints1}
                builtCustomPoints2={builtCustomPoints2} setBuiltCustomPoints2={setBuiltCustomPoints2}
                builtCustomPoints3={builtCustomPoints3} setBuiltCustomPoints3={setBuiltCustomPoints3}
                builtCustomPoints4={builtCustomPoints4} setBuiltCustomPoints4={setBuiltCustomPoints4}
                builtCustomPoints5={builtCustomPoints5} setBuiltCustomPoints5={setBuiltCustomPoints5}
                builtCustomPoints6={builtCustomPoints6} setBuiltCustomPoints6={setBuiltCustomPoints6}
                builtCustomPoints7={builtCustomPoints7} setBuiltCustomPoints7={setBuiltCustomPoints7}
                builtCustomPoints8={builtCustomPoints8} setBuiltCustomPoints8={setBuiltCustomPoints8}
                builtCustomPoints9={builtCustomPoints9} setBuiltCustomPoints9={setBuiltCustomPoints9}
                builtCustomPoints10={builtCustomPoints10} setBuiltCustomPoints10={setBuiltCustomPoints10}
                builtCustomPoints11={builtCustomPoints11} setBuiltCustomPoints11={setBuiltCustomPoints11}
                builtCustomPoints12={builtCustomPoints12} setBuiltCustomPoints12={setBuiltCustomPoints12}
                builtCustomPoints13={builtCustomPoints13} setBuiltCustomPoints13={setBuiltCustomPoints13}
                builtCustomPoints14={builtCustomPoints14} setBuiltCustomPoints14={setBuiltCustomPoints14}
                builtCustomPoints15={builtCustomPoints15} setBuiltCustomPoints15={setBuiltCustomPoints15}
                builtCustomPoints16={builtCustomPoints16} setBuiltCustomPoints16={setBuiltCustomPoints16}
                builtCustomPoints17={builtCustomPoints17} setBuiltCustomPoints17={setBuiltCustomPoints17}
                builtCustomPoints18={builtCustomPoints18} setBuiltCustomPoints18={setBuiltCustomPoints18}
                builtCustomPoints19={builtCustomPoints19} setBuiltCustomPoints19={setBuiltCustomPoints19}
                builtCustomPoints20={builtCustomPoints20} setBuiltCustomPoints20={setBuiltCustomPoints20}

                builtSideSizes1={builtSideSizes1} setBuiltSideSizes1={setBuiltSideSizes1}
                builtSideSizes2={builtSideSizes2} setBuiltSideSizes2={setBuiltSideSizes2}
                builtSideSizes3={builtSideSizes3} setBuiltSideSizes3={setBuiltSideSizes3}
                builtSideSizes4={builtSideSizes4} setBuiltSideSizes4={setBuiltSideSizes4}
                builtSideSizes5={builtSideSizes5} setBuiltSideSizes5={setBuiltSideSizes5}
                builtSideSizes6={builtSideSizes6} setBuiltSideSizes6={setBuiltSideSizes6}
                builtSideSizes7={builtSideSizes7} setBuiltSideSizes7={setBuiltSideSizes7}
                builtSideSizes8={builtSideSizes8} setBuiltSideSizes8={setBuiltSideSizes8}
                builtSideSizes9={builtSideSizes9} setBuiltSideSizes9={setBuiltSideSizes9}
                builtSideSizes10={builtSideSizes10} setBuiltSideSizes10={setBuiltSideSizes10}
                builtSideSizes11={builtSideSizes11} setBuiltSideSizes11={setBuiltSideSizes11}
                builtSideSizes12={builtSideSizes12} setBuiltSideSizes12={setBuiltSideSizes12}
                builtSideSizes13={builtSideSizes13} setBuiltSideSizes13={setBuiltSideSizes13}
                builtSideSizes14={builtSideSizes14} setBuiltSideSizes14={setBuiltSideSizes14}
                builtSideSizes15={builtSideSizes15} setBuiltSideSizes15={setBuiltSideSizes15}
                builtSideSizes16={builtSideSizes16} setBuiltSideSizes16={setBuiltSideSizes16}
                builtSideSizes17={builtSideSizes17} setBuiltSideSizes17={setBuiltSideSizes17}
                builtSideSizes18={builtSideSizes18} setBuiltSideSizes18={setBuiltSideSizes18}
                builtSideSizes19={builtSideSizes19} setBuiltSideSizes19={setBuiltSideSizes19}
                builtSideSizes20={builtSideSizes20} setBuiltSideSizes20={setBuiltSideSizes20}

                builtCustomPoints1Copy={builtCustomPoints1Copy} setBuiltCustomPoints1Copy={setBuiltCustomPoints1Copy}
                builtCustomPoints2Copy={builtCustomPoints2Copy} setBuiltCustomPoints2Copy={setBuiltCustomPoints2Copy}
                builtCustomPoints3Copy={builtCustomPoints3Copy} setBuiltCustomPoints3Copy={setBuiltCustomPoints3Copy}
                builtCustomPoints4Copy={builtCustomPoints4Copy} setBuiltCustomPoints4Copy={setBuiltCustomPoints4Copy}
                builtCustomPoints5Copy={builtCustomPoints5Copy} setBuiltCustomPoints5Copy={setBuiltCustomPoints5Copy}
                builtCustomPoints6Copy={builtCustomPoints6Copy} setBuiltCustomPoints6Copy={setBuiltCustomPoints6Copy}
                builtCustomPoints7Copy={builtCustomPoints7Copy} setBuiltCustomPoints7Copy={setBuiltCustomPoints7Copy}
                builtCustomPoints8Copy={builtCustomPoints8Copy} setBuiltCustomPoints8Copy={setBuiltCustomPoints8Copy}
                builtCustomPoints9Copy={builtCustomPoints9Copy} setBuiltCustomPoints9Copy={setBuiltCustomPoints9Copy}
                builtCustomPoints10Copy={builtCustomPoints10Copy} setBuiltCustomPoints10Copy={setBuiltCustomPoints10Copy}
                builtCustomPoints11Copy={builtCustomPoints11Copy} setBuiltCustomPoints11Copy={setBuiltCustomPoints11Copy}
                builtCustomPoints12Copy={builtCustomPoints12Copy} setBuiltCustomPoints12Copy={setBuiltCustomPoints12Copy}
                builtCustomPoints13Copy={builtCustomPoints13Copy} setBuiltCustomPoints13Copy={setBuiltCustomPoints13Copy}
                builtCustomPoints14Copy={builtCustomPoints14Copy} setBuiltCustomPoints14Copy={setBuiltCustomPoints14Copy}
                builtCustomPoints15Copy={builtCustomPoints15Copy} setBuiltCustomPoints15Copy={setBuiltCustomPoints15Copy}
                builtCustomPoints16Copy={builtCustomPoints16Copy} setBuiltCustomPoints16Copy={setBuiltCustomPoints16Copy}
                builtCustomPoints17Copy={builtCustomPoints17Copy} setBuiltCustomPoints17Copy={setBuiltCustomPoints17Copy}
                builtCustomPoints18Copy={builtCustomPoints18Copy} setBuiltCustomPoints18Copy={setBuiltCustomPoints18Copy}
                builtCustomPoints19Copy={builtCustomPoints19Copy} setBuiltCustomPoints19Copy={setBuiltCustomPoints19Copy}
                builtCustomPoints20Copy={builtCustomPoints20Copy} setBuiltCustomPoints20Copy={setBuiltCustomPoints20Copy}

                Apex1Id={Apex1Id} setApex1Id={setApex1Id}
                Apex2Id={Apex2Id} setApex2Id={setApex2Id}
                Apex3Id={Apex3Id} setApex3Id={setApex3Id}
                Apex4Id={Apex4Id} setApex4Id={setApex4Id}
                Apex5Id={Apex5Id} setApex5Id={setApex5Id}
                Apex6Id={Apex6Id} setApex6Id={setApex6Id}
                Apex7Id={Apex7Id} setApex7Id={setApex7Id}
                Apex8Id={Apex8Id} setApex8Id={setApex8Id}
                Apex9Id={Apex9Id} setApex9Id={setApex9Id}
                Apex10Id={Apex10Id} setApex10Id={setApex10Id}
                Apex11Id={Apex11Id} setApex11Id={setApex11Id}
                Apex12Id={Apex12Id} setApex12Id={setApex12Id}
                Apex13Id={Apex13Id} setApex13Id={setApex13Id}
                Apex14Id={Apex14Id} setApex14Id={setApex14Id}
                Apex15Id={Apex15Id} setApex15Id={setApex15Id}
                Apex16Id={Apex16Id} setApex16Id={setApex16Id}
                Apex17Id={Apex17Id} setApex17Id={setApex17Id}
                Apex18Id={Apex18Id} setApex18Id={setApex18Id}
                Apex19Id={Apex19Id} setApex19Id={setApex19Id}
                Apex20Id={Apex20Id} setApex20Id={setApex20Id}


                Eaves1Id={Eaves1Id} setEaves1Id={setEaves1Id}
                Eaves2Id={Eaves2Id} setEaves2Id={setEaves2Id}
                Eaves3Id={Eaves3Id} setEaves3Id={setEaves3Id}
                Eaves4Id={Eaves4Id} setEaves4Id={setEaves4Id}
                Eaves5Id={Eaves5Id} setEaves5Id={setEaves5Id}
                Eaves6Id={Eaves6Id} setEaves6Id={setEaves6Id}
                Eaves7Id={Eaves7Id} setEaves7Id={setEaves7Id}
                Eaves8Id={Eaves8Id} setEaves8Id={setEaves8Id}
                Eaves9Id={Eaves9Id} setEaves9Id={setEaves9Id}
                Eaves10Id={Eaves10Id} setEaves10Id={setEaves10Id}
                Eaves11Id={Eaves11Id} setEaves11Id={setEaves11Id}
                Eaves12Id={Eaves12Id} setEaves12Id={setEaves12Id}
                Eaves13Id={Eaves13Id} setEaves13Id={setEaves13Id}
                Eaves14Id={Eaves14Id} setEaves14Id={setEaves14Id}
                Eaves15Id={Eaves15Id} setEaves15Id={setEaves15Id}
                Eaves16Id={Eaves16Id} setEaves16Id={setEaves16Id}
                Eaves17Id={Eaves17Id} setEaves17Id={setEaves17Id}
                Eaves18Id={Eaves18Id} setEaves18Id={setEaves18Id}
                Eaves19Id={Eaves19Id} setEaves19Id={setEaves19Id}
                Eaves20Id={Eaves20Id} setEaves20Id={setEaves20Id}

                lines1Copy={lines1Copy} setLines1Copy={setLines1Copy}
                lines2Copy={lines2Copy} setLines2Copy={setLines2Copy}
                lines3Copy={lines3Copy} setLines3Copy={setLines3Copy}
                lines4Copy={lines4Copy} setLines4Copy={setLines4Copy}
                lines5Copy={lines5Copy} setLines5Copy={setLines5Copy}
                lines6Copy={lines6Copy} setLines6Copy={setLines6Copy}
                lines7Copy={lines7Copy} setLines7Copy={setLines7Copy}
                lines8Copy={lines8Copy} setLines8Copy={setLines8Copy}
                lines9Copy={lines9Copy} setLines9Copy={setLines9Copy}
                lines10Copy={lines10Copy} setLines10Copy={setLines10Copy}
                lines11Copy={lines11Copy} setLines11Copy={setLines11Copy}
                lines12Copy={lines12Copy} setLines12Copy={setLines12Copy}
                lines13Copy={lines13Copy} setLines13Copy={setLines13Copy}
                lines14Copy={lines14Copy} setLines14Copy={setLines14Copy}
                lines15Copy={lines15Copy} setLines15Copy={setLines15Copy}
                lines16Copy={lines16Copy} setLines16Copy={setLines16Copy}
                lines17Copy={lines17Copy} setLines17Copy={setLines17Copy}
                lines18Copy={lines18Copy} setLines18Copy={setLines18Copy}
                lines19Copy={lines19Copy} setLines19Copy={setLines19Copy}
                lines20Copy={lines20Copy} setLines20Copy={setLines20Copy}
              />
              }
            {appContext.state.isBuildMode &&
              <CoveredPolygon
                coords={coordsForCover}
                dictionaryItem={dictionary[selectedCategory]}
                setCalcResult={setCalcResult}
                figureBottomLine1={figureBottomLine1}
                leavePlusTop={leavePlusTop}
                setLeavePlusTop={setLeavePlusTop}
                leaveMinusTop={leaveMinusTop}
                setLeaveMinusTop={setLeaveMinusTop}
                leavePlusBot={leavePlusBot}
                setLeavePlusBot={setLeavePlusBot}
                leaveMinusBot={leaveMinusBot}
                setLeaveMinusBot={setLeaveMinusBot}
                leaveDelete={leaveDelete}
                setLeaveDelete={setLeaveDelete}
                leaveConnect={leaveConnect}
                setLeaveConnect={setLeaveConnect}
                leaveDivide={leaveDivide}
                setLeaveDivide={setLeaveDivide}
                leaveAdd={leaveAdd}
                setLeaveAdd={setLeaveAdd}
                plusMaxHeight={plusMaxHeight}
                setPlusMaxHeight={setPlusMaxHeight}
                minusMaxHeight={minusMaxHeight}
                setMinusMaxHeight={setMinusMaxHeight}
                changeCoverSide={changeCoverSide}
                setChangeCoverSide={setChangeCoverSide}
                leaveChangeValue={leaveChangeValue}
                setLeaveArrowValue={setLeaveArrowValue}
                leaveArrowValue={leaveArrowValue}
                flipFigureX={flipFigureX}
                setFlipFigureX={setFlipFigureX}
                flipFigureY={flipFigureY}
                setFlipFigureY={setFlipFigureY}

                leavesLeft={leavesLeft} 
                setLeavesLeft={setLeavesLeft}
                leavesTop={leavesTop}
                setLeavesTop={setLeavesTop}
                leavesRight={leavesRight} 
                setLeavesRight={setLeavesRight}
                leavesBottom={leavesBottom} 
                setLeavesBottom={setLeavesBottom}

                cutsForCover={cutsForCover}
                isGridActive={isGridActive}

                areasCount={areasCount}

                areas1={areas1} setAreas1={setAreas1}
                areas2={areas2} setAreas2={setAreas2}
                areas3={areas3} setAreas3={setAreas3}
                areas4={areas4} setAreas4={setAreas4}
                areas5={areas5} setAreas5={setAreas5}
                areas6={areas6} setAreas6={setAreas6}
                areas7={areas7} setAreas7={setAreas7}
                areas8={areas8} setAreas8={setAreas8}
                areas9={areas9} setAreas9={setAreas9}
                areas10={areas10} setAreas10={setAreas10}
                areas11={areas11} setAreas11={setAreas11}
                areas12={areas12} setAreas12={setAreas12}
                areas13={areas13} setAreas13={setAreas13}
                areas14={areas14} setAreas14={setAreas14}
                areas15={areas15} setAreas15={setAreas15}
                areas16={areas16} setAreas16={setAreas16}
                areas17={areas17} setAreas17={setAreas17}
                areas18={areas18} setAreas18={setAreas18}
                areas19={areas19} setAreas19={setAreas19}
                areas20={areas20} setAreas20={setAreas20}

                cuts1={cuts1} setCuts1={setCuts1}
                cuts2={cuts2} setCuts2={setCuts2}
                cuts3={cuts3} setCuts3={setCuts3} 
                cuts4={cuts4} setCuts4={setCuts4}
                cuts5={cuts5} setCuts5={setCuts5}
                cuts6={cuts6} setCuts6={setCuts6}
                cuts7={cuts7} setCuts7={setCuts7}
                cuts8={cuts8} setCuts8={setCuts8}
                cuts9={cuts9} setCuts9={setCuts9}
                cuts10={cuts10} setCuts10={setCuts10}
                cuts11={cuts11} setCuts11={setCuts11}
                cuts12={cuts12} setCuts12={setCuts12} 
                cuts13={cuts13} setCuts13={setCuts13} 
                cuts14={cuts14} setCuts14={setCuts14} 
                cuts15={cuts15} setCuts15={setCuts15} 
                cuts16={cuts16} setCuts16={setCuts16} 
                cuts17={cuts17} setCuts17={setCuts17} 
                cuts18={cuts18} setCuts18={setCuts18} 
                cuts19={cuts19} setCuts19={setCuts19} 
                cuts20={cuts20} setCuts20={setCuts20} 

                

                lines1={lines1} setLines1={setLines1}
                lines2={lines2} setLines2={setLines2}
                lines3={lines3} setLines3={setLines3}
                lines4={lines4} setLines4={setLines4}
                lines5={lines5} setLines5={setLines5}
                lines6={lines6} setLines6={setLines6}
                lines7={lines7} setLines7={setLines7}
                lines8={lines8} setLines8={setLines8}
                lines9={lines9} setLines9={setLines9}
                lines10={lines10} setLines10={setLines10}
                lines11={lines11} setLines11={setLines11}
                lines12={lines12} setLines12={setLines12}
                lines13={lines13} setLines13={setLines13}
                lines14={lines14} setLines14={setLines14}
                lines15={lines15} setLines15={setLines15}
                lines16={lines16} setLines16={setLines16}
                lines17={lines17} setLines17={setLines17}
                lines18={lines18} setLines18={setLines18}
                lines19={lines19} setLines19={setLines19}
                lines20={lines20} setLines20={setLines20}

                results1={results1} setResults1={setResults1}
                 results2={results2} setResults2={setResults2}
                 results3={results3} setResults3={setResults3}
                 results4={results4} setResults4={setResults4}
                 results5={results5} setResults5={setResults5}
                 results6={results6} setResults6={setResults6}
                 results7={results7} setResults7={setResults7}
                 results8={results8} setResults8={setResults8}
                 results9={results9} setResults9={setResults9}
                 results10={results10} setResults10={setResults10}
                 results11={results11} setResults11={setResults11}
                 results12={results12} setResults12={setResults12}
                 results13={results13} setResults13={setResults13}
                 results14={results14} setResults14={setResults14}
                 results15={results15} setResults15={setResults15}
                 results16={results16} setResults16={setResults16}
                 results17={results17} setResults17={setResults17}
                 results18={results18} setResults18={setResults18}
                 results19={results19} setResults19={setResults19}
                 results20={results20} setResults20={setResults20}

                 cellRows1={cellRows1} setCellRows1={setCellRows1}
                cellRows2={cellRows2} setCellRows2={setCellRows2}
                cellRows3={cellRows3} setCellRows3={setCellRows3}
                cellRows4={cellRows4} setCellRows4={setCellRows4}
                cellRows5={cellRows5} setCellRows5={setCellRows5}
                cellRows6={cellRows6} setCellRows6={setCellRows6}
                cellRows7={cellRows7} setCellRows7={setCellRows7}
                cellRows8={cellRows8} setCellRows8={setCellRows8}
                cellRows9={cellRows9} setCellRows9={setCellRows9}
                cellRows10={cellRows10} setCellRows10={setCellRows10}
                cellRows11={cellRows11} setCellRows11={setCellRows11}
                cellRows12={cellRows12} setCellRows12={setCellRows12}
                cellRows13={cellRows13} setCellRows13={setCellRows13}
                cellRows14={cellRows14} setCellRows14={setCellRows14}
                cellRows15={cellRows15} setCellRows15={setCellRows15}
                cellRows16={cellRows16} setCellRows16={setCellRows16}
                cellRows17={cellRows17} setCellRows17={setCellRows17}
                cellRows18={cellRows18} setCellRows18={setCellRows18}
                cellRows19={cellRows19} setCellRows19={setCellRows19}
                cellRows20={cellRows20} setCellRows20={setCellRows20}
              />
            }
            {/*appContext.state.isCutFigureMode &&*/ appContext.state.isBuildMode == false && areasCount > 0 && // appContext.state.editedMode !== 1 &&
              <CutFigure
              coords={polygonPoints}
              clickedCoords={clickedCoords}
              width={500}
              start={start} setStart={setStart}
              isDottAdditional={isDottAdditional}
              setIsDottAdditional={setIsDottAdditional}
              prevStep={prevStep}
              setPrevStep={setPrevStep}
              nextStep={nextStep}
              setNextStep={setNextStep}
              deleteDot={deleteDot}
              setDeleteDot={setDeleteDot}
              doubleCutFigure={doubleCutFigure}
              setDoubleCutFigure={setDoubleCutFigure}
              xCutShift={xCutShift}
              yCutShift={yCutShift}
              setXCutShift={setXCutShift}
              setYCutShift={setYCutShift}
              cutFigureCount={cutFigureCount}
              setCutFigureCount={setCutFigureCount}
              deleteCutFigure={deleteCutFigure}
              setDeleteCutFigure={setDeleteCutFigure}
              deleteFigure={deleteFigure}
              areasCount={areasCount}

              builtCutCustomPoints={builtCutCustomPoints} 
              setBuiltCutCustomPoints={setBuiltCutCustomPoints}
              cutFigureStart={cutFigureStart}
              setCutFigureStart={setCutFigureStart}

              cutFigures={cutFigures} setCutFigures={setCutFigures}
              cutFiguresLines={cutFiguresLines} setCutFiguresLines={setCutFiguresLines}

              areas1={areas1} setAreas1={setAreas1}
                areas2={areas2} setAreas2={setAreas2}
                areas3={areas3} setAreas3={setAreas3}
                areas4={areas4} setAreas4={setAreas4}
                areas5={areas5} setAreas5={setAreas5}
                areas6={areas6} setAreas6={setAreas6}
                areas7={areas7} setAreas7={setAreas7}
                areas8={areas8} setAreas8={setAreas8}
                areas9={areas9} setAreas9={setAreas9}
                areas10={areas10} setAreas10={setAreas10}
                areas11={areas11} setAreas11={setAreas11}
                areas12={areas12} setAreas12={setAreas12}
                areas13={areas13} setAreas13={setAreas13}
                areas14={areas14} setAreas14={setAreas14}
                areas15={areas15} setAreas15={setAreas15}
                areas16={areas16} setAreas16={setAreas16}
                areas17={areas17} setAreas17={setAreas17}
                areas18={areas18} setAreas18={setAreas18}
                areas19={areas19} setAreas19={setAreas19}
                areas20={areas20} setAreas20={setAreas20}

                cuts1={cuts1} setCuts1={setCuts1}
                cuts2={cuts2} setCuts2={setCuts2}
                cuts3={cuts3} setCuts3={setCuts3} 
                cuts4={cuts4} setCuts4={setCuts4}
                cuts5={cuts5} setCuts5={setCuts5}
                cuts6={cuts6} setCuts6={setCuts6}
                cuts7={cuts7} setCuts7={setCuts7}
                cuts8={cuts8} setCuts8={setCuts8}
                cuts9={cuts9} setCuts9={setCuts9}
                cuts10={cuts10} setCuts10={setCuts10}
                cuts11={cuts11} setCuts11={setCuts11}
                cuts12={cuts12} setCuts12={setCuts12} 
                cuts13={cuts13} setCuts13={setCuts13} 
                cuts14={cuts14} setCuts14={setCuts14} 
                cuts15={cuts15} setCuts15={setCuts15} 
                cuts16={cuts16} setCuts16={setCuts16} 
                cuts17={cuts17} setCuts17={setCuts17} 
                cuts18={cuts18} setCuts18={setCuts18} 
                cuts19={cuts19} setCuts19={setCuts19} 
                cuts20={cuts20} setCuts20={setCuts20} 

                closedCuts1={closedCuts1} setClosedCuts1={setClosedCuts1}
                closedCuts2={closedCuts2} setClosedCuts2={setClosedCuts2}
                closedCuts3={closedCuts3} setClosedCuts3={setClosedCuts3}
                closedCuts4={closedCuts4} setClosedCuts4={setClosedCuts4}
                closedCuts5={closedCuts5} setClosedCuts5={setClosedCuts5}
                closedCuts6={closedCuts6} setClosedCuts6={setClosedCuts6}
                closedCuts7={closedCuts7} setClosedCuts7={setClosedCuts7}
                closedCuts8={closedCuts8} setClosedCuts8={setClosedCuts8}
                closedCuts9={closedCuts9} setClosedCuts9={setClosedCuts9}
                closedCuts10={closedCuts10} setClosedCuts10={setClosedCuts10}
                closedCuts11={closedCuts11} setClosedCuts11={setClosedCuts11}
                closedCuts12={closedCuts12} setClosedCuts12={setClosedCuts12}
                closedCuts13={closedCuts13} setClosedCuts13={setClosedCuts13}
                closedCuts14={closedCuts14} setClosedCuts14={setClosedCuts14}
                closedCuts15={closedCuts15} setClosedCuts15={setClosedCuts15}
                closedCuts16={closedCuts16} setClosedCuts16={setClosedCuts16}
                closedCuts17={closedCuts17} setClosedCuts17={setClosedCuts17}
                closedCuts18={closedCuts18} setClosedCuts18={setClosedCuts18}
                closedCuts19={closedCuts19} setClosedCuts19={setClosedCuts19}
                closedCuts20={closedCuts20} setClosedCuts20={setClosedCuts20}

                lines1={lines1} setLines1={setLines1}
                lines2={lines2} setLines2={setLines2}
                lines3={lines3} setLines3={setLines3}
                lines4={lines4} setLines4={setLines4}
                lines5={lines5} setLines5={setLines5}
                lines6={lines6} setLines6={setLines6}
                lines7={lines7} setLines7={setLines7}
                lines8={lines8} setLines8={setLines8}
                lines9={lines9} setLines9={setLines9}
                lines10={lines10} setLines10={setLines10}
                lines11={lines11} setLines11={setLines11}
                lines12={lines12} setLines12={setLines12}
                lines13={lines13} setLines13={setLines13}
                lines14={lines14} setLines14={setLines14}
                lines15={lines15} setLines15={setLines15}
                lines16={lines16} setLines16={setLines16}
                lines17={lines17} setLines17={setLines17}
                lines18={lines18} setLines18={setLines18}
                lines19={lines19} setLines19={setLines19}
                lines20={lines20} setLines20={setLines20}

                cutLines1={cutLines1} setCutLines1={setCutLines1}
                cutLines2={cutLines2} setCutLines2={setCutLines2}
                cutLines3={cutLines3} setCutLines3={setCutLines3}
                cutLines4={cutLines4} setCutLines4={setCutLines4}
                cutLines5={cutLines5} setCutLines5={setCutLines5}
                cutLines6={cutLines6} setCutLines6={setCutLines6}
                cutLines7={cutLines7} setCutLines7={setCutLines7}
                cutLines8={cutLines8} setCutLines8={setCutLines8}
                cutLines9={cutLines9} setCutLines9={setCutLines9}
                cutLines10={cutLines10} setCutLines10={setCutLines10}
                cutLines11={cutLines11} setCutLines11={setCutLines11}
                cutLines12={cutLines12} setCutLines12={setCutLines12}
                cutLines13={cutLines13} setCutLines13={setCutLines13}
                cutLines14={cutLines14} setCutLines14={setCutLines14}
                cutLines15={cutLines15} setCutLines15={setCutLines15}
                cutLines16={cutLines16} setCutLines16={setCutLines16}
                cutLines17={cutLines17} setCutLines17={setCutLines17}
                cutLines18={cutLines18} setCutLines18={setCutLines18}
                cutLines19={cutLines19} setCutLines19={setCutLines19}
                cutLines20={cutLines20} setCutLines20={setCutLines20}



                /*eaves1={eaves1} setEaves1={setEaves1} 
                eaves2={eaves2} setEaves2={setEaves2} 
                eaves3={eaves3} setEaves3={setEaves3}
                eaves4={eaves4} setEaves4={setEaves4} 
                eaves5={eaves5} setEaves5={setEaves5} 
                eaves6={eaves6} setEaves6={setEaves6}
                eaves7={eaves7} setEaves7={setEaves7} 
                eaves8={eaves8} setEaves8={setEaves8} 
                eaves9={eaves9} setEaves9={setEaves9}
                eaves10={eaves10} setEaves10={setEaves10} 
                eaves11={eaves11} setEaves11={setEaves11} 
                eaves12={eaves12} setEaves12={setEaves12}
                eaves13={eaves13} setEaves13={setEaves13} 
                eaves14={eaves14} setEaves14={setEaves14} 
                eaves15={eaves15} setEaves15={setEaves15}
                eaves16={eaves16} setEaves16={setEaves16} 
                eaves17={eaves17} setEaves17={setEaves17} 
                eaves18={eaves18} setEaves18={setEaves18}
                eaves19={eaves19} setEaves19={setEaves19} 
                eaves20={eaves20} setEaves20={setEaves20}*/


                /*apex1={apex1} setApex1={setApex1}
                apex2={apex2} setApex2={setApex2}
                apex3={apex3} setApex3={setApex3}
                apex4={apex4} setApex4={setApex4}
                apex5={apex5} setApex5={setApex5}
                apex6={apex6} setApex6={setApex6}
                apex7={apex7} setApex7={setApex7}
                apex8={apex8} setApex8={setApex8}
                apex9={apex9} setApex9={setApex9}
                apex10={apex10} setApex10={setApex10}
                apex11={apex11} setApex11={setApex11}
                apex12={apex12} setApex12={setApex12}
                apex13={apex13} setApex13={setApex13}
                apex14={apex14} setApex14={setApex14}
                apex15={apex15} setApex15={setApex15}
                apex16={apex16} setApex16={setApex16}
                apex17={apex17} setApex17={setApex17}
                apex18={apex18} setApex18={setApex18}
                apex19={apex19} setApex19={setApex19}
                apex20={apex20} setApex20={setApex20}


                isLinesClosed1={isLinesClosed1} setIsLinesClosed1={setIsLinesClosed1} 
                isLinesClosed2={isLinesClosed2} setIsLinesClosed2={setIsLinesClosed2} 
                isLinesClosed3={isLinesClosed3} setIsLinesClosed3={setIsLinesClosed3} 
                isLinesClosed4={isLinesClosed4} setIsLinesClosed4={setIsLinesClosed4} 
                isLinesClosed5={isLinesClosed5} setIsLinesClosed5={setIsLinesClosed5} 
                isLinesClosed6={isLinesClosed6} setIsLinesClosed6={setIsLinesClosed6} 
                isLinesClosed7={isLinesClosed7} setIsLinesClosed7={setIsLinesClosed7} 
                isLinesClosed8={isLinesClosed8} setIsLinesClosed8={setIsLinesClosed8} 
                isLinesClosed9={isLinesClosed9} setIsLinesClosed9={setIsLinesClosed9} 
                isLinesClosed10={isLinesClosed10} setIsLinesClosed10={setIsLinesClosed10} 
                isLinesClosed11={isLinesClosed11} setIsLinesClosed11={setIsLinesClosed11} 
                isLinesClosed12={isLinesClosed12} setIsLinesClosed12={setIsLinesClosed12} 
                isLinesClosed13={isLinesClosed13} setIsLinesClosed13={setIsLinesClosed13} 
                isLinesClosed14={isLinesClosed14} setIsLinesClosed14={setIsLinesClosed14} 
                isLinesClosed15={isLinesClosed15} setIsLinesClosed15={setIsLinesClosed15} 
                isLinesClosed16={isLinesClosed16} setIsLinesClosed16={setIsLinesClosed16} 
                isLinesClosed17={isLinesClosed17} setIsLinesClosed17={setIsLinesClosed17} 
                isLinesClosed18={isLinesClosed18} setIsLinesClosed18={setIsLinesClosed18} 
                isLinesClosed19={isLinesClosed19} setIsLinesClosed19={setIsLinesClosed19} 
                isLinesClosed20={isLinesClosed20} setIsLinesClosed20={setIsLinesClosed20} */
            />
            }
        </>
    )
}

export default PolygonContainer;