
// @ts-nocheck

import React, {createContext, FC, useContext, useEffect, useReducer, useRef, useState} from "react";
import "./Categories.scss";
import { Link } from "react-router-dom";

import plusImg from "../../../images/plus.png";
import categoryImg from "../../../images/nophoto.png";
import {AppContext} from "../../../context/AppContext";
import {useActiveCategory} from "../Dictionary";
import AddCategoryDialog from "../Dialogs/AddCategoryDialog";

interface ICategoryParams {
    categoryExceptions: any[];
    categoryRecommended: any[];
    categoryMade: any[];
    generalWidth: any[];
    usefulWidth: any[];
    verticalOverlap: any[];
    horizontalOverlap: any[];
    doubleOverlap: any[];
    tail: any[];
    maxHeight: any[];
}
interface Props {
    activeTab: any;
    setActiveTab: any;
}

const Categories: FC<Props> = ({activeTab, setActiveTab}) => {

    const appContext = useContext(AppContext);

    const activeCategoryOutletCtx = useActiveCategory();

    const [modalCategory, setModalCategory] = useState<Boolean>(false);
    const [categoryName, setCategoryName] = useState('');

    const [categories, setCategories] = useState<any[]>([]);

    const [name, setName] = useState<string>('');

    const [selectedFile, setSelectedFile] = useState(undefined)
    const [preview, setPreview] = useState(undefined)

    let onCreateCategory = () => {
        setModalCategory(false)
        const fd = new FormData();
        // @ts-ignore
        fd.append('image', selectedFile);
        fd.append('name', name);

        // send `POST` request

        var requestOptions: RequestInit = {
            method: 'POST',
            body: fd,
            redirect: 'follow'
        };

        fetch("https://tile.sofenty.com/api/add-category", requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));

        setCategories([...categories, {id: categories.length + 1, name: name, image: (preview !== undefined ? preview : categoryImg), exceptions: [], recommended: [], made: [], generalWidth: [], usefulWidth: [], verticalOverlap: [], horizontalOverlap: [], doubleOverlap: [], tail: [], maxHeight: []}])

    }

    useEffect(() => {
        appContext.dispatch({type: 'update-dictionary', payload: {dictionary: categories}});
    }, [categories])

    useEffect(() => {
        setCategories(appContext.state.dictionary);
    },[])

    const uploadFile = (file: any) => {
        // add file to FormData object
        const fd = new FormData();
        // @ts-ignore
        fd.append('image', selectedFile);

        // send `POST` request
        fetch('https://tile.sofenty.com/api/add-category', {
            method: 'POST',
            body: fd
        })
            .then(res => res.json())
            .then(json => console.log(json))
            .catch(err => console.error(err));
    }

    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(selectedFile)
        // @ts-ignore
        setPreview(objectUrl)
    }, [selectedFile])

    function onSetCategoryBtnHandler(item: any) {
        activeCategoryOutletCtx.setActiveCategory(item)
        // window.location.href = `/dictionary/${item.id}`;
        // window.location.href = `/table/${item.id}`;
        // window.location.href = `/table`;
        //setType('category-info');
        setCategoryName(item.name);
        uploadFile(selectedFile);
    }

    function onRemoveBtnHandler(item: any) {
        fetch('https://tile.sofenty.com/api/remove-category/' + item.id, {
            method: 'DELETE',
        })
            .then(res => {
                return res.json()
            })
            .then(data => {
                //window.location.href = '/dictionary';
            });
    }


    ////////////////////////////////////// 134 <Link to={'table' + '/category=' + item.id}>

    return (
        <div className="categories-wrapper">
            {/* Сторінка списку категорій */}

            <div className="category-list">
                {categories.map(item => {
                    return (
                        
                            <div key={item.id} className="category-card" onClick={() => {setActiveTab("dictionary/table/category=" + item.id)}}>
                                <button className="remove" onClick={(e) => {e.preventDefault(); e.stopPropagation(); onRemoveBtnHandler(item)}}>X</button>
                                <div style={{minHeight: '180px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                                    <img src={item.image} alt={item.name}/>
                                    <div className="name">{item.name}</div>
                                </div>
                                <button className="edit" onClick={(e) => {
                                    // e.preventDefault();
                                    // e.stopPropagation()
                                }}>Редагувати</button>
                            </div>
                    )
                })}

              <div onClick={e => setModalCategory(true)} key={categories.length + 1} className="plus-card">
                <img src={plusImg} alt="Plus" />
              </div>
            </div>

        {modalCategory && <AddCategoryDialog modalCategory={modalCategory} setModalCategory={setModalCategory} name={name} setName={setName} selectedFile={selectedFile} setSelectedFile={setSelectedFile} onCreateCategoryCallback={onCreateCategory}/>}

        </div>
    );
}

export default Categories;
