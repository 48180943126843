// @ts-nocheck
import React, {FC, useContext, useEffect} from 'react';
import { Layer, Line, Text } from "react-konva";
import {scalesConfig} from "../../data";

interface Props {
    cellSize: number;
    gridHeight: number;
    updateScale: any;
}

const GridNumbers: FC<Props> = ({cellSize,gridHeight, updateScale}) => {
    function createCellNumber() {
        let step = cellSize;

        let arr = []
        let x = 0;
        let y = 0;
        for (let i = 0; i < 100; i++) {
            x += step;
            let newX = x;

            arr.push(
                <Text text={i.toString()} key={1000 + i}
                      x={newX}
                      y={-790}
                      scaleY={-1}
                      fill={'black'}
                      width={100}
                      points={[0, 0, 0, 800, 0]}
                />
            );
        }

        for (let i = 0; i < 50; i++) {
            y += step;
            let newY = y ;
            arr.push(
                <Text text={(i).toString()} key={10000 + i}
                      x={0}
                      scaleY={-1}
                      fill={'black'}
                      y={-gridHeight / 1 + newY}
                      width={100}
                />
            );
        }

        return arr;
    }

    return (
        <>
            <Layer>
                {createCellNumber()}
            </Layer>
        </>
    );
}

export default GridNumbers;